
const state = () => (getDefaultState());

const mutations = {
	setFolderTree(state, tree) {
		state.folderTree = tree;
	},
	setFolderSearchResults(state, value) {
		state.foldersSr = value;
	},
	setBreadcrumbs(state, breadcrumbs) {
		state.breadcrumbs = breadcrumbs;
	},
	setIsCurrentTree(state, isCurrent) {
		state.isCurrentTree = isCurrent;
	},
	setPredefinedParent(state, value) {
		state.lookupPredefinedFields.parentfolder = value;
	},
	setDefaultState(state) {
		Object.assign(state, getDefaultState())
	}
};
const actions = {
	setDefaultValues({ commit }, value) {
		commit('setDefaultState');
	}
};

const getDefaultState = () => ({
	folderTree: null,
	isCurrentTree: false,
	breadcrumbs: [],
	lookupPredefinedFields: {},
	foldersSr: null
});

export default {
	namespaced: true,
	state,
	mutations,
	actions
};
