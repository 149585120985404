const getDataProvider = () => {
	return sc.classes.get('edge.dataProvider');
};
export default {
	async sendExportRequest(entity, fields, total, filters, selection, filename) {
		const combinedFilters = [...filters, ...this._exportFilters(fields, total, selection)];
		const query = getDataProvider().fillFilters(combinedFilters);
		const { exportId } = await getDataProvider().sendSearchRequest({
			type: 'post',
			url: ['plugins', 'endpoint', 'export', 'post'],
			body: {
				entity,
				format: 'excel',
				query: JSON.stringify(query)
			}
		}, true);
		if (!exportId) {
			return false;
		}
		return await this._downloadFile(exportId, filename);
	},
	_exportFilters(fields, total, selection) {
		const filters = [
			sc.classes.get('selectedFields.filter', fields, false).fillQuery(),
			sc.classes.get('resultsFormat.filter', 'Export'),
			sc.classes.get('offsetSize.filter', selection.length || total || 1000)
		];
		if (selection) {
			filters.push(sc.classes.get('ids.filter', selection && selection.map(sel => sel.Id)).fillQuery());
		}
		return filters;
	},
	_getExportedFileUrl(exportId) {
		const lng = sc.localization.find(l => { return l.current === true; });
		const token = localStorage.getItem(sc.classes.get('authentication').getTokenKey(sc.cluster.key));
		let requestUrl = getDataProvider().getRequestUrl(['plugins', 'endpoint', 'export', 'get']);
		requestUrl += `?id=${exportId}&token=${token}`;
		if (lng) {
			requestUrl += `&lcid=${lng.code}`;
		}
		return requestUrl;
	},
	async _downloadFile(exportId, filename) {
		const response = await fetch(this._getExportedFileUrl(exportId));
		const blob = await response.blob();
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.style.display = 'none';
		a.href = url;
		a.download = `${filename}.xlsx`;
		document.body.appendChild(a);
		a.click();
		window.URL.revokeObjectURL(url);
		document.body.removeChild(a);
	}
};
