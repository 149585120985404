import {
	createEvaluationContext, evaluate, evaluateAsyncFake
} from '../../../../Data/EvaluationContext/evaluationContext';

export class BaseAspect {
	constructor() {
		this.options = null;
		this.evaluationContext = null;

		this.fork = sc.events.fork();
		this.components = sc.classes.get('container'); /* TODO: refactor */
	}

	init(options, context) {
		this.options = options;
		this.evaluationContext = createEvaluationContext(context);
	}

	eval(expression, defaultValue) {
		if (!expression) {
			return defaultValue;
		}
		return this.evaluationContext ? this.evaluationContext.eval(expression) : evaluate(expression);
	}

	build(expression, defaultValue) {
		if (!expression) return ko.pureComputed(() => defaultValue);

		// TODO: workaround. Use await evalAsync later
		return this.evaluationContext ? this.evaluationContext.evalAsyncFake(expression) : evaluateAsyncFake(expression);
	}

	dispose() {
		if (this.components) this.components.dispose();
		if (this.fork) this.fork.dispose();
	}

	errorHandler(response) {
		let message = response;
		if (!_.isString(response) && response && response.responseText) {
			message = response.responseText;
		}
		sc.utils.errorMessage.byMessage('Some records were skipped.', message);
	};
}
