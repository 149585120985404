<template>
	<div class="dialog-content-wrapper">
		<div class="search-result-dialog">
			<div class="loading-overlay large" v-show="isLoading"></div>
			<div class="dialog-container" v-if="!isLoading">
				<component :is="searchResultComponentName"
						   :options="componentOptions"
						   :evaluationContext="evaluationContext"></component>
			</div>
			<div class="footer">
				<div class='buttons'>
					<button type="button" @click="close" v-localization="{key: 'common.dictionary.buttons.close'}"></button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import ComponentSearchResults from '@/Components/ComponentSet/SearchResults/component-search-results';
export default {
	name: 'shareddocument-dialog',
	props: { options: Object },
	components: { ComponentSearchResults },
	data() {
		return {
			isLoading: true,
			componentOptions: this.options.params.componentSet.component[0],
			searchResultComponentName: this.options.params.searchResultComponentName ? this.options.params.searchResultComponentName : 'component-search-results',
			evaluationContext: this.options.params.evaluationContext
		};
	},

	created() {
		this.componentOptions.parentData = this.options.params.parentRef.toObject();
		this.stateNamespace = this.options.params.componentSet.component[0].stateNamespace.value;
		this.isLoading = false;
	},
	methods: {
		close() {
			this.$parent.$emit('close');
		}
	},
	unmounted() {
		this.$store.commit(this.stateNamespace + '/openInfoPanel', null);
		this.$store.commit(this.stateNamespace + '/resetFilters');
	}
};
</script>
<style src="./search-result-dialog.less" scoped></style>
