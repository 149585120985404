<template>
	<div class="row" v-bind:class="{ 'selected': isSelected, 'no-selection': !options.allowSelection }">
		<VirtualCheckbox :select="isSelected"
						 :record="rowItem"
						 :stateNamespace="stateNamespace"
						 :isParent="options.isParent"
						 :syncSelection="options.syncSelection"
						 :evaluationContext="evaluationContext"
						 v-if="options.allowSelection"></VirtualCheckbox>
		<control v-bind:name="options.contentComponent.$type"
				 v-bind:contentProps="options.contentComponent"
				 v-bind:evaluationContext="evaluationContext">
		</control>
		<div class="loading-overlay" v-show="isLoading"></div>
	</div>
</template>
<script>
import VirtualCheckbox from './../../../../Control/Checkboxes/virtual-checkbox';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';

export default {
	name: 'map-view-row',
	props: {
		rowItem: Object,
		stateNamespace: String,
		options: Object
	},
	components: {
		VirtualCheckbox,
		Control: () => import('./../../../../Entity/control.vue')
	},
	data() {
		return {
			isSelected: false,
			evaluationContext: this.getEvalContext(this.rowItem),
			isLoading: false
		};
	},
	created() {
		this.fork = sc.events.fork();
		this.fork.on(`loading.${this.rowItem.Type}.${this.rowItem.Id}`, (isLoading) => {
			this.isLoading = isLoading;
		});
		const selectChilds = (additionalInfo) => {
			const diff = {
				...this.rowItem,
				...{ selected: this.isSelected }
			};
			this.$parent.$emit('select-childs', additionalInfo
				? {
					...additionalInfo,
					...{ diffSelection: [diff] }
				}
				: { diffSelection: [diff] });
		};

		this.$on('checkbox-select', () => {
			this.isSelected = !this.isSelected;

			this.$parent.$emit('row-selected', {
				isSelected: this.isSelected,
				item: this.rowItem
			});
			selectChilds();
		});

		this.$parent.$on('row-selected.' + this.rowItem.Id, (selected, skipAllChildSelection) => {
			this.isSelected = selected;
			this.$parent.$emit('row-selected', {
				isSelected: this.isSelected,
				item: this.rowItem
			});
			if (!skipAllChildSelection) selectChilds();
		});
	},
	beforeDestroy() {
		if (this.fork) {
			this.fork.dispose();
		}
	},
	methods: {
		getEvalContext(record) {
			return this.evaluationContext || createEvaluationContext(record);
		}
	}
};
</script>
<style src="./map-view-row.less" scoped></style>
