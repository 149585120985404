<template>
	<div class="dialog-content-wrapper">
		<div class="loading-overlay large" v-show="isLoading"></div>
		<div class="share-entity-dialog">
			<div class="dialog-container">
				<div class="description">
					<h5 v-localization="{ key: 'dialogues.shareSavedSearch.findContacts' }"></h5>
				</div>
				<div class="select-entity">
					<control-lookup :evaluationContext="options.evaluationContext" :options="getLookupOptions()"></control-lookup>
				</div>
				<div class="entity-list">
					<control class="formcontrol-template"
							v-if="userListTemplate"
							:name="userListTemplate.$type"
							:contentProps="userListTemplate"
							:evaluationContext="options.evaluationContext"
							:initResults="sharedData"></control>
				</div>
			</div>

			<div class='footer'>
				<div class='buttons'>
					<span class="button-wrapper">
						<button @click="save" v-bind:disabled="!enableSave" type="button" v-localization="{key: 'dialogues.shareSavedSearch.share'}"></button>
					</span>
					<button class="cancel" @click="close" type="button" v-localization="{key: 'common.dictionary.buttons.cancel'}"></button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import helperMethods from '../ComponentSet/component-set-evaluation-context-helper.methods';
import {
	showToastOrInfoDialog, generateGuid, Create, Search, CreateBulk
} from '@acx-xms/data-functions/dist';
const ControlLookup = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './../Control/Lookup/control-lookup');
const Control = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '../Entity/control');

export default {
	name: 'share-entity-dialog',
	components: {
		ControlLookup,
		Control
	},
	props: { options: Object },
	data() {
		return {
			isLoading: false,
			texts: {},
			sharedData: [],
			userListTemplate: null,
			userLookupTemplate: null
		};
	},
	computed: {
		enableSave() {
			return !!this.grantAccessEntities.length || !!this.revokeAccessEntities.length;
		},
		grantAccessEntities() {
			return this.diffById(this.sharedData, this.initialData);
		},
		revokeAccessEntities() {
			return this.diffById(this.initialData, this.sharedData);
		}
	},
	created() {
		this.dataProvider = sc.classes.get('edge.dataProvider');
		this.localizations = sc.classes.get('localization.dataProvider');
		this.userEntity = helperMethods.eval(this.options.evaluationContext, this.options.userEntity);
		this.modalNamespace = helperMethods.eval(this.options.evaluationContext, this.options.modalNamespace);
		this.fork = sc.events.fork();
		this.fork.on(`${this.modalNamespace}.share.deleteContact`, this.removeEntity);
		this.isLoading = true;
		this.initialData = [];
		this.selectionRef = sc.classes.get('entityReference', this.options.selection);
		this.dataProvider.getAccess(this.selectionRef.logicalname, this.selectionRef.id)
			.done(({ PrincipalAccess }) => {
				const systemUserIds = PrincipalAccess.map(accessItem => {
					if (!accessItem.Privileges || !accessItem.Privileges.includes('Share')) {
						return null;
					}
					return accessItem.Principal.id;
				}).filter(Boolean);
				if (systemUserIds && systemUserIds.length) {
					Search(this.userEntity, [
						sc.classes.get('offsetSize.filter', 1000),
						sc.classes.get('orderBy.filter', {
							logicalName: 'fullname',
							query: false
						}).fillQuery(),
						sc.classes.get('termFacet.filter', {
							logicalName: 'systemuserid.id',
							query: systemUserIds
						}).fillQuery(),
						sc.classes.get('selectedFields.filter', [
							{ logicalname: 'account' },
							{ logicalname: 'contactsystemuseridsystemuser.avatarid.id' },
							{ logicalname: 'email' },
							{ logicalname: 'firstname' },
							{ logicalname: 'fullname' },
							{ logicalname: 'jobtitle' },
							{ logicalname: 'lastname' },
							{ logicalname: 'recordstate.id' },
							{ logicalname: 'salutation' },
							{ logicalname: 'systemuserid' }
						]).fillQuery()
					]).then(({ Results }) => {
						this.sharedData = Results;
						this.initialData = [...Results];
					}).finally(() => { this.isLoading = false; });
				} else {
					this.sharedData = [];
					this.initialData = [];
					this.isLoading = false;
				}
			})
			.fail((err) => {
				console.error(err);
				this.isLoading = false;
			});
		sc.classes.get('entityConfiguration.dataProvider')
			.getTemplate(this.userEntity, this.options.userListTemplate.usage, this.options.userListTemplate.subtype)
			.done(template => {
				this.userListTemplate = template && template.content;
			});
		sc.classes.get('entityConfiguration.dataProvider')
			.getTemplate(this.userEntity, this.options.userLookupTemplate.usage, this.options.userLookupTemplate.subtype)
			.done(template => {
				this.userLookupTemplate = template;
			});
	},
	beforeDestroy() {
		this.fork && this.fork.dispose();
	},
	methods: {
		getLookupOptions() {
			return {
				placeholder: '',
				fetchData: (searchQuery, startFrom, pageSize) => {
					startFrom = startFrom || 0;
					pageSize = pageSize || 1000;
					const filters = [
						sc.classes.get('offsetFrom.filter', startFrom),
						sc.classes.get('offsetSize.filter', pageSize),
						sc.classes.get('termFacet.filter', {
							logicalName: 'systemuserid.id',
							query: [null],
							negative: true
						}).fillQuery(),
						sc.classes.get('termFacet.filter', {
							logicalName: 'serviceuser',
							query: [true],
							negative: true
						}).fillQuery(),
						sc.classes.get('termFacet.filter', {
							logicalName: 'recordstate.id',
							query: ['8d113fa8-3015-4060-a107-14cedcd19dd3']
						}).fillQuery(),
						sc.classes.get('selectedFields.filter', [
							{ logicalname: 'account' },
							{ logicalname: 'contactsystemuseridsystemuser.avatarid.id' },
							{ logicalname: 'email' },
							{ logicalname: 'firstname' },
							{ logicalname: 'fullname' },
							{ logicalname: 'jobtitle' },
							{ logicalname: 'lastname' },
							{ logicalname: 'recordstate.id' },
							{ logicalname: 'salutation' },
							{ logicalname: 'systemuserid' }
						]).fillQuery()
					];
					if (searchQuery) {
						filters.push(sc.classes.get('field.filter', {
							logicalName: '_all',
							query: searchQuery
						}));
					}
					return Search(this.userEntity, filters).then(({ Results, Total }) => {
						return {
							total: Total,
							template: this.userLookupTemplate,
							items: Results.map(res => {
								return {
									displayName: res.Name,
									data: res
								};
							})
						};
					});
				},
				singleSelectionOnArray: this.selectEntity,
				template: this.userLookupTemplate
			};
		},
		selectEntity(item) {
			if (item && item.data && !this.sharedData.some(result => item.data.Id === result.Id)) {
				this.sharedData.push(item.data);
			}
		},
		removeEntity(entity) {
			this.sharedData = this.sharedData.filter(item => item.Id !== entity.id);
		},

		async getCurrentContact() {
			const userInfo = await sc.classes.get('authentication').getUserInfo();
			const contacts = await Search(['contact'], [
				sc.classes.get('offsetSize.filter', 1).fillQuery(),
				sc.classes.get('termFacet.filter', {
					logicalName: 'systemuserid.id',
					query: [userInfo.Id]
				}).fillQuery(),
				sc.classes.get('selectedFields.filter', [
					{ logicalname: 'fullname' }
				]).fillQuery()
			]);

			if (contacts.Results.length) {
				return contacts.Results[0];
			}
		},

		async save() {
			this.isLoading = true;
			const shareRelatedEntities = this.options.shareRelatedEntities;
			let relatedRecords = [];
			if (shareRelatedEntities) {
				relatedRecords = await Search(shareRelatedEntities.relatedEntityName, [
					sc.classes.get('offsetSize.filter', 10000),
					sc.classes.get('termFacet.filter', {
						logicalName: shareRelatedEntities.relatedEntityFilterLogicalName,
						query: [this.options.evaluationContext.entity.id]
					}).fillQuery(),
					sc.classes.get('selectedFields.filter', []).fillQuery()
				]);
				relatedRecords = relatedRecords.Results;
			}
			for (const entity of this.grantAccessEntities) {
				if (entity.Source.systemuserid) {
					const grantAccessRecords = [];
					grantAccessRecords.push({
						id: generateGuid(),
						method: 'POST',
						url: `${this.selectionRef.logicalname}/${this.selectionRef.id}/GrantAccess`,
						body: {
							SyncProcessMode: 'Brief',
							PrincipalType: 'systemuser',
							PrincipalId: entity.Source.systemuserid.id,
							AccessRights: ['Read', 'Share']
						}
					});
					if (relatedRecords.length) {
						for (const record of relatedRecords) {
							grantAccessRecords.push({
								id: generateGuid(),
								method: 'POST',
								url: `${record.Type}/${record.Id}/GrantAccess`,
								body: {
									SyncProcessMode: 'Brief',
									PrincipalType: 'systemuser',
									PrincipalId: entity.Source.systemuserid.id,
									AccessRights: ['Read', 'Share']
								}
							});
						}
					}

					try {
						await CreateBulk(grantAccessRecords, { async: true });
						if (this.options.sendNotification) {
							const currentContact = await this.getCurrentContact();

							if (currentContact) {
								await Create('notification', {
									userInfo: JSON.stringify({
										relatedRecord: {
											id: this.selectionRef.id,
											logicalname: this.selectionRef.logicalname
										},
										content: {
											contact: {
												id: currentContact.Id,
												logicalname: currentContact.Type
											}
										}
									}),

									headings: { 'en-us': 'Shared Saved Search' },
									recepientid: {
										id: entity.Source.systemuserid.id,
										logicalname: 'systemuser'
									},
									contents: { 'en-us': `${currentContact.Source.fullname} has shared ${this.selectionRef.name} Saved Search with you.` },
									notificationtype: {
										id: '8c7f1c12-824e-4804-8a37-910630bb77af',
										logicalname: 'lookupnotificationtype'

									}
								});
							}
						}
					} catch (err) {
						console.error(err);
					}
				}
			}
			for (const entity of this.revokeAccessEntities) {
				if (entity.Source.systemuserid) {
					const revokeAccessRecords = [];
					revokeAccessRecords.push({
						id: generateGuid(),
						method: 'DELETE',
						url: `${this.selectionRef.logicalname}/${this.selectionRef.id}/RevokeAccess`,
						body: {
							SyncProcessMode: 'Brief',
							PrincipalType: 'systemuser',
							PrincipalId: entity.Source.systemuserid.id,
							AccessRights: ['Read', 'Share']
						}
					});
					if (relatedRecords.length) {
						for (const record of relatedRecords) {
							revokeAccessRecords.push({
								id: generateGuid(),
								method: 'DELETE',
								url: `${record.Type}/${record.Id}/RevokeAccess`,
								body: {
									SyncProcessMode: 'Brief',
									PrincipalType: 'systemuser',
									PrincipalId: entity.Source.systemuserid.id,
									AccessRights: ['Read', 'Share']
								}
							});
						}
					}
					try {
						await CreateBulk(revokeAccessRecords, { async: true });
					} catch (err) {
						console.error(err);
					}
				}
			}
			await showToastOrInfoDialog({
				toastMessageKey: this.options.toastMessageKey,
				informationDialogTextKey: this.options.informationDialogTextKey,
				options: { context: this }
			});
			this.isLoading = false;
			this.close();
		},
		close() {
			this.$parent.$emit('close');
		},
		diffById(arr1, arr2) {
			return arr1.filter(a => !arr2.find(b => a.Id === b.Id));
		}
	}

};
</script>
<style src="./saved-search-dialog.less" scoped></style>
<style>
	.share-entity-dialog .table-cell:last-child {
		text-align: right;
	}

	.share-entity-dialog .no-results-message .table-cell {
		text-align: center;
	}

	.share-entity-dialog .table-cell:last-child .icon-wrapper {
		cursor: pointer;
	}
</style>
