var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sc-ndaFileUpload" }, [
    _c(
      "div",
      { staticClass: "nda-file-upload-wrapper row" },
      [
        !_vm.disableFileLookupEditor
          ? _c("div", { staticClass: "input-radio-wrapper" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.isExisting,
                    expression: "isExisting"
                  },
                  {
                    name: "data-attr",
                    rawName: "v-data-attr",
                    value: {
                      title: _vm.dataAttr.title,
                      $type: "dialog.input.radio"
                    },
                    expression:
                      "{title: dataAttr.title, $type: 'dialog.input.radio'}"
                  }
                ],
                attrs: { type: "radio" },
                domProps: {
                  value: false,
                  checked: _vm._q(_vm.isExisting, false)
                },
                on: {
                  change: function($event) {
                    _vm.isExisting = false
                  }
                }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("control-file-upload", {
          directives: [
            {
              name: "data-attr",
              rawName: "v-data-attr",
              value: {
                title: _vm.dataAttr.title,
                $type: "dialog.input.upload"
              },
              expression:
                "{title: dataAttr.title, $type: 'dialog.input.upload'}"
            }
          ],
          key: _vm.isExisting,
          staticClass: "control-wrapper",
          class: { invalid: !_vm.isExisting && !_vm.isValid },
          attrs: {
            showRequiredMark: _vm.showRequiredMark,
            evaluationContext: _vm.evaluationContext,
            options: _vm.uploaderOptions
          },
          model: {
            value: _vm.uploadedFileName,
            callback: function($$v) {
              _vm.uploadedFileName = $$v
            },
            expression: "uploadedFileName"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    !_vm.disableFileLookupEditor
      ? _c(
          "div",
          { staticClass: "nda-file-select-document-wrapper row" },
          [
            _c("div", { staticClass: "input-radio-wrapper" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.isExisting,
                    expression: "isExisting"
                  },
                  {
                    name: "data-attr",
                    rawName: "v-data-attr",
                    value: {
                      title: _vm.dataAttr.title,
                      $type: "dialog.input.radio"
                    },
                    expression:
                      "{title: dataAttr.title, $type: 'dialog.input.radio'}"
                  }
                ],
                attrs: { type: "radio" },
                domProps: {
                  value: true,
                  checked: _vm._q(_vm.isExisting, true)
                },
                on: {
                  change: function($event) {
                    _vm.isExisting = true
                  }
                }
              })
            ]),
            _vm._v(" "),
            _vm.componentOptions
              ? _c("control-folder-tree-view-editor", {
                  staticClass: "control-wrapper",
                  attrs: {
                    invalid: !_vm.isValid && _vm.isExisting,
                    visible: _vm.isExisting,
                    showRequiredMark: _vm.showRequiredMark,
                    evaluationContext: _vm.evaluationContext,
                    options:
                      _vm.componentOptions && _vm.componentOptions.component[0]
                  },
                  on: { existingDocument: _vm.setExisting }
                })
              : _vm._e()
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }