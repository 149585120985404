<template>
	<div class="dialog-content-wrapper">
		<div class="loading-overlay large" v-show="isLoading" />
		<div class="save-search-dialog">
			<div class="dialog-container">
				<div v-localization="{key: 'common.dictionary.createNewOrAdd'}" class="title p4" />
				<div class="dialog-content">
					<div class="new-search">
						<div class="radio">
							<input
								v-model="createNew"
								v-data-attr="{title: options.params.title, $type: 'dialog.input.radio'}"
								type="radio"
								:value="true"
								@change="clearInputs"
							/>
						</div>
						<input
							v-model.trim="newSearchName"
							v-data-attr="{title: options.params.title, $type: 'dialog.input'}"
							:disabled="!createNew"
							:placeholder="newPlaceholderText"
							class="new-search-name sc-input p1"
							type="text"
						/>
					</div>
					<div class="existing-search">
						<div class="radio">
							<input
								v-model="createNew"
								v-data-attr="{title: options.params.title, $type: 'dialog.input.radio'}"
								:value="false"
								type="radio"
								@change="clearInputs"
							/>
						</div>
						<ControlLookupEditor
							v-model="selectedSearchValue"
							v-data-attr="{title: options.params.title, $type: 'dialog.input.lookup'}"
							:evaluationContext="evaluationContext"
							:options="getLookupOptions()"
						/>
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="buttons">
					<span class="button-wrapper">
						<button
							v-localization="{key: 'common.dictionary.buttons.save'}"
							v-data-attr="{title: options.params.title, $type: 'dialog.button.submit'}"
							:disabled="!saveEnabled"
							type="button"
							@click="save"
						/>
					</span>
					<button
						v-localization="{key: 'common.dictionary.buttons.cancel'}"
						v-data-attr="{title: options.params.title, $type: 'dialog.button.cancel'}"
						class="cancel"
						type="button"
						@click="cancel"
					/>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
/* eslint no-empty: 0 */
import { GetUserInfo } from '@/Data/Auth/authentication-service';
import {
	Create, Search, SendRequest, Get, getFullRecordLink
} from '@acx-xms/data-functions/dist';
const ControlLookupEditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Lookup/control-lookup-editor');

export default {
	name: 'add-to-staticlist-dialog',
	components: { ControlLookupEditor },
	props: { options: Object },
	data() {
		return {
			isLoading: false,
			newSearchName: null,
			existingSearch: null,
			createNew: true,
			evaluationContext: this.options.evaluationContext,
			selectedSearchValue: null,
			newPlaceholderText: null
		};
	},
	computed: {
		saveEnabled() {
			return (this.createNew && this.newSearchName) || (!this.createNew && this.selectedSearch);
		},
		selectedSearch() {
			return this.selectedSearchValue && sc.classes.get('entityReference', this.selectedSearchValue.data);
		}
	},
	async created() {
		this.localizations = sc.classes.get('localization.dataProvider');
		this.newPlaceholderText = await this.localizations.getLabelForCurrentLanguage('dialogues.saveSearch.nameForNew');
		this.placeholder = await this.localizations.getLabelForCurrentLanguage('dialogues.saveSearch.selectExisting');
		this.systemuser = await GetUserInfo();
	},
	methods: {
		async save() {
			this.isLoading = true;
			let staticlistRef;
			if (this.createNew) {
				const record = await Create('staticlist',
					{
						name: this.newSearchName,
						membertype: {
							id: '4d8a18bd-ac8a-428b-90c6-7ffe70f8034a',
							logicalname: 'lookupmembertype'
						}
					}
				);
				staticlistRef = sc.classes.get('entityReference', record);
			} else if (this.selectedSearch) {
				staticlistRef = this.selectedSearch;
			}
			try {
				const records = [];
				let createdCount = 0;
				const selection = this.options.params.selection;
				// add related to availability listing. The only possible case for selecting availability without related listing - availability inline action
				if (selection.length === 1 && (selection[0].logicalname || selection[0].Type === 'availability')) {
					const listing = await Search('listing',
						[
							sc.classes.get('offsetSize.filter', 1),
							sc.classes.get('termFacet.filter', {
								logicalName: 'availabilities.availabilityid',
								query: [selection[0].id || selection[0].Id]
							}).fillQuery(),
							sc.classes.get('selectedFields.filter', []).fillQuery()
						]
					);
					if (listing.Results.length) {
						const existing = await Search('staticlistmember',
							[
								sc.classes.get('termFacet.filter', {
									logicalName: 'staticlist.id',
									query: [staticlistRef.id]
								}).fillQuery(),
								sc.classes.get('termFacet.filter', {
									logicalName: 'entityreference.id',
									query: [listing.Results[0].Id]
								}).fillQuery(),
								sc.classes.get('selectedFields.filter', []).fillQuery()
							]
						);
						if (existing.Total === 0) {
							selection.push(listing.Results[0]);
						}
					}
				}
				await Promise.all(selection.map(async (member) => {
					const memberRef = sc.classes.get('entityReference', member);
					const existing = await Search('staticlistmember',
						[
							sc.classes.get('termFacet.filter', {
								logicalName: 'availabilities.availabilityid',
								query: [staticlistRef.id]
							}).fillQuery(),
							sc.classes.get('termFacet.filter', {
								logicalName: 'entityreference.id',
								query: [memberRef.id]
							}).fillQuery()
						]
					);
					if (existing.Total === 0) {
						createdCount++;
						records.push({
							staticlist: {
								id: staticlistRef.id,
								logicalname: 'staticlist'
							},
							entityreference: memberRef,
							name: memberRef.name,
							type: 'staticlistmember'
						});
					}
				}));
				if (records.length) {
					SendRequest('/api/MarketingList/CreateMembers', 'POST', records);
				}
				this.isLoading = false;
				if (createdCount > 0) {
					const toastText = await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('common.dictionary.itemsAddedToStaticList', [createdCount, staticlistRef.name]);
					this.$root.$emit('toast.open', toastText);
				}

				if (this.options.params.refreshNamespace) {
					this.$root.$emit(this.options.params.refreshNamespace + '.searching');
				}
				this.close();
			} catch (error) {
				this.errorhandler(error);
			}
		},
		cancel() {
			this.close();
		},
		close() {
			this.$parent.$emit('close');
		},
		getLookupOptions() {
			return {
				placeholder: this.placeholder,
				disabled: this.createNew,
				fetchData: (searchQuery, startFrom, pageSize) => {
					startFrom = startFrom || 0;
					pageSize = pageSize || 1000;
					const filters = [
						sc.classes.get('offsetFrom.filter', startFrom),
						sc.classes.get('offsetSize.filter', pageSize),
						sc.classes.get('termFacet.filter', {
							logicalName: 'ownerid.id',
							query: [this.systemuser.systemuserid]
						}).fillQuery(),
						sc.classes.get('termFacet.filter', {
							logicalName: 'recordstate.id',
							query: ['8d113fa8-3015-4060-a107-14cedcd19dd3']
						}).fillQuery(),
						sc.classes.get('termFacet.filter', {
							logicalName: 'membertype.id',
							query: ['4d8a18bd-ac8a-428b-90c6-7ffe70f8034a']
						}).fillQuery(),
						sc.classes.get('selectedFields.filter', []).fillQuery()
					];
					if (searchQuery) {
						filters.push(sc.classes.get('field.filter', {
							logicalName: '_all',
							query: searchQuery
						}));
					}
					return Search('staticlist', filters)
						.then(({ Results, Total }) => {
							return {
								total: Total,
								items: Results.map(res => {
									return {
										displayName: res.Name,
										data: res
									};
								})
							};
						});
				}
			};
		},
		clearInputs() {
			this.newSearchName = '';
			this.selectedSearchValue = null;
		},
		async errorhandler(error) {
			this.isLoading = false;
			if (typeof error === 'object' && error.Code === 'UniqueConstraintViolation') {
				const { metadata, errorHyperlinked, fieldsToSkip = [] } = await this.configDataProvider.fetchEntity(this.logicalName);
				const metaFieldsObj = metadata.detail.fields;
				const entityFieldsObj = error.Detail.Entity;
				const errorFields = Object.keys(entityFieldsObj).map(field => {
					return (metaFieldsObj[field].name && metaFieldsObj[field].name.base) ? metaFieldsObj[field].name.base : this.logicalName;
				}).filter(item => fieldsToSkip.indexOf(item)).join(', ');
				try {
					const { Name: name } = await Get(this.logicalName, error.Detail.DuplicateId);
					let duplicateRecord = name;
					let needParsing = true;
					if (errorHyperlinked) {
						const recordHref = getFullRecordLink(this.$route, {
							id: error.Detail.DuplicateId,
							logicalname: this.logicalName
						});
						duplicateRecord = `<a href=${recordHref} target="_blank">${name}</a>`;
						needParsing = false;
					}
					const customError = await this.localization.localize('dialogues.duplicateError', [errorFields, duplicateRecord]);
					sc.utils.errorMessage.byMessage(customError, '', null, needParsing);
				} catch {
					sc.utils.errorMessage.byResponse(error);
				}
			} else if (error && error.disableErrorDialog) {

			} else {
				sc.utils.errorMessage.byResponse(error);
			}
		}
	}

};
</script>
<style src="./add-to-staticlist-dialog.less" scoped></style>
