var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "control-manage-notifications" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("Manage Notifications")]),
      _vm._v(" "),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading,
            expression: "isLoading"
          }
        ],
        staticClass: "loading-overlay large"
      }),
      _vm._v(" "),
      _vm.isPushNotificationsEnabled
        ? [
            _vm._m(0),
            _vm._v(" "),
            _vm.groups.length
              ? _vm._l(_vm.groups, function(group) {
                  return _c("div", { key: group.id, staticClass: "body" }, [
                    _c("div", { staticClass: "type" }, [
                      _c("div", { staticClass: "icon" }, [
                        _c("svg", { staticClass: "svg-icon svg-20" }, [
                          _c("use", {
                            attrs: { "xlink:href": _vm.getIcon(group.id) }
                          })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "name" }, [
                        _vm._v(_vm._s(group.name))
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "items" },
                      _vm._l(group.subgroups, function(item) {
                        return _c(
                          "div",
                          { key: item.id, staticClass: "data" },
                          [
                            _c("div", { staticClass: "name" }, [
                              _vm._v(_vm._s(item.name))
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "desc" }, [
                              _vm._v(_vm._s(item.description))
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "checkbox" },
                              [
                                _c("checkbox", {
                                  attrs: { disabled: item.readonly },
                                  on: {
                                    input: function($event) {
                                      _vm.addToList(item)
                                    }
                                  },
                                  model: {
                                    value: item.enabled,
                                    callback: function($$v) {
                                      _vm.$set(item, "enabled", $$v)
                                    },
                                    expression: "item.enabled"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      })
                    )
                  ])
                })
              : _vm._e()
          ]
        : [
            _c("div", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: {
                    key: "notificationPanel.disabledNotificationMessage",
                    args: ["\r\n"]
                  },
                  expression:
                    "{ key: 'notificationPanel.disabledNotificationMessage', args: ['\\r\\n'] }"
                }
              ],
              staticClass: "notifications-disabled"
            })
          ]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "type" }, [_vm._v("Type")]),
      _vm._v(" "),
      _c("div", { staticClass: "title-wrapper" }, [
        _c("div", { staticClass: "name" }, [_vm._v("Name")]),
        _vm._v(" "),
        _c("div", { staticClass: "desc" }, [_vm._v("Description")]),
        _vm._v(" "),
        _c("div", { staticClass: "checkbox" }, [_vm._v("Allow")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }