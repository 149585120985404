import Vue from 'vue';

function calcDims(element, arrowNext, arrowPrev, tabsContainer, tabs, isResize) {
	if (element.offsetHeight >= tabsContainer.scrollHeight) {
		arrowNext.style.display = 'none';
		arrowPrev.style.display = 'none';
		return;
	}
	if (isResize) {
		const activeTab = tabs.find(tab => tab.classList.contains('active'));
		activeTab && activeTab.scrollIntoView();
	}
	if (tabsContainer.scrollTop === 0) {
		arrowPrev.style.display = 'none';
	} else {
		arrowPrev.style.display = 'flex';
	}
	if (Math.ceil(tabsContainer.scrollTop) + element.offsetHeight >= tabsContainer.scrollHeight) {
		arrowNext.style.display = 'none';
	} else {
		arrowNext.style.display = 'flex';
	}
	if (!isResize) {
		const nextTab = tabs.find(tab => tab.offsetTop === Math.ceil(tabsContainer.scrollTop));
		nextTab && nextTab.click();
	}
}
let resizeHandler;
let orientationChangeCallback;
Vue.directive('scrollable-tabs', {
	inserted(element) {
		const arrowNext = element.querySelector('.next');
		const arrowPrev = element.querySelector('.prev');
		const tabsContainer = element.querySelector('.tabs-container');
		const tabs = Array.prototype.slice.call(element.querySelectorAll('.tab-title'));
		arrowNext.addEventListener('click', () => {
			arrowNext.style.display = 'none';
			tabsContainer.scrollTop += element.offsetHeight;
			calcDims(element, arrowNext, arrowPrev, tabsContainer, tabs);
		});
		arrowPrev.addEventListener('click', () => {
			tabsContainer.scrollTop -= element.offsetHeight;
			calcDims(element, arrowNext, arrowPrev, tabsContainer, tabs);
		});
		calcDims(element, arrowNext, arrowPrev, tabsContainer, tabs);
		resizeHandler = calcDims.bind(this, element, arrowNext, arrowPrev, tabsContainer, tabs, true);
		orientationChangeCallback = () => {
			const orientationChange = () => {
				resizeHandler();
				window.removeEventListener('resize', orientationChange);
			};
			window.addEventListener('resize', orientationChange);
		};
		window.addEventListener('orientationchange', orientationChangeCallback);
	},
	unbind() {
		window.removeEventListener('orientationchange', orientationChangeCallback);
		resizeHandler = null;
	}
});
