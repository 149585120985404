<template>
    <div class="dialog-step">
        <div v-if="statuses.finished" class="list-content">
            <div class="control-tableView inner-search-results">
                <div class="tableview-header">
                    <div class="cell first-column">
                        <span class="label-header" v-localization="{ key: 'import.recordsCreated'}"></span>
                    </div>
                    <div class="cell second-column">
                        <span class="label-header" v-localization="{ key: 'import.recordsUpdated'}"></span>
                    </div>
                    <div class="cell third-column">
                        <span class="label-header" v-localization="{ key: 'import.recordsSkipped'}"></span>
                    </div>
                </div>
                <div class="tableview-content">
                    <div class="content-scroll-wrapper">
                        <div class="row">
                            <div class="table-cell first-column">
                                <span class="label">{{statuses.created}}</span>
                            </div>
                            <div class="table-cell second-column">
                                <span class="label">{{statuses.updated}}</span>
                            </div>
                            <div class="table-cell third-column">
                                <span class="label">{{statuses.skipped}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="message-success">
                <span class="title-success" v-localization="{ key: 'import.congratulations'}"></span>
                <span class="title-success" v-localization="{ key: 'import.importComplete'}"></span>
            </div>
        </div>
        <div class="footer">
            <div class="buttons">
                <button
                    type="button"
                    class="cancel"
                    @click="close"
                    v-localization="{key: 'import.close'}"
                    v-if="!options.finalStepButtonLabel"
                >
                </button>
                <button
                    type="button"
                    @click="close"
                    v-if="options.finalStepButtonLabel"
                >
                    {{options.finalStepButtonLabel}}
                </button>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint vue/no-unused-components: 0 */
import importProvider from '@/Data/DataProviders/import.dataProvider';
const ControlTableView = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Table/control-table-view');
export default {
	name: 'import-step-four',
	props: {
		step: Number,
		options: Object
	},
	components: { ControlTableView },
	data() {
		return {
			statuses: {
				created: 0,
				updated: 0,
				skipped: 0,
				finished: false
			},
			isImported: false
		};
	},
	beforeMount() {
		this.getStatuses();
	},
	methods: {
		async getStatuses() {
			const statuses = await importProvider.importStatus(this.options.fileGuid, this.options.total);
			if (statuses.finished) {
				if (statuses.hasServerError) {
					this.$root.$emit(
						this.options.failureOptions.event,
						this.options.failureOptions.message
					);
				} else {
					this.statuses = statuses;
					const importedRecords = await importProvider.getImportedRecords(this.options.selectedEntity, this.options.fileGuid);
					this.options.refreshEvent && this.$root.$emit(this.options.refreshEvent);
					this.$root.$emit('createMarketingList.importedContacts', importedRecords);
				}
			}
		},
		close() {
			this.$parent.$emit('close');
			this.proceedWithMLCreation();
		},
		proceedWithMLCreation() {
			this.$root.$emit('proceedWithMLCreation');
		}
	}
};
</script>
<style src="@/Components/Control/Table/control-table-view.less" scoped></style>
<style src="@/Components/Control/Table/control-table-view-row.less" scoped></style>
<style src="./import-steps.less" scoped></style>
