var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        {
          class: [
            "row custom-row",
            {
              selected: _vm.isSelected,
              odd: _vm.isOdd,
              noSelection: !_vm.allowSingleSelection
            }
          ]
        },
        [
          _vm.options.allowSelection &&
          !_vm.isRecordToDeactivate &&
          _vm.allowSingleSelection
            ? _c("VirtualCheckbox", {
                attrs: {
                  record: _vm.rowItem,
                  stateNamespace: _vm.stateNamespace,
                  isParent: _vm.options.isParent,
                  syncSelection: _vm.options.syncSelection,
                  singleSelection: _vm.options.singleSelection,
                  evaluationContext: _vm.evaluationContext,
                  cssClass: _vm.allChildsSelectionClass
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.filteredContent, function(cell, index) {
            return _c(
              "div",
              {
                key: index,
                class: [
                  "table-cell",
                  cell.cssClass ? _vm.getContext().eval(cell.cssClass) : ""
                ],
                style: { width: cell.width }
              },
              [
                _c("ControlTableViewColumn", {
                  attrs: { options: cell, evaluationContext: _vm.getContext() }
                })
              ],
              1
            )
          }),
          _vm._v(" "),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.isLoading || (!_vm.inactive && _vm.isRecordToDeactivate),
                expression: "isLoading || (!inactive && isRecordToDeactivate)"
              }
            ],
            staticClass: "loading-overlay"
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }