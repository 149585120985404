var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "delete-dialog-wrapper" }, [
    _c("div", { staticClass: "delete-dialog-message" }, [
      _vm.options.params.customConfirmRemovingMessage
        ? _c("span", { staticClass: "p4" }, [
            _vm._v(_vm._s(_vm.options.params.customConfirmRemovingMessage))
          ])
        : _c("span", {
            directives: [
              {
                name: "localization",
                rawName: "v-localization",
                value: { key: "common.dictionary.confirmRemoving" },
                expression: "{ key: 'common.dictionary.confirmRemoving'}"
              }
            ],
            staticClass: "p4"
          })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c("button", {
        directives: [
          {
            name: "localization",
            rawName: "v-localization",
            value: { key: "common.dictionary.buttons.cancel" },
            expression: "{ key: 'common.dictionary.buttons.cancel'}"
          },
          {
            name: "data-attr",
            rawName: "v-data-attr",
            value: { title: _vm.options.title, $type: "dialog.button.submit" },
            expression: "{title: options.title, $type: 'dialog.button.submit'}"
          }
        ],
        staticClass: "cancel",
        on: { click: _vm.close }
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          directives: [
            {
              name: "data-attr",
              rawName: "v-data-attr",
              value: {
                title: _vm.options.title,
                $type: "dialog.button.submit"
              },
              expression:
                "{title: options.title, $type: 'dialog.button.submit'}"
            }
          ],
          attrs: { type: "button" },
          on: { click: _vm.onSubmit }
        },
        [
          _vm.options.params.customConfirmText
            ? _c("span", [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.options.params.customConfirmText) +
                    "\n\t\t\t"
                )
              ])
            : _c("span", {
                directives: [
                  {
                    name: "localization",
                    rawName: "v-localization",
                    value: { key: "common.dictionary.buttons.delete" },
                    expression: "{ key: 'common.dictionary.buttons.delete'}"
                  }
                ]
              })
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ],
      staticClass: "loading-overlay"
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }