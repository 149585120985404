var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.disableCustomItems
        ? _c("control-lookup", {
            attrs: {
              evaluationContext: _vm.evaluationContext,
              options: _vm.getLookupOptions()
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "filter-content panel-content" }, [
        _vm.isLoading
          ? _c("div", { staticClass: "loading-overlay" })
          : _c(
              "div",
              {
                directives: [
                  {
                    name: "perfectscroll",
                    rawName: "v-perfectscroll",
                    value: { enable: true },
                    expression: "{ enable: true }"
                  }
                ],
                staticClass: "filter-list-holder"
              },
              [
                _vm.facets.length
                  ? _c(
                      "ul",
                      { staticClass: "filter-list" },
                      _vm._l(_vm.facets, function(facet, index) {
                        return _c(
                          "li",
                          {
                            directives: [
                              {
                                name: "data-attr",
                                rawName: "v-data-attr",
                                value: {
                                  options: _vm.options,
                                  stateNamespace: facet.displayName
                                },
                                expression:
                                  "{ options, stateNamespace: facet.displayName }"
                              }
                            ],
                            key: facet.displayName + facet.value + index,
                            staticClass: "item",
                            attrs: { "data-bind": "visible: isVisible" }
                          },
                          [
                            _c("label", [
                              _c(
                                "div",
                                { staticClass: "facet-item-checkbox" },
                                [
                                  _c(
                                    "span",
                                    {
                                      class: [
                                        "checkbox bordered",
                                        { checked: facet.checked }
                                      ]
                                    },
                                    [
                                      _c(
                                        "svg",
                                        { staticClass: "svg-icon svg-12" },
                                        [
                                          _c("use", {
                                            staticClass:
                                              "layout-icons-checkbox-small-checked",
                                            attrs: {
                                              "xlink:href":
                                                "#layout-icons-checkbox-small-checked"
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: facet.checked,
                                        expression: "facet.checked"
                                      }
                                    ],
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      checked: Array.isArray(facet.checked)
                                        ? _vm._i(facet.checked, null) > -1
                                        : facet.checked
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = facet.checked,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                facet,
                                                "checked",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                facet,
                                                "checked",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(facet, "checked", $$c)
                                        }
                                      }
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  class: [
                                    "item-info",
                                    { selected: facet.checked }
                                  ]
                                },
                                [
                                  _c("div", { staticClass: "name" }, [
                                    _c(
                                      "span",
                                      { staticClass: "tooltip-wrapper" },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(facet.displayName) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "icon" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "icon-wrapper item-info-count"
                                      },
                                      [
                                        _c("i", [
                                          _vm._v("("),
                                          _c("i", [
                                            _vm._v(_vm._s(facet.count))
                                          ]),
                                          _vm._v(")")
                                        ])
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ])
                          ]
                        )
                      })
                    )
                  : _c("div", {
                      directives: [
                        {
                          name: "localization",
                          rawName: "v-localization",
                          value: { key: "filtersPanel.unavailable" },
                          expression: " { key: 'filtersPanel.unavailable' }"
                        }
                      ],
                      staticClass: "warning-message"
                    }),
                _vm._v(" "),
                _vm.options.isIncludeEmptyVisible
                  ? _c("include-empty", {
                      directives: [
                        {
                          name: "data-attr",
                          rawName: "v-data-attr",
                          value: {
                            options: _vm.options,
                            stateNamespace: "empty"
                          },
                          expression: "{ options, stateNamespace: 'empty' }"
                        }
                      ],
                      attrs: { enabled: _vm.enableIncludeEmpty },
                      model: {
                        value: _vm.includeEmpty,
                        callback: function($$v) {
                          _vm.includeEmpty = $$v
                        },
                        expression: "includeEmpty"
                      }
                    })
                  : _vm._e()
              ],
              1
            )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }