<template>
	<div>
		<control-lookup
			v-if="!disableCustomItems"
			:evaluationContext="evaluationContext"
			:options="getLookupOptions()"
		/>
		<div class="filter-content panel-content">
			<div v-if="isLoading" class="loading-overlay" />
			<div
				v-else
				v-perfectscroll="{ enable: true }"
				class="filter-list-holder"
			>
				<ul v-if="facets.length" class="filter-list">
					<li
						v-for="(facet, index) in facets"
						:key="facet.displayName + facet.value + index"
						v-data-attr="{ options, stateNamespace: facet.displayName }"
						class="item"
						data-bind="visible: isVisible"
					>
						<label>
							<div class="facet-item-checkbox">
								<span :class="['checkbox bordered', { checked: facet.checked }]">
									<svg class="svg-icon svg-12">
										<use xlink:href="#layout-icons-checkbox-small-checked" class="layout-icons-checkbox-small-checked" />
									</svg>
								</span>
								<input v-model="facet.checked" type="checkbox">
							</div>
							<div :class="['item-info', { selected: facet.checked}]">
								<div class="name">
									<span class="tooltip-wrapper">
										{{ facet.displayName }}
									</span>
								</div>
								<div class="icon">
									<div class="icon-wrapper item-info-count">
										<i>(<i>{{ facet.count }}</i>)</i>
									</div>
								</div>
							</div>
						</label>
					</li>
				</ul>
				<div
					v-else
					v-localization=" { key: 'filtersPanel.unavailable' }"
					class="warning-message"
				/>
				<include-empty
					v-if="options.isIncludeEmptyVisible"
					v-model="includeEmpty"
					v-data-attr="{ options, stateNamespace: 'empty' }"
					:enabled="enableIncludeEmpty"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import '@/Bindings/ui-attribute.directive';
import helperMethods from '@/Components/ComponentSet/component-set-evaluation-context-helper.methods';
import FilterInfoListsMixins from './filter.info.lists.mixins';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';
const IncludeEmpty = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './include-empty');
const ControlLookup = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Lookup/control-lookup');

export default {
	name: 'filter-info-panels-ids-list',
	mixins: [FilterInfoListsMixins],
	props: {
		options: Object,
		evaluationContext: Object,
		stateNamespace: String
	},
	data() {
		return {
			facets: [],
			isLoading: false,
			includeEmpty: false
		};
	},
	components: {
		IncludeEmpty,
		ControlLookup
	},
	created() {
		this.logicalName = helperMethods.eval(this.evaluationContext, this.options.logicalName);
		this.disableCustomItems = helperMethods.eval(this.evaluationContext, this.options.disableCustomItems);
		// todo be sure filter is used only for one entity
		this.entity = this.options.entities[0];

		// do we need computed here
		this.statistic = this.$store.getters[this.stateNamespace + '/getStatistic'](this.entity);
		this.facets = this.readStatistic(this.statistic);

		this.filterValue = this.$store.getters[this.stateNamespace + '/getFilterValue']({
			entity: this.entity,
			logicalName: this.logicalName
		});
		if (this.filterValue) {
			this.applyFilterValue(this.filterValue);

			this.includeEmpty = this.filterValue.data.includeEmpty;
		}
		this.$parent.$on(this.stateNamespace + 'resetFilter', this.resetFilter);
		this.entityName = helperMethods.eval(this.evaluationContext, this.options.entityLogicalName);
		if (this.facets.length > 0) {
			this.isLoading = true;
			const self = this;

			this.extendFacetsWithDisplayName(this.facets).done(function () {
				self.isLoading = false;
			});
		}
	},
	methods: {
		getLookupOptions() {
			const self = this;

			return {
				fetchData: function (searchQuery, startFrom, pageSize) {
					return self.searchAdditionalItems().then(function (termFacet) {
						const formFacets = self.formFacets(termFacet.Items, true);

						const fieldFilter = sc.classes.get('field.filter', {
							logicalName: '_all',
							query: searchQuery,
							additionalOptions: { kind: self.options.kind }
						});

						return self.extendFacetsWithDisplayName(formFacets, fieldFilter, startFrom, pageSize).then(function ({ extendedFacet, total }) {
							const items = extendedFacet.map(facetItem => {
								return {
									displayName: facetItem.displayName,
									data: termFacet.Items.find(item => item.Term === facetItem.value),
									formFacet: facetItem
								};
							});

							return {
								total,
								items,
								template: self.options.template
							};
						});
					});
				},
				singleSelectionOnArray: this.onSelectAdditionalItem
			};
		},

		extendFacetsWithDisplayName(facets, fieldFilter, startFrom, pageSize) {
			const self = this;
			const ids = facets.map(item => item.value);
			startFrom = startFrom || 0;
			pageSize = pageSize || 1000;

			const filters = [
				sc.classes.get('ids.filter', ids),
				sc.classes.get('offsetFrom.filter', startFrom),
				sc.classes.get('offsetSize.filter', pageSize),
				sc.classes.get('selectedFields.filter', [
					{ logicalname: 'name' },
					{ logicalname: 'fullname' }
				])
			];

			fieldFilter && filters.push(fieldFilter);

			return sc.classes.get('edge.dataProvider').search({
				filters,
				entities: [this.entityName]
			}).then(function (response) {
				const results = [];

				response.Results.forEach((result) => {
					const index = facets.findIndex((facet) => facet.value === result.Id);
					if (index >= 0) {
						if (self.options.entityField) {
							const localEval = createEvaluationContext(result);
							facets[index].displayName = helperMethods.eval(localEval, self.options.entityField);
						} else {
							facets[index].displayName = result.Name;
						}

						results.push(facets[index]);
					}
				});

				return {
					extendedFacet: results,
					total: response.Total
				};
			});
		}
	},
	async beforeDestroy() {
		const value = this.facets.filter((item) => {
			return item.checked;
		}).map((item) => {
			return item.value;
		});

		const hasValue = () => {
			return value.length;
		};
		const wasChanged = () => {
			return (!this.filterValue && hasValue()) ||
					(this.filterValue && !hasValue()) ||
					(this.filterValue && JSON.stringify(value) !== JSON.stringify(this.filterValue.data.items));
		};
		if (wasChanged()) {
			const filter = await this.getSearchFilter(this.logicalName);
			this.emitFilterChanged(filter);
		}
	}
};
</script>
<style src="./panels.less" scoped></style>
