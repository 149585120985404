<template>
	<div class="editor">
		<div class="body" v-perfectscroll="{enable: isScrollEnabled, onScroll: onScroll}">
			<template v-if="editorInitializationReady">
				<control	v-if="editorTemplate" :name="editorTemplate.$type"
							:contentProps.once="editorTemplate"
							:evaluationContext="controlContext"></control>
				<control	v-if="relatedEntityTemplate" :name="relatedEntityTemplate.$type"
							:contentProps.once="relatedEntityTemplate"
							:evaluationContext="relatedContext"></control>
			</template>
		</div>
		<div class="footer">
			<button class="cancel" @click="onCancel" v-data-attr="{ title: options.title, $type: 'dialog.button.close' }">Cancel</button>
			<button @click="onSubmit" v-data-attr="{ title: options.title, $type: 'dialog.button.submit' }">Save</button>
		</div>
		<div class="loading-overlay" v-show="isLoading"></div>
	</div>
</template>
<script>
import Control from '@/Components/Entity/control';
import {
	Create, Update, Delete
} from '@acx-xms/data-functions/dist';
import editorMixins from '@/Components/editor.mixins.js';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';
import { getBehavior } from './behaviors/index.js';

export default {
	name: 'editor',
	components: { Control },
	mixins: [editorMixins],
	props: { options: Object },
	data() {
		return {
			editorTemplate: null,
			relatedEntityTemplate: null,
			evaluationContext: this.options.evaluationContext || createEvaluationContext({}),
			controlContext: null,
			relatedEntityContext: null,
			isLoading: false,
			logicalName: null,
			subtype: null,
			entity: null,
			stateKey: null,
			forceCreate: !!this.options.forceCreate,
			isScrollEnabled: null,
			editorInitializationReady: false
		};
	},
	created() {
		this.configDataProvider = sc.classes.get('entityConfiguration.dataProvider');
		this.logicalName = this.options.logicalname && this.evaluationContext.eval(this.options.logicalname);
		this.subtype = this.options.subtype && this.evaluationContext.eval(this.options.subtype);
		this.entity = this.options.parameters && this.options.parameters.entity && { ...this.options.parameters.entity };
		this.localization = sc.classes.get('localization.dataProvider');
		this.mode = this.options.mode || '';
		this.isScrollEnabled = this.options.scroll;

		const overrideFields = this.options.parameters && (this.options.parameters.overrideFields || (this.options.parameters.predefinedFields &&
				this.options.parameters.predefinedFields.overrideFields)); // need for edit action
		const relatedEntityOverrideFields = this.options.parameters && this.options.parameters.relatedEntityOverrideFields;
		this.overrideFields = overrideFields ? (Array.isArray(overrideFields) ? overrideFields : [overrideFields]) : [];
		this.relatedEntityOverrideFields = relatedEntityOverrideFields ? (Array.isArray(relatedEntityOverrideFields) ? relatedEntityOverrideFields : [relatedEntityOverrideFields]) : [];
		this.predefinedAspectsFields = this.options.predefinedAspectsFields ? this.evaluationContext.eval(this.options.predefinedAspectsFields) : {};

		if (this.options.asyncSave) {
			this.asyncSaveoutsideResolve = null;
			this.asyncSaveoutsideReject = null;
			this.asyncSavePromise = new Promise((resolve, reject) => {
				this.asyncSaveoutsideResolve = resolve;
				this.asyncSaveoutsideReject = reject;
			});
		}
	},
	async mounted() {
		this.isLoading = true;
		await this.init();
		setTimeout(() => {
			this.editorInitializationReady = true;
			this.isLoading = false;
		}, 0);
	},
	methods: {
		onCancel() {
			this.close();
		},
		async onSubmit() {
			this.isLoading = true;
			const validationResult = await this.validate(this.stateKey);

			if (this.relatedEntityLogicalName) {
				const isRelatedValidationResult = await this.validate(this.relatedEntityStateKey, this.relatedEntityLogicalName);
				if (!isRelatedValidationResult.isValid) {
					validationResult.isValid = false;
					validationResult.message += isRelatedValidationResult.message;
				}
			}

			if (!validationResult.isValid) {
				this.isLoading = false;
				this.showValidationError(validationResult.message);
				return;
			}
			const entityData = this.$store.state[this.stateKey].entityData;
			const entityDataChanged = {}; let relatedEntityDataChanged = {};
			const layoutsRedirectToMain = this.options.parameters && this.options.parameters.layoutsRedirectToMain;

			if (this.relatedEntityStateKey) {
				// do we need file upload for related entity?
				const relatedEntity = this.$store.state[this.relatedEntityStateKey].entityData;
				if (relatedEntity && Object.keys(relatedEntity).length) {
					try {
						await this.processAspects('validate', relatedEntity, this.relatedEntityRef, this.relatedAspects, this.relatedContext, { layoutsRedirectToMain });
						await this.processAspects('beforeSave', relatedEntity, this.relatedEntityRef, this.relatedAspects, this.relatedContext, { layoutsRedirectToMain });

						let record = null;
						if (this.relatedEntityRef && this.relatedEntityRef.id) {
							this.relatedEntityChangedFields.forEach(field => {
								relatedEntityDataChanged[field] = relatedEntity[field];
							});

							relatedEntityDataChanged = handleRelatedSubfields(relatedEntity, relatedEntityDataChanged);

							if (relatedEntityDataChanged && Object.keys(relatedEntityDataChanged).length) {
								record = await Update(this.relatedEntityLogicalName, this.relatedEntityRef.id, relatedEntityDataChanged);
								this.notifyEntityChanged('entity.changed', record);
							}
						} else {
							record = await Create(this.relatedEntityLogicalName, relatedEntity);
							entityData[this.relatedEntityLogicalName + 'id'] = sc.classes.get('entityReference', record);
							this.notifyEntityChanged('entity.created', record);
						}
						if (record) {
							await this.processAspects('afterSave', record, this.relatedEntityRef, this.relatedAspects, this.relatedContext, { layoutsRedirectToMain });
							await this.processAspects('complete', record, this.relatedEntityRef, this.relatedAspects, this.relatedContext, { layoutsRedirectToMain });
							this.processAspects('async', record, this.relatedEntityRef, this.relatedAspects, this.relatedContext, { layoutsRedirectToMain });
						}
					} catch (error) {
						this.errorhandler(error);

						return;
					}
				}
			}
			if (entityData && Object.keys(entityData).length) {
				try {
					await this.processAspects('validate', entityData, this.entityRef, this.aspects, this.evaluationContext, { layoutsRedirectToMain });
					await this.processAspects('beforeSave', entityData, this.entityRef, this.aspects, createEvaluationContext(entityData), { layoutsRedirectToMain });
					let record = null;

					if (this.options.asyncSave) {
						this.$parent.$emit('minimize');
					}
					if (this.entity.id) {
						this.changedFields.forEach(field => {
							entityDataChanged[field] = entityData[field];
						});
						if (entityDataChanged && Object.keys(entityDataChanged).length) {
							record = await Update(this.logicalName, this.entity.id, entityDataChanged, this.file);
							this.notifyEntityChanged('entity.changed', this.entity);
						} else {
							this.close();
							return;
						}
					} else {
						try {
							record = await Create(this.logicalName, entityData, this.file);
							this.notifyEntityChanged('entity.created', record);
						} catch (e) {
							if (e.status === 400 && this.relatedEntityLogicalName) {
								await Delete(this.relatedEntityLogicalName, entityData[this.relatedEntityLogicalName + 'id'].id);
							}
							throw e;
						}
					}

					await this.processAspects('afterSave', record, this.entityRef, this.aspects, this.evaluationContext, { layoutsRedirectToMain });
					await this.processAspects('complete', record, this.entityRef, this.aspects, this.evaluationContext, { layoutsRedirectToMain });
					this.processAspects('async', record, this.entityRef, this.aspects, this.evaluationContext, { layoutsRedirectToMain });

					const refreshNamespace = this.options.parameters && (this.options.parameters.refreshNamespace ||
							(this.options.parameters.predefinedFields && this.options.parameters.predefinedFields.refreshNamespace));
					refreshNamespace && this.$root.$emit(refreshNamespace + '.searching');

					this.close();
					if (this.options.asyncSave) {
						this.asyncSaveoutsideResolve();
						this.options.asyncSaveToastMsgKey && this.$root.$emit('toast.open', this.options.asyncSaveToastMsgKey, 3000);
					}
				} catch (error) {
					this.errorhandler(error);

					if (this.options.asyncSave) {
						this.outsideResolve();
					}
				}
			} else {
				this.close();
			}
		},
		close() {
			this.$parent.$emit('close');
		},
		onScroll() {
			this.$root.$emit('lookupArea.scrolled');
		},
		hasWriteAccess(currentRights) {
			return currentRights ? currentRights.indexOf('WriteAccess') > -1 : true;
		},
		async init() {
			if (!this.entity) {
				this.entity = await sc.classes.get('entityConfiguration.dataProvider').produceEntity(this.logicalName);
			} else if (this.options.asyncSave) {
				const stateName = this.checkIfModuleIsRegistered(this.logicalName);

				if (stateName) {
					const promise = this.$store.state[stateName].asyncSavePromise;
					await promise;
				}
			}
			this.stateKey = this.registerModule(this.logicalName);
			this.$store.commit(this.getStateKey(this.logicalName) + '/setAsyncSavePromise', this.asyncSavePromise);

			let recordDetails = null;
			if (this.options.relatedEntityLogicalName) {
				this.relatedEntityLogicalName = this.evaluationContext.eval(this.options.relatedEntityLogicalName);
			}
			if (this.entity.id) {
				recordDetails = await sc.classes.get('edge.dataProvider').get(this.entity.logicalname, this.entity.id, false);

				if (this.relatedEntityLogicalName) {
					const relatedEntityRef = sc.utils.findProperty(recordDetails.Source, this.relatedEntityLogicalName + 'id', true) || null;
					await this.initRelatedEntity(relatedEntityRef);
				}

				if (this.overrideFields) {
					this.overrideFields.forEach(field => {
						recordDetails.Detail[field.name] = field.value;
						recordDetails.Source[field.name] = field.value;
					});
				}

				this.controlContext = createEvaluationContext(recordDetails);

				if (recordDetails && this.hasWriteAccess(recordDetails.Detail.CurrentUserRights)) {
					this.$store.commit(this.stateKey + '/initEntity', recordDetails.Source || recordDetails);
				} else {
					this.close();
					sc.events.emit('dialog.error', {
						title: this.localization.localize('entity.editor.validationError'),
						message: this.localization.localize('dialogs.editAccessDenied')
					});
				}
			} else {
				this.controlContext = createEvaluationContext(this.entity);
				this.$store.commit(this.stateKey + '/initEntity', {});
				if (this.relatedEntityLogicalName) {
					await this.initRelatedEntity();
				}
			}

			// add predefined (e.g. parentRecord) fields to store
			if (this.options.parameters && this.options.parameters.predefinedFields) {
				Object.keys(this.options.parameters.predefinedFields).forEach(async (key) => {
					if (key.toLowerCase() !== 'overridefields') {
						await this.$store.dispatch(this.stateKey + '/setField', {
							name: key,
							value: this.options.parameters.predefinedFields[key]
						});
					}
				});
			}
			if (this.forceCreate) {
				this.entity.id = null;
				if (this.relatedEntityRef) {
					this.relatedEntityRef.id = null;
				}
			}

			this.editorTemplate = await this.configDataProvider.getTemplate(this.logicalName, 'edit', this.subtype);
			const entityConfig = await this.configDataProvider.fetchEntity(this.logicalName);
			const relatedEntityConfig = await this.configDataProvider.fetchEntity(this.relatedEntityLogicalName);
			this.entityRef = sc.classes.get('entityReference', this.entity);
			const editorBehaviorKey = `${this.mode}editorBehavior`;

			this.aspects = [];
			const entityData = this.$store.state[this.stateKey] && this.$store.state[this.stateKey].entityData;
			const relatedEntityData = this.$store.state[this.relatedEntityStateKey] && this.$store.state[this.relatedEntityStateKey].entityData;

			const behaviors = [];
			entityConfig && entityConfig[editorBehaviorKey] && entityConfig[editorBehaviorKey].forEach(item => {
				behaviors.push(getBehavior(item.$type, {
					...entityData,
					...item
				}));
			});

			const relatedEntityBehaviors = [];
			relatedEntityConfig && relatedEntityConfig[editorBehaviorKey].forEach(item => relatedEntityBehaviors.push(getBehavior(item.$type, {
				...relatedEntityData,
				...item
			})));

			this.aspects = [];
			this.relatedAspects = [];

			//init default behavior
            let defaultCreateBehavior = !this.entity.id && getBehavior(`behavior.${this.logicalName}.onCreate`);
            let defaultUpdateBehavior = this.entity.id && getBehavior(`behavior.${this.logicalName}.onUpdate`);
			defaultCreateBehavior && behaviors.push(defaultCreateBehavior);
			defaultUpdateBehavior && behaviors.push(defaultUpdateBehavior);

			behaviors.forEach((b) => {
				this.aspects.push.apply(this.aspects, b.installAspects(entityData));
			});
			relatedEntityBehaviors.forEach((b) => {
				this.relatedAspects.push.apply(this.relatedAspects, b.installAspects(relatedEntityData));
			});
		},
		async initRelatedEntity(relatedEntityRef) {
			let relatedEntity = null;

			this.relatedEntityStateKey = this.registerModule(this.relatedEntityLogicalName);

			if (!relatedEntityRef) {
				relatedEntity = await sc.classes.get('entityConfiguration.dataProvider').produceEntity(this.relatedEntityLogicalName);
				this.relatedEntityRef = sc.classes.get('entityReference', relatedEntity);
				this.$store.commit(this.relatedEntityStateKey + '/initEntity', {});
			} else {
				this.relatedEntityRef = relatedEntityRef;
				relatedEntity = await sc.classes.get('edge.dataProvider').get(relatedEntityRef.logicalname, relatedEntityRef.id);
				if (this.relatedEntityOverrideFields) {
					this.relatedEntityOverrideFields.forEach(field => {
						relatedEntity.Detail[field.name] = field.value;
						relatedEntity.Source[field.name] = field.value;
					});
				}
				this.$store.commit(this.relatedEntityStateKey + '/initEntity', relatedEntity.Source || relatedEntity);
			}
			this.relatedContext = createEvaluationContext(relatedEntity);

			this.relatedEntityTemplate = await this.configDataProvider.getTemplate(this.relatedEntityLogicalName, 'edit', this.subtype);
		}
	},

	beforeDestroy() {
		if (this.relatedEntityLogicalName) {
			this.$store.unregisterModule(this.relatedEntityStateKey);
		}
	}
};

function handleRelatedSubfields(primary, resulted) {
	const RELATED = { geocoordinates: ['city', 'country', 'county', 'street', 'postalcode', 'stateorprovince'] };
	Object.keys(RELATED).forEach(key => {
		const relate = Object.keys(primary).some(field => RELATED[key].includes(field));
		if (relate && !resulted[key]) resulted[key] = primary[key];
	});
	return resulted;
}
</script>
<style src="./editor.less" scoped></style>
