import { UpdateWithParameterAspect } from './aspects/updateWithParameter.aspect';
// TODO: delete in unused
export class UpdateWithParameter {
	constructor(options) {
		this.options = options;
	}

	installAspects() {
		return [new UpdateWithParameterAspect(this.options)];
	}
}
