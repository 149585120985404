/* eslint no-useless-escape: 0 */
/* eslint no-useless-catch: 0 */
import dataProvider from '@/Data/DataProviders/reportingserviceDataprovider';
import RS4Utils from './reportingservice.Utils';
import coDataProvider from '@/Data/DataProviders/coDataprovider';
import { capitalizeFirstLetter } from '@acx-xms/data-functions/dist';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';
import { GetPerConfig, SetPerConfig } from '@/Data/DataProviders/userStorageDataProvider';

export default {
	props: {
		options: Object,
		selectedTemplate: Object,
		stateKey: String,
		totalCOTemplates: Number
	},
	data() {
		return {
			availableFormatTranslations: this.options.availableFormatTranslations,
			reportTemplate: null,
			editorTemplate: null,
			recordsList: this.options.recordsList,
			isElementListEmpty: this.options.isElementListEmpty,
			hasItemsMatchedToTemplate: (this.options.recordsList || []).filter(list => list.matchedToTemplate).some(list => list.itemsCount)
		};
	},
	computed: {
		entityData() {
			return this.$store.state[this.stateKey] && this.$store.state[this.stateKey].entityData;
		}
	},
	async created() {
		this.localization = sc.classes.get('localization.dataProvider');
		this.evaluationContext = createEvaluationContext(this.selectedTemplate);
		this.firstAddedItemLogicalName = this.options.firstAddedItemLogicalName;
		this.editorTemplate = this.options.editorTemplate;
		this.reportTemplate = await this.getTemplate('reports', 'search', 'templateInfo');
		this.$parent.$on('isElementListEmpty', this.isElementListEmptyCallback);
	},
	beforeDestroy() {
		this.$parent.$off('isElementListEmpty', this.isElementListEmptyCallback);
	},
	methods: {
		async getTemplate(entity, usage, subtype) {
			return await sc.classes.get('entityConfiguration.dataProvider').getTemplate(entity, usage, subtype);
		},
		close() {
			this.$emit('close');
		},
		async validate() {
			const result = {
				isValid: false,
				message: ''
			};
			try {
				await this.$store.dispatch(this.stateKey + '/validate');
				result.isValid = true;
			} catch (errors) {
				errors.forEach(error => {
					const fieldName = error.field.charAt(0).toUpperCase() + error.field.slice(1);
					result.message += `– ${fieldName}: ${error.message}<br\>`;
				});
			}
			return result;
		},
		showValidationError(message) {
			sc.events.emit('dialog.error', {
				title: this.localization.localize('entity.editor.validationError'),
				message
			});
		},
		isElementListEmptyCallback(val) {
			this.isElementListEmpty = val;
		},
		async prepareDataForArtifact() {
			const coFormats = ['pdf', 'html'];
			const useCO = !!(!this.entityData['output-format'] || coFormats.includes(this.entityData['output-format'])) && this.selectedTemplate.templateId;
			let templateId;
			if (useCO) {
				templateId = this.selectedTemplate.templateId;
			} else {
				if (this.selectedTemplate.templateIds) {
					templateId = this.selectedTemplate.templateIds.length > 1
						? this.selectedTemplate.templateIds.find(template => template.color === this.entityData.color).templateId
						: this.selectedTemplate.templateIds[0].templateId;
				} else {
					templateId = this.selectedTemplate.templateId;
				}
			}
			const props = {
				...this.entityData,
				'report-name': this.selectedTemplate.templateName,
				templateId,
				isCO: useCO
			};

			if (this.entityData.saveAsDefault) {
				await this.wrapAndSaveDefaultData(dataProvider.reportingDefaultParamsKey, props);
			}

			const propsFormated = {};
			for (const key in props) {
				if (!(props[key] === void 0 || props[key] === null)) {
					if (props[key] === Object(props[key])) {
						propsFormated[key] = props[key].id;
					} else {
						propsFormated[key] = (typeof (props[key]) === 'string') ? props[key] : JSON.stringify(props[key]);
					}
				}
			}

			const filteredItems = this.recordsList.filter(item => item.matchedToTemplate);
			if (!filteredItems.length) {
				this.showValidationError(this.localization.localize('dialogues.RS4.noFilteredItems', [capitalizeFirstLetter(this.recordsList[0].entityName)]));
				return;
			}

			if (filteredItems[0].itemsCount > filteredItems[0].itemsLimitToGenerateCO && useCO) {
				this.showValidationError(this.localization.localize('dialogues.RS4.coReachedLimitOfRecord'));
				return;
			}
			this.isLoading = true;
			this.$parent.$emit('isGenerating', true);
			const selectionData = { items: filteredItems };
			return {
				selectionData,
				propsFormated,
				useCO
			};
		},
		async createArtifact() {
			const { selectionData, propsFormated } = await this.prepareDataForArtifact();
			const fieldsToRegenerate = ['broker', 'campaign-name'];
			const fieldChanged = this.changedFields.some(field => fieldsToRegenerate.includes(field));
			if (fieldChanged || !this.artifact || this.listChanged) {
				this.artifact && coDataProvider.deleteArtifact(this.artifact.artifactId);
				this.listChanged = false;
				this.$store.commit(this.stateKey + '/initEntity', { ...this.entityData });
				this.artifact = await this.generateCO(propsFormated, selectionData, false, 24);
			}
		},
		async generateCO(propsFormated, selectionData, generatePDF, maxRetryCount) {
			const artifactAlias = `${this.selectedTemplate.templateId}_${Date.now()}`;
			const data = {
				templateId: this.selectedTemplate.templateId,
				artifactName: propsFormated['output-file-name'] || propsFormated['campaign-name'],
				artifactAlias,
				accessible: true
			};
			if (selectionData.items.length) {
				selectionData.items.forEach(item => { data.entityIds = item.items.map(i => i.id); });
			}

			try {
				const jobId = await coDataProvider.generateHtml(data);
				await coDataProvider.getJobStatus(jobId, maxRetryCount);
				const artifact = await coDataProvider.getArtifactByAlias(artifactAlias);
				if (!generatePDF) {
					return artifact;
				} else if (propsFormated['output-format'] === 'pdf') {
					const pdfJobId = await coDataProvider.generatePdf(artifact.artifactId);
					const pdfResult = await coDataProvider.getJobStatus(pdfJobId);
					this.$parent.$emit('isGenerating', false);
					pdfResult.status === 'completed' && RS4Utils.downloadCOReport(pdfResult.result);
				}
			} catch (e) {
				throw e && e.jobstatus ? e.jobstatus : e;
			}
		},
		async wrapAndSaveDefaultData(paramKey, props) {
			const firstAddedItemLogicalName = this.firstAddedItemLogicalName;
			const fieldsToIgnore = ['report-name', 'output-format', 'isCO'];
			let data = await GetPerConfig(paramKey);
			if (!data) data = {};
			data[firstAddedItemLogicalName] = {};
			Object.keys(props).forEach((key) => {
				if (fieldsToIgnore.indexOf(key) > -1) {
					data[firstAddedItemLogicalName][key] = props[key];
				} else {
					data[key] = props[key];
				}
			});
			await SetPerConfig(paramKey, data);
		}
	}
};
