var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("stringeditor", {
    attrs: {
      value: _vm.value,
      enable: _vm.enable,
      showRequired: _vm.showRequiredMark,
      placeholder: _vm.placeholder,
      isValid: _vm.isValid,
      stringFormatRegexp: _vm.stringFormatRegexp,
      stringFormat: _vm.options.stringFormat,
      dataAttr: _vm.dataAttr
    },
    on: { focused: _vm.onFocusedChange, input: _vm.onInputChange }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }