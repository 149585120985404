var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "search-field-wrapper inline-block",
      class: Object.assign(
        {},
        {
          focused: _vm.searchFocused || _vm.isPopUpOpen,
          opened: _vm.isPopUpOpen
        },
        _vm.cssClasses
      )
    },
    [
      _c("div", { staticClass: "input-wrapper" }, [
        _c("input", {
          staticClass: "search-box",
          attrs: {
            spellcheck: "false",
            autocomplete: "off",
            type: "text",
            placeholder: _vm.placeholder,
            disabled: _vm.isDisabled
          },
          domProps: { value: _vm.searchQuery },
          on: {
            keyup: [
              function($event) {
                if (
                  !("button" in $event) &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.search($event)
              },
              function($event) {
                if (
                  !("button" in $event) &&
                  _vm._k($event.keyCode, "down", 40, $event.key, [
                    "Down",
                    "ArrowDown"
                  ])
                ) {
                  return null
                }
                _vm.$root.$emit("lookup.keyDown")
              },
              function($event) {
                if (
                  !("button" in $event) &&
                  _vm._k($event.keyCode, "up", 38, $event.key, [
                    "Up",
                    "ArrowUp"
                  ])
                ) {
                  return null
                }
                _vm.$root.$emit("lookup.keyUp")
              }
            ],
            input: _vm.searchWithDebounce,
            focus: function($event) {
              _vm.searchFocused = true
            },
            blur: _vm.onInputBlur
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "search-icon-wrapper" }, [
        _c(
          "svg",
          {
            staticClass: "svg-icon",
            class: { "disabled-search-icon": _vm.isDisabled },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return (function() {
                  _vm.search(true)
                })($event)
              }
            }
          },
          [
            _c("use", {
              staticClass: "layout-icons-ms-search",
              attrs: { "xlink:href": "#layout-icons-ms-search" }
            })
          ]
        ),
        _vm._v(" "),
        _c(
          "svg",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.searchQuery && _vm.options.withCrossIcon,
                expression: "searchQuery && options.withCrossIcon"
              }
            ],
            staticClass: "svg-icon svg-20 clear-button",
            on: { click: _vm.clearSearchBox }
          },
          [
            _c("use", {
              staticClass: "layout-icons-textbox-delete",
              attrs: { "xlink:href": "#layout-icons-textbox-delete" }
            })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }