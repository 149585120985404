<template>
	<div class="mapping-edit-dialog">
		<div class="loading-overlay large" v-show="isLoading"></div>
		<div class="content">
			<mapping-search :pairs="pairs"
							@onChange="setFilteredPairs"
			/>
			<div class="list-content">
				<div class="control-tableView inner-search-results">
					<div class="tableview-header" v-show="filteredPairs.length">
						<div class="cell" style="width: 35%">
							<span v-localization="{ key: 'dialogues.mapping.crmField'}"></span>
						</div>
						<div class="cell" style="width: 10%">
							<span v-localization="{ key: 'dialogues.mapping.type'}"></span>
						</div>
						<div class="cell" style="width: 10%">
							<span v-localization="{ key: 'dialogues.mapping.mapped'}"></span>
						</div>
						<div class="cell" style="width: 35%">
							<span v-localization="{ key: 'dialogues.mapping.msField'}"></span>
						</div>
						<div class="cell" style="width: 10%">
							<span v-localization="{ key: 'dialogues.mapping.type'}"></span>
						</div>
					</div>
					<div class="tableview-content">
						<div class="content-scroll-wrapper" v-perfectscroll="{ enable: true, onScroll: onScroll}">
							<div class="row" v-for="pair in filteredPairs" :key="pair.crmLogicalName">
								<div class="table-cell" style="width: 35%">
									<p class="label"><span class="p2">{{pair.crmDisplayName}}</span> ({{pair.crmLogicalName}})</p>
								</div>
								<div class="table-cell" style="width: 10%">
									<span class="label">{{pair.crmType}}</span>
								</div>
								<div class="table-cell" style="width: 10%">
									<svg v-show="pair.msField" class="svg-icon svg-20 control-component svg-primary"><use xlink:href="#layout-icons-positive"></use></svg>
								</div>
								<div class="table-cell" style="width: 35%">
									<control-lookup-editor v-if="editMode"
															v-model="pair.msField"
														   :evaluationContext="currentContext"
														   :options="getGroupLookupOptions()" />
									<span v-if="!editMode && pair.msFieldShort" class="label">
										<p class="label"><span class="p2">{{pair.msFieldShort.displayName}}</span> ({{pair.msFieldShort.logicalName}})</p>
									</span>
								</div>
								<div class="table-cell" style="width: 10%">
									<span class="label" v-if="pair.msField">{{pair.msField.data ? pair.msField.data.DataType : ''}}</span>
								</div>
							</div>
							<div class="row no-results-message p13" v-if="filteredPairs && !filteredPairs.length">
								<div class="table-cell" v-bind:style="{ width: '100%' }">
									<div v-localization="{ key: 'common.dictionary.noMatchesFound' }"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="footer" v-if="editMode">
				<p class="warning" v-localization="{ key: 'dialogues.mapping.footer'}"></p>
				<div class="buttons">
					<button type="button" @click="save" v-localization="{key: 'common.dictionary.buttons.save'}"></button>
					<button class="cancel" type="button" @click="close" v-localization="{key: 'common.dictionary.buttons.cancel'}"></button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import crmSyncDataProvider from '@/Data/DataProviders/crmSyncDataProvider';
import {
	showToastOrInfoDialog, Search, Metadata, Create, Update, Delete, Get
} from '@acx-xms/data-functions/dist';
import MappingSearch from './mapping-search.vue';
function GetFieldFromMeta(meta, logicalName) {
	const filtered = meta.filter(m => {
		return m.LogicalName.toLowerCase() === logicalName.toLowerCase();
	});
	return filtered[0];
}
function IsMSFieldValid(logicalName, type) {
	const msForbbidenFields = ['dummy', 'type', 'isremoved', 'sfcrmid', 'crmownerid', 'createdon', 'modifiedon', 'crmownerinid', 'crmmodifiedon', 'versionnumber'];
	const msForbbidenTypes = ['entityReference', 'guid'];

	return msForbbidenTypes.indexOf(type) < 0 &&
			msForbbidenFields.indexOf(logicalName) < 0;
}
function IsSFFieldValid(logicalName, type) {
	const sfForbbidenTypes = ['id', 'reference', 'location'];
	const sfForbbidenKeywords = ['IsDeleted', 'PrimaryImage', 'Geolocation', 'PublishToMarketSpace'];
	if (sfForbbidenTypes.indexOf(type) >= 0) return false;

	for (let i = 0; i < sfForbbidenKeywords.length; i += 1) {
		if (logicalName.toLowerCase().includes(sfForbbidenKeywords[i].toLowerCase())) {
			return false;
		}
	}
	return true;
}
const ControlLookupEditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Lookup/control-lookup-editor');

export default {
	props: {
		options: Object,
		evaluationContext: Object
	},
	components: {
		ControlLookupEditor,
		MappingSearch
	},
	data() {
		return {
			pairs: [],
			isLoading: false,
			fields: [],
			editMode: true,
			filteredPairs: []
		};
	},
	computed: {
		currentContext() {
			return this.options.params ? this.options.params.evaluationContext : this.evaluationContext;
		}
	},

	async created() {
		try {
			if (this.options.editable) {
				this.editMode = this.currentContext.eval(this.options.editable);
			}

			this.logicalName = this.currentContext.eval({
				$type: 'expression.field',
				name: 'edgeentity',
				schema: 'source'
			});

			this.mappingId = this.currentContext.eval({
				$type: 'expression.field',
				name: 'Id',
				schema: 'root'
			});
			const isResetting = this.currentContext.eval({
				$type: 'expression.field',
				name: 'isresetting',
				schema: 'source'
			});
			this.upperCaseLogicalName = this.logicalName.charAt(0).toUpperCase() + this.logicalName.slice(1);
			if (isResetting) {
				return this.openDialogue(this.upperCaseLogicalName);
			}
			this.isLoading = true;
			const metaPromise = Metadata(this.logicalName);
			const filters = [
				sc.classes.get('offsetFrom.filter', 0),
				sc.classes.get('offsetSize.filter', 9999),
				sc.classes.get('termFacet.filter', {
					logicalName: 'mappingid.id',
					query: [this.mappingId]
				}).fillQuery(),
				sc.classes.get('selectedFields.filter', [
					{ logicalname: 'crmFields' },
					{ logicalname: 'edgeField' }
				]).fillQuery()
			];

			const pairsPromise = Search(['mappingpair'], filters);
			crmSyncDataProvider.pluginName = 'sync';

			const crmentity = this.currentContext.eval({
				$type: 'expression.field',
				name: 'crmentity',
				schema: 'source'
			});
			const sfMetaPromise = crmSyncDataProvider.getMeta(crmentity);
			// todo: add error handling if needed
			const [meta, mappingsResult, sfMeta] = await Promise.all([metaPromise, pairsPromise, sfMetaPromise]);

			const allPairs = sfMeta.data.fields.map(sf => {
				const existing = mappingsResult.Results.filter(p => {
					return p.Source.crmFields.toLowerCase() === sf.name.toLowerCase();
				});
				const pair = {
					crmLogicalName: sf.name,
					crmDisplayName: sf.label,
					crmType: sf.type
				};
				if (existing.length > 0) {
					const p = existing[0];
					const fromMeta = GetFieldFromMeta(meta.Fields, p.Source.edgeField);
					if (fromMeta) {
						pair.initialField = fromMeta.LogicalName;
						pair.existingId = p.Id;
						pair.msField = {
							displayName: `${fromMeta.DisplayName} (${fromMeta.LogicalName})`,
							data: fromMeta
						};
						pair.msFieldShort = {
							displayName: fromMeta.DisplayName,
							logicalName: fromMeta.LogicalName
						};
					}
				}

				return pair;
			});

			this.pairs = allPairs.filter(p => {
				return IsSFFieldValid(p.crmLogicalName, p.crmType) &&
						(!p.msField || IsMSFieldValid(p.msField.data.LogicalName, p.msField.data.DataType));
			}).sort((a, b) => {
				return a.crmDisplayName > b.crmDisplayName ? 1 : -1;
			});

			this.fields = meta.Fields.map(res => {
				return {
					displayName: `${res.DisplayName} (${res.LogicalName})`,
					data: Object.assign({ Id: res.LogicalName }, res)
				};
			}).filter(f => {
				return IsMSFieldValid(f.data.LogicalName, f.data.DataType);
			});

			this.isLoading = false;
		} catch (e) {
			sc.utils.errorMessage.byResponse(e);
			this.close();
		}
	},

	methods: {
		async save() {
			const mappingRecord = await Get('mapping', this.mappingId);
			if (mappingRecord.Source.isresetting) {
				return this.openDialogue(this.upperCaseLogicalName);
			}
			const updatedPairs = this.pairs.filter(p => {
				return (p.initialField && !p.msField) || (p.msField && p.initialField !== p.msField.data.LogicalName);
			});
			if (updatedPairs.length) {
				this.isLoading = true;

				const promises = [];

				updatedPairs.forEach(p => {
					let promise = null;
					if (!p.msField && p.existingId) {
						promise = Delete('mappingpair', p.existingId);
					} else if (p.existingId) {
						promise = Update('mappingpair', p.existingId, { edgeField: p.msField.data.LogicalName });
					} else {
						promise = Create('mappingpair', {
							edgeField: p.msField.data.LogicalName,
							crmFields: p.crmLogicalName,
							mappingid: {
								id: this.mappingId,
								logicalname: 'mapping'
							},
							recordstate: {
								id: '8d113fa8-3015-4060-a107-14cedcd19dd3',
								logicalname: 'lookuprecordstate'
							}
						});
					}
					promises.push(promise);
				});

				await Promise.all(promises);
				await showToastOrInfoDialog({
					toastMessageKey: this.options.params.toastMessageKey,
					informationDialogTextKey: this.options.params.informationDialogTextKey,
					options: { context: this }
				});
				this.isLoading = false;
			}
			this.close();
		},
		onScroll() {
			this.$root.$emit('lookupArea.scrolled');
		},
		close() {
			this.$parent.$emit('close');
		},
		getGroupLookupOptions() {
			return {
				disabled: !this.editMode,
				isRequired: true,
				placeholder: '',
				fetchData: searchQuery => {
					const existing = this.pairs.filter(p => {
						return p.msField && p.msField.data;
					}).map(p => {
						return p.msField.data.LogicalName;
					});

					const filtered = this.fields.filter(f => {
						return existing.indexOf(f.data.LogicalName) < 0 && f.displayName.toLowerCase().includes(searchQuery.toLowerCase());
					});

					return {
						total: filtered.length,
						items: filtered
					};
				},
				template: {
					$type: 'entityTemplate.fieldGroup',
					columns: 1,
					control: [
						{
							$type: 'control.label',
							wrap: 'noWrap',
							cssClass:
								{
									$type: 'expression.text',
									value: 'p2'
								},
							text:
								{
									$type: 'expression.field',
									name: 'DisplayName',
									schema: 'root'
								},
							colspan: 1
						},
						{
							$type: 'control.label',
							wrap: 'noWrap',
							text:
								{
									$type: 'expression.concat',
									argument: [
										{
											$type: 'expression.text',
											value: '('
										},
										{
											$type: 'expression.field',
											name: 'LogicalName',
											schema: 'root'
										},
										{
											$type: 'expression.text',
											value: ')'
										}]
								},
							colspan: 1
						}],
					colspan: 1
				}
			};
		},
		setFilteredPairs(value) {
			this.filteredPairs = value;
		},
		async openDialogue(edgeEntityName) {
			sc.events.emit('dialog.open', {
				title: 'Warning',
				component: 'dialog.info',
				params: {
					message: await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('common.dictionary.mappingAlreadyResetting', [edgeEntityName]),
					buttons: [
						{ title: 'OK' }
					]
				}
			});
		}
	}
};
</script>
<style src="./mapping-edit-dialog.less" scoped></style>
<style src="@/Components/Control/Table/control-table-view.less" scoped></style>
<style src="@/Components/Control/Table/control-table-view-row.less" scoped></style>
