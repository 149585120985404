import { Search } from '@acx-xms/data-functions/dist';

export default {
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return {
			stateKey: null,
			entity: this.evaluationContext.entity,
			initEntity: {},
			field: this.options.field,
			schema: this.options.schema,
			localValue: null, // if used outside vue editor
			calcVisible: null,
			dataAttr: {}
		};
	},
	computed: {
		value: {
			get() {
				return this.getField();
			},
			set(value) {
				this.setField(value);
			}
		},
		enable() {
			if (this.storeState) {
				return !this.storeState.disabledFields[this.field];
			}
			return this.options.enable ? this.evaluationContext.eval(this.options.enable) : true;
		},
		visible() {
			if (this.storeState) {
				return !this.storeState.hiddenFields[this.field];
			}
			// we can use vue-async-computed package instead to have async computed properties
			// the problem is if evaluationContext or options are changed - we won't react on it with current approach (but context and options won't change so we are good)
			return this.calcVisible;
		},

		showRequiredMark() {
			if (this.storeState) {
				return this.storeState.requiredFields[this.field] && !this.focused && !this.value && this.enable;
			}
			return this.options.required ? this.evaluationContext.eval(this.options.required) && !this.focused && !this.value && this.enable : false;
		},
		isValid() {
			if (this.storeState) {
				return this.storeState.validFields[this.field];
			}
			return true;
		},
		storeState() {
			return this.$store.state[this.stateKey];
		}
	},
	async created() {
		this.entityConfig = sc.classes.get('entityConfiguration.dataProvider');
		this.stateKey = 'editor.' + this.entity.logicalname;
		this.initEntity = this.storeState && this.storeState.initialEntity;
		await this.fieldRegistration();
		this.calcVisible = this.options.visible !== void 0 ? await this.evaluationContext.evalAsync(this.options.visible) : true;
		this.dataAttr = this.options.qaDataAttribute && this.evaluationContext.eval(this.options.qaDataAttribute);
		if (this.initEntity && Object.keys(this.initEntity).length) {
			await this.setField(this.initEntity.Source ? this.initEntity.Source[this.field] : this.initEntity[this.field], true);
		} else {
			const skip = this.evaluationContext.eval(this.options.skipDefaultValueIfValueExists);
			if (this.options.defaultValue && (!skip || (skip && this.storeState.entityData[this.field] === void 0))) {
				const val = await this.evaluationContext.evalAsync(this.options.defaultValue);
				await this.setField(val);
			}
			if (this.options.defaultFilteredValue && !this.options.defaultValue && (!skip || (skip && this.storeState.entityData[this.field] === void 0))) {
				const configFilters = this.options.defaultFilteredValue.filters.map((filter) => {
					return sc.classes.get(filter.$type, filter, this.evaluationContext).toFilter();
				});
				const filters = [
					sc.classes.get('offsetSize.filter', 1),
					...configFilters
				];
				const entity = this.options.defaultFilteredValue.entityTypes;
				const defaultValueResponse = await Search(entity, filters);
				if (defaultValueResponse.Results.length) {
					const result = defaultValueResponse.Results[0];
					let defaultValue = null;
					if (this.options.defaultFilteredValue.path) {
						const { name, schema } = this.options.defaultFilteredValue.path;
						if (schema === 'root') {
							defaultValue = result;
						} else {
							defaultValue = name.split('.').reduce((o, i) => o[i], result[schema]);
						}
						if (this.storeState.fieldsOptions[this.field].defaultFilteredValue.pathName) {
							const { name, schema } = this.storeState.fieldsOptions[this.field].defaultFilteredValue.pathName;
							defaultValue.name = name.split('.').reduce((o, i) => o[i], result[schema]);
						}
					} else {
						defaultValue = { // rethink object forming like sc.classes.get('entityReference', defaultValue)
							id: result.Id,
							name: result.Name,
							logicalname: entity[0]
						};
					}
					await this.setField(defaultValue);
				}
			}
		}
	},
	methods: {
		async setField(value, isFormInit, fieldName = this.field) {
			if (this.storeState) {
				await this.$store.dispatch(this.stateKey + '/setField', {
					name: fieldName,
					value,
					isFormInit
				});
			} else {
				this.localValue = value;
				this.emitEventOnChange && this.$root.$emit(`${this.field}.changed`, {
					value,
					id: this.evaluationContext.entity.id
				});
			}
		},
		getField(fieldName = this.field) {
			return this.storeState ? this.storeState.entityData[fieldName] : this.localValue;
		},
		async fieldRegistration() {
			if (this.storeState) {
				await this.$store.dispatch(this.stateKey + '/registerField', {
					field: this.field,
					options: this.options
				});
			}
		}
	}
};
