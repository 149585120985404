import { BaseAspect } from './base.aspect';
import {
	Search, SendRequest, getOrgSettings
} from '@acx-xms/data-functions/dist';

export class SEOAfterSaveAspect extends BaseAspect {
	constructor(options) {
		super();
		this.stage = 'afterSave';

		this.init(options);
	}

	init(options, context) {
		super.init(options, context);
	}

	async execute(options) {
		const isAvailability = options.saveResult.type === 'availability';
		let isPublished;

		if (isAvailability) {
			const listing = await Search(
				['listing'],
				[sc.classes.get('offsetSize.filter', 4), sc.classes.get('ids.filter', [options.saveResult.listingid.id])]
			);

			isPublished = listing.Results[0].Source.publishedtolistingportal;
		} else isPublished = options.saveResult.publishedtolistingportal;

		if (!isPublished) return;

		const orgSettings = await getOrgSettings();
		if (!orgSettings.enableseo) return;

		await SendRequest(
			'/api/sitemap/addurl',
			'post',
			{
				id: options.saveResult[isAvailability ? 'availabilityid' : 'listingid'],
				name: options.saveResult.name,
				logicalname: options.saveResult.type
			}
		);
	}
}
