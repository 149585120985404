import { BaseAspect } from './base.aspect';
import crmSyncDataProvider from '../../../../Data/DataProviders/crmSyncDataProvider';
import { Update } from '@acx-xms/data-functions/dist';

export class SaveContactToSFAspect extends BaseAspect {
	constructor(options) {
		super();
		this.init(options);
		this.stage = 'afterSave';
	}

	init(options, event) {
		super.init(options, event);
	}

	async execute(options) {
		try {
			const contact = options.saveResult;
			const crmSetting = await crmSyncDataProvider.getCrmSetting();
			crmSyncDataProvider.pluginName = 'sync';
			this.crmNamespace = crmSetting.Results && crmSetting.Results.length && crmSetting.Results[0].Source.crmnamespace;
			if (this.crmNamespace) {
				if (contact.account) {
					let sfAccount = await this.getAccount(contact.account);
					sfAccount = sfAccount && sfAccount.length && sfAccount[0] && sfAccount[0].Id;
					if (!sfAccount) {
						sfAccount = await this.createSFRecord('Account', {
							Name: contact.account,
							[`${this.crmNamespace}__SourceSystem__c`]: 'MarketSpace'
						});
					}
					this.sfAccountid = sfAccount;
				}
				const sfRecord = this.buildSfRecord(contact);
				const sfcrmid = await this.createSFRecord('Contact', sfRecord);
				await this.updateEdgeRecord(contact.contactid, sfcrmid);
			}
		} catch (error) {
			sc.utils.errorMessage.byResponse(error);
		}
	}

	async updateEdgeRecord(edgeid, sfcrmid) {
		return await Update('contact', edgeid, { sfcrmid });
	}

	buildSfRecord(edgeRecord) {
		const mappings = [
			{
				crmField: 'FirstName',
				edgeField: 'firstname',
				dataSource: edgeRecord
			},
			{
				crmField: 'LastName',
				edgeField: 'lastname',
				dataSource: edgeRecord
			},
			{
				crmField: 'AccountId',
				value: this.sfAccountid
			},
			{
				crmField: 'Email',
				edgeField: 'email',
				dataSource: edgeRecord
			},
			{
				crmField: 'Phone',
				edgeField: 'phone',
				dataSource: edgeRecord
			},
			{
				crmField: 'MobilePhone',
				edgeField: 'mobilephone',
				dataSource: edgeRecord
			},
			{
				crmField: 'SourceSystem__c',
				value: 'MarketSpace'
			},
			{
				crmField: 'SourceSystemNumber__c',
				edgeField: 'Id',
				dataSource: edgeRecord
			}
		];

		const sfRecord = {};

		mappings.forEach(mapping => {
			let value;
			if (mapping.value) value = mapping.value;
			else value = sc.utils.findProperty(mapping.dataSource, mapping.edgeField);

			if (value) {
				if (mapping.crmField.indexOf('__c') > -1) mapping.crmField = `${this.crmNamespace}__${mapping.crmField}`;
				sfRecord[mapping.crmField] = value;
			}
		});
		return sfRecord;
	}

	createSFRecord(sfLogicalName, fields) {
		return crmSyncDataProvider.proxy('api/sf/create', {
			entity: sfLogicalName,
			fields
		});
	}

	getAccount(name) {
		return crmSyncDataProvider.proxy('api/sf/search', {
			entity: 'Account',
			fields: ['Id'],
			criteria: {
				conditions: [{
					field: 'Name',
					operator: 'eq',
					value: name
				}]
			}
		});
	}
}
