<template>
	<div v-if="enable" :class="cssClass" class="column" :style="{ width: width || '100%', height: height || 'auto' }">
		<div class="column-wrapper" :class="{ 'minimized':  !collapsed}">
			<div v-if="!hideHeader" class="column-title">
				<div class="region-left">
					<div class="region">
						<slot name="title-slot"><p v-text="title"></p></slot>
						<slot name="toggle-slot" slot="toggle-slot" />
					</div>

				</div>
				<div v-if="!computedLoading" class="action region-right">
					<action-menu v-if="innerContext!==null && actionMenuComponent && (actionMenuComponent.menu || actionMenuComponent.toolbarActions)"
								 :options="actionMenuComponent"
								 :evaluationContext="innerContext"
								 class="without-padding without-border"
								 :record="record">
					</action-menu>
					<div v-if="allowToggle" class="icon-holder" @click="toggle">
						<svg class="svg-icon svg-20">
							<use v-if="collapsed" xlink:href="#layout-icons-header-arrow-up" class="hover"></use>
							<use v-else-if="!collapsed" xlink:href="#layout-icons-header-arrow-down" class="hover"></use>
						</svg>
					</div>
				</div>
			</div>
			<div class="loading-overlay" v-show="computedLoading"></div>

			<div v-if="!computedLoading && innerContext!==null" :class="{ 'no-header': hideHeader }" class="column-content" v-perfectscroll="{enabled:true}">
				<control :name="options.control.$type"
						 :contentProps.once="options.control"
						 v-show="collapsed"
						 :evaluationContext="innerContext">
				</control>
				<control :name="options.controlMinimized.$type"
						 v-show="!collapsed && options.controlMinimized"
						 v-if="options.controlMinimized"
						 :contentProps.once="options.controlMinimized"
						 :evaluationContext="innerContext">
				</control>
			</div>
		</div>
	</div>
</template>

<script>
/* eslint  promise/param-names: 0 */
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';

export default {
	name: 'details-column-control-namespaced',
	props: {
		options: Object,
		evaluationContext: Object
	},
	components: {
		control: () => import('./../../Entity/control'),
		ActionMenu: () => import('./../../Menu/action-menu')
	},
	data() {
		return {
			innerContext: null,
			record: null,
			isLoading: true,
			collapsed: (this.options.collapsed) ? !this.evaluationContext.eval(this.options.collapsed) : true,
			actionMenuComponent: null
		};
	},
	computed: {
		computedLoading() {
			return this.options.showLoadingIfNoResults ? (this.record && Object.keys(this.record).length ? this.isLoading : true) : this.isLoading;
		}
	},
	async created() {
		const namespace = this.evaluationContext.eval(this.options.refreshNamespace);

		this.width = this.options.width;
		this.height = this.options.height;
		this.allowToggle = this.evaluationContext.eval(this.options.toggleComponent);
		this.title = this.evaluationContext.eval(this.options.title);
		this.hideHeader = !!this.evaluationContext.eval(this.options.hideHeader);
		this.enable = this.options.enable === void 0 || !!this.evaluationContext.eval(this.evaluationContext, this.options.enable);
		this.cssClass = this.options.cssClass ? this.evaluationContext.eval(this.options.cssClass) : [];
		this.query = await sc.classes.get(this.options.query.query.$type, this.options.query.query, this.evaluationContext).toQuery();

		await this.search();

		if (this.options.retry) {
			let attempts = this.options.retry.number;
			const delay = this.options.retry.delay;
			if (!Object.keys(this.record).length) {
				while (attempts-- > 0) {
					await new Promise(r => setTimeout(r, delay));
					if (!Object.keys(this.record).length) await this.search();
				}
			}
		}

		this.actionMenuComponent = {
			menu: this.options.menu,
			toolbarActions: this.options.actionToolbar,
			entityType: this.query.entities[0],
			$type: 'layoutcomponent.marketspaceActionMenu'
		};

		if (namespace) {
			this.fork = sc.events.fork();
			this.fork.on(namespace + '.selectSingleItem', () => {
				this.isLoading = true;
				this.search();
			});
		}
		this.$root.$on(['entity.changed', 'entity.created', 'entity.deleted'], this.checkForUpdateImage);
		// remove forked after migrated to vue
		this.fork.on(['entity.changed', 'entity.created', 'entity.deleted'], this.checkForUpdateImage);
	},

	beforeDestroy() {
		this.$root.$off(['entity.changed', 'entity.created', 'entity.deleted'], this.checkForUpdateImage);
		if (this.fork) {
			this.fork.dispose();
		}
	},

	methods: {
		checkForUpdateImage(data) {
			if (data.entity.logicalname === this.query.entities[0]) {
				this.isLoading = true;
				this.search();
			}
		},
		toggle() {
			this.collapsed = !this.collapsed;
		},
		async search() {
			try {
				const data = await sc.classes.get('edge.dataProvider').search(this.query);
				this.record = data.Results.length > 0 ? data.Results[0] : {};

				this.innerContext = createEvaluationContext(this.record);
			} catch (e) {
				this.innerContext = createEvaluationContext({});
			} finally {
				this.isLoading = false;
			}
		}
	}
};
</script>
<style src="./details-column-control.less" scoped></style>
