<template>
	<div class="convert-inquiry-dialog">
		<div class="body" v-perfectscroll="{enable: true}">
			<div class="section">
				<h5 class="title">{{contact.displayName}}</h5>
				<div class="option">
					<div class="input-radio-wrapper">
						<label>
							<input type="radio" v-model="contact.recordOption" :value="'new'" />
							<span class="label" v-localization="{ key: 'convertInquiryDialog.createNew' }"></span>
						</label>
					</div>
					<control v-if="editorTemplate"
							 v-show="contact.recordOption==='new'"
							 :name="editorTemplate.$type"
							 :contentProps="editorTemplate"
							 :evaluationContext="evaluationContext"></control>
				</div>
				<div class="option">
					<div class="input-radio-wrapper">
						<label>
							<input type="radio" v-model="contact.recordOption" :value="'existing'" />
							<span class="label" v-localization="{ key: 'convertInquiryDialog.chooseExisting' }"></span>
						</label>
					</div>
					<control-lookup-editor v-if="contact.recordOption==='existing'"
										   :showRequired="true"
										   v-model="contact.selectedRecord"
										   :evaluationContext="evaluationContext"
										   :options="getLookupOptions(contact)"
										   :class="{invalid: !contact.isValid}"></control-lookup-editor>
				</div>
			</div>
			<div class="section">
				<h5 class="title">{{preference.displayName}}</h5>
				<div class="option">
					<div class="input-radio-wrapper">
						<label>
							<input type="radio" v-model="preference.recordOption" :value="'new'" />
							<span class="label" v-localization="{ key: 'convertInquiryDialog.createNew' }"></span>
						</label>
					</div>
				</div>
				<div class="option">
					<div class="input-radio-wrapper">
						<label>
							<input type="radio" v-model="preference.recordOption" :value="'existing'" />
							<span class="label" v-localization="{ key: 'convertInquiryDialog.chooseExisting' }"></span>
						</label>
					</div>
					<control-lookup-editor v-if="preference.recordOption==='existing'"
										   :showRequired="true"
										   v-model="preference.selectedRecord"
										   :evaluationContext="evaluationContext"
										   :options="getLookupOptions(preference)"
										   :class="{invalid: !preference.isValid}"></control-lookup-editor>
					<div class="warning" v-show="showWarning&&preference.recordOption==='existing'" v-localization="{ key: 'convertInquiryDialog.warningPreference' }"></div>
				</div>
				<div class="option">
					<div class="input-radio-wrapper">
						<label>
							<input type="radio" v-model="preference.recordOption" :value="'no'" />
							<span class="label" v-localization="{ key: 'convertInquiryDialog.doNotConvertToPrefrence' }"></span>
						</label>
					</div>
				</div>
			</div>
			<div class="section">
				<h5 class="title">{{collaborationroom.displayName}}</h5>
				<div class="option">
					<div class="input-radio-wrapper">
						<label>
							<input type="radio" v-model="collaborationroom.recordOption" :value="'new'" />
							<span class="label" v-localization="{ key: 'convertInquiryDialog.createNew' }"></span>
						</label>
					</div>
				</div>
				<div class="option">
					<div class="input-radio-wrapper">
						<label>
							<input
								type="radio"
								v-model="collaborationroom.recordOption"
								:value="'existing'"
								:disabled="preference.recordOption === 'new'"
								:class="{ 'disabled-radio': preference.recordOption === 'new' }"
							/>
							<span
								:class="['label', { 'disabled-label': preference.recordOption === 'new' }]"
								v-localization="{ key: 'convertInquiryDialog.chooseExisting' }"
							></span>
						</label>
					</div>
					<control-lookup-editor v-if="collaborationroom.recordOption==='existing'"
										   :showRequired="true"
										   v-model="collaborationroom.selectedRecord"
										   :evaluationContext="evaluationContext"
										   :options="getLookupOptions(collaborationroom)"
										   :class="{invalid: !collaborationroom.isValid}"></control-lookup-editor>
				</div>
				<div class="option">
					<div class="input-radio-wrapper">
						<label>
							<input type="radio" v-model="collaborationroom.recordOption" :value="'no'" />
							<span class="label" v-localization="{ key: 'convertInquiryDialog.doNotConvertToCR' }"></span>
						</label>
					</div>
				</div>
			</div>
		</div>
		<div class="footer">
			<button class="cancel" @click="close" v-localization="{ key: 'common.dictionary.buttons.cancel'}"></button>
			<button @click="onSubmit" v-localization="{ key: 'common.dictionary.buttons.save'}"></button>
		</div>
		<div class="loading-overlay" v-show="isLoading"></div>
	</div>
</template>
<script>
/* eslint no-empty: 0 */
import Control from '@/Components/Entity/control';
import editorMixins from '@/Components/editor.mixins.js';
import {
	getFullRecordLink, showToastOrInfoDialog, Create, Update, Search, Get
} from '@acx-xms/data-functions/dist';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';
const ControlLookupEditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Lookup/control-lookup-editor');

export default {
	name: 'convert-inquiry-dialog',
	components: {
		Control,
		ControlLookupEditor
	},
	mixins: [editorMixins],
	props: { options: Object },
	computed: {
		showWarning() {
			const preferenceContact = sc.utils.findProperty(this.preference.selectedRecord, 'data.source.contactid', true);
			return preferenceContact &&
					this.contact.recordOption === 'existing' &&
					this.contact.selectedRecord &&
					preferenceContact.id !== this.contact.selectedRecord.data.Id;
		}
	},
	watch: {
		'preference.recordOption': function (newVal) {
			if (newVal === 'new' && this.collaborationroom.recordOption === 'existing') {
				this.collaborationroom.recordOption = 'new';
			}
		}
	},
	data() {
		return {
			editorTemplate: null,
			evaluationContext: this.options.evaluationContext || createEvaluationContext({}),
			controlContext: null,
			isLoading: false,
			stateKey: null,
			contact: {
				recordOption: 'new',
				selectedRecord: null,
				isValid: true,
				additionalFilters: []
			},
			preference: {
				recordOption: 'new',
				selectedRecord: null,
				isValid: true,
				additionalFilters: []
			},
			collaborationroom: {
				recordOption: 'new',
				selectedRecord: null,
				isValid: true,
				additionalFilters: []
			}
		};
	},
	async created() {
		this.configDataProvider = sc.classes.get('entityConfiguration.dataProvider');
		this.localization = sc.classes.get('localization.dataProvider');
		// even though we are creating contact - we have context of inquiry
		this.stateKey = this.registerModule('inquiry');
		const [contactEntity, preferenceEntity, crEntity] = await Promise.all([
			this.configDataProvider.fetchEntity('contact'), this.configDataProvider.fetchEntity('preference'), this.configDataProvider.fetchEntity('collaborationroom')
		]);

		await Promise.all([
			this.processData(contactEntity, 'contact', { subtype: 'vue-list' }),
			this.processData(preferenceEntity, 'preference', { usage: 'list' }),
			this.processData(crEntity, 'collaborationroom', { usage: 'list' })
		]);

		this.editorTemplate = await this.configDataProvider.getTemplate(this.contact.logicalName, 'edit', 'convert-inquiry-edit');
	},
	methods: {
		async processData(entity, entityName, condition) {
			const conditionKey = Object.keys(condition)[0];
			this[entityName].logicalName = entity.logicalName;
			this[entityName].displayName = await this.evaluationContext.evalAsync(entity.displayName);
			this[entityName].template = entity.templates.find(template => template[conditionKey] === condition[conditionKey]).template;
			// additional filters
			if (entity.defaultFilters && entity.defaultFilters.filter && entity.defaultFilters.filter.length) {
				this[entityName].additionalFilters = entity.defaultFilters.filter.map((f) => {
					return sc.classes.get(f.$type, f, null).toFilter();
				});
			}
		},
		addToErrorMessage(initMessage, name, customMessage) {
			const message = `– ${name}: Field is required \n`;
			return (initMessage || '') + (customMessage || message);
		},
		async onSubmit() {
			let validationResult = { isValid: true };
			this.contact.isValid = this.preference.isValid = this.collaborationroom.isValid = true;
			if (this.contact.recordOption === 'new') {
				validationResult = await this.validate(this.stateKey, this.contact.logicalName);
			} else {
				if (!this.contact.selectedRecord) {
					this.contact.isValid = false;
					validationResult.isValid = false;
					validationResult.message = this.addToErrorMessage(validationResult.message, this.contact.displayName);
				}
			}
			const preferenceHasCR = this.preference.selectedRecord && !!this.preference.selectedRecord.data.Source.collaborationroomid.id;
			if (this.preference.recordOption === 'existing') {
				if (!this.preference.selectedRecord || preferenceHasCR) {
					let customMessage = '';
					if (preferenceHasCR) {
						this.isLoading = true;
						const { Results: crOwner } = await Search(['contact'],
							[
								sc.classes.get('offsetSize.filter', 1),
								sc.classes.get('termFacet.filter', {
									logicalName: 'systemuserid.id',
									query: [this.preference.selectedRecord.data.Source.preferencecollaborationroomidcollaborationroom.ownerid.id]
								}).fillQuery(),
								sc.classes.get('selectedFields.filter', [
									{ logicalname: 'fullname' }
								]).fillQuery()
							]
						);
						const crName = this.preference.selectedRecord.data.Source.preferencecollaborationroomidcollaborationroom.name;
						const crid = this.preference.selectedRecord.data.Source.preferencecollaborationroomidcollaborationroom.collaborationroomid;
						customMessage = await this.localization.getLabelForCurrentLanguage('addPreferenceDialog.preference.alreadyused.error',
							[crid, crName, crOwner[0].Name]);
						this.isLoading = false;
					}
					this.preference.isValid = false;
					validationResult.isValid = false;
					validationResult.message = this.addToErrorMessage(validationResult.message, this.preference.displayName, customMessage);
				}
			}
			if (this.collaborationroom.recordOption === 'existing' && !this.collaborationroom.selectedRecord) {
				this.collaborationroom.isValid = false;
				validationResult.isValid = false;
				validationResult.message = this.addToErrorMessage(validationResult.message, this.collaborationroom.displayName);
			}
			if (validationResult && !validationResult.isValid) {
				this.showValidationError(validationResult.message);
				return;
			}
			this.isLoading = true;
			let convertedContact, convertedPreference, convertedCR;
			if (this.contact.recordOption === 'new') {
				const entityData = this.$store.state[this.stateKey].entityData;
				try {
					convertedContact = await Create(this.contact.logicalName, entityData);
				} catch (error) {
					this.errorhandler(error);
					return;
				}
			} else {
				convertedContact = this.contact.selectedRecord.data;
			}
			// we create collaborationroom record before preference as we need collaborationroomid for preference to not update preference record one more time
			if (this.collaborationroom.recordOption !== 'no') {
				let chatPromise;
				if (this.collaborationroom.recordOption === 'new') {
					convertedCR = await this.createCR(convertedContact.fullname || convertedContact.Source.fullname);
					chatPromise = Create('chat', {
						collaborationroomid: {
							logicalname: this.collaborationroom.logicalName,
							id: convertedCR.Id
						},
						public: true,
						name: convertedCR.name,
						teamid: convertedCR.teamid,
						coownerteamid: convertedCR.coownerteamid,
						brokerteamid: convertedCR.brokerteamid
					});
				} else {
					convertedCR = this.collaborationroom.selectedRecord.data;
					chatPromise = new Promise((resolve) => {
						Search('chat', [
							sc.classes.get('offsetFrom.filter', 0),
							sc.classes.get('offsetSize.filter', 1),
							sc.classes.get('termFacet.filter', {
								logicalName: 'collaborationroomid.id',
								query: [convertedCR.Id]
							}).fillQuery(),
							sc.classes.get('selectedFields.filter', [
								{ logicalname: 'chatparticipants.contactid' }
							]).fillQuery()
						]).then(results => resolve(results.Results[0]));
					});
				}
				await this.createCRRelatedRecords(convertedCR.Id, chatPromise, convertedContact.Id);
			}
			if (this.preference.recordOption !== 'no') {
				if (this.preference.recordOption === 'new') {
					/* If selected option 'new' - create preference */
					convertedPreference = await this.createPreference(convertedContact.Id, convertedCR && convertedCR.Id);
				} else {
					convertedPreference = this.preference.selectedRecord.data;
					const updateBody = {
						contact: {
							logicalname: this.contact.logicalName,
							id: convertedContact.Id
						}
					};
					if (convertedCR) {
						updateBody.collaborationroomid = {
							logicalname: this.collaborationroom.logicalName,
							id: convertedCR.Id
						};
					}
					await Update(this.preference.logicalName, convertedPreference.Id, updateBody);
				}
			}

			const inquiry = await this.updateInquiry(convertedContact.Id, convertedPreference && convertedPreference.Id, convertedCR && convertedCR.Id);
			const entity = sc.classes.get('entityReference', inquiry);
			this.$root.$emit('entity.changed', { entity });
			await showToastOrInfoDialog({
				toastMessageKey: this.options.params.toastMessageKey,
				informationDialogTextKey: this.options.params.informationDialogTextKey,
				options: { context: this }
			});

			this.close();
		},
		createPreference(contactId, crId) {
			const preferenceFields = ['name', 'saleorlease', 'areaminimum', 'areamaximum', 'propertytype', 'propertyclass', 'priceminimum',
				'pricemaximum', 'rentminimum', 'rentmaximum', 'geography1', 'geography2', 'geography3', 'requiredmoveindate'];
			const preferenceBody = {};
			preferenceFields.forEach(field => {
				const value = this.getFieldValue(field);
				if (value) {
					preferenceBody[field] = value;
				}
			});

			const commentsFields = [{
				title: 'Listing',
				value: 'inquirylistingidlisting.name'
			},
			{
				title: 'Availability',
				value: 'inquiryavailabilityidavailability.name'
			}, {
				title: 'Comments',
				value: 'message'
			}];
			const comments = [];
			commentsFields.forEach(field => {
				const value = this.getFieldValue(field.value);
				if (value) {
					comments.push(`${field.title}: ${value}`);
				}
			});
			preferenceBody.comments = comments.join('\r\n');
			preferenceBody.contactid = {
				logicalname: this.contact.logicalName,
				id: contactId
			};
			/* Configure collaboration room ID (CR was created or selected from exciting) */
			if (crId) {
				preferenceBody.collaborationroomid = {
					logicalname: this.collaborationroom.logicalName,
					id: crId
				};
			}
			return Create(this.preference.logicalName, preferenceBody);
		},
		createCR(contactName) {
			const propertytype = this.getFieldValue('inquirypropertytypelookuppropertytype.name') || '';
			const saleorlease = this.getFieldValue('inquirysaleorleaselookupsaleorlease.name') || '';
			const crName = [[propertytype, saleorlease].filter(Boolean).join(' '), contactName].filter(Boolean).join(' for ');
			return Create(this.collaborationroom.logicalName, { name: crName });
		},
		// create crlisting, cravailability, chat, chatparticipant
		async createCRRelatedRecords(crId, chatPromise, contactId) {
			const promiseArr = [];
			const listingId = this.getFieldValue('listingid.id');
			const availabilityId = this.getFieldValue('availabilityid.id');
			let crlistingPromise;
			if (listingId) {
				crlistingPromise = this.createRelatedRecord('crlisting', [{
					id: listingId,
					logicalname: 'listingid.id'
				}, {
					id: crId,
					logicalname: 'collaborationroomid.id'
				}],
				{
					collaborationroomid: {
						id: crId,
						logicalname: this.collaborationroom.logicalName
					},
					listingid: {
						id: listingId,
						logicalname: 'listing'
					},
					name: this.getFieldValue('inquirylistingidlisting.name'),
					archived: false
				});
			} else {
				crlistingPromise = Promise.resolve();
			}
			if (availabilityId) {
				const availabilityResults = await Get('availability', availabilityId);
				const availabilityListingId = availabilityResults.Source.listingid.id;
				if (listingId === availabilityListingId) {
					promiseArr.push(new Promise((resolve) => {
						crlistingPromise.then(crlisting => {
							resolve(this.createRelatedRecord('cravailability', [{
								id: availabilityId,
								logicalname: 'availability.id'
							}, {
								id: crId,
								logicalname: 'collaborationroomid.id'
							}],
							{
								collaborationroomid: {
									id: crId,
									logicalname: this.collaborationroom.logicalName
								},
								availabilityid: {
									id: availabilityId,
									logicalname: 'availability'
								},
								name: this.getFieldValue('inquiryavailabilityidavailability.name'),
								crlistingid: {
									id: crlisting.Id,
									logicalname: 'crlisting'
								},
								listingid: crlisting.listingid,
								archived: false
							}));
						});
					}));
				} else {
					promiseArr.push(new Promise((resolve) => {
						crlistingPromise.then(() => {
							return this.createRelatedRecord('crlisting', [{
								id: availabilityListingId,
								logicalname: 'listingid.id'
							}, {
								id: crId,
								logicalname: 'collaborationroomid.id'
							}],
							{
								collaborationroomid: {
									id: crId,
									logicalname: this.collaborationroom.logicalName
								},
								listingid: {
									id: availabilityListingId,
									logicalname: 'listing'
								},
								name: availabilityResults.Source.availabilitylistingidlisting.name,
								archived: false
							});
						}).then(crlisting => {
							resolve(this.createRelatedRecord('cravailability', [{
								id: availabilityId,
								logicalname: 'availability.id'
							}, {
								id: crId,
								logicalname: 'collaborationroomid.id'
							}],
							{
								collaborationroomid: {
									id: crId,
									logicalname: this.collaborationroom.logicalName
								},
								availabilityid: {
									id: availabilityId,
									logicalname: 'availability'
								},
								name: this.getFieldValue('inquiryavailabilityidavailability.name'),
								crlistingid: {
									id: crlisting.Id,
									logicalname: 'crlisting'
								},
								listingid: crlisting.listingid,
								archived: false
							}));
						});
					}));
				}
			} else {
				promiseArr.push(crlistingPromise);
			}

			const currentChat = await chatPromise;
			let participantExist = false;
			if (currentChat.Source) {
				participantExist = currentChat.Source.chatparticipants.some((participant) => {
					return participant.contactid.id === contactId;
				});
			}
			if (!participantExist) {
				promiseArr.push(new Promise((resolve) => {
					chatPromise.then((chat) => {
						resolve(this.createRelatedRecord('chatparticipant', [{
							id: contactId,
							logicalname: 'contact.id'
						}, {
							id: chat.Id,
							logicalname: 'chat.id'
						}],
						{
							chatid: {
								id: chat.Id,
								logicalname: 'chat'
							},
							contactid: {
								id: contactId,
								logicalname: this.contact.logicalName
							},
							ispending: true
						}));
					});
				}));
			}

			Promise.all(promiseArr);
		},
		async createRelatedRecord(logicalname, arrayFilters, body) {
			const filters = [sc.classes.get('offsetFrom.filter', 0),
				sc.classes.get('offsetSize.filter', 1),
				sc.classes.get('selectedFields.filter', []).fillQuery()];
			arrayFilters.forEach(f => {
				filters.push(sc.classes.get('termFacet.filter', {
					logicalName: f.logicalname,
					query: [f.id]
				}).fillQuery());
			});
			const result = await Search(logicalname, filters);
			if (result.Total) {
				return result.Results[0];
			} else {
				return Create(logicalname, body);
			}
		},
		updateInquiry(contactId, preferenceId, crId) {
			const updateInquiryBody = {
				convertedcontactid: {
					logicalname: this.contact.logicalName,
					id: contactId
				},
				inquirystatus: {
					logicalname: 'lookupinquirystatus',
					id: '387ff1de-5cd0-421d-bb61-052ff54c8994' // Converted
				},
				statusreason: {
					logicalname: 'lookupstatusreason',
					id: '76fcca5e-a173-4b56-8386-15cd8e3a316c' // Qualified
				}
			};
			if (preferenceId) {
				updateInquiryBody.convertedpreferenceid = {
					logicalname: this.preference.logicalName,
					id: preferenceId
				};
			}
			if (crId) {
				updateInquiryBody.convertedcrid = {
					logicalname: this.collaborationroom.logicalName,
					id: crId
				};
			}
			return Update('inquiry', this.options.params.entities[0].Id, updateInquiryBody);
		},
		getFieldValue(name) {
			return this.evaluationContext.eval({
				$type: 'expression.field',
				name,
				schema: 'source'
			});
		},
		getLookupOptions(entity) {
			return {
				placeholder: `Find ${entity.displayName}`,
				template: entity.template,
				fetchData: (searchQuery, startFrom = 0, pageSize = 1000) => {
					let selectedFields = [];
					let filters = [
						sc.classes.get('offsetFrom.filter', startFrom),
						sc.classes.get('offsetSize.filter', pageSize)
					];
					let hasLogicalFieldFilter = false;
					switch (entity.logicalName) {
					case 'collaborationroom':
						selectedFields = [
							{ logicalname: 'name' }
						];
						if (
							this.preference.recordOption === 'existing' &&
									this.preference.selectedRecord &&
									this.preference.selectedRecord.data.Source.collaborationroomid.id
						) {
							filters.push(sc.classes.get('termFacet.filter', {
								logicalName: 'collaborationroomid',
								query: [this.preference.selectedRecord.data.Source.collaborationroomid.id]
							}).fillQuery());
						}
						break;
					case 'preference':
						selectedFields = [
							{ logicalname: 'preferencesaleorleaselookupsaleorlease.name' },
							{ logicalname: 'preferencepropertytypelookuppropertytype.name' },
							{ logicalname: 'preferencecollaborationroomidcollaborationroom.ownerid' },
							{ logicalname: 'preferencecollaborationroomidcollaborationroom.name' },
							{ logicalname: 'preferencecollaborationroomidcollaborationroom.collaborationroomid' },
							{ logicalname: 'preferencecontactidcontact.fullname' },
							{ logicalname: 'collaborationroomid.id' },
							{ logicalname: 'contactid.id' },
							{ logicalname: 'propertytype' },
							{ logicalname: 'areaminimum' },
							{ logicalname: 'areamaximum' },
							{ logicalname: 'priceminimum' },
							{ logicalname: 'pricemaximum' },
							{ logicalname: 'name' }
						];
						filters.push(sc.classes.get('termFacet.filter', {
							logicalName: 'recordstate.id',
							query: ['9e817a63-452c-e811-8320-7824af3b452f'],
							negative: true
						}).fillQuery());
						if (searchQuery) {
							filters.push(this.getPreferenceLogicalFilter(searchQuery));
							hasLogicalFieldFilter = true;
						}
						break;
					case 'contact':
						selectedFields = [
							{ logicalname: 'contactsystemuseridsystemuser.avatarid.id' },
							{ logicalname: 'recordstate.id' },
							{ logicalname: 'salutation' },
							{ logicalname: 'firstname' },
							{ logicalname: 'lastname' },
							{ logicalname: 'account' },
							{ logicalname: 'jobtitle' }
						];
						break;
					}

					filters.push(sc.classes.get('selectedFields.filter', selectedFields).fillQuery());
					if (searchQuery && !hasLogicalFieldFilter) {
						filters.push(sc.classes.get('field.filter', {
							logicalName: '_all',
							query: searchQuery
						}));
					}
					if (entity.additionalFilters) {
						filters = filters.concat(entity.additionalFilters);
					}

					return Search(entity.logicalName, filters).then(({ Results, Total }) => {
						return {
							total: Total,
							items: Results.map(res => {
								return {
									displayName: res.Name,
									data: res
								};
							})
						};
					});
				}
			};
		},
		getPreferenceLogicalFilter(searchQuery) {
			const logicalFilter = {
				$type: 'search.logicalFilter',
				operator: {
					$type: 'search.or',
					operator: [
						{
							$type: 'search.operand',
							filter: [{
								$type: 'search.logicalFieldFilter',
								query: searchQuery,
								logicalName: 'name',
								kind: 'PhrasePrefix'
							}]
						},
						{
							$type: 'search.operand',
							filter: [{
								$type: 'search.logicalFieldFilter',
								query: searchQuery,
								logicalName: 'preferencecontactidcontact.fullname',
								kind: 'PhrasePrefix'
							}]
						}
					]
				}
			};

			return sc.classes.get('search.logicalFilter', logicalFilter).toFilter();
		},
		close() {
			this.$parent.$emit('close');
		},
		async errorhandler(error) {
			this.isLoading = false;
			if (typeof error === 'object' && error.Code === 'UniqueConstraintViolation') {
				const { metadata, errorHyperlinked, fieldsToSkip = [] } = await this.configDataProvider.fetchEntity(this.contact.logicalName);
				const metaFieldsObj = metadata.detail.fields;
				const entityFieldsObj = error.Detail.Entity;
				const errorFields = Object.keys(entityFieldsObj).map(field => {
					return (metaFieldsObj[field].name && metaFieldsObj[field].name.base) ? metaFieldsObj[field].name.base : this.contact.logicalName;
				}).filter(item => fieldsToSkip.indexOf(item)).join(', ');
				try {
					const { Name: name } = await Get(this.contact.logicalName, error.Detail.DuplicateId);
					let duplicateRecord = name;
					let needParsing = true;
					if (errorHyperlinked) {
						const recordHref = getFullRecordLink(this.$route, {
							id: error.Detail.DuplicateId,
							logicalname: this.contact.logicalName
						});
						duplicateRecord = `<a href=${recordHref} target='_blank'>${name}</a>`;
						needParsing = false;
					}
					const customError = await this.localization.localize('dialogues.duplicateError', [errorFields, duplicateRecord]);
					sc.utils.errorMessage.byMessage(customError, '', null, needParsing);
				} catch {
					sc.utils.errorMessage.byResponse(error);
				}
			} else if (error && error.disableErrorDialog) {

			} else {
				sc.utils.errorMessage.byResponse(error);
			}
		}
	}
};
</script>
<style src="./convert-inquiry-dialog.less" scoped></style>
