<template>
	<tree ref="tree" :data="treeData" :options="treeOptions" v-perfectscroll="{ enabled:true }" class="folder-tree">
		<span class="tree-text" slot-scope="{ node }">
			<template v-if="node.children.length && !node.expanded()">
				<svg class="svg-icon-folder">
					<use xlink:href="#layout-icons-folder-plus"></use>
				</svg>
			</template>
			<template v-if="node.children.length && node.expanded()">
				<svg class="svg-icon-folder">
					<use xlink:href="#layout-icons-folder-minus"></use>
				</svg>
			</template>
			<template>
				<svg class="layout-icons-folder">
					<use :xlink:href="[node.expanded() ? '#layout-icons-folder-opened' : '#layout-icons-folder-closed']"></use>
				</svg>
				<p :class="[node.data.active ? 'p2':'p13']">{{ node.text }}</p>
			</template>
		</span>
	</tree>
</template>
<script>
import LiquorTree from 'liquor-tree';
export default {
	name: 'folder-tree',
	props: {
		predefinedId: String,
		rootName: String,
		foldersSr: {
			type: Array,
			required: true
		},
		treeLogicalName: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			treeData: [],
			treeOptions: { parentSelect: true }
		};
	},
	components: { tree: LiquorTree },
	watch: {
		treeData: {
			handler: function (newVal) {
				this.$refs.tree.setModel(newVal);
			},
			deep: true
		}
	},
	created() {
		this.treeData.push({
			data: {
				folderId: null,
				active: true
			},
			text: this.rootName || 'My Documents',
			state: { expanded: true },
			expanded() {
				return this.state.expanded;
			}
		});
		this.fillTree(this.treeData);
		if (this.predefinedId) {
			this.onSetPredefinedActive(this.predefinedId);
		}
	},
	mounted() {
		this.$refs.tree.$on('node:clicked', this.onClick);
		this.$refs.tree.$on('node:expanded', this.onExpand);
		this.$refs.tree.$on('node:collapsed', this.onCollapse);
	},
	methods: {
		onSetPredefinedActive(id) {
			const result = this.searchTree(this.treeData[0], id);
			this.expandParent(result.data.parentId);
			this.setActive(this.predefinedId);
		},
		fillTree(arr) {
			if (!arr.length) {
				return;
			}
			let children = [];
			arr.forEach(folder => {
				children = this.filterFolder(folder.data.folderId);
				this.$set(folder, 'children', children);
				this.fillTree(folder.children);
			});
		},
		filterFolder(parentId) {
			if (!parentId) { // root folder
				return this.foldersSr.filter(item => !item.parentfolder).map(item => {
					return {
						text: item.title,
						data: {
							folderId: item[`${this.treeLogicalName}id`],
							parentId: item.parentfolder ? item.parentfolder.id : null
						}
					};
				});
			}
			return this.foldersSr.filter(item => item.parentfolder && item.parentfolder.id === parentId).map(item => {
				return {
					text: item.title,
					data: {
						folderId: item[`${this.treeLogicalName}id`],
						parentId: item.parentfolder ? item.parentfolder.id : null
					}
				};
			});
		},
		searchTree(element, matchingId) {
			if (element.data.folderId === matchingId) {
				return element;
			} else if (element.children != null) {
				let result = null;
				for (let i = 0; result == null && i < element.children.length; i++) {
					result = this.searchTree(element.children[i], matchingId);
				}
				return result;
			}
			return null;
		},
		setActive(id) {
			const result = this.searchTree(this.treeData[0], id);
			this.$set(result.data, 'active', true);
			if (result.data.hasOwnProperty('parentId')) {
				this.setActive(result.data.parentId);
			}
		},
		expandParent(parentId) {
			if (parentId) {
				const parent = this.searchTree(this.treeData[0], parentId);
				this.$set(parent, 'state', { expanded: true });
				this.expandParent(parent.data.parentId);
			}
		},
		onClick(node) {
			this.$emit('treeClicked', node);
		},
		onExpand(node) {
			this.$emit('treeExpanded', node);
		},
		onCollapse(node) {
			this.$emit('treeCollapsed', node);
		}

	}
};
</script>
<style src="./folder-tree.less" scoped></style>
