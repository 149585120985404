<template>
	<div class="reportingservice-editor-mci">
		<div class="right-panel" v-perfectscroll="{enable: true}">
			<div class="selected-template">
				<span class="title p4 pl5" v-localization="{key: 'dialogues.RS4.templates.templateLabel'}"></span>
				<control v-if="reportTemplate && totalCOTemplates"
						 :name="reportTemplate.$type"
						 :contentProps.once="reportTemplate"
						 :evaluationContext="evaluationContext">
				</control>
				<div v-else-if="!totalCOTemplates" class="no-cotemplates">
					There are no templates to show yet
				</div>
				<div class="thumbnail-annotated" v-if="!selectedTemplate">
					<div class="thumbnail">
						<div class="image-node">
							<svg class="svg-icon">
								<use xlink:href="#layout-icons-menu_templates" class="layout-icons-menu_templates"></use>
							</svg>
						</div>
					</div>
				</div>
				<div class="campaign-buttons">
					<button type="button"
							class="button cancel"
							v-localization="{key: 'dialogues.RS4.templates.changeTemplateButton'}"
							@click="$emit('openTemplateList')"
							:disabled="disabledButtonChangeTemplate"></button>
					<button type="button"
							class="button cancel"
							v-localization="{key: 'dialogues.RS4.templates.previewCampaignButton'}"
							@click="previewCampaign"
							:disabled="disabledButton"></button>
					<button type="button"
							class="button cancel"
							v-localization="{key: 'dialogues.RS4.templates.editCampaignButton'}"
							@click="editCampaign"
							:disabled="disabledButton"></button>
				</div>
				<span v-if="!selectedTemplate" class="noTemplateLabel" v-localization="{key: 'dialogues.RS4.templates.noTemplate'}"></span>
			</div>

			<div class="body">
				<control v-if="editorTemplate"
						 :name="editorTemplate.$type"
						 :contentProps.once="editorTemplate"
						 :evaluationContext="evaluationContext">
				</control>
			</div>
		</div>
		<div class="footer">
			<span class="buttons">
				<button type="submit"
						@click="saveAndStartCampaign"
						v-localization="{key: 'dialogues.RS4.mcidialog.saveAndStartCampaign'}"
						:disabled="disabledButton"></button>
				<button type="button"
						class="cancel"
						@click="close"
						v-localization="{key: 'dialogues.RS4.dialog.cancel'}"></button>
				<!-- <button type="submit"
						@click="save"
						v-localization="{key: 'common.dictionary.buttons.save'}"
						:disabled="disabledButton"></button> -->
			</span>
		</div>
	</div>
</template>
<script>
/* eslint vue/no-unused-components: 0 */
import Control from '@/Components/Entity/control';
import coDataProvider from '@/Data/DataProviders/coDataprovider';
import {
	generateGuid, Create, Search, SearchByIds, CreateBulk
} from '@acx-xms/data-functions/dist';
import reportingServiceEditorMixin from './reportingservice.editor.mixins.js';
import {
	createCampaign, startCampaign, createDefaultList, getDefaultList, addMembersToList, getMembersStatus
} from '@/Data/DataProviders/coMCIDataprovider';
const ControlLookupEditorWrapper = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Editor/Controls/Lookup/control-lookup-editor-wrapper');

const CAMPAIGN_STATUSES = {
	SENT: 'Sent',
	DRAFT: 'Draft'
};

export default {
	name: 'reportingservice-mci-editor',
	components: {
		Control,
		ControlLookupEditorWrapper
	},
	mixins: [reportingServiceEditorMixin],
	data() {
		return { listChanged: false };
	},
	computed: {
		changedFields() {
			return this.$store.getters[this.stateKey + '/changedFields'];
		},
		disabledButtonChangeTemplate() {
			return this.isElementListEmpty || !this.totalCOTemplates;
		},
		disabledButton() {
			return this.disabledButtonChangeTemplate || !this.hasItemsMatchedToTemplate;
		}
	},
	watch: {
		recordsList: {
			handler: function (newVal, oldVal) {
				this.listChanged = true;
				this.hasItemsMatchedToTemplate = newVal.filter(list => list.matchedToTemplate).some(list => list.itemsCount);
			},
			deep: true
		}
	},
	methods: {
		isElementListEmptyCallback(val) {
			this.isElementListEmpty = val;
		},
		displayError() {
			sc.events.emit('vue.dialog.info', {
				title: 'Start Email Campaign',
				message: 'The report generation has been failed. Please, re-start the campaign.',
				showFooter: false
			});
		},
		async save() {
			if (!this.entityData['campaign-name']) {
				this.$store.commit(this.stateKey + '/setValidState', {
					field: 'campaign-name',
					valid: false
				});
				this.showValidationError('– Email Campaign Name: Field is required<br>');
				return;
			}
			try {
				await this.createArtifact();
				await this.createRecord(CAMPAIGN_STATUSES.DRAFT);
				this.$parent.$emit('isGenerating', false);
				this.close();
			} catch (e) {
				this.$parent.$emit('isGenerating', false);
				if (e === 'Reached max retry attepmts') {
					this.displayError();
				} else {
					sc.utils.errorMessage.byMessage(e);
				}
			}
		},
		async saveAndStartCampaign() {
			const validationResult = await this.validate();
			if (!validationResult.isValid) {
				this.showValidationError(validationResult.message);
				return;
			}
			try {
				await this.createArtifact();
			} catch (e) {
				this.$parent.$emit('isGenerating', false);
				if (e === 'Reached max retry attepmts') {
					this.displayError();
				} else {
					sc.utils.errorMessage.byMessage(e);
				}
				return;
			}
			const marketingList = this.entityData['marketing-list'];
			const broker = this.entityData.broker;
			const getOrCreateDefaultList = async () => {
				let list;
				try {
					list = await getDefaultList();
				} catch (e) {
					list = await createDefaultList();
				}
				return list;
			};
			let [contacts, brokerUser, list] = await Promise.all([
				Search('staticlistmember',
					[
						sc.classes.get('offsetSize.filter', 10000),
						sc.classes.get('termFacet.filter', {
							logicalName: 'staticlist.id',
							query: [marketingList.id]
						}).fillQuery(),
						sc.classes.get('termFacet.filter', {
							logicalName: 'recordstate.id',
							query: ['8d113fa8-3015-4060-a107-14cedcd19dd3']
						}).fillQuery(),
						sc.classes.get('selectedFields.filter', [
							{
								logicalname: 'staticlistmemberentityreferencecontact.email',
								title: 'contactEmail'
							}
						]).fillQuery()
					]),
				SearchByIds('systemuser', [broker.id],
					[
						sc.classes.get('offsetSize.filter', 1),
						sc.classes.get('selectedFields.filter', [
							{ logicalname: 'email' },
							{ logicalname: 'fullname' }
						]).fillQuery()
					]),
				getOrCreateDefaultList()
			]);
			brokerUser = brokerUser.Results[0];

			const getSegment = (list, marketingList) => {
				return (list.segments || []).find(segment => segment.name.toLowerCase() === marketingList.name.toLowerCase());
			};

			let segment = getSegment(list, marketingList);
			const members = contacts.Results.map(item => item.Source.contactEmail);
			const status = await addMembersToList(members, [marketingList.name]);
			const membersResult = await getMembersStatus(status.id);
			const failedMembers = membersResult.erroredOperations; const totalMembers = membersResult.totalOperations;
			try {
				if (failedMembers === totalMembers && !(segment && segment.memberCount)) {
					throw Error();
				} else if (failedMembers > 0) {
					sc.events.emit('vue.dialog.info', {
						title: 'Contacts Import',
						message: `Imported contacts: ${totalMembers - failedMembers} out of ${totalMembers}. Please make sure that the emails you provided are correct.`,
						showFooter: false
					});
				}
				if (!segment) {
					list = await getDefaultList();
					segment = getSegment(list, marketingList);
				}
				const campaign = await createCampaign({
					tagId: segment.id,
					title: this.entityData['campaign-name'],
					previewText: this.entityData['campaign-name'],
					fromName: brokerUser.Source.fullname,
					replyTo: brokerUser.Source.email,
					subject: this.entityData['campaign-name'],
					artifactId: this.artifact.artifactId
				});
				this.campaignId = campaign.id;
				await startCampaign(campaign.id);
				await this.createRecord(CAMPAIGN_STATUSES.SENT);
				this.close();
			} catch (e) {
				sc.events.emit('vue.dialog.info', {
					title: 'Start Campaign',
					message: 'Your Campaign is not ready to create or send. Please make sure that the data you provided is valid and correct',
					showFooter: false
				});
			} finally {
				this.$parent.$emit('isGenerating', false);
			}
		},
		async previewCampaign() {
			const url = 'ohc/app/page/artifact-preview?artifactId=';
			this.editPreviewCampaign(url);
		},
		async editCampaign() {
			const url = 'ohc/app/page/artifact/';
			this.editPreviewCampaign(url);
		},
		async editPreviewCampaign(url) {
			const newWindow = window.open(
				`${window.location.origin}/in-progress/${encodeURIComponent('Artifact generation is in progress. Please wait...')}`,
				'_blank'
			);

			if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
				sc.events.emit('vue.dialog.info', {
					title: 'Start Email Campaign',
					message: 'Please, allow pop-ups on in the browser\'s settings and re-open the window to continue',
					showFooter: false
				});
			} else {
				try {
					const [coURL, token] = await Promise.all([coDataProvider.getCoURL(), coDataProvider.getToken()]);
					await this.createArtifact();
					const tokenUrl = url.includes('?') ? `&token=${token}` : `?token=${token}`;
					newWindow.location.href = `${coURL}${url}${this.artifact.artifactId}${tokenUrl}`;
				} catch (e) {
					this.$parent.$emit('isGenerating', false);
					newWindow.close();
					if (e === 'Reached max retry attepmts') {
						this.displayError();
					} else {
						sc.utils.errorMessage.byMessage(e);
					}
					return;
				}
				this.$parent.$emit('isGenerating', false);
			}
		},
		async createRecord(campaignStatus) {
			const body = {
				name: this.entityData['campaign-name'],
				brokerid: this.entityData.broker,
				artifactid: this.artifact.artifactId,
				...(this.campaignId && { mcicampaignid: this.campaignId }),
				systemuserserviceaccountid: sc.classes.get('entityReference', this.options.systemuserserviceaccount),
				status: campaignStatus
			};
			const campaign = await Create('marketingcampaign', body);
			// START / TODO: Temporary marketingcampaignmember for single staticlist. Rework after Wizard will be done.
			const temporaryStaticlsit = {
				id: generateGuid(),
				method: 'POST',
				url: 'marketingcampaignmember',
				body: {
					type: 'marketingcampaignmember',
					name: ' ',
					marketingcampaignid: {
						id: campaign.Id,
						logicalname: 'marketingcampaign'
					},
					recordid: this.entityData['marketing-list']

				}
			};
			const records = [temporaryStaticlsit];
			// END
			const recordsList = [];
			this.recordsList.filter(item => item.matchedToTemplate).forEach(list => recordsList.push(list.items.map(item => {
				return {
					id: item.id,
					logicalname: item.logicalname
				};
			})));
			recordsList.flat().forEach(rec => {
				const record = {
					id: generateGuid(),
					method: 'POST',
					url: 'marketingcampaignmember',
					body: {
						type: 'marketingcampaignmember',
						name: ' ', // required field in EDGE
						marketingcampaignid: {
							id: campaign.Id,
							logicalname: 'marketingcampaign'
						},
						recordid: {
							id: rec.id,
							logicalname: rec.logicalname
						}
					}
				};
				records.push(record);
			});

			await CreateBulk(records, { async: true });
		}
	}
};
</script>
<style src="./reportingservice-uiw-editor.less" scoped></style>
