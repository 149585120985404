var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ms-edit-list" }, [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ],
      staticClass: "loading-overlay large"
    }),
    _vm._v(" "),
    _vm.lookupTemplate
      ? _c(
          "div",
          { staticClass: "list-editor" },
          [
            _c("span", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: { key: "staticListsPanel.editor.searchLookupName" },
                  expression:
                    "{key: 'staticListsPanel.editor.searchLookupName'}"
                }
              ],
              staticClass: "h5"
            }),
            _vm._v(" "),
            _c("control-lookup", { attrs: { options: _vm.getLookupOptions() } })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "marketspace-marketing-lists" },
      [
        _c("component-search-results", {
          attrs: {
            options: _vm.componentOptions,
            evaluationContext: _vm.evaluationContext
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "footer-wrapper" }, [
      _c("div", { staticClass: "footer" }, [
        _c("span", { staticClass: "buttons" }, [
          _c("button", {
            directives: [
              {
                name: "localization",
                rawName: "v-localization",
                value: { key: "common.dictionary.buttons.close" },
                expression: "{key: 'common.dictionary.buttons.close'}"
              }
            ],
            attrs: { type: "button" },
            on: { click: _vm.close }
          })
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }