<template>
	<div class="create-dealroom-dialog editor">
		<div class="loading-overlay" v-show="isLoading"></div>
		<div class="body" v-perfectscroll="{enable: true}">
			<control v-if="editorTemplate" :name="editorTemplate.$type"
					 :contentProps.once="editorTemplate"
					 :evaluationContext="controlContext"></control>
		</div>

		<div class="footer">
			<div class="dialog-buttons">
				<button @click="cancel" class="cancel" type="button" v-localization="{key: 'common.dictionary.buttons.cancel'}"></button>
				<button @click="save" type="button" v-localization="{key: 'common.dictionary.buttons.save'}"></button>
			</div>
		</div>
	</div>
</template>
<script>
import Control from '@/Components/Entity/control.vue';
import createDocumentMixins from '@/Components/editor.mixins.js';
import {
	showToastOrInfoDialog, Create, Update
} from '@acx-xms/data-functions/dist';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';

export default {
	name: 'dealroom-add-nda',
	components: { Control },
	props: { options: Object },
	mixins: [createDocumentMixins],
	data() {
		return {
			editorTemplate: null,
			isLoading: false
		};
	},
	async created() {
		this.configDataProvider = sc.classes.get('entityConfiguration.dataProvider');
		this.controlContext = createEvaluationContext(this.options.params.entity);
		this.logicalName = this.controlContext.eval(this.options.params.logicalName);
		this.localization = sc.classes.get('localization.dataProvider');
		this.stateKey = this.registerModule(this.logicalName);
		const editorTemplate = await this.configDataProvider
			.getTemplate(this.logicalName, this.options.editorTemplate.usage, this.options.editorTemplate.subtype);

		this.editorTemplate = editorTemplate && editorTemplate.content;
	},
	methods: {
		async processNda() {
			const data = this.$store.state[this.stateKey].entityData;
			if (!data.ndaid.isExisting && !this.file) {
				return null;
			}
			let documentRef = null;
			let documentTitle = '';
			if (!data.ndaid.isExisting && this.file) {
				const document = await this.createRecord('document',
					{
						category: 'NDA',
						filename: data.ndaid.uploadedFileName,
						title: data.ndaid.uploadedFileName.split('.').slice(0, -1).join('.')

					}, this.file);

				documentRef = sc.classes.get('entityReference', document);
				documentTitle = document.title;
			} else {
				documentRef = sc.classes.get('entityReference', data.ndaid.selectedFile.data);
				documentTitle = documentRef.name;
			}
			return {
				type: 'shareddocument',
				title: documentTitle,
				documentid: documentRef,
				nda: true
			};
		},
		async save() {
			const validationResult = await this.validate(this.stateKey);
			if (!validationResult.isValid) {
				this.showValidationError(validationResult.message);
				return;
			}
			this.isLoading = true;

			const sharedDocBody = await this.processNda();
			try {
				const shareddoc = await this.createRecord(sharedDocBody.type, {
					dealroomid: {
						logicalname: this.logicalName,
						id: this.options.params.entity.id
					},
					...sharedDocBody
				});

				await Update(this.logicalName, this.options.params.entity.id, { ndaid: sc.classes.get('entityReference', shareddoc) });

				if (this.options.refreshEvent) {
					this.$root.$emit(this.options.refreshEvent);
				}
			} catch (e) {
				sc.utils.errorMessage.byMessage(e);
			}
			await showToastOrInfoDialog({
				toastMessageKey: this.options.toastMessageKey,
				informationDialogTextKey: this.options.informationDialogTextKey,
				options: { context: this }
			});
			this.isLoading = false;
			this.$parent.$emit('close');
		},
		createRecord(entity, data, file) {
			return Create(entity, data, file)
				.then((results) => {
					return results;
				})
				.catch((e) => {
					sc.utils.errorMessage.byMessage(e);
				});
		},
		cancel() {
			this.$parent.$emit('close');
		}
	}
};
</script>
<style src="./create-dealroom-dialog.less" scoped></style>
