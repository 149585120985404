<template>
    <div class="dialog-step">
        <div class="loading-overlay large" v-show="isLoading"></div>
        <div class="list-content">
            <div class="control-tableView inner-search-results">
                <div class="tableview-header">
                    <div class="cell first-column">
                        <span class="label-header" v-localization="{ key: 'import.TypeOfRecords'}"></span>
                    </div>
                    <div class="cell second-column">
                        <span class="label-header" v-localization="{ key: 'import.fieldsIncluded'}"></span>
                    </div>
                    <div class="cell third-column">
                        <span class="label-header" v-localization="{ key: 'import.fieldsExcluded'}"></span>
                    </div>
                </div>
                <div class="tableview-content">
                    <div class="content-scroll-wrapper">
                        <div class="row">
                            <div class="table-cell first-column">
                                <span class="label">{{options.displayName}}</span>
                            </div>
                            <div class="table-cell second-column">
                                <span class="label">{{Object.keys(options.selectedFields).length}}</span>
                            </div>
                            <div class="table-cell third-column">
                                <span class="label">{{options.lengthFields-Object.keys(options.selectedFields).length}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class='footer'>
            <span class='buttons'>
                <button type="button"
                        @click="nextStep"
                        v-localization="{key: 'import.startImport'}"></button>
                <button type="button"
                        class="cancel"
                        @click="previousStep"
                        v-localization="{key: 'import.back'}"></button>
            </span>
        </div>
    </div>
</template>
<script>
/* eslint vue/no-unused-components: 0 */
import importProvider from '@/Data/DataProviders/import.dataProvider';
const ControlTableView = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Table/control-table-view');
export default {
	name: 'import-step-three',
	props: {
		step: Number,
		options: Object
	},
	components: { ControlTableView },
	data() {
		return {
			isImported: false,
			isLoading: false
		};
	},
	methods: {
		async nextStep() {
			this.isLoading = true;
			const result = await importProvider.submit(this.options.fileGuid, this.options.entityName, this.options.selectedFields);
			result.recordsCount = this.options.recordsCount;
			this.$parent.$emit('changeStep', {
				step: this.step + 1,
				result: {
					fileGuid: this.options.fileGuid,
					total: this.options.recordsCount
				}
			});
			this.isLoading = false;
		},
		previousStep() {
			this.$parent.$emit('changeStep', {
				step: this.step - 1,
				result: { fieldsMapping: this.options.fieldsMapping }
			});
		}
	}
};
</script>
<style src="@/Components/Control/Table/control-table-view.less" scoped></style>
<style src="@/Components/Control/Table/control-table-view-row.less" scoped></style>
<style src="./import-steps.less" scoped></style>
