<template>
	<div :class="{'open-folder': true, 'showLink': showLink}" @click="openFolder" :title="title">
		<svg class='folder-icon'>
			<use xlink:href='#layout-icons-folder-closed'></use>
		</svg>
		<span class="text oh-ellipsis">{{title}}</span>
	</div>
</template>
<script>
export default {
	name: 'open-folder',
	props: {
		options: Object,
		evaluationContext: Object
	},
	methods: {
		openFolder() {
			if (!this.showLink) {
				return;
			}
			this.$root.$emit(this.stateNamespace + '.setActiveFolder',
				{
					data: {
						folderId: this.folderid,
						parentId: this.parentId,
						type: this.type
					}
				});
		}
	},
	created() {
		this.title = this.evaluationContext.eval(this.options.title);
		this.showLink = this.options.showLink && this.evaluationContext.eval(this.options.showLink);
		if (this.showLink) {
			this.folderid = this.options.folderid && this.evaluationContext.eval(this.options.folderid);
			this.type = this.options.type && this.evaluationContext.eval(this.options.type);
			this.parentId = this.options.parentId && this.evaluationContext.eval(this.options.parentId);
			this.stateNamespace = this.options.stateNamespace && this.evaluationContext.eval(this.options.stateNamespace);
		}
	}
};
</script>
<style src="./open-folder.less" scoped></style>
