import { SEOAfterSaveAspect } from './aspects/SEOAfterSave.aspect';

export class SEO {
	constructor(options) {
		this.options = options;
	}

	installAspects() {
		return [new SEOAfterSaveAspect(this.options)];
	}
}
