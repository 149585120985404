<template>
	<div>
		<control v-if="visible"
				 :name="single.$type"
				 :contentProps.once="single"
				 :evaluationContext="evaluationContext"
				 v-for="single in controls" :key="single.key"></control>
	</div>
</template>
<script>
import helperMethods from './../../ComponentSet/component-set-evaluation-context-helper.methods';

export default {
	name: 'control-table-view-column',
	props: {
		options: Object,
		evaluationContext: Object
	},
	components: { Control: () => import('./../../Entity/control.vue') },
	data() {
		return {
			controls: this.options.control.map((ctrl) => {
				ctrl.key = helperMethods.getRandomArbitary(1, 10000000000000000).toFixed();
				return ctrl;
			}),
			visible: (this.options.visible) !== void 0 ? this.evaluationContext.eval(this.options.visible) : true
		};
	},
	mounted() {
	},
	created() {
	},
	methods: {
		getAdditionalId: function () {
			return helperMethods.getRandomArbitary(1, 10000000000000000).toFixed();
		}
	},
	beforeDestroy() {
	}
};
</script>
