var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "control-texteditor" }, [
    _c(
      "div",
      {
        staticClass: "input",
        class: { disabled: !_vm.enable, invalid: !_vm.isValid }
      },
      [
        _vm.showRequiredMark
          ? _c("div", { staticClass: "required-mark" }, [
              _c(
                "svg",
                { staticClass: "svg-icon svg-8 layout-icons-required" },
                [
                  _c("use", {
                    attrs: { "xlink:href": "#layout-icons-required" }
                  })
                ]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model.lazy",
              value: _vm.value,
              expression: "value",
              modifiers: { lazy: true }
            }
          ],
          attrs: { height: _vm.height, disabled: !_vm.enable, rows: _vm.rows },
          domProps: { value: _vm.value },
          on: {
            focus: function($event) {
              _vm.focused = true
            },
            blur: function($event) {
              _vm.focused = false
            },
            change: function($event) {
              _vm.value = $event.target.value
            }
          }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }