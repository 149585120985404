<template>
	<div class="quote-message-wrapper">
		<div class="quote-message">
			<div class="first-line">
				<p class="p2">{{createBy}},</p>
				<p class="p6 date">{{date}}</p>
			</div>

			<p class="quote-message-content p1" v-parselinks v-text="message.text"></p>
		</div>
	</div>
</template>
<script>
import { ParselinksDirective } from '@acx-xms/components/dist';
import { evaluate } from '@/Data/EvaluationContext/evaluationContext';

export default {
	name: 'quote',
	props: { message: Object },
	data() {
		return {
			date: '',
			createBy: ''
		};
	},

	created() {
		this.date = evaluate({
			$type: 'expression.timespan',
			argument: {
				$type: 'expression.text',
				value: this.message.createdon
			}
		});

		this.createBy = this.message.createBy;
	}
};
</script>
<style src="./quote-message.less" scoped></style>
