
<template>
	<div class="search-field-wrapper inline-block">
		<input
			v-model="query"
			v-data-attr="{ options }"
			type="text"
			placeholder="Search"
			class="search-box"
			@keyup.enter="Submit"
		/>
		<div class="search-icon-wrapper">
			<svg class="svg-icon" @click="Submit">
				<use xlink:href="#layout-icons-ms-search" class="layout-icons-ms-search" />
			</svg>
			<svg
				v-show="query && query.length"
				class="svg-icon svg-20 clear-button"
				@click="clearSearchBox"
			>
				<use xlink:href="#layout-icons-textbox-delete" class="layout-icons-textbox-delete" />
			</svg>
		</div>
	</div>
</template>

<script>
import '@/Bindings/ui-attribute.directive';
import helperMethods from '@/Components/ComponentSet/component-set-evaluation-context-helper.methods';
import FilterMixins from '@/Components/FiltersPanel/filter.mixins';

export default {
	name: 'filter-info-panels-field',
	mixins: [FilterMixins],
	props: {
		options: Object,
		evaluationContext: Object,
		stateNamespace: String
	},
	data() {
		return { query: '' };
	},
	created() {
		this.logicalName = helperMethods.eval(this.evaluationContext, this.options.logicalName);
		this.maxExpansions = helperMethods.eval(this.evaluationContext, this.options.maxExpansions);
		this.entity = this.options.entities[0];
		this.filterValue = this.$store.getters[this.stateNamespace + '/getFilterValue']({
			entity: this.entity,
			logicalName: this.logicalName
		});
		if (this.filterValue) {
			this.applyFilterValue(this.filterValue);
		}

		this.$parent.$on(this.stateNamespace + 'resetFilter', this.resetFilter);
	},
	methods: {
		Submit() {
			// this will trigger panel to be closed and beforeDestroy callback will run
			this.$store.commit(this.stateNamespace + '/openInfoPanel', null);
		},
		clearSearchBox() {
			this.query = '';
			this.Submit();
		}
	},
	async beforeDestroy() {
		const hasValue = () => {
			return !!this.query.length;
		};
		const wasChanged = () => {
			return (!this.filterValue && hasValue()) ||
					(this.filterValue && !hasValue()) ||
					(this.filterValue && this.query !== this.filterValue.data.query);
		};
		if (wasChanged()) {
			const filter = hasValue() ? await this.getSearchFilter(this.logicalName) : null;
			this.emitFilterChanged(filter);
		}
	}
};
</script>
<style src="./panels.less" scoped></style>
<style src="@/Components/ComponentSet/SearchResults/search-field/search-field.less" scoped></style>
