var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "add-preference-dialog" }, [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ],
      staticClass: "loading-overlay large"
    }),
    _vm._v(" "),
    _vm.isReady
      ? _c("div", { staticClass: "body" }, [
          _c("div", { staticClass: "custom-lookup" }, [
            _c("div", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: { key: "addPreferenceDialog.contact.lookup" },
                  expression: "{key: 'addPreferenceDialog.contact.lookup'}"
                }
              ],
              staticClass: "p13"
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "add-preference" },
              [
                _c("control-lookup-editor", {
                  attrs: {
                    showRequired: false,
                    options: _vm.getContactLookupOptions()
                  },
                  model: {
                    value: _vm.selectedContact,
                    callback: function($$v) {
                      _vm.selectedContact = $$v
                    },
                    expression: "selectedContact"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "custom-lookup" }, [
            _c("div", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: { key: "addPreferenceDialog.preference.lookup" },
                  expression: "{key: 'addPreferenceDialog.preference.lookup'}"
                }
              ],
              staticClass: "p13"
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "add-preference" },
              [
                _c("control-lookup-editor", {
                  class: { invalid: !_vm.isValid },
                  attrs: {
                    showRequired: true,
                    options: _vm.getPreferenceLookupOptions()
                  },
                  model: {
                    value: _vm.selectedPreference,
                    callback: function($$v) {
                      _vm.selectedPreference = $$v
                    },
                    expression: "selectedPreference"
                  }
                })
              ],
              1
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c("span", { staticClass: "buttons" }, [
        _c("button", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "common.dictionary.buttons.save" },
              expression: "{key: 'common.dictionary.buttons.save'}"
            }
          ],
          attrs: { type: "button" },
          on: { click: _vm.save }
        }),
        _vm._v(" "),
        _c("button", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "common.dictionary.buttons.cancel" },
              expression: "{key: 'common.dictionary.buttons.cancel'}"
            }
          ],
          staticClass: "cancel",
          attrs: { type: "button" },
          on: { click: _vm.close }
        })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }