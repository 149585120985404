<template>
	<div class="dialog-content-wrapper copy-link-dialog editor">
		<div class="dialog-container">
			<div class="field-wrapper">
				<div class="p13">Direct Link: </div>
				<div class="field">
					<input ref="text" type="text" :value="getUrl()" />
					<button type="submit" @click="copy" v-localization="{key: 'dialogues.email.link.copy'}"></button>
				</div>
			</div>
			<div class="field-wrapper">
				<div class="p13">Hyperlink to share with other Participants: </div>
				<div class="field">
					<input type="text" :value="roomName" />
					<button type="submit" @click="copyHyperLink" v-localization="{key: 'dialogues.email.link.copy'}"></button>
				</div>
			</div>
		</div>
		<div class='footer'>
			<span class="buttons">
				<button type="button" class="cancel" @click="cancel" v-localization="{key: 'common.dictionary.buttons.cancel'}"></button>
			</span>
		</div>
		<a class="link" ref="link" :href="getUrl()">{{roomName}}</a>
	</div>
</template>
<script>
import { showToastOrInfoDialog } from '@acx-xms/data-functions/dist';

export default {
	name: 'copy-link-dialog',
	props: { options: Object },
	data() {
		return { roomName: this.options.params.selection.Name };
	},
	methods: {
		getUrl() {
			return `${window.location.origin}/${sc.clusterKey()}/${this.options.params.linkTail}`;
		},
		async copy() {
			this.$refs.text.select();
			document.execCommand('copy');
			window.getSelection().removeAllRanges();
			await showToastOrInfoDialog({
				toastMessageKey: this.options.params.toastMessageKey || 'common.dictionary.toastText',
				informationDialogTextKey: this.options.params.informationDialogTextKey,
				options: { context: this }
			});
		},
		async copyHyperLink() {
			const link = this.$refs.link;
			const range = document.createRange();
			range.selectNode(link);
			const selection = window.getSelection();
			selection.removeAllRanges();
			selection.addRange(range);
			document.execCommand('copy');
			await showToastOrInfoDialog({
				toastMessageKey: this.options.params.toastMessageKey || 'common.dictionary.toastText',
				informationDialogTextKey: this.options.params.informationDialogTextKey,
				options: { context: this }
			});
		},
		cancel() {
			this.$parent.$emit('close');
		}
	}
};
</script>
<style src="./copy-link-dialog.less" scoped></style>
