var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "breadcrumbs" }, [
    _c(
      "span",
      {
        class: { icon: true, disabled: _vm.disabled },
        on: { click: _vm.navigatePrev }
      },
      [
        _c("svg", { staticClass: "svg-icon svg-12 back-button" }, [
          _c("use", {
            attrs: { "xlink:href": "#layout-icons-details-header-back" }
          })
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "breadcrumbs-wrapper" },
      [
        _vm.breadcrumbs.length > _vm.maxBreadcrumbsLength
          ? _c("div", { staticClass: "breadcrumb-item" }, [
              _c("div", { staticClass: "title" }, [_vm._v("...")])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.filteredBreadcrumbs, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              class: {
                "breadcrumb-item": true,
                disabled: item.data && item.data.disabled
              },
              on: {
                click: function($event) {
                  _vm.navigate(item)
                }
              }
            },
            [_c("div", { staticClass: "title" }, [_vm._v(_vm._s(item.text))])]
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }