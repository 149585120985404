﻿import { AvatarUpdateAspect } from './aspects/avatarUpdate.aspect';

export class AvatarUpdate {
	constructor(options) {
		this.options = options;
	}

	installAspects() {
		// after create or update item (listing) -> details
		return [new AvatarUpdateAspect(this.options)];
	}
}