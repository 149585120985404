<template>
	<div class="ms-add-to-list">
		<div class="loading-overlay large" v-show="isLoading"></div>
		<component-search-results :options="options.params.componentOptions">
			<reactive-action-menu 	slot="reactive-menu"
									:options="actionMenuComponent"
									@click.native="cancel">
			</reactive-action-menu>
		</component-search-results>
		<div class="footer-wrapper">
			<div class="footer">
				<span class='buttons'>
					<button type="button"
						@click="update"
						v-localization="{key: 'dialogues.addToList.addToListBtn'}"
						v-data-attr="{title: options.title, $type: 'dialog.button.submit'}">
					</button>
					<button class="cancel"
						type="button"
						@click="cancel"
						v-localization="{key: 'dialogues.addToList.cancelBtn'}"
						v-data-attr="{title: options.title, $type: 'dialog.button.cancel'}">
					</button>
				</span>
			</div>
		</div>
	</div>

</template>
<script>
import ComponentSearchResults from '@/Components/ComponentSet/SearchResults/component-search-results';
import helperMethods from '@/Components/ComponentSet/component-set-evaluation-context-helper.methods';
import ReactiveActionMenu from '@/Components/Menu/reactive-action-menu';
import { Search, SendRequest } from '@acx-xms/data-functions/dist';

export default {
	name: 'staticlists-ms-add-to-list',
	props: { options: Object },
	components: {
		ComponentSearchResults,
		ReactiveActionMenu
	},
	data() {
		return {
			isLoading: false,
			stateNamespace: helperMethods.eval(null, this.options.params.componentOptions.stateNamespace),
			searchResultsOptions: this.options.params.componentOptions
		};
	},
	async created() {
		this.targetEntity = helperMethods.eval(null, this.options.params.componentOptions.targetEntity[0]);
		// need to process predefined contacts selection #86812
		const toolbarActions = { ...this.searchResultsOptions.toolbarActions };
		if (toolbarActions) {
			const createActionIndex = toolbarActions.item.findIndex(item => item.action.$type === 'action.marketinglist.create');
			if (createActionIndex !== -1) {
				toolbarActions.item[createActionIndex].action.customSelection = [...this.options.params.selection];
			}
		}
		this.actionMenuComponent = { toolbarActions };
	},
	methods: {
		cancel() {
			this.$parent.$emit('close');
		},
		async update() {
			const selection = this.$store.getters[this.stateNamespace + '/getSelection'];
			let ids;
			if (!(selection && selection[this.targetEntity] && selection[this.targetEntity].length > 0)) {
				return;
			} else {
				ids = selection[this.targetEntity].map((record) => { return record.Id; });
			}
			this.isLoading = true;
			const records = [];
			const members = await Search('staticlistmember',
				[
					sc.classes.get('termFacet.filter', {
						logicalName: 'staticlist.id',
						query: ids
					}).fillQuery(),
					sc.classes.get('termFacet.filter', {
						logicalName: 'entityreference.id',
						query: this.options.params.selection.map(i => i.id)
					}).fillQuery(),
					sc.classes.get('selectedFields.filter', [
						{ logicalname: 'staticlist' },
						{ logicalname: 'staticlistmemberentityreferencecontact.contactid' }
					]).fillQuery(),
					sc.classes.get('offsetSize.filter', Number(this.options.params.selection.length) * Number(ids.length))
				]
			);
			this.options.params.selection.forEach((contact) => {
				ids.forEach(marketingListId => {
					if (!members.Results.some(i => i.Source.staticlist.id === marketingListId && i.Source.staticlistmemberentityreferencecontact.contactid === contact.id)) {
						records.push({
							staticlist: {
								id: marketingListId,
								logicalname: 'staticlist'
							},
							entityreference: contact,
							name: contact.name,
							type: 'staticlistmember'
						});
					}
				});
			});
			if (records.length) {
				SendRequest('/api/MarketingList/CreateMembers', 'POST', records);
				const toastText = await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('common.dictionary.marketinglistUpdated');
				this.$root.$emit('toast.open', toastText);
			}
			this.isLoading = false;
			if (this.options.params.refreshNamespace) {
				const refreshNamespace = helperMethods.eval(null, this.options.params.refreshNamespace);
				this.$root.$emit(refreshNamespace + '.searching');
			}
			this.cancel();
		}
	}
};
</script>
<style src="./staticlists-ms-add-to-list.less" scoped></style>
