var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "remove-document-dialog-wrapper" }, [
    _c("div", { staticClass: "delete-dialog-message" }, [
      _c("span", {
        directives: [
          {
            name: "localization",
            rawName: "v-localization",
            value: { key: "common.dictionary.confirmRemoving" },
            expression: "{ key: 'common.dictionary.confirmRemoving'}"
          }
        ],
        staticClass: "p4"
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c("button", {
        directives: [
          {
            name: "localization",
            rawName: "v-localization",
            value: { key: "common.dictionary.buttons.cancel" },
            expression: "{ key: 'common.dictionary.buttons.cancel'}"
          }
        ],
        staticClass: "cancel",
        on: { click: _vm.close }
      }),
      _vm._v(" "),
      _c("button", {
        directives: [
          {
            name: "localization",
            rawName: "v-localization",
            value: { key: "common.dictionary.buttons.delete" },
            expression: "{ key: 'common.dictionary.buttons.delete'}"
          }
        ],
        attrs: { type: "button" },
        on: { click: _vm.onSubmit }
      })
    ]),
    _vm._v(" "),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ],
      staticClass: "loading-overlay"
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }