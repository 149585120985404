var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-step" }, [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ],
      staticClass: "loading-overlay large"
    }),
    _vm._v(" "),
    _c("div", { staticClass: "body" }, [
      _c(
        "div",
        { staticClass: "template-format" },
        [
          _c("div", { staticClass: "label entity" }, [
            _c("span", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: { key: "import.TypeOfRecords" },
                  expression: "{ key: 'import.TypeOfRecords'}"
                }
              ],
              staticClass: "title p4"
            })
          ]),
          _vm._v(" "),
          _c("control-lookup-editor", {
            class: { invalid: !_vm.isSelectedEntity },
            attrs: {
              showRequired: _vm.selectedEntity,
              options: _vm.getLookupOptions()
            },
            model: {
              value: _vm.entity,
              callback: function($$v) {
                _vm.entity = $$v
              },
              expression: "entity"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "template-format" },
        [
          _c("div", { staticClass: "label" }, [
            _c("span", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: {
                    key: "import.selectFile",
                    args: [_vm.recordsLimit, _vm.options.displayname]
                  },
                  expression:
                    "{key: 'import.selectFile', args: [recordsLimit, options.displayname] }"
                }
              ],
              staticClass: "title p4"
            })
          ]),
          _vm._v(" "),
          _c("control-file-upload", {
            ref: "reff",
            staticClass: "control-wrapper",
            class: { invalid: !_vm.isUploaded },
            attrs: {
              options: {},
              showRequiredMark: !_vm.selectedFile,
              labelKey: "controls.ndaFileEditor.noExcelFile"
            },
            model: {
              value: _vm.uploadedFileName,
              callback: function($$v) {
                _vm.uploadedFileName = $$v
              },
              expression: "uploadedFileName"
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "list-content step-one" }, [
      _c("div", { staticClass: "control-tableView inner-search-results" }, [
        _c(
          "div",
          { staticClass: "tableview-header" },
          _vm._l(_vm.fields, function(field, idx) {
            return _c(
              "div",
              {
                key: field.name,
                staticClass: "cell",
                style: { width: _vm.columnsSize[idx] }
              },
              [
                _c("div", { staticClass: "label-header required-wrapper" }, [
                  _c("span", [_vm._v(_vm._s(field.name))]),
                  _vm._v(" "),
                  field.isRequired
                    ? _c("div", { staticClass: "required-mark" }, [
                        _c(
                          "svg",
                          {
                            staticClass: "svg-icon svg-5 layout-icons-required"
                          },
                          [
                            _c("use", {
                              attrs: { "xlink:href": "#layout-icons-required" }
                            })
                          ]
                        )
                      ])
                    : _vm._e()
                ])
              ]
            )
          })
        ),
        _vm._v(" "),
        _c("div", { staticClass: "tableview-content" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "perfectscroll",
                  rawName: "v-perfectscroll",
                  value: { enable: true },
                  expression: "{enable: true}"
                }
              ],
              staticClass: "content-scroll-wrapper"
            },
            _vm._l(_vm.dataExamples, function(example, exampleIdx) {
              return _c(
                "div",
                { key: _vm.exampleKey + exampleIdx, staticClass: "row" },
                _vm._l(example, function(exampleValue, exampleValueIdx) {
                  return _c(
                    "div",
                    {
                      key: exampleValue,
                      staticClass: "table-cell",
                      style: { width: _vm.columnsSize[exampleValueIdx] }
                    },
                    [
                      _c("span", { staticClass: "label" }, [
                        _vm._v(_vm._s(exampleValue))
                      ])
                    ]
                  )
                })
              )
            })
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c("span", { staticClass: "buttons" }, [
        _c("button", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "import.next" },
              expression: "{key: 'import.next'}"
            }
          ],
          attrs: { type: "button" },
          on: { click: _vm.nextStep }
        }),
        _vm._v(" "),
        _c("button", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "import.close" },
              expression: "{key: 'import.close'}"
            }
          ],
          staticClass: "cancel",
          attrs: { type: "button" },
          on: { click: _vm.close }
        })
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "fields-info p4" }, [
      _vm._v("Please find the "),
      _c("span", [_vm._v("example")]),
      _vm._v(" below and make sure your file contains all the "),
      _c("span", [_vm._v("required fields")]),
      _vm._v(":")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }