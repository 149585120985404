var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "email-campaign-step-root select-listing" },
    [
      _vm.templateProcess
        ? _c("Loader", {
            attrs: {
              text:
                "The publication with chosen data is being generated for the future preview. This process can take some time and no changes can be made with listings until it ends. Please, wait a while"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("SearchResults")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }