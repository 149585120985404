<template>
    <div class="dialog-step">
        <div class="loading-overlay large" v-show="isLoading"></div>
        <div class="body">
            <div class="template-format">
                <div class="label entity">
                    <span class="title p4" v-localization="{ key: 'import.TypeOfRecords'}" />
                </div>
                <control-lookup-editor v-model="entity"
                                       :showRequired="selectedEntity"
                                       :class="{invalid: !isSelectedEntity}"
                                       :options="getLookupOptions()"></control-lookup-editor>
            </div>
            <div class="template-format">
                <div class="label">
                    <span class="title p4" v-localization="{key: 'import.selectFile', args: [recordsLimit, options.displayname] }"></span>
                </div>
                <control-file-upload class="control-wrapper"
                                     :class="{invalid: !isUploaded}"
                                     v-model="uploadedFileName"
                                     :options="{}"
                                     :showRequiredMark="!selectedFile"
                                     :labelKey="'controls.ndaFileEditor.noExcelFile'"
                                     ref="reff"></control-file-upload>
            </div>
        </div>
        <p class="fields-info p4">Please find the <span>example</span> below and make sure your file contains all the <span>required fields</span>:</p>
        <div class="list-content step-one">
            <div class="control-tableView inner-search-results">
                <div class="tableview-header">
                    <div class="cell" v-for="(field, idx) in fields" :key="field.name" :style="{ width: columnsSize[idx] }">
                        <div class="label-header required-wrapper">
                            <span>{{field.name}}</span>
                            <div class="required-mark" v-if="field.isRequired">
                                <svg class="svg-icon svg-5 layout-icons-required">
                                    <use xlink:href="#layout-icons-required"></use>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tableview-content">
                    <div class="content-scroll-wrapper" v-perfectscroll="{enable: true}">
                        <div class="row" v-for="(example, exampleIdx) in dataExamples" :key="exampleKey + exampleIdx">
                            <div class="table-cell"
                                 v-for="(exampleValue, exampleValueIdx) in example"
                                 :key="exampleValue"
                                 :style="{ width: columnsSize[exampleValueIdx] }">
                                <span class="label">{{exampleValue}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class='footer'>
            <span class='buttons'>
                <button type="button"
                        @click="nextStep"
                        v-localization="{key: 'import.next'}"></button>
                <button type="button"
                        class="cancel"
                        @click="close"
                        v-localization="{key: 'import.close'}"></button>
            </span>
        </div>
    </div>
</template>
<script>
import importProvider from '@/Data/DataProviders/import.dataProvider';
import { generateGuid, generateId } from '@acx-xms/data-functions/dist';
const ControlLookupEditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Lookup/control-lookup-editor');
const ControlFileUpload = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/FileUpload/file-upload');

export default {
	name: 'import-step-one',
	props: {
		step: Number,
		options: Object
	},
	components: {
		ControlFileUpload,
		ControlLookupEditor
	},
	data() {
		return {
			isUploaded: true,
			isSelectedEntity: true,
			fileGuid: null,
			uploadedFileName: null,
			parsedFile: null,
			entity: {
				displayName: this.options.displayname,
				value: this.options.logicalname
			},
			availableEntities: [{
				displayName: this.options.displayname,
				value: this.options.logicalname
			}],
			fields: this.options.fields,
			dataExamples: this.options.dataExamples,
			columnsSize: this.options.columnsSize,
			exampleKey: generateId(),
			recordsLimit: 20000,
			isLoading: false
		};
	},
	computed: {
		selectedEntity() {
			return (this.entity === null);
		},
		selectedFile() {
			return (this.uploadedFileName !== null);
		}
	},
	created() {
		this.localization = sc.classes.get('localization.dataProvider');
		this.$root.$on('fileUploaded', this.onFileUploaded);
		this.$root.$on('removeFile', this.onRemoveFile);
	},
	beforeDestroy() {
		this.$root.$off('fileUploaded', this.onFileUploaded);
	},
	methods: {
		getLookupOptions() {
			return {
				isRequired: true,
				placeholder: '',
				fetchData: searchQuery => {
					searchQuery = searchQuery.toLowerCase();
					const filteredFormats = this.availableEntities.filter(item => {
						return item.displayName.toLowerCase().indexOf(searchQuery) === 0;
					});
					return {
						total: filteredFormats.length,
						items: filteredFormats
					};
				}
			};
		},
		async validateParsedFile(parsedFile) {
			let errorMessage;
			if (parsedFile.success) {
				this.isUploaded = true;
			} else {
				this.isUploaded = false;
				switch (parsedFile.reason) {
				case 'invalidfile':
					errorMessage = await this.localization.getLabelForCurrentLanguage('import.wrongFileFormat');
					break;
				case 'invalidentity':
					errorMessage = await this.localization.getLabelForCurrentLanguage('import.invalidEntity');
					break;
				case 'nodata':
					errorMessage = await this.localization.getLabelForCurrentLanguage('import.emptyFile');
					break;
				default:
					errorMessage = await this.localization.getLabelForCurrentLanguage('import.validationError');
				}
			}
			if (!this.entity) {
				this.isSelectedEntity = false;
				const msg = await this.localization.getLabelForCurrentLanguage('import.entityIsSelected');
				errorMessage = errorMessage ? errorMessage + '\r\n' + msg : msg;
			}
			return errorMessage;
		},
		async validateInputs() {
			let errorMessage = '';
			if (!this.entity) {
				this.isSelectedEntity = false;
				errorMessage = await this.localization.getLabelForCurrentLanguage('import.entityIsSelected');
			}
			if (!this.isUploaded || !this.uploadedFileName) {
				const msg = await this.localization.getLabelForCurrentLanguage('import.fileIsSelected');
				errorMessage = errorMessage ? errorMessage + '\r\n' + msg : msg;
				this.isUploaded = false;
			}
			return errorMessage;
		},
		showErrorMessage(errorMessage) {
			sc.events.emit('dialog.info', {
				title: this.localization.localize('import.validationError'),
				message: errorMessage
			});
		},
		onRemoveFile() {
			this.uploadedFileName = null;
		},
		async onFileUploaded(event) {
			if (event) {
				const formData = new FormData();
				this.fileGuid = generateGuid();
				const authToken = localStorage.getItem(sc.classes.get('authentication').getTokenKey(sc.cluster.key));
				const allowedExtensions = '.';
				formData.append('file', event.body);
				formData.append('fileGuid', this.fileGuid);
				formData.append('allowedExtensions', allowedExtensions);
				formData.append('token', authToken);
				await fetch('/api/Utilities/SaveFile', {
					method: 'POST',
					body: formData
				});
				this.isUploaded = true;
			}
		},
		async nextStep() {
			let error = '';
			this.isLoading = true;
			error = await this.validateInputs();
			if (error) {
				this.showErrorMessage(error);
				this.isLoading = false;
				return;
			}
			this.parsedFile = await importProvider.parseFields(this.fileGuid, this.options.logicalname);
			error = await this.validateParsedFile(this.parsedFile);
			if (error) {
				this.showErrorMessage(error);
				this.isLoading = false;
				return;
			}
			this.isDisabled = false;
			this.$parent.$emit('changeStep', {
				step: this.step + 1,
				result: {
					parsedFile: this.parsedFile,
					fileGuid: this.fileGuid
				}
			});
			this.isLoading = false;
		},
		close() {
			this.$parent.$emit('close');
		}
	}
};
</script>
<style src="./import-steps.less" scoped></style>
<style src="@/Components/Control/Table/control-table-view.less" scoped></style>
<style src="@/Components/Control/Table/control-table-view-row.less" scoped></style>
