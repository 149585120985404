var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible,
          expression: "visible"
        }
      ],
      staticClass: "link-wrapper",
      attrs: { title: _vm.title },
      on: { click: _vm.trackActivity }
    },
    [
      _c(
        "a",
        {
          staticClass: "download-link",
          attrs: {
            target: "blank",
            download: "",
            href: _vm.url,
            text: _vm.title
          }
        },
        [_vm._v(_vm._s(_vm.title))]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }