<template>
	<VueDatePicker
		v-model="innerValue"
		:disabled-date="disabled"
		:open="opened"
		:class="['date-picker', { disabled: !enable }]"
		@open="open"
		@close="close"
	>
		<ControlSvgIcon slot="icon-calendar" :options="{ name: 'layout-icons-calendar-marker' }" />
		<div slot="input" :class="['input', { invalid: !valid }]">
			<input
				type="text"
				:value="formatted"
				:disabled="!enable"
				@change="inputDate"
				@keyup.enter="inputDate"
			/>
			<div class="actions before">
				<div v-if="showRequiredMark" class="required-mark">
					<svg class="svg-icon svg-8 layout-icons-required">
						<use xlink:href="#layout-icons-required" />
					</svg>
				</div>
			</div>
		</div>
		<div v-if="hasTodayAction" slot="footer">
			<span
				v-localization="{ key: 'common.dictionary.buttons.datepicker.current' }"
				:class="['today-action', { disabled: disabled(today) }]"
				@click="todayAction"
			/>
		</div>
	</VueDatePicker>
</template>

<script>
import 'vue2-datepicker/index.css';
const ControlSvgIcon = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Images/control-svg-icon');
const VueDatePicker = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ 'vue2-datepicker');

function isValid(date) {
	return !isNaN(Date.parse(date) || NaN); // If has no date or if date are invalid
}

export default {
	name: 'date-picker',
	components: {
		VueDatePicker,
		ControlSvgIcon
	},
	data: () => ({
		innerValue: null,
		formatted: '',
		opened: false
	}),
	watch: {
		innerValue: {
			handler(value) {
				if (this.disabled(value)) this.submitDate();
				this.submitDate(this.formatDate(value));
			},
			immediate: true
		},
		value: {
			handler(value) {
				this.innerValue = value;
			},
			immediate: true
		}
	},
	props: {
		value: { type: Date },
		locale: { type: String },
		enable: {
			type: Boolean,
			default: true
		},
		valid: {
			type: Boolean,
			default: true
		},
		enabledDates: { type: Array },
		showRequiredMark: {
			type: Boolean,
			default: false
		},
		hasTodayAction: {
			type: Boolean,
			default: true
		}
	},
	computed: {
		today() {
			const today = new Date();
			today.setHours(0, 0, 0, 0);
			return today;
		}
	},
	methods: {
		open() {
			this.opened = true;
		},
		close() {
			this.opened = false;
		},
		formatDate(value) {
			this.formatted = value ? this.formatDateByLocal(value) : '';
			return value;
		},
		todayAction() {
			if (!this.disabled(this.today)) this.submitDate(this.today);
		},
		inputDate($event) {
			let date = $event.target.value;
			if (date.length && !isValid(date)) this.showError();
			if (this.disabled(date)) date = null; // Prevent to input a disabled date
			this.submitDate(date);
		},
		submitDate(date) {
			this.$emit('input', date);
			this.$emit('change', date);
		},
		formatDateByLocal(date) {
			const locale = this.locale || (navigator.languages || [])[0] || navigator.language || navigator.browserLanguage || navigator.systemLanguage || 'en-US'; // Get local from prop or browser
			return Intl.DateTimeFormat(locale).format(date);
		},
		showError() {
			this.close(); // Prevent bug with opened popup after date submit
			const localization = sc.classes.get('localization.dataProvider');
			sc.events.emit('dialog.error', {
				title: localization.localize('entity.editor.validationError'),
				message: `Invalid date. Please enter in the following format "${this.formatDateByLocal(this.today)}".` // TODO: get string from localization?
			});
		},
		disabled(date) {
			if (!this.enabledDates) return false;
			const before = this.enabledDates[0];
			const after = this.enabledDates[1];

			if (before && after) return date > before || date < after;
			else if (before && !after) return date > before;
			else if (!before && after) return date < after;

			return false;
		}
	}
};
</script>

<style lang="less" scoped>
.date-picker {
	&.disabled {
		pointer-events: none;
	}

	input {
		&:focus {
			& + .actions.before {
				display: none;
			}
		}
	}

	.input.invalid > input {
		border: 1px solid var(--color8);
	}

	.actions {
		position: absolute;
		display: flex;
		top: 0.25rem;
		line-height: 1rem;

		&.before {
			left: 0.375rem;

			svg.layout-icons-required {
				fill: #bd181e;
			}
		}

		&.after {
			right: 0.375rem;
		}
	}
}
</style>
