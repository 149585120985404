<template>
	<div class="collection" :class="{'expanded': isExpanded}">
		<div class="border-top"></div>
		<div class="header with-margin">
			<div class="icon-with-text">
				<span class="icon-holder" @click="isExpanded=!isExpanded">
					<svg class='svg-icon svg-15'>
						<use :xlink:href="'#layout-icons-filter-group-collapse'" class="hover" v-if="isExpanded"></use>
						<use :xlink:href="'#layout-icons-filter-group-open'" class="hover" v-if="!isExpanded"></use>
					</svg>
				</span>
				<span class="h2 title">{{ title }}:</span>
			</div>
			<button @click="add">Add</button>
		</div>

		<div v-for="(item, index) in items" :key="item['$type']+index" class="collection-item" v-show="isExpanded">
			<panel :title="`${index}`"
				   :template="getCurrentItemValue(index)"
				   :base="base"
				   :allowItemActions="true"
				   :currentIndex="index"
				   :itemActions="itemActions"
				   :metaFields="metaFields"
				   @copyItem="copy(index)"
				   @topUpItem="topUpItem(index)"
				   @topDownItem="topDownItem(index)"
				   @oneUpItem="oneUpItem(index)"
				   @oneDownItem="oneDownItem(index)"
				   @removeItem="remove(index)"
				   @onChange="onChange(index, ...arguments)">
			</panel>
		</div>
	</div>
</template>

<script>
import { generateId, traverseObj } from '@acx-xms/data-functions/dist';

export default {
	name: 'collection',
	props: {
		title: String,
		base: String,
		currentValue: Array,
		metaFields: Array
	},
	data() {
		return {
			items: this.currentValue ? [...this.currentValue] : [],
			isExpanded: false,
			itemActions: [
				{
					icon: 'ce-lowest',
					event: 'topDownItem',
					enable: (index) => {
						return index !== this.items.length - 1;
					},
					class: 'ml5'
				},
				{
					icon: 'ce-lower',
					event: 'oneDownItem',
					enable: (index) => {
						return index !== this.items.length - 1;
					},
					class: 'mr5'
				},
				{
					icon: 'ce-up',
					event: 'topUpItem',
					enable: (index) => {
						return index !== 0;
					},
					class: 'ml5'
				},
				{
					icon: 'ce-upward',
					event: 'oneUpItem',
					enable: (index) => {
						return index !== 0;
					},
					class: 'mr5'
				},
				{
					icon: 'ce-copy',
					event: 'copyItem',
					enable: () => true
				},
				{
					icon: 'dialog-close',
					event: 'removeItem',
					enable: () => true
				}
			]
		};
	},
	watch: {
		currentValue() {
			this.items = [...this.currentValue];
		}
	},
	components: { Panel: () => import('./../panel/panel.vue') },
	created() {
		this.$on('expand', this.onExpanded);
	},
	beforeDestroy() {
		this.$off('expand', this.onExpanded);
	},
	methods: {
		add() {
			const flexibleFieldWrapper = {
				$type: 'control.flexibleFieldWrapper',
				controlWidth: '',
				cssClass: {
					$type: 'expression.text',
					value: ''
				},
				displayStyle: '',
				title: {
					$type: 'expression.text',
					value: ''
				},
				titleWidth: '',
				value: {
					$type: 'control.label',
					value: ''
				}
			};
			const flexibleContainer = {
				$type: 'control.flexibleContainerItem',
				grow: '',
				control: { ...flexibleFieldWrapper },
				minWidth: '',
				shrink: '',
				width: ''
			};
			if (this.base.includes('Control')) {
				if (this.title === 'item') {
					this.items.push(flexibleContainer);
				}
				if (this.title === 'control') {
					this.items.push(flexibleFieldWrapper);
				}
				if (this.title === 'group') {
					this.items.push(
						{
							$type: 'entityTemplate.fieldGroup',
							columns: 0,
							cssClass: {
								$type: 'expression.text',
								value: ''
							},
							control: [
								{
									$type: 'control.label',
									value: ''
								}
							],
							colspan: 1,
							visible: { $type: 'expression.true' }
						}
					);
				}
			} else if (this.base.includes('SearchResult')) {
				switch (this.title) {
				case 'row':
					this.items.push({
						$type: 'control.searchResultDetailsRow',
						cssClass: {
							$type: 'expression.text',
							value: ''
						},
						column: [flexibleContainer]
					});
					break;
				case 'column':
					this.items.push(flexibleContainer);
					break;
				default:
					break;
				}
			} else if (this.base.includes('Expression')) {
				this.items.push({
					$type: 'expression.text',
					value: ''
				});
			} else if (this.base.includes('SwitchCase')) {
				this.items.push({
					$type: 'expression.switchCase',
					key: {
						$type: 'expression.text',
						value: ''
					},
					value: {
						$type: 'expression.text',
						value: ''
					}
				});
			} else if (this.base.includes('Menu')) {
				this.items.push({
					$type: 'menu.menuItemSVG',
					display: 'image',
					default: false,
					text: {
						$type: 'expression.text',
						value: 'Edit'
					},
					icon: {
						$type: 'expression.text',
						value: 'action-icons-ms-entity-edit'
					},
					action: {
						$type: 'action.vue.editor',
						logicalname: {
							$type: 'expression.text',
							value: 'image'
						},
						selection: { $type: 'expression.contextRef' },
						source: 'context'
					}
				});
			} else if (this.base.includes('ActionParameter')) {
				this.items.push({
					$type: 'action.actionParameter',
					name: '',
					value: {
						$type: 'expression.text',
						value: ''
					}
				});
			} else if (this.base.includes('UpdateData')) {
				this.items.push({
					$type: 'action.updData',
					field: {
						$type: 'expression.text',
						value: ''
					},
					value: {
						$type: 'expression.text',
						value: ''
					}
				});
			} else if (this.base.includes('RelatedImageDataSource')) {
				this.items.push({
					$type: 'imageGallery.relatedImagesDataSource',
					primaryId: { $type: 'expression.contextRef' },
					filter: [
						{
							$type: 'search.termFacetFilter',
							negative: false,
							filterViewKind: 'Default',
							item: [
								{
									$type: 'search.termFacetItem',
									value: {
										$type: 'expression.text',
										value: '8d113fa8-3015-4060-a107-14cedcd19dd3'
									}
								}
							],
							logicalName: 'recordstate.id'
						}
					],
					imageSize: 'thumbnail',
					previewSize: 'thumbnail',
					downloadSize: 'original',
					orderBy: 'primary'
				});
			} else if (this.base.includes('FilterSearch')) {
				this.items.push({
					$type: 'search.termFacetFilter',
					negative: false,
					filterViewKind: 'Default',
					item: [
						{
							$type: 'search.termFacetItem',
							value: {
								$type: 'expression.text',
								value: '8d113fa8-3015-4060-a107-14cedcd19dd3'
							}
						}
					],
					logicalName: 'recordstate.id'
				});
			} else if (this.base.includes('TermFacetItem')) {
				this.items.push({
					$type: 'search.termFacetItem',
					value: {
						$type: 'expression.text',
						value: '8d113fa8-3015-4060-a107-14cedcd19dd3'
					}
				});
			} else if (this.base.includes('Filter')) {
				this.items.push({
					$type: 'search.idsFilter',
					id: [
						{
							$type: 'expression.text',
							value: '32413b0a-0e58-4e15-9de2-ef23c0e72eb2'
						}
					]
				});
			} else if (this.base.includes('AbstractSelectedField')) {
				this.items.push({
					$type: 'search.selectedField',
					name: {
						$type: 'expression.text',
						value: ''
					}
				});
			}
			this.$emit('onChange', this.title, this.items);
		},
		remove(index) {
			this.items.splice(index, 1);
			this.$emit('onChange', this.title, this.items);
		},
		copy(index) {
			const copiedItem = _.cloneDeep(this.items[index]);
			traverseObj(copiedItem, value => {
				if (value.hasOwnProperty('$id')) {
					value.$id = generateId();
				}
			});
			this.items.splice(index, 0, copiedItem);
			this.$emit('onChange', this.title, this.items);
		},
		topUpItem(index) {
			const el = this.items.splice(index, 1)[0];
			this.items.unshift(el);
			this.$emit('onChange', this.title, this.items);
		},
		topDownItem(index) {
			const el = this.items.splice(index, 1)[0];
			this.items.push(el);
			this.$emit('onChange', this.title, this.items);
		},
		oneUpItem(index) {
			[this.items[index - 1], this.items[index]] = [this.items[index], this.items[index - 1]];
			this.$emit('onChange', this.title, this.items);
		},
		oneDownItem(index) {
			[this.items[index + 1], this.items[index]] = [this.items[index], this.items[index + 1]];
			this.$emit('onChange', this.title, this.items);
		},
		getCurrentItemValue(index) {
			return this.currentValue.length > index ? this.currentValue[index] : {};
		},
		onChange(i, key, value) {
			this.items[i] = value;
			this.$emit('onChange', this.title, this.items);
		},
		onExpanded(value) {
			this.isExpanded = value;
			this.$parent.$emit('expand', true);
		}
	}
};
</script>
<style src="./collection.less" scoped></style>
