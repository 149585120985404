<template>
	<div class="editor">
		<div class="body" v-perfectscroll="{ enable: true }">
			<control	v-if="editorTemplate"
						:name="editorTemplate.$type"
						:contentProps.once="editorTemplate"
						:evaluationContext="controlContext"></control>
		</div>
		<div class="footer">
			<button v-show="state==='notStarted'"
				class="cancel" @click="onCancel"
				v-localization="{key: 'common.dictionary.buttons.cancel'}"
				v-data-attr="{title: options.title, $type: 'dialog.button.cancel'}"></button>
			<button v-show="state!=='finished'"
				:disabled="state==='started'"
				@click="onSubmit"
				v-localization="{key: 'common.dictionary.buttons.upload'}"
				v-data-attr="{title: options.title, $type: 'dialog.button.submit'}"></button>
			<button v-show="state==='finished'"
				@click="onCancel"
				v-localization="{key: 'common.dictionary.buttons.close'}"
				v-data-attr="{title: options.title, $type: 'dialog.button.cancel'}"></button>
		</div>
	</div>
</template>
<script>
import Control from '@/Components/Entity/control';
import editorMixins from '@/Components/editor.mixins.js';
import createDocumentMixins from './create.document.mixins';
import { createEvaluationContext, evaluate } from '@/Data/EvaluationContext/evaluationContext';

export default {
	name: 'create-document',
	components: { Control },
	mixins: [editorMixins, createDocumentMixins],
	props: { options: Object },
	data() {
		return {
			stateKey: null,
			editorTemplate: null
		};
	},
	async created() {
		this.logicalName = this.options.logicalname && evaluate(this.options.logicalname);
		this.localization = sc.classes.get('localization.dataProvider');
		this.stateKey = this.registerModule(this.logicalName);

		this.editorTemplate = await sc.classes.get('entityConfiguration.dataProvider').getTemplate(this.logicalName, 'edit', 'create');
		this.controlContext = createEvaluationContext({ Type: this.logicalName });
		// add predefined (e.g. parentRecord) fields to store
		if (this.options.parameters && this.options.parameters.predefinedFields) {
			Object.keys(this.options.parameters.predefinedFields).forEach(async (key) => {
				await this.$store.dispatch(this.stateKey + '/setField', {
					name: key,
					value: this.options.parameters.predefinedFields[key]
				});
			});
		}
	}
};
</script>

<style src="../editor.less" scoped></style>
<style scoped>
	button:disabled {
		opacity: 0.5;
	}
</style>
