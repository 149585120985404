<template>
	<div class="import-dialog">
		<ul class="steps">
			<li class="step"
				v-for="(step, index) in stepsLabels"
				:class="classObject(index + 1)"
				:key="index + stepKey"
			>
				<span class="step-icon"></span>
				<span class="step-label">{{step}}</span>
			</li>
		</ul>
		<importStepOne v-show="step === 1" :step="step" :options="options"></importStepOne>
		<importStepTwo v-if="step === 2" :step="step" :options="stepTwoData"></importStepTwo>
		<importStepThree v-if="step === 3" :step="step" :options="stepThreeData"></importStepThree>
		<importStepFour v-if="step === 4" :step="step" :options="stepFourData"></importStepFour>
	</div>
</template>
<script>
import { generateId } from '@acx-xms/data-functions/dist';
const importStepOne = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './import-step-one');
const importStepTwo = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './import-step-two');
const importStepThree = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './import-step-three');
const importStepFour = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './import-step-four');

export default {
	name: 'import-dialog',
	props: { options: Object },
	components: {
		importStepOne,
		importStepTwo,
		importStepThree,
		importStepFour
	},
	data() {
		return {
			step: 0,
			stepsLabels: null,
			fileGuid: null,
			stepTwoData: null,
			stepThreeData: null,
			stepFourData: null,
			stepKey: generateId()
		};
	},
	async created() {
		this.localization = sc.classes.get('localization.dataProvider');
		this.stepsLabels = await Promise.all([
			this.localization.getLabelForCurrentLanguage('import.dataFileSetup'),
			this.localization.getLabelForCurrentLanguage('import.fieldMapping'),
			this.localization.getLabelForCurrentLanguage('import.import'),
			this.localization.getLabelForCurrentLanguage('import.results')
		]);
		this.onStepChange({
			step: this.step + 1,
			result: null
		});
		this.$on('changeStep', this.onStepChange);
		this.$on('close', this.closeForm);
	},
	methods: {
		classObject(sequence) {
			return {
				'step-incomplete': (this.step === sequence),
				'step-active': (this.step === sequence),
				'step-complete': (this.step > sequence),
				'step-inactive': (this.step !== sequence)
			};
		},
		onStepChange({ step, result }) {
			switch (step) {
			case 1:
				this.step = step;
				break;
			case 2:
				if (result.fieldsMapping) {
					this.stepTwoData.fieldsMapping = result.fieldsMapping;
				} else if (!this.fileGuid || this.fileGuid !== result.fileGuid) {
					this.fileGuid = result.fileGuid;
					const fieldsMapping = result.parsedFile.fieldMapping.map(fm => {
						fm.checked = true;
						fm.unSelected = true;
						if (fm.metaField) {
							fm.data = {
								displayName: fm.metaField.displayName,
								value: fm.metaField.logicalName
							};
						} else {
							fm.data = null;
						}
						return fm;
					});
					this.stepTwoData = result.parsedFile;
					this.stepTwoData.fieldsMapping = fieldsMapping;
				}
				this.step = step;
				break;
			case 3:
				if (result) {
					this.stepThreeData = result;
					this.stepThreeData.displayName = this.options.displayname;
				}
				this.step = step;
				break;
			case 4:
				if (result) {
					this.stepFourData = {
						...result,
						refreshEvent: this.options.refreshEvent,
						selectedEntity: this.options.logicalname,
						finalStepButtonLabel: this.options.finalStepButtonLabel,
						failureOptions: this.options.failureOptions
					};
				}
				this.step = step;
				break;
			}
		},
		closeForm() {
			this.$parent.$emit('close');
		}
	}
};
</script>
<style src="./import-dialog.less" scoped></style>
