var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "reportingservice-editor" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "perfectscroll",
            rawName: "v-perfectscroll",
            value: { enable: true },
            expression: "{enable: true}"
          }
        ],
        staticClass: "right-panel"
      },
      [
        _c(
          "div",
          { staticClass: "selected-template" },
          [
            _c("span", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: { key: "dialogues.RS4.templates.templateLabel" },
                  expression: "{key: 'dialogues.RS4.templates.templateLabel'}"
                }
              ],
              staticClass: "title p4 pl5"
            }),
            _vm._v(" "),
            _vm.reportTemplate
              ? _c("control", {
                  attrs: {
                    name: _vm.reportTemplate.$type,
                    contentProps: _vm.reportTemplate,
                    evaluationContext: _vm.evaluationContext
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.selectedTemplate
              ? _c("div", { staticClass: "thumbnail-annotated" }, [
                  _c("div", { staticClass: "thumbnail" }, [
                    _c("div", { staticClass: "image-node" }, [
                      _c("svg", { staticClass: "svg-icon" }, [
                        _c("use", {
                          staticClass: "layout-icons-menu_templates",
                          attrs: {
                            "xlink:href": "#layout-icons-menu_templates"
                          }
                        })
                      ])
                    ])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "composer-buttons" }, [
              _c("button", {
                directives: [
                  {
                    name: "localization",
                    rawName: "v-localization",
                    value: {
                      key: "dialogues.RS4.templates.changeTemplateButton"
                    },
                    expression:
                      "{key: 'dialogues.RS4.templates.changeTemplateButton'}"
                  }
                ],
                staticClass: "button cancel",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    _vm.$emit("openTemplateList")
                  }
                }
              })
            ]),
            _vm._v(" "),
            !_vm.selectedTemplate
              ? _c("span", {
                  directives: [
                    {
                      name: "localization",
                      rawName: "v-localization",
                      value: { key: "dialogues.RS4.templates.noTemplate" },
                      expression: "{key: 'dialogues.RS4.templates.noTemplate'}"
                    }
                  ],
                  staticClass: "noTemplateLabel"
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "body" },
          [
            _c("div", { staticClass: "template-format" }, [
              _c("div", {
                directives: [
                  {
                    name: "localization",
                    rawName: "v-localization",
                    value: { key: "dialogues.RS4.reports.formatTitle" },
                    expression: "{key: 'dialogues.RS4.reports.formatTitle'}"
                  }
                ],
                staticClass: "title p4"
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("control-lookup-editor-wrapper", {
                    attrs: {
                      options: _vm.lookupOptions,
                      evaluationContext: _vm.evaluationContext
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _vm.editorTemplate
              ? _c("control", {
                  attrs: {
                    name: _vm.editorTemplate.$type,
                    contentProps: _vm.editorTemplate,
                    evaluationContext: _vm.evaluationContext
                  }
                })
              : _vm._e()
          ],
          1
        )
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c("span", { staticClass: "buttons" }, [
        _c("button", {
          directives: [
            {
              name: "data-attr",
              rawName: "v-data-attr",
              value: {
                title: _vm.options.title,
                $type: "dialog.button.submit"
              },
              expression:
                "{title: options.title, $type: 'dialog.button.submit'}"
            },
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "dialogues.RS4.dialog.generate" },
              expression: "{key: 'dialogues.RS4.dialog.generate'}"
            }
          ],
          attrs: { type: "submit", disabled: _vm.disabledButton },
          on: { click: _vm.generate }
        }),
        _vm._v(" "),
        _c("button", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "dialogues.RS4.dialog.cancel" },
              expression: "{key: 'dialogues.RS4.dialog.cancel'}"
            },
            {
              name: "data-attr",
              rawName: "v-data-attr",
              value: {
                title: _vm.options.title,
                $type: "dialog.button.cancel"
              },
              expression:
                "{title: options.title, $type: 'dialog.button.cancel'}"
            }
          ],
          staticClass: "cancel",
          attrs: { type: "button" },
          on: { click: _vm.close }
        })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }