var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "drag-and-drop-wrapper" }, [
    _c(
      "div",
      {
        staticClass: "drag-and-drop",
        class: {
          dragover: _vm.isDragover,
          disable: !_vm.enable,
          invalid: _vm.invalid
        },
        on: {
          dragover: function($event) {
            $event.preventDefault()
            return _vm.dragover($event)
          },
          dragleave: _vm.dragleave,
          drop: function($event) {
            $event.preventDefault()
            return _vm.drop($event)
          }
        }
      },
      [
        _c("input", {
          directives: [
            {
              name: "data-attr",
              rawName: "v-data-attr",
              value: _vm.dataAttr,
              expression: "dataAttr"
            }
          ],
          ref: "file",
          attrs: {
            type: "file",
            multiple: "",
            id: "files",
            accept: _vm.acceptExtensions || ".*"
          },
          on: { change: _vm.onChangeInput }
        }),
        _vm._v(" "),
        _c("label", { attrs: { for: "files" } }, [
          _c("svg", { staticClass: "svg-icon svg-50" }, [
            _c("use", { attrs: { "xlink:href": "#layout-icons-upload" } })
          ]),
          _vm._v(" "),
          _c("span", {
            directives: [
              {
                name: "localization",
                rawName: "v-localization",
                value: { key: "controls.dragAndDrop.dropFile.label" },
                expression: "{key: 'controls.dragAndDrop.dropFile.label'}"
              }
            ],
            staticClass: "h4"
          }),
          _vm._v(" "),
          _c("span", {
            directives: [
              {
                name: "localization",
                rawName: "v-localization",
                value: { key: "controls.dragAndDrop.filesLimitation.label" },
                expression:
                  "{key: 'controls.dragAndDrop.filesLimitation.label'}"
              }
            ],
            staticClass: "h4"
          })
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }