import { BeforeSaveAspect } from './aspects/beforeSave.aspect.js';

export class EntityEditor {
	constructor(options) {
		this.options = options;
	}

	installAspects(data) {
		const results = [];
		const context = sc.createEvaluationContext(this.options.rawEntity);
		const latitudeField = context.eval(this.options.latitudeField);
		const longitudeField = context.eval(this.options.longitudeField);

		results.push(
			new BeforeSaveAspect({
				fields: this.options.inputField,
				latitudeField,
				longitudeField
			})
		);

		return results;
	}
}
