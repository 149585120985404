<template>
	<div class="control-texteditor">
		<div class="input" v-bind:class="{'disabled': !enable, 'invalid': !isValid}">
			<div class="required-mark" v-if="showRequiredMark">
				<svg class="svg-icon svg-8 layout-icons-required">
					<use xlink:href="#layout-icons-required"></use>
				</svg>
			</div>
			<textarea :height="height" :disabled="!enable" @focus="focused = true" @blur="focused = false" v-model.lazy="value" v-bind:rows=rows />
		</div>
	</div>
</template>
<script>
/* eslint  vue/no-unused-components: 0 */
import ControlMixins from '@/Components/Editor/Controls/control.mixins';
const ControlSvgIcon = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Images/control-svg-icon');

export default {
	name: 'control-texteditor',
	mixins: [ControlMixins],
	components: { ControlSvgIcon },
	data() {
		return {
			focused: false,
			rows: this.options.rows ? parseInt(this.evaluationContext.eval(this.options.rows)) : 4,
			height: this.options.rows ? 'auto' : '75px'
		};
	},
	methods: {
		clear() {
			this.value = '';
		}
	}
};
</script>
<style src="./control-texteditor.less" scoped></style>
