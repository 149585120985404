<template>
	<div class="filter-content input-check-box include-empty panel-content">
		<label :class="{ disabled: !enabled }">
			<div class="facet-item-checkbox">
				<span :class="['checkbox bordered', { checked: value }]">
					<svg class="svg-icon svg-12">
						<use xlink:href="#layout-icons-checkbox-small-checked" class="layout-icons-checkbox-small-checked" />
					</svg>
				</span>
				<input
					v-model="included"
					:disabled="!enabled"
					type="checkbox"
					@change="includeChanged"
				/>
			</div>
			<span
				v-localization="{ key: 'filtersPanel.includeEmpty' }"
				class="title"
			/>
		</label>
	</div>
</template>
<script>
export default {
	name: 'include-empty',
	props: {
		value: Boolean,
		enabled: Boolean
	},
	data() {
		return { included: this.value };
	},
	methods: {
		includeChanged: function () {
			this.$emit('input', this.included);
		}
	}
};
</script>
<style src="./panels.less" scoped></style>
