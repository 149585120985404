<template>
	<div class="account-wrapper">
		<div class="account">
			<p class="message">Select the account you want to add:</p>
			<div class="service-account"
				v-for="type in accountTypes"
				:key="type.Source.name"
				@click="onClick(type.Source.optionname)"
			>
				<svg class="svg-icon svg-15">
					<use xlink:href="#action-icons-mailchimp-logo" class="icon"></use>
				</svg>
				<span class="service-name">{{ type.Source.name }}</span>
			</div>
			<div class="loading-overlay" v-show="isLoading"></div>
		</div>
	</div>
</template>
<script>
import { GetPerConfig } from '@/Data/DataProviders/userStorageDataProvider';
import {
	resourceIsRichable, isNullOrUndefined, Search, Create, Update
} from '@acx-xms/data-functions/dist';
import { authenticate as authMCI, getMCIUrlSwagger } from '@/Data/DataProviders/coMCIDataprovider';
import coAuthDataProvider from '@/Data/DataProviders/coAuthDataprovider';
import { GetUserInfo } from '@/Data/Auth/authentication-service';

const ACCOUNTS = { mailchimp: 'addMCIAccount' };

export default {
	name: 'add-service-dialog',
	data: () => ({
		isLoading: false,
		accountTypes: [],
		mciUser: null
	}),
	async created() {
		this.isLoading = true;
		const { Results: lookupResults } = await Search('lookupserviceaccounttype', [
			sc.classes.get('offsetSize.filter', 99),
			sc.classes.get('selectedFields.filter', [
				{ logicalname: 'name' },
				{ logicalname: 'optionname' }
			]).fillQuery()
		]);
		this.accountTypes = lookupResults;
		this.isLoading = false;
	},
	beforeDestroy() {
		window.onstorage = null;
	},
	methods: {
		async onClick(type) {
			this[ACCOUNTS[type]] && this[ACCOUNTS[type]]();
		},
		async addMCIAccount() {
			this.isLoading = true;

			let [coAuthIsRichable, mciAuthIsRichable] = await Promise.all([
				resourceIsRichable(await coAuthDataProvider.getCOAuthURLSwagger()),
				resourceIsRichable(await getMCIUrlSwagger())
			]);
			mciAuthIsRichable = isNullOrUndefined(mciAuthIsRichable)
				? true
				: mciAuthIsRichable;

			if (!coAuthIsRichable || !mciAuthIsRichable) {
				this.isLoading = false;
				return;
			}
			let token = await GetPerConfig('co-auth-key');
			const proceedAuthentication = async (refresh) => {
				try {
					await this.authenticateMCI();
				} catch (err) {
					if (refresh && (err.toString() === 'Error: JWT token is absent or invalid' || err.message === 'JWT token is absent or invalid')) {
						const refreshed = await this.refreshToken();
						refreshed && await this.authenticateMCI();
						await proceedAuthentication();
					}
					console.log(err);
					this.isLoading = false;
				}
			};

			if (token) {
				await proceedAuthentication(true);
			} else {
				await this.authenticate();
			}

			window.onstorage = async () => {
				const newToken = await GetPerConfig('co-auth-key');

				if (newToken && token !== newToken) {
					token = newToken;
					proceedAuthentication();
				} else if (newToken === 'undefined') {
					window.onstorage = null;
				}
			};
		},
		async authenticate() {
			await coAuthDataProvider.authenticate(`${window.location.origin}/${this.$route.params.clusterKey}/${this.$route.params.config}/coauth`, true);
		},
		async authenticateMCI() {
			try {
				this.mciUser = JSON.parse(await authMCI());
				await this.processMCIUser();
			} catch (e) {
				try {
					await this.authenticate();
				} catch (e) {
					if (e.message === 'pop-ups are blocked') {
						this.$parent.$emit('close');
						sc.events.emit('vue.dialog.info', {
							title: 'Start Email Campaign',
							message: 'Please, allow pop-ups on in the browser\'s settings and re-open the window to continue',
							showFooter: false
						});
					}
				} finally {
					if (!this.mciUser) {
						this.mciUser = JSON.parse(await authMCI());
						await this.processMCIUser();
					}
				}
			}
		},
		async refreshToken() {
			try {
				await coAuthDataProvider.refreshToken();
				return true;
			} catch {
				await this.authenticate();
				return false;
			}
		},
		async processMCIUser() {
			if (!this.mciUser) {
				this.mciUser = JSON.parse(await authMCI());
			}
			const accountType = 'MailChimp';
			const { email, mcUserId, userId, mcName } = this.mciUser;
			const { firstname, lastname } = await GetUserInfo(); // get rid of this fields when composer will add them into response above
			const serviceAccount = this.accountTypes.find(type => type.Source.name === accountType);
			const { Results: accountResults } = await Search(
				'systemuserserviceaccount',
				[
					sc.classes.get('offsetSize.filter', 1),
					sc.classes.get('termFacet.filter', {
						logicalName: 'serviceaccountuserid',
						query: [mcUserId]
					}).fillQuery(),
					sc.classes.get('termFacet.filter', {
						logicalName: 'systemuserid.id',
						query: [userId]
					}).fillQuery(),
					sc.classes.get('selectedFields.filter', [
						{ logicalname: 'inuse' }
					]).fillQuery()
				]
			);

			if (!accountResults.length) {
				await Create('systemuserserviceaccount', {
					name: mcName || firstname + ' ' + lastname,
					serviceaccountuserid: mcUserId,
					inuse: true,
					systemuserid: {
						id: userId,
						logicalname: 'systemuser'
					},
					serviceaccounttype: {
						id: serviceAccount.Id,
						logicalname: serviceAccount.Type
					},
					firstname,
					lastname,
					email
				});
			} else if (accountResults[0].Source && !accountResults[0].Source.inuse) {
				await Update(accountResults[0].Type, accountResults[0].Id, { inuse: true });
			}

			this.isLoading = false;

			this.$root.$emit('refreshCurrentDetails');
			this.$parent.$emit('close');
		}
	}
};
</script>
<style src="./add-service-dialog.less" scoped></style>
