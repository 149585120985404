<template>
	<div class="create-relation-dialog editor">
		<div class="search-record">
			<div class="loading-overlay large" v-show="isLoading"></div>
			<div class="body">
				<control v-if="editorTemplate" :name="editorTemplate.$type"
						 :contentProps="editorTemplate"
						 :evaluationContext="options.params.evaluationContext"></control>
			</div>
		</div>
		<div class="entity-list">
			<control class="formcontrol-template"
					 v-if="documentRelatedRecordsTemplate"
					 :name="documentRelatedRecordsTemplate.$type"
					 :contentProps="documentRelatedRecordsTemplate"
					 :initResults="documentRelatedRecords"
					 :key="componentKey"></control>
		</div>
		<div class="footer">
			<div class="dialog-buttons">
				<button @click="cancel" class="cancel" type="button" v-localization="{key: 'common.dictionary.buttons.cancel'}" v-data-attr="{title: options.title , $type: 'dialog.button.cancel'}"></button>
				<button @click="save" type="button" v-localization="{key: 'common.dictionary.buttons.save'}" :disabled="!selectedRecord" v-data-attr="{title: options.title , $type: 'dialog.button.cancel'}"></button>
			</div>
		</div>
	</div>
</template>
<script>
/* eslint vue/no-unused-components: 0 */
import Control from '@/Components/Entity/control.vue';
import ControlLookupEditor from '@/Components/Control/Lookup/control-lookup-editor';
import createRelationDataProvider from '@/Data/DataProviders/createRelationDataProvider';
import editorMixins from '@/Components/editor.mixins.js';
import sharedToMixin from '@/Components/Dialogues/SharedToDialog/sharedTo.mixins.js';
import { showToastOrInfoDialog } from '@acx-xms/data-functions/dist';
import {
	processResults, searchDocumentRelatedRecords, searchChilrenDocuments
} from './docsRelationUtils';

export default {
	name: 'document-folder-add-to-dialog',
	components: {
		Control,
		ControlLookupEditor
	},
	props: { options: Object },
	mixins: [editorMixins, sharedToMixin],
	data() {
		return {
			isLoading: false,
			editorTemplate: null,
			documentRelatedRecordsTemplate: null,
			documentRelatedRecords: null,
			componentKey: 0
		};
	},
	computed: {
		selectedRecord() {
			const entity = this.$store.state[this.stateKey] && this.$store.state[this.stateKey].entityData;
			return entity ? entity.parentRecord : null;
		}
	},
	async created() {
		this.isLoading = true;

		this.relationRecordName = this.options.params.relationRecordName;
		this.relateToEntityName = this.options.params.relateToEntityName;
		this.relateFromRecord = this.options.params.relateFromRecord;
		this.logicalName = this.options.logicalName;
		this.stateKey = this.registerModule(this.logicalName);

		if (this.relateFromRecord && !this.relateFromRecord.hasOwnProperty('Source')) {
			const edgeDataProvider = sc.classes.get('edge.dataProvider');
			this.relateFromRecord = await edgeDataProvider.get(this.relateFromRecord.logicalname, this.relateFromRecord.id);
		}
		const configDataProvider = sc.classes.get('entityConfiguration.dataProvider');
		const editorTemplate = this.options.editorTemplate && await configDataProvider
			.getTemplate(this.options.editorTemplate.entity, this.options.editorTemplate.usage, this.options.editorTemplate.subtype);
		this.editorTemplate = editorTemplate && editorTemplate.content;

		this.isLoading = false;
	},
	methods: {
		async save() {
			this.isLoading = true;
			const relateToField = this.relateToEntityName + 'id';
			const relateFromField = this.relateFromRef.logicalname + 'id';
			const relateToRef = {
				id: this.selectedRecord.id,
				logicalname: this.relateToEntityName
			};

			try {
				const isExist = await createRelationDataProvider
					.checkIsRelatedRecordExists(this.relationRecordName, relateFromField, this.relateFromRef.id, relateToField, relateToRef.id);
				if (isExist) {
					this.isLoading = false;
					this.openInfoDialog(
						'Warning',
						'Current document is already related to the selected record. Please choose another one'
					);
				} else {
					await createRelationDataProvider.createRelationRecord(
						this.relationRecordName,
						relateFromField,
						this.relateFromRef,
						relateToField,
						relateToRef,
						this.relateFromRef.name,
						null
					);
				}
			} catch (err) {
				let error;
				typeof err === 'object' && (error = err.message || err.Message);
				const errorMessage = error ? `Error message: ${error}` : 'Unhandled error.';
				this.isLoading = false;
				this.openInfoDialog('Something went wrong', errorMessage);
			} finally {
				let filteredResults;

				if (this.logicalName === 'folder') {
					this.childrenDocuments = await searchChilrenDocuments(this.relateFromRef.id);
					if (this.childrenDocuments.length) {
						const promises = this.childrenDocuments.map(document => {
							return searchDocumentRelatedRecords([document.id], this.options);
						});
						const results = await Promise.all(promises);
						filteredResults = (results || []).flat().map(res => res.Source);
					}
				} else if (this.logicalName === 'document') {
					const records = await searchDocumentRelatedRecords([this.relateFromRef.id], this.options);
					filteredResults = records.map(res => res.Source);
				}
				this.documentRelatedRecords = processResults(filteredResults);
				this.componentKey += 1;

				this.isLoading = false;
				await showToastOrInfoDialog({
					toastMessageKey: this.options.params.toastMessageKey,
					informationDialogTextKey: this.options.params.informationDialogTextKey,
					options: { context: this }
				});
			}
		},
		openInfoDialog(title, message) {
			this.$root.$emit('vue.dialog.info', {
				title,
				message
			});
		}
	}
};
</script>
<style src="@/Components/CreateRelation/create-relation-dialog.less" scoped></style>
