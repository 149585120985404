var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-content-wrapper" }, [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ],
      staticClass: "loading-overlay large"
    }),
    _vm._v(" "),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "add-to-list" }, [
        _c("div", { staticClass: "email-wrapper" }, [
          _c("h5", {
            directives: [
              {
                name: "localization",
                rawName: "v-localization",
                value: { key: "dialogues.addBrokers.emailLabel" },
                expression: "{ key: 'dialogues.addBrokers.emailLabel'}"
              }
            ],
            attrs: { for: "email" }
          }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.emailAddress,
                expression: "emailAddress",
                modifiers: { trim: true }
              },
              {
                name: "data-attr",
                rawName: "v-data-attr",
                value: { title: _vm.options.title, $type: "dialog.input" },
                expression: "{title: options.title, $type: 'dialog.input'}"
              }
            ],
            attrs: {
              type: "text",
              id: "email",
              placeholder: "email@example.com",
              maxlength: "255"
            },
            domProps: { value: _vm.emailAddress },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.emailAddress = $event.target.value.trim()
              },
              blur: function($event) {
                _vm.$forceUpdate()
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("button", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "dialogues.addBrokers.addToList" },
              expression: "{ key: 'dialogues.addBrokers.addToList'}"
            },
            {
              name: "data-attr",
              rawName: "v-data-attr",
              value: {
                title: _vm.options.title,
                $type: "dialog.button.submit"
              },
              expression:
                "{title: options.title, $type: 'dialog.button.submit'}"
            }
          ],
          attrs: {
            type: "button",
            disabled: !_vm.emailAddress || !_vm.emailAddress.length
          },
          on: { click: _vm.addToList }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "list-content" }, [
        _c("div", { staticClass: "search-area" }, [
          _c("h5", {
            directives: [
              {
                name: "localization",
                rawName: "v-localization",
                value: { key: "dialogues.addBrokers.listContent" },
                expression: "{ key: 'dialogues.addBrokers.listContent'}"
              }
            ]
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "search-field-wrapper inline-block",
              class: { focused: _vm.searchFocused }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "localization",
                    rawName: "v-localization",
                    value: {
                      key: "common.dictionary.search",
                      attrs: ["placeholder"]
                    },
                    expression:
                      "{ key: 'common.dictionary.search', attrs: ['placeholder'] }"
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.term,
                    expression: "term"
                  }
                ],
                staticClass: "search-box",
                attrs: { type: "text" },
                domProps: { value: _vm.term },
                on: {
                  keyup: function($event) {
                    if (
                      !("button" in $event) &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.getBrokerList($event)
                  },
                  focus: function($event) {
                    _vm.searchFocused = true
                  },
                  blur: function($event) {
                    _vm.searchFocused = false
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.term = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "search-icon-wrapper" }, [
                _c(
                  "svg",
                  { staticClass: "svg-icon", on: { click: _vm.getBrokerList } },
                  [
                    _c("use", {
                      staticClass: "layout-icons-ms-search",
                      attrs: { "xlink:href": "#layout-icons-ms-search" }
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "svg",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.term && _vm.term.length > 0,
                        expression: "term && term.length > 0"
                      }
                    ],
                    staticClass: "svg-icon svg-20 clear-button",
                    on: { click: _vm.clearSearchBox }
                  },
                  [
                    _c("use", {
                      staticClass: "layout-icons-textbox-delete",
                      attrs: { "xlink:href": "#layout-icons-textbox-delete" }
                    })
                  ]
                )
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "control-tableView" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.brokerList.length,
                  expression: "brokerList.length"
                }
              ],
              staticClass: "tableview-header"
            },
            [
              _c("div", { staticClass: "cell" }, [
                _c("span", {
                  directives: [
                    {
                      name: "localization",
                      rawName: "v-localization",
                      value: { key: "dialogues.addBrokers.headerEmail" },
                      expression: "{ key: 'dialogues.addBrokers.headerEmail'}"
                    }
                  ]
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "tableview-content" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "perfectscroll",
                    rawName: "v-perfectscroll",
                    value: { enable: true },
                    expression: "{ enable: true }"
                  }
                ],
                staticClass: "content-scroll-wrapper"
              },
              [
                _vm._l(_vm.brokerList, function(broker) {
                  return _c("div", { key: broker.Id, staticClass: "row" }, [
                    _c("div", { staticClass: "table-cell" }, [
                      _c("span", { staticClass: "label" }, [
                        _vm._v(_vm._s(broker.Source.email))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "table-cell" }, [
                      _c(
                        "span",
                        {
                          staticClass: "icon-wrapper",
                          on: {
                            click: function($event) {
                              _vm.removeFromList(broker)
                            }
                          }
                        },
                        [
                          _c("svg", { staticClass: "svg-icon svg-15" }, [
                            _c("use", {
                              attrs: { "xlink:href": "#action-icons-close-btn" }
                            })
                          ])
                        ]
                      )
                    ])
                  ])
                }),
                _vm._v(" "),
                _vm.brokerList && !_vm.brokerList.length
                  ? _c("div", { staticClass: "row no-results-message p13" }, [
                      _c(
                        "div",
                        { staticClass: "table-cell", style: { width: "100%" } },
                        [
                          _c("div", {
                            directives: [
                              {
                                name: "localization",
                                rawName: "v-localization",
                                value: {
                                  key: "common.dictionary.noMatchesFound"
                                },
                                expression:
                                  "{ key: 'common.dictionary.noMatchesFound' }"
                              }
                            ]
                          })
                        ]
                      )
                    ])
                  : _vm._e()
              ],
              2
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "footer" }, [
        _c("div", { staticClass: "buttons" }, [
          _c("span", { staticClass: "button-wrapper" }, [
            _c("button", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: { key: "common.dictionary.buttons.save" },
                  expression: "{key: 'common.dictionary.buttons.save'}"
                },
                {
                  name: "data-attr",
                  rawName: "v-data-attr",
                  value: {
                    title: _vm.options.title,
                    $type: "dialog.button.submit"
                  },
                  expression:
                    "{title: options.title, $type: 'dialog.button.submit'}"
                }
              ],
              attrs: {
                type: "button",
                disabled: !_vm.itemsToDelete.length && !_vm.itemsToAdd.length
              },
              on: { click: _vm.ok }
            })
          ]),
          _vm._v(" "),
          _c("button", {
            directives: [
              {
                name: "localization",
                rawName: "v-localization",
                value: { key: "common.dictionary.buttons.cancel" },
                expression: "{key: 'common.dictionary.buttons.cancel'}"
              },
              {
                name: "data-attr",
                rawName: "v-data-attr",
                value: {
                  title: _vm.options.title,
                  $type: "dialog.button.cancel"
                },
                expression:
                  "{title: options.title, $type: 'dialog.button.cancel'}"
              }
            ],
            staticClass: "cancel",
            attrs: { type: "button" },
            on: { click: _vm.close }
          })
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }