<template>
	<map-tooltip-base :options="options" />
</template>
<script>
/* eslint vue/no-mutating-props:0 */
const MapTooltipBase = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './map-tooltip-base.vue');

export default {
	name: 'mobile-map-tooltip',
	props: { options: Object },
	components: { MapTooltipBase },
	created() {
		this.options.paginationComponent = 'mobile-pagination';
		this.options.psAddMouseOverEvent = false;
	}
};
</script>
