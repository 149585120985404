var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "assign-license-dialog" }, [
    _c("div", { staticClass: "pb5" }, [
      _c("h5", [_vm._v(_vm._s(_vm.unassignedLicenseCountText))])
    ]),
    _vm._v(" "),
    _c("h5", {
      directives: [
        {
          name: "localization",
          rawName: "v-localization",
          value: { key: "dialogues.assignLicense.selectLicense" },
          expression: "{key: 'dialogues.assignLicense.selectLicense'}"
        }
      ],
      staticClass: "pb5"
    }),
    _vm._v(" "),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ],
      staticClass: "loading-overlay large"
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "select-record" },
      [
        !_vm.isLoading
          ? _c("control-lookup-editor", {
              attrs: {
                evaluationContext: _vm.options.params.evaluationContext,
                options: _vm.getLookupOptions(),
                showRequired: !this.selectedRecord
              },
              model: {
                value: _vm.selectedRecord,
                callback: function($$v) {
                  _vm.selectedRecord = $$v
                },
                expression: "selectedRecord"
              }
            })
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c("div", { staticClass: "dialog-buttons" }, [
        _c("button", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "common.dictionary.buttons.cancel" },
              expression: "{key: 'common.dictionary.buttons.cancel'}"
            },
            {
              name: "data-attr",
              rawName: "v-data-attr",
              value: {
                title: _vm.options.title,
                $type: "dialog.button.cancel"
              },
              expression:
                "{title: options.title, $type: 'dialog.button.cancel'}"
            }
          ],
          staticClass: "cancel",
          attrs: { type: "button" },
          on: { click: _vm.close }
        }),
        _vm._v(" "),
        _c("button", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "dialogues.assignLicense.assign" },
              expression: "{key: 'dialogues.assignLicense.assign'}"
            },
            {
              name: "data-attr",
              rawName: "v-data-attr",
              value: {
                title: _vm.options.title,
                $type: "dialog.button.submit"
              },
              expression:
                "{title: options.title, $type: 'dialog.button.submit'}"
            }
          ],
          attrs: { type: "button", disabled: !this.selectedRecord },
          on: { click: _vm.save }
        })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }