<template>
	<div class="manage-roles">
		<div class="dialog-container">
			<h5 class="subheader" v-localization="{ key: 'dialogues.manageSecurityRoles.selectRole'}"></h5>
			<div class="input-radio-wrapper">
				<label>
					<input type="radio" v-model="selectedGroup" value="Guest" />
					<span class="p1">Guest</span>
				</label>
				<label>
					<input type="radio" v-model="selectedGroup" value="Broker" @click="selectMSODSRole" />
					<span class="p1">Internal User</span>
				</label>
			</div>
			<h5 v-if="selectedGroup==='Broker'" class="subheader" key="Broker" v-localization="{ key: 'dialogues.manageSecurityRoles.assignAddRoles'}"></h5>
			<h5 v-else class="subheader disabled" key="Guest" v-localization="{ key: 'dialogues.manageSecurityRoles.noAddRolesForGuest'}"></h5>
			<div class="roles-wrapper" :class="{'disabled': selectedGroup!=='Broker'}">
				<div v-for="role in roles" @click="selectRole(role)" class="role" v-if="role.enable" :key="role.value">
					<div class="checkbox-wrapper">
						<div class="virtual-checkbox clickable" :class="role.checked ? 'selected' : 'unselected'">
							<svg class='svg-icon'>
								<use xlink:href='#layout-icons-ms-checkbox' class='layout-icons-ms-checkbox'></use>
							</svg>
						</div>
					</div>
					<div class="label p2">{{ role.name }}</div>
				</div>
			</div>
		</div>
		<div class="footer">
			<button class="cancel" @click="onCancel" v-data-attr="{title: options.title, $type: 'dialog.button.cancel'}">Cancel</button>
			<button @click="onSubmit" v-data-attr="{title: options.title, $type: 'dialog.button.submit'}">Save</button>
		</div>
		<div class="loading-overlay" v-show="isLoading"></div>
	</div>
</template>
<script>
import helperMethods from '@/Components/ComponentSet/component-set-evaluation-context-helper.methods';
import {
	showToastOrInfoDialog, CreateBulk, Update
} from '@acx-xms/data-functions/dist';

export default {
	name: 'manage-security-roles-dialog',
	components: {},
	props: { options: Object },
	data() {
		return {
			isLoading: false,
			selectedGroup: null,
			groups: ['Broker', 'Guest'],
			roles: []
		};
	},
	async created() {
		this.isLoading = true;
		this.initialUserRolesList = this.options.parameters.rolemembers.map(rolemember => { return rolemember.name; });
		this.edgeDataProvider = sc.classes.get('edge.dataProvider');
		const rolesSesttings = await sc.classes.get('settings.dataProvider').get('manageSecurityRoles-settings');
		this.roles = rolesSesttings.role.map(role => {
			return {
				name: role.name,
				value: role.value,
				checked: false,
				enable: role.enable ? helperMethods.eval(null, role.enable) : true
			};
		});

		// TODO: rework finding systemuser group in group's list
		this.selectedGroup = this.groups.filter(group => this.options.parameters.group.name.includes(group)).toString();
		this.initialGroup = this.selectedGroup;
		// check roles if they match with roles in settings
		this.roles.forEach(role => {
			if (this.initialUserRolesList.includes(role.value || role.name)) {
				role.checked = true;
			}
		});
		this.isLoading = false;
	},
	methods: {
		selectMSODSRole() {
			const roleIndex = this.roles.findIndex(role => role.name === 'MarketSpace ODS');
			if (roleIndex !== -1 && this.roles[roleIndex].enable) {
				this.roles[roleIndex].checked = true;
			}
		},
		selectRole(role) {
			role.checked = !role.checked;
		},
		getAllRoles() {
			return this.edgeDataProvider.search({
				entities: ['role'],
				filters: [
					sc.classes.get('offsetSize.filter', 100),
					sc.classes.get('selectedFields.filter', [])
				]
			}).then(roles => {
				return roles.Results.map(role => {
					return {
						id: role.Id,
						name: role.Name
					};
				});
			});
		},
		getAllGroups() {
			return this.edgeDataProvider.search({
				entities: ['group'],
				filters: [
					sc.classes.get('offsetSize.filter', 10),
					sc.classes.get('selectedFields.filter', [])
				]
			}).then(groups => {
				return groups.Results.map(group => {
					return {
						id: group.Id,
						name: group.Name
					};
				});
			});
		},
		onCancel() {
			this.close();
		},
		async onSubmit() {
			this.isLoading = true;

			if (this.initialGroup !== this.selectedGroup) {
				const allGroups = await this.getAllGroups();
				const newGroup = allGroups.filter(group => group.name.includes(this.selectedGroup));
				await Update(this.options.parameters.logicalname, this.options.parameters.systemuserid, {
					groupid: {
						id: newGroup[0].id,
						logicalname: 'group'
					}
				}, null, { async: true });
			}

			if (this.selectedGroup === 'Broker') {
				// update roles
				await this.changeRoles(this.initialUserRolesList, this.roles);
			}
			this.$root.$emit('entity.changed', { entity: { logicalname: this.options.parameters.logicalname } });
			await showToastOrInfoDialog({
				toastMessageKey: this.options.toastMessageKey,
				informationDialogTextKey: this.options.informationDialogTextKey,
				options: { context: this }
			});

			this.close();
		},
		async changeRoles(initialRoles, assignedRoles) {
			const promiseArr = [];
			const rolesToAdd = [];
			const rolesToDelete = [];
			const allRoles = await this.getAllRoles();

			assignedRoles.forEach(assignedRole => {
				if (initialRoles.includes(assignedRole.value || assignedRole.name)) {
					if (!assignedRole.checked) {
						rolesToDelete.push(assignedRole.value || assignedRole.name);
					}
				} else {
					if (assignedRole.checked) {
						rolesToAdd.push(assignedRole.value || assignedRole.name);
					}
				}
			});
			if (rolesToAdd.length && allRoles.length) {
				const records = [];
				rolesToAdd.forEach(roleToAdd => {
					const roleRef = allRoles.filter(role => role.name === roleToAdd)[0];
					const record = {
						id: sc.utils.guid(),
						method: 'POST',
						url: 'rolemember',
						body: {
							type: 'rolemember',
							name: ' ', // required field in EDGE
							roleid: {
								id: roleRef.id,
								logicalname: 'role'
							},
							memberid: {
								id: this.options.parameters.systemuserid,
								logicalname: this.options.parameters.logicalname
							},
							rolename: roleRef.name
						}
					};
					records.push(record);
				});
				promiseArr.push(CreateBulk(records, { async: true }));
			}
			if (rolesToDelete.length) {
				rolesToDelete.forEach(roleToDelete => {
					const rolememberRef = this.options.parameters.rolemembers.filter(role => role.name === roleToDelete)[0];
					promiseArr.push(this.edgeDataProvider.deleteEntity('rolemember', rolememberRef.rolememberid));
				});
			}
			return Promise.all(promiseArr);
		},
		close() {
			this.$parent.$emit('close');
		}
	}
};
</script>
<style src="./manage-security-roles-dialog.less" scoped></style>
<style src="@/Components/Control/Checkboxes/virtual-checkbox.less" scoped></style>
