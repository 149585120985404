<template>
	<div class="dialog-content-wrapper copy-link-dialog editor">
		<div class="dialog-container">
			<div class="field-wrapper field-wrapper-copy-share-link">
				<div class="loading-overlay" v-show="isLoading || isDisabled"></div>
				<div class="info-text p1">{{ infoText }}</div>
				<div class="p4">Link</div>
				<div class="field" :class="isDisabled && 'disabled'">
					<input class="field-input" ref="text" type="text" :value="url" />
					<button type="submit" @click="copy">{{ copyBtnText }}</button>
				</div>
				<div class="p4 link-lifetime-title">Link lifetime</div>
				<div class="link-lifetime">
					<input min="1" max="24" :disabled="isCopied" @keypress='lifeTimeHandlerRegex' @change="lifeTimeHandler" placeholder="Default 6" class="link-lifetime-input" type="number" :value="linkLifeTime" />months
				</div>
			</div>
		</div>
		<div class='footer'>
			<span class="buttons">
				<button type="button" class="cancel" @click="cancel" v-localization="{key: 'common.dictionary.buttons.close'}"></button>
			</span>
		</div>
	</div>
</template>
<script>
import { Create, getBaseRouteUrl } from '@acx-xms/data-functions/dist';

export default {
	name: 'share-search-results-dialog',
	props: { options: Object },
	data() {
		return {
			isCopied: false,
			isLoading: true,
			url: '',
			isDisabled: Boolean(this.options.isDisabled),
			infoText: this.options.infoText,
			sharedsearchid: null,
			linkLifeTime: null
		};
	},
	computed: {
		copyBtnText() {
			return this.isCopied ? 'Copied!' : 'Copy Link';
		}
	},
	async created() {
		this.sharedsearchid = sc.utils.guid();
		this.url = `${window.location.origin}${getBaseRouteUrl(this.$route)}/${this.$route.params.componentSet}/sharedsearch/${this.sharedsearchid}`;
		this.isLoading = false;
	},
	methods: {
		async copy() {
			if (!this.isCopied) {
				this.isLoading = true;
				await Create('sharedsearch', {
					name: `Share link for ${this.options.logicalNames.join(', ')}. ${new Date().toISOString()}`,
					filters: this.options.filters,
					sharedsearchid: this.sharedsearchid,
					lifetimemonths: this.lifeTimeHandler(this.linkLifeTime)
				}).finally(() => {
					navigator.clipboard.writeText(this.url).then(() => { this.isCopied = true; });
					this.isLoading = false;
				});
			} else {
				navigator.clipboard.writeText(this.url);
			}
		},
		cancel() {
			this.$parent.$emit('close');
		},
		lifeTimeHandler(e) {
			this.linkLifeTime = null;
			const value = e && e.target ? e.target.value : e;
			value > 0 ? Number(value) > 24 ? this.linkLifeTime = 24 : this.linkLifeTime = value : this.linkLifeTime = 6;
			return this.linkLifeTime;
		},
		lifeTimeHandlerRegex(e) {
			return /^[A-Za-z0-9]+$/.test(String.fromCharCode(e.keyCode)) ? true : e.preventDefault();
		}
	}
};
</script>
<style src="./share-search-results-dialog.less" scoped></style>
