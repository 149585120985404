<template>
	<div class="drag-and-drop-results">
		<div class="progress" v-if="value && value.length">
			<p class="h2">{{value.length}} file(s) added for upload.</p>
			<span class="label" v-show="progress===null" v-localization="{key: 'controls.dragAndDrop.clickUploadToStart.label'}"></span>
			<div v-if="progress!=='100' && progress!==null">
				<span class="label">Uploading {{validFiles.length}} files: {{progress + '%'}}</span>
			</div>
			<span class="label" v-show="progress==='100'">{{this.validFiles.length + ' files uploaded successfully!'}}</span>
			<div class="content" v-show="progress!==null">
				<div class="progress-bar-container" v-bind:style="{ width: progress + '%' }"></div>
			</div>
		</div>
		<div class="control-tableView control-component formcontrol-template" v-if="value && value.length">
			<div class="tableview-header">
				<div class="cell first-cell" style="width: 70%;"><span v-localization="{key: 'controls.dragAndDrop.tableHeader.name'}"></span></div>
				<div class="cell" style="width: 10%;"><span v-localization="{key: 'controls.dragAndDrop.tableHeader.size'}"></span></div>
				<div class="cell" style="width: 20%;"><span v-localization="{key: 'controls.dragAndDrop.tableHeader.progress'}"></span></div>
			</div>
			<div class="tableview-content">
				<div class="content-scroll-wrapper" v-perfectscroll="{ enable: true }">
					<div class="row" bind:is-odd="index%2==0" v-for="data in value" :key="data.file.id">
						<div class="table-cell" style="width: 70%;">
							<div class="label-wrapper control-component  wrap"><span class="label">{{data.file.name}}</span></div>
						</div>
						<div class="table-cell" style="width: 10%;">
							<div class="label-wrapper control-component wrap"><span class="label">{{data.file.size}}</span></div>
						</div>
						<div class="table-cell" style="width: 15%;">
							<div class="label-wrapper control-component wrap" v-bind:class="{'p2': data.state === 'uploading', 'error': data.errors.length}"><span class="label">{{getProgressLabel(data)}}</span></div>
						</div>
						<div class="table-cell" style="width: 5%;">
							<span class="icon-wrapper" @click="remove(data)" v-if="data.state === 'notStarted'">
								<svg class="svg-icon svg-15">
									<use xlink:href="#action-icons-close-btn"></use>
								</svg>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'control-file-draganddrop',
	props: ['value'],
	data() {
		return {
			isDragover: false,
			documentTemplate: null
		};
	},
	async created() {
		this.localization = sc.classes.get('localization.dataProvider');
		this.notStartedState = await this.localization.getLabelForCurrentLanguage('controls.dragAndDrop.notStarted.state');
		this.queuedState = await this.localization.getLabelForCurrentLanguage('controls.dragAndDrop.queued.state');
		this.uploadingState = await this.localization.getLabelForCurrentLanguage('controls.dragAndDrop.uploading.state');
		this.uploadedState = await this.localization.getLabelForCurrentLanguage('controls.dragAndDrop.uploaded.state');
	},
	computed: {
		progress() {
			if (this.validFiles && this.validFiles.length) {
				const isStaredUpload = this.validFiles.some(field => {
					return ['queued', 'uploaded'].includes(field.state);
				});

				if (isStaredUpload) {
					const uploadedFiles = this.validFiles.filter(field => {
						return field.state === 'uploaded';
					});

					return (uploadedFiles.length / this.validFiles.length * 100).toFixed(0);
				}
			}

			return null;
		},
		validFiles() {
			return this.value.filter(f => !f.errors.length);
		}
	},
	methods: {
		remove({ id, state }) {
			if (state !== 'notStarted') {
				return;
			}

			if (id) {
				const fileList = this.value.filter((file) => {
					return file.id !== id;
				});

				this.$emit('input', fileList);
			}
		},
		getProgressLabel({ state, errors }) {
			if (errors.length) {
				return errors.join(', ');
			}

			const labels = {
				notStarted: this.notStartedState,
				queued: this.queuedState,
				uploading: this.uploadingState,
				uploaded: this.uploadedState
			};

			return labels[state];
		}
	}
};
</script>
<style src="./control-file-table.less" scoped></style>
<style src="@/Components/Control/Table/control-table-view.less" scoped></style>
<style src="@/Components/Control/Table/control-table-view-row.less" scoped></style>
