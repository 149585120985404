<template>
	<div class="value">
		<span class="title p4">{{ title }}</span>
		<control-lookup-editor-with-custom-value v-model="value"
												 :options="getLookupOptions()"></control-lookup-editor-with-custom-value>
	</div>
</template>

<script>
const ControlLookupEditorWithCustomValue = () => import(/* webpackChunkName: 'deffered' */ /* webpackPrefetch: true */ '@/Components/Control/Lookup/control-lookup-editor-with-custom-value');
export default {
	name: 'value',
	props: {
		title: String,
		currentValue: String,
		metaFields: Array
	},
	components: { ControlLookupEditorWithCustomValue },
	data() {
		return { value: '' };
	},
	watch: {
		currentValue: {
			handler(newVal, oldVal) {
				if (newVal) {
					const field = this.metaFields.find(field => field.data.logicalName.toLowerCase() === newVal.toLowerCase());
					this.value = field || { displayName: newVal };
				} else {
					this.value = null;
				}
			},
			immediate: true
		}
	},
	methods: {
		onChange(value) {
			this.$emit('onChange', this.title, value && value.displayName);
		},
		getLookupOptions() {
			return {
				placeholder: '',
				pageSize: 100,
				fetchData: (searchQuery, startFrom = 0, pageSize = 100) => {
					const filtered = this.metaFields.filter(f => {
						return f.displayName.toLowerCase().includes(searchQuery.toLowerCase());
					});

					return {
						total: filtered.length,
						items: filtered.slice(startFrom, pageSize + startFrom)
					};
				},
				singleSelectionOnArray: this.onChange,
				template: {
					$type: 'entityTemplate.fieldGroup',
					columns: 1,
					control: [
						{
							$type: 'control.label',
							wrap: 'noWrap',
							cssClass:
								{
									$type: 'expression.text',
									value: 'p2'
								},
							text:
								{
									$type: 'expression.field',
									name: 'DisplayName',
									schema: 'root'
								},
							colspan: 1
						},
						{
							$type: 'control.label',
							wrap: 'noWrap',
							text:
								{
									$type: 'expression.concat',
									argument: [
										{
											$type: 'expression.text',
											value: '('
										},
										{
											$type: 'expression.field',
											name: 'LogicalName',
											schema: 'root'
										},
										{
											$type: 'expression.text',
											value: ')'
										}]
								},
							colspan: 1
						}],
					colspan: 1
				}
			};
		}
	}

};
</script>
<style src="./value.less" scoped></style>
