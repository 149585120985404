import coDataProvider from '@/Data/DataProviders/coDataprovider';

const getTemplatesMixin = {
	data: () => ({
		filtersSelected: true
	}),
	methods: {
		async search() {
			/* if no filters - show 'no results' message */
			this.filtersSelected = this.filters.filter(filter => filter.selected).length;
			this.isLoading = true;
			this.searching = true;
			let searchCOCriteria = {
				limit: 100,
				offset: 0,
				mainEntitiesFilter: {
					exactMatch: false,
					values: [
						...this.filters.reduce((a, i) => { return i.selected ? [...a, i.name] : a; }, [])
					]
				},
				templateTypeFilter: {
					exactMatch: true,
					values: ['email']
				}
			};
			if (this.customSearch) {
				searchCOCriteria = {
					...searchCOCriteria,
					nameFilter: { value: this.customSearch } /* get and adding query for template search */
				};
			}
			new Promise(async (resolve, reject) => {
				const coResults = await coDataProvider.searchTemplates(searchCOCriteria, true);
				const filteredTemplates = [...coResults.htmlTemplates];
				// filters when template already selected
				const collecctionTypes = searchCOCriteria.mainEntitiesFilter.values;
				const isTemplateSelected = (!!this.getTemplates && this.getTemplates.length && this.getTemplates.find(i => i.selected)) || null;
				if (isTemplateSelected && !isTemplateSelected.dummyTemplate) {
					if (collecctionTypes.length && !collecctionTypes.some(i => i === isTemplateSelected.inputTypes)) {
						filteredTemplates.push(isTemplateSelected)
					} else if (searchCOCriteria.nameFilter && !!searchCOCriteria.nameFilter.value && !filteredTemplates.some(i => i.templateId === isTemplateSelected.templateId)) {
						filteredTemplates.push(isTemplateSelected)
					}
				}
				this.updateTemplates && this.updateTemplates(filteredTemplates.sort((a, b) => a.templateName < b.templateName ? -1 : (a.templateName > b.templateName ? 1 : 0)));
				resolve([...coResults.htmlTemplates]);
			}).then(res => {
				this.templates = res.sort((a, b) => a.templateName < b.templateName ? -1 : (a.templateName > b.templateName ? 1 : 0));
			}).finally(() => { 
				this.isLoading = false;
				setTimeout(() => {
					this.searching = false;
				}, 1500)
			});
		},
		executeSearch(searchQuery) {
			this.customSearch = searchQuery;
			!this.searching && this.search();
		}
	}
};

export default getTemplatesMixin;
