<template>
	<label class="custom-checkbox mobile tooltip" :class="isAnonymous && 'disabled'">
		<input ref="input" type="checkbox" v-model="selected" />
		<span class="value">
			<span class="value-checked">
				<span class="value-title"></span>
				<svg class='svg-icon svg-15'>
					<use xlink:href='#layout-icons-bool-editor-on' class='boolean-icon layout-icons-bool-editor-on'></use>
				</svg>
			</span>
			<span class="value-unchecked">
				<span class="value-title"></span>
				<svg class='svg-icon svg-15'>
					<use xlink:href='#layout-icons-bool-editor-off' class='boolean-icon layout-icons-bool-editor-off'></use>
				</svg>
			</span>
		</span>
	</label>
</template>
<script>
/* eslint vue/no-side-effects-in-computed-properties: 0 */
import FilterMixins from './../filter.mixins';
import helperMethods from './../../ComponentSet/component-set-evaluation-context-helper.methods';
export default {
	name: 'bool-inline-view',
	mixins: [FilterMixins],
	data() {
		return {
			applied: false,
			isAnonymous: false
		};
	},
	props: {
		options: Object,
		evaluationContext: Object,
		stateNamespace: String
	},
	computed: {
		selected: {
			get() {
				const value = this.$store.getters[this.stateNamespace + '/getFilterValue']({
					entity: this.entity,
					logicalName: this.logicalName
				});
				this.applied = !!value;
				return !!value;
			},
			async set(value) {
				if (!this.isAnonymous) {
					this.applied = value;
					const filter = (value) ? await this.getSearchFilter(this.logicalName) : null;
					this.emitFilterChanged(filter);
				} else {
					this.showWarningDialogue();
				}
			}
		}
	},
	async created() {
		this.logicalName = helperMethods.eval(this.evaluationContext, this.options.logicalName);
		this.isAnonymous = await helperMethods.evalAsync(this.evaluationContext, this.options.isAnonymous);
		this.localizationProvider = sc.classes.get('localization.dataProvider');
		this.entity = this.options.entities[0];
		this.$root.$on('applyFavoritesFilter', () => { !this.applied && this.$refs.input.click(); });
	},
	methods: {
		async showWarningDialogue() {
			sc.events.emit('dialog.info',
				{
					title: await this.localizationProvider.localize('common.dictionary.warning'),
					message: await this.localizationProvider.localize('common.dictionary.registerPlease')
				});
		}
	}
};
</script>
<style src="./bool-inline-view.less" scoped></style>
<style src="./favorites-inline-view.less" scoped></style>
