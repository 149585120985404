<template>
	<div class="progress-dialog-wrapper" v-if="options.params.progress.total > 0">
		<div class="progress-dialog">
			<div class="main">
				<span class="primary-label p14">{{progressMessage}}</span>
				<span class="label">{{progressPercentage}}</span>
				<div class="content">
					<div :class="['progress-bar-container', { failed: isOperationFailed }]" :style="{ width: progressPercentage }"></div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
const FAILURE_EVENT = 'operationFailed';
export default {
	name: 'progress-dialog',
	props: { options: Object },
	data() {
		return {
			isOperationFailed: false,
			progressMessage: this.options.params.progress.message
		};
	},
	created() {
		this.$root.$on(FAILURE_EVENT, (failureMessage) => {
			this.isOperationFailed = true;
			this.progressMessage = failureMessage;
		});
	},
	methods: {
		close() {
			this.$parent.$emit('close');
		}
	},
	computed: {
		progressPercentage() {
			const progress = this.options.params.progress;
			const percentage = parseInt(progress.processed / progress.total * 100);

			if (percentage === 100) {
				this.close();
			}

			return percentage + '%';
		}
	}
};
</script>
<style src="./progress-dialog.less" scoped></style>
