var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "deactivate-dialog" }, [
    _c("div", { staticClass: "body" }, [
      _c("span", { staticClass: "p4" }, [_vm._v(_vm._s(_vm.localizedMessage))])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "entity-list" },
      [
        _vm.documentRelatedRecordsTemplate
          ? _c("control", {
              staticClass: "formcontrol-template",
              attrs: {
                name: _vm.documentRelatedRecordsTemplate.$type,
                contentProps: _vm.documentRelatedRecordsTemplate,
                initResults: _vm.results
              }
            })
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c("button", {
        directives: [
          {
            name: "localization",
            rawName: "v-localization",
            value: { key: "common.dictionary.buttons.cancel" },
            expression: "{ key: 'common.dictionary.buttons.cancel'}"
          },
          {
            name: "data-attr",
            rawName: "v-data-attr",
            value: { title: _vm.title, $type: "dialog.button.submit" },
            expression: "{title, $type: 'dialog.button.submit'}"
          }
        ],
        staticClass: "cancel",
        on: { click: _vm.onCancel }
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          directives: [
            {
              name: "data-attr",
              rawName: "v-data-attr",
              value: { title: _vm.title, $type: "dialog.button.cancel" },
              expression: "{title , $type: 'dialog.button.cancel'}"
            }
          ],
          attrs: { type: "button" },
          on: { click: _vm.onSubmit }
        },
        [_vm._v(_vm._s(_vm.localizedOkBtn))]
      )
    ]),
    _vm._v(" "),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ],
      staticClass: "loading-overlay"
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }