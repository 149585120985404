<template>
	<div class="custom-select">
		<div class="region-wrapper popuparea">
			<div class="popup-holder striped">
				<div class="items-wrapper"
					 @scrollToBottom="onScrollToBottom"
					 ref="scrollArea"
					 v-perfectscroll="{enable: true, refreshScroll: false}"
					 v-if="options.data.items && options.data.items.length">
					<div class="items">
						<!-- we need compare displayName because of optionsets-->
						<div class="clickable suggestion" :key="item.data && item.data.Id" v-for="item in options.data.items" @click="selectItem(item)" v-bind:class="{'selected':options.value && options.value.displayName===item.displayName}">
							<div v-if="!options.template" class="column">{{item.displayName}}</div>
							<control v-else-if="options.template"
									 :name="options.template.$type"
									 v-bind:contentProps.once="options.template"
									 :evaluationContext="getContext(item.data)"></control>
						</div>
					</div>
				</div>
				<div class="items-footer">
					<div class="info">
						<div v-show="!options.isLoading" v-if="!options.hideFooter">
							<span v-if="!options.data.items || options.data.items.length === 0" v-localization="{ key: 'controls.lookup.noResultsFound'}"></span>
							<span v-else-if="options.data.items && options.data.items.length > 0">
								<span v-localization="{ key: 'controls.lookup.loaded'}"></span>
								<span class="counter">{{options.data.items.length}}</span>
								<span v-localization="{ key: 'controls.lookup.of'}"></span>
								<span class="counter">{{options.data.total || options.data.items.length}}</span>
							</span>
						</div>
						<div v-show="options.isLoading">
							<span v-localization="{ key: 'controls.lookup.loading'}"></span>
						</div>
					</div>
					<div class="loading-overlay inline" v-show="options.isLoading"></div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import LookupSuggestion from './lookup-suggestion.mixin';
const Control = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '../../Entity/control.vue');
export default {
	name: 'control-lookup-suggestion',
	mixins: [
		LookupSuggestion
	],
	components: { Control },
	data() {
		return {};
	},
	methods: {
		onScrollToBottom() {
			!this.options.isLoading && this.options.onScrollToBottom && this.options.onScrollToBottom();
		},
		selectItem(item) {
			this.options.onSelect && this.options.onSelect(item);
		}
	}
};
</script>
<style src="./control-lookup-suggestion.less" scoped></style>
