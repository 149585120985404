<template>
  <component :is='galleryDialogType' :options="options" :dialogId="dialogId" @closeDialog="$emit('closeDialog')"
    @removeClose="$emit('removeClose')" />
</template>
<script>
import { GetUserInfo } from '@/Data/Auth/authentication-service';
import imageGalleryDefaultDialog from './imageGalleryDefaultDialog.vue';
import imageGalleryDDDialog from './imageGalleryDDDialog.vue';

export default {
	name: 'image-gallery-dialog',
	props: {
		options: Object,
		dialogId: String
	},
	components: {
		// eslint-disable-next-line vue/no-unused-components
		imageGalleryDefaultDialog,
		imageGalleryDDDialog
	},
	data() {
		return { userInfo: null };
	},
	async created() {
		this.userInfo = await GetUserInfo();
	},
	computed: {
		galleryDialogType() {
			let isBroker = null;
			if (this.userInfo) {
				isBroker = this.userInfo.CRMRoles.some(role => role.Name === 'Broker');
			}
			return this.$route.params && this.$route.name === 'details' && (this.$route.params.type === 'listing' || this.$route.params.type === 'availability') && isBroker && this.$route.params.config === 'MarketSpace'
				? 'imageGalleryDDDialog'
				: 'imageGalleryDefaultDialog';
		}
	}
};
</script>
