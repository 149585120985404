var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ms-add-to-list" },
    [
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading,
            expression: "isLoading"
          }
        ],
        staticClass: "loading-overlay large"
      }),
      _vm._v(" "),
      _c(
        "component-search-results",
        { attrs: { options: _vm.options.params.componentOptions } },
        [
          _c("reactive-action-menu", {
            attrs: { slot: "reactive-menu", options: _vm.actionMenuComponent },
            nativeOn: {
              click: function($event) {
                return _vm.cancel($event)
              }
            },
            slot: "reactive-menu"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "footer-wrapper" }, [
        _c("div", { staticClass: "footer" }, [
          _c("span", { staticClass: "buttons" }, [
            _c("button", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: { key: "dialogues.addToList.addToListBtn" },
                  expression: "{key: 'dialogues.addToList.addToListBtn'}"
                },
                {
                  name: "data-attr",
                  rawName: "v-data-attr",
                  value: {
                    title: _vm.options.title,
                    $type: "dialog.button.submit"
                  },
                  expression:
                    "{title: options.title, $type: 'dialog.button.submit'}"
                }
              ],
              attrs: { type: "button" },
              on: { click: _vm.update }
            }),
            _vm._v(" "),
            _c("button", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: { key: "dialogues.addToList.cancelBtn" },
                  expression: "{key: 'dialogues.addToList.cancelBtn'}"
                },
                {
                  name: "data-attr",
                  rawName: "v-data-attr",
                  value: {
                    title: _vm.options.title,
                    $type: "dialog.button.cancel"
                  },
                  expression:
                    "{title: options.title, $type: 'dialog.button.cancel'}"
                }
              ],
              staticClass: "cancel",
              attrs: { type: "button" },
              on: { click: _vm.cancel }
            })
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }