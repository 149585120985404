<template>
	<div class="dialog-content send-test-dialog">
		<div v-show="isLoading" class="loading-overlay" />
		<div class="lookup-wrapper">
			<ControlLookupWrapper
				:lookupOptions="getLookupOptions()"
				:buttonOptions="buttonOptions"
				:validationOptions="validationOptions"
				:cssClasses="cssClasses"
			/>
		</div>
		<div class="table-header mci-send-test-info">
			<div class="info-text">Add up to {{ (maxRecipients - contacts.length) > 0 ? maxRecipients - contacts.length : 0 }} recipients</div>
			<InfoBadge class="info-icon" :tooltipText=getQuantityInfo() :direction="'bottom'" :fitIntoContainerClass="'send-test-dialog'" :fitInPostition="'center'" />
		</div>
		<transition name="fade">
			<div v-if="error" class="error">
				{{ error }}
				<svg class="svg-icon svg-15" @click="setError('')">
					<use xlink:href="#action-icons-close-btn"></use>
				</svg>
			</div>
		</transition>
		<div v-perfectscroll="{ enable: true }" class="table-wrapper">
			<Control
				v-if="tableTemplate"
				v-perfectscroll="{ enable: true }"
				:name="tableTemplate.$type"
				:contentProps="tableTemplate"
				:initResults="contacts"
			/>
		</div>
		<div class="footer">
			<button
				class="cancel"
				type="button"
				@click="onCancel"
			>
				{{ options.cancelLabel }}
			</button>
			<button
				type="button"
				:disabled="!contacts.length"
				@click="onSubmit"
			>
				{{ options.submitLabel }}
			</button>
		</div>
	</div>
</template>

<script>

import {
	RECORD_STATE, Search, generateGuid
} from '@acx-xms/data-functions/dist';
import Control from '@/Components/Entity/control';
import { InfoBadge } from '@acx-xms/components/dist';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';
import { mapState } from 'vuex';

const ControlLookupWrapper = () => import(/* webpackChunkName: 'deffered' */ /* webpackPrefetch: true */ '@/Components/Control/Lookup/control-lookup-wrapper');

const VALIDATION_PATTERN = /^[a-zA-Z0-9!#$%&\/\\'*+=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&\/\\'*+=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-zA-Z0-9](?:[a-z0-9-]*[a-z0-9])?$/m;
const ACCOUNTS = {
	FREE: {
		PER_DAY: 24,
		PER_CAMPAIGN_PER_DAY: 12,
		MAX_RECIPIENTS: 6,
		NAME: 'forever_free'
	},
	PAID: {
		PER_DAY: 200,
		PER_CAMPAIGN_PER_DAY: 70,
		MAX_RECIPIENTS: 20
	}
};
const ERRORS = { TOO_MUCH_CONTACT: 'The maximum number of available test emails for one-time sending is reached. Please, edit the number of recipients.' };

export default {
	name: 'send-test-email-dialog',
	components: {
		Control,
		ControlLookupWrapper,
		InfoBadge
	},
	props: { options: Object },
	data() {
		return {
			isLoading: true,
			evaluationContext: createEvaluationContext(),
			tableTemplate: null,
			contacts: [],
			validationOptions: {
				message: 'Invalid email adress',
				isValid: true
			},
			buttonOptions: {
				onSubmit: this.onAddToList,
				label: 'Add To List',
				isDisabled: false
			},
			cssClasses: {},
			maxRecipients: null,
			isValid: true,
			error: '',
			plan: null,
			quantityInfo: ''
		};
	},
	computed: { ...mapState('wizard', ['steps', 'campaign']) },
	async created() {
		this.fork = sc.events.fork();
		this.fork.on('sendTestEmail.deleteRecipient', (id) => {
			this.contacts = this.contacts.filter((contact) => contact.Id !== id);
		});
		this.$root.$on('inputCleared', () => {
			this.validationOptions.isValid = true;
			this.cssClasses.invalid = false;
		});

		this.configProvider = sc.classes.get('entityConfiguration.dataProvider');
		this.tableTemplate = await this.configProvider.getTemplate(
			this.options.templateEntity,
			this.options.tableTemplate.usage,
			this.options.tableTemplate.subtype
		);

		this.hasFreePlan = this.steps.find((step) => step.name === 'setup').mcUser.plan === ACCOUNTS.FREE.NAME;
		this.hasFreePlan
			? (this.maxRecipients = ACCOUNTS.FREE.MAX_RECIPIENTS)
			: (this.maxRecipients = ACCOUNTS.PAID.MAX_RECIPIENTS);

		this.isLoading = false;
		this.plan = this.hasFreePlan ? ACCOUNTS.FREE : ACCOUNTS.PAID;
	},
	methods: {
		async onSubmit() {
			if (!this.contacts.length) return;
			if (this.contacts.length > this.plan.MAX_RECIPIENTS) {
				this.setError(ERRORS.TOO_MUCH_CONTACT);
				return;
			};
			/* clear error */
			this.setError();
			/* send campaign */
			this.$store.dispatch('wizard/createCampaignForTest', {
				testEmails: this.contacts.map(el => el.Source.email),
				id: this.campaign.edgeId
			});
			/* close popup */
			this.onCancel();
		},
		onCancel() {
			this.$parent.$emit('close');
		},
		showInfoPopup(message) {
			this.$root.$emit('vue.dialog.open', {
				title: 'Test emails cannot be sent',
				component: 'confirm.dialog',
				maximizedWidth: '450px',
				maximizedHeight: 'auto',
				message,
				cancelLabel: 'Cancel'
			});
		},
		getLookupOptions() {
			return {
				placeholder: 'Search for contacts or enter email address',
				fetchData: async (searchQuery, startFrom, pageSize) => {
					startFrom = startFrom || 0;
					pageSize = pageSize || 1000;
					const filters = [
						sc.classes.get('offsetFrom.filter', startFrom),
						sc.classes.get('offsetSize.filter', pageSize)
					];
					// set selections fields
					filters.push(
						...this.options.lookupFilters.map(filters => sc.classes.get(filters.$type, filters, this.evaluationContext).toFilter()),
						sc.classes.get('termFacet.filter', {
							logicalName: 'recordstate.id',
							query: [RECORD_STATE.ACTIVE]
						}).fillQuery(),
						sc.classes.get('termFacet.filter', {
							logicalName: 'contactrecordstatelookuprecordstate.name',
							query: ['Active']
						}).fillQuery(),
						sc.classes.get('termFacet.filter', {
							logicalName: 'serviceuser',
							query: ['true'],
							negative: true
						}).fillQuery()
					);
					if (searchQuery) {
						filters.push(
							sc.classes.get('field.filter', {
								logicalName: '_all',
								query: searchQuery
							})
						);
					}

					const { Results, Total } = await Search(this.options.templateEntity, filters);

					return {
						total: Total,
						items: Results.map((res) => ({
							displayName: res.Name,
							data: res
						}))
					};
				},
				singleSelectionOnArray: this.onSuggestionClick,
				template: this.options.lookupTemplate,
				withCrossIcon: true
			};
		},
		onSuggestionClick(item) {
			if (this.contacts.length >= this.plan.MAX_RECIPIENTS) {
				this.setError(ERRORS.TOO_MUCH_CONTACT);
				return;
			};
			item.data &&
				!this.contacts.find((c) => c.Id === item.data.Id) &&
				this.contacts.length !== this.maxRecipients &&
				this.contacts.push(item.data);
		},
		onAddToList(query, searchResults) {
			if (this.contacts.length >= this.plan.MAX_RECIPIENTS) {
				this.setError(ERRORS.TOO_MUCH_CONTACT);
				return;
			};
			const alreadyFoundContact = searchResults.find(({ data }) => data.Source.email === query);
			const foundContactData = alreadyFoundContact && alreadyFoundContact.data;
			const hasContactInList = this.contacts.find(contact => (contact !== void 0 && contact.Id) === (foundContactData !== void 0 && foundContactData.Id) ||
				contact.Source.email === (foundContactData && foundContactData.Source.email) ||
				contact.Source.email === query
			);

			if (!hasContactInList) {
				this.buttonOptions.isDisabled = true;
				setTimeout(() => {
					this.buttonOptions.isDisabled = false;
				}, 0);
				if (alreadyFoundContact) this.contacts.push(alreadyFoundContact.data);
				else {
					if (VALIDATION_PATTERN.test(query)) {
						this.contacts.push({
							Source: { email: query },
							Id: generateGuid()
						});
					} else {
						this.cssClasses = {
							...this.cssClasses,
							invalid: true
						};
						this.validationOptions.isValid = false;
						return;
					}
				}
			}

			this.cssClasses = { invalid: false };
			this.validationOptions.isValid = true;
		},
		setError(error) {
			this.error = error;
		},
		getQuantityInfo() {
			return '<div class="quantity-popup">' +
						'<div class="info info-1">The number of available recipients is determined based on the Mailchimp limits for sending test emails. The specific limits are displayed in the table:</div>' +
						'<div class="table">' +
							'<div class="header">' +
								'<div class="row">' +
									'<div class="cell cell-1">Plan</div>' +
									'<div class="cell cell-2">Test emails per campaign</div>' +
									'<div class="cell cell-3">Test emails per 24 hours</div>' +
									'<div class="cell cell-4">Addresses per test</div>' +
								'</div>' +
							'</div>' +
							'<div class="body">' +
								'<div class="row">' +
									'<div class="cell cell-1">Free</div>' +
									'<div class="cell cell-2">12</div>' +
									'<div class="cell cell-3">24</div>' +
									'<div class="cell cell-4">6</div>' +
								'</div>' +
								'<div class="row">' +
									'<div class="cell cell-1">Paid</div>' +
									'<div class="cell cell-2">70</div>' +
									'<div class="cell cell-3">200</div>' +
									'<div class="cell cell-4">20</div>' +
								'</div>' +
							'</div>' +
						'</div>' +
						'<div class="info info-2">When you include multiple addresses in a test, each one counts toward your total test sends for that campaign and the 24-hour period. So, if you send one test to four addresses, it counts as four test emails toward the campaign limit.</div>' +
					'</div>';
		}
	},
	beforeDestroy() {
		this.fork.dispose();
	}
};
</script>

<style src='./sendTestDialog.less' scoped></style>
<style src='./quantity-popup.less'></style>
