var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content tab-details" }, [
    _c(
      "div",
      {
        class: {
          "with-message": _vm.deactivatedIndicator.visible,
          header: true
        }
      },
      [
        _vm.breadcrumbs
          ? _c("breadcrumbs", { attrs: { breadcrumbs: _vm.breadcrumbs } })
          : _vm._e(),
        _vm._v(" "),
        _vm.actionMenuComponent && _vm.data && !_vm.isLoading && !_vm.error
          ? _c("action-menu", {
              staticClass: "without-padding without-border",
              attrs: {
                options: _vm.actionMenuComponent,
                record: _vm.data,
                evaluationContext: _vm.evaluationContext
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.deactivatedIndicator.visible && !_vm.isLoading && !_vm.error
          ? _c("div", { class: _vm.deactivatedIndicator.cssClass }, [
              _vm.deactivatedIndicator.icon
                ? _c("svg", { staticClass: "svg-icon svg-20" }, [
                    _c("use", {
                      attrs: {
                        "xlink:href": "#" + _vm.deactivatedIndicator.icon
                      }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.deactivatedIndicator.text) +
                    "\n\t\t\t"
                )
              ])
            ])
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    !_vm.error
      ? _c(
          "div",
          {
            directives: [
              {
                name: "perfectscroll",
                rawName: "v-perfectscroll",
                value: {
                  enable: true,
                  onScroll: _vm.onScroll,
                  scrollToTopEvent: _vm.scrollToTopEvent
                },
                expression:
                  "{enable: true, onScroll: onScroll, scrollToTopEvent}"
              }
            ],
            staticClass: "tabs content-scroll-wrapper",
            style: { visibility: _vm.isLoading ? "hidden" : "visible" }
          },
          [
            _c(
              "div",
              {
                directives: [
                  { name: "scrollable-tabs", rawName: "v-scrollable-tabs" }
                ],
                staticClass: "tab-switcher h2"
              },
              [
                _c("div", { staticClass: "tab-arrow prev" }, [
                  _c("svg", { staticClass: "svg-icon svg-15" }, [
                    _c("use", {
                      attrs: { "xlink:href": "#layout-icons-ms-arrow-left" }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "tabs-container" },
                  _vm._l(_vm.tabs, function(tab) {
                    return _c(
                      "div",
                      {
                        key: tab.$key,
                        class: {
                          active: _vm.currentTabKey === tab.$key,
                          "tab-title": true
                        },
                        on: {
                          click: function($event) {
                            _vm.currentTabKey = tab.$key
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t" + _vm._s(tab.title) + "\n\t\t\t\t"
                        )
                      ]
                    )
                  })
                ),
                _vm._v(" "),
                _c("div", { staticClass: "tab-arrow next" }, [
                  _c("svg", { staticClass: "svg-icon svg-15" }, [
                    _c("use", {
                      attrs: { "xlink:href": "#layout-icons-ms-arrow-right" }
                    })
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _vm._l(_vm.tabs, function(tab) {
              return !_vm.isLoading && tab.$key === _vm.currentTabKey
                ? _c(
                    "div",
                    {
                      key: tab.$key,
                      staticClass: "tab-content",
                      class: { "fixed-header": tab.fixedHeader }
                    },
                    _vm._l(tab.rows, function(row) {
                      return _c("DetailsRowControl", {
                        key: row.$key,
                        style: { height: row.height },
                        attrs: {
                          evaluationContext: _vm.evaluationContext,
                          row: row
                        }
                      })
                    })
                  )
                : _vm._e()
            })
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.isLoading && _vm.error
      ? _c("div", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "common.dictionary.recordDetails.noAccess" },
              expression: "{key: 'common.dictionary.recordDetails.noAccess'}"
            }
          ],
          staticClass: "no-data"
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.isLoading
      ? _c("div", { staticClass: "loading-wrapper" }, [
          _c("div", { staticClass: "loading-overlay" })
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }