<template>
	<div class="custom-select">
		<div class="region-wrapper popuparea">
			<div class="popup-holder striped">
				<div class="items-wrapper"
					 ref="scrollArea"
					 v-perfectscroll="{enable: true, refreshScroll: false}"
					 v-if="options.data.items">
					<folder-tree :foldersSr="getFoldersSr(options.data.items)"
								 :rootName="options.rootName"
								 :treeLogicalName="options.treeLogicalName"
								 :predefinedId="options.selectedItem && options.selectedItem.data.id"
								 @treeClicked="selectItem">
					</folder-tree>
				</div>
				<div class="items-footer" v-show="options.isLoading">
					<div class="loading-overlay inline"></div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import LookupSuggestion from './lookup-suggestion.mixin';
const FolderTree = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/FolderTree/folder-tree');
export default {
	name: 'control-lookup-folder-suggestion',
	mixins: [
		LookupSuggestion
	],
	components: { FolderTree },
	data() {
		return { foldersSr: [] };
	},
	methods: {
		getFoldersSr(items) {
			return items.map(item => item.data.Source);
		},
		selectItem: function (item) {
			const id = item.data.folderId;
			const filteredItem = this.options.data.items.find(el => el.data.Id === id);
			this.$emit('setActive', item.data.folderId);
			this.options.onSelect && this.options.onSelect(filteredItem);
		}
	}
};
</script>
<style src="./control-lookup-suggestion.less" scoped></style>
