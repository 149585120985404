import helperMethods from './../../../ComponentSet/component-set-evaluation-context-helper.methods';
import FilterMixins from './../../filter.mixins';
export default {
	mixins: [FilterMixins],

	methods: {
		searchAdditionalItems: function (fieldFilter, additionalFilters) {
			const self = this;
			const selectedFilter = this.getSelectedFilter();
			if (!additionalFilters) selectedFilter.data.Size = 99999;
			const filters = this.$store.getters[this.stateNamespace + '/getFilters'](this.entity);
			const searchQueryFilter = this.$store.getters[this.stateNamespace + '/getSearchQueryFilter'];

			searchQueryFilter && filters.push(searchQueryFilter);
			filters.push(selectedFilter);
			filters.push(sc.classes.get('selectedFields.filter', []));
			fieldFilter && filters.push(fieldFilter);
			additionalFilters && filters.push(...additionalFilters);

			return sc.classes.get('edge.dataProvider').search({
				entities: this.options.entities,
				filters,
				relation: false
			}).then(function (data) {
				const path = helperMethods.eval(self.evaluationContext, self.options.collectionName);

				return data.TermFacets && data.TermFacets.find(t => {
					return t.LogicalName === path ? path + '.' + self.logicalName : self.logicalName;
				});
			});
		},
		onSelectAdditionalItem: function (item) {
			const facet = this.facets && this.facets.find(facet => {
				return facet.value === item.formFacet.value;
			});

			if (facet) {
				facet.checked = true;
			} else {
				this.statistic = this.addTermFacetStatistic(this.statistic, item.data);
				this.$store.commit(this.stateNamespace + '/setStatistic', {
					entity: this.logicalName,
					statistic: this.statistic
				});

				this.facets = this.facets.concat(item.formFacet);
			}
		}
	}
};
