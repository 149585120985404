<template>
	<div class="realte-to-contact-in-crm-dialog">
		<div class="loading-overlay large" v-show="isLoading"></div>
		<div class="body">
			<div class="edit" v-if="!isLoading">
				<div class="p13" v-localization="{key: 'realteToContactInCrmDialog.lookupText'}"></div>
				<div class="realte-to-contact-in-crm">
					<control-lookup-editor	v-model="value"
											:showRequired="true"
											:class="{invalid: !isValid}"
											:options="getLookupOptions()"></control-lookup-editor>
				</div>
			</div>
		</div>
		<div class='footer'>
			<span class='buttons'>
				<button @click="save" type="button" v-localization="{key: 'common.dictionary.buttons.save'}"></button>
				<button @click="close" type="button" class="cancel" v-localization="{key: 'common.dictionary.buttons.cancel'}"></button>
			</span>
		</div>
	</div>
</template>
<script>
import crmSyncDataProvider from '@/Data/DataProviders/crmSyncDataProvider.js';
import { showToastOrInfoDialog, Update } from '@acx-xms/data-functions/dist';
const ControlLookupEditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Lookup/control-lookup-editor');

export default {
	name: 'realte-to-contact-in-crm-dialog',
	props: { options: Object },
	data() {
		return {
			isLoading: true,
			value: null,
			isValid: true
		};
	},
	components: { ControlLookupEditor },
	async created() {
		switch (this.options.crm) {
		case 'sf': {
			this.edgeToCrmFieldMapping = {
				firstname: 'FirstName',
				lastname: 'LastName',
				jobtitle: 'Title',
				account: 'Account.Name',
				Id: 'Id',
				email: 'Email'
			};
			this.crmEmailField = 'Email';
			this.crmEntity = 'Contact';
			break;
		}
		default:
			throw new Error(`${this.options.crm} CRM is not supported`);
		}

		this.localization = sc.classes.get('localization.dataProvider');
		this.placeholder = await this.localization.getLabelForCurrentLanguage('realteToContactInCrmDialog.placeholder');
		this.crmId = this.options.contact.Source.sfcrmid;
		crmSyncDataProvider.pluginName = 'sync';

		const entityConfigDataProvider = sc.classes.get('entityConfiguration.dataProvider');
		this.userLookupTemplate = await entityConfigDataProvider
			.getTemplate('contact', 'custom', 'crm-contact-list');

		try {
			this.contacts = await this.searchContacts();
			if (this.crmId && this.crmId.length && this.contacts && this.contacts.length) {
				this.value = this.contacts.find(x => {
					return x.data.Id === this.crmId;
				});
			}
		} catch (e) {
			sc.utils.errorMessage.byResponse(e);
		} finally {
			this.isLoading = false;
		}
	},
	methods: {
		async searchContacts() {
			const searchRequest = {
				entity: this.crmEntity,
				fields: Object.values(this.edgeToCrmFieldMapping),
				criteria: {
					conditions: [
						{
							field: this.crmEmailField,
							operator: 'eq',
							value: this.options.contact.Source.email
						},
						{
							field: 'IsDeleted',
							operator: 'eq',
							value: false
						}
					]
				}
			};

			const crmContacts = await crmSyncDataProvider.proxy('api/sf/search', searchRequest);
			return this.perpareContacts(crmContacts);
		},
		getLookupOptions() {
			return {
				isRequired: true,
				placeholder: this.placeholder,
				fetchData: searchQuery => {
					let filtered = this.contacts;

					if (searchQuery && searchQuery.length) {
						filtered = filtered.filter(c => {
							return Object.keys(this.edgeToCrmFieldMapping).some(f => {
								const fieldValue = c.data.Source[f];
								return fieldValue && fieldValue.length && fieldValue.indexOf(searchQuery) > -1;
							});
						});
					}

					return {
						total: filtered.length,
						items: filtered
					};
				},
				singleSelectionOnArray: this.onChange,
				template: this.userLookupTemplate
			};
		},
		perpareContacts(sfContacts) {
			return sfContacts.map(crmContact => {
				const isSelected = crmContact.Id === this.crmId;

				return {

					displayName: `${crmContact.FirstName} ${crmContact.LastName}`,
					data: {
						Id: crmContact.Id,
						Source: {
							firstname: crmContact.FirstName,
							lastname: crmContact.LastName,
							account: crmContact.Account ? crmContact.Account.Name : null,
							jobtitle: crmContact.Title,
							isSelected,
							email: crmContact.Email
						}
					}
				};
			});
		},
		async save() {
			if (this.value) {
				this.isLoading = true;

				try {
					await Update('contact', this.options.contact.Id, { sfcrmid: this.value.data.Id });
					await crmSyncDataProvider.startSync({
						crmIds: [this.value.data.Id],
						crmLogicalName: this.crmEntity,
						force: ['contact']
					});
				} catch (e) {
					sc.utils.errorMessage.byResponse(e);
				} finally {
					this.isLoading = false;

					if (this.options.refreshEvent) {
						this.$root.$emit(this.options.refreshEvent);
					}
					await showToastOrInfoDialog({
						toastMessageKey: this.options.toastMessageKey,
						informationDialogTextKey: this.options.informationDialogTextKey,
						options: { context: this }
					});
					this.close();
				}
			} else {
				this.isValid = false;
				sc.events.emit('dialog.error', {
					title: this.localization.localize('entity.editor.validationError'),
					message: ` - Contact: ${await this.localization.getLabelForCurrentLanguage('common.dictionary.validators.requiredField')}`
				});
			}
		},
		close() {
			this.$parent.$emit('close');
		}
	}
};
</script>
<style src="./realte-to-contact-in-crm-dialog.less" scoped></style>
