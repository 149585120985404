<template>
	<div class="step">
		<Loader
			v-if="templateProcess"
			height="100%"
			text="The publication with chosen data is being generated for the future preview. This process can take some time and no changes can be made with a template until it ends. Please, wait a while."
		/>
		<div class="wizard-step-header">
			<div class="wizard-step-header-title">
				<div class="wizard-step-header-icon">
					<div :class="['filter-button active', { open: isPanelOpen }]" @click="isPanelOpen = !isPanelOpen">
						<svg class="svg-icon">
							<use class="ms-filter-icon" xlink:href="#layout-icons-ms-filter-icon"></use>
						</svg>
					</div>
				</div>
				Select Template
			</div>
			<div class="wizard-step-header-search">
				<SearchField :options="{ debouncedWatchSearchQuery: { delay: 1500 }}" />
				<button
					class="secondary"
					v-localization="{ key: 'dialogues.RS4.dialog.manage' }"
					@click="openEmailTemplates"
				>
				</button>
			</div>
		</div>
		<div class='email-campaign-step-root select-template'>
			<div class='select-template-wrapper'>
				<div class='filters' :class='{hidden : !isPanelOpen}'>
					<div class='sections'>
						<div class='section'>
							<div class='filter-header' @click='isFiltersOpen = !isFiltersOpen'>
								<span class='caption'>Input Type</span>
								<span class='icon'>
									<svg class='svg-10 svg-icon' :class="[isFiltersOpen ? 'dropdown-arrow-open' : 'dropdown-arrow' ]">
										<use :xlink:href="[isFiltersOpen ? '#layout-icons-ms-arrow-up' : '#layout-icons-ms-arrow-down' ] " class='hover' :class="[isFiltersOpen ? 'layout-icons-ms-arrow-up' : 'layout-icons-ms-arrow-down' ]"></use>
									</svg>
								</span>
							</div>
							<ul class='filter-list' v-show='isFiltersOpen'>
								<li  v-for='(filter, index) in filters' :key='index' class='item' :class='{isChecked: filters.find(i => i.name === filter.name).selected}'>
									<label>
										<div class='item-checkbox'>
											<div class='virtual-checkbox clickable' :class='{ selected: filters.find(i => i.name === filter.name).selected}'>
												<svg class='svg-icon'>
													<use xlink:href='#layout-icons-ms-checkbox' class='layout-icons-ms-checkbox'></use>
												</svg>
											</div>
											<input type='checkbox' :disabled="isLoading" :checked='filters.find(i => i.name === filter.name).selected' :value='filter.name' @input='checkboxHandler(filter)'>
										</div>
										<div class='item-info'>
											<div class='name'>
												<span class='tooltip-wrapper'>
													<p>{{filter.displayName || filter.name}}</p>
												</span>
											</div>
										</div>
									</label>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="templates">
					<div class="templates-list-wrapper">
						<SelectTemplatesTable
							v-if='filtersSelected'
							:templates="templates"
							:class="{ empty: !filtersSelected || !templates.length }"
							@click="actionTemplateHandler('select')"
						/>
						<div v-if='(!filtersSelected || !templates.length) && !isLoading' class="no-results-message p13">
							<div v-localization="{ key: 'common.dictionary.noMatchesFound' }" />
						</div>
					</div>
					<div class="reportingservice-overlay loading-overlay large" v-show="isLoading" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	mapActions, mapState, mapGetters
} from 'vuex';
import templatesMixin from './Mixins/templatesMixin';
import { wizardMixin } from '@/Components/Wizard/Mixins/wizardMixin';
import coDataProvider from '@/Data/DataProviders/coDataprovider';
import SelectTemplatesTable from '@/Components/Wizard/WizardSets/EmailCampaign/Components/SelectTemplatesTable/selectTemplatesTable';
import SearchField from '@/Components/ComponentSet/SearchResults/search-field/search-field';
import Loader from '../../Components/Loader/loader';
import helperMethods from '@/Components/ComponentSet/component-set-evaluation-context-helper.methods';

export default {
	components: {
		SelectTemplatesTable,
		SearchField,
		Loader
	},
	name: 'email-campaign-step-select-template',
	data() {
		return {
			isLoading: false,
			templates: [],
			isPanelOpen: true,
			isFiltersOpen: true,
			customSearch: '',
			searching: false,
			filters: [
				{
					name: 'listing',
					selected: true,
					displayName: ''

				},
				{
					name: 'availability',
					selected: true,
                    displayName: ''
				}
			],
		};
	},
	mixins: [templatesMixin, wizardMixin],
	computed: {
		...mapState('wizard', ['steps', 'templateProcess']),
		...mapGetters('wizard', ['isTemplateSelected']),
		getTemplates() {
			return this.steps ? this.steps.find(i => i.active).templates : null;
		}
	},
	methods: {
		...mapActions('wizard', ['updateTemplates', 'updateFrameSrc', 'actionTemplateHandler', 'updateRecordList', 'selectedTemplateHandler', 'setErrors']),
		async openEmailTemplates() {
			let selectedFilters = this.filters.filter(i => i.selected);
			let options = {
				type: 'email',
				...(selectedFilters.length === 1 && { entity: selectedFilters[0].name }),
			};

			await coDataProvider.openCo(options);
		},
		checkboxHandler(filter) {
			this.filters.find(i => i.name === filter.name).selected = !filter.selected;
			this.search();
		}
	},
	async created() {
		// set display names to filter options
		for await (const f of this.filters) {
			const names = await sc.classes.get('entityConfiguration.dataProvider').getEntityCaptions([f.name], 'singular');
			f.displayName = helperMethods.eval(null, names[0]);
		};

		this.search();
		this.$on('execute-search', (searchQuery) => {
			this.executeSearch(searchQuery);
		});
		this.$on('search-query-changed', (searchQuery) => {
			this.executeSearch(searchQuery);
		});
		this.$on('clear-search-query', (searchQuery) => {
			this.executeSearch(searchQuery);
		});
		if (this.steps.find(i => i.name === 'template').artifact) {
			this.setErrors([{
				text: 'The changes with template will trigger the new publication generation. The publication currently available on the preview will become unavailable once you apply them. Ensure that such edits align with your needs.',
				type: 'warning',
				close: true,
				step: 'template',
				name: 'templateChanges'
			}]);
		}
	}
};
</script>

<style lang='less' scoped>
@import '../../Assets/Styles/default.less';
@import './select-template.less';
@import '~@acx-xms/styles-mixins/style.mixins.less';
</style>
