var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "control-color-picker" }, [
    _vm.enable
      ? _c("input", {
          staticClass: "color-text",
          attrs: { type: "text" },
          domProps: { value: _vm.value },
          on: { change: _vm.updateInput }
        })
      : _vm._e(),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model.lazy",
          value: _vm.value,
          expression: "value",
          modifiers: { lazy: true }
        }
      ],
      staticClass: "color-picker",
      attrs: { disabled: !_vm.enable, type: "color" },
      domProps: { value: _vm.value },
      on: {
        change: function($event) {
          _vm.value = $event.target.value
        }
      }
    }),
    _vm._v(" "),
    !_vm.enable
      ? _c("span", { staticClass: "color-label" }, [_vm._v(_vm._s(_vm.value))])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }