<template>
	<div class="search-area">
		<h5 v-localization="{ key: 'dialogues.mapping.header', args: [logicalName || ''] }"></h5>
		<div class="search-field-wrapper inline-block" v-bind:class="{'focused':searchFocused}">
			<input class="search-box"
					v-localization="{ key: 'common.dictionary.search', attrs: ['placeholder'] }"
					type="text"
					v-model="term"
					@focus="searchFocused = true"
					@blur="searchFocused = false" />
			<div class="search-icon-wrapper">
				<svg class="svg-icon">
					<use :xlink:href="'#layout-icons-ms-search'" class='layout-icons-ms-search'></use>
				</svg>
				<svg class="svg-icon svg-20 clear-button" v-show="term && term.length > 0" @click="onClearSearchBox">
					<use xlink:href="#layout-icons-textbox-delete" class="layout-icons-textbox-delete"></use>
				</svg>
			</div>
		</div>
	</div>
</template>
<script>

export default {
	props: {
		logicalName: String,
		pairs: Array
	},

	data() {
		return {
			term: '',
			searchFocused: false
		};
	},

	computed: {
		filteredPairs() {
			return this.pairs.filter(pair => {
				return pair.crmLogicalName.toLowerCase().includes(this.term.toLowerCase()) ||
					pair.crmDisplayName.toLowerCase().includes(this.term.toLowerCase()) ||
					pair.crmType.toLowerCase().includes(this.term.toLowerCase()) ||
					(pair.msField && pair.msField.displayName.toLowerCase().includes(this.term.toLowerCase())) ||
					(pair.msField && pair.msField.data.DataType.toLowerCase().includes(this.term.toLowerCase()));
			});
		}
	},

	watch: {
		filteredPairs: function (value) {
			this.$emit('onChange', value);
		}
	},
	methods: {
		onClearSearchBox() {
			this.term = '';
		}
	}
};
</script>
<style src="./mapping-edit-dialog.less" scoped></style>
<style src="@/Components/ComponentSet/SearchResults/search-field/search-field.less" scoped></style>
