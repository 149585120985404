import { SaveContactToSFAspect } from './aspects/saveContactSF.aspect';

export class SaveContactToSF {
	constructor(options) {
		this.options = options;
	}

	installAspects() {
		return [new SaveContactToSFAspect(this.options)];
	}
}
