<template>
	<div class="control-chat">
		<div v-if="!hideHeader" class="header">
			<div class="region">
				<slot name="title-slot">
					<div class="title h1 inline-block">{{ title }}</div>
				</slot>
			</div>
			<div class="region">
				<SearchField />
			</div>
		</div>
		<div v-if="chatSelected" class="messages-container">
			<div
				v-show="!isLoading"
				v-perfectscroll="{ enabled: true, onScroll: onScroll }"
				class="all-messages"
			>
				<template v-if="!messages.length">
					<div class="info p13">
						{{ lastSearchedQuery ? 'There is nothing to show here' : 'No messages yet' }}
					</div>
				</template>
				<Message
					v-for="message in messages"
					:key="message.Id"
					:currentUserId="currentUserId"
					:message="message"
					:evaluationContext="evaluationContext"
					:hasWriteAccess="hasWriteAccess"
					:disabled="disabled"
				/>
			</div>
			<div v-if="isMessageLengthExceeded" class="alert-length-exceeded">
				<svg class="svg-icon warning-sign">
					<use xlink:href='#layout-icons-warning' />
				</svg>
				<span>Please shorten the message to send it</span>
			</div>
			<div v-if="refreshing" v-show="!isLoading" class="refresh-wrapper">
				<svg class="svg-icon">
					<use xlink:href='#layout-icons-loader_sending' />
				</svg>
			</div>
			<div
				v-if="hasWriteAccess"
				v-show="!isLoading"
				:class="['chat-footer', {'disabled': disabled}]"
			>
				<div v-if="quoteMessage !== null" class="quote-message">
					<Quote
						:message="{
							text: quoteMessage.Name,
							createdonfromui: quoteMessage.Source.createdonfromui,
							createBy: quoteMessage.Source.nonregistered ? quoteMessage.Source.contactname : (quoteMessage.Source.chatmessagecontactidcontact || quoteMessage.Source.chatmessagecreatedbysystemuser).fullname
						}"
						:key="quoteMessage.Id"
					/>
				</div>
				<div v-perfectscroll="{ enabled: true }" class="text-input-wrapper">
					<textarea
						v-model="message"
						:placeholder="placeholder"
						:disabled="disabled"
						ref="textarea"
						class="text-input"
						@input="updateTextArea"
						@keyup.8="removeQuote"
						@keydown.enter.exact.prevent="enterHandler"
					/>
				</div>
				<div class="send" @click="sendMessage">
					<svg class="svg-icon">
						<use xlink:href='#layout-icons-chat-send' />
					</svg>
				</div>
			</div>
			<div v-show="isLoading" class="loading-overlay" />
		</div>
		<div v-else class="info p13">{{ statusMessage }}</div>
	</div>
</template>
<script>
import {
	findChat, getChatParticipant, onNewMessage, onNewMessages, findMessages, scrollToTheBottom, scrollToTheTop, scrollToTheLastReadMessage, isNeededScrollToTheBottom
} from './control-chat.methods';
import { SubscribeOnlineOnly, UnSubscribeOnlineOfflineConnection } from '@/Data/DataProviders/onlineStateDataProvider';
import { Create, generateGuid } from '@acx-xms/data-functions/dist';
import { Get as GetItem } from '@/Data/DataProviders/userStorageDataProvider';
import {
	Set as StorageSet, Get as StorageGet, Remove as StorageRemove
} from '@/Data/DataProviders/localStorageDataProvider';
import { GetAvatar } from '../../../Data/avatar-service';

const SearchField = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './../../ComponentSet/SearchResults/search-field/search-field');
const Message = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './message');
const Quote = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './quote');

export default {
	name: 'control-chat',
	props: {
		options: Object,
		evaluationContext: Object,
		isSelected: Boolean
	},
	components: {
		Message,
		Quote,
		SearchField
	},
	data() {
		return {
			chatSelected: false,
			chat: null,
			statusMessage: '',
			messages: [],
			message: '',
			currentUserId: null,
			refreshing: false,
			title: 'Chat',
			isLoading: true,
			quoteMessage: null,
			records: [],
			disabled: false,
			placeholder: '',
			lastSearchedQuery: '',
			hasWriteAccess: false,
			recordsInProcess: []
		};
	},
	computed: {
		textAreaElement() {
			return this.chatSelected && this.$el.querySelector('.text-input');
		},
		textAreaWrapper() {
			return this.chatSelected && this.$el.querySelector('.text-input-wrapper');
		},
		isMessageLengthExceeded() {
			return this.message.length > this.messageMaxLength;
		}
	},
	methods: {
		newline() {
			this.message = `${this.message}\n`;
		},
		enterHandler() {
			if (sc.utils.userAgent.userAgent.isMobile) {
				this.newline();
				return;
			}
			this.sendMessage();
		},
		resetTextArea() {
			this.quoteMessage = null;
			scrollToTheBottom.call(this, true);
			this.updateTextArea();
			this.textAreaWrapper.scrollTop = 0;
		},
		sendMessage() {
			this.message = this.message.trim();
			// todo: here we need to show that message cannot be sent empty, disable or highlight send button
			if (!this.message || this.isMessageLengthExceeded) {
				this.message = '';
				this.$nextTick(() => {
					this.resetTextArea();
				});
				return;
			}
			const generatedID = generateGuid();
			const clientServerTimeDelta = isNaN(this.$store.state.clientServerTimeDelta) ? 0 : this.$store.state.clientServerTimeDelta;
			const createdOnFromUI = new Date(new Date().getTime() - clientServerTimeDelta);

			const tempMessage = {
				Id: generatedID,
				Name: this.message,
				Source: {
					chatmessagecreatedbysystemuser: {
						fullname: this.currentUser.fullname,
						avatarid: GetAvatar()
					},
					createdby: {
						id: this.currentUser.systemuserid,
						logicalname: 'systemuser'
					},
					chatmessageparentchatmessageidchatmessage: this.quoteMessage ? this.quoteMessage.Source : null,
					ownerid: { id: this.currentUserId },
					chatmessagecontactidcontact: this.chatParticipant.Source.chatparticipantcontactidcontact,
					createdonfromui: createdOnFromUI,
					messagetext: this.message,
					contactname: this.chatParticipant.Source.nonregistered !== true ? this.chatParticipant.Source.chatparticipantcontactidcontact.fullname : this.chatParticipant.Name,
					nonregistered: this.chatParticipant.Source.nonregistered || false,
					messagetype: 'Message'
				}
			};

			this.messages.push(tempMessage);
			/* save to storage this message */
			StorageSet(this.chat.id, [{
				chatid: this.chat.id,
				message: tempMessage
			}]);

			const record = {
				chatmessageid: generatedID,
				chatid: {
					id: this.chat.id,
					logicalname: 'chat'
				},
				createdby: {
					id: this.currentUser.systemuserid,
					logicalname: 'systemuser'
				},
				type: 'chatmessage',
				messagetext: this.message,
				contactid: this.chatParticipant.Source.contactid,
				contactname: this.chatParticipant.Source.nonregistered !== true ? this.chatParticipant.Source.chatparticipantcontactidcontact.fullname : this.chatParticipant.Name,
				messagetype: 'Message',
				nonregistered: this.chatParticipant.Source.nonregistered || false,
				createdonfromui: createdOnFromUI,
				visibleto: 'All Users',
				chatmessagechatidchat: {
					collaborationroomid: this.evaluationContext.datasource.Source.collaborationroomid,
					dealroomid: this.evaluationContext.datasource.Source.dealroomid
				}
			};

			/* handle quoted message */
			if (this.quoteMessage) {
				record.parentchatmessageid = {
					id: this.quoteMessage.Id,
					logicalname: 'chatmessage'
				};
				record.chatmessageparentchatmessageidchatmessage = this.quoteMessage.Source;
			}
			/* add sended message as notification before it realy sended */
			this.$store.commit('counters/setMessages', [{
				chatid: this.chat.id,
				message: {
					Id: generatedID,
					Source: record
				}
			}]);

			if (this.lastSearchedQuery) {
				this.$emit('set-search-query', '');
				this.clearSearchQuery(true).then(this.processMessage(record, generatedID));
			} else {
				this.processMessage(record, generatedID);
			}
			this.message = '';
			this.$nextTick(() => {
				this.resetTextArea();
			});
		},
		updateTextArea() {
			if (this.textAreaElement) {
				const needsScroll = isNeededScrollToTheBottom.call(this);
				this.textAreaElement.style.height = '35px';
				this.textAreaElement.style.height = this.textAreaElement.scrollHeight + 'px';
				this.textAreaWrapper.style.height = this.textAreaElement.scrollHeight + 'px';
				if (needsScroll) {
					scrollToTheBottom.call(this);
				}
			}
		},
		removeQuote(e) {
			if (this.quoteMessage && e.currentTarget.selectionStart === 0) {
				this.quoteMessage = null;
			}
		},
		addScrollTopBottomSubscription() {
			this.$nextTick(() => {
				const messagesEL = this.$el.querySelector('.all-messages');
				if (!messagesEL) {
					return;
				}

				this.scrollTopSubscription = messagesEL && messagesEL.addEventListener('scrollToTop', () => {
					if (!this.refreshing && !this.lastSearchedQuery) {
						this.refreshing = true;
						findMessages.call(this, { isOld: true }).then(() => {
							const container = this.$el.querySelector('.all-messages');
							container.scrollTop = 10;
							this.refreshing = false;
						});
					}
				});
				this.scrollBottomSubscription = messagesEL && messagesEL.addEventListener('scrollToBottom', () => {
					if (!this.refreshing && this.lastSearchedQuery && !this.isLoading && this.loadMoreFiltered) {
						this.refreshing = true;
						findMessages.call(this, { searchQuery: this.lastSearchedQuery }).then(() => {
							const container = this.$el.querySelector('.all-messages');
							container.scrollBottom = 10;
							this.refreshing = false;
						});
					}
				});
			});
		},
		scrollMessages() {
			this.$nextTick(() => {
				if (this.messages.length === this.unreadMessages.length) {
					scrollToTheTop.call(this);
				} else if (this.unreadMessages.length === 0) {
					scrollToTheBottom.call(this);
				} else {
					scrollToTheLastReadMessage.call(this);
				}
			});
		},
		onScroll() {
			this.$root.$emit('mainSearch.chat.scrolled');
		},
		searchMessagesByQuery(query) {
			if (this.lastSearchedQuery === query || !this.chat || !this.chat.id) {
				return;
			}
			this.lastSearchedQuery = query;
			if (!query) {
				this.clearSearchQuery(true);
			} else {
				findMessages.call(this, {
					searchQuery: query,
					initial: true
				}).then(() => {
					this.$nextTick(() => {
						scrollToTheTop.call(this);
					});
				});
			}
		},
		clearSearchQuery(force) {
			if (!force && (this.lastSearchedQuery === '' || !this.chat || !this.chat.id)) {
				return Promise.resolve();
			}

			this.isLoading = true;
			this.lastSearchedQuery = '';
			return findMessages.call(this, {
				initial: true,
				cleared: true
			}).then(() => {
				this.$nextTick(() => {
					scrollToTheBottom.call(this);
				});
			});
		},
		initializeVariables() {
			this.oldmessagesscount = 0;
			this.oldmessagessTotalcount = 0;
			this.lastreadmessagedate = null;
			this.unreadMessages = [];
			this.initialLastReadMessage = undefined;
			this.lastReadRemoteMessageDate = null;
			this.loadedMessageIds = [];
			this.loadMoreFiltered = true;
		},
		async processMessage(message, tempID) {
			let result = false;
			const record = Object.assign({}, message);
			if (record.parentchatmessageid) {
				if (this.recordsInProcess.some(rec => rec.chatmessageid === record.parentchatmessageid.id)) {
					this.recordsInProcess.push(record);
					return;
				}
			}
			/*
				hotfix
				TODO: refactor messages: filters & sending data
			*/
			if (record.chatmessagechatidchat) delete record.chatmessagechatidchat;
			if (record.createdby) delete record.createdby;

			this.recordsInProcess.push(record);
			await Create('chatmessage', record)
				.then(response => {
					/* modify response */
					response.chatmessagechatidchat = {
						collaborationroomid: this.evaluationContext.datasource.Source.collaborationroomid,
						dealroomid: this.evaluationContext.datasource.Source.dealroomid
					};
					if (record.parentchatmessageid) {
						response.parentchatmessageid = record.parentchatmessageid;
						response.chatmessageparentchatmessageidchatmessage = record.chatmessageparentchatmessageidchatmessage;
					}
					this.messages.splice(this.messages.findIndex(el => el.Id === tempID), 1, {
						Id: response.chatmessageid,
						Source: response
					});
					/* update previously added message */
					this.$store.commit('counters/updateMessage', {
						filters: {
							chatid: response.chatid.id,
							createdonfromui: new Date(response.createdonfromui),
							contactid: response.contactid.id
						},
						data: {
							chatid: response.chatid.id,
							message: {
								Id: response.chatmessageid,
								Source: response
							}
						}
					});
					/* emit new message event */
					this.$root.$emit(`newMessage.${response.chatid.id}`, {
						Id: response.chatmessageid,
						Source: response
					});
					/* update message in storage */
					StorageSet(response.chatid.id, this.messages.filter(el => el.chatid === response.chatid.id));
					result = true;
				})
				.catch(() => {
					/* delete message from list */
					this.messages.pop();
					/* set last sended message to notification */
					this.$store.commit('counters/setMessages', [{
						chatid: this.chat.id,
						message: {
							Id: this.messages[this.messages.length - 1].Id,
							Source: this.messages[this.messages.length - 1].Source
						}
					}]);
					result = false;
				});

			this.handleQuotedMessages(record, result);
		},
		handleQuotedMessages(record, isSuccess) {
			const processingRecords = this.recordsInProcess.filter(rec => rec.parentchatmessageid && rec.parentchatmessageid.id === record.chatmessageid) || [];
			const mappedRecs = processingRecords.map(rec => rec.chatmessageid);
			mappedRecs.push(record.chatmessageid);
			this.recordsInProcess = this.recordsInProcess.filter(rec => !mappedRecs.includes(rec.chatmessageid)) || [];
			if (isSuccess) {
				processingRecords.forEach(rec => {
					this.processMessage(rec);
				});
			}
		},
		messageSync(event) {
			if (event.key !== this.chat.id) return; /* only messages from chatid */
			/* get item from local-storage */
			const stored = StorageGet(this.chat.id);
			if (stored && stored.length) {
				/* get last message from store */
				const lastMessage = this.$store.getters['counters/getMessageByChatId'](this.chat.id).message;
				/* message from local-storage */
				const message = stored[0].message;
				/* if last messages in store and local-storage is equal */
				if (lastMessage.Id !== message.Id) {
					/* add message to component messages */
					this.messages.push(message);
					/* scroll to bottom */
					this.scrollMessages();
				} else StorageRemove(this.chat.id); /* clear item from local-storage */
			}
		}
	},
	watch: {
		isSelected(newVal, oldVal) {
			if (!oldVal && newVal) {
				this.scrollMessages();
			}
		}
	},

	async created() {
		// todo rethink
		this.numberOfMessages = 10;
		this.initializeVariables();
		const recordId = this.evaluationContext.eval(this.options.recordId);
		this.logicalName = this.evaluationContext.eval(this.options.logicalname);
		const internalNamespace = this.evaluationContext.eval(this.options.internalNamespace);
		this.namespace = this.evaluationContext.eval(this.options.namespace) + '.' + internalNamespace;
		this.edgeDataProvider = sc.classes.get('edge.dataProvider');
		this.localizationDataProvider = sc.classes.get('localization.dataProvider');
		this.showOnLoad = this.evaluationContext.eval(this.options.showOnLoad);
		this.chatSelected = this.showOnLoad;
		this.fork = sc.events.fork();
		this.filters = (this.options.filter || []).map(filter => { return sc.classes.get(filter.$type, filter, this.evaluationContext).toFilter(); });
		this.hideHeader = this.evaluationContext.eval(this.options.hideHeader);

		this.statusMessage = ko.unwrap(this.localizationDataProvider.localize('chat.selectConversation'));
		this.placeholder = ko.unwrap(this.localizationDataProvider.localize('chat.typeMessage'));

		sc.classes.get('entityConfiguration.dataProvider').fetchEntity('chatmessage').done((chatmessage) => {
			this.messageMaxLength = chatmessage.metadata.detail.fields.messagetext.type.len;
		});

		const entity = await sc.classes.get('entityConfiguration.dataProvider').fetchEntity(this.logicalName);
		this.closeCompleteState = this.evaluationContext.eval(entity.closeCompleteStatuses3.inactiveStateCode.value);
		this.hasWriteAccess = this.options.hasWriteAccess ? await this.evaluationContext.evalAsync(this.options.hasWriteAccess) : true;

		this.userInfo = await sc.classes.get('authentication').getUserInfo(true);
		this.currentUserId = this.userInfo.Id;
		this.currentUser = this.userInfo;

		const data = await findChat.call(this, recordId);
		if (data.Results && data.Results.length) {
			this.chat = sc.classes.get('entityReference', data.Results[0]);
			this.chat.recordstateid = sc.utils.findProperty(data.Results[0], 'Source.recordstate.id');
			if (this.chat.recordstateid === this.closeCompleteState.Id) {
				this.disabled = true;
				this.placeholder = ko.unwrap(this.localizationDataProvider.localize('chat.roomIsClosed'));
			}
		} else {
			this.chatSelected = false;
		}
		let additionalFilters = [];
		if (this.currentUser.CRMRoles.some(role => role.Name.includes('Non-Registered Participant'))) {
			const lspath = `${this.$route.params.layout}_${this.$route.params.type}_${this.$route.params.id}`;
			const userName = await GetItem(lspath);
			additionalFilters = [sc.classes.get('termFacet.filter', {
				logicalName: 'name',
				query: [userName]
			})];
		}
		const cpResults = await getChatParticipant.call(this, this.currentUserId, this.chat.id, additionalFilters);
		this.chatParticipant = cpResults.Results[0];

		this.$store.commit('counters/setChatParticipant', cpResults.Results[0]);

		if (this.chatSelected) {
			await findMessages.call(this, {
				from: 0,
				initial: true
			});
			this.scrollMessages();
			this.addScrollTopBottomSubscription();
		} else {
			// no chat found
			this.statusMessage = ko.unwrap(this.localizationDataProvider.localize('chat.noChatFound'));
		}

		this.$on('quote', (message) => {
			this.quoteMessage = message;
			this.$refs.textarea.focus();
		});

		this.$on('clear-search-query', this.clearSearchQuery);
		this.$on('execute-search', this.searchMessagesByQuery);
		this.$root.$on(`newMessage.${this.chat.id}`, async (message) => {
			this.chatSelected = true;
			this.refreshing = true;
			const _isNeededScrollToTheBottom = isNeededScrollToTheBottom.call(this);
			await onNewMessage.call(this, message);
			this.refreshing = false;
			_isNeededScrollToTheBottom && scrollToTheBottom.call(this);
		});

		window.addEventListener('resize', this.updateTextArea);

		/* Add sync between chat and storage */
		window.addEventListener('storage', this.messageSync);

		/* after loading add chat, if it hasn't added */
		if (!this.$store.getters['counters/getMessageByChatId'](this.chat.id)) {
			/* get last message from current messages */
			const lastMessage = this.messages[this.messages.length - 1];
			/* set message in store */
			lastMessage && this.$store.commit('counters/setMessages', [{
				chatid: this.chat.id,
				message: {
					Id: lastMessage.Id,
					Source: lastMessage.Source
				}
			}]);
		}

		SubscribeOnlineOnly(`chat-${this.chat.id}`, async () => {
			const msgs = await findMessages.call(this);
			if (msgs.Total) {
				await onNewMessages.call(this, msgs);
				scrollToTheLastReadMessage.call(this);
			}
			this.refreshing = false;
		});

		await this.$store.getters.serverTimeInit.promise;
	},

	async beforeDestroy() {
		window.removeEventListener('resize', this.updateTextArea);
		const allMessagesContainer = this.$el.querySelector('.all-messages');
		if (allMessagesContainer) {
			allMessagesContainer.removeEventListener('scrollToTop', this.scrollTopSubscription);
			allMessagesContainer.removeEventListener('scrollToBottom', this.scrollBottomSubscription);
		}
		this.$root.$off(`newMessage.${this.chat.id}`);

		this.$off('clear-search-query');
		this.$off('execute-search');

		await UnSubscribeOnlineOfflineConnection(`chat-${this.chat.id}`);

		/* Remove sync between chat and storage */
		StorageRemove(this.chat.id);
		window.removeEventListener('storage', this.messageSync);
	}
};
</script>
<style src="./control-chat.less" scoped></style>
