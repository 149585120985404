<template>
	<div class="control-manage-notifications">
		<div class="title">Manage Notifications</div>
		<div class="loading-overlay large" v-show="isLoading"></div>
		<template v-if="isPushNotificationsEnabled">
			<div class="header">
				<div class="type">Type</div>
				<div class="title-wrapper">
					<div class="name">Name</div>
					<div class="desc">Description</div>
					<div class="checkbox">Allow</div>
				</div>
			</div>
			<template v-if="groups.length">
				<div class="body" v-for="group in groups" :key="group.id">
					<div class="type">
						<div class="icon">
							<svg class='svg-icon svg-20'>
								<use :xlink:href="getIcon(group.id)"></use>
							</svg>
						</div>
						<div class="name">{{group.name}}</div>
					</div>
					<div class="items">
						<div class="data" v-for="item in group.subgroups" :key="item.id">
							<div class="name">{{item.name}}</div>
							<div class="desc">{{item.description}}</div>
							<div class="checkbox"><checkbox @input="addToList(item)" :disabled=item.readonly v-model="item.enabled" /></div>
						</div>
					</div>
				</div>
			</template>
		</template>
		<template v-else>
			<div class="notifications-disabled" v-localization="{ key: 'notificationPanel.disabledNotificationMessage', args: ['\r\n'] }"></div>
		</template>
	</div>
</template>
<script>
import {
	getNotificationGroups, getAvailableNotifications, updateNotificationSettings
} from '@/Data/DataProviders/notificationSettingsDataProvider';
import Checkbox from '@/Components/Control/Checkboxes/checkbox-base';

export default {
	name: 'control-manage-notifications',
	components: { Checkbox },
	props: {
		options: Object,
		evaluationContext: Object
	},
	computed: {
		isPushNotificationsEnabled() {
			return this.$store.getters.isPushNotificationsEnabled;
		}
	},
	data() {
		return {
			groups: [],
			isLoading: true
		};
	},
	watch: {
		isPushNotificationsEnabled: {
			async handler(value) {
				if (value) {
					this.isLoading = true;
					this.icons = {
						'30381a67-1043-4bc1-afda-5b30599d66ef': '#layout-icons-manage-notification-calendar', // Events
						'04366ff6-aa1c-47ec-a26e-56caf62efcec': '#layout-icons-manage-notification-notifications', // Reminders
						'43f5098a-ac63-4af0-bae6-f605cdf52dfa': '#layout-icons-manage-notification-settings' // System messages
					};
					this.processItems = [];

					const groups = await getNotificationGroups();
					const availableNotifications = await getAvailableNotifications();

					groups.forEach(item => {
						item.subgroups = item.subgroups.map(subitem => {
							return {
								...subitem,
								enabled: availableNotifications[subitem.id].enabled
							};
						});
					});

					this.groups = groups;

					this.debouncedCallback = _.debounce(this.updateSettings, 1500);
				}
				this.isLoading = false;
			},
			immediate: true
		}
	},
	methods: {
		updateSettings() {
			const clone = [].concat(this.processItems);
			this.processItems = [];

			updateNotificationSettings(clone);
		},
		getIcon(id) {
			return this.icons[id];
		},
		addToList(item) {
			const existItemIndex = this.processItems.findIndex(processItem => processItem.id === item.id);
			if (existItemIndex !== -1) {
				this.processItems.splice(existItemIndex, 1, item);
			} else {
				this.processItems.push(item);
			}

			this.debouncedCallback();
		}
	}
};
</script>
<style src="./control-manage-notifications.less" scoped></style>
