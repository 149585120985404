<template>
	<div v-if="enable&&visible" class="column" :class="[cssClass, { collapsed: !collapsed }]" :style="{ width: width || '100%', height }">
		<div class="toggle" v-if="allowToggle" @click="toggle" :class="{ maximized: collapsed }"></div>
		<div v-if="!hideHeader"
             @click="allowToggle ? toggle() : null"
             class="column-title"
             v-bind:class="{'with-check':options.withCheckAll, 'column-title-pointer':allowToggle}">
			<div class="flex-wrapper">
				{{ title }}
				<action-menu class="without-border"
							 v-if="actionMenuComponent"
							 :options="actionMenuComponent"
							 :evaluationContext="evaluationContext">
				</action-menu>
			</div>
		</div>

		<div :class="{ 'no-header': hideHeader }" class="column-content" v-show="collapsed" v-perfectscroll="{enabled:true}">
			<control :name="options.control.$type" v-bind:contentProps.once="options.control" :evaluationContext="evaluationContext"></control>
		</div>
	</div>
</template>
<script>

import helperMethods from './../component-set-evaluation-context-helper.methods';
export default {
	name: 'details-column-control',
	props: {
		options: Object,
		evaluationContext: Object
	},
	components: {
		Control: () => import('./../../Entity/control'),
		ActionMenu: () => import('@/Components/Menu/action-menu')
	},
	data() {
		return {
			width: this.options.width,
			height: this.options.height,
			allowToggle: helperMethods.eval(this.evaluationContext, this.options.toggleComponent),
			title: helperMethods.eval(this.evaluationContext, this.options.title),
			collapsed: (this.options.collapsed) ? !helperMethods.eval(this.evaluationContext, this.options.collapsed) : true,
			visible: false,
			hideHeader: !!helperMethods.eval(this.evaluationContext, this.options.hideHeader),
			enable: false,
			cssClass: this.options.cssClass ? helperMethods.eval(this.evaluationContext, this.options.cssClass) : [],
			actionMenuComponent: null
		};
	},
	async created() {
		if (this.options.menu) {
			this.actionMenuComponent = { toolbarActions: this.options.menu };
		}
		this.enable = this.options.enable === void 0 || await helperMethods.evalAsync(this.evaluationContext, this.options.enable);
		this.visible = this.options.visible === void 0 || await helperMethods.evalAsync(this.evaluationContext, this.options.visible);
	},
	methods: {
		toggle: function () {
			this.collapsed = !this.collapsed;
		},
		componentWrapper: function (component) {
			return {
				$bindProperties: {
					name: component.$type,
					params: [component, this.evaluationContext, {
						$propertyBag: {},
						$evaluationContext: this.evaluationContext
					}]
				}
			};
		}
	}

};
</script>
<style>
	.component-search-results-details > .content > .rows > .row > .column.cr-participants > .column-content {
		padding: 0 30px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.component-search-results-details .rows .row .column.cr-participants > .column-content > div {
		height: auto;
	}

	.component-search-results-details .rows .row .column.cr-participants .column-content .scrollable-panel .field-wrapper {
		padding: 0;
	}
</style>
<style src="./details-column-control.less" scoped></style>
