var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "perfectscroll",
          rawName: "v-perfectscroll",
          value: { enable: true },
          expression: "{enable: true}"
        }
      ],
      staticClass: "tabs-wrapper"
    },
    [
      _c(
        "ul",
        { staticClass: "tabs-header" },
        _vm._l(_vm.tabs, function(tab, idx) {
          return _c(
            "li",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: tab.visible == undefined || tab.visible,
                  expression: "tab.visible == undefined || tab.visible"
                }
              ],
              key: tab.title,
              staticClass: "tab",
              class: { "selected-tab": idx == _vm.selectedIdx },
              on: {
                click: function($event) {
                  _vm.selectTab(idx)
                }
              }
            },
            [_vm._v("\n\t\t\t" + _vm._s(tab.title) + "\n\t\t")]
          )
        })
      ),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }