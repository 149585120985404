var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "boolean-value" }, [
    _c("span", { staticClass: "p4" }, [_vm._v(_vm._s(_vm.title))]),
    _vm._v(" "),
    _c("label", { staticClass: "checkbox-wrapper" }, [
      _c("input", {
        attrs: { type: "checkbox" },
        domProps: { value: _vm.currentValue, checked: _vm.currentValue },
        on: { change: _vm.onChange }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "slider round" })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }