var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tabs-pop-up-wrapper",
      class: { "justify-content-end": _vm.isLoading || _vm.isValidating }
    },
    [
      !_vm.isLoading && !_vm.isValidating
        ? _c(
            "tabs",
            [
              _c(
                "tab",
                { attrs: { title: "Email Setup", visible: _vm.hasSMTPApp } },
                [
                  _c("div", { staticClass: "tab-content smtp" }, [
                    _c("div", { staticClass: "p4 tab-title" }, [
                      _vm._v("Please enter SMTP server credentials")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "default-smtp-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "checkbox-wrapper" },
                        [
                          _c("checkbox", {
                            attrs: { value: _vm.isDefaultSMTPEnabled },
                            model: {
                              value: _vm.isDefaultSMTPEnabled,
                              callback: function($$v) {
                                _vm.isDefaultSMTPEnabled = $$v
                              },
                              expression: "isDefaultSMTPEnabled"
                            }
                          }),
                          _vm._v(" "),
                          _c("label", { staticClass: "label" }, [
                            _vm._v("Use default server")
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "tooltip" }, [
                            _c("span", { staticClass: "tooltip-icon" }, [
                              _c("svg", { staticClass: "svg-icon svg-16" }, [
                                _c("use", {
                                  attrs: {
                                    "xlink:href":
                                      "/Images/login-info.svg#login-info"
                                  }
                                })
                              ])
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "tooltip-text" }, [
                              _vm._v(
                                "Emails sent from this email address may end up in Spam"
                              )
                            ])
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.isDefaultSMTPEnabled
                        ? _c("p", { staticClass: "default-smtp-info" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\tSMTP server is setup. Emails will be sent from the " +
                                _vm._s(_vm.defaultSMTPDomain) +
                                ". If you want your emails to be sent from another domain, please set up your custom SMTP server.\n\t\t\t\t\t"
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row row-1" }, [
                      _c(
                        "div",
                        { staticClass: "server" },
                        [
                          _c("div", { staticClass: "title p4" }, [
                            _vm._v("SMTP Server:")
                          ]),
                          _vm._v(" "),
                          _c("stringeditor", {
                            attrs: {
                              value: _vm.smtpUrl,
                              isValid: _vm.fieldOptions.smtpUrl.valid,
                              dataAttr: {
                                title: "Org Settings",
                                $type: "dialog.input"
                              },
                              enable: !_vm.isDefaultSMTPEnabled,
                              hideText: _vm.isDefaultSMTPEnabled
                            },
                            on: {
                              input: function($event) {
                                _vm.onValueChange($event, "smtpUrl")
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "port" },
                        [
                          _c("div", { staticClass: "title p4" }, [
                            _vm._v("SMTP Port:")
                          ]),
                          _vm._v(" "),
                          _c("stringeditor", {
                            attrs: {
                              value: _vm.smtpPort,
                              isValid: _vm.fieldOptions.smtpPort.valid,
                              dataAttr: {
                                title: "Org Settings",
                                $type: "dialog.input"
                              },
                              enable: !_vm.isDefaultSMTPEnabled,
                              hideText: _vm.isDefaultSMTPEnabled
                            },
                            on: {
                              input: function($event) {
                                _vm.onValueChange($event, "smtpPort")
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row row-2" },
                      [
                        _c("div", { staticClass: "title p4" }, [
                          _vm._v("Login:")
                        ]),
                        _vm._v(" "),
                        _c("stringeditor", {
                          attrs: {
                            value: _vm.smtpLogin,
                            isValid: _vm.fieldOptions.smtpLogin.valid,
                            dataAttr: {
                              title: "Org Settings",
                              $type: "dialog.input"
                            },
                            enable: !_vm.isDefaultSMTPEnabled,
                            hideText: _vm.isDefaultSMTPEnabled
                          },
                          on: {
                            input: function($event) {
                              _vm.onValueChange($event, "smtpLogin")
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row row-3" },
                      [
                        _c("div", { staticClass: "title p4" }, [
                          _vm._v("Password:")
                        ]),
                        _vm._v(" "),
                        _c("passwordeditor", {
                          attrs: {
                            value: _vm.smtpPass,
                            isValid: _vm.fieldOptions.smtpPass.valid,
                            dataAttr: {
                              title: "Org Settings",
                              $type: "dialog.input"
                            },
                            enable: !_vm.isDefaultSMTPEnabled,
                            hideText: _vm.isDefaultSMTPEnabled,
                            showUnhide: _vm.showUnhide
                          },
                          on: {
                            input: function($event) {
                              _vm.onValueChange($event, "smtpPass")
                            },
                            onfocuschange: _vm.onFocusChange
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row row-4" },
                      [
                        _c("div", { staticClass: "title p4" }, [
                          _vm._v("Email address to send test emails:")
                        ]),
                        _vm._v(" "),
                        _c("stringeditor", {
                          attrs: {
                            value: _vm.testEmailAddress,
                            isValid: _vm.fieldOptions.testEmailAddress.valid,
                            dataAttr: {
                              title: "Org Settings",
                              $type: "dialog.input"
                            },
                            enable: !_vm.isDefaultSMTPEnabled,
                            hideText: _vm.isDefaultSMTPEnabled
                          },
                          on: {
                            input: function($event) {
                              _vm.onValueChange($event, "testEmailAddress")
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    !_vm.isDefaultSMTPEnabled
                      ? _c(
                          "div",
                          {
                            staticClass: "row row-5",
                            class: [_vm.status ? "with-status" : ""]
                          },
                          [
                            _vm.status
                              ? _c(
                                  "p",
                                  {
                                    staticClass: "status",
                                    class: [
                                      _vm.status === "Connected"
                                        ? "success"
                                        : "error"
                                    ]
                                  },
                                  [
                                    _vm._v("\n\t\t\t\t\t\tStatus: "),
                                    _c("span", [_vm._v(_vm._s(_vm.status))])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "data-attr",
                                    rawName: "v-data-attr",
                                    value: {
                                      title: "Org Settings",
                                      $type: "dialog.button.submit"
                                    },
                                    expression:
                                      "{title: 'Org Settings', $type: 'dialog.button.submit'}"
                                  }
                                ],
                                attrs: { disabled: !_vm.isValidateEnabled },
                                on: { click: _vm.validateCreds }
                              },
                              [_vm._v("Validate credentials")]
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ]
              ),
              _vm._v(" "),
              _c("tab", { attrs: { title: "SEO", visible: "true" } }, [
                _c(
                  "div",
                  { staticClass: "tab-content seo" },
                  [
                    _c("checkbox", {
                      attrs: { value: _vm.isSeoEnabled },
                      model: {
                        value: _vm.isSeoEnabled,
                        callback: function($$v) {
                          _vm.isSeoEnabled = $$v
                        },
                        expression: "isSeoEnabled"
                      }
                    }),
                    _vm._v(" "),
                    _c("label", {
                      directives: [
                        {
                          name: "localization",
                          rawName: "v-localization",
                          value: {
                            key: "common.dictionary.orgsettings.indexing.label"
                          },
                          expression:
                            "{key: 'common.dictionary.orgsettings.indexing.label'}"
                        }
                      ],
                      staticClass: "label"
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "footer" }, [
        _c("button", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "common.dictionary.buttons.cancel" },
              expression: "{key: 'common.dictionary.buttons.cancel'}"
            },
            {
              name: "data-attr",
              rawName: "v-data-attr",
              value: { title: "Org Settings", $type: "dialog.button.cancel" },
              expression:
                "{title: 'Org Settings', $type: 'dialog.button.cancel'}"
            }
          ],
          staticClass: "cancel",
          on: { click: _vm.close }
        }),
        _vm._v(" "),
        _c("button", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "common.dictionary.buttons.save" },
              expression: "{key: 'common.dictionary.buttons.save'}"
            },
            {
              name: "data-attr",
              rawName: "v-data-attr",
              value: { title: "Org Settings", $type: "dialog.button.submit" },
              expression:
                "{title: 'Org Settings', $type: 'dialog.button.submit'}"
            }
          ],
          attrs: { disabled: !_vm.isSaveButtonEnabled },
          on: { click: _vm.onSave }
        })
      ]),
      _vm._v(" "),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading,
            expression: "isLoading"
          }
        ],
        staticClass: "loading-overlay"
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }