import { BaseAspect } from './base.aspect';

export class AddQuickContactToParticipants extends BaseAspect {
	constructor() {
		super();
		this.stage = 'afterSave';
	}

	async execute(options) {
		sc.events.emit('inviteParticipants.addParticipant', options.saveResult.Id);
	}
}
