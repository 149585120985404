var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dialog-content-wrapper email-link-dialog" },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "header" }, [
          _c("p", {
            directives: [
              {
                name: "localization",
                rawName: "v-localization",
                value: { key: "dialogues.email.link.legend" },
                expression: "{key:'dialogues.email.link.legend'}"
              }
            ]
          })
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "perfectscroll",
                rawName: "v-perfectscroll",
                value: { enabled: true },
                expression: "{enabled:true}"
              }
            ],
            ref: "email",
            staticClass: "email-body"
          },
          [
            _c("table", { staticStyle: { width: "100%" } }, [
              _c(
                "tbody",
                _vm._l(_vm.options.params.selection, function(item) {
                  return _c("tr", { key: item.Name }, [
                    _c(
                      "td",
                      { staticClass: "strong-text", attrs: { width: "25%" } },
                      [_vm._v(_vm._s(item.Name))]
                    ),
                    _vm._v(" "),
                    _c("td", { attrs: { width: "75%" } }, [
                      _c(
                        "a",
                        {
                          attrs: { target: "_blank", href: _vm.GetUrl(item) },
                          on: {
                            mousedown: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.GetUrl(item)))]
                      )
                    ])
                  ])
                })
              )
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "footer" }, [
        _c("span", { staticClass: "buttons" }, [
          _c("button", {
            directives: [
              {
                name: "localization",
                rawName: "v-localization",
                value: { key: "dialogues.email.link.copy" },
                expression: "{key: 'dialogues.email.link.copy'}"
              },
              {
                name: "data-attr",
                rawName: "v-data-attr",
                value: {
                  title: _vm.options.title,
                  $type: "dialog.button.copy"
                },
                expression:
                  "{title: options.title, $type: 'dialog.button.copy'}"
              }
            ],
            attrs: { type: "submit" },
            on: { click: _vm.copy }
          }),
          _vm._v(" "),
          _c("button", {
            directives: [
              {
                name: "localization",
                rawName: "v-localization",
                value: { key: "common.dictionary.buttons.cancel" },
                expression: "{key: 'common.dictionary.buttons.cancel'}"
              },
              {
                name: "data-attr",
                rawName: "v-data-attr",
                value: {
                  title: _vm.options.title,
                  $type: "dialog.button.cancel"
                },
                expression:
                  "{title: options.title, $type: 'dialog.button.cancel'}"
              }
            ],
            staticClass: "cancel",
            attrs: { type: "button" },
            on: { click: _vm.cancel }
          })
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }