<template>
    <div class='email-campaign-step-root select-marketing-list'>
        <SearchResults />
    </div>
</template>

<script>
const SearchResults = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */'../../Components/SearchResults/searchResults.vue');
import { mapState, mapGetters, mapActions } from 'vuex';
export default {
    components: { SearchResults },
    name: 'email-campaign-step-select-marketing-list',
    computed: {
        ...mapState('wizard', ['steps']),
        ...mapGetters('wizard', ['isEdited']),
        getWizardMarketingListingState () {
            return this.$store.state && 
            this.$store.state['wizard.ml'] &&
            this.$store.state['wizard.ml'].selection &&
            this.$store.state['wizard.ml'].selection.staticlist;
        },
        marketingListingModuleReady() {
            return this.$store.state && this.$store.state['wizard.ml']
        }
    },
    watch: {
        getWizardMarketingListingState(newVal, oldVal) {
            if (!this.isEdited && this.$route.params.isDetails && newVal) {
                this.$store.commit('wizard/UPDATE_EDITED_FLAG', true);
            }
            if (!this.isEdited && !this.$route.params.isDetails && (oldVal || !this.initMlSelection.length && newVal.length)) {
                this.$store.commit('wizard/UPDATE_EDITED_FLAG', true);
            }
        },
        marketingListingModuleReady (data) {
            if (data) { 
                this.$store.commit(`wizard.ml/changeSelection`, { records: [...this.steps[3].selection], select: true });
                this.initMlSelection = this.steps[3].selection;
                this.setMlInitSelection([]);
            }
        }
    },
    created() {
        this.$route.params.componentSet = 'email-campaign-wizard-marketing-list';
    },
    methods: {
        ...mapActions('wizard', ['setMlInitSelection'])
    }
};
</script>

<style lang='less' scoped>
@import '../../Assets/Styles/default.less';

.select-marketing-list {
    padding: 0;
    margin: 0;
    height: 100%;
}
</style>