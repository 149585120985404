<template>
	<div class="leave-room-dialog">
		<div class="body">
			<span class="p4" v-localization="{ key: 'common.dictionary.leaveRoomConfirmation'}"></span>
		</div>
		<div class="footer">
			<button class="cancel" @click="onCancel" v-localization="{ key: 'common.dictionary.buttons.cancel'}"></button>
			<button type="button" @click="onSubmit" v-localization="{ key: 'common.dictionary.leaveRoomOkButton'}"></button>
		</div>
		<div class="loading-overlay" v-show="isLoading"></div>
	</div>
</template>
<script>
import { GetUserInfo } from '@/Data/Auth/authentication-service';
import {
	getBaseRouteUrl, showToastOrInfoDialog, Update, Search
} from '@acx-xms/data-functions/dist';

export default {
	name: 'leave-room-dialog',
	components: {},
	props: { options: Object },
	data() {
		return { isLoading: false };
	},

	async created() {
		this.isLoading = true;
		this.localizationProvider = sc.classes.get('localization.dataProvider');
		this.selection = this.options.params.sel;
		const userInfo = await GetUserInfo();
		this.participant = await this.getParticipant(this.selection, userInfo.systemuserid);
		if (!this.participant) {
			const message = this.localizationProvider.localize('errors.errorsHandler.title');
			const messageDetails = this.localizationProvider.localize('common.dictionary.youAreNotParticipant');
			sc.utils.errorMessage.byMessage(message, messageDetails);
			this.close();
		}
		this.room = this.participant.Source.chatparticipantchatidchat.dealroomid || this.participant.Source.chatparticipantchatidchat.collaborationroomid;

		this.isLoading = false;
	},
	methods: {
		async onSubmit() {
			this.isLoading = true;
			try {
				await Update(this.participant.Type, this.participant.Id, {
					recordstate: {
						id: '9e817a63-452c-e811-8320-7824af3b452f',
						logicalname: 'lookuprecordstate'
					}
				}, null, { syncProcessMode: 'All' });
				const roomType = this.room.Type || this.room.logicalname;
				const roomId = this.room.Id || this.room.id;
				const roomPathTale = roomType === 'dealroom' ? 'deal-rooms' : 'collaboration-rooms';
				const roomPath = `${getBaseRouteUrl(this.$route)}/${roomPathTale}`;
				this.$root.$emit(`hideRow.${roomType}.${roomId}`, true);
				sc.events.emit('dialog.info', {
					title: await this.localizationProvider.getLabelForCurrentLanguage('common.dictionary.success'),
					message: await this.localizationProvider.getLabelForCurrentLanguage('common.dictionary.successLeaveRoom')
				});
				this.$router.push(roomPath);
				this.$root.$emit('entity.changed', { entity: { logicalname: roomType } });
			} catch (e) {
				const exceptionText = e.toString();
				const message = exceptionText === 'Error: Please make some other Participant Primary and try again.' ? exceptionText.replace('Error: ', '') : exceptionText || this.localizationProvider.localize('errors.errorsHandler.title');

				sc.utils.errorMessage.byMessage(message, e.responseText);
			}
			await showToastOrInfoDialog({
				toastMessageKey: this.options.params.toastMessageKey,
				informationDialogTextKey: this.options.params.informationDialogTextKey,
				options: { context: this }
			});
			this.close();
		},
		onCancel() {
			this.close();
		},
		close() {
			this.$parent.$emit('close');
		},
		async getParticipant(selection, userId) {
			const filters = [
				sc.classes.get('offsetSize.filter', 1).fillQuery(),
				sc.classes.get('termFacet.filter', {
					logicalName: 'chatparticipantcontactidcontact.systemuserid.id',
					query: [userId]
				}).fillQuery(),
				sc.classes.get('selectedFields.filter', [
					{ logicalname: 'chatparticipantchatidchat.dealroomid' },
					{ logicalname: 'chatparticipantchatidchat.collaborationroomid' }
				]).fillQuery()
			];
			const selectionId = selection.Id || selection.id;
			const selectionType = selection.Type || selection.logicalname;
			switch (selectionType) {
			case 'dealroom': {
				filters.push(sc.classes.get('termFacet.filter', {
					logicalName: 'chatparticipantchatidchat.dealroomid.id',
					query: [selectionId]
				}).fillQuery());
				break;
			}
			case 'collaborationroom': {
				filters.push(sc.classes.get('termFacet.filter', {
					logicalName: 'chatparticipantchatidchat.collaborationroomid.id',
					query: [selectionId]
				}).fillQuery());
				break;
			}
			case 'chatparticipant': {
				filters.push(sc.classes.get('termFacet.filter', {
					logicalName: 'chatparticipantid',
					query: [selectionId]
				}).fillQuery());
				break;
			}
			default: {
				const message = this.localizationProvider.localize('errors.errorsHandler.title');
				const messageDetails = this.localizationProvider.localize('errors.noRoomFound');
				sc.utils.errorMessage.byMessage(message, messageDetails);
				this.close();
			}
			}
			const response = await Search(['chatparticipant'], filters);
			return response.Results.pop();
		}
	}
};
</script>
<style src="./leave-room-dialog.less" scoped></style>
