export default class {
	constructor(options, context) {
		this.options = options;
		this.context = context;
	}

	execute() {
		throw new Error('execute() should be defined');
	}
}
