<template>
	<div class="control-numericeditor">
		<div class="input" v-bind:class="{'disabled': !enable, 'invalid': !isValid}">
			<div class="actions before">
				<div class="required-mark" v-if="showRequiredMark">
					<svg class="svg-icon svg-8 layout-icons-required">
						<use xlink:href="#layout-icons-required"></use>
					</svg>
				</div>
				<span v-if="prefix">{{prefix}}</span>
			</div>
			<input @focus="focused = true" @blur="focused = false" @change="AdjustValue" :maxlength="maxLength" :value="value" type="text" :disabled="!enable" v-bind:class="{'with-prefix' : prefix}" />
			<div class="actions after">
				<span v-if="postfix">{{postfix}}</span>
				<div class="action" @click="clear" v-if="!!value">
					<control-svg-icon :options="{ name: 'layout-icons-textbox-delete' }" />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
/* eslint no-useless-escape: 0 */
import ControlMixins from '@/Components/Editor/Controls/control.mixins';
const ControlSvgIcon = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Images/control-svg-icon');

export default {
	name: 'control-numericeditor',
	mixins: [ControlMixins],
	components: { ControlSvgIcon },
	data() {
		return {
			focused: false,
			prefix: null,
			postfix: null
		};
	},
	async created() {
		this.maxLength = this.options.maxLength !== 0 ? this.options.maxLength : null;
		if (this.options.uom) {
			const propName = this.options.uom === 'money' ? 'prefix' : 'postfix';
			const value = await sc.classes.get('uom.dataProvider').getUomSymbol(null, this.options.uom);
			this.$set(this, propName, value);
		}
	},
	methods: {
		clear() {
			this.value = '';
		},
		AdjustValue(e) {
			const val = e.target.value;
			e.target.value = null;
			const validationRegex = /^\-?(\d*\.)?\d+$/igm;
			const isValid = validationRegex.test(val);
			let adjusted = null;
			if (isValid) {
				adjusted = this.options.precision ? parseFloat(val).toFixed(this.options.precision) : val;
			}
			this.value = adjusted;
		}
	}
};
</script>
<style src="./control-numericeditor.less" scoped></style>
