var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.galleryDialogType, {
    tag: "component",
    attrs: { options: _vm.options, dialogId: _vm.dialogId },
    on: {
      closeDialog: function($event) {
        _vm.$emit("closeDialog")
      },
      removeClose: function($event) {
        _vm.$emit("removeClose")
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }