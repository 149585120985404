var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tags-list" },
    [
      _c(
        "transition-group",
        {
          directives: [
            {
              name: "perfectscroll",
              rawName: "v-perfectscroll",
              value: { suppressScrollX: true },
              expression: "{ suppressScrollX: true }"
            }
          ],
          ref: "list",
          class: ["list", { oneliner: _vm.height < 30 }],
          attrs: { name: "list", tag: "div" }
        },
        _vm._l(_vm.tags, function(tag) {
          return _c("span", { key: tag.Id, staticClass: "tag group-item" }, [
            _c("span", { staticClass: "content" }, [
              tag.prefix
                ? _c("span", { key: tag.Id, staticClass: "prefix" }, [
                    _vm._v("\n\t\t\t\t\t" + _vm._s(tag.prefix) + "\n\t\t\t\t")
                  ])
                : _vm._e(),
              _vm._v("\n\t\t\t\t" + _vm._s(tag.Name) + "\n\t\t\t\t"),
              tag.suffix
                ? _c("span", { key: tag.Id, staticClass: "suffix" }, [
                    _vm._v("\n\t\t\t\t\t" + _vm._s(tag.suffix) + "\n\t\t\t\t")
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "delete-action",
                on: {
                  click: function($event) {
                    _vm.remove(tag)
                  }
                }
              },
              [
                _c("svg", { staticClass: "delete-icon" }, [
                  _c("use", {
                    attrs: { "xlink:href": "#action-icons-options-remove" }
                  })
                ])
              ]
            )
          ])
        })
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.tags.length,
              expression: "!tags.length"
            }
          ],
          staticClass: "no-selected"
        },
        [_vm._v("\n\t\t" + _vm._s(_vm.placeholder || _vm.noSelected) + "\n\t")]
      ),
      _vm._v(" "),
      _vm.$slots.default
        ? _c("div", { staticClass: "after" }, [_vm._t("default")], 2)
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }