var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ms-edit-list create" }, [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ],
      staticClass: "loading-overlay large"
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "list-editor" },
      [
        !_vm.isLoading
          ? [
              _vm.editorTemplate
                ? _c("control", {
                    attrs: {
                      name: _vm.editorTemplate.$type,
                      contentProps: _vm.editorTemplate,
                      evaluationContext: _vm.evaluationContext
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("span", {
                directives: [
                  {
                    name: "localization",
                    rawName: "v-localization",
                    value: { key: "staticListsPanel.editor.searchLookupName" },
                    expression:
                      "{ key: 'staticListsPanel.editor.searchLookupName' }"
                  }
                ],
                staticClass: "h5"
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "intermediate-wrapper" },
                [
                  _c("control-lookup", {
                    attrs: { options: _vm.getLookupOptions() }
                  })
                ],
                1
              )
            ]
          : _vm._e()
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "header" },
      [
        _vm._m(0),
        _vm._v(" "),
        _c("search-field", {
          attrs: {
            placeholderLocalizationKey:
              "common.dictionary.searchSelectedContancts"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "marketspace-marketing-lists" },
      [
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isTableLoading,
              expression: "isTableLoading"
            }
          ],
          staticClass: "loading-overlay large"
        }),
        _vm._v(" "),
        _vm.listTemplate && !_vm.isLoading
          ? _c("control", {
              staticClass: "formcontrol-template",
              attrs: {
                name: _vm.listTemplate.$type,
                contentProps: _vm.listTemplate,
                evaluationContext: _vm.evaluationContext,
                initResults: _vm.membersVisible
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.paginationParams
          ? _c("pagination", {
              attrs: {
                pageSize: _vm.paginationParams.pageSize,
                total: _vm.paginationParams.total,
                from: _vm.paginationParams.from,
                showPaging: _vm.paginationParams.showPaging,
                activePages: _vm.paginationParams.activePages
              }
            })
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "footer-wrapper" }, [
      _c("div", { staticClass: "footer" }, [
        _c("span", { staticClass: "buttons" }, [
          _c("button", {
            directives: [
              {
                name: "localization",
                rawName: "v-localization",
                value: { key: "common.dictionary.buttons.save" },
                expression: "{ key: 'common.dictionary.buttons.save' }"
              },
              {
                name: "data-attr",
                rawName: "v-data-attr",
                value: {
                  title: _vm.options.title,
                  $type: "dialog.button.submit"
                },
                expression:
                  "{title: options.title, $type: 'dialog.button.submit'}"
              }
            ],
            attrs: { type: "button" },
            on: { click: _vm.save }
          }),
          _vm._v(" "),
          _c("button", {
            directives: [
              {
                name: "localization",
                rawName: "v-localization",
                value: { key: "common.dictionary.buttons.cancel" },
                expression: "{ key: 'common.dictionary.buttons.cancel' }"
              },
              {
                name: "data-attr",
                rawName: "v-data-attr",
                value: {
                  title: _vm.options.title,
                  $type: "dialog.button.cancel"
                },
                expression:
                  "{title: options.title, $type: 'dialog.button.cancel'}"
              }
            ],
            staticClass: "cancel",
            attrs: { type: "button" },
            on: { click: _vm.close }
          })
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "region" }, [
      _c("h5", { staticClass: "title" }, [_vm._v("List Content")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }