var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reportingservice-dialog" },
    [
      _vm.isLoading || _vm.isInitialization || _vm.isInitializationMCI
        ? _c(
            "div",
            { staticClass: "reportingservice-overlay loading-overlay large" },
            [
              _vm.isInitialization
                ? _c("div", { staticClass: "initialization-message p13" }, [
                    _vm._v(
                      "Your authorization to Composer Online is in progress. Please wait."
                    )
                  ])
                : _vm.isInitializationMCI
                ? _c("div", { staticClass: "initialization-message p13" }, [
                    _vm._v(
                      "Your authorization to MailChimp is in progress. Please wait."
                    )
                  ])
                : _vm._e()
            ]
          )
        : _vm.isServerError
        ? _c("div", { staticClass: "reportingservice-overlay error-overlay" }, [
            _c("div", { staticClass: "initialization-message p13" }, [
              _vm._v(
                "Couldn't sign in to Composer Online. Please try again later."
              )
            ])
          ])
        : [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.templatelistComponent === null,
                    expression: "templatelistComponent === null"
                  }
                ],
                staticClass: "main-panel"
              },
              [
                _c("div", { staticClass: "left-panel with-preview" }, [
                  _vm.isElementListEmpty
                    ? _c("div", [
                        _c("div", {
                          directives: [
                            {
                              name: "localization",
                              rawName: "v-localization",
                              value: { key: "dialogues.RS4.reports.elements" },
                              expression:
                                "{key: 'dialogues.RS4.reports.elements'}"
                            }
                          ],
                          staticClass: "group-title"
                        }),
                        _vm._v(" "),
                        _c("div", {
                          directives: [
                            {
                              name: "localization",
                              rawName: "v-localization",
                              value: {
                                key: "dialogues.RS4.reports.noelements"
                              },
                              expression:
                                "{key: 'dialogues.RS4.reports.noelements'}"
                            }
                          ],
                          staticClass: "no-elements"
                        })
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isElementListEmpty && _vm.activeGroup
                    ? _c("div", { staticClass: "entities detail" }, [
                        _c("div", { staticClass: "entities-list" }, [
                          _c("div", { staticClass: "records-wrapper" }, [
                            _c("div", { staticClass: "entity-title" }, [
                              _c("div", { staticClass: "back" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "control-button tooltip dialog-back-hover-parent clickable",
                                    on: {
                                      click: function($event) {
                                        _vm.setActiveGroup(null)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass:
                                          "svg-icon svg-12 back-button"
                                      },
                                      [
                                        _c("use", {
                                          attrs: {
                                            "xlink:href":
                                              "#layout-icons-details-header-back"
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "title tooltip" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t" +
                                    _vm._s(_vm.activeGroup.entity) +
                                    "\n\t\t\t\t\t\t\t\t"
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "count" }, [
                                _vm._v(_vm._s(_vm.activeGroup.items.length))
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "clear-all",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      _vm.activeGroup.removeAll(_vm.activeGroup)
                                    }
                                  }
                                },
                                [
                                  _c("span", {
                                    directives: [
                                      {
                                        name: "localization",
                                        rawName: "v-localization",
                                        value: {
                                          key: "dialogues.RS4.reports.clearAll"
                                        },
                                        expression:
                                          "{key: 'dialogues.RS4.reports.clearAll'}"
                                      }
                                    ],
                                    staticClass: "clear clickable"
                                  })
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _vm.activeGroup.items.length
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "perfectscroll",
                                        rawName: "v-perfectscroll",
                                        value: { enable: true },
                                        expression: "{enable: true}"
                                      }
                                    ]
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "entity-data" },
                                      _vm._l(_vm.activeGroup.items, function(
                                        item
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: item.id,
                                            staticClass: "record"
                                          },
                                          [
                                            _c("control", {
                                              attrs: {
                                                name:
                                                  _vm.activeGroup.template
                                                    .$type,
                                                contentProps:
                                                  _vm.activeGroup.template,
                                                evaluationContext: _vm.createEvaluationContext(
                                                  item
                                                )
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "tooltip delete-control",
                                                on: {
                                                  click: function($event) {
                                                    _vm.activeGroup.remove(
                                                      _vm.activeGroup,
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass:
                                                      "svg-icon clickable"
                                                  },
                                                  [
                                                    _c("use", {
                                                      attrs: {
                                                        "xlink:href":
                                                          "#layout-icons-dialog-close"
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      })
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.activeGroup.matchedToTemplate ||
                                    (_vm.activeGroup.itemsCount >
                                      _vm.activeGroup.itemsLimitToGenerateCO &&
                                      _vm.selectedTemplate.isCO),
                                  expression:
                                    "!activeGroup.matchedToTemplate || (activeGroup.itemsCount > activeGroup.itemsLimitToGenerateCO && selectedTemplate.isCO)"
                                }
                              ],
                              staticClass: "entity-overlay"
                            }),
                            _vm._v(" "),
                            _c("div", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.activeGroup.matchedToTemplate,
                                  expression: "!activeGroup.matchedToTemplate"
                                },
                                {
                                  name: "localization",
                                  rawName: "v-localization",
                                  value: {
                                    key: "dialogues.RS4.recordsDontMatch"
                                  },
                                  expression:
                                    "{key: 'dialogues.RS4.recordsDontMatch'}"
                                }
                              ],
                              staticClass: "entity-overlay-text"
                            }),
                            _vm._v(" "),
                            _c("div", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.activeGroup.itemsCount >
                                      _vm.activeGroup.itemsLimitToGenerateCO &&
                                    _vm.selectedTemplate.isCO,
                                  expression:
                                    "activeGroup.itemsCount > activeGroup.itemsLimitToGenerateCO && selectedTemplate.isCO"
                                },
                                {
                                  name: "localization",
                                  rawName: "v-localization",
                                  value: {
                                    key: "dialogues.RS4.coReachedLimitOfRecord"
                                  },
                                  expression:
                                    "{key: 'dialogues.RS4.coReachedLimitOfRecord'}"
                                }
                              ],
                              staticClass: "entity-overlay-text",
                              class: {
                                offset: !_vm.activeGroup.matchedToTemplate
                              }
                            })
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isElementListEmpty && !_vm.activeGroup
                    ? _c("div", [
                        _c("div", {
                          directives: [
                            {
                              name: "localization",
                              rawName: "v-localization",
                              value: { key: "dialogues.RS4.reports.elements" },
                              expression:
                                "{key: 'dialogues.RS4.reports.elements'}"
                            }
                          ],
                          staticClass: "group-title"
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "perfectscroll",
                                rawName: "v-perfectscroll",
                                value: { enable: true },
                                expression: "{enable: true}"
                              }
                            ],
                            staticClass: "entities groups"
                          },
                          _vm._l(_vm.recordsList, function(item) {
                            return _vm.recordsList.length
                              ? _c(
                                  "div",
                                  {
                                    key: item.entityName,
                                    staticClass: "entities-list"
                                  },
                                  [
                                    item.items.length
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "records-wrapper group"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "entity-title clickable",
                                                on: {
                                                  click: function($event) {
                                                    _vm.setActiveGroup(item)
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "title tooltip"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(item.entity) +
                                                        "\n\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "count" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.items.length)
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "clear-all",
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        item.removeAll(item)
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("span", {
                                                      directives: [
                                                        {
                                                          name: "localization",
                                                          rawName:
                                                            "v-localization",
                                                          value: {
                                                            key:
                                                              "dialogues.RS4.reports.clearAll"
                                                          },
                                                          expression:
                                                            "{key: 'dialogues.RS4.reports.clearAll'}"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "clear tooltip"
                                                    })
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("div", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !item.matchedToTemplate,
                                                  expression:
                                                    "!item.matchedToTemplate"
                                                },
                                                {
                                                  name: "localization",
                                                  rawName: "v-localization",
                                                  value: {
                                                    key:
                                                      "dialogues.RS4.recordsDontMatch"
                                                  },
                                                  expression:
                                                    "{key: 'dialogues.RS4.recordsDontMatch'}"
                                                }
                                              ],
                                              staticClass: "entity-overlay-text"
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e()
                          })
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.templatelistComponent && !_vm.isGenerating,
                        expression: "!templatelistComponent && !isGenerating"
                      }
                    ],
                    staticClass: "editor-component"
                  },
                  [
                    _vm.editorOptions.selectedFormat
                      ? _c(_vm.editorComponent, {
                          directives: [
                            {
                              name: "perfectscroll",
                              rawName: "v-perfectscroll",
                              value: { enable: true },
                              expression: "{ enable: true }"
                            }
                          ],
                          key: _vm.templateKey,
                          tag: "component",
                          attrs: {
                            options: _vm.editorOptions,
                            selectedTemplate: _vm.selectedTemplate,
                            stateKey: _vm.stateKey,
                            totalCOTemplates: _vm.totalCOTemplates
                          },
                          on: {
                            close: _vm.close,
                            openTemplateList: _vm.openTemplateList
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.isGenerating
                  ? _c("div", {
                      staticClass:
                        "reportingservice-overlay loading-overlay large"
                    })
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _vm.templatelistComponent
              ? _c("reportingservice-uiw-templateslist", {
                  attrs: { options: _vm.templatelistComponent }
                })
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }