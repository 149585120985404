import { BaseAspect } from './base.aspect';
import { Create, SALEORLEASEVALUES } from '@acx-xms/data-functions/dist';
import crmSyncDataProvider from '../../../../Data/DataProviders/crmSyncDataProvider';

export class ContactUsAfterSaveAspect extends BaseAspect {
	constructor(options) {
		super();
		this.init(options);
		this.stage = 'afterSave';
	}

	init(options) {
		super.init(options, options.evaluationContext);
	}

	async execute(options) {
		try {
			const toastText = options.evaluationContext.eval(this.options.toastText);
			const edgeInquiry = options.saveResult;
			const crmSetting = await crmSyncDataProvider.getCrmSetting();
			this.crmNamespace = crmSetting.Results && crmSetting.Results.length && crmSetting.Results[0].Source.crmnamespace;
			const sfLink = crmSetting.Results && crmSetting.Results.length && crmSetting.Results[0].Source.domainurl;

			const to = [
				options.params.predefinedFields.listingbroker1email,
				options.params.predefinedFields.listingbroker2email,
				options.params.predefinedFields.listingbroker3email
			].filter(Boolean).join(';');

			const listing = options.params.predefinedFields.listingid.name;
			const availablity = options.params.predefinedFields.availabilityid && options.params.predefinedFields.availabilityid.name;
			const listingUrl = replaceWithBrokerConfigParams(sc.utils.mailTo.getLinkToRecord(
				options.params.predefinedFields.listingid,
				null,
				options.options.layoutsRedirectToMain
			));
			const availablityUrl = options.params.predefinedFields.availabilityid && replaceWithBrokerConfigParams(sc.utils.mailTo.getLinkToRecord(
				options.params.predefinedFields.availabilityid,
				null,
				options.options.layoutsRedirectToMain
			));

			const subject = `New Inquiry on ${availablity || listing}`;

			let html = `You have new Inquiry: ${addLink(replaceWithHtmlEntities(edgeInquiry.name), '{{link}}')} ${MARGINS}`;
			html += addField('First Name', edgeInquiry.firstname);
			html += addField('Last Name', edgeInquiry.lastname);
			if (edgeInquiry.phone) html += addField('Phone', edgeInquiry.phone);
			html += addField('Email', addLink(edgeInquiry.email, `mailto:${edgeInquiry.email}`));
			html += addField('Listing', addLink(replaceWithHtmlEntities(listing), listingUrl));
			if (availablity) html += addField('Availability', addLink(replaceWithHtmlEntities(availablity), availablityUrl));
			html += addField('Sale or Lease', SALEORLEASEVALUES[edgeInquiry.saleorlease.id]);
			html += addField('Message', edgeInquiry.message);

			const record = {
				to,
				html,
				subject
			};

			if (this.crmNamespace) {
				this.sfInquiryLogicalName = `${this.crmNamespace}__Inquiry__c`;
				crmSyncDataProvider.pluginName = 'sync';
				const [sfInquiryTypes] = await Promise.all([crmSyncDataProvider.getSfInquiryTypes(this.sfInquiryLogicalName)]);

				if (!(sfInquiryTypes && sfInquiryTypes.length)) {
					// in case there are crmsettings but no salesforce instance is configred which is mainly on local machines
					sc.utils.errorMessage.byResponse('SalesForce instance is not configured');
				} else {
					await crmSyncDataProvider.createSFInquiry({
						crmNamespace: this.crmNamespace,
						sfInquiryLogicalName: this.sfInquiryLogicalName,
						edgeInquiry,
						sfRecord: this.buildSfRecord(edgeInquiry, sfInquiryTypes),
						email: record,
						sfLink
					});
				}
			} else {
				const inquiryLink = sc.utils.mailTo.getLinkToRecord(sc.classes.get('entityReference', edgeInquiry), null, options.options.layoutsRedirectToMain);
				record.html = record.html.replace('{{link}}', replaceWithBrokerConfigParams(inquiryLink));
				await Create('email', record);
			}
			this.fork.emit('toast.open', toastText);
		} catch (error) {
			sc.utils.errorMessage.byResponse(error);
		}
	}

	buildSfRecord(edgeInquiry, sfInquiryTypes, listingOwnerId = null) {
		const mappings = [
			{
				crmField: 'EmailAddress__c',
				edgeField: 'email',
				dataSource: edgeInquiry
			},
			{
				crmField: 'Description__c',
				edgeField: 'message',
				dataSource: edgeInquiry
			},
			{
				crmField: 'FirstName__c',
				edgeField: 'firstname',
				dataSource: edgeInquiry
			},
			{
				crmField: 'Availability__c',
				edgeField: 'Source.sfcrmid',
				dataSource: null
			},
			{
				crmField: 'LastName__c',
				edgeField: 'lastname',
				dataSource: edgeInquiry
			},
			{
				crmField: 'Listing__c',
				edgeField: 'Source.sfcrmid',
				dataSource: null
			},
			{
				crmField: 'SourceSystemNumber__c',
				edgeField: 'Id',
				dataSource: null
			},
			{
				crmField: 'Property__c',
				edgeField: 'Source.sfcrmid',
				dataSource: null
			},
			{
				crmField: 'Telephone__c',
				edgeField: 'phone',
				dataSource: edgeInquiry
			},
			{
				crmField: 'Name',
				edgeField: 'name',
				dataSource: edgeInquiry
			},
			{
				crmField: 'RecordTypeId',
				edgeField: 'saleorlease',
				executeMapping() {
					const edgeSaleOrLeaseId = edgeInquiry.saleorlease && edgeInquiry.saleorlease.id;
					let name;
					switch (edgeSaleOrLeaseId) {
					case '637f7a63-452c-e811-8320-7824af3b452f': {
						name = 'Sale';
						break;
					}
					case '647f7a63-452c-e811-8320-7824af3b452f': {
						name = 'Lease';
						break;
					}
					default:
						return null;
					}
					const sfRecordType = sfInquiryTypes.find(l => l.Name === name);

					if (sfRecordType) return sfRecordType.Id;
				}
			}
		];

		const sfRecord = {};

		mappings.forEach(mapping => {
			let value;
			if (mapping.executeMapping) value = mapping.executeMapping();
			else value = mapping.dataSource ? sc.utils.findProperty(mapping.dataSource, mapping.edgeField) : '';

			if (mapping.crmField.indexOf('__c') > -1) mapping.crmField = `${this.crmNamespace}__${mapping.crmField}`;
			if (value) sfRecord[mapping.crmField] = value;
		});

		sfRecord[`${this.crmNamespace}__InquirySource__c`] = 'Website';
		sfRecord[`${this.crmNamespace}__SourceSystem__c`] = 'MarketSpace';
		if (listingOwnerId) sfRecord.OwnerId = listingOwnerId;

		return sfRecord;
	}
}

const replaceWithHtmlEntities = (str) => {
	return str
		.replace(/&/g, '&amp;')
		.replace(/</g, '&lt;')
		.replace(/>/g, '&gt;');
};

const replaceWithBrokerConfigParams = (str) => {
	return str
		.replace(/MSGuest/, /MarketSpace/)
		.replace(/listing-portal/, /main/)
		.replace(/anonymous/i, '');
};

const MARGINS = '<br/><br/>';
const addField = (field, value) => `<b>${field}:</b> ${value} ${MARGINS}`;
const addLink = (content, link) => `<a href='${link}'>${replaceWithHtmlEntities(content)}</a>`;
