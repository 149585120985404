/* eslint no-useless-escape: 0 */
import { typesDictionary } from './resolver';

function getKeyByValue(object, value) {
	return Object.keys(object).find(key => object[key] === value);
}

// this method converts names from TypeScript classes to xml types, e.g. LinkControl to control.link, etc.
export function adjustTypeToJson(name) {
	return typesDictionary[name];
}

// this method converts names from xml types to TypeScript classes, e.g. control.link to LinkControl, etc.
export function adjustTypeToSchema(name) {
	return getKeyByValue(typesDictionary, name);
}

export function getLookupOptions(items, callback) {
	return {
		isRequired: false,
		placeholder: '',
		fetchData: searchQuery => {
			const filtered = items.filter(item => {
				return item.displayName.toLowerCase().includes(searchQuery.toLowerCase());
			});

			return {
				total: filtered.length,
				items: filtered
			};
		},
		singleSelectionOnArray: callback,
		template: {
			$type: 'entityTemplate.fieldGroup',
			columns: 1,
			control: [
				{
					$type: 'control.label',
					wrap: 'noWrap',
					text:
					{
						$type: 'expression.field',
						name: 'title',
						schema: 'root'
					},
					colspan: 1
				}],
			colspan: 1
		}
	};
}

export function generateSelectedFields(content) {
	const exprssionFields = JSON.stringify(content).split('{\"$type').filter(str => str.includes('expression.field'));
	const selectedFields = [];
	exprssionFields.forEach(str => {
		// TODO: think of excluding fields with schema=root
		let part = extract(str, 'name":"', '"');
		part = cut(part, '.id', false, true);
		part = cut(part, '.logicalname', false, true);
		if (selectedFields.indexOf(part) === -1) {
			selectedFields.push(part);
		}
	});
	return JSON.stringify(selectedFields);
}

function extract(s, prefix, suffix) {
	s = cut(s, prefix, true);
	if (suffix) {
		s = cut(s, suffix, false);
	}
	return s;
}
function cut(s, stringToCut, isPrefix, checkIfStringExist = false) {
	const i = s.indexOf(stringToCut);
	if (i >= 0) {
		return isPrefix ? s.substring(i + stringToCut.length) : s.substring(0, i);
	} else {
		return checkIfStringExist ? s : '';
	}
}
