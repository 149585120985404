<template>
	<div class="search-field-wrapper inline-block"
		:class="{
			focused: searchFocused || isPopUpOpen,
			opened: isPopUpOpen,
			...cssClasses
		}"
	>
		<div class="input-wrapper">
			<input class="search-box"
				spellcheck="false"
				autocomplete="off"
				type="text"
				:placeholder="placeholder"
				:disabled="isDisabled"
				:value="searchQuery"
				@keyup.enter="search"
				@keyup.down="$root.$emit('lookup.keyDown')"
				@keyup.up="$root.$emit('lookup.keyUp')"
				@input="searchWithDebounce"
				@focus="searchFocused = true"
				@blur="onInputBlur"
			/>
		</div>
		<div class="search-icon-wrapper">
			<svg
				class='svg-icon'
				:class="{'disabled-search-icon': isDisabled}"
				@click.stop="() => { search(true) }"
			>
				<use :xlink:href="'#layout-icons-ms-search'" class='layout-icons-ms-search'></use>
			</svg>
			<svg
				v-show="searchQuery && options.withCrossIcon"
				class="svg-icon svg-20 clear-button"
				@click="clearSearchBox"
			>
				<use xlink:href="#layout-icons-textbox-delete" class="layout-icons-textbox-delete" />
			</svg>
		</div>
	</div>
</template>
<script>
import LookupMixins from './lookup.mixins';

export default {
	name: 'control-lookup',
	mixins: [LookupMixins],
	props: {
		cssClasses: {
			type: Object,
			default: () => ({})
		},
		isDisabled: {
			type: Boolean,
			default: false
		}
	},
	watch: {
		isDisabled(newVal) {
			if (newVal) {
				this.searchQuery = '';
			}
		}
	}
};
</script>
<style src="@/Components/ComponentSet/SearchResults/search-field/search-field.less" scoped></style>
