var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "convert-inquiry-dialog" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "perfectscroll",
            rawName: "v-perfectscroll",
            value: { enable: true },
            expression: "{enable: true}"
          }
        ],
        staticClass: "body"
      },
      [
        _c("div", { staticClass: "section" }, [
          _c("h5", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.contact.displayName))
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "option" },
            [
              _c("div", { staticClass: "input-radio-wrapper" }, [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.contact.recordOption,
                        expression: "contact.recordOption"
                      }
                    ],
                    attrs: { type: "radio" },
                    domProps: {
                      value: "new",
                      checked: _vm._q(_vm.contact.recordOption, "new")
                    },
                    on: {
                      change: function($event) {
                        _vm.$set(_vm.contact, "recordOption", "new")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("span", {
                    directives: [
                      {
                        name: "localization",
                        rawName: "v-localization",
                        value: { key: "convertInquiryDialog.createNew" },
                        expression: "{ key: 'convertInquiryDialog.createNew' }"
                      }
                    ],
                    staticClass: "label"
                  })
                ])
              ]),
              _vm._v(" "),
              _vm.editorTemplate
                ? _c("control", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.contact.recordOption === "new",
                        expression: "contact.recordOption==='new'"
                      }
                    ],
                    attrs: {
                      name: _vm.editorTemplate.$type,
                      contentProps: _vm.editorTemplate,
                      evaluationContext: _vm.evaluationContext
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "option" },
            [
              _c("div", { staticClass: "input-radio-wrapper" }, [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.contact.recordOption,
                        expression: "contact.recordOption"
                      }
                    ],
                    attrs: { type: "radio" },
                    domProps: {
                      value: "existing",
                      checked: _vm._q(_vm.contact.recordOption, "existing")
                    },
                    on: {
                      change: function($event) {
                        _vm.$set(_vm.contact, "recordOption", "existing")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("span", {
                    directives: [
                      {
                        name: "localization",
                        rawName: "v-localization",
                        value: { key: "convertInquiryDialog.chooseExisting" },
                        expression:
                          "{ key: 'convertInquiryDialog.chooseExisting' }"
                      }
                    ],
                    staticClass: "label"
                  })
                ])
              ]),
              _vm._v(" "),
              _vm.contact.recordOption === "existing"
                ? _c("control-lookup-editor", {
                    class: { invalid: !_vm.contact.isValid },
                    attrs: {
                      showRequired: true,
                      evaluationContext: _vm.evaluationContext,
                      options: _vm.getLookupOptions(_vm.contact)
                    },
                    model: {
                      value: _vm.contact.selectedRecord,
                      callback: function($$v) {
                        _vm.$set(_vm.contact, "selectedRecord", $$v)
                      },
                      expression: "contact.selectedRecord"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "section" }, [
          _c("h5", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.preference.displayName))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "option" }, [
            _c("div", { staticClass: "input-radio-wrapper" }, [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.preference.recordOption,
                      expression: "preference.recordOption"
                    }
                  ],
                  attrs: { type: "radio" },
                  domProps: {
                    value: "new",
                    checked: _vm._q(_vm.preference.recordOption, "new")
                  },
                  on: {
                    change: function($event) {
                      _vm.$set(_vm.preference, "recordOption", "new")
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", {
                  directives: [
                    {
                      name: "localization",
                      rawName: "v-localization",
                      value: { key: "convertInquiryDialog.createNew" },
                      expression: "{ key: 'convertInquiryDialog.createNew' }"
                    }
                  ],
                  staticClass: "label"
                })
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "option" },
            [
              _c("div", { staticClass: "input-radio-wrapper" }, [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.preference.recordOption,
                        expression: "preference.recordOption"
                      }
                    ],
                    attrs: { type: "radio" },
                    domProps: {
                      value: "existing",
                      checked: _vm._q(_vm.preference.recordOption, "existing")
                    },
                    on: {
                      change: function($event) {
                        _vm.$set(_vm.preference, "recordOption", "existing")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("span", {
                    directives: [
                      {
                        name: "localization",
                        rawName: "v-localization",
                        value: { key: "convertInquiryDialog.chooseExisting" },
                        expression:
                          "{ key: 'convertInquiryDialog.chooseExisting' }"
                      }
                    ],
                    staticClass: "label"
                  })
                ])
              ]),
              _vm._v(" "),
              _vm.preference.recordOption === "existing"
                ? _c("control-lookup-editor", {
                    class: { invalid: !_vm.preference.isValid },
                    attrs: {
                      showRequired: true,
                      evaluationContext: _vm.evaluationContext,
                      options: _vm.getLookupOptions(_vm.preference)
                    },
                    model: {
                      value: _vm.preference.selectedRecord,
                      callback: function($$v) {
                        _vm.$set(_vm.preference, "selectedRecord", $$v)
                      },
                      expression: "preference.selectedRecord"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.showWarning &&
                      _vm.preference.recordOption === "existing",
                    expression:
                      "showWarning&&preference.recordOption==='existing'"
                  },
                  {
                    name: "localization",
                    rawName: "v-localization",
                    value: { key: "convertInquiryDialog.warningPreference" },
                    expression:
                      "{ key: 'convertInquiryDialog.warningPreference' }"
                  }
                ],
                staticClass: "warning"
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "option" }, [
            _c("div", { staticClass: "input-radio-wrapper" }, [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.preference.recordOption,
                      expression: "preference.recordOption"
                    }
                  ],
                  attrs: { type: "radio" },
                  domProps: {
                    value: "no",
                    checked: _vm._q(_vm.preference.recordOption, "no")
                  },
                  on: {
                    change: function($event) {
                      _vm.$set(_vm.preference, "recordOption", "no")
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", {
                  directives: [
                    {
                      name: "localization",
                      rawName: "v-localization",
                      value: {
                        key: "convertInquiryDialog.doNotConvertToPrefrence"
                      },
                      expression:
                        "{ key: 'convertInquiryDialog.doNotConvertToPrefrence' }"
                    }
                  ],
                  staticClass: "label"
                })
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "section" }, [
          _c("h5", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.collaborationroom.displayName))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "option" }, [
            _c("div", { staticClass: "input-radio-wrapper" }, [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.collaborationroom.recordOption,
                      expression: "collaborationroom.recordOption"
                    }
                  ],
                  attrs: { type: "radio" },
                  domProps: {
                    value: "new",
                    checked: _vm._q(_vm.collaborationroom.recordOption, "new")
                  },
                  on: {
                    change: function($event) {
                      _vm.$set(_vm.collaborationroom, "recordOption", "new")
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", {
                  directives: [
                    {
                      name: "localization",
                      rawName: "v-localization",
                      value: { key: "convertInquiryDialog.createNew" },
                      expression: "{ key: 'convertInquiryDialog.createNew' }"
                    }
                  ],
                  staticClass: "label"
                })
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "option" },
            [
              _c("div", { staticClass: "input-radio-wrapper" }, [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.collaborationroom.recordOption,
                        expression: "collaborationroom.recordOption"
                      }
                    ],
                    class: {
                      "disabled-radio": _vm.preference.recordOption === "new"
                    },
                    attrs: {
                      type: "radio",
                      disabled: _vm.preference.recordOption === "new"
                    },
                    domProps: {
                      value: "existing",
                      checked: _vm._q(
                        _vm.collaborationroom.recordOption,
                        "existing"
                      )
                    },
                    on: {
                      change: function($event) {
                        _vm.$set(
                          _vm.collaborationroom,
                          "recordOption",
                          "existing"
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("span", {
                    directives: [
                      {
                        name: "localization",
                        rawName: "v-localization",
                        value: { key: "convertInquiryDialog.chooseExisting" },
                        expression:
                          "{ key: 'convertInquiryDialog.chooseExisting' }"
                      }
                    ],
                    class: [
                      "label",
                      {
                        "disabled-label": _vm.preference.recordOption === "new"
                      }
                    ]
                  })
                ])
              ]),
              _vm._v(" "),
              _vm.collaborationroom.recordOption === "existing"
                ? _c("control-lookup-editor", {
                    class: { invalid: !_vm.collaborationroom.isValid },
                    attrs: {
                      showRequired: true,
                      evaluationContext: _vm.evaluationContext,
                      options: _vm.getLookupOptions(_vm.collaborationroom)
                    },
                    model: {
                      value: _vm.collaborationroom.selectedRecord,
                      callback: function($$v) {
                        _vm.$set(_vm.collaborationroom, "selectedRecord", $$v)
                      },
                      expression: "collaborationroom.selectedRecord"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "option" }, [
            _c("div", { staticClass: "input-radio-wrapper" }, [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.collaborationroom.recordOption,
                      expression: "collaborationroom.recordOption"
                    }
                  ],
                  attrs: { type: "radio" },
                  domProps: {
                    value: "no",
                    checked: _vm._q(_vm.collaborationroom.recordOption, "no")
                  },
                  on: {
                    change: function($event) {
                      _vm.$set(_vm.collaborationroom, "recordOption", "no")
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", {
                  directives: [
                    {
                      name: "localization",
                      rawName: "v-localization",
                      value: { key: "convertInquiryDialog.doNotConvertToCR" },
                      expression:
                        "{ key: 'convertInquiryDialog.doNotConvertToCR' }"
                    }
                  ],
                  staticClass: "label"
                })
              ])
            ])
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c("button", {
        directives: [
          {
            name: "localization",
            rawName: "v-localization",
            value: { key: "common.dictionary.buttons.cancel" },
            expression: "{ key: 'common.dictionary.buttons.cancel'}"
          }
        ],
        staticClass: "cancel",
        on: { click: _vm.close }
      }),
      _vm._v(" "),
      _c("button", {
        directives: [
          {
            name: "localization",
            rawName: "v-localization",
            value: { key: "common.dictionary.buttons.save" },
            expression: "{ key: 'common.dictionary.buttons.save'}"
          }
        ],
        on: { click: _vm.onSubmit }
      })
    ]),
    _vm._v(" "),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ],
      staticClass: "loading-overlay"
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }