var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "message-wrapper",
      class: {
        own: _vm.message.Source.ownerid.id == _vm.currentUserId,
        unread: _vm.message.Source.unread,
        "text-center": _vm.message.Source.messagetype == "Action"
      }
    },
    [
      _vm.message.Source.messagetype == "Message"
        ? _c("div", [
            _c("div", { staticClass: "svg-thumbnail avatar" }, [
              _c("img", { attrs: { src: _vm.imgUrl } })
            ]),
            _vm._v(" "),
            _c("svg", { staticClass: "svg-icon corner" }, [
              _c("use", {
                attrs: {
                  "xlink:href": "#layout-icons-chat-ext-corner-combined"
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "message" }, [
              _vm.message.Source.chatmessageparentchatmessageidchatmessage
                ? _c(
                    "div",
                    { staticClass: "quote-message" },
                    [
                      _c("quote", {
                        key:
                          _vm.message.Source
                            .chatmessageparentchatmessageidchatmessage
                            .chatmessageid,
                        attrs: {
                          message: {
                            text:
                              _vm.message.Source
                                .chatmessageparentchatmessageidchatmessage
                                .messagetext,
                            createdon:
                              _vm.message.Source
                                .chatmessageparentchatmessageidchatmessage
                                .createdonfromui,
                            createBy:
                              _vm.message.Source
                                .chatmessageparentchatmessageidchatmessage
                                .contactname
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "first-line" },
                [
                  _c("div", { staticClass: "info" }, [
                    _vm.message.parsedMessage
                      ? _c(
                          "p",
                          { staticClass: "p2" },
                          _vm._l(_vm.createBy, function(item, index) {
                            return _c(
                              "span",
                              {
                                key: index,
                                class: item.highlight && "search-highlight"
                              },
                              [_vm._v(_vm._s(item.message))]
                            )
                          })
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.message.parsedMessage
                      ? _c("p", { staticClass: "p2" }, [
                          _vm._v(_vm._s(_vm.createBy))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("p", { staticClass: "p6" }, [_vm._v(_vm._s(_vm.date))])
                  ]),
                  _vm._v(" "),
                  _vm.hasWriteAccess
                    ? _c("ActionMenu", {
                        attrs: {
                          options: _vm.actionMenuOptions,
                          evaluationContext: _vm.evaluationContext,
                          record: _vm.message
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _vm.message.parsedMessage
                ? _c(
                    "p",
                    { staticClass: "message-content p1" },
                    _vm._l(_vm.message.Source.messagetext, function(
                      item,
                      index
                    ) {
                      return _c(
                        "span",
                        {
                          directives: [
                            { name: "parselinks", rawName: "v-parselinks" }
                          ],
                          key: index,
                          class: item.highlight && "search-highlight"
                        },
                        [_vm._v(_vm._s(item.message))]
                      )
                    })
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.message.parsedMessage
                ? _c("p", {
                    directives: [
                      { name: "parselinks", rawName: "v-parselinks" }
                    ],
                    staticClass: "message-content p1",
                    domProps: {
                      textContent: _vm._s(_vm.message.Source.messagetext)
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.currentUserId === _vm.message.Source.ownerid.id
                ? _c("svg", { staticClass: "svg-icon status" }, [
                    _c("use", {
                      attrs: { "xlink:href": "#layout-icons-loader_sent" }
                    })
                  ])
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.message.Source.messagetype == "Action" && !_vm.message.parsedMessage
        ? _c("div", { staticClass: "action" }, [
            _c("span", { staticClass: "message-text" }, [
              _c("b", { staticClass: "contact-name" }, [
                _vm._v(_vm._s(_vm.message.Source.contactname))
              ]),
              _vm._v(
                "\n\t\t\t" + _vm._s(_vm.message.Source.messagetext) + "\n\t\t"
              )
            ])
          ])
        : _vm.message.Source.messagetype == "Action" &&
          _vm.message.parsedMessage
        ? _c("div", { staticClass: "action" }, [
            _c(
              "div",
              { staticClass: "contact-name" },
              _vm._l(_vm.message.Source.contactname, function(item, index) {
                return _c(
                  "span",
                  { key: index, class: item.highlight && "search-highlight" },
                  [_vm._v(_vm._s(item.message))]
                )
              })
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "message-text" },
              _vm._l(_vm.message.Source.messagetext, function(item, index) {
                return _c(
                  "span",
                  { key: index, class: item.highlight && "search-highlight" },
                  [_vm._v(_vm._s(item.message))]
                )
              })
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }