var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-step" }, [
    _vm.statuses.finished
      ? _c("div", { staticClass: "list-content" }, [
          _c("div", { staticClass: "control-tableView inner-search-results" }, [
            _c("div", { staticClass: "tableview-header" }, [
              _c("div", { staticClass: "cell first-column" }, [
                _c("span", {
                  directives: [
                    {
                      name: "localization",
                      rawName: "v-localization",
                      value: { key: "import.recordsCreated" },
                      expression: "{ key: 'import.recordsCreated'}"
                    }
                  ],
                  staticClass: "label-header"
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "cell second-column" }, [
                _c("span", {
                  directives: [
                    {
                      name: "localization",
                      rawName: "v-localization",
                      value: { key: "import.recordsUpdated" },
                      expression: "{ key: 'import.recordsUpdated'}"
                    }
                  ],
                  staticClass: "label-header"
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "cell third-column" }, [
                _c("span", {
                  directives: [
                    {
                      name: "localization",
                      rawName: "v-localization",
                      value: { key: "import.recordsSkipped" },
                      expression: "{ key: 'import.recordsSkipped'}"
                    }
                  ],
                  staticClass: "label-header"
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tableview-content" }, [
              _c("div", { staticClass: "content-scroll-wrapper" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "table-cell first-column" }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v(_vm._s(_vm.statuses.created))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "table-cell second-column" }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v(_vm._s(_vm.statuses.updated))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "table-cell third-column" }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v(_vm._s(_vm.statuses.skipped))
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "message-success" }, [
            _c("span", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: { key: "import.congratulations" },
                  expression: "{ key: 'import.congratulations'}"
                }
              ],
              staticClass: "title-success"
            }),
            _vm._v(" "),
            _c("span", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: { key: "import.importComplete" },
                  expression: "{ key: 'import.importComplete'}"
                }
              ],
              staticClass: "title-success"
            })
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c("div", { staticClass: "buttons" }, [
        !_vm.options.finalStepButtonLabel
          ? _c("button", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: { key: "import.close" },
                  expression: "{key: 'import.close'}"
                }
              ],
              staticClass: "cancel",
              attrs: { type: "button" },
              on: { click: _vm.close }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.options.finalStepButtonLabel
          ? _c(
              "button",
              { attrs: { type: "button" }, on: { click: _vm.close } },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.options.finalStepButtonLabel) +
                    "\n            "
                )
              ]
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }