var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "lookup-folder-tree-view": true, invalid: _vm.invalid } },
    [
      _c("div", { staticClass: "field-wrapper" }, [
        _c("div", { staticClass: "field-title" }, [
          _vm.showRequiredMark
            ? _c("div", { staticClass: "required-mark" }, [
                _c(
                  "svg",
                  { staticClass: "svg-icon svg-8 layout-icons-required" },
                  [
                    _c("use", {
                      attrs: { "xlink:href": "#layout-icons-required" }
                    })
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("p", [_vm._v("Find in your documents")])
        ])
      ]),
      _vm._v(" "),
      _vm.visible
        ? _c("component-search-results", {
            tag: "component",
            attrs: { options: _vm.options }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }