<template>
	<control-lookup-editor	v-model="wrappedValue"
							:evaluationContext="evaluationContext"
							:options="getLookupOptions()"
							:showRequired="showRequiredMark"
							:class="{invalid: !isValid}"></control-lookup-editor>
</template>
<script>
/* eslint no-mixed-operators: 0 */
import ControlMixins from '@/Components/Editor/Controls/control.mixins';
const ControlLookupEditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Lookup/control-lookup-editor');

export default {
	name: 'control-stringtopicklist',
	mixins: [ControlMixins],
	components: { ControlLookupEditor },
	data() {
		return {
			focused: false,
			picklistitems: null
		};
	},
	computed: {
		wrappedValue: {
			get() {
				return this.value
					? {
						displayName: this.value,
						data: this.value
					}
					: null;
			},
			set(val) {
				if (val) {
					this.value = val.displayName;
					this.displayName = val.displayName;
				} else {
					this.value = null;
					this.displayName = '';
				}
			}
		}
	},
	async created() {
		let itemid = 0;
		const defaultPickList = this.evaluationContext.eval(this.options.defaultPickList);
		if (defaultPickList) {
			this.picklistitems = defaultPickList.map(item => ({
				displayName: item,
				data: { Id: itemid++ }
			}));
		} else {
			const items = await sc.classes.get('settings.dataProvider').get('stringToPicklist-settings');
			this.picklistitems = (((items && items.options || []).find(setting => setting.picklistName === this.options.picklistName.value)) || { option: [] }).option
				.map(item => {
					const evalItem = this.evaluationContext.eval(item);
					return {
						displayName: evalItem,
						data: { Id: itemid++ }
					};
				});
		}

		let val = null;
		if (this.initEntity) {
			val = this.initEntity.Source ? this.initEntity.Source[this.field] : this.initEntity[this.field];
		}
		if (!val && this.defaultValue) {
			val = this.defaultValue;
		}
	},
	methods: {
		getPicklistItemById(id) {
			return this.picklistitems ? this.picklistitems.find(result => id === result.data.Id) : null;
		},
		getPicklistItemByName(name) {
			return this.picklistitems ? this.picklistitems.find(result => name === result.displayName) : null;
		},
		getLookupOptions() {
			return {
				disabled: !this.enable,
				placeholder: '',
				fetchData: (searchQuery) => {
					return Promise.resolve(this.picklistitems).then((items) => {
						const filteredItems = items.filter((item) => { return searchQuery ? item.displayName.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 : item; });
						return {
							total: filteredItems.length,
							items: filteredItems
						};
					});
				},
				singleSelectionOnArray: this.selectItem,
				focusCallback: (val) => { this.focused = val; }
			};
		}
	}
};
</script>
<style src="./control-stringtopicklist.less" scoped></style>
