import { SendRequest } from '@acx-xms/data-functions/dist';

const reportingServicePath = '/api/plugins/endpoint/reportingservice';

function mapTemplates(data) {
	if (data) {
		data.templates = data.templates.map(template => {
			const obj = {
				templateIds: [{
					templateId: template.templateId,
					color: template.properties.color
				}],
				isCO: false,
				inputTypes: template.properties.inputTypes,
				templateName: template.templateName,
				targetOutputFormats: template.properties.targetOutputFormats,
				preview: `${reportingServicePath}/images?entityType=templates&id=${template.templateId}&imageSize=thumbnail&configKey=${sc.cluster.configKey}`
			};
			return obj;
		});
	}
	return data;
}

function sendRequest(request) {
	let { type, url, body = null, query = {} } = request;
	url = reportingServicePath + '/' + url;
	query.configKey = sc.cluster.configKey;

	return SendRequest(url, type, body, null, query);
}

export default {
	filterMapping: {
		inputType: {
			sectionName: 'Input Type',
			targetPath: 'properties.inputTypes'
		},
		country: {
			sectionName: 'Country',
			targetPath: 'properties.country'
		},
		language: {
			sectionName: 'Language',
			targetPath: 'properties.language'
		}
	},

	reportingDefaultParamsKey: 'reportingDefaultParams',

	async searchTemplates(filter) {
		try {
			const results = await sendRequest({
				type: 'post',
				url: 'searchtemplates',
				body: filter
			});
			return mapTemplates(results);
		} catch (e) {
			return {
				templates: [],
				totalCount: 0
			};
		}
	},

	async generateReport(params, selectionData, settings) {
		const offset = (new Date().getTimezoneOffset() / 60);

		const body = {
			publicShared: settings.publicShared,
			reportFormat: params['output-format'].toUpperCase(),
			reportName: params['output-file-name'],
			templateId: params.templateId,
			tenantShared: settings.tenantShared,
			timeToLive: 6,
			timeZone: 'Etc/GMT' + (offset > 0 ? '-' : '+') + Math.abs(offset)
		};

		const generateParams = {};

		if (selectionData.items.length) {
			selectionData.items.forEach(item => {
				generateParams[`${item.entityName}-id`] = item.items.map(i => { return i.id; });
			});
		}

		body.generateParams = generateParams;
		body.refreshToken = 'string';

		const currentLanguage = await sc.classes.get('localization.dataProvider').getCurrentLanguage();

		if (currentLanguage) {
			body.generateLocale = currentLanguage.code;
		}

		const request = {
			type: 'post',
			url: ['reports'],
			body
		};

		return sendRequest(request);
	},

	async status(reportId) {
		return new Promise((resolve, reject) => {
			const exec = async () => {
				const status = await sendRequest({
					type: 'get',
					url: ['status'],
					query: { id: reportId }
				});
				switch (status.reportStatus) {
				case 'GENERATED': {
					resolve(reportId);
					break;
				}
				case 'FAILED': {
					reject(reportId);
					break;
				}
				default:
					setTimeout(exec, 5000);
					break;
				}
			};
			exec();
		});
	},

	getReportInfo(id) {
		return sendRequest({
			type: 'get',
			url: 'getreportinfo',
			query: { id }
		});
	},

	getTemplateFilters() {
		return sendRequest({
			type: 'post',
			url: 'gettemplatefilters',
			body: { properties: ['inputTypes', 'country'] }
		});
	},

	getRequestUrl(data, queryParams) {
		let path = [reportingServicePath, data].join('/');
		if (queryParams) {
			const str = [];
			for (const key in queryParams) {
				str.push(`${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`);
			}
			path = `${path}?${str.join('&')}`;
		}
		return path.replace('//', '/');
	}
};
