import { MarketspaceEditCompiteAspect } from './aspects/editComplete.aspect';

export class EditComplete {
	constructor(options) {
		this.options = options;
	}

	installAspects() {
		// after create or update item (listing) -> details
		return [new MarketspaceEditCompiteAspect(this.options)];
	}
}
