var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("control-lookup", {
        attrs: {
          evaluationContext: _vm.evaluationContext,
          options: _vm.getLookupOptions()
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "perfectscroll",
              rawName: "v-perfectscroll",
              value: { enable: true },
              expression: "{ enable: true }"
            }
          ],
          staticClass: "filter-content panel-content"
        },
        [
          _vm.isLoading
            ? _c("div", { staticClass: "loading-overlay" })
            : _c("div", { staticClass: "filter-list-holder" }, [
                _vm.items.length
                  ? _c(
                      "ul",
                      { staticClass: "filter-list" },
                      _vm._l(_vm.items, function(item) {
                        return _c(
                          "li",
                          {
                            directives: [
                              {
                                name: "data-attr",
                                rawName: "v-data-attr",
                                value: {
                                  options: _vm.options,
                                  stateNamespace: item.Name
                                },
                                expression:
                                  "{ options, stateNamespace: item.Name }"
                              }
                            ],
                            key: _vm.recordKey(item),
                            staticClass: "item"
                          },
                          [
                            _c(
                              "div",
                              {
                                on: {
                                  click: function($event) {
                                    _vm.applySearch(item)
                                  }
                                }
                              },
                              [
                                _vm.isShared(item) &&
                                _vm.logicalName !== "sharedSearches"
                                  ? _c("ControlSvgIcon", {
                                      staticClass: "layout-icon",
                                      attrs: {
                                        options: {
                                          name:
                                            "layout-icons-shared-saved-search"
                                        },
                                        evaluationContext: _vm.evaluationContext
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(item.Name) +
                                      "\n\t\t\t\t\t\t"
                                  )
                                ])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            !!_vm.options.menu
                              ? _c("action-menu", {
                                  staticClass: "without-padding without-border",
                                  attrs: {
                                    options: _vm.getMenuParams(item),
                                    record: _vm.getMenuParams(item).selection,
                                    evaluationContext: _vm.evaluationContext
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      })
                    )
                  : _c("div", {
                      directives: [
                        {
                          name: "localization",
                          rawName: "v-localization",
                          value: { key: "filtersPanel.unavailable" },
                          expression: " { key: 'filtersPanel.unavailable' }"
                        }
                      ],
                      staticClass: "warning-message"
                    })
              ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }