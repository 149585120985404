var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "control-optionseditor-wrapper" },
    _vm._l(_vm.optionsItems, function(item) {
      return _c(
        "div",
        {
          key: item.value,
          staticClass: "control-optionseditor",
          on: {
            click: function($event) {
              $event.preventDefault()
              _vm.selectItem(item)
            }
          }
        },
        [
          _c("label", [
            _c("input", {
              attrs: { type: "radio" },
              domProps: { checked: item.value === _vm.value }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "label" }, [_vm._v(_vm._s(item.label))])
          ])
        ]
      )
    })
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }