var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "search-field-wrapper inline-block" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.query,
          expression: "query"
        },
        {
          name: "data-attr",
          rawName: "v-data-attr",
          value: { options: _vm.options },
          expression: "{ options }"
        }
      ],
      staticClass: "search-box",
      attrs: { type: "text", placeholder: "Search" },
      domProps: { value: _vm.query },
      on: {
        keyup: function($event) {
          if (
            !("button" in $event) &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.Submit($event)
        },
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.query = $event.target.value
        }
      }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "search-icon-wrapper" }, [
      _c("svg", { staticClass: "svg-icon", on: { click: _vm.Submit } }, [
        _c("use", {
          staticClass: "layout-icons-ms-search",
          attrs: { "xlink:href": "#layout-icons-ms-search" }
        })
      ]),
      _vm._v(" "),
      _c(
        "svg",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.query && _vm.query.length,
              expression: "query && query.length"
            }
          ],
          staticClass: "svg-icon svg-20 clear-button",
          on: { click: _vm.clearSearchBox }
        },
        [
          _c("use", {
            staticClass: "layout-icons-textbox-delete",
            attrs: { "xlink:href": "#layout-icons-textbox-delete" }
          })
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }