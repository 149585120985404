<template>
	<div class="import-template-dialog">
		<div class="loading-overlay large" v-show="isLoading"></div>
		<div class="select-record">
			<file-to-json class="control-wrapper"
						  v-model="uploadedFileContent"
						  :options="options"
						  :showRequiredMark="!uploadedFileContent"></file-to-json>
		</div>
		<div class="footer">
			<div class="dialog-buttons">
				<button @click="close" class="cancel" type="button" v-localization="{key: 'common.dictionary.buttons.cancel'}"></button>
				<button @click="save" type="button" v-localization="{key: 'dialogs.import'}" :disabled="!this.uploadedFileContent"></button>
			</div>
		</div>
	</div>
</template>
<script>
import { showToastOrInfoDialog, Create } from '@acx-xms/data-functions/dist';
const FileToJson = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/FileUpload/file-to-json');

export default {
	name: 'import-template-dialog',
	components: { FileToJson },
	props: { options: Object },
	data() {
		return {
			isLoading: true,
			uploadedFileContent: null
		};
	},
	async created() {
		this.localizations = sc.classes.get('localization.dataProvider');
		this.dataProvider = sc.classes.get('edge.dataProvider');

		this.isLoading = false;
	},
	methods: {
		async save() {
			this.isLoading = true;
			const requiredFields = this.options.params.requiredFields.split(',');
			const missingFields = requiredFields.filter(f => this.uploadedFileContent[f.toLowerCase()] === void 0);
			if (missingFields.length) {
				let message = '';
				missingFields.forEach(mf => {
					message += `– ${mf}: Field is required \n`;
				});
				sc.events.emit('dialog.error', {
					title: this.localizations.localize('entity.editor.validationError'),
					message
				});
				this.isLoading = false;
				return;
			}

			try {
				JSON.parse(this.uploadedFileContent.content);
			} catch {
				sc.events.emit('dialog.error', {
					title: this.localizations.localize('entity.editor.validationError'),
					message: 'Invalid template content'
				});
				this.isLoading = false;
				return;
			}

			try {
				await Create('configtemplate', this.uploadedFileContent);
				this.options.params.refreshEvent && this.$root.$emit(this.options.params.refreshEvent);
			} catch (error) {
				sc.events.emit('dialog.error', {
					title: this.localizations.localize('entity.editor.validationError'),
					message: error.toString()
				});
			}
			await showToastOrInfoDialog({
				toastMessageKey: this.options.params.toastMessageKey,
				informationDialogTextKey: this.options.params.informationDialogTextKey,
				options: { context: this }
			});
			this.isLoading = false;
			this.close();
		},

		close() {
			this.$parent.$emit('close');
		}
	}
};
</script>
<style src="./import-template-dialog.less" scoped></style>
