<template>
	<div class="reportingservice-editor">
		<div v-perfectscroll="{enable: true}" class="right-panel">
			<div class="selected-template">
				<span v-localization="{key: 'dialogues.RS4.templates.templateLabel'}" class="title p4 pl5"></span>
				<control
					v-if="reportTemplate"
					:name="reportTemplate.$type"
					:contentProps.once="reportTemplate"
					:evaluationContext="evaluationContext"
				/>
				<div v-if="!selectedTemplate" class="thumbnail-annotated" >
					<div class="thumbnail">
						<div class="image-node">
							<svg class="svg-icon">
								<use xlink:href="#layout-icons-menu_templates" class="layout-icons-menu_templates"></use>
							</svg>
						</div>
					</div>
				</div>
				<div class="composer-buttons">
					<button
						v-localization="{key: 'dialogues.RS4.templates.changeTemplateButton'}"
						type="button"
						class="button cancel"
						@click="$emit('openTemplateList')"
					/>
				</div>
				<span
					v-if="!selectedTemplate"
					v-localization="{key: 'dialogues.RS4.templates.noTemplate'}"
					class="noTemplateLabel"
				></span>
			</div>

			<div class="body">
				<div class="template-format">
					<div v-localization="{key: 'dialogues.RS4.reports.formatTitle'}" class="title p4"></div>
					<div class="control">
						<control-lookup-editor-wrapper :options="lookupOptions" :evaluationContext="evaluationContext" />
					</div>
				</div>
				<control
					v-if="editorTemplate"
					:name="editorTemplate.$type"
					:contentProps.once="editorTemplate"
					:evaluationContext="evaluationContext"
				/>
			</div>
		</div>
		<div class='footer'>
			<span class='buttons'>
				<button
					v-data-attr="{title: options.title, $type: 'dialog.button.submit'}"
					v-localization="{key: 'dialogues.RS4.dialog.generate'}"
					type="submit"
					:disabled="disabledButton"
					@click="generate"
				/>
				<button
					v-localization="{key: 'dialogues.RS4.dialog.cancel'}"
					v-data-attr="{title: options.title, $type: 'dialog.button.cancel'}"
					type="button"
					class="cancel"
					@click="close"
				/>
			</span>
		</div>
	</div>
</template>
<script>
import Control from '@/Components/Entity/control';
import dataProvider from '@/Data/DataProviders/reportingserviceDataprovider';
import RS4Utils from './reportingservice.Utils';
import reportingServiceEditorMixin from './reportingservice.editor.mixins.js';
const ControlLookupEditorWrapper = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Editor/Controls/Lookup/control-lookup-editor-wrapper');

export default {
	name: 'reportingservice-uiw-editor',
	components: {
		Control,
		ControlLookupEditorWrapper
	},
	mixins: [reportingServiceEditorMixin],
	props: {
		options: Object,
		selectedTemplate: Object,
		stateKey: String
	},
	data() {
		return {
			availableFormatTranslations: this.options.availableFormatTranslations,
			reportTemplate: null,
			editorTemplate: null,
			isElementListEmpty: false
		};
	},
	computed: {
		// TODO: if remove old Composer templates & functionality - see mci-editor.vue and move computed and watcher to mixin if possible
		disabledButton() {
			return this.isElementListEmpty || !this.hasItemsMatchedToTemplate;
		}
	},
	watch: {
		recordsList: {
			handler: function (newVal, oldVal) {
				this.hasItemsMatchedToTemplate = (newVal || []).filter(list => list.matchedToTemplate).some(list => list.itemsCount);
			},
			deep: true
		}
	},
	created() {
		this.lookupOptions = {
			field: 'output-format',
			defaultValue: this.options.selectedFormat.value,
			lookupNameField: this.options.selectedFormat.displayName,
			requiredValidator: {
				$type: 'control.requiredValidator',
				hideFieldName: false,
				customFieldName: {
					$type: 'expression.localizedLabel',
					key: 'dialogues.RS4.field.email'
				},
				message: {
					$type: 'expression.localizedLabel',
					key: 'common.dictionary.validators.requiredField'
				},
				type: 'required'
			},
			lookupOptions: this.getLookupOptions()
		};
	},
	methods: {
		isElementListEmptyCallback(val) {
			this.isElementListEmpty = val;
		},
		async getTemplate(entity, usage, subtype) {
			return await sc.classes.get('entityConfiguration.dataProvider').getTemplate(entity, usage, subtype);
		},
		async generate() {
			const validationResult = await this.validate();
			if (!validationResult.isValid) {
				this.showValidationError(validationResult.message);
				return;
			}
			const { selectionData, propsFormated, useCO } = await this.prepareDataForArtifact();
			if (useCO) {
				try {
					await this.generateCO(propsFormated, selectionData, true, 24);
				} catch (error) {
					const message = error.message || 'The report generation has been failed. Please, try again.';
					sc.events.emit('vue.dialog.info', {
						title: 'Generate Report',
						message,
						showFooter: false
					});
				}
				this.$parent.$emit('isGenerating', false);
				return;
			}
			try {
				const data = await dataProvider.generateReport(propsFormated, selectionData, this.options.settings);

				if (data && data.reportId) {
					try {
						const reportId = await dataProvider.status(data.reportId);
						this.$parent.$emit('isGenerating', false);
						RS4Utils.downloadReport(reportId, this.localization.localize, dataProvider);
					} catch (e) {
						const reportInfo = await dataProvider.getReportInfo(data.reportId);
						this.$parent.$emit('isGenerating', false);
						reportInfo && reportInfo.generationError &&
								sc.utils.errorMessage.byMessage(reportInfo.generationError);
					}
				}
			} catch (e) {
				this.$parent.$emit('isGenerating', false);
				RS4Utils.parseComposerError(e);
				e.status === 401 && this.close();
			}
		},
		getLookupOptions() {
			return {
				isRequired: true,
				placeholder: '',
				fetchData: searchQuery => {
					searchQuery = searchQuery.toLowerCase();
					const filteredFormats = this.options.availableFormatTranslations.filter(item => {
						return item.displayName.toLowerCase().indexOf(searchQuery) === 0;
					});
					return {
						total: filteredFormats.length,
						items: filteredFormats
					};
				}
			};
		}
	}
};
</script>
<style src="./reportingservice-uiw-editor.less" scoped></style>
