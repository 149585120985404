<template>
	<div class="step">
		<div class="wizard-step-header">
			<div class="wizard-step-header-title">
				Setup
			</div>
		</div>
		<div class="email-campaign-step-root setup-campaign">
			<form class="email-campaign-step-form">
				<div
					v-for="(field, i) in form"
					class="email-campaign-input"
					:key="i"
				>
					<StringEditor
						:type="field.type"
						:fieldTitle="field.title"
						:adValue="field.splitted && getUserDomains"
						:value="field.value"
						:maxLength="+field.limit"
						@input="inputHander(i, ...arguments)"
						@clearAction="clearAction"
					/>
				</div>
			</form>
			<div class="setup-campaign-aletr">* The field is required to send the campaign</div>
		</div>
	</div>
</template>

<script>
import {
	mapActions, mapGetters, mapMutations
} from 'vuex';
import { wizardMixin } from '@/Components/Wizard/Mixins/wizardMixin';
import StringEditor from '@/Components/Editor/Controls/StringEditor/stringeditor';
const namespace = 'wizard';

export default {
	name: 'email-campaign-step-setup',
	mixins: [wizardMixin],
	components: { StringEditor },
	computed: {
		...mapGetters(namespace, ['isPrefilled']),
		...mapGetters('wizard', ['getUserDomains']),
		mcUserData() {
			const setup = this.steps.find(i => i.name === 'setup');
			const hasMcUserEmail = Object.keys(setup.mcUser).length && setup.mcUser.email;
			return {
				name: (hasMcUserEmail ? setup.mcUser.mcName : setup.systemuser.name) || '',
				email: (hasMcUserEmail ? setup.mcUser.email : setup.systemuser.email) || ''
			};
		},
		form() {
			return this.steps.find(step => step.name === 'setup').form;
		},
		email() {
			const value = this.form.email.value || this.mcUserData.email || '';
			const defaultEmail = this.mcUserData && this.mcUserData.email && this.mcUserData.email.split('@')[1];
			if (!value) return ['', ''];
			return value.indexOf('@') > -1 ? value.split('@') : [value, defaultEmail];
		}
	},
	methods: {
		...mapActions(namespace, ['updateFormField']),
		...mapMutations(namespace, ['UPDATE_PREFILLED']),
		inputHander(name, value) {
			const oldValue = this.steps.find(step => step.name === 'setup').form[name].value;
			if (oldValue.trim() !== value.trim()) {
				this.$store.commit('wizard/UPDATE_EDITED_FLAG', true);
			}
			this.updateFormField({
				step: this.activeStepNumber,
				name,
				value: value.trim() || ''
			});
		},
		// onTyping(value) {
		// 	if (!this.isEdited && value) {
		// 		this.$store.commit('wizard/UPDATE_EDITED_FLAG', true);
		// 	}
		// },
		clearAction() {
			this.$store.commit('wizard/UPDATE_EDITED_FLAG', true);
		}
	},
	async mounted() {
		this.setNavigation({
			prev: false,
			next: true
		});
		// set default user data, if user logged in email campaign wizard
		this.loggedData = this.$watch('mcUserData', (newVal, oldVal) => {
			// if hasn't logged yet
			if (this.mcUserData.email) {
				// if it new campaign
				if (!this.withEditMode && this.$route.name === 'wizard' && !this.isPrefilled) {
					const form = this.steps.find(step => step.name === 'setup').form;
					if (!form.from_name.value) {
						this.updateFormField({
							step: this.activeStepNumber,
							name: 'from_name',
							value: this.mcUserData.name,
							splitted: true
						});
					}
					if (!form.email.value) {
						this.updateFormField({
							step: this.activeStepNumber,
							name: 'email',
							value: this.email[0]
						});
					}
					// remove watcher
					this.UPDATE_PREFILLED(true);
					this.loggedData && this.loggedData();
				}
			}
		}, {
			deep: true, /* to watch object values */
			immediate: true /* to set if user logged before */
		});
	}
};
</script>

<style lang='less' scoped>
@import '../../Assets/Styles/default.less';
@import './setup-campaign.less';
</style>
