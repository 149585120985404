<template>
	<div class="action-menu popup-component">
		<div class="rating-tooltip dropdown-wrapper">
			<div class="top-right-triangle"></div>
			<div v-for="(item, index) in ratings" @click="updateRating(item.value)"
				 class="icon" :class="{'active': index < currentActiveIndex || index === currentActiveIndex}"
				 @mouseleave="currentActiveIndex = null"
				 @mouseover="currentActiveIndex = index"
				 :key="index">
				<svg class="svg-icon">
					<use :xlink:href="'#layout-icons-'+ item.iconFilled" v-if="index < currentActiveIndex || index === currentActiveIndex"></use>
					<use :xlink:href="'#layout-icons-'+ item.icon" v-else></use>
				</svg>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'rating-tooltip',
	props: { options: Object },
	data() {
		return {
			currentActiveIndex: null,
			ratings: [
				{
					value: 1,
					icon: 'rating-1',
					iconFilled: 'rating-filled-1'
				},
				{
					value: 2,
					icon: 'rating-2',
					iconFilled: 'rating-filled-2'
				},
				{
					value: 3,
					icon: 'rating-3',
					iconFilled: 'rating-filled-3'
				},
				{
					value: 4,
					icon: 'rating-4',
					iconFilled: 'rating-filled-4'
				},
				{
					value: 5,
					icon: 'rating-5',
					iconFilled: 'rating-filled-5'
				}
			]
		};
	},
	methods: {
		updateRating(value) {
			this.$root.$emit('templatePopup.close.rating');
			this.options.updateRating && this.options.updateRating(value);
		}
	}
};
</script>
<style src="./rating.less" scoped></style>
