<template>
	<div class="dialog-content-wrapper" :class="{'with-name-field': options.params.customName !== void 0}">
		<div class="name" v-if="options.params.customName !== void 0">
			<p>List name</p>
			<!--TODO: rethink structure of our controls
			ideally that would be:
				stringEditor - component with props like required, enable, etc. This component doesnt work with store;
				controlStringEditor - wrapper with store logic that contains stringEditor
				need to apply this structure for all controls to be able to use them separately-->
			<string-editor @input="onNameInputChanged"
				@focused="onFocusedChange"
				:showRequired="!nameValue && !nameFieldFocused"
				:enable="true"
				:isValid="nameFieldValid"
				:dataAttr="{title: options.title, $type: 'dialog.input'}">
			</string-editor>
		</div>

		<div class="search-result-dialog">
			<div class="loading-overlay large" v-show="isLoading"></div>
			<div class="dialog-container">
				<component :is="searchResultComponentName"
						   :options="componentOptions"
						   :evaluationContext="csEvaluationContext"></component>
			</div>
			<div class='footer'>
				<div class="statistic">
					<div class="selection-info error" v-if="validationError">
						<span v-localization="{key: validationError}"></span>
					</div>
				</div>
				<div class='buttons'>
					<span class="button-wrapper" v-if="options.params.modalDialogCallback">
						<button type="button" @click="addListing" v-localization="{key: saveBtnLocalizationKey}" v-data-attr="{title: options.title, $type: 'dialog.button.cancel'}"></button>
					</span>
					<button class="cancel" type="button" @click="cancel" v-localization="{key: cancelBtnText}" v-data-attr="{title: options.title, $type: 'dialog.button.cancel'}"></button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import StringEditor from '@/Components/Editor/Controls/StringEditor/stringeditor';
import ComponentSearchResults from '@/Components/ComponentSet/SearchResults/component-search-results';
import MobileComponentSearchResults from '@/Components/ComponentSet/SearchResults/mobile-component-search-results';
import {
	showToastOrInfoDialog, generateGuid, Delete, Search, CreateBulk
} from '@acx-xms/data-functions/dist';
import { GetUserInfo } from '@/Data/Auth/authentication-service';

export default {
	name: 'ms-addlisting-dialog',
	props: { options: Object },
	components: {
		ComponentSearchResults,
		MobileComponentSearchResults,
		StringEditor
	},
	data() {
		return {
			validationError: null,
			availabilitiesSelection: [],
			listingSelection: [],
			isLoading: false,
			valid: false,
			componentOptions: this.options.params.componentSet.component[0],
			searchResultComponentName: this.options.params.searchResultComponentName || 'component-search-results',
			csEvaluationContext: null,
			nameValue: null,
			nameFieldValid: true,
			nameFieldFocused: false,
			currentSelection: {}
		};
	},
	async created() {
		const btnText = this.options.cancelBtnText || 'cancel';
		this.cancelBtnText = 'crAddListingPlugin.buttons.' + btnText;
		this.fork = sc.events.fork();
		this.fork.on('staticlist.member.delete', this.deleteMember);
		this.saveBtnLocalizationKey = this.options.params.customName !== void 0 ? 'common.dictionary.buttons.save' : 'crAddListingPlugin.buttons.addlisting';
		if (this.options.params.defaultFilters) {
			this.componentOptions.defaultFilters = this.options.params.defaultFilters;
			this.csEvaluationContext = this.options.evaluationContext;
		}
		// todo - rethink
		this.stateNamespace = this.options.params.componentSet.component[0].stateNamespace.value;
		// apply preferences
		this.localization = sc.classes.get('localization.dataProvider');
		if (this.options.params.preference) {
			this.componentOptions.parentData = this.options.params.preference;
		}

		if (this.options.params.parentRef) {
			let crlisting = await this.getListingRecords(this.options.params.parentRef);
			crlisting = crlisting.map(item => ({
				id: item.Id,
				listingid: item.Source.listingid.id,
				availabilities: []
			}));
			if (crlisting.length > 0) {
				const crlistingids = crlisting.map(item => item.id);
				const crAvailability = await this.getAvailabilityRecords(crlistingids);
				crAvailability.forEach(item => {
					const relatedListingid = item.Source.crlistingid.id;
					const crlistingIndex = crlisting.findIndex(listing => listing.id === relatedListingid);
					crlisting[crlistingIndex].availabilities.push(item.Source.availabilityid.id);
				});
				this.$store.commit(this.stateNamespace + '/setSelectedcrResult', crlisting);
			}
		}
	},
	mounted() {
		this.$parent.$on('close', (id) => {
			if (this.fork) {
				this.fork.dispose();
			}
			this.$store.commit(this.stateNamespace + '/openInfoPanel', null);
			this.$store.commit(this.stateNamespace + '/resetDefaultFilters', { entity: 'listing' });
			this.$store.commit(this.stateNamespace + '/resetDefaultFilters', { entity: 'availability' });
			this.$store.commit(this.stateNamespace + '/resetFilters');
			this.$store.commit(this.stateNamespace + '/setSelectedcrResult', []);
		});
		if (this.options.params.validateSelection) {
			this.errorMessage = this.localization.localize('crAddListingPlugin.warning.drWarning');
		} else {
			this.errorMessage = this.localization.localize('crAddListingPlugin.warning.crWarning');
		}
		this.$store.subscribe((mutation, state) => {
			switch (mutation.type) {
			case this.stateNamespace + '/cleanSelection':
			case this.stateNamespace + '/changeSelection':
				this.currentSelection = state[this.stateNamespace].selection;
				if (this.options.params.validateSelection) {
					this.valid = !!this.options.params.validateSelection(this.currentSelection);
				} else {
					this.valid = this.currentSelection && this.currentSelection.listing && this.currentSelection.listing.length > 0;
				}
				break;
			}
		});
	},
	beforeDestroy() {
		if (this.fork) {
			this.fork.dispose();
		}
		this.$store.commit(`${this.stateNamespace}/cleanSelection`);
	},
	methods: {
		async getListingRecords(parentRecord) {
			const filters = [];

			filters.push(sc.classes.get('termFacet.filter', {
				logicalName: 'collaborationroomid.id',
				query: [parentRecord.id]
			}));
			filters.push(sc.classes.get('offsetSize.filter', 9999));
			filters.push(sc.classes.get('selectedFields.filter', [
				{ logicalname: 'listingid.id' }
			]));
			const data = await sc.classes.get('edge.dataProvider').search({
				entities: ['crlisting'],
				filters,
				resultFormat: 'source'
			});

			return data.Results && data.Results.length ? data.Results : [];
		},

		async getAvailabilityRecords(ids) {
			const filters = [];

			filters.push(sc.classes.get('termFacet.filter', {
				logicalName: 'crlistingid.id',
				query: ids
			}));
			filters.push(sc.classes.get('termFacet.filter', {
				logicalName: 'cravailabilityavailabilityidavailability.recordstate.id',
				query: ['8d113fa8-3015-4060-a107-14cedcd19dd3']
			}));
			filters.push(sc.classes.get('offsetSize.filter', 9999));
			filters.push(sc.classes.get('selectedFields.filter', [
				{ logicalname: 'crlistingid.id' },
				{ logicalname: 'availabilityid.id' }
			]));
			const data = await sc.classes.get('edge.dataProvider').search({
				entities: ['cravailability'],
				filters,
				resultFormat: 'source'
			});

			return data.Results && data.Results.length ? data.Results : [];
		},

		onNameInputChanged(value) {
			this.nameValue = value;
		},
		onFocusedChange({ value }) {
			this.nameFieldFocused = value;
		},
		async deleteMember(options) {
			const emitLoading = (isLoading) => {
				options.loadingEvent && sc.events.emit(options.loadingEvent, isLoading);
			};

			try {
				const promiseArr = [];
				if (this.options.evaluationContext.entity.id) {
					emitLoading(true);
					let result = await Search('staticlistmember', [
						sc.classes.get('offsetSize.filter', 1),
						sc.classes.get('termFacet.filter', {
							logicalName: 'entityreference.id',
							query: [options.payload.id]
						}).fillQuery(),
						sc.classes.get('termFacet.filter', {
							logicalName: 'staticlist.id',
							query: [this.options.evaluationContext.entity.id]
						}).fillQuery(),
						sc.classes.get('selectedFields.filter', []).fillQuery()
					]);
					result = result.Total && result.Results[0];
					if (options.payload.logicalname === 'listing') { // if we delete listing - delete all availabilities
						let availabilities = await Search('availability', [
							sc.classes.get('offsetSize.filter', 10000),
							sc.classes.get('termFacet.filter', {
								logicalName: 'listingid.id',
								query: [options.payload.id]
							}).fillQuery(),
							sc.classes.get('selectedFields.filter', []).fillQuery()
						]);
						availabilities = availabilities.Results.length && availabilities.Results.map(a => a.Id);
						if (availabilities.length) {
							const availabilitiesMembers = await Search('staticlistmember', [
								sc.classes.get('offsetSize.filter', 10000),
								sc.classes.get('termFacet.filter', {
									logicalName: 'entityreference.id',
									query: availabilities
								}).fillQuery(),
								sc.classes.get('termFacet.filter', {
									logicalName: 'staticlist.id',
									query: [this.options.evaluationContext.entity.id]
								}).fillQuery(),
								sc.classes.get('selectedFields.filter', []).fillQuery()
							]);
							(availabilitiesMembers.Results || []).forEach(am => {
								promiseArr.push(Delete(am.Type, am.Id));
							});
						}
					}
					promiseArr.push(Delete(result.Type, result.Id));
					await Promise.all(promiseArr);
				}
				const filters = this.$store.getters[this.stateNamespace + '/getFiltersRaw'](options.payload.logicalname);
				Object.keys(filters).forEach(f => {
					if (filters[f] && filters[f].requestLabel === 'Ids') {
						const filteredArray = filters[f].data.filter(d => d !== options.payload.id);
						filters[f].data = filteredArray.length ? filteredArray : ['00000000-0000-0000-0000-000000000000'];
					}
				});
				this.$store.commit(this.stateNamespace + '/replaceAllFilters', {
					entity: options.payload.logicalname,
					filters
				});

				options.refreshEvent && sc.events.emit(options.refreshEvent);
				sc.events.emit('mainSearch.marketspace.staticlists.searching');
			} catch (err) {
				sc.utils.errorMessage.byResponse(err);
			}

			emitLoading(false);
		},
		// todo move to dataProvider
		displayError(message) {
			if (message) {
				sc.events.emit('dialog.open', {
					title: 'Warning',
					allowMinimize: false,
					allowMaximize: false,
					allowPin: false,
					allowClose: false,
					component: 'dialog.info',
					params: {
						message,
						buttons: [
							{ title: 'OK' }
						]
					}
				});
			}
		},
		addListing() {
			if (this.options.params.customName !== void 0) {
				this.nameFieldValid = true;
				if (this.nameValue) {
					const listings = this.$store.getters[this.stateNamespace + '/getSearchResults']('listing');
					const availabilities = this.$store.getters[this.stateNamespace + '/getSearchResults']('availability');
					Object.keys(listings).forEach(listing => {
						if (this.currentSelection.listing) {
							this.currentSelection.listing = [...this.currentSelection.listing, ...listings[listing]];
						} else {
							this.currentSelection.listing = [...listings[listing]];
						}
					});
					Object.keys(availabilities).forEach(availability => {
						if (this.currentSelection.availability) {
							this.currentSelection.availability = [...this.currentSelection.availability, ...availabilities[availability]];
						} else {
							this.currentSelection.availability = [...availabilities[availability]];
						}
					});
				} else {
					this.nameFieldValid = false;
					this.showValidationError('Name: Field is required');
					return;
				}
			} else {
				if (!this.valid) {
					this.displayError(this.errorMessage);
					return;
				}
			}
			// removing partiallySelected listings from selections
			const filteredListings = this.currentSelection.listing.filter(record => {
				const recordState = this.$store.getters[this.stateNamespace + '/isRecordPreselected'](record);
				return recordState === 'default';
			});
			this.currentSelection.listing = filteredListings;
			this.isLoading = true;
			this.options.params.modalDialogCallback(this.currentSelection.listing, this.currentSelection.availability, this.nameValue).then(async () => {
				await showToastOrInfoDialog({
					toastMessageKey: this.options.params.toastMessageKey,
					informationDialogTextKey: this.options.params.informationDialogTextKey,
					options: { context: this }
				});
				this.close();
			}).catch((e) => {
				sc.utils.errorMessage.byMessage(e);
			}).finally(async () => {
				this.isLoading = false;
				await this.sendNotification(this.options.params.parentRef, filteredListings, this.options.params.isNotificationAllowed);
			});
		},
		async sendNotification(parentRecord, selectedRecords, isAllowed) {
			if (!isAllowed || !selectedRecords.length) {
				return;
			}
			const { systemuserid: currentUserRef, fullname: currentUserName } = await GetUserInfo();
			const { Results: chat } = await Search(['chat'], [
				sc.classes.get('offsetSize.filter', 1),
				sc.classes.get('termFacet.filter', {
					logicalName: 'collaborationroomid.id',
					query: [parentRecord.id]
				}).fillQuery(),
				sc.classes.get('selectedFields.filter', [
					{ logicalname: 'chatparticipants.chatparticipantsystemuseridsystemuser.systemuserid' },
					{ logicalname: 'chatparticipants.chatparticipantsystemuseridsystemuser.groupid' },
					{ logicalname: 'chatparticipants.chatid' },
					{ logicalname: 'chatparticipants.contactid' },
					{ logicalname: 'chatparticipants.nonregistered' },
					{ logicalname: 'chatparticipants.chatparticipantchatidchat.collaborationroomid' },
					{ logicalname: 'chatparticipants.systemuserid' },
					{ logicalname: 'chatparticipants.ispending' },
					{ logicalname: 'chatparticipants.nonregistered' },
					{ logicalname: 'chatparticipants.recordstate' }
				]).fillQuery()
			]);
			const inactive = '9e817a63-452c-e811-8320-7824af3b452f';
			const chatParticipants = chat[0].Source.chatparticipants.filter(cp => cp.systemuserid && cp.systemuserid.id !== currentUserRef && !cp.ispending && cp.noregistered !== true && cp.recordstate.id !== inactive);
			const notificationContent = (amountOfRecords) => {
				return amountOfRecords === 1
					? `${currentUserName} has added a new Listing to the room.`
					: `${currentUserName} has added ${amountOfRecords} new Listings to the room.`;
			};
			const notificationTypeId = '2d091365-a01c-4568-af68-416c8b36da37';
			const notificationRequest = (recepient) => {
				return {
					id: generateGuid(),
					method: 'POST',
					url: 'notification',
					body: {
						userInfo: JSON.stringify({
							relatedRecord: recepient.contactid,
							content: {
								chatId: recepient.chatid.id,
								navigationRef: recepient.chatparticipantchatidchat.collaborationroomid
							}
						}),
						headings: { 'en-us': 'New Listing Added' },
						recepientid: recepient.systemuserid,
						contents: { 'en-us': notificationContent(selectedRecords.length) },
						notificationtype: {
							id: notificationTypeId,
							logicalname: 'lookupnotificationtype'
						}
					}
				};
			};
			const notificationDisabledByUsers = [];
			const notificationPromises = chatParticipants.map(cp => {
				const promise = Search(['systemusernotificationssettings'], [
					sc.classes.get('offsetSize.filter', 1),
					sc.classes.get('termFacet.filter', {
						logicalName: 'ownerid.id',
						query: [cp.systemuserid.id]
					}).fillQuery(),
					sc.classes.get('termFacet.filter', {
						logicalName: 'notificationtype.id',
						query: [notificationTypeId]
					}).fillQuery(),
					sc.classes.get('termFacet.filter', {
						logicalName: 'enabled',
						query: [false]
					}).fillQuery()
				]);
				return promise.then(data => {
					if (data.Results.length) {
						notificationDisabledByUsers.push(data.Results[0].Source.ownerid.id);
					}
				});
			});
			await Promise.all(notificationPromises);
			const recepients = !notificationDisabledByUsers.length
				? chatParticipants
				: chatParticipants.filter(cp => {
					let isCorrectRecepient = true;
					notificationDisabledByUsers.forEach(id => {
						if (id === cp.systemuserid.id) {
							isCorrectRecepient = false;
						}
					});
					if (isCorrectRecepient) {
						return cp;
					}
					return false;
				});
			const notificationRequests = [];
			recepients.forEach(cp => {
				notificationRequests.push(notificationRequest(cp));
			});
			await CreateBulk(notificationRequests, { async: true });
		},
		cancel() {
			if (this.options.params.allowCancel && !this.options.params.allowCancel.value) {
				this.displayError(ko.unwrap(this.localization.localize(this.options.params.allowCancel.errorMessageKey)));
			} else {
				this.close();
			}
		},
		close() {
			this.$parent.$emit('close');
		},
		showValidationError(message) {
			sc.events.emit('dialog.error', {
				title: this.localization.localize('entity.editor.validationError'),
				message
			});
		}
	}
};
</script>
<style src="./search-result-dialog.less" scoped></style>
