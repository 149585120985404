import { CreatePreferenceAspect } from './aspects/crCreatePreference.aspect';

export class CreatePreference {
	constructor(options) {
		this.options = options;
	}

	installAspects() {
		return [new CreatePreferenceAspect(this.options)];
	}
}
