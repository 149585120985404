<template>
	<div class="content tab-details">
		<div :class="{'with-message': deactivatedIndicator.visible, 'header': true}">
			<breadcrumbs v-if="breadcrumbs" :breadcrumbs="breadcrumbs"></breadcrumbs>
			<action-menu	:options="actionMenuComponent"
							:record="data"
							v-if="actionMenuComponent && data && !isLoading && !error"
							class="without-padding without-border"
							:evaluationContext="evaluationContext"></action-menu>
			<div v-if="deactivatedIndicator.visible && !isLoading && !error" :class="deactivatedIndicator.cssClass">
				<svg class="svg-icon svg-20" v-if="deactivatedIndicator.icon">
					<use :xlink:href="'#'+deactivatedIndicator.icon"></use>
				</svg>
				<span>
					{{deactivatedIndicator.text}}
				</span>
			</div>
		</div>
		<div v-if="!error" :style="{ visibility: isLoading ? 'hidden': 'visible' }" class="tabs content-scroll-wrapper" v-perfectscroll="{enable: true, onScroll: onScroll, scrollToTopEvent}">
			<div class="tab-switcher h2" v-scrollable-tabs>
				<div class="tab-arrow prev">
					<svg class="svg-icon svg-15">
						<use xlink:href="#layout-icons-ms-arrow-left"></use>
					</svg>
				</div>
				<div class="tabs-container">
					<div :class="{ active: currentTabKey === tab.$key, 'tab-title': true}" v-for="tab in tabs" @click="currentTabKey = tab.$key" :key="tab.$key">
						{{tab.title}}
					</div>
				</div>
				<div class="tab-arrow next">
					<svg class="svg-icon svg-15">
						<use xlink:href="#layout-icons-ms-arrow-right"></use>
					</svg>
				</div>
			</div>
			<div class="tab-content" v-if="!isLoading && tab.$key === currentTabKey" v-bind:class="{'fixed-header': tab.fixedHeader}" v-for="tab in tabs" :key="tab.$key">
				<DetailsRowControl v-for="row in tab.rows" :style="{height: row.height}" :key="row.$key" :evaluationContext="evaluationContext" :row="row"></DetailsRowControl>
			</div>
		</div>
		<div class="no-data" v-if="!isLoading && error" v-localization="{key: 'common.dictionary.recordDetails.noAccess'}"></div>
		<div class="loading-wrapper" v-if="isLoading">
			<div class="loading-overlay"></div>
		</div>
	</div>
</template>
<script>
/* eslint no-useless-call: 0 */
import '@/Bindings/scrollable-tabs.directive';
import ComponentSetDetailsMixin from './componentsetdetails.mixins';
import helperMethods from '@/Components/ComponentSet/component-set-evaluation-context-helper.methods';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';
import { filterAsync } from '@acx-xms/data-functions/dist';

export default {
	name: 'entitytemplate-componentsettabdetails',
	mixins: [ComponentSetDetailsMixin],
	data() {
		return {
			tabs: {},
			currentTabKey: null,
			deactivatedIndicator: null
		};
	},
	async created() {
		this.fork = sc.events.fork();
		this.logicalName = this.$route.params.type;
		this.id = this.$route.params.id;

		await this.trackUserActivity({
			id: this.id,
			logicalname: this.logicalName
		});

		this.$root.$on('refreshCurrentDetails', this.getDetailsData);
		// todo - remove after aspects migrate to vue
		this.fork.on('refreshCurrentDetails', this.getDetailsData);
		this.tabs = this.template.content.tab.map((tab) => {
			return {
				$key: helperMethods.getRandomArbitary(1, 10000000000000000).toFixed(),
				title: helperMethods.eval(this.evaluationContext, tab.title),
				fixedHeader: tab.fixedHeader,
				rows: tab.row.map(row => {
					return {
						...row,
						...{ $key: helperMethods.getRandomArbitary(1, 10000000000000000).toFixed() }
					};
				})
			};
		});
		if (this.tabs.length) {
			this.currentTabKey = this.tabs[0].$key;
		}
		this.deactivatedIndicator = Object.assign({}, this.template.content.deactivatedIndicator);
		this.detailsScroll = helperMethods.eval(this.evaluationContext, this.scrollNamespace);
		this.actionMenuComponent = {
			menu: this.template.content.menu,
			toolbarActions: this.template.content.actionToolbar,
			entityType: this.logicalname,
			$type: 'layoutcomponent.marketspaceActionMenu',
			scrollNamespace: this.detailsScroll
		};
		this.scrollToTopEvent = this.detailsScroll + '.scrollToTop';
		const entityConfig = await sc.classes.get('entityConfiguration.dataProvider').fetchEntity(this.logicalName);
		if (entityConfig.middlewares) {
			const beforeDetailsMiddlewares = await filterAsync((entityConfig.middlewares ? entityConfig.middlewares.middleware : []), async (middle) => {
				return middle && helperMethods.eval(this.evaluationContext, middle.target) === 'beforeDetails' &&
						(middle.enable === void 0 || await helperMethods.evalAsync(this.evaluationContext, middle.enable));
			});
			this._applyMiddlewares(beforeDetailsMiddlewares).then(() => {
				this.getDetailsData();
			});
		} else {
			this.getDetailsData();
		}
		this.$on('.navigateBreadcrumbs', () => {
			const bread = this.breadcrumbs;
			// current
			bread.pop();
			// prev
			const prev = bread.pop();
			if (prev) {
				this.syncBreadcrumbs(null, bread);
				this.$router.push(prev.route);
			}
		});
		this.$root.$on(this.detailsScroll + '.scrollTabToTop', () => {
			this.$emit(this.scrollToTopEvent);
		});
	},
	beforeDestroy() {
		if (this.fork) {
			this.fork.dispose();
		}
		this.$root.$off('refreshCurrentDetails', this.getDetailsData);
		this.$root.$off(this.detailsScroll + '.scrollTabToTop');
		this.evaluationContext = null;
		this.data = null;
		this.tabs = {};
	},
	methods: {
		getDetailsData() {
			this.isLoading = true;

			$.when.apply($, [
				sc.classes.get('entityConfiguration.dataProvider').fetchEntity(this.logicalName),
				sc.classes.get('edge.dataProvider').get(this.logicalName, this.id)
			])
				.done((entityConfig, data) => {
					this.evaluationContext = createEvaluationContext(data);
					this.data = data;
					const title = this.evaluationContext.eval(entityConfig.entityItemDisplayName);
					if (this.template.content.deactivatedIndicator) {
						this.$set(this.deactivatedIndicator, 'visible', this.template.content.deactivatedIndicator.visible
							? this.evaluationContext.eval(this.template.content.deactivatedIndicator.visible)
							: false);
					}
					if (entityConfig.middlewares) {
						this._applyMiddlewares(entityConfig.middlewares.middleware);
					}

					this.syncBreadcrumbs({
						id: this.id,
						logicalName: this.logicalName,
						title
					});
				})
				.always(() => {
					this.isLoading = false;
				})
				.fail((error) => {
					this.error = true;
					this.data = null;
					console.log(ko.unwrap(error));
				});
		}
	}

};
</script>
<style src="@/Components/ComponentSet/SearchResults/search-results.less" scoped></style>
<style src="@/Components/ComponentSet/Details/search-details.less" scoped></style>
<style src="./tab-details.less" scoped></style>
