var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "control-chat" }, [
    !_vm.hideHeader
      ? _c("div", { staticClass: "header" }, [
          _c(
            "div",
            { staticClass: "region" },
            [
              _vm._t("title-slot", [
                _c("div", { staticClass: "title h1 inline-block" }, [
                  _vm._v(_vm._s(_vm.title))
                ])
              ])
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "region" }, [_c("SearchField")], 1)
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.chatSelected
      ? _c("div", { staticClass: "messages-container" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isLoading,
                  expression: "!isLoading"
                },
                {
                  name: "perfectscroll",
                  rawName: "v-perfectscroll",
                  value: { enabled: true, onScroll: _vm.onScroll },
                  expression: "{ enabled: true, onScroll: onScroll }"
                }
              ],
              staticClass: "all-messages"
            },
            [
              !_vm.messages.length
                ? [
                    _c("div", { staticClass: "info p13" }, [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(
                            _vm.lastSearchedQuery
                              ? "There is nothing to show here"
                              : "No messages yet"
                          ) +
                          "\n\t\t\t\t"
                      )
                    ])
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.messages, function(message) {
                return _c("Message", {
                  key: message.Id,
                  attrs: {
                    currentUserId: _vm.currentUserId,
                    message: message,
                    evaluationContext: _vm.evaluationContext,
                    hasWriteAccess: _vm.hasWriteAccess,
                    disabled: _vm.disabled
                  }
                })
              })
            ],
            2
          ),
          _vm._v(" "),
          _vm.isMessageLengthExceeded
            ? _c("div", { staticClass: "alert-length-exceeded" }, [
                _c("svg", { staticClass: "svg-icon warning-sign" }, [
                  _c("use", {
                    attrs: { "xlink:href": "#layout-icons-warning" }
                  })
                ]),
                _vm._v(" "),
                _c("span", [_vm._v("Please shorten the message to send it")])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.refreshing
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLoading,
                      expression: "!isLoading"
                    }
                  ],
                  staticClass: "refresh-wrapper"
                },
                [
                  _c("svg", { staticClass: "svg-icon" }, [
                    _c("use", {
                      attrs: { "xlink:href": "#layout-icons-loader_sending" }
                    })
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasWriteAccess
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLoading,
                      expression: "!isLoading"
                    }
                  ],
                  class: ["chat-footer", { disabled: _vm.disabled }]
                },
                [
                  _vm.quoteMessage !== null
                    ? _c(
                        "div",
                        { staticClass: "quote-message" },
                        [
                          _c("Quote", {
                            key: _vm.quoteMessage.Id,
                            attrs: {
                              message: {
                                text: _vm.quoteMessage.Name,
                                createdonfromui:
                                  _vm.quoteMessage.Source.createdonfromui,
                                createBy: _vm.quoteMessage.Source.nonregistered
                                  ? _vm.quoteMessage.Source.contactname
                                  : (
                                      _vm.quoteMessage.Source
                                        .chatmessagecontactidcontact ||
                                      _vm.quoteMessage.Source
                                        .chatmessagecreatedbysystemuser
                                    ).fullname
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "perfectscroll",
                          rawName: "v-perfectscroll",
                          value: { enabled: true },
                          expression: "{ enabled: true }"
                        }
                      ],
                      staticClass: "text-input-wrapper"
                    },
                    [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.message,
                            expression: "message"
                          }
                        ],
                        ref: "textarea",
                        staticClass: "text-input",
                        attrs: {
                          placeholder: _vm.placeholder,
                          disabled: _vm.disabled
                        },
                        domProps: { value: _vm.message },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.message = $event.target.value
                            },
                            _vm.updateTextArea
                          ],
                          keyup: function($event) {
                            if (!("button" in $event) && $event.keyCode !== 8) {
                              return null
                            }
                            return _vm.removeQuote($event)
                          },
                          keydown: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            if (
                              $event.ctrlKey ||
                              $event.shiftKey ||
                              $event.altKey ||
                              $event.metaKey
                            ) {
                              return null
                            }
                            $event.preventDefault()
                            return _vm.enterHandler($event)
                          }
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "send", on: { click: _vm.sendMessage } },
                    [
                      _c("svg", { staticClass: "svg-icon" }, [
                        _c("use", {
                          attrs: { "xlink:href": "#layout-icons-chat-send" }
                        })
                      ])
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isLoading,
                expression: "isLoading"
              }
            ],
            staticClass: "loading-overlay"
          })
        ])
      : _c("div", { staticClass: "info p13" }, [
          _vm._v(_vm._s(_vm.statusMessage))
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }