var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "step" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "email-campaign-step-root setup-campaign" }, [
      _c(
        "form",
        { staticClass: "email-campaign-step-form" },
        _vm._l(_vm.form, function(field, i) {
          return _c(
            "div",
            { key: i, staticClass: "email-campaign-input" },
            [
              _c("StringEditor", {
                attrs: {
                  type: field.type,
                  fieldTitle: field.title,
                  adValue: field.splitted && _vm.getUserDomains,
                  value: field.value,
                  maxLength: +field.limit
                },
                on: {
                  input: function($event) {
                    var i$1 = arguments.length,
                      argsArray = Array(i$1)
                    while (i$1--) argsArray[i$1] = arguments[i$1]
                    _vm.inputHander.apply(void 0, [i].concat(argsArray))
                  },
                  clearAction: _vm.clearAction
                }
              })
            ],
            1
          )
        })
      ),
      _vm._v(" "),
      _c("div", { staticClass: "setup-campaign-aletr" }, [
        _vm._v("* The field is required to send the campaign")
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "wizard-step-header" }, [
      _c("div", { staticClass: "wizard-step-header-title" }, [
        _vm._v("\n\t\t\tSetup\n\t\t")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }