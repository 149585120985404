var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.isLoading,
          expression: "!isLoading"
        }
      ],
      staticClass: "config-tool-containter"
    },
    [
      _c(
        "div",
        { staticClass: "tools-container", class: { expanded: _vm.isExpanded } },
        [
          _c("div", { staticClass: "header" }, [
            _c("div", { staticClass: "left-menu" }, [
              _c(
                "div",
                {
                  staticClass: "undo",
                  attrs: { disabled: _vm.currentStateIndex <= 0 },
                  on: { click: _vm.undo }
                },
                [
                  _c("span", { staticClass: "icon-holder" }, [
                    _c("svg", { staticClass: "svg-icon svg-15" }, [
                      _c("use", {
                        staticClass: "hover",
                        attrs: { "xlink:href": "#action-icons-undo" }
                      })
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "redo",
                  attrs: {
                    disabled: _vm.state.length - 1 === _vm.currentStateIndex
                  },
                  on: { click: _vm.redo }
                },
                [
                  _c("span", { staticClass: "icon-holder" }, [
                    _c("svg", { staticClass: "svg-icon svg-15" }, [
                      _c("use", {
                        staticClass: "hover",
                        attrs: { "xlink:href": "#action-icons-redo" }
                      })
                    ])
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "right-menu" }, [
              _c(
                "button",
                {
                  staticClass: "save",
                  attrs: { disabled: !_vm.isDirty },
                  on: { click: _vm.save }
                },
                [_vm._v("Save")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "toggle" }, [
                _c(
                  "span",
                  {
                    staticClass: "icon-holder",
                    on: {
                      click: function($event) {
                        _vm.isExpanded = !_vm.isExpanded
                      }
                    }
                  },
                  [
                    _c("svg", { staticClass: "svg-icon svg-15" }, [
                      _c("use", {
                        staticClass: "hover",
                        attrs: {
                          "xlink:href": "#layout-icons-filter-group-collapse"
                        }
                      })
                    ])
                  ]
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "panels-wrapper" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "perfectscroll",
                    rawName: "v-perfectscroll",
                    value: { enable: true, onScroll: _vm.onScroll },
                    expression: "{enable: true, onScroll: onScroll}"
                  }
                ]
              },
              [
                _c(
                  "div",
                  { staticClass: "inner-panels-wrapper" },
                  [
                    _vm.template
                      ? _c("panel", {
                          attrs: {
                            title: "root",
                            template: _vm.template,
                            base: _vm.adjustTypeToSchema(_vm.template["$type"]),
                            metaFields: _vm.metaFields
                          },
                          on: { updateTemplate: _vm.onUpdateTemplate }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          ])
        ]
      ),
      _vm._v(" "),
      !_vm.isExpanded
        ? _c("div", { staticClass: "absolute-toggle-wrapper" }, [
            _c("div", { staticClass: "toggle" }, [
              _c(
                "span",
                {
                  staticClass: "icon-holder",
                  on: {
                    click: function($event) {
                      _vm.isExpanded = !_vm.isExpanded
                    }
                  }
                },
                [
                  _c("svg", { staticClass: "svg-icon svg-15" }, [
                    _c("use", {
                      staticClass: "hover",
                      attrs: { "xlink:href": "#layout-icons-filter-group-open" }
                    })
                  ])
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "preview-container" }, [
        _c("div", { staticClass: "preview-header" }, [
          _c("p", { staticClass: "h2" }, [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _c("p", {
            directives: [
              {
                name: "localization",
                rawName: "v-localization",
                value: { key: "common.dictionary.configToolBeta" },
                expression: "{ key: 'common.dictionary.configToolBeta' }"
              }
            ]
          })
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "perfectscroll",
                rawName: "v-perfectscroll",
                value: { enable: true },
                expression: "{ enable: true }"
              }
            ]
          },
          [
            _vm.template
              ? _c("control", {
                  key: _vm.previewRecord.Id + _vm.templateString,
                  staticClass: "template",
                  attrs: {
                    name: _vm.template.$type,
                    contentProps: _vm.template,
                    evaluationContext: _vm.evaluationContext
                  }
                })
              : _vm._e()
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }