/* eslint no-async-promise-executor: 0 */
import MiddlewareBase from './MiddlewareBase';
import middlewareDataProvider from '@/Data/DataProviders/middlewareDataProvider';
import { getBaseRouteUrl } from '@acx-xms/data-functions/dist';

export default class extends MiddlewareBase {
	execute() {
		let ndasigned = false;
		return new Promise(async (resolve, reject) => {
			try {
				const cpContextWithNDA = await middlewareDataProvider.getParticipantContextWithNDA({
					id: this.context.id,
					logicalname: this.context.logicalName
				});
				if (cpContextWithNDA.Success) {
					const ndaDoc = cpContextWithNDA.ndadoc;
					this.context.chatparticipantInfo = cpContextWithNDA.chatparticipant;
					ndasigned = !!sc.utils.findProperty(cpContextWithNDA.chatparticipant, 'Source.ndasignindate', true);
					if (ndaDoc && !ndasigned && !cpContextWithNDA.isRoomOwner) {
						const dealRoomPath = `${getBaseRouteUrl(this.context.$route)}/deal-rooms`;
						sc.events.fork().emit('vue.dialog.open', {
							title: 'Accept NDA',
							component: 'accept-nda-dialog',
							minimumHeight: 'auto',
							maximizedWidth: '90%',
							maximizedHeight: '100%',
							params: {
								entity: ndaDoc,
								chatparticipant: cpContextWithNDA.chatparticipant,
								usage: 'edit',
								subtype: 'ndaAccept',
								acceptCallback: async (data) => {
									if (data.chatparticipant) {
										this.context.chatparticipantInfo = data.chatparticipant;
										let participant;
										const ndasignindate = sc.utils.findProperty(data.chatparticipant, 'Source.ndasignindate', true);
										const list = this.context.$store.getters.getUserChatParticipants;
										const id = list.findIndex(el => el.chatid === data.chatparticipant.Source.chatid.id);
										if (id > -1) {
											participant = list[id];
											participant.ndasignindate = data.chatparticipant.Source.ndasignindate;
											list[id] = participant;
										} else {
											participant = {
												chatid: data.chatparticipant.Source.chatid.id,
												lastreadmessagedate: null,
												collaborationroomid: null,
												dealroomid: data.chatparticipant.Source.chatparticipantchatidchat.dealroomid.id,
												ndasignindate,
												systemuserid: data.chatparticipant.Source.systemuserid.id
											};
											list.push(participant);
										}
										this.context.$store.commit('setUserChatParticipants', list);
										await middlewareDataProvider.changeParticipantState(this.context.chatparticipantInfo);
										ndasignindate && resolve();
									}
								},
								cancelCallback: () => {
									this.context.$router.push(dealRoomPath);
								},
								closeCallback: () => {
									this.context.$router.push(dealRoomPath);
								},
								saveData: cpContextWithNDA
							}
						});
					} else {
						await middlewareDataProvider.changeParticipantState(this.context.chatparticipantInfo);
						resolve();
					}
				} else {
					reject(cpContextWithNDA);
				}
			} catch (e) {
				this.context.data = null;
				this.context.error = true;
				this.context.isLoading = false;
				reject(e);
			}
		});
	}
}
