<template>
	<div class="drag-and-drop-editor">
		<ControlFileTable v-model="value" :enable="enable" :invalid="!isValid" />
	</div>
</template>
<script>
import ControlMixins from '@/Components/Editor/Controls/control.mixins';
import ControlFileTable from '@/Components/Control/FileUpload/control-file-table';
export default {
	name: 'control-file-table-editor',
	mixins: [ControlMixins],
	props: {
		options: Object,
		evaluationContext: Object
	},
	components: { ControlFileTable }
};
</script>
