var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        {
          class: [
            "filter-group filter-group-toggle-panel",
            {
              selected: _vm.isSelected,
              open: _vm.isExpanded,
              collapsible: _vm.collapsible
            }
          ]
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "data-attr",
                  rawName: "v-data-attr",
                  value: {
                    options: _vm.options,
                    stateNamespace: _vm.stateNamespace
                  },
                  expression: "{ options, stateNamespace }"
                }
              ],
              staticClass: "panel-title panel-opener",
              on: { click: _vm.toggle }
            },
            [
              _c("h3", [_vm._v("\n\t\t\t" + _vm._s(_vm.name) + "\n\t\t")]),
              _vm._v(" "),
              _vm.showSaveSearchButton
                ? _c("ControlSvgIcon", {
                    staticClass: "action-icon",
                    attrs: {
                      options: { name: "action-icons-search-save" },
                      evaluationContext: _vm.evaluationContext
                    },
                    nativeOn: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.saveSearch($event)
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.collapsible
                ? _c("div", { staticClass: "icon-holder" }, [
                    _c("svg", { staticClass: "svg-icon svg-15" }, [
                      _c("use", {
                        staticClass: "hover",
                        attrs: {
                          "xlink:href": "#layout-icons-filter-group-collapse"
                        }
                      })
                    ])
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ul",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isExpanded,
                  expression: "isExpanded"
                }
              ],
              staticClass: "filter-items filter-group-content"
            },
            [
              _c(
                "li",
                { class: ["filter", { open: _vm.isMySearchExpanded }] },
                [
                  _c(
                    "div",
                    {
                      staticClass: "filter-opener items-opener",
                      on: { click: _vm.openMySearches }
                    },
                    [
                      _c("span", { staticClass: "filter-name-truncate" }, [
                        _c("span", [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.texts.mySearches) +
                              "\n\t\t\t\t\t"
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "icon-wrapper" }, [
                        _c("svg", { staticClass: "svg-icon svg-15" }, [
                          _c("use", {
                            staticClass: "hover layout-icons-filter-opener",
                            attrs: {
                              "xlink:href": "#layout-icons-filter-opener"
                            }
                          })
                        ])
                      ])
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "li",
                { class: ["filter", { open: _vm.isSharedSearchExpanded }] },
                [
                  _c(
                    "div",
                    {
                      staticClass: "filter-opener items-opener",
                      on: { click: _vm.openSharedSearches }
                    },
                    [
                      _c("span", { staticClass: "filter-name-truncate" }, [
                        _c("span", [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.texts.sharedWithMe) +
                              "\n\t\t\t\t\t"
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "icon-wrapper" }, [
                        _c("svg", { staticClass: "svg-icon svg-15" }, [
                          _c("use", {
                            staticClass: "hover layout-icons-filter-opener",
                            attrs: {
                              "xlink:href": "#layout-icons-filter-opener"
                            }
                          })
                        ])
                      ])
                    ]
                  )
                ]
              )
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }