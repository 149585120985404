var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "file-upload" }, [
    _c("div", { staticClass: "file-exists-message" }, [
      _vm.showRequiredMark && !_vm.isLoading
        ? _c("div", { staticClass: "required-mark" }, [
            _c("svg", { staticClass: "svg-icon svg-8 layout-icons-required" }, [
              _c("use", { attrs: { "xlink:href": "#layout-icons-required" } })
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoading
        ? _c("div", {
            directives: [
              {
                name: "localization",
                rawName: "v-localization",
                value: { key: "controls.ndaFileEditor.uploadingLbl" },
                expression: "{ key: 'controls.ndaFileEditor.uploadingLbl'}"
              }
            ],
            key: "uploading"
          })
        : _vm.value
        ? _c("div", [_vm._v(_vm._s(_vm.fileName))])
        : _c("div", {
            directives: [
              {
                name: "localization",
                rawName: "v-localization",
                value: { key: "controls.ndaFileEditor.noFileChoosenLbl" },
                expression: "{key: 'controls.ndaFileEditor.noFileChoosenLbl'}"
              }
            ],
            key: "noFileChosen"
          })
    ]),
    _vm._v(" "),
    _c("span", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.value,
          expression: "!value"
        },
        {
          name: "localization",
          rawName: "v-localization",
          value: { key: "controls.ndaFileEditor.chooseFileLbl" },
          expression: "{key:'controls.ndaFileEditor.chooseFileLbl'}"
        }
      ],
      staticClass: "button",
      class: { disabled: _vm.options.disabled },
      attrs: { type: "submit" },
      on: {
        click: function($event) {
          !_vm.options.disabled && _vm.uploadFile()
        }
      }
    }),
    _vm._v(" "),
    _c("span", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.value,
          expression: "value"
        },
        {
          name: "localization",
          rawName: "v-localization",
          value: { key: "controls.ndaFileEditor.removeLbl" },
          expression: "{key:'controls.ndaFileEditor.removeLbl'}"
        }
      ],
      staticClass: "button",
      attrs: { type: "submit" },
      on: { click: _vm.removeFile }
    }),
    _vm._v(" "),
    _c("input", {
      ref: "fileInput",
      staticClass: "file-input",
      attrs: { name: "file", type: "file", accept: ".json" },
      on: { change: _vm.fileUploaded }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }