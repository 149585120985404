<template>
	<div class="image-fullscreen-preview-wrapper" @click.self="close">
		<div class="close-wrapper" @click="close">
			<svg class="svg-icon">
				<use xlink:href='#layout-icons-dialog-close'></use>
			</svg>
		</div>
		<image-wrapper :url="options.url" class="image-wrapper"></image-wrapper>
	</div>
</template>
<script>
const ImageWrapper = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Common/image-wrapper');
export default {
	name: 'image-fullscreen-preview',
	props: { options: Object },
	components: { ImageWrapper },
	methods: {
		close() {
			this.$emit('close');
		}
	}
};
</script>
<style src="./image-fullscreen-preview.less" scoped></style>
