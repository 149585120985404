<template>
	<div class="control-optionseditor-wrapper">
		<div class="control-optionseditor" v-for="item in optionsItems" @click.prevent="selectItem(item)" :key="item.value">
			<label>
				<input type="radio" :checked="item.value === value" />
				<span class="label">{{item.label}}</span>
			</label>
		</div>
	</div>
</template>
<script>
/* eslint no-mixed-operators: 0 */
import ControlMixins from '@/Components/Editor/Controls/control.mixins';

export default {
	name: 'control-optionseditor',
	mixins: [ControlMixins],
	data() {
		return { optionsItems: null };
	},
	async created() {
		const items = await sc.classes.get('settings.dataProvider').get('optionsEditor-settings');
		this.optionsItems = (((items && items.options || []).find(setting => setting.name === this.options.name.value)) || { option: [] }).option
			.map(item => {
				return {
					label: this.evaluationContext.eval(item.label),
					value: this.evaluationContext.eval(item.value)
				};
			});
	},
	methods: {
		selectItem(item) {
			this.value = item.value;
		}
	}
};
</script>
<style src="./control-optionseditor.less" scoped></style>
