var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "accept-nda" }, [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ],
      staticClass: "loading-overlay"
    }),
    _vm._v(" "),
    _c("div", { staticClass: "entity-editor-content" }, [
      _c(
        "div",
        { staticClass: "editor-container" },
        [
          _vm.editorTemplate
            ? _c("control", {
                attrs: {
                  name: _vm.editorTemplate.$type,
                  contentProps: _vm.editorTemplate,
                  evaluationContext: _vm.templateContext
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "nda-accept-checkbox" }, [
        _c(
          "div",
          {
            staticClass: "checkbox-wrapper",
            on: {
              click: function($event) {
                $event.stopPropagation()
                $event.preventDefault()
                _vm.ndachecked = !_vm.ndachecked
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "virtual-checkbox clickable",
                class: _vm.ndachecked && "selected"
              },
              [
                _c("svg", { staticClass: "svg-icon" }, [
                  _c("use", {
                    staticClass: "layout-icons-ms-checkbox",
                    attrs: { "xlink:href": "#layout-icons-ms-checkbox" }
                  })
                ])
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c("span", { staticClass: "accept-nda-label" }, [
          _c("span", {
            directives: [
              {
                name: "localization",
                rawName: "v-localization",
                value: { key: "CreateDealRoomPlugin.acceptNDACheckboxLabel" },
                expression:
                  "{ key: 'CreateDealRoomPlugin.acceptNDACheckboxLabel' }"
              }
            ]
          }),
          _vm._v(" "),
          _c("span", { staticClass: "p2" }, [
            _vm._v(_vm._s(_vm.options.params.saveData.dealroomRec.Name))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c("span", { staticClass: "buttons" }, [
        _c("button", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "CreateDealRoomPlugin.acceptNDA" },
              expression: "{key: 'CreateDealRoomPlugin.acceptNDA'}"
            }
          ],
          attrs: { type: "button", disabled: !_vm.ndachecked },
          on: { click: _vm.accept }
        }),
        _vm._v(" "),
        _c("button", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "common.dictionary.buttons.cancel" },
              expression: "{key: 'common.dictionary.buttons.cancel'}"
            }
          ],
          staticClass: "cancel",
          attrs: { type: "button" },
          on: { click: _vm.cancel }
        })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }