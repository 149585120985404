<template>
	<div class="drag-and-drop-wrapper">
		<div @dragover.prevent="dragover"
			 @dragleave="dragleave"
			 @drop.prevent="drop"
			 class="drag-and-drop"
			 v-bind:class="{'dragover':isDragover, 'disable': !enable, 'invalid': invalid}">
			<input
				type="file"
				multiple id="files"
				@change="onChangeInput"
				:accept="acceptExtensions || '.*'" ref="file"
				v-data-attr="dataAttr"
			/>
			<label for="files">
				<svg class="svg-icon svg-50">
					<use xlink:href='#layout-icons-upload'></use>
				</svg>
				<span class="h4" v-localization="{key: 'controls.dragAndDrop.dropFile.label'}">
				</span>
				<span class="h4" v-localization="{key: 'controls.dragAndDrop.filesLimitation.label'}">
				</span>
			</label>
		</div>
	</div>
</template>
<script>
export default {
	name: 'control-file-draganddrop',
	props: ['value', 'enable', 'invalid', 'acceptExtensions', 'allowedExtensions', 'dataAttr'],
	data() {
		return {
			isDragover: false,
			documentTemplate: null
		};
	},
	async created() {
		this.localization = sc.classes.get('localization.dataProvider');
		this.maxFileNumberError = await this.localization.getLabelForCurrentLanguage('controls.dragAndDrop.maxFileNumber.error');
		this.sizeLimitationMaxError = await this.localization.getLabelForCurrentLanguage('controls.dragAndDrop.sizeLimitationMax.error');
		this.sizeLimitationMinError = await this.localization.getLabelForCurrentLanguage('controls.dragAndDrop.sizeLimitationMin.error');
		this.invalidFileTypeError = await this.localization.getLabelForCurrentLanguage('controls.dragAndDrop.invalidFileType.error');
		this.fileNotSupportedError = await this.localization.getLabelForCurrentLanguage('controls.dragAndDrop.fileNotSupported.error');
	},
	methods: {
		onChangeInput() {
			const newFiles = [...this.$refs.file.files];
			this.$refs.file.value = null;

			this.onChange(newFiles);
		},

		onChange(newFiles) {
			const value = this.value || [];

			if (newFiles.length + value.length > 100) {
				this.showErrorDialog([this.maxFileNumberError]);

				return;
			}

			newFiles = newFiles.map(file => {
				const result = {
					file,
					id: this.getFileId(file),
					errors: this.validateFile(file)
				};

				this.$set(result, 'state', 'notStarted');

				return result;
			}).filter(newFile => {
				return !value.some(oldFile => newFile.id === oldFile.id);
			});

			const fileList = [...value, ...newFiles];

			this.$emit('input', fileList);
		},

		showErrorDialog(errors) {
			sc.events.emit('dialog.error', {
				title: this.localization.localize('entity.editor.validationError'),
				message: errors.join('<br>')
			});
		},

		validateFile(file) {
			const defaultRestrictedExtensions = ['exe', 'bat', 'cms', 'msi', 'com', 'json', 'cmd'];

			const fileSizeMb = file.size / 1024 / 1024;
			const invalidMaxFileSize = fileSizeMb > 50;
			const invalidMinFileSize = fileSizeMb === 0;
			const ext = file.name.split('.').pop().toLowerCase();
			let invalidExtension = false;
			if (this.allowedExtensions && this.allowedExtensions.length) {
				invalidExtension = this.allowedExtensions.indexOf(ext) === -1;
			} else {
				invalidExtension = defaultRestrictedExtensions.indexOf(ext) >= 0;
			}
			const errors = [];

			if (invalidMaxFileSize) {
				errors.push(this.sizeLimitationMaxError);
			}
			if (invalidMinFileSize) {
				errors.push(this.sizeLimitationMinError);
			}
			if (invalidExtension) {
				errors.push(this.invalidFileTypeError);
			}

			return errors;
		},

		dragover() {
			this.isDragover = true;
		},
		dragleave() {
			this.isDragover = false;
		},
		drop(event) {
			this.isDragover = false;
			const files = event.dataTransfer.files;
			const filteredFiles = [];

			for (let i = 0; i < files.length; i++) {
				const f = files[i];
				// Check if file is not folder
				if (f.type || f.size % 4096 !== 0) {
					filteredFiles.push(f);
				}
			}

			if (files.length !== filteredFiles.length) {
				this.showErrorDialog([this.fileNotSupportedError]);
			}

			if (filteredFiles.length) {
				this.onChange(filteredFiles);
			}
		},
		getFileId(file) {
			return `${file.name}_${file.size}`;
		}
	}
};
</script>
<style src="./control-file-draganddrop.less" scoped></style>
