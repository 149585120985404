var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "select-record-dialog" }, [
    _c(
      "div",
      { staticClass: "search-record" },
      [
        _c("span", { staticClass: "h5" }, [_vm._v(_vm._s(_vm.lookupTitle))]),
        _vm._v(" "),
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isLoading,
              expression: "isLoading"
            }
          ],
          staticClass: "loading-overlay large"
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "select-record" },
          [
            _c("control-lookup-editor", {
              attrs: {
                evaluationContext: _vm.options.params.evaluationContext,
                options: _vm.getLookupOptions()
              },
              model: {
                value: _vm.selectedRecord,
                callback: function($$v) {
                  _vm.selectedRecord = $$v
                },
                expression: "selectedRecord"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("Checkbox", {
          attrs: { label: _vm.checkboxTitle },
          model: {
            value: _vm.onlyActiveRecords,
            callback: function($$v) {
              _vm.onlyActiveRecords = $$v
            },
            expression: "onlyActiveRecords"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c("div", { staticClass: "dialog-buttons" }, [
        _c("button", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "common.dictionary.buttons.cancel" },
              expression: "{key: 'common.dictionary.buttons.cancel'}"
            }
          ],
          staticClass: "cancel",
          attrs: { type: "button" },
          on: { click: _vm.close }
        }),
        _vm._v(" "),
        _c("button", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "common.dictionary.buttons.save" },
              expression: "{key: 'common.dictionary.buttons.save'}"
            }
          ],
          attrs: { type: "button", disabled: !_vm.selectedRecord },
          on: { click: _vm.save }
        })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }