<template>
	<div @click="focusInput"
		 class="control-lookup-editor input"
		 :class="{focused: searchFocused || isPopUpOpen, opened: isPopUpOpen, disabled: options.disabled}">
		<div v-show="isLoading" class="loading-overlay"></div>
		<div class="actions before">
			<div class="required-mark" v-if="showRequiredMark">
				<svg class="svg-icon svg-8 layout-icons-required">
					<use xlink:href="#layout-icons-required"></use>
				</svg>
			</div>
		</div>
		<input v-show="false"
			   class="lookup-editor-input p1"
			   :class="{'required': showRequiredMark}"
			   type="text"
			   @keyup.down="$root.$emit('lookup.keyDown')"
			   @keyup.up="$root.$emit('lookup.keyUp')"
			   :value="searchQuery"
			   @focus="onInputFocus"
			   @blur="onInputBlur" />
		<div class="selected-items" @click="toggleOpen">
			<div class="breadcrumbs">
				<div class="breadcrumbs-wrapper">
					<div class="breadcrumb-item" v-for="(item, index) in path" :key="index">
						<div class="title">{{ item }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="actions after clickable" v-show="!options.disabled">
			<div class="icon-wrapper" @click.stop="iconAction">
				<template v-if="customIcon">
					<div class="icon">
						<svg class="svg-icon">
							<use :xlink:href="'#'+customIcon" class=customIcon></use>
						</svg>
					</div>
				</template>
				<template v-else>
					<div class="icon" v-if="!isPopUpOpen">
						<svg class="svg-icon svg-10">
							<use :xlink:href="'#layout-icons-ms-arrow-down'" class='layout-icons-ms-arrow-down'></use>
						</svg>
					</div>
					<div class="icon" v-else @click.stop="closePopUp">
						<svg class="svg-icon svg-10">
							<use :xlink:href="'#layout-icons-ms-arrow-up'" class='layout-icons-ms-arrow-up'></use>
						</svg>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>
<script>
import LookupEditorMixins from './lookup-editor.mixin';
export default {
	name: 'control-lookup-folder',
	props: {
		lookupRootName: String,
		initParentFolderStateKey: String // key to store to obtain initial parent folder in quick create folder/srfolder
	},
	data() {
		return {
			selectedItemVal: null,
			isRequired: this.options.isRequired,
			customIcon: this.options.customIcon,
			treeLogicalName: this.options.treeLogicalName,
			suggestionComponent: 'control-lookup-folder-suggestion',
			searchResult: [],
			rootPath: this.lookupRootName || (this.options.rootName ? this.evaluationContext.eval(this.options.rootName) : 'My Documents'),
			path: [],
			isLoading: false
		};
	},
	mixins: [
		LookupEditorMixins
	],
	watch: {
		selectedItem: {
			immediate: true,
			async handler(newVal) {
				if (newVal) {
					const filteredItem = this.searchResult.find(item => item.data.Id === (newVal.data.Id || newVal.data.id));
					if (!filteredItem) {
						this.isLoading = true;
						await this.fetchData();
						this.isLoading = false;
					}
				}
				this.path = newVal && this.searchResult.length ? this.buildPathChildren() : [this.rootPath];
			}
		}
	},
	mounted() {
		if (this.initParentFolderStateKey) {
			const parentFolder = this.$store.state[this.initParentFolderStateKey] && this.$store.state[this.initParentFolderStateKey].entityData.parentfolder;
			if (parentFolder) {
				this.selectedItem = {
					data: parentFolder,
					displayName: parentFolder.name
				};
			}
		}
	},
	methods: {
		toggleOpen() {
			if (!this.isPopUpOpen) {
				this.$root.$once('lookupArea.scrolled', this.closePopUp);
				this.focusInput();
				this.$nextTick(() => {
					this.search(true);
				});
			} else {
				this.onClosePopup();
			}
		},
		buildPath(pathArr, id) {
			const result = this.searchResult.find(item => item.data.Id === id);
			pathArr.unshift(result);
			const parent = result.data.Source.parentfolder;
			if (parent) {
				this.buildPath(pathArr, parent.id);
			}
			return pathArr;
		},
		buildPathChildren() {
			const path = this.buildPath([], this.selectedItem.data.Id || this.selectedItem.data.id).map(item => {
				return item.displayName;
			});
			path.unshift(this.rootPath);
			return path;
		}
	}
};
</script>
<style scoped>
	.control-lookup-editor.input {
		height: 30px;
	}

	.control-lookup-editor.opened:not(.disabled).top-positioned input {
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
</style>
<style src="@/Components/Editor/Controls/editor.mixins.less" scoped></style>
<style src="./lookup.styles.less" scoped></style>
<style src="./lookup-folder.less" scoped></style>
