<template>
	<div class="message-wrapper" :class="{
		 own:message.Source.ownerid.id == currentUserId,
		 unread: message.Source.unread,
		 'text-center': message.Source.messagetype == 'Action'
		 }">
		<div v-if="message.Source.messagetype == 'Message'">
			<div class="svg-thumbnail avatar">
				<img v-bind:src="imgUrl" />
			</div>
			<svg class="svg-icon corner">
				<use xlink:href='#layout-icons-chat-ext-corner-combined'></use>
			</svg>
			<div class="message">
				<div class="quote-message" v-if="message.Source.chatmessageparentchatmessageidchatmessage">
					<quote :message="{
								text: message.Source.chatmessageparentchatmessageidchatmessage.messagetext,
								createdon: message.Source.chatmessageparentchatmessageidchatmessage.createdonfromui,
								createBy: message.Source.chatmessageparentchatmessageidchatmessage.contactname
						   }"
						   :key="message.Source.chatmessageparentchatmessageidchatmessage.chatmessageid" />
				</div>
				<div class="first-line">
					<div class="info">
						<p class="p2" v-if="message.parsedMessage">
							<span v-for="(item,index) in createBy" :key="index" :class="item.highlight && 'search-highlight'">{{item.message}}</span>
						</p>
						<p class="p2" v-if="!message.parsedMessage">{{createBy}}</p>
						<p class="p6">{{date}}</p>
					</div>
					<ActionMenu v-if="hasWriteAccess"
								:options="actionMenuOptions"
								:evaluationContext="evaluationContext"
								:record="message"></ActionMenu>
				</div>
				<p v-if="message.parsedMessage" class="message-content p1"><span v-for="(item, index) in message.Source.messagetext" v-parselinks :key="index" :class="item.highlight && 'search-highlight'">{{item.message}}</span></p>
				<p v-if="!message.parsedMessage" class="message-content p1" v-parselinks v-text="message.Source.messagetext"></p>
				<svg v-if="currentUserId === message.Source.ownerid.id" class="svg-icon status">
					<use xlink:href='#layout-icons-loader_sent'></use>
				</svg>
			</div>
		</div>
		<div v-if="message.Source.messagetype == 'Action' && !message.parsedMessage" class="action">
			<span class="message-text">
				<b class="contact-name">{{ message.Source.contactname }}</b>
				{{ message.Source.messagetext }}
			</span>
		</div>
		<div class="action" v-else-if="message.Source.messagetype == 'Action' && message.parsedMessage">
			<div class="contact-name">
				<span v-for="(item,index) in message.Source.contactname" :key="index" :class="item.highlight && 'search-highlight'">{{item.message}}</span>
			</div>
			<div class="message-text">
				<span v-for="(item,index) in message.Source.messagetext" :key="index" :class="item.highlight && 'search-highlight'">{{item.message}}</span>
			</div>
		</div>
	</div>
</template>
<script>
import { ParselinksDirective } from '@acx-xms/components/dist';
import { copyToClipboard } from '@acx-xms/data-functions/dist';
const ActionMenu = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './../../Menu/action-menu');
const Quote = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './quote');
export default {
	name: 'message',
	props: {
		message: Object,
		currentUserId: String,
		evaluationContext: Object,
		disabled: Boolean,
		hasWriteAccess: Boolean
	},
	components: {
		ActionMenu,
		Quote
	},
	data() {
		return {
			date: '',
			status: this.message.status || '',
			imgUrl: '',
			createBy: ''
		};
	},
	methods: {
		async executeAction(actionType) {
			switch (actionType) {
			case 'quote':
				this.$parent.$emit('quote', this.message);
				break;
			case 'copy':
				copyToClipboard(this.message.Source.messagetext);
				this.$root.$emit('toast.open', await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('common.dictionary.toastText'));
				break;
			default:
			}
		}
	},
	created() {
		const menuItems = [
			{
				$type: 'menu.menuItem',
				display: 'text',
				default: false,
				text: {
					$type: 'expression.text',
					value: 'Copy'
				},
				action: {
					$type: 'action.chatmessage.copy',
					actionType: 'copy',
					callBack: this.executeAction,
					enable: { $type: this.disabled ? 'expression.false' : 'expression.true' },
					source: 'context'
				}
			}
		];

		if (this.status !== 'temporary') {
			menuItems.push(
				{
					$type: 'menu.menuItem',
					display: 'text',
					default: false,
					text: {
						$type: 'expression.text',
						value: 'Quote'
					},
					action: {
						$type: 'action.chatmessage.copy',
						actionType: 'quote',
						callBack: this.executeAction,
						enable: { $type: this.disabled ? 'expression.false' : 'expression.true' },
						source: 'context'
					}
				}
			);
		}
		if (this.hasWriteAccess) {
			this.actionMenuOptions = {
				menu: {
					$type: 'menu.menu',
					display: 'auto',
					responsiveDisplayStyle: 'auto',
					responsiveStyle: 'collapsed',
					item: menuItems
				},
				namespace: 'mainSearch',
				internalNamespace: 'chat',
				entityType: 'chatmessage'
			};
		}

		this.date = this.evaluationContext.eval({
			$type: 'expression.timespan',
			argument: {
				$type: 'expression.text',
				value: this.message.Source.createdonfromui
			}
		});

		const extendedEnityReffernce = this.message.Source.chatmessagecontactidcontact || this.message.Source.chatmessagecreatedbysystemuser;
		this.createBy = this.message.Source.contactname;

		if (this.message.Source.chatmessagecreatedbysystemuser.avatarid) {
			this.imgUrl = sc.classes.get('edge.dataProvider').getImageUrlWithToken('avatar', this.message.Source.chatmessagecreatedbysystemuser.avatarid.id, 'thumbnail');
		} else {
			let text = (extendedEnityReffernce.firstname) ? extendedEnityReffernce.firstname.charAt(0) : '';
			text += (extendedEnityReffernce.lastname) ? extendedEnityReffernce.lastname.charAt(0) : '';
			if (!text || this.message.Source.nonregistered === true) {
				const [firstName, lastName] = this.message.Source.nonregistered !== true ? extendedEnityReffernce.fullname.split(' ') : (Array.isArray(this.createBy) ? this.createBy.map(item => item.message).join('') : this.createBy).split(' ');
				text = firstName ? firstName.charAt(0) : '';
				text += lastName ? lastName.charAt(0) : '';
			}
			const provider = sc.classes.get('smartIcon.dataProvider', this.evaluationContext.eval);

			provider.getImageUrl(text, 40, 90, 'Segoe UI Semibold', provider.getBackgroundColor(text)).then(url => {
				this.imgUrl = url;
			});
		}
	}
};
</script>
<style src="./message.less" scoped></style>
