var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-content-wrapper" }, [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ],
      staticClass: "loading-overlay large"
    }),
    _vm._v(" "),
    _c("div", { staticClass: "save-search-dialog" }, [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "title p4" }, [
          _vm._v("\n\t\t\t\t" + _vm._s(_vm.texts.desc) + "\n\t\t\t")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "dialog-content" }, [
          _c("div", { staticClass: "new-search" }, [
            _c("div", { staticClass: "radio" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.createNew,
                    expression: "createNew"
                  }
                ],
                attrs: { type: "radio" },
                domProps: { value: true, checked: _vm._q(_vm.createNew, true) },
                on: {
                  change: [
                    function($event) {
                      _vm.createNew = true
                    },
                    _vm.clearInputs
                  ]
                }
              })
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.newSearchName,
                  expression: "newSearchName",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "new-search-name sc-input p1",
              attrs: {
                disabled: !_vm.createNew,
                placeholder: _vm.texts.newPlaceholder,
                type: "text"
              },
              domProps: { value: _vm.newSearchName },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.newSearchName = $event.target.value.trim()
                },
                blur: function($event) {
                  _vm.$forceUpdate()
                }
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "existing-search" },
            [
              _c("div", { staticClass: "radio" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createNew,
                      expression: "createNew"
                    }
                  ],
                  attrs: { type: "radio" },
                  domProps: {
                    value: false,
                    checked: _vm._q(_vm.createNew, false)
                  },
                  on: {
                    change: [
                      function($event) {
                        _vm.createNew = false
                      },
                      _vm.clearInputs
                    ]
                  }
                })
              ]),
              _vm._v(" "),
              _c("control-lookup-editor", {
                attrs: {
                  evaluationContext: _vm.evaluationContext,
                  options: _vm.getLookupOptions()
                },
                model: {
                  value: _vm.selectedSearchValue,
                  callback: function($$v) {
                    _vm.selectedSearchValue = $$v
                  },
                  expression: "selectedSearchValue"
                }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "footer" }, [
        _c("div", { staticClass: "statistic" }, [
          _vm.validationError
            ? _c("div", { staticClass: "selection-info error" }, [
                _c("span", {
                  directives: [
                    {
                      name: "localization",
                      rawName: "v-localization",
                      value: { key: _vm.validationError },
                      expression: "{key: validationError}"
                    }
                  ]
                })
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "buttons" }, [
          _c("span", { staticClass: "button-wrapper" }, [
            _c("button", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: { key: "common.dictionary.buttons.save" },
                  expression: "{key: 'common.dictionary.buttons.save'}"
                }
              ],
              attrs: { disabled: !_vm.saveEnabled, type: "button" },
              on: { click: _vm.save }
            })
          ]),
          _vm._v(" "),
          _c("button", {
            directives: [
              {
                name: "localization",
                rawName: "v-localization",
                value: { key: "common.dictionary.buttons.cancel" },
                expression: "{key: 'common.dictionary.buttons.cancel'}"
              }
            ],
            staticClass: "cancel",
            attrs: { type: "button" },
            on: { click: _vm.cancel }
          })
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }