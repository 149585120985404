var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "row",
      class: {
        selected: _vm.isSelected,
        "no-selection": !_vm.options.allowSelection
      }
    },
    [
      _vm.options.allowSelection
        ? _c("VirtualCheckbox", {
            attrs: {
              select: _vm.isSelected,
              record: _vm.rowItem,
              stateNamespace: _vm.stateNamespace,
              isParent: _vm.options.isParent,
              syncSelection: _vm.options.syncSelection,
              evaluationContext: _vm.evaluationContext
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("control", {
        attrs: {
          name: _vm.options.contentComponent.$type,
          contentProps: _vm.options.contentComponent,
          evaluationContext: _vm.evaluationContext
        }
      }),
      _vm._v(" "),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading,
            expression: "isLoading"
          }
        ],
        staticClass: "loading-overlay"
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }