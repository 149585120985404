var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.options.params.progress.total > 0
    ? _c("div", { staticClass: "progress-dialog-wrapper" }, [
        _c("div", { staticClass: "progress-dialog" }, [
          _c("div", { staticClass: "main" }, [
            _c("span", { staticClass: "primary-label p14" }, [
              _vm._v(_vm._s(_vm.progressMessage))
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.progressPercentage))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _c("div", {
                class: [
                  "progress-bar-container",
                  { failed: _vm.isOperationFailed }
                ],
                style: { width: _vm.progressPercentage }
              })
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }