<template>
	<div class="dialog-content-wrapper email-link-dialog">
		<div class="dialog-container">
			<div class="header">
				<p v-localization="{key:'dialogues.email.link.legend'}"></p>
			</div>
			<div class="email-body" ref="email" v-perfectscroll="{enabled:true}">
				<table style="width: 100%">
					<tbody>
						<tr v-for="item in options.params.selection" :key="item.Name">
							<td width="25%" class="strong-text">{{item.Name}}</td>
							<td width="75%"><a @mousedown.stop.prevent target="_blank" :href="GetUrl(item)">{{GetUrl(item)}}</a></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class='footer'>
			<span class="buttons">
				<button type="submit" @click="copy" v-localization="{key: 'dialogues.email.link.copy'}" v-data-attr="{title: options.title, $type: 'dialog.button.copy'}"></button>
				<button class="cancel" type="button" @click="cancel" v-localization="{key: 'common.dictionary.buttons.cancel'}" v-data-attr="{title: options.title, $type: 'dialog.button.cancel'}"></button>
			</span>
		</div>
	</div>
</template>
<script>
import { recordLink, showToastOrInfoDialog } from '@acx-xms/data-functions/dist';

export default {
	name: 'email-link-dialog',
	props: { options: Object },
	data() {
		return {};
	},
	methods: {
		GetUrl(item) {
			const ref = sc.classes.get('entityReference', item);
			return window.location.origin + recordLink(this.$route, ref);
		},
		async copy() {
			window.getSelection().selectAllChildren(this.$refs.email);
			document.execCommand('copy');
			window.getSelection().removeAllRanges();
			await showToastOrInfoDialog({
				toastMessageKey: this.options.params.toastMessageKey || 'common.dictionary.toastText',
				informationDialogTextKey: this.options.params.informationDialogTextKey,
				options: { context: this }
			});
		},
		cancel() {
			this.$parent.$emit('close');
		}
	}
};
</script>
