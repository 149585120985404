var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "filter-content slide-holder panel-content" }, [
        _vm.available
          ? _c("div", { staticClass: "slider-range number" }, [
              _c("div", { class: ["wrapper", { error: _vm.error.from }] }, [
                _c("input", {
                  directives: [
                    {
                      name: "data-attr",
                      rawName: "v-data-attr",
                      value: { options: _vm.options, stateNamespace: "from" },
                      expression: "{ options, stateNamespace: 'from' }"
                    }
                  ],
                  class: { changed: _vm.from != _vm.min || _vm.rangeChanged },
                  attrs: { type: "text" },
                  domProps: { value: _vm.from },
                  on: {
                    input: function($event) {
                      _vm.set("from", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("span", {
                directives: [
                  {
                    name: "localization",
                    rawName: "v-localization",
                    value: { key: "filtersPanel.slider.to" },
                    expression: "{ key: 'filtersPanel.slider.to' }"
                  }
                ]
              }),
              _vm._v(" "),
              _c("div", { class: ["wrapper", { error: _vm.error.to }] }, [
                _c("input", {
                  directives: [
                    {
                      name: "data-attr",
                      rawName: "v-data-attr",
                      value: { options: _vm.options, stateNamespace: "to" },
                      expression: "{ options, stateNamespace: 'to' }"
                    }
                  ],
                  class: { changed: _vm.to != _vm.max || _vm.rangeChanged },
                  attrs: { type: "text" },
                  domProps: { value: _vm.to },
                  on: {
                    input: function($event) {
                      _vm.set("to", $event.target.value)
                    }
                  }
                })
              ])
            ])
          : _c("div", { staticClass: "slider-range" }, [
              _c("span", {
                directives: [
                  {
                    name: "localization",
                    rawName: "v-localization",
                    value: { key: "filtersPanel.unavailable" },
                    expression: "{ key: 'filtersPanel.unavailable' }"
                  }
                ]
              })
            ])
      ]),
      _vm._v(" "),
      _vm.options.isIncludeEmptyVisible
        ? _c("IncludeEmpty", {
            directives: [
              {
                name: "data-attr",
                rawName: "v-data-attr",
                value: { options: _vm.options, stateNamespace: "empty" },
                expression: "{ options, stateNamespace: 'empty' }"
              }
            ],
            attrs: { enabled: _vm.enableIncludeEmpty },
            model: {
              value: _vm.includeEmpty,
              callback: function($$v) {
                _vm.includeEmpty = $$v
              },
              expression: "includeEmpty"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }