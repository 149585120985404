var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "custom-select" }, [
    _c("div", { staticClass: "region-wrapper popuparea" }, [
      _c("div", { staticClass: "popup-holder striped" }, [
        _vm.options.data.items && _vm.options.data.items.length
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "perfectscroll",
                    rawName: "v-perfectscroll",
                    value: { enable: true, refreshScroll: false },
                    expression: "{enable: true, refreshScroll: false}"
                  }
                ],
                ref: "scrollArea",
                staticClass: "items-wrapper",
                on: { scrollToBottom: _vm.onScrollToBottom }
              },
              [
                _c(
                  "div",
                  { staticClass: "items" },
                  _vm._l(_vm.options.data.items, function(item) {
                    return _c(
                      "div",
                      {
                        key: item.data && item.data.Id,
                        staticClass: "clickable suggestion",
                        class: {
                          selected:
                            _vm.options.value &&
                            _vm.options.value.displayName === item.displayName
                        },
                        on: {
                          click: function($event) {
                            _vm.selectItem(item)
                          }
                        }
                      },
                      [
                        !_vm.options.template
                          ? _c("div", { staticClass: "column" }, [
                              _vm._v(_vm._s(item.displayName))
                            ])
                          : _vm.options.template
                          ? _c("control", {
                              attrs: {
                                name: _vm.options.template.$type,
                                contentProps: _vm.options.template,
                                evaluationContext: _vm.getContext(item.data)
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  })
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "items-footer" }, [
          _c("div", { staticClass: "info" }, [
            !_vm.options.hideFooter
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.options.isLoading,
                        expression: "!options.isLoading"
                      }
                    ]
                  },
                  [
                    !_vm.options.data.items ||
                    _vm.options.data.items.length === 0
                      ? _c("span", {
                          directives: [
                            {
                              name: "localization",
                              rawName: "v-localization",
                              value: { key: "controls.lookup.noResultsFound" },
                              expression:
                                "{ key: 'controls.lookup.noResultsFound'}"
                            }
                          ]
                        })
                      : _vm.options.data.items &&
                        _vm.options.data.items.length > 0
                      ? _c("span", [
                          _c("span", {
                            directives: [
                              {
                                name: "localization",
                                rawName: "v-localization",
                                value: { key: "controls.lookup.loaded" },
                                expression: "{ key: 'controls.lookup.loaded'}"
                              }
                            ]
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "counter" }, [
                            _vm._v(_vm._s(_vm.options.data.items.length))
                          ]),
                          _vm._v(" "),
                          _c("span", {
                            directives: [
                              {
                                name: "localization",
                                rawName: "v-localization",
                                value: { key: "controls.lookup.of" },
                                expression: "{ key: 'controls.lookup.of'}"
                              }
                            ]
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "counter" }, [
                            _vm._v(
                              _vm._s(
                                _vm.options.data.total ||
                                  _vm.options.data.items.length
                              )
                            )
                          ])
                        ])
                      : _vm._e()
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.options.isLoading,
                    expression: "options.isLoading"
                  }
                ]
              },
              [
                _c("span", {
                  directives: [
                    {
                      name: "localization",
                      rawName: "v-localization",
                      value: { key: "controls.lookup.loading" },
                      expression: "{ key: 'controls.lookup.loading'}"
                    }
                  ]
                })
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.options.isLoading,
                expression: "options.isLoading"
              }
            ],
            staticClass: "loading-overlay inline"
          })
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }