var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "control-geocoordinateseditor",
      class: this.options.orientation
    },
    [
      _c("div", { staticClass: "latitude" }, [
        _c("div", { staticClass: "field-title" }, [_vm._v("Latitude")]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "input",
            class: { disabled: !_vm.enable, invalid: !_vm.isLatValid }
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.lazy",
                  value: _vm.valuelat,
                  expression: "valuelat",
                  modifiers: { lazy: true }
                }
              ],
              attrs: { type: "number" },
              domProps: { value: _vm.valuelat },
              on: {
                focus: function($event) {
                  _vm.focusedlat = true
                },
                blur: function($event) {
                  _vm.focusedlon = false
                },
                change: [
                  function($event) {
                    _vm.valuelat = $event.target.value
                  },
                  _vm.adjustValueLat
                ]
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "actions after" }, [
              !!_vm.valuelat
                ? _c(
                    "div",
                    { staticClass: "action", on: { click: _vm.clearLat } },
                    [
                      _c("control-svg-icon", {
                        attrs: {
                          options: { name: "layout-icons-textbox-delete" }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "longitude" }, [
        _c("div", { staticClass: "field-title" }, [_vm._v("Longitude")]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "input",
            class: { disabled: !_vm.enable, invalid: !_vm.isLonValid }
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.lazy",
                  value: _vm.valuelon,
                  expression: "valuelon",
                  modifiers: { lazy: true }
                }
              ],
              attrs: { type: "number" },
              domProps: { value: _vm.valuelon },
              on: {
                focus: function($event) {
                  _vm.focusedlon = true
                },
                blur: function($event) {
                  _vm.focusedlon = false
                },
                change: [
                  function($event) {
                    _vm.valuelon = $event.target.value
                  },
                  _vm.adjustValueLon
                ]
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "actions after" }, [
              !!_vm.valuelon
                ? _c(
                    "div",
                    { staticClass: "action", on: { click: _vm.clearLon } },
                    [
                      _c("control-svg-icon", {
                        attrs: {
                          options: { name: "layout-icons-textbox-delete" }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }