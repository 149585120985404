<template>
	<div class="file-upload">
		<div class="file-exists-message">
			<div class="required-mark" v-if="showRequiredMark && !isLoading">
				<svg class="svg-icon svg-8 layout-icons-required">
					<use xlink:href="#layout-icons-required"></use>
				</svg>
			</div>
			<div v-if="isLoading" key="uploading" v-localization="{ key: 'controls.ndaFileEditor.uploadingLbl'}"></div>
			<div v-else-if="value">{{fileName}}</div>
			<div v-else key="noFileChosen" v-localization="{key: 'controls.ndaFileEditor.noFileChoosenLbl'}"></div>
		</div>
		<!--todo: find a better way-->
		<span type="submit"
			  v-show="!value"
			  @click="!options.disabled&&uploadFile()"
			  class="button"
			  :class="{'disabled': options.disabled}"
			  v-localization="{key:'controls.ndaFileEditor.chooseFileLbl'}"></span>
		<span type="submit"
			  class="button"
			  v-show="value"
			  @click="removeFile"
			  v-localization="{key:'controls.ndaFileEditor.removeLbl'}"></span>
		<input name="file" ref="fileInput" type="file" class="file-input" accept=".json" @change="fileUploaded" />
	</div>
</template>
<script>
export default {
	name: 'control-file-to-json',
	props: {
		value: Object,
		options: Object,
		showRequiredMark: Boolean
	},
	data() {
		return { isLoading: false };
	},
	created() {
		this.localizationProvider = sc.classes.get('localization.dataProvider');
	},
	methods: {
		uploadFile() {
			this.$refs.fileInput.click();
		},
		removeFile() {
			this.$emit('input', null);
			this.$refs.fileInput.value = '';
		},
		fileUploaded(e) {
			if (e.target.files.length > 0) {
				const raw = e.target.files[0];

				// byteLimit can be set in control configuration, but it should not be more than 50 mb
				if ((raw.size > 52428800 || this.options.byteLimit) && raw.size > this.options.byteLimit) {
					const mbytes = this.options.byteLimit / 1024 / 1024;
					const message = this.localizationProvider.localize('controls.fileUpload.sizeError', [mbytes]);
					sc.utils.errorMessage.byMessage(message);
					e.target.value = null;
					return false;
				}
				this.isLoading = true;
				this.fileName = raw.name;
				const fr = new FileReader();

				fr.readAsText(raw);

				fr.onload = (e) => {
					try {
						const result = JSON.parse(e.target.result);
						this.$emit('input', result);
					} catch {
						const message = this.localizationProvider.localize('controls.fileToJson.parseError');
						sc.utils.errorMessage.byMessage(message);
					}
				};

				fr.onerror = (e) => {
					fr.abort();
					const message = this.localizationProvider.localize('controls.fileToJson.readError', [e.target.error.code]);
					sc.utils.errorMessage.byMessage(message);
				};
				this.removeFile();
				this.isLoading = false;
			}
		}
	}
};
</script>
<style src="./file-upload.less" scoped></style>
