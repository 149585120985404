import { Search } from '@acx-xms/data-functions/dist';
async function searchChilrenDocuments(folderId) {
	let childrenDocuments = [];
	const _searchChilrenDocuments = async (folderId) => {
		const filters = [
			sc.classes.get('offsetFrom.filter', 0),
			sc.classes.get('offsetSize.filter', 9999),
			sc.classes.get('termFacet.filter', {
				logicalName: 'recordstate.id',
				query: ['8d113fa8-3015-4060-a107-14cedcd19dd3']
			}).fillQuery(),
			sc.classes.get('termFacet.filter', {
				logicalName: 'parentfolder.id',
				query: [folderId]
			}).fillQuery()
			// add selected fields
		];
		const [documents, subfolders] = await Promise.all([await Search(['document'], filters), await Search(['folder'], filters)]);
		if (documents.Results.length) {
			childrenDocuments = childrenDocuments.concat(documents.Results).map(document => {
				return sc.classes.get('entityReference', document);
			});
		};
		if (subfolders.Results.length) {
			const promises = subfolders.Results.map(subfolder => {
				return _searchChilrenDocuments(subfolder.Source.folderid, childrenDocuments);
			});
			await Promise.all(promises);
		}
	};
	await _searchChilrenDocuments(folderId);
	return childrenDocuments;
}

// TODO: check config filters
async function searchDocumentRelatedRecords(query, options, documentRelatedRecords = []) {
	const configFilters = options.lookupFilters
		? options.lookupFilters.map((filter) => {
			return sc.classes.get(filter.$type, filter/* , context */).toFilter();
		})
		: [];
	const relatedEntitiesList = ['shareddocumentlistingidlisting', 'shareddocumentcollaborationroomidcollaborationroom',
		'shareddocumentdealroomiddealroom', 'shareddocumentchatidchat', 'shareddocumentavailabilityidavailability'];
	const filters = [
		sc.classes.get('offsetFrom.filter', 0),
		sc.classes.get('offsetSize.filter', 9999),
		sc.classes.get('termFacet.filter', {
			logicalName: 'documentid.id',
			query
		}).fillQuery(),
		...configFilters
	];

	let results = await Search([options.documentRelatedRecordsTemplate.entity], filters);
	results = results.Results.filter(res => {
		return !relatedEntitiesList.some(entity => res.Source[entity] && res.Source[entity].recordstate.id === '9e817a63-452c-e811-8320-7824af3b452f');
	});

	documentRelatedRecords = documentRelatedRecords.concat(results);

	return documentRelatedRecords;
}

function processResults(filteredResults) {
	const filteredObj = {};
	filteredResults.forEach(document => {
		if (filteredObj[document.documentid.id]) {
			if (document.shareddocumentcollaborationroomidcollaborationroom) {
				filteredObj[document.documentid.id].collaborationroomNames.push(
					document.shareddocumentcollaborationroomidcollaborationroom.name
				);
			}
			if (document.shareddocumentdealroomiddealroom) {
				filteredObj[document.documentid.id].dealroomNames.push(
					document.shareddocumentdealroomiddealroom.name
				);
			}
			if (document.shareddocumentlistingidlisting) {
				filteredObj[document.documentid.id].listingsNames.push(
					document.shareddocumentlistingidlisting.name
				);
			}
			if (document.shareddocumentavailabilityidavailability) {
				filteredObj[document.documentid.id].availabilitiesNames.push(
					document.shareddocumentavailabilityidavailability.name
				);
			}
		} else {
			filteredObj[document.documentid.id] = {
				shareddocumentdocumentiddocument: document.shareddocumentdocumentiddocument,
				collaborationroomNames: document.shareddocumentcollaborationroomidcollaborationroom ? [document.shareddocumentcollaborationroomidcollaborationroom.name] : [],
				dealroomNames: document.shareddocumentdealroomiddealroom ? [document.shareddocumentdealroomiddealroom.name] : [],
				listingsNames: document.shareddocumentlistingidlisting ? [document.shareddocumentlistingidlisting.name] : [],
				availabilitiesNames: document.shareddocumentavailabilityidavailability ? [document.shareddocumentavailabilityidavailability.name] : []
			};
		}
	});
	Object.values(filteredObj).forEach(result => {
		result.collaborationroomNames = [...new Set(result.collaborationroomNames)];
		result.dealroomNames = [...new Set(result.dealroomNames)];
		result.listingsNames = [...new Set(result.listingsNames)];
		result.availabilitiesNames = [...new Set(result.availabilitiesNames)];
	});
	return Object.values(filteredObj).sort((a, b) => a.shareddocumentdocumentiddocument.title.localeCompare(b.shareddocumentdocumentiddocument.title));
};
export {
	processResults,
	searchDocumentRelatedRecords,
	searchChilrenDocuments
};
