var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VueDatePicker",
    {
      class: ["date-picker", { disabled: !_vm.enable }],
      attrs: { "disabled-date": _vm.disabled, open: _vm.opened },
      on: { open: _vm.open, close: _vm.close },
      model: {
        value: _vm.innerValue,
        callback: function($$v) {
          _vm.innerValue = $$v
        },
        expression: "innerValue"
      }
    },
    [
      _c("ControlSvgIcon", {
        attrs: {
          slot: "icon-calendar",
          options: { name: "layout-icons-calendar-marker" }
        },
        slot: "icon-calendar"
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          class: ["input", { invalid: !_vm.valid }],
          attrs: { slot: "input" },
          slot: "input"
        },
        [
          _c("input", {
            attrs: { type: "text", disabled: !_vm.enable },
            domProps: { value: _vm.formatted },
            on: {
              change: _vm.inputDate,
              keyup: function($event) {
                if (
                  !("button" in $event) &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.inputDate($event)
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "actions before" }, [
            _vm.showRequiredMark
              ? _c("div", { staticClass: "required-mark" }, [
                  _c(
                    "svg",
                    { staticClass: "svg-icon svg-8 layout-icons-required" },
                    [
                      _c("use", {
                        attrs: { "xlink:href": "#layout-icons-required" }
                      })
                    ]
                  )
                ])
              : _vm._e()
          ])
        ]
      ),
      _vm._v(" "),
      _vm.hasTodayAction
        ? _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c("span", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: {
                    key: "common.dictionary.buttons.datepicker.current"
                  },
                  expression:
                    "{ key: 'common.dictionary.buttons.datepicker.current' }"
                }
              ],
              class: ["today-action", { disabled: _vm.disabled(_vm.today) }],
              on: { click: _vm.todayAction }
            })
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }