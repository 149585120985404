var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-info-wrapper" }, [
    _c("div", { staticClass: "dialog-info" }, [
      _c(
        "div",
        {
          staticClass: "info-message",
          class: {
            "with-template": _vm.options.template && _vm.options.records
          }
        },
        [
          _c("div", { staticClass: "info" }, [
            _c("p", { staticClass: "message" }, [
              _vm._v(_vm._s(_vm.options.message))
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "entity-list" },
        [
          _vm.options.template && _vm.options.records
            ? _c("control", {
                staticClass: "formcontrol-template",
                attrs: {
                  name: _vm.options.template.$type,
                  contentProps: _vm.options.template,
                  initResults: _vm.options.records
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.showFooter
        ? _c("div", { staticClass: "footer" }, [
            _c("div", { staticClass: "buttons" }, [
              _c(
                "button",
                { attrs: { type: "button" }, on: { click: _vm.close } },
                [_vm._v("OK")]
              )
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }