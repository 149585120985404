<template>
	<map-tooltip-base :options="options" />
</template>
<script>
const MapTooltipBase = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './map-tooltip-base.vue');

export default {
	name: 'map-tooltip',
	props: { options: Object },
	components: { MapTooltipBase }
};
</script>
