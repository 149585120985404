var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "enums-wrapper" },
    [
      _c("span", { staticClass: "p4" }, [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c("control-lookup-editor", {
        staticClass: "lookup",
        attrs: { options: _vm.getLookupOptions() },
        model: {
          value: _vm.value,
          callback: function($$v) {
            _vm.value = $$v
          },
          expression: "value"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }