var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "flex-wrapper" },
      [
        _c("ControlLookup", {
          attrs: {
            options: Object.assign({}, _vm.lookupOptions, { query: _vm.query }),
            cssClasses: _vm.cssClasses,
            isDisabled: _vm.buttonOptions.isDisabled
          },
          on: {
            input: function($event) {
              _vm.query = $event
            }
          }
        }),
        _vm._v(" "),
        _vm.buttonOptions
          ? _c(
              "button",
              {
                attrs: { disabled: _vm.buttonOptions.isDisabled },
                on: { click: _vm.onSubmit }
              },
              [_vm._v("\n\t\t\t" + _vm._s(_vm.buttonOptions.label) + "\n\t\t")]
            )
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _vm.validationOptions && !_vm.validationOptions.isValid
      ? _c("div", { staticClass: "error-wrapper" }, [
          _c("svg", { staticClass: "svg-icon" }, [
            _c("use", { attrs: { "xlink:href": "#ui-icon-exclamation-mark" } })
          ]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.validationOptions.message))])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }