var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "delete-restriction-dialog" }, [
    _c("div", {
      directives: [
        {
          name: "localization",
          rawName: "v-localization",
          value: { key: "deleteRestrictionDialog.label" },
          expression: "{ key: 'deleteRestrictionDialog.label' }"
        }
      ],
      staticClass: "p4"
    }),
    _vm._v(" "),
    _c("div", {
      directives: [
        {
          name: "localization",
          rawName: "v-localization",
          value: {
            key: "deleteRestrictionDialog.table.title",
            args: [_vm.relatedTotal]
          },
          expression:
            "{ key: 'deleteRestrictionDialog.table.title', args: [relatedTotal] }"
        }
      ],
      staticClass: "h5"
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "entity-list" },
      [
        _vm.template && _vm.relatedRecords.length
          ? _c("control", {
              staticClass: "formcontrol-template",
              attrs: {
                name: _vm.template.$type,
                contentProps: _vm.template,
                initResults: _vm.relatedRecords
              }
            })
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ],
      staticClass: "loading-overlay"
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }