var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "select-template-table" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "perfectscroll",
            rawName: "v-perfectscroll",
            value: { enabled: true, onScroll: _vm.onScroll },
            expression: "{ enabled: true, onScroll }"
          }
        ],
        staticClass: "select-template-table-content"
      },
      [
        _c(
          "div",
          [
            _vm.deletedTemplate
              ? _c(
                  "div",
                  { staticClass: "select-template-table-item deleted" },
                  [
                    _c("div", { staticClass: "dot" }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "select-template-table-col" },
                      [
                        _c(
                          "div",
                          { staticClass: "select-template-table-item-img" },
                          [
                            _c("img", {
                              attrs: { src: _vm.deletedTemplate.preview }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "select-template-table-text" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.deletedTemplate.templateName ||
                                  "Template is deleted"
                              )
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("InfoBadge", {
                          staticClass: "info-icon",
                          attrs: { tooltipText: _vm.deletedTemplateTooltipText }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "select-template-table-col" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.deletedTemplate.inputTypes) +
                          "\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "select-template-table-col" })
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.templates, function(item, key) {
              return _c(
                "div",
                {
                  key: key,
                  class: [
                    "select-template-table-item",
                    {
                      selected:
                        _vm.getTemplates.find(function(i) {
                          return i.templateId === item.templateId
                        }) &&
                        _vm.getTemplates.find(function(i) {
                          return i.templateId === item.templateId
                        }).selected,
                      deleted:
                        _vm.getTemplates.find(function(i) {
                          return i.templateId === item.templateId
                        }) &&
                        _vm.getTemplates.find(function(i) {
                          return i.templateId === item.templateId
                        }).deleted
                    }
                  ],
                  on: {
                    click: function($event) {
                      _vm.click(item.templateId)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "dot" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "select-template-table-col" }, [
                    _c(
                      "div",
                      { staticClass: "select-template-table-item-img" },
                      [
                        _c("img", {
                          attrs: {
                            src:
                              item && item.preview
                                ? item.preview
                                : "/Images/co-default-thumbnail.png"
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "select-template-table-text" }, [
                      _vm._v(_vm._s(item.templateName))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "select-template-table-col" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(item.inputTypes) +
                        "\n                    "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "select-template-table-col" },
                    [
                      _c("action-menu", {
                        attrs: {
                          hasDropDownItems: true,
                          options: _vm.getMenuOptions(item),
                          record: { Id: item.templateId, Type: "template" }
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            })
          ],
          2
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "select-template-table-header" }, [
      _c("div", { staticClass: "select-template-table-col" }, [
        _vm._v("\n                Template\n            ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "select-template-table-col" }, [
        _vm._v("\n                Input Type\n            ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "select-template-table-col" }, [
        _vm._v("\n                Actions\n            ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }