<template>
	<label v-data-attr="{ options }" class="custom-checkbox mobile tooltip">
		<input v-model="selected" type="checkbox" />
		<span class="value">
			<span class="value-checked">
				<span class="value-title" />
				<svg class="svg-icon svg-15">
					<use xlink:href="#layout-icons-bool-editor-on" class="boolean-icon layout-icons-bool-editor-on" />
				</svg>
			</span>
			<span class="value-unchecked">
				<span class="value-title" />
				<svg class="svg-icon svg-15">
					<use xlink:href="#layout-icons-bool-editor-off" class="boolean-icon layout-icons-bool-editor-off" />
				</svg>
			</span>
		</span>
	</label>
</template>
<script>
import '@/Bindings/ui-attribute.directive';
import FilterMixins from './../filter.mixins';
import helperMethods from '@/Components/ComponentSet/component-set-evaluation-context-helper.methods';

export default {
	name: 'bool-inline-view',
	mixins: [FilterMixins],
	props: {
		options: Object,
		evaluationContext: Object,
		stateNamespace: String
	},
	computed: {
		selected: {
			get() {
				const value = this.$store.getters[this.stateNamespace + '/getFilterValue']({
					entity: this.entity,
					logicalName: this.logicalName
				});
				return !!value;
			},
			async set(value) {
				const filter = value ? await this.getSearchFilter(this.logicalName) : null;
				this.emitFilterChanged(filter);
			}
		}
	},
	created() {
		this.logicalName = helperMethods.eval(this.evaluationContext, this.options.logicalName);
		this.entity = this.options.entities[0];
	}
};
</script>
<style src="./bool-inline-view.less" scoped></style>
