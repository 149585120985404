import { isMobile, downloadUrl } from '@acx-xms/data-functions/dist';

const
	filterMapping = {
		inputType: {
			sectionName: 'Input Type',
			targetPath: 'inputTypes'
		},
		colorType: {
			sectionName: 'Color',
			targetPath: 'color'
		},
		country: {
			sectionName: 'Country',
			targetPath: 'country'
		},
		language: {
			sectionName: 'Language',
			targetPath: 'language'
		},
		format: {
			sectionName: 'Format',
			targetPath: 'targetOutputFormats'
		},
		templateType: {
			sectionName: 'Template Type',
			targetPath: 'templateTypeFilter'
		}
	};

const downloadReport = (reportId, localize, dataProvider, showNotification, customMessageKey) => {
	if (isMobile() || showNotification) {
		const okButton = {
			title: localize('reportingServicePlugin.reports.download'),
			callback: () => {
				downloadUrl(dataProvider.getRequestUrl('content', {
					id: reportId,
					configKey: sc.cluster.configKey
				}));
			}
		};

		const cancelButton = { title: localize('reportingServicePlugin.reports.cancelDownload') };

		sc.events.emit('dialog.open', {
			localizedTitle: 'reportingServicePlugin.reports.downloadFile',
			allowMinimize: false,
			allowMaximize: false,
			allowPin: false,
			component: 'dialog.info',
			minimumHeight: '105px',
			params: {
				buttons: [
					okButton,
					cancelButton
				],
				message: localize(customMessageKey || 'reportingServicePlugin.reports.downloadFileMessage')
			}
		});
	} else {
		downloadUrl(dataProvider.getRequestUrl('content', {
			id: reportId,
			configKey: sc.cluster.configKey
		}));
	}
};

const downloadCOReport = url => {
	downloadUrl(url);
};

const parseComposerError = errorObj => {
	let errorText = errorObj.responseText;
	if (errorText && typeof (errorText) === 'string') {
		try {
			const parseFn = (objCurrent, objPrevious) => {
				if (objCurrent && objCurrent.cause) { return parseFn(objCurrent.cause, objCurrent); } else {
					return (objPrevious || objCurrent).errorMessage || '';
				}
			};
			errorText = parseFn(JSON.parse(errorText));
		} catch (e) {
			sc.utils.errorMessage.byMessage(e);
		}
	}
	sc.utils.errorMessage.byMessage(errorText || errorObj.statusText, undefined, errorObj);
};

export default {
	filterMapping,
	downloadReport,
	parseComposerError,
	downloadCOReport
};
