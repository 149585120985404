var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-step" }, [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ],
      staticClass: "loading-overlay large"
    }),
    _vm._v(" "),
    _c("div", { staticClass: "list-content with-checkbox" }, [
      _c("div", { staticClass: "control-tableView inner-search-results" }, [
        _c("div", { staticClass: "tableview-header step-two" }, [
          _c(
            "div",
            { staticClass: "cell checkbox" },
            [
              _c("checkbox", {
                key: "checkedAll" + _vm.checkedAll,
                attrs: { value: _vm.checkedAll },
                on: { input: _vm.onSelectAll }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "cell first-column" }, [
            _c("span", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: { key: "import.sourceField" },
                  expression: "{ key: 'import.sourceField'}"
                }
              ],
              staticClass: "label-header"
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "cell second-column" }, [
            _c("span", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: { key: "import.exampleOfFieldData" },
                  expression: "{ key: 'import.exampleOfFieldData'}"
                }
              ],
              staticClass: "label-header"
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "cell third-column" }, [
            _c("span", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: { key: "import.destination" },
                  expression: "{ key: 'import.destination'}"
                }
              ],
              staticClass: "label-header"
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "tableview-content" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "perfectscroll",
                  rawName: "v-perfectscroll",
                  value: { enable: true },
                  expression: "{enable: true}"
                }
              ],
              staticClass: "content-scroll-wrapper"
            },
            _vm._l(_vm.fieldsMapping, function(item, index) {
              return _c(
                "div",
                { key: item.fileField + item.checked, staticClass: "row" },
                [
                  _c(
                    "div",
                    { staticClass: "table-cell checkbox" },
                    [
                      _c("checkbox", {
                        key: "item.fileField_" + "checked",
                        on: {
                          input: function($event) {
                            var i = arguments.length,
                              argsArray = Array(i)
                            while (i--) argsArray[i] = arguments[i]
                            _vm.onCheckedItemChanged.apply(
                              void 0,
                              [index].concat(argsArray)
                            )
                          }
                        },
                        model: {
                          value: item.checked,
                          callback: function($$v) {
                            _vm.$set(item, "checked", $$v)
                          },
                          expression: "item.checked"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "table-cell first-column" }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v(_vm._s(item.fileField))
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "table-cell multiline second-column" },
                    [
                      _c("span", { staticClass: "label" }, [
                        _vm._v(_vm._s(item.example))
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "table-cell third-column" },
                    [
                      _c("control-lookup-editor", {
                        class: { invalid: !item.unSelected },
                        attrs: {
                          evaluationContext: null,
                          options: _vm.getLookupOptions(index)
                        },
                        on: {
                          input: function($event) {
                            var i = arguments.length,
                              argsArray = Array(i)
                            while (i--) argsArray[i] = arguments[i]
                            _vm.onChangeInput.apply(
                              void 0,
                              [index].concat(argsArray)
                            )
                          }
                        },
                        model: {
                          value: item.data,
                          callback: function($$v) {
                            _vm.$set(item, "data", $$v)
                          },
                          expression: "item.data"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            })
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _vm.options
      ? _c("div", { staticClass: "footer" }, [
          _c("span", { staticClass: "buttons" }, [
            _c("button", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: { key: "import.next" },
                  expression: "{key: 'import.next'}"
                }
              ],
              attrs: { type: "button" },
              on: { click: _vm.nextStep }
            }),
            _vm._v(" "),
            _c("button", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: { key: "import.back" },
                  expression: "{key: 'import.back'}"
                }
              ],
              staticClass: "cancel",
              attrs: { type: "button" },
              on: { click: _vm.previousStep }
            })
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }