import { InviteParticipantsAspect } from './aspects/inviteParticipantAfterSave.aspect';

export class InviteParticipants {
	constructor(options) {
		this.options = options;
	}

	installAspects(data) {
		const results = [];
		if (!data.id) {
			if (this.options.afterSaveAction) {
				results.push(
					new InviteParticipantsAspect({
						action: this.options.afterSaveAction,
						preferenceNameField: this.options.preferenceNameField,
						preferenceLink: this.options.preferenceLink,
						chatActiveState: this.options.chatActiveState
					})
				);
			}
		}
		return results;
	}
}
