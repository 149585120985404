<template>
	<div class="control-boolean-editor" :class="cssClass" v-if="visible" v-data-attr="dataAttr || {}">
		<label class="slider-wrapper" v-if="options.view === 'slider'">
			<input type="checkbox" v-model="value" :disabled="!enable" />
			<span class="slider round" :class="{'disabled':!enable}"></span>
		</label>
		<Checkbox v-model="value" :label="label" :disabled="!enable" v-else-if="options.view === 'checkbox'" />
	</div>
</template>
<script>
import ControlMixins from '@/Components/Editor/Controls/control.mixins';
import Checkbox from '@/Components/Control/Checkboxes/checkbox-base';

export default {
	name: 'control-boolean-editor',
	mixins: [ControlMixins],
	data() {
		return {
			label: '',
			emitEventOnChange: this.evaluationContext.eval(this.options.emitEventOnChange || false),
			cssClass: this.evaluationContext.eval(this.options.cssClass)
		};
	},
	components: { Checkbox },
	async created() {
		this.label = await this.evaluationContext.evalAsync(this.options.label);
	}
};
</script>
<style src="./control-boolean-editor.less" scoped></style>
