<template>
	<div class="control-stringeditor">
        <div class="stringeditor-title" v-if="fieldTitle">
            {{fieldTitle}}
        </div>
		<div class="input" v-bind:class="{'disabled': !enable, 'invalid': !isValid, 'splitted-input': adValue}">
			<div class="actions before">
				<div class="required-mark" v-if="showRequired">
					<svg class="svg-icon svg-8 layout-icons-required">
						<use xlink:href="#layout-icons-required"></use>
					</svg>
				</div>
			</div>
			<input @focus="focused = true"
				   @blur="onInputBlur($event)"
				   @keyup="onKeyUp"
				   :value="localValue"
				   :type="localType"
				   @change="adjustValue"
				   :placeholder="placeholder"
				   :class="{'required': showRequired, 'grey': color === 'grey', 'hidden-text': hideText}"
				   :disabled="!enable"
				   :maxlength="maxLength"
				   v-data-attr="dataAttr || {}"
				   :ref="'input' + type"/>
			<div class="actions after">
				<div tabindex=-1 @focus="onUnhideClick" class="action unhide" :style="{ right: value ? '30px' : '10px' }" v-if="isUnhideEnabled">
					<svg class='svg-icon' v-if="localType === 'password'">
						<use xlink:href='#ui-icon-show-password-icon'></use>
					</svg>
					<svg class='svg-icon' v-else>
						<use xlink:href='#ui-icon-show-password-active-icon'></use>
					</svg>
				</div>
				<div class="action" @click="clear" v-if="!!localValue && enable">
					<control-svg-icon :options="{ name: 'layout-icons-textbox-delete' }" />
				</div>
			</div>
            <div class='additional-value' v-if='adValue'>
				<ControlLookupEditor @input="temporaryInputHandler" :temporarySelectLookup="true" :temporarySelectLookupItems="adValue" :options="adValueOptions" :showRequired="false"/>
            </div>
		</div>
	</div>
</template>
<script>
/* eslint no-useless-escape: 0 */
const ControlSvgIcon = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Images/control-svg-icon');
const ControlLookupEditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Lookup/control-lookup-editor');
const DUMMY_PASS = '000-000-000-000';

export default {
    name: 'stringeditor',
    components: {
        ControlSvgIcon,
		ControlLookupEditor
    },
    props: {
        value: String,
        showRequired: {
            type: Boolean,
            default: false,
        },
        isValid: {
            type: Boolean,
            default: true,
        },
        adValue: {
            type: [String, Array],
            default: '',
        },
        enable: {
            type: Boolean,
            default: true,
        },
        placeholder: String,
        stringFormatRegexp: RegExp,
        stringFormat: Object,
        type: {
            type: String,
            default: 'text',
        },
        maxLength: Number,
        dataAttr: {
            value: String,
            $type: String,
        },
        fieldTitle: {
            type: String,
            default: ''
        },
        color: {
            type: String,
            default: ''
        },
        hideText: {
            type: Boolean,
            default: false,
        },
        isUnhideEnabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            focused: false,
            isUnhideClicked: false,
            localType: this.type,
            maxStringLength: this.maxLength === void 0 ? null : this.maxLength,
			adValueOptions: {
				isRequired: false
			}
        };
    },
    computed: {
        localValue: {
            get() {
                if (this.$refs.inputpassword  && (this.value === DUMMY_PASS || !this.value)) {
                    this.localType = 'password';
                }
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        }
    },
    watch: {
        focused(value) {
            this.$emit('focused', { value, isUnhideClicked: this.isUnhideClicked });
        }
    },
    methods: {
        onInputBlur(event) {
            if (event.relatedTarget && event.relatedTarget.className.includes('unhide')) {
                this.isUnhideClicked = true;
                this.onUnhideClick();
                this.focused = true;
            } else {
                this.isUnhideClicked = false;
                this.focused = false;
            }
        },
        clear() {
            this.localValue = '';
			this.$emit('clearAction');

            if (this.$refs.inputpassword) {
                this.$refs.inputpassword.focus();
                this.$refs.inputpassword.click();
            }
        },
        onUnhideClick() {
            if (this.$refs.inputpassword) {
                if (this.localType === 'password') {
                    this.localType = 'text';
                }
                else {
                    this.localType = 'password';
                }
                this.$refs.inputpassword.focus();
                this.$refs.inputpassword.click();
            }
        },
        adjustValue(e) {
            let val = e.target.value;
            if (!val) {
                this.localValue = '';
                return;
            }
            if (this.stringFormat && this.stringFormat.mask === 'phone') {
                this.localValue = this.formatPhoneNumber(val.trim());
            } else if (this.stringFormatRegexp && !this.stringFormatRegexp.test(val.trim())) {
                this.localValue = '';
            } else {
                this.localValue = val.trim();
            }
        },
        formatPhoneNumber(val) {
            //strip all non-numeric characters
            let replaceSequence = /[a-zA-Zа-яА-Я.!#$()%&'\-*+=?^`{|\[\]}~]/g;
            let strippedVal = val.replace(replaceSequence, '').replace(/\s+/g, '');
            if (strippedVal.length == 7) {
                return `${strippedVal.substring(0, 3)}-${strippedVal.substring(3)}`;
            }
            if (strippedVal.length == 10) {
                return `(${strippedVal.substring(0, 3)}) ${strippedVal.substring(3, 6)}-${strippedVal.substring(6)}`;
            }
            return val;
        },
        onKeyUp(e) {
            this.$emit('typing', e.target.value.trim());
        },
		temporaryInputHandler(value) {
			// TODO: remove when new Lookup for Import will be created
			let selectedDomain = value;
			selectedDomain.selected = true;
			this.$store.commit('wizard/SET_DOMAINS', [selectedDomain]);
			this.$store.commit('wizard/UPDATE_EDITED_FLAG', true);
		}
    },
}
</script>
<style src="./stringeditor.less" scoped></style>
