<template>
	<div class="file-upload">
		<div class="file-exists-message" v-bind:class="{'disabled': options.disabled}">
			<div class="required-mark" v-if="showRequiredMark && !isLoading">
				<svg class="svg-icon svg-8 layout-icons-required">
					<use xlink:href="#layout-icons-required"></use>
				</svg>
			</div>
			<div v-if="isLoading" key="uploading" v-localization="{ key: 'controls.ndaFileEditor.uploadingLbl'}"></div>
			<div v-else-if="value">{{value}}</div>
			<div v-else key="noFileChosen" v-localization="{key: labelKey || 'controls.ndaFileEditor.noFileChoosenLbl'}"></div>
		</div>
		<!--todo: find a better way-->
		<span type="submit"
			  v-show="!value"
			  @click="!options.disabled&&uploadFile()"
			  class="button"
			  v-bind:class="{'disabled': options.disabled}"
			  v-localization="{key:'controls.ndaFileEditor.chooseFileLbl'}"
			  v-data-attr="{title: options.title, $type: 'dialog.button.submit'}"></span>
		<span type="submit"
			  class="button"
			  v-show="value"
			  @click="removeFile"
			  v-localization="{key:'controls.ndaFileEditor.removeLbl'}"
			  v-data-attr="{title: options.title, $type: 'dialog.button.cancel'}"></span>
		<input name="file" ref="fileInput" type="file" class="file-input" :accept="options.acceptExtensions" @change="fileUploaded" v-data-attr="{title: options.title, $type: 'dialog.input.upload'}"/>
	</div>
</template>
<script>
export default {
	name: 'control-file-upload',
	props: {
		value: String,
		options: Object,
		evaluationContext: Object,
		showRequiredMark: Boolean,
		labelKey: String
	},
	data() {
		return { isLoading: false };
	},
	created() {
		this.localizationProvider = sc.classes.get('localization.dataProvider');
		this.defaultRestrictedExtensions = ['exe', 'bat', 'cms', 'msi', 'com', 'json', 'cmd'];
	},
	methods: {
		uploadFile() {
			this.$refs.fileInput.click();
		},
		removeFile() {
			this.$emit('input', null);
			this.$root.$emit('fileUploaded', null);
			this.$refs.fileInput.value = '';
		},
		async doUpload(body, name) {
			this.$root.$emit('fileUploaded', {
				body,
				name
			});
			this.$emit('input', name);
			this.isLoading = false;
		},
		fileUploaded(e) {
			if (e.target.files.length > 0) {
				const raw = e.target.files[0];
				const fileName = raw.name;
				const ext = fileName.split('.').pop().toLowerCase();
				let invalidExtension = false;
				if (this.options.allowedExtensions && this.options.allowedExtensions.length) {
					invalidExtension = this.options.allowedExtensions.indexOf(ext) === -1;
				} else {
					invalidExtension = this.defaultRestrictedExtensions.indexOf(ext) >= 0;
				}
				if (invalidExtension) {
					const message = this.localizationProvider.localize('controls.fileUpload.extensionError');
					sc.utils.errorMessage.byMessage(message);
					e.target.value = null;
					return false;
				}

				// byteLimit can be set in control configuration, but it should not be more than 50 mb
				if (this.options.byteLimit && (raw.size > 52428800 || raw.size > this.options.byteLimit)) {
					const mbytes = this.options.byteLimit / 1024 / 1024;
					const message = this.localizationProvider.localize('controls.fileUpload.sizeError', [mbytes]);
					sc.utils.errorMessage.byMessage(message);
					e.target.value = null;
					return false;
				}
				this.isLoading = true;

				this.doUpload(raw, fileName);
			}
		}
	}
};
</script>
<style src="./file-upload.less" scoped></style>
