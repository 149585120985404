var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "manage-roles" }, [
    _c("div", { staticClass: "dialog-container" }, [
      _c("h5", {
        directives: [
          {
            name: "localization",
            rawName: "v-localization",
            value: { key: "dialogues.manageSecurityRoles.selectRole" },
            expression: "{ key: 'dialogues.manageSecurityRoles.selectRole'}"
          }
        ],
        staticClass: "subheader"
      }),
      _vm._v(" "),
      _c("div", { staticClass: "input-radio-wrapper" }, [
        _c("label", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedGroup,
                expression: "selectedGroup"
              }
            ],
            attrs: { type: "radio", value: "Guest" },
            domProps: { checked: _vm._q(_vm.selectedGroup, "Guest") },
            on: {
              change: function($event) {
                _vm.selectedGroup = "Guest"
              }
            }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "p1" }, [_vm._v("Guest")])
        ]),
        _vm._v(" "),
        _c("label", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedGroup,
                expression: "selectedGroup"
              }
            ],
            attrs: { type: "radio", value: "Broker" },
            domProps: { checked: _vm._q(_vm.selectedGroup, "Broker") },
            on: {
              click: _vm.selectMSODSRole,
              change: function($event) {
                _vm.selectedGroup = "Broker"
              }
            }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "p1" }, [_vm._v("Internal User")])
        ])
      ]),
      _vm._v(" "),
      _vm.selectedGroup === "Broker"
        ? _c("h5", {
            directives: [
              {
                name: "localization",
                rawName: "v-localization",
                value: { key: "dialogues.manageSecurityRoles.assignAddRoles" },
                expression:
                  "{ key: 'dialogues.manageSecurityRoles.assignAddRoles'}"
              }
            ],
            key: "Broker",
            staticClass: "subheader"
          })
        : _c("h5", {
            directives: [
              {
                name: "localization",
                rawName: "v-localization",
                value: {
                  key: "dialogues.manageSecurityRoles.noAddRolesForGuest"
                },
                expression:
                  "{ key: 'dialogues.manageSecurityRoles.noAddRolesForGuest'}"
              }
            ],
            key: "Guest",
            staticClass: "subheader disabled"
          }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "roles-wrapper",
          class: { disabled: _vm.selectedGroup !== "Broker" }
        },
        _vm._l(_vm.roles, function(role) {
          return role.enable
            ? _c(
                "div",
                {
                  key: role.value,
                  staticClass: "role",
                  on: {
                    click: function($event) {
                      _vm.selectRole(role)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "checkbox-wrapper" }, [
                    _c(
                      "div",
                      {
                        staticClass: "virtual-checkbox clickable",
                        class: role.checked ? "selected" : "unselected"
                      },
                      [
                        _c("svg", { staticClass: "svg-icon" }, [
                          _c("use", {
                            staticClass: "layout-icons-ms-checkbox",
                            attrs: { "xlink:href": "#layout-icons-ms-checkbox" }
                          })
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "label p2" }, [
                    _vm._v(_vm._s(role.name))
                  ])
                ]
              )
            : _vm._e()
        })
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c(
        "button",
        {
          directives: [
            {
              name: "data-attr",
              rawName: "v-data-attr",
              value: {
                title: _vm.options.title,
                $type: "dialog.button.cancel"
              },
              expression:
                "{title: options.title, $type: 'dialog.button.cancel'}"
            }
          ],
          staticClass: "cancel",
          on: { click: _vm.onCancel }
        },
        [_vm._v("Cancel")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          directives: [
            {
              name: "data-attr",
              rawName: "v-data-attr",
              value: {
                title: _vm.options.title,
                $type: "dialog.button.submit"
              },
              expression:
                "{title: options.title, $type: 'dialog.button.submit'}"
            }
          ],
          on: { click: _vm.onSubmit }
        },
        [_vm._v("Save")]
      )
    ]),
    _vm._v(" "),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ],
      staticClass: "loading-overlay"
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }