<template>
	<div class="link-wrapper" :title="title" v-show="visible" @click="trackActivity">
		<a class="download-link" target="blank" download :href="url" :text="title">{{title}}</a>
	</div>
</template>
<script>
import fileDownload from './file-download.mixin';

export default {
	name: 'file-download',
	mixins: [fileDownload]
};
</script>
<style src="./file-download.less" scoped></style>
