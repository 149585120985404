var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tree", {
    directives: [
      {
        name: "perfectscroll",
        rawName: "v-perfectscroll",
        value: { enabled: true },
        expression: "{ enabled:true }"
      }
    ],
    ref: "tree",
    staticClass: "folder-tree",
    attrs: { data: _vm.treeData, options: _vm.treeOptions },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var node = ref.node
          return _c(
            "span",
            { staticClass: "tree-text" },
            [
              node.children.length && !node.expanded()
                ? [
                    _c("svg", { staticClass: "svg-icon-folder" }, [
                      _c("use", {
                        attrs: { "xlink:href": "#layout-icons-folder-plus" }
                      })
                    ])
                  ]
                : _vm._e(),
              _vm._v(" "),
              node.children.length && node.expanded()
                ? [
                    _c("svg", { staticClass: "svg-icon-folder" }, [
                      _c("use", {
                        attrs: { "xlink:href": "#layout-icons-folder-minus" }
                      })
                    ])
                  ]
                : _vm._e(),
              _vm._v(" "),
              [
                _c("svg", { staticClass: "layout-icons-folder" }, [
                  _c("use", {
                    attrs: {
                      "xlink:href": [
                        node.expanded()
                          ? "#layout-icons-folder-opened"
                          : "#layout-icons-folder-closed"
                      ]
                    }
                  })
                ]),
                _vm._v(" "),
                _c("p", { class: [node.data.active ? "p2" : "p13"] }, [
                  _vm._v(_vm._s(node.text))
                ])
              ]
            ],
            2
          )
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }