﻿import { BaseAspect } from './base.aspect';
import { OnAvatarUpdate } from '@/Data/avatar-service';

export class AvatarUpdateAspect extends BaseAspect {
	constructor() {
		super();
		this.stage = 'complete';
	}

	execute(options) {
		if (options.saveResult.avatarid) {
			OnAvatarUpdate({
				id: options.saveResult.avatarid,
				logicalname: options.saveResult.type
			});
		}

		return Promise.resolve();
	}
}
