import { ContactUsAfterSaveAspect } from './aspects/contactUsAfterSave.aspect';

export class ContactUs {
	constructor(options) {
		this.options = options;
	}

	installAspects() {
		return [new ContactUsAfterSaveAspect(this.options)];
	}
}
