var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dialog-content-wrapper",
      class: { "with-name-field": _vm.options.params.customName !== void 0 }
    },
    [
      _vm.options.params.customName !== void 0
        ? _c(
            "div",
            { staticClass: "name" },
            [
              _c("p", [_vm._v("List name")]),
              _vm._v(" "),
              _c("string-editor", {
                attrs: {
                  showRequired: !_vm.nameValue && !_vm.nameFieldFocused,
                  enable: true,
                  isValid: _vm.nameFieldValid,
                  dataAttr: { title: _vm.options.title, $type: "dialog.input" }
                },
                on: {
                  input: _vm.onNameInputChanged,
                  focused: _vm.onFocusedChange
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "search-result-dialog" }, [
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isLoading,
              expression: "isLoading"
            }
          ],
          staticClass: "loading-overlay large"
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "dialog-container" },
          [
            _c(_vm.searchResultComponentName, {
              tag: "component",
              attrs: {
                options: _vm.componentOptions,
                evaluationContext: _vm.csEvaluationContext
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "footer" }, [
          _c("div", { staticClass: "statistic" }, [
            _vm.validationError
              ? _c("div", { staticClass: "selection-info error" }, [
                  _c("span", {
                    directives: [
                      {
                        name: "localization",
                        rawName: "v-localization",
                        value: { key: _vm.validationError },
                        expression: "{key: validationError}"
                      }
                    ]
                  })
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "buttons" }, [
            _vm.options.params.modalDialogCallback
              ? _c("span", { staticClass: "button-wrapper" }, [
                  _c("button", {
                    directives: [
                      {
                        name: "localization",
                        rawName: "v-localization",
                        value: { key: _vm.saveBtnLocalizationKey },
                        expression: "{key: saveBtnLocalizationKey}"
                      },
                      {
                        name: "data-attr",
                        rawName: "v-data-attr",
                        value: {
                          title: _vm.options.title,
                          $type: "dialog.button.cancel"
                        },
                        expression:
                          "{title: options.title, $type: 'dialog.button.cancel'}"
                      }
                    ],
                    attrs: { type: "button" },
                    on: { click: _vm.addListing }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("button", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: { key: _vm.cancelBtnText },
                  expression: "{key: cancelBtnText}"
                },
                {
                  name: "data-attr",
                  rawName: "v-data-attr",
                  value: {
                    title: _vm.options.title,
                    $type: "dialog.button.cancel"
                  },
                  expression:
                    "{title: options.title, $type: 'dialog.button.cancel'}"
                }
              ],
              staticClass: "cancel",
              attrs: { type: "button" },
              on: { click: _vm.cancel }
            })
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }