<template>
	<sharedtree ref="sharedtree" :data="sharedTreeData" :options="sharedTreeOptions" v-perfectscroll="{ enabled:true, addMouseoverEvent: true, onceMouseover: false }">
		<span class="tree-text" slot-scope="{ node }">
			<template v-if="!node.expanded() && (node.data.hasChildren || node.children.length)">
				<svg class="svg-icon-folder">
					<use xlink:href="#layout-icons-folder-plus"></use>
				</svg>
			</template>
			<template v-if="node.expanded() && (node.data.hasChildren || node.children.length)">
				<svg class="svg-icon-folder">
					<use xlink:href="#layout-icons-folder-minus"></use>
				</svg>
			</template>
			<template>
				<svg class="layout-icons-folder">
					<use :xlink:href="[node.expanded() ? '#layout-icons-folder-opened' : '#layout-icons-folder-closed']"></use>
				</svg>
				<p :class="[node.data.active ? 'p2':'p13']">{{ node.text }}</p>
			</template>
		</span>
	</sharedtree>
</template>
<script>
import { Search } from '@acx-xms/data-functions/dist';
import LiquorTree from 'liquor-tree';
import MsSharedFolderState from '@/States/modules/ms-sharedfolder-state';
export default {
	name: 'sharedfolder-tree',
	props: {
		sharedRootName: String,
		srNamespace: {
			type: String,
			required: true
		},
		folderSrNamespace: {
			type: String,
			required: true
		},
		sharedlogicalname: {
			type: String,
			required: true
		},
		sharedTreeLogicalName: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			sharedTreeData: [],
			sharedTreeOptions: {
				parentSelect: true,
				fetchData: async (node) => {
					await this.getCategoriesChildren(node);
				}
			},
			stateNamespace: 'sharedfolder-tree'
		};
	},
	components: { sharedtree: LiquorTree },
	computed: {
		state() {
			return this.$store.state[this.stateNamespace];
		},
		activeSharedFolder() {
			return this.state && this.state.activeSharedFolder;
		},
		sharedFolderTree() {
			return this.state && this.state.sharedTree;
		},
		isCurrentTree() {
			return this.state && this.state.isCurrentTree;
		}
	},
	watch: {
		sharedTreeData: {
			handler: function (newVal) {
				this.$refs.sharedtree.setModel(newVal);
				this.$store.commit(`${this.stateNamespace}/setSharedFolderTree`, newVal);
			},
			deep: true
		}
	},
	created() {
		this.sharedSearchResults = [];
		this.breadcrumbs = [];
		if (!this.state) {
			this.$store.registerModule(this.stateNamespace, MsSharedFolderState);
		};

		this.sharedCategories = [
			{
				name: 'Collaboration Rooms',
				entity: 'collaborationroom',
				id: 'cc9ed149-ef63-4b44-ae5f-6c34ce5e735f'
			},
			{
				name: 'Deal Rooms',
				entity: 'dealroom',
				id: 'a5e7d8df-9c82-4f77-984e-f49ac507364b',
				treeFilters: [
					sc.classes.get('termFacet.filter', {
						logicalName: 'listingid.id',
						query: [null],
						negative: true
					}).fillQuery()
				]
			}
		];

		this.categoriesIds = this.sharedCategories.map(category => category.id);
		this.categoriesEntities = this.sharedCategories.map(category => category.entity);

		if (!this.isCurrentTree && this.sharedFolderTree) {
			this.sharedTreeData = this.sharedFolderTree;
		} else {
			// TODO: Move to Configuration
			// Hardcoded ids need for process parent ids in root categories
			if (!this.isCurrentTree && !this.sharedFolderTree) {
				this.init();
			}
		}
		this.$parent.$on(this.srNamespace + '.componentSetChanged', () => {
			this.$store.commit(`${this.stateNamespace}/setIsCurrentTree`, false);
			this.clearActiveNodes(this.sharedTreeData);
		});

		this.$root.$on(this.srNamespace + '.entity.searched', () => {
			this.init();
			if (this.activeSharedFolder) {
				this.processActiveSharedFolder(this.activeSharedFolder);
			}
		});

		this.$root.$on(['entity.created', 'entity.changed'], this.checkForUpdateFolder);
		this.$root.$on(this.srNamespace + '.setActiveFolder', this.onSetActiveFolder);
	},
	mounted() {
		this.$refs.sharedtree.$on('node:clicked', this.onClick);
		this.$refs.sharedtree.$on('node:expanded', this.onExpand);
		this.$refs.sharedtree.$on('node:collapsed', this.onCollapse);
	},
	beforeDestroy() {
		this.$root.$off(this.srNamespace + '.entity.searched');
		this.$root.$off(['entity.created', 'entity.changed'], this.checkForUpdateFolder);
		this.$root.$off(this.srNamespace + '.setActiveFolder', this.onSetActiveFolder);
	},
	methods: {
		init() {
			this.sharedSearchResults = [];
			this.sharedTreeData = [{
				data: {
					folderId: null,
					active: false,
					disabled: true,
					isRoot: true
				},
				text: this.sharedRootName || 'Shared Documents',
				children: this.sharedCategories.map(category => {
					return {
						data: {
							folderId: category.id,
							entity: category.entity,
							parentId: null,
							disabled: true,
							treeFilters: category.treeFilters,
							isRootCategory: true,
							hasChildren: true
						},
						children: [],
						text: category.name,
						isBatch: true
					};
				}),
				state: { expanded: true }
			}];
		},
		async processActiveSharedFolder(node) {
			const { Results } = await Search([node.data.type], [
				sc.classes.get('offsetFrom.filter', 0),
				sc.classes.get('offsetSize.filter', 1),
				sc.classes.get('termFacet.filter', {
					logicalName: 'recordstate.id',
					query: ['8d113fa8-3015-4060-a107-14cedcd19dd3']
				}).fillQuery(),
				sc.classes.get('termFacet.filter', {
					logicalName: `${node.data.type}id`,
					query: [node.data.folderId]
				}).fillQuery(),
				sc.classes.get('selectedFields.filter', [
					{ logicalname: 'parentfolder' },
					{ logicalname: 'collaborationroomid' }, // TODO: map from Categories
					{ logicalname: 'dealroomid' }
				]).fillQuery()
			]);
			if (Results.length) {
				const record = Results[0];
				const activeCategory = this.findParentEntity(record);
				await this.getCategoriesChildren(activeCategory);
				if (this.categoriesIds.indexOf(node.data.parentId) === -1) { // because node with root category parent cannot relocate
					const parentEntity = this.categoriesEntities.find(entityName => {
						return record.Source[`${entityName}id`];
					});
					const parentNode = this.searchTree(this.sharedTreeData[0], record.Source[`${parentEntity}id`].id);
					await this.getCategoriesChildren(parentNode);

					if (record.Source.parentfolder) {
						this.expandParent(record.Source.parentfolder.id);
					}
				}
				this.$emit('setBreadcrumbs', this.breadcrumbs);
				this.createLookupPath(node);
			} else {
				this.$root.$emit(this.folderSrNamespace + '.setActiveFolder', { data: { folderId: null } });
			}
		},
		async getCategoriesChildren(node, isdescending = false) {
			let filters = [
				sc.classes.get('offsetFrom.filter', 0),
				sc.classes.get('offsetSize.filter', 9999),
				sc.classes.get('termFacet.filter', {
					logicalName: 'recordstate.id',
					query: ['8d113fa8-3015-4060-a107-14cedcd19dd3']
				}).fillQuery(),
				sc.classes.get('selectedFields.filter', [
					{ logicalname: 'parentfolder' },
					{ logicalname: 'collaborationroomid' }, // TODO: map from Categories
					{ logicalname: 'dealroomid' }
				]).fillQuery(),
				sc.classes.get('orderBy.filter', {
					query: [{
						logicalName: node.data.entity === 'sharedfolder' ? 'title' : 'name',
						value: isdescending
					}]
				})
			];
			if (node.data.treeFilters) {
				filters = filters.concat(node.data.treeFilters);
			}

			const folder = this.searchTree(this.sharedTreeData[0], node.data.folderId);
			folder.isBatch = false;
			this.$set(folder, 'state', { expanded: true });

			const { Results } = await Search([node.data.entity], filters);

			if (Results.length) {
				this.breadcrumbs = [];
				const results = Results.map(result => {
					return {
						...result,
						title: result.Name,
						parentfolder: result.Source.parentfolder || { id: node.data.folderId }
					};
				});
				this.sharedSearchResults = this.sharedSearchResults.concat(results);
				this.fillSharedTree([folder]);
				this.$set(folder, 'state', { expanded: true });
				if (this.activeSharedFolder) {
					this.setActive(this.activeSharedFolder.data.folderId);
				}
			} else {
				const folder = this.searchTree(this.sharedTreeData[0], node.data.folderId);
				this.$set(folder, 'data', {
					...folder.data,
					hasChildren: false
				});
			}
		},
		fillSharedTree(arr) {
			if (!arr.length) {
				return;
			}
			let children = [];
			arr.forEach(folder => {
				children = this.filterSharedFolder(folder.data.folderId);
				this.$set(folder, 'children', children);
				this.fillSharedTree(folder.children);
			});
		},
		filterSharedFolder(parentId) {
			if (this.categoriesIds.includes(parentId)) { // root subcategories
				return this.sharedSearchResults.filter(item => item.parentfolder && item.parentfolder.id === parentId).map(item => {
					return {
						text: item.title,
						data: {
							folderId: item.Id,
							parentId: item.parentfolder.id,
							hasChildren: true,
							callback: (stateNamespace) => {
								this.$store.commit(stateNamespace + '/addOrUpdateFilter', {
									entity: this.sharedlogicalname,
									logicalName: 'parentrecord',
									filter: null
								});
								this.$store.commit(stateNamespace + '/addOrUpdateFilter', {
									entity: this.sharedlogicalname,
									logicalName: 'parentrecord',
									filter:
											sc.classes.get('termFacet.filter', {
												logicalName: `${item.Type}id.id`,
												query: [item.Id]
											}).fillQuery()
								});
								this.$store.commit(stateNamespace + '/addOrUpdateFilter', {
									entity: this.sharedlogicalname,
									logicalName: 'parentfolder.id',
									filter:
											sc.classes.get('termFacet.filter', {
												logicalName: 'parentfolder.id',
												query: [null]
											}).fillQuery()
								});
								if (!this.isCurrentTree) {
									this.$store.commit(`${this.stateNamespace}/setIsCurrentTree`, true);
								}
							},
							treeFilters: [
								sc.classes.get('termFacet.filter', {
									logicalName: `${item.Type}id.id`,
									query: [item.Id]
								}).fillQuery()
							],
							type: item.Type,
							entity: this.sharedTreeLogicalName
						},
						isBatch: true
					};
				});
			}
			return this.sharedSearchResults.filter(item => item.parentfolder && item.parentfolder.id === parentId).map(item => {
				return {
					text: item.title,
					data: {
						folderId: item.Id,
						parentId: item.parentfolder.id,
						callback: (stateNamespace) => {
							this.$store.commit(stateNamespace + '/addOrUpdateFilter', {
								entity: this.sharedlogicalname,
								logicalName: 'parentrecord',
								filter: null
							});
							this.$store.commit(stateNamespace + '/addOrUpdateFilter', {
								entity: this.sharedlogicalname,
								logicalName: 'parentfolder.id',
								filter:
										sc.classes.get('termFacet.filter', {
											logicalName: 'parentfolder.id',
											query: [item.Id]
										}).fillQuery()
							});
							if (!this.isCurrentTree) {
								this.$store.commit(`${this.stateNamespace}/setIsCurrentTree`, true);
							}
						},
						type: item.Type,
						entity: this.sharedTreeLogicalName
					}
				};
			});
		},
		findParentEntity(record) {
			return this.sharedTreeData[0].children.find(categoryNode => {
				return record.Source[`${categoryNode.data.entity}id`];
			});
		},
		// TODO: move to mixin
		searchTree(element, matchingId) {
			if (element.data.folderId === matchingId) {
				return element;
			} else if (element.children != null) {
				let result = null;
				for (let i = 0; result == null && i < element.children.length; i++) {
					result = this.searchTree(element.children[i], matchingId);
				}
				return result;
			}
			return null;
		},
		setActive(id) {
			const result = this.searchTree(this.sharedTreeData[0], id);
			if (!result) {
				return;
			}
			this.$set(result.data, 'active', true);
			this.breadcrumbs.unshift(result);
			if (result.data.hasOwnProperty('parentId')) {
				this.setActive(result.data.parentId);
			}
		},
		setExpanded(node) {
			const result = this.searchTree(this.sharedTreeData[0], node.data.folderId);
			this.$set(result, 'state', { expanded: node.expanded() });
		},
		setCollapsed(node) {
			const result = this.searchTree(this.sharedTreeData[0], node.data.folderId);
			this.$set(result, 'state', { collapsed: node.collapsed() });
		},
		clearActiveNodes(arr) {
			if (!arr.length) {
				return;
			}
			arr.forEach(folder => {
				this.$set(folder.data, 'active', false);
				this.clearActiveNodes(folder.children);
			});
		},
		expandParent(parentId) {
			if (parentId) {
				const parent = this.searchTree(this.sharedTreeData[0], parentId);
				this.$set(parent, 'state', { expanded: true });
				this.expandParent(parent.data.parentId);
			}
		},
		onExpand(node) {
			this.setExpanded(node);
		},
		onCollapse(node) {
			this.setCollapsed(node);
		},
		// TODO: end of mixin
		onSetActiveFolder(node) {
			const result = this.searchTree(this.sharedTreeData[0], node.data.folderId);
			if (!result) {
				node.data.callback = (stateNamespace) => {
					this.$store.commit(stateNamespace + '/addOrUpdateFilter', {
						entity: this.sharedlogicalname,
						logicalName: 'parentrecord',
						filter: null
					});
					this.$store.commit(stateNamespace + '/addOrUpdateFilter', {
						entity: this.sharedlogicalname,
						logicalName: 'parentfolder.id',
						filter:
								sc.classes.get('termFacet.filter', {
									logicalName: 'parentfolder.id',
									query: [node.data.folderId]
								}).fillQuery()
					});
					if (!this.isCurrentTree) {
						this.$store.commit(`${this.stateNamespace}/setIsCurrentTree`, true);
					}
				};
				this.$store.commit(`${this.stateNamespace}/setCurrentActive`, node);
				this.$emit('sharedTreeClicked', node, this.srNamespace);
			} else {
				this.onClick(result);
			}
		},
		onClick(node) {
			const menuPopupKey = this.$store.getters.menuPopupKey;
			if (menuPopupKey) {
				this.$root.$emit(menuPopupKey + '.absoluteMenu.close');
			}
			if (node.data.isRoot) {
				return;
			}
			if (node.data.isRootCategory) {
				if (node.isBatch) {
					this.getCategoriesChildren(node);
				} else {
					if (node.expanded()) {
						node.collapse();
					} else {
						node.expand();
					}
				}
			} else {
				this.$store.commit(`${this.stateNamespace}/setCurrentActive`, node);
				this.$emit('sharedTreeClicked', node, this.srNamespace);
			}
		},
		createLookupPath(node) {
			const searchResult = this.sharedSearchResults.find(result => result.Id === node.data.folderId);
			let parentRecord = null;
			let lookupRootName = '';
			if (this.categoriesEntities.includes(node.data.type)) {
				parentRecord = {
					id: searchResult.Id,
					logicalname: searchResult.Type
				};
				lookupRootName = searchResult.Name;
				this.$store.commit(`${this.stateNamespace}/setPredefinedParent`, null);
			} else {
				const parentRecordKey = Object.keys(searchResult.Source).find(key => {
					return searchResult.Source[key] && this.categoriesEntities.includes(searchResult.Source[key].logicalname);
				});
				parentRecord = searchResult.Source[parentRecordKey];
				const predefinedParent = {
					id: searchResult.Id,
					logicalname: searchResult.Type
				};
				lookupRootName = this.sharedSearchResults.find(result => result.Id === parentRecord.id).Name;
				this.$store.commit(`${this.stateNamespace}/setPredefinedParent`, predefinedParent);
			}
			this.$store.commit(`${this.stateNamespace}/setParentRecord`, parentRecord);

			this.$store.commit(`${this.stateNamespace}/setLookupRootName`, lookupRootName);
		},
		async checkForUpdateFolder({ entity }) {
			if (this.categoriesEntities.includes(entity.logicalname)) {
				await this.init();
				if (this.activeSharedFolder) {
					this.processActiveSharedFolder(this.activeSharedFolder);
				}
			}
		}
	}
};
</script>
<style src="./folder-tree.less" scoped></style>
