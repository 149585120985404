<template>
	<div class="repotingservice4-templateslist">
		<div class="filters">
			<div class="back">
				<div class="control-button tooltip dialog-back-hover-parent clickable" @click="back">
					<svg class="svg-icon svg-12 back-button">
						<use xlink:href="#layout-icons-details-header-back"></use>
					</svg>
				</div>
				<span class="caption" v-localization="{key: 'dialogues.RS4.changeTemplate'}"></span>
			</div>
			<div class="sections">
				<div class="section" v-for="(filter,index) in filters" v-if="!filter.isMainSearchField && filter.visible" :key="index">
					<div class="filter-header" @click="filter.isExpanded = !filter.isExpanded">
						<span class="caption">{{filter.sectionName}}</span>
						<span class="icon">
							<svg class="dropdown-arrow-open svg-icon svg-10" v-if="filter.isExpanded">
								<use xlink:href="#layout-icons-ms-arrow-up" class="layout-icons-ms-arrow-up hover"></use>
							</svg>
							<svg class="dropdown-arrow svg-icon svg-10" v-else>
								<use xlink:href="#layout-icons-ms-arrow-down" class="layout-icons-ms-arrow-down hover"></use>
							</svg>
						</span>
					</div>
					<ul class="filter-list" v-show="filter.isExpanded">
						<li class="item" :class="{'isChecked': filter.isSelected}" v-for="(item,indexLi) in filter.items" v-show="item.visible" :key="indexLi">
							<label>
								<div class="item-checkbox">
									<div class="virtual-checkbox clickable" :class="{ selected: item.isSelected }">
										<svg class="svg-icon">
											<use xlink:href="#layout-icons-ms-checkbox" class="layout-icons-ms-checkbox"></use>
										</svg>
									</div>
									<input type="checkbox" :checked="item.isSelected" :value="item.name" @input="item.isSelected = !item.isSelected">
								</div>

								<div class="item-info">
									<div class="name">
										<span class="tooltip-wrapper">
											<p :tooltip="item.displayName">{{item.displayName}}</p>
										</span>
									</div>
								</div>
							</label>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="templates">
			<div class="search-area">
				<div class="templates-title h5" v-localization="{key: 'dialogues.RS4.templates.templatesTitle'}"></div>
				<div class="search-field-container">
					<search-field :options="searchFieldOptions" />
				</div>
			</div>
			<div class="templates-list-wrapper">
				<control class="templates-list"
						 v-if="tableViewTemplate && templates.length && !isLoading"
						 :name="tableViewTemplate.$type"
						 :contentProps="tableViewTemplate"
						 :initResults="templatesWithPagination"
						 :key='templateKey'
						 :onSelect="onSelect"></control>
				<div class="no-results-message p13" v-if="!templates.length && !isLoading">
					<div v-localization="{ key: 'common.dictionary.noMatchesFound' }"></div>
				</div>
				<div class='footer' v-if="!isLoading">
					<pagination v-if="paginationParams"
								:pageSize="paginationParams.pageSize"
								:total="paginationParams.total"
								:from="paginationParams.from"
								:showPaging="paginationParams.showPaging"
								:activePages="paginationParams.activePages"
								:showWrapperIfEmpty="true"
								:maxLength="paginationPages" />
					<button class="secondary"
							@click="openHtmlTemplates"
							v-localization="{key: 'dialogues.RS4.dialog.manage'}">
					</button>
				</div>
			</div>
		</div>
		<div class="reportingservice-overlay loading-overlay large" v-show="isLoading"></div>
	</div>
</template>
<script>
/* eslint no-empty: 0 */
import helperMethods from '@/Components/ComponentSet/component-set-evaluation-context-helper.methods';
import Control from '@/Components/Entity/control';
import Pagination from '@/Components/ComponentSet/pagination/pagination';
import SearchField from '@/Components/ComponentSet/SearchResults/search-field/search-field';
import dataProvider from '@/Data/DataProviders/reportingserviceDataprovider';
import coDataProvider from '@/Data/DataProviders/coDataprovider';
import RS4Utils from './reportingservice.Utils';
import { debounce } from '@acx-xms/data-functions/dist';

export default {
	name: 'reportingservice-uiw-templateslist',
	props: { options: Object },
	components: {
		Control,
		Pagination,
		SearchField
	},
	data() {
		return {
			templates: [],
			filters: null,
			isLoading: true,
			tableViewTemplate: null,
			paginationParams: {
				showPaging: true,
				pageSize: helperMethods.eval(null, this.options.settings.templatesPerPage) || 12,
				total: 0,
				activePages: [1],
				from: 0
			},
			isManager: this.options.isManager,
			paginationPages: 5
		};
	},
	watch: {
		filters: {
			deep: true,
			handler() {
				this.debouncedSend();
			}
		}
	},
	computed: {
		templatesWithPagination() {
			const pageSize = this.paginationParams.pageSize * (this.paginationParams.activePages.length);
			return [...this.templates].splice(this.paginationParams.from, pageSize);
		},
		templateKey() {
			return this.templatesWithPagination.length + this.templatesWithPagination[0].templateName + this.paginationParams.activePages.length;
		}
	},
	async created() {
		this.searchFieldOptions = { debouncedWatchSearchQuery: { delay: 1500 } };
		this.debouncedSend = debounce(() => this.search(), 1500);

		const getTemplatePromise = sc.classes.get('entityConfiguration.dataProvider').getTemplate('reports', 'custom', 'table-view');
		[this.tableViewTemplate] = await Promise.all([getTemplatePromise, this.applyFilter()]);

		this.$on('.pageChanged', (activePages) => {
			this.paginationParams.from = (activePages[0] - 1) * this.paginationParams.pageSize;
			this.paginationParams.activePages = activePages;
		});
		this.$on('execute-search', (searchQuery) => {
			this.executeSearch(searchQuery);
		});
		this.$on('search-query-changed', (searchQuery) => {
			this.executeSearch(searchQuery);
		});

		this.onResize = debounce(() => {
			const innerWidth = window.innerWidth;
			if (innerWidth < 1327) {
				this.paginationPages = 3;
			} else {
				this.paginationPages = 5;
			}
		}, 1500);

		window.addEventListener('resize', this.onResize);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},
	methods: {
		back() {
			this.options.onClose();
		},
		onSelect(item) {
			this.options.onSelect(item);
		},
		executeSearch(searchQuery) {
			const index = this.filters.findIndex(f => f.isMainSearchField);
			this.filters[index].items[0].name = searchQuery;
			this.search();
		},
		getReportingServiceEntities(settings) {
			let entities;
			if (!settings.inputType) {
				entities = this.getAddedRecordTypes();
			} else {
				entities = settings.inputType.option.map((e) => {
					return e.value;
				});
			}
			return entities;
		},

		getAddedRecordTypes() {
			return this.options.recordsList.map((record) => {
				return record.entityName;
			});
		},

		composeOptions(options) {
			return options.map((o) => {
				return o instanceof Object ? o : this.optionToObject(o);
			});
		},

		optionToObject(value, isSelected) {
			return {
				value,
				isSelected
			};
		},

		getFilterData() {
			const settings = _.cloneDeep(this.options.settings.templateFilters);
			const filterMapping = RS4Utils.filterMapping;
			for (const key in filterMapping) {
				if (settings[key]) {
					settings[key].visible = settings[key].visible === void 0 ? true : settings[key].visible;
				}
			}
			if (settings.inputType) {
				const allowedEntities = this.getReportingServiceEntities(settings);

				const filteredEntities = this.options.recordsList.filter(item => item.items.length).map(item => item.entityName);

				settings.inputType.option = settings.inputType.option.filter(o => {
					return allowedEntities.indexOf(o.value) > -1;
				}).map(o => {
					if (filteredEntities.indexOf(o.value) === -1) {
						o.isSelected = false;
					}
					return o;
				});
			}
			delete settings.$type;

			return settings;
		},

		async applyFilter() {
			const promises = [];
			const filtersData = this.getFilterData();
			for (const key in filtersData) {
				if (key !== '$type') {
					const filter = { ...RS4Utils.filterMapping[key] };
					filter.items = [];
					filter.visible = filtersData[key].visible;
					filter.isExpanded = true;
					filtersData[key].option.forEach(async (option) => {
						let displayName = option.displayName;
						if (key === 'inputType') {
							const names = await sc.classes.get('entityConfiguration.dataProvider').getEntityCaptions([option.value], 'singular');
							// TODO: remove eval after getEntityCaptions method is refactored
							displayName = helperMethods.eval(null, names[0]);
						}
						if (key === 'format') {
							displayName = option.value;
						}
						filter.items.push({
							isSelected: !!option.isSelected,
							name: option.value,
							visible: option.visible,
							displayName,
							includeEmpty: option.includeEmpty
						});
					});

					promises.push(filter);
				}
			}

			this.filters = await Promise.all(promises);
			this.addMainSearchFilter();
		},

		addMainSearchFilter() {
			this.filters.push({
				isMainSearchField: true,
				targetPath: 'name',
				items: [
					{
						isSelected: true,
						name: ''
					}
				]
			});
		},

		async search(pageChanged) {
			const searchCriteria = this.getSearchCriteria(this.filters);
			const searchCOCriteria = this.getCOSearchCriteria(this.filters);
			if (!pageChanged) {
				this.paginationParams.from = 0;
				this.paginationParams.activePages = [1];
			}
			if (JSON.stringify(this.previousSearchCriteria) !== JSON.stringify(searchCriteria)) {
				this.isLoading = true;
				this.previousSearchCriteria = searchCriteria;
				try {
					const outputFormatFilter = this.filters.find(item => item.targetPath === 'targetOutputFormats');
					let results, coResults;
					if (outputFormatFilter) {
						const searchAll = outputFormatFilter.items.every(item => item.isSelected) || outputFormatFilter.items.every(item => !item.isSelected);
						const searchPdf = outputFormatFilter.items.find(item => item.name === 'pdf').isSelected;
						const searchPptx = outputFormatFilter.items.find(item => item.name === 'pptx').isSelected;

						[results, coResults] = await Promise.all([
							searchAll || searchPdf || searchPptx
								? dataProvider.searchTemplates(searchCriteria)
								: {
									templates: [],
									totalCount: 0
								},
							searchAll || searchPdf
								? coDataProvider.searchTemplates(searchCOCriteria)
								: {
									htmlTemplates: [],
									totalCount: 0
								}
						]);
					} else {
						coResults = await coDataProvider.searchTemplates(searchCOCriteria);
					}
					const loadMore = async (offset, totalCount = 0, includeThumbnail = false, items = { htmlTemplates: [] }) => {
                        if (totalCount > items.htmlTemplates.length && offset < totalCount) {
							const newSearchCOCriteria = this.getCOSearchCriteria(this.filters, offset);
							const result = await coDataProvider.searchTemplates(newSearchCOCriteria, includeThumbnail);

							items.htmlTemplates = [...items.htmlTemplates, ...result.htmlTemplates];
							return loadMore(offset + 50, totalCount, includeThumbnail, items);
						} else {
							return items;
						}
					};
					const loadMoreOld = async (offset) => {
						if (results.totalCount > results.templates.length) {
							const newSearchCriteria = this.getSearchCriteria(this.filters, offset);
							const items = await dataProvider.searchTemplates(newSearchCriteria);
							results.templates = [...results.templates, ...items.templates];
							await loadMoreOld(offset + 50);
						}
					};
					let filteredTemplates = [];
					if (outputFormatFilter) {
                        const [, items] = await Promise.all([loadMoreOld(50), loadMore(50, coResults.totalCount, false, coResults)]);
						coResults.htmlTemplates = [...items.htmlTemplates];
						filteredTemplates = [...results.templates, ...coResults.htmlTemplates];
					} else {
                        const items = await loadMore(50, coResults.totalCount, false, coResults);
						coResults.htmlTemplates = [...items.htmlTemplates];
						filteredTemplates = coResults.htmlTemplates;
					}
					this.templates = filteredTemplates.sort((a, b) => a.templateName < b.templateName ? -1 : (a.templateName > b.templateName ? 1 : 0));
					this.paginationParams.total = filteredTemplates.length;

					if (coResults.totalCount) {
						coDataProvider.searchTemplates(searchCOCriteria, true).then(async (res) => {
                            const loadMoreRes = await loadMore(50, coResults.totalCount, true, res);
							const coTotal = [...loadMoreRes.htmlTemplates];
							const total = [...(results ? results.templates : []), ...coTotal];
							this.templates = total.sort((a, b) => a.templateName < b.templateName ? -1 : (a.templateName > b.templateName ? 1 : 0));
							this.$root.$emit('co.thumbnails.loaded', this.templates);
						});
					}
				} finally {
					this.isLoading = false;
				}
			}
		},

		getSearchCriteria(filtersData, offset = 0) {
			const searchCriteria = {};
			searchCriteria.limit = 50;
			searchCriteria.offset = offset;

			searchCriteria.propertiesFilters = [];

			const onlyUnique = (value, index, self) => {
				return self.indexOf(value) === index;
			};

			filtersData.forEach(filter => {
				const selectedItems = filter.items.filter(item => {
					return item.isSelected;
				});

				switch (filter.targetPath) {
				case '$type': {
					return;
				}
				case 'targetOutputFormats': {
					if (selectedItems.length) {
						const names = selectedItems.map(item => item.name);
						searchCriteria.propertiesFilters.push(
							{
								exactMatch: false,
								name: 'targetOutputFormats',
								values: [names.join(','), ...names].filter(onlyUnique)
							});
					}
					return;
				}
				case 'name': {
					const templateName = filter.items[0].name;

					if (templateName && templateName.length) {
						searchCriteria.nameFilter = {
							exactMatch: false,
							values: [
								templateName
							]
						};
					}
					return;
				}
				case 'templateTypeFilter': {
					return;
				}
				default: {
					let options = null;
					options = selectedItems.map(item => {
						return item.name;
					});
					if (options && options.length) {
						if (selectedItems.some(item => item.includeEmpty)) {
							options.push('');
						}
						searchCriteria.propertiesFilters.push(
							{
								exactMatch: false,
								name: filter.targetPath.split('.').pop(),
								values: options
							});
					}
				}
				}
			});

			return searchCriteria;
		},

		getCOSearchCriteria(filtersData, offset = 0) {
			const searchCriteria = {};
			searchCriteria.limit = 50;
			searchCriteria.offset = offset;
			// TODO: fix mess with filters after old composer is removed
			filtersData.forEach(filter => {
				const selectedItems = filter.items.filter(item => {
					return item.isSelected;
				});

				switch (filter.targetPath) {
				case '$type': {
					return;
				}
				case 'name': {
					const templateName = filter.items[0].name;

					if (templateName && templateName.length) {
						searchCriteria.nameFilter = { value: templateName };
					}
					return;
				}
				case 'inputTypes': {
					const options = selectedItems.map(item => {
						return item.name;
					});
					if (options && options.length) {
						searchCriteria.mainEntitiesFilter = {
							exactMatch: false,
							values: options
						};
					}
					return;
				}
				case 'templateTypeFilter': {
					searchCriteria.templateTypeFilter = {
						exactMatch: true,
						values: filter.items.map(i=>i.name)
					};
					break;
				}
				default: {

				}
				}
			});

			return searchCriteria;
		},
		async manageInCo() {
			await coDataProvider.openCo();
		},
		async openHtmlTemplates() {
			let options = { type: 'html,printable' };
			let entities = this.filters.find(f => f.targetPath == "inputTypes").items.filter(f => f.isSelected);
			if (entities.length == 1) {
				options.entity = entities[0].name;
			}
			await coDataProvider.openCo(options);
		},
	}
};
</script>
<style src="./reportingservice-uiw-templateslist.less" scoped></style>
