<template>
	<div class="delete-restriction-dialog">
		<div class="p4" v-localization="{ key: 'deleteRestrictionDialog.label' }"></div>
		<div class="h5" v-localization="{ key: 'deleteRestrictionDialog.table.title', args: [relatedTotal] }"></div>
		<div class="entity-list">
			<control class="formcontrol-template"
					 v-if="template && relatedRecords.length"
					 :name="template.$type"
					 :contentProps.once="template"
					 :initResults="relatedRecords"></control>
		</div>
		<div class="loading-overlay" v-show="isLoading"></div>
	</div>
</template>
<script>
import { Search } from '@acx-xms/data-functions/dist';
import Control from '@/Components/Entity/control.vue';

export default {
	props: { options: Object },
	data() {
		return {
			relatedRecords: [],
			template: null,
			isLoading: true,
			relatedTotal: 0
		};
	},
	components: { Control },
	async created() {
		this.entityConfiguration = sc.classes.get('entityConfiguration.dataProvider');
		this.template = await this.entityConfiguration.getTemplate('lookup', 'custom', 'restriction-records-template');
		const entityRef = this.options.params.entityRef;
		const oneToManyReferences = await this.getOneToManyReferences(entityRef);
		const relatedRecords = await this.getRelatedRecords(oneToManyReferences, entityRef);
		this.relatedRecords = relatedRecords.Results;
		this.relatedTotal = relatedRecords.Total;
		this.isLoading = false;
	},
	methods: {
		async getOneToManyReferences(entityRef) {
			const entities = await this.entityConfiguration.fetch();
			const oneToManyReferences = {};

			for (const entityLogicalName in entities) {
				const entity = entities[entityLogicalName];

				if (entity.metadata && entity.metadata.index) {
					const fieldsMetadata = Object.values(entity.metadata.index.fields);
					fieldsMetadata.forEach(f => {
						const hasRelation = f.type.$type === 'reference' && f.type.refs.includes(entityRef.logicalname);
						if (hasRelation) {
							if (!oneToManyReferences[entityLogicalName]) { oneToManyReferences[entityLogicalName] = [f.sourceName]; } else { oneToManyReferences[entityLogicalName].push(f.sourceName); }
						}
					});
				}
			}

			return oneToManyReferences;
		},
		async getRelatedRecords(oneToManyReferences = {}, entityRef) {
			const operands = [];
			const logicalNames = Object.keys(oneToManyReferences);

			if (!logicalNames.length) {
				return [];
			}

			for (const oneToManyReference in oneToManyReferences) {
				const fields = oneToManyReferences[oneToManyReference] || [];
				const filters = [];
				fields.forEach(f => {
					filters.push({
						$type: 'search.termFacetFilter',
						negative: false,
						filterViewKind: 'Default',
						item: [
							{
								$type: 'search.termFacetItem',
								value: {
									$type: 'expression.text',
									value: entityRef.id
								}
							}],
						logicalName: f
					});
				});

				operands.push({
					$type: 'search.operand',
					filter: filters
				});
			}

			const logicalFilter = {
				$type: 'search.logicalFilter',
				operator: {
					$type: 'search.or',
					operator: operands
				}
			};

			const filters = [
				sc.classes.get('offsetFrom.filter', 0),
				sc.classes.get('offsetSize.filter', 10),
				sc.classes.get('search.logicalFilter', logicalFilter).toFilter()
			];

			return await Search(logicalNames, filters);
		}
	}
};
</script>
<style scoped>
	.p4 {
		padding: 20px 30px;
	}
	.h5 {
		padding: 0 30px;
	}
</style>
