<template>
	<div v-if="visible" :class="['row custom-row', {'selected': isSelected, 'odd': isOdd, 'noSelection': !allowSingleSelection}]">
		<VirtualCheckbox
			v-if="options.allowSelection && !isRecordToDeactivate && allowSingleSelection"
			:record="rowItem"
			:stateNamespace="stateNamespace"
			:isParent="options.isParent"
			:syncSelection="options.syncSelection"
			:singleSelection="options.singleSelection"
			:evaluationContext="evaluationContext"
			:cssClass="allChildsSelectionClass"
		/>
		<div
			v-for="(cell, index) in filteredContent"
			:key="index"
			:class="['table-cell', cell.cssClass ? getContext().eval(cell.cssClass) : '']"
			:style="{ width: cell.width }"
		>
			<ControlTableViewColumn :options="cell" :evaluationContext="getContext()" />
		</div>
		<div v-show="isLoading || (!inactive && isRecordToDeactivate)" class="loading-overlay" />
	</div>
</template>
<script>
import ComponentMixins from '../../component.mixins';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';
import { GetPerConfig, SetPerConfig } from '@/Data/DataProviders/userStorageDataProvider';
const VirtualCheckbox = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */'./../Checkboxes/virtual-checkbox');
const ControlTableViewColumn = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */'./control-table-view-column');

export default {
	name: 'control-table-view-row',
	props: {
		rowItem: Object,
		content: Array,
		options: Object,
		stateNamespace: String,
		selectAll: Boolean,
		evaluationContext: Object,
		isOdd: Boolean
	},
	mixins: [ComponentMixins],
	components: {
		VirtualCheckbox,
		ControlTableViewColumn
	},
	data() {
		return {
			isSelected: false,
			isMounted: false,
			targetEntity: this.options.targetEntity,
			modalNamespace: this.options.modalNamespace,
			internalNamespace: this.options.internalNamespace,
			namespace: this.options.namespace,
			isLoading: false,
			allowSingleSelection: true,
			visible: true
		};
	},
	computed: {
		state() {
			return this.$store.state[this.stateNamespace];
		},
		recordsToDeactivate() {
			return this.state && this.state.recordsToDeactivate;
		},
		inactive() {
			if (!this.rowItem || !this.rowItem.Source) {
				return false;
			}
			return this.rowItem.Source.recordstate && this.rowItem.Source.recordstate.id === '9e817a63-452c-e811-8320-7824af3b452f';
		},
		isRecordToDeactivate() {
			return this.recordsToDeactivate && this.recordsToDeactivate.some(recordId => recordId === this.rowItem.Id);
		},
		getRowChilds() {
			return (this.rowItem.Type === 'listing' && this.$store.getters[`${this.stateNamespace}/isRecordSelected`]({
				Type: 'listing',
				Id: this.rowItem.Id
			}) && this.rowItem.Source.availabilities && this.rowItem.Source.availabilities.filter(i => i.availabilityid)) || null;
		},
		allChildsSelectionClass() {
			if (this.getRowChilds) {
				const filtered = this.getRowChilds.filter((i) => {
					return this.$store.getters[`${this.stateNamespace}/isRecordSelected`]({
						Type: 'availability',
						Id: i.availabilityid
					});
				});
				const selected = this.getRowChilds.length - filtered.length;
				return (selected < this.getRowChilds.length && selected > 0 && 'semi-selected') || '';
			}
			return '';
		},
		filteredContent() {
			return this.content.filter(element => element.visible ? this.getContext().eval(element.visible) : true);
		}
	},
	mounted() {
		this.isMounted = true;
	},
	created() {
		this.fork = sc.events.fork();
		this.fork.on(`loading.${this.rowItem.Type}.${this.rowItem.Id}`, isLoading => {
			this.isLoading = isLoading;
		});
		this.$root.$on(`hideRow.${this.rowItem.Type}.${this.rowItem.Id}`, async (hide) => {
			this.visible = !hide;
			const recordToHide = {
				logicalname: this.rowItem.Type,
				id: this.rowItem.Id,
				time: Date.now()
			};
			let recordsToHide = await GetPerConfig('hiddenRecords') || [];
			// hide for 1 min
			recordsToHide = recordsToHide.filter(record => Math.abs(Date.now() - record.time) < 1 * 1000 * 60);
			recordsToHide.push(recordToHide);
			await SetPerConfig('hiddenRecords', recordsToHide);
		});
		this.context = this.options.useParentContext ? createEvaluationContext(this.rowItem, this.evaluationContext) : createEvaluationContext(this.rowItem);
		if (this.options.allowSingleSelection) {
			this.allowSingleSelection = this.context.eval(this.options.allowSingleSelection);
		}
		this.$store.subscribe((mutation) => {
			if (this.isRecordToDeactivate && mutation.type === this.stateNamespace + '/changeSelection' && mutation.payload.select) {
				const isIdMatching = mutation.payload && mutation.payload.records.find(record => record.Id === this.rowItem.Id);
				if (isIdMatching) {
					this.$store.commit(this.stateNamespace + '/changeSelection', {
						records: [this.rowItem],
						select: false
					});
				}
				this.$root.$emit('disableResult', this.rowItem.Id);
			}
		});
	},
	beforeDestroy() {
		if (this.fork) {
			this.fork.dispose();
		}
		this.$root.$off(`hideRow.${this.rowItem.Type}.${this.rowItem.Id}`);
	},
	methods: {
		getContext: function () {
			// to remove vue reactivity
			return this.context;
		}
	}
};
</script>
<style src="./control-table-view-row.less" scoped></style>
