<template>
	<div class="control-lookup-editor-wrapper">
		<control-lookup-folder v-model="wrappedValue"
							   :evaluationContext="evaluationContext"
							   :lookupRootName="lookupRootName"
							   :initParentFolderStateKey="options.initParentFolderStateKey"
							   :options="lookupOptions"
							   :showRequired="showRequiredMark"
							   :class="{invalid: !isValid}"></control-lookup-folder>
		<button type="button" class="secondary" :disabled="!enable" v-if="options.allowQuickAdd && logicalName" @click="quickCreate">{{quickCreateButtonName}}</button>
	</div>
</template>
<script>
import LookupEditorWrapperMixins from './control-lookup-editor-wrapper.mixin';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';
const ControlLookupFolder = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Lookup/control-lookup-folder');
export default {
	name: 'control-lookup-folder-editor-wrapper',
	data() {
		return {
			displayName: '',
			quickCreateButtonName: '',
			treeLogicalName: this.options.entityTypes[0]
		};
	},
	mixins: [
		LookupEditorWrapperMixins
	],
	components: { ControlLookupFolder },
	computed: {
		parentRecord() {
			return this.storeState && this.storeState.entityData.parentRecord;
		},
		lookupRootName() {
			return this.storeState && this.storeState.entityData.lookupRootName;
		},
		lookupOptions() {
			return (this.options && this.options.lookupOptions) || {
				placeholder: this.options.placeholder || '',
				rootName: this.options.rootName,
				disabled: !this.enable,
				template: this.options.template,
				treeLogicalName: this.treeLogicalName,
				fetchData: async (searchQuery) => { // here need folder by Ids
					// this we need to handle form changes
					const context = this.storeState
						? createEvaluationContext(this.storeState.entityData)
						: this.evaluationContext;

					const configFilters = this.options.filters
						? this.options.filters.map((filter) => {
							return sc.classes.get(filter.$type, filter, context).toFilter();
						}).filter(filter => filter !== null)
						: [];

					const selectedFields = [
						{ logicalname: 'title' },
						{ logicalname: `${this.treeLogicalName}id` },
						{ logicalname: 'parentfolder' }
					];
					const filters = [
						...configFilters,
						sc.classes.get('offsetFrom.filter', 0),
						sc.classes.get('offsetSize.filter', 9999),
						sc.classes.get('selectedFields.filter', selectedFields).fillQuery(),
						sc.classes.get('termFacet.filter', {
							logicalName: 'recordstate.id',
							query: ['8d113fa8-3015-4060-a107-14cedcd19dd3']
						}).fillQuery(),
						sc.classes.get('orderBy.filter', {
							query: [{
								logicalName: 'title',
								value: false
							}]
						}).fillQuery()
					];
					if (this.parentRecord) {
						selectedFields.push({ logicalname: `${this.parentRecord.logicalname}id` });
						filters.push(
							sc.classes.get('termFacet.filter', {
								logicalName: `${this.parentRecord.logicalname}id.id`,
								query: [this.parentRecord.id]
							}).fillQuery()
						);
					}
					if (searchQuery) {
						filters.push(sc.classes.get('field.filter', {
							logicalName: '_all',
							query: searchQuery
						}));
					}
					let { Results: results } = await sc.classes.get('edge.dataProvider').search({
						filters,
						entities: this.options.entityTypes
					});
					const excludedId = this.options.excludedId && context.eval(this.options.excludedId);
					if (excludedId) {
						const diff = [];
						const filterSearchResults = (id) => {
							diff.push(id);
							const subfolder = results.find(result => result.Source.parentfolder && result.Source.parentfolder.id === id);
							if (subfolder) {
								filterSearchResults(subfolder.Id);
							}
						};
						filterSearchResults(excludedId);
						results = results.filter(result => !diff.includes(result.Id));
					}
					return {
						total: results.Total,
						items: results.map(res => {
							return {
								displayName: res.Name,
								data: res
							};
						})
					};
				}
			};
		}
	}
};
</script>
<style src="./control-lookup-editor-wrapper.less" scoped></style>
