const state = () => (getDefaultState());
const mutations = {
	setCurrentActive(state, record) {
		state.activeSharedFolder = record;
	},
	setParentRecord(state, record) {
		state.lookupPredefinedFields.parentRecord = record;
	},
	setLookupRootName(state, name) {
		state.lookupPredefinedFields.lookupRootName = name;
	},
	setPredefinedParent(state, record) {
		state.lookupPredefinedFields.parentfolder = record;
	},
	setSharedFolderTree(state, tree) {
		state.sharedTree = tree;
	},
	setIsCurrentTree(state, isCurrent) {
		state.isCurrentTree = isCurrent;
	},
	setDefaultState(state) {
		Object.assign(state, getDefaultState())
	}
};
const actions = {
	setDefaultValues({ commit }, value) {
		commit('setDefaultState');
	}
};

const getDefaultState = () => ({
	activeSharedFolder: null,
	sharedTree: null,
	isCurrentTree: false,
	lookupPredefinedFields: {}
});

export default {
	namespaced: true,
	state,
	mutations,
	actions
};
