import { BaseAspect } from './base.aspect';
import { findInString } from '@acx-xms/data-functions/dist';

export class MarketspaceEditCompiteAspect extends BaseAspect {
	constructor() {
		super();
		this.stage = 'complete';
	}

	execute(options) {
		const entityConfigurationDataProvider = sc.classes.get('entityConfiguration.dataProvider');
		const viewTemplate = entityConfigurationDataProvider.getTemplate(options.entityRef.logicalname, 'detail', options.editorOptions.subtype);
		const metadata = entityConfigurationDataProvider.fetchEntity(options.entityRef.logicalname);
		const deferred = $.Deferred();

		$.when(viewTemplate, metadata).then((template, entityMetadata) => {
			let entityRef;
			if (options.saveResult) {
				entityRef = _.extend(sc.classes.get('entityReference', options.saveResult), options.saveResult);
				if (entityMetadata.entityItemDisplayName) {
					const entityEvalContext = sc.createEvaluationContext(options.saveResult, null, { immutable: true });
					entityRef.entityTitle = entityEvalContext.eval(entityMetadata.entityItemDisplayName);
				}
			} else entityRef = options.entityRef;

			if (!entityMetadata.saveAndClose) this.showDetails(entityRef, template);
			this.close(options);
			deferred.resolve();
		}, () => {
			this.close(options);
			deferred.resolve();
		});

		return deferred.promise();
	}

	showDetails(entityRef) {
		const locationHref = window.location.href;
		if (findInString(locationHref, entityRef.logicalname) && findInString(locationHref, entityRef.id)) {
			// TODO: remove after aspects migrate to vue
			sc.events.emit('refreshCurrentDetails');
		} else sc.events.emit('showDetails', entityRef);
	}

	close(options) {
		options.dialog && options.dialog.close();
	}
}
