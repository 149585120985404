<template>
	<div class="accept-nda">
		<div class="loading-overlay" v-show="isLoading"></div>
		<div class="entity-editor-content">
			<div class="editor-container">
				<control v-if="editorTemplate" :name="editorTemplate.$type"
						 :contentProps.once="editorTemplate"
						 :evaluationContext="templateContext"></control>
			</div>
			<div class="nda-accept-checkbox">
				<div class="checkbox-wrapper" @click.stop.prevent="ndachecked=!ndachecked">
					<div class="virtual-checkbox clickable" v-bind:class="ndachecked && 'selected'">
						<svg class='svg-icon'>
							<use xlink:href='#layout-icons-ms-checkbox' class='layout-icons-ms-checkbox'></use>
						</svg>
					</div>

				</div>
				<span class="accept-nda-label">
					<span v-localization="{ key: 'CreateDealRoomPlugin.acceptNDACheckboxLabel' }"></span>
					<span class="p2">{{options.params.saveData.dealroomRec.Name}}</span>
				</span>
			</div>
		</div>
		<div class='footer'>
			<span class='buttons'>
				<button type="button"
						@click="accept"
						:disabled="!ndachecked"
						v-localization="{key: 'CreateDealRoomPlugin.acceptNDA'}"></button>
				<button type="button"
                        class="cancel"
						@click="cancel"
						v-localization="{key: 'common.dictionary.buttons.cancel'}"></button>
			</span>
		</div>
	</div>
</template>
<script>
import Control from '@/Components/Entity/control';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';
export default {
	name: 'accept-nda-dialog',
	props: { options: Object },
	components: { Control },
	data() {
		return {
			isLoading: false,
			ndachecked: false,
			editorTemplate: null
		};
	},
	async created() {
		this.templateContext = createEvaluationContext(this.options.params.entity);
		this.editorTemplate = await sc.classes.get('entityConfiguration.dataProvider').getTemplate(this.options.params.entity.Type, this.options.params.usage, this.options.params.subtype);
	},
	methods: {
		cancel() {
			this.options.params.cancelCallback();
			this.$parent.$emit('close');
		},
		async accept() {
			this.isLoading = true;
			const moment = this.getLocaleDateISOFormat();
			const chatparticipantRef = sc.classes.get('entityReference', this.options.params.chatparticipant);
			const participant = await sc.classes.get('edge.dataProvider').update(chatparticipantRef.logicalname, chatparticipantRef.id, { ndasignindate: moment }, { resultsFormat: 'detail' });

			this.isLoading = false;
			this.options.params.acceptCallback({ chatparticipant: participant });
			this.$parent.$emit('close');
		},
		getLocaleDateISOFormat() {
			const datetime = new Date();
			const timeZoneOffset = datetime.getTimezoneOffset() / 60;
			const hours = datetime.getHours();
			datetime.setHours(hours - timeZoneOffset);

			return datetime.toISOString();
		}
	}
};
</script>
<style src="./accept-nda-dialog.less" scoped></style>
