<template>
	<div class="password-editor-wrapper">
		<stringeditor	:value="value"
						@input="onInputChange"
						@focused="onFocusChange"
						:enable="enable"
						:showRequired="showRequired"
						:isValid="isValid"
						:type="type"
						:hideText="hideText"
						:dataAttr="dataAttr"
						:isUnhideEnabled="isUnhideEnabled"></stringeditor>
	</div>
</template>
<script>
const Stringeditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './stringeditor');
export default {
	name: 'passwordeditor',
	components: { Stringeditor },
	props: {
		value: String,
		showRequired: {
			type: Boolean,
			default: false
		},
		isValid: {
			type: Boolean,
			default: true
		},
		enable: {
			type: Boolean,
			default: true
		},
		dataAttr: Object,
		hideText: {
			type: Boolean,
			default: false
		},
		showUnhide: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return { type: 'password' };
	},
	computed: {
		isUnhideEnabled() {
			let isEnabled = true;
			if (!this.showUnhide || !this.enable) {
				isEnabled = false;
			};
			return isEnabled;
		}
	},
	methods: {
		onInputChange(value) {
			this.$emit('input', value);
		},
		onFocusChange(data) {
			this.$emit('onfocuschange', data);
		}
	}
};
</script>

<style src="./passwordeditor.less" scoped></style>
