<template>
	<div class="link-wrapper" :title="title" v-show="visible" @click="send">
		<a class="download-link" target="blank" download :href="url" :text="title">{{title}}</a>
	</div>
</template>
<script>
import fileDownload from './file-download.mixin';
import { Create, Search } from '@acx-xms/data-functions/dist';
import { GetUserInfo } from '@/Data/Auth/authentication-service';
import { Get as GetItem } from '@/Data/DataProviders/userStorageDataProvider';

export default {
	name: 'file-download-inform',
	mixins: [fileDownload],
	data() {
		return {
			results: [],
			notifications: [],
			isGuest: null
		};
	},
	methods: {
		async getInformData() {
			const configuredFilters = this.options.filters.map(item => {
				const filter = sc.classes.get(item.$type, item, this.evaluationContext);
				return filter.toFilter();
			}).filter(Boolean);
			configuredFilters.push(sc.classes.get('selectedFields.filter', [
				{ logicalname: 'chatparticipants.chatparticipantsystemuseridsystemuser.systemuserid' },
				{ logicalname: 'chatparticipants.name' },
				{ logicalname: 'chatparticipants.chatid' },
				{ logicalname: 'chatparticipants.contactid' },
				{ logicalname: 'chatparticipants.nonregistered' },
				{ logicalname: 'chatparticipants.chatparticipantcontactidcontact.fullname' },
				{ logicalname: 'chatparticipants.chatparticipantid' },
				{ logicalname: 'chatparticipants.chatparticipantchatidchat.ownerid' },
				{ logicalname: 'chatparticipants.coowner' },
				{ logicalname: 'chatparticipants.isroomowner' },
				{ logicalname: 'chatparticipants.chatparticipantchatidchat.collaborationroomid' },
				{ logicalname: 'chatparticipants.chatparticipantchatidchat.dealroomid' },
				{ logicalname: 'chatcollaborationroomidcollaborationroom.crnumber' },
				{ logicalname: 'chatcollaborationroomidcollaborationroom.name' },
				{ logicalname: 'chatdealroomiddealroom.drnumber' },
				{ logicalname: 'chatdealroomiddealroom.name' }
			]).fillQuery());
			const getChatsPromise = Search(['chat'], [sc.classes.get('offsetSize.filter', 9999), ...configuredFilters]);
			const [{ Results: chats }, { systemuserid, CRMRoles }] = await Promise.all([getChatsPromise, GetUserInfo()]);
			const chatparticipants = [];
			const notificationRecipients = [];
			const lspath = `${this.$route.params.layout}_${this.$route.params.type}_${this.$route.params.id}`;
			const userName = await GetItem(lspath);
			chats.forEach(({ Source: chat }) => {
				const matchesParticipants = chat.chatparticipants.filter(participant =>
					participant.chatparticipantsystemuseridsystemuser &&
						(participant.chatparticipantsystemuseridsystemuser.systemuserid === systemuserid) &&
						((participant.name === userName) || !userName)
				);
				const recipients = chat.chatparticipants
					.filter(participant =>
						participant.chatparticipantsystemuseridsystemuser &&
							(participant.chatparticipantsystemuseridsystemuser.systemuserid !== systemuserid) &&
							(participant.isroomowner || participant.coowner)
					).map(participant => {
						return {
							id: participant.chatparticipantsystemuseridsystemuser.systemuserid,
							logicalname: 'systemuser'
						};
					});
				chatparticipants.push(...matchesParticipants);
				notificationRecipients.push(...recipients);
			});
			const roommapping = {
				collaborationroom: 'Collaboration Room',
				dealroom: 'Deal Room'
			};
			const roomnumbermapping = {
				collaborationroom: 'crnumber',
				dealroom: 'drnumber'
			};
			const roomLogicalName = (chatparticipants[0].chatparticipantchatidchat.collaborationroomid || chatparticipants[0].chatparticipantchatidchat.dealroomid).logicalname;
			const messagetext = await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('downloadInfo.downladedDocMessage', [this.title]);

			const hasChatEditAccess = CRMRoles.some(role => role.Name === 'Participant With Edit Access to Chat');

			return chatparticipants.map((participant, index) => {
				const roomNumber = chats[index].Source[`chat${roomLogicalName}id${roomLogicalName}`][roomnumbermapping[roomLogicalName]];
				const roomName = chats[index].Source[`chat${roomLogicalName}id${roomLogicalName}`].name;
				const result = {
					notifications: [],
					message: {}
				};

				notificationRecipients.forEach(recepientRef => {
					result.notifications.push({
						userInfo: JSON.stringify({
							relatedRecord: {
								id: participant.chatparticipantid,
								logicalname: 'chatparticipant'
							},
							content: {
								chatId: participant.chatid.id,
								navigationRef: participant.chatparticipantchatidchat[`${roomLogicalName}id`]
							}
						}),

						headings: { 'en-us': 'Shared Document Downloaded' },
						recepientid: recepientRef,
						contents: { 'en-us': `${participant.name} has downloaded a document in your ${roommapping[roomLogicalName]} ${roomNumber}, ${roomName}` },
						notificationtype: {
							id: '712c7f79-4fd2-4d97-98a6-904edf9f615c',
							logicalname: 'lookupnotificationtype'

						}
					});
				});

				if (!participant.nonregistered || (participant.nonregistered && hasChatEditAccess)) {
					// if participant is not nonregistered or if nonregistered and has Edit Access to Chat
					result.message = {
						type: 'chatmessage',
						messagetext,
						chatid: participant.chatid,
						contactid: participant.contactid,
						contactname: participant.nonregistered !== true ? participant.chatparticipantcontactidcontact.fullname : participant.name,
						messagetype: 'Action',
						visibleto: 'Brokers Only',
						createdonfromui: new Date()
					};
				}
				return result;
			});
		},
		async send() {
			if (!this.isGuest) {
				return;
			}
			if (!this.results.length) {
				this.results = await this.getInformData();
			};
			this.results.forEach(result => {
				result.hasOwnProperty('message') && Create(result.message.type, result.message);
				result.notifications.forEach(body => { Create('notification', body); });
			});
			this.trackActivity();
		}
	},
	async created() {
		this.isGuest = await this.evaluationContext.evalAsync(this.options.isGuest);
	}
};
</script>
<style src="./file-download.less" scoped></style>
