var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "create-dealroom-dialog editor" }, [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ],
      staticClass: "loading-overlay"
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "perfectscroll",
            rawName: "v-perfectscroll",
            value: { enable: true },
            expression: "{enable: true}"
          }
        ],
        staticClass: "body"
      },
      [
        _vm.editorTemplate
          ? _c("control", {
              attrs: {
                name: _vm.editorTemplate.$type,
                contentProps: _vm.editorTemplate,
                evaluationContext: _vm.controlContext
              }
            })
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c("div", { staticClass: "dialog-buttons" }, [
        _c("button", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "common.dictionary.buttons.cancel" },
              expression: "{key: 'common.dictionary.buttons.cancel'}"
            }
          ],
          staticClass: "cancel",
          attrs: { type: "button" },
          on: { click: _vm.cancel }
        }),
        _vm._v(" "),
        _c("button", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "common.dictionary.buttons.save" },
              expression: "{key: 'common.dictionary.buttons.save'}"
            }
          ],
          attrs: { type: "button" },
          on: { click: _vm.save }
        })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }