var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "control-numericeditor" }, [
    _c(
      "div",
      {
        staticClass: "input",
        class: { disabled: !_vm.enable, invalid: !_vm.isValid }
      },
      [
        _c("div", { staticClass: "actions before" }, [
          _vm.showRequiredMark
            ? _c("div", { staticClass: "required-mark" }, [
                _c(
                  "svg",
                  { staticClass: "svg-icon svg-8 layout-icons-required" },
                  [
                    _c("use", {
                      attrs: { "xlink:href": "#layout-icons-required" }
                    })
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.prefix ? _c("span", [_vm._v(_vm._s(_vm.prefix))]) : _vm._e()
        ]),
        _vm._v(" "),
        _c("input", {
          class: { "with-prefix": _vm.prefix },
          attrs: {
            maxlength: _vm.maxLength,
            type: "text",
            disabled: !_vm.enable
          },
          domProps: { value: _vm.value },
          on: {
            focus: function($event) {
              _vm.focused = true
            },
            blur: function($event) {
              _vm.focused = false
            },
            change: _vm.AdjustValue
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "actions after" }, [
          _vm.postfix ? _c("span", [_vm._v(_vm._s(_vm.postfix))]) : _vm._e(),
          _vm._v(" "),
          !!_vm.value
            ? _c(
                "div",
                { staticClass: "action", on: { click: _vm.clear } },
                [
                  _c("control-svg-icon", {
                    attrs: { options: { name: "layout-icons-textbox-delete" } }
                  })
                ],
                1
              )
            : _vm._e()
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }