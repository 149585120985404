import { BaseAspect } from './base.aspect';
import { Search } from '@acx-xms/data-functions/dist';
// before create cr | duplicated preference
export class ValidatePreferenceAspect extends BaseAspect {
	constructor() {
		super();
		this.stage = 'validate';
	}

	localize(label, options) {
		return sc.classes.get('localization.dataProvider').getObservableLabel(label, options);
	}

	async execute(options) {
		if (options.store.preference) {
			const preferenceResults = await Search(
				['preference'],
				[
					sc.classes.get('offsetSize.filter', 1),
					sc.classes.get('termFacet.filter', {
						logicalName: 'preferenceid',
						query: [options.store.preference.id]
					}).fillQuery(),
					sc.classes.get('selectedFields.filter', [
						{ logicalname: 'hascollaborationroom' },
						{ logicalname: 'collaborationroomid.id' }
					])
				]
			);
			const selectedPreference = preferenceResults.Results[0];
			if (selectedPreference.Source.hascollaborationroom) {
				const { Results: crResults } = await Search(
					['collaborationroom'],
					[
						sc.classes.get('offsetSize.filter', 1),
						sc.classes.get('termFacet.filter', {
							logicalName: 'collaborationroomid',
							query: [selectedPreference.Source.collaborationroomid.id]
						}),
						sc.classes.get('selectedFields.filter', [
							{ logicalname: 'name' },
							{ logicalname: 'collaborationroomowneridsystemuser.fullname' },
							{ logicalname: 'collaborationroomid' }
						])
					]
				);
				const source = crResults[0].Source;
				const collaborationroom = {
					Name: source.name,
					Owner: source.collaborationroomowneridsystemuser.fullname,
					ID: source.collaborationroomid
				};

				const error = ko.unwrap(this.localize('addPreferenceDialog.preference.alreadyused.error', [collaborationroom.ID, collaborationroom.Name, collaborationroom.Owner]));
				throw error;
			}
		}
	}
}
