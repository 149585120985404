var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "realte-to-contact-in-crm-dialog" }, [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ],
      staticClass: "loading-overlay large"
    }),
    _vm._v(" "),
    _c("div", { staticClass: "body" }, [
      !_vm.isLoading
        ? _c("div", { staticClass: "edit" }, [
            _c("div", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: { key: "realteToContactInCrmDialog.lookupText" },
                  expression: "{key: 'realteToContactInCrmDialog.lookupText'}"
                }
              ],
              staticClass: "p13"
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "realte-to-contact-in-crm" },
              [
                _c("control-lookup-editor", {
                  class: { invalid: !_vm.isValid },
                  attrs: {
                    showRequired: true,
                    options: _vm.getLookupOptions()
                  },
                  model: {
                    value: _vm.value,
                    callback: function($$v) {
                      _vm.value = $$v
                    },
                    expression: "value"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c("span", { staticClass: "buttons" }, [
        _c("button", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "common.dictionary.buttons.save" },
              expression: "{key: 'common.dictionary.buttons.save'}"
            }
          ],
          attrs: { type: "button" },
          on: { click: _vm.save }
        }),
        _vm._v(" "),
        _c("button", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "common.dictionary.buttons.cancel" },
              expression: "{key: 'common.dictionary.buttons.cancel'}"
            }
          ],
          staticClass: "cancel",
          attrs: { type: "button" },
          on: { click: _vm.close }
        })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }