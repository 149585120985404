
<template>
	<div class="dialog-content-wrapper">
		<div class="loading-overlay large" v-show="isLoading"></div>
		<div class="content">
			<div class="add-to-list">
				<div class="email-wrapper">
					<h5 for="email" v-localization="{ key: 'dialogues.addBrokers.emailLabel'}"></h5>
					<input
						v-model.trim="emailAddress"
						type="text"
						id="email"
						placeholder="email@example.com"
						maxlength="255"
						v-data-attr="{title: options.title, $type: 'dialog.input'}"
					>
				</div>
				<button
					type="button"
					@click="addToList"
					:disabled="!emailAddress || !emailAddress.length"
					v-localization="{ key: 'dialogues.addBrokers.addToList'}"
					v-data-attr="{title: options.title, $type: 'dialog.button.submit'}"
				/>
			</div>
			<div class="list-content">
				<div class="search-area">
					<h5 v-localization="{ key: 'dialogues.addBrokers.listContent'}"></h5>
					<div class="search-field-wrapper inline-block" v-bind:class="{'focused':searchFocused}">
						<input
							v-localization="{ key: 'common.dictionary.search', attrs: ['placeholder'] }"
							v-model="term"
							@keyup.enter="getBrokerList"
							@focus="searchFocused = true"
							@blur="searchFocused = false"
							type="text"
							class="search-box"
						/>
						<div class="search-icon-wrapper">
							<svg class="svg-icon" @click="getBrokerList">
								<use :xlink:href="'#layout-icons-ms-search'" class='layout-icons-ms-search'></use>
							</svg>
							<svg class="svg-icon svg-20 clear-button" v-show="term && term.length > 0" @click="clearSearchBox">
								<use xlink:href="#layout-icons-textbox-delete" class="layout-icons-textbox-delete"></use>
							</svg>
						</div>
					</div>
				</div>

				<div class="control-tableView">
					<div class="tableview-header" v-show="brokerList.length">
						<div class="cell">
							<span v-localization="{ key: 'dialogues.addBrokers.headerEmail'}"></span>
						</div>
					</div>
					<div class="tableview-content">
						<div class="content-scroll-wrapper" v-perfectscroll="{ enable: true }">
							<div class="row" v-for="broker in brokerList" :key="broker.Id">
								<div class="table-cell">
									<span class="label">{{broker.Source.email}}</span>
								</div>
								<div class="table-cell">
									<span class="icon-wrapper" v-on:click="removeFromList(broker)">
										<svg class="svg-icon svg-15">
											<use xlink:href="#action-icons-close-btn">
											</use>
										</svg>
									</span>
								</div>
							</div>
							<div class="row no-results-message p13" v-if="brokerList && !brokerList.length">
								<div class="table-cell" v-bind:style="{ width: '100%' }">
									<div v-localization="{ key: 'common.dictionary.noMatchesFound' }"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class='footer'>
				<div class='buttons'>
					<span class="button-wrapper">
						<button type="button"
							@click="ok"
							:disabled="!itemsToDelete.length && !itemsToAdd.length"
							v-localization="{key: 'common.dictionary.buttons.save'}"
							v-data-attr="{title: options.title, $type: 'dialog.button.submit'}"></button>
					</span>
					<button class="cancel"
						type="button"
						@click="close"
						v-localization="{key: 'common.dictionary.buttons.cancel'}"
						v-data-attr="{title: options.title, $type: 'dialog.button.cancel'}"></button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { showToastOrInfoDialog, isValidEmail } from '@acx-xms/data-functions/dist';

export default {
	name: 'add-brokers',
	props: { options: Object },
	data() {
		return {
			emailAddress: '',
			brokerList: [],
			term: '',
			searchFocused: false,
			isLoading: true,
			itemsToDelete: [],
			itemsToAdd: []
		};
	},
	created() {
		this.entityLogicalName = 'brokerslist';
		this.localizationDataProvider = sc.classes.get('localization.dataProvider');
		this.edgeDataProvider = sc.classes.get('edge.dataProvider');

		this.getBrokerList();
	},
	methods: {
		ok() {
			const promises = [];
			this.isLoading = true;

			this.itemsToDelete.forEach((item) => {
				promises.push(this.deleteBroker(this.entityLogicalName, item.Id));
			});

			this.itemsToAdd.forEach((item) => {
				promises.push(this.saveBroker(item));
			});

			$.when.apply($, promises).done(() => {
				this.$parent.$emit('close');
			}).fail((err) => {
				sc.utils.errorMessage.byResponse(err);
			}).always(async () => {
				this.isLoading = false;
				await showToastOrInfoDialog({
					toastMessageKey: this.options.toastMessageKey,
					informationDialogTextKey: this.options.informationDialogTextKey,
					options: { context: this }
				});
			});
		},
		close() {
			this.$parent.$emit('close');
		},
		clearSearchBox() {
			this.term = '';
			this.getBrokerList();
		},
		removeFromList(broker) {
			if (broker.Id) {
				this.itemsToDelete.push(broker);
			} else {
				this.itemsToAdd = this.itemsToAdd.filter((item) => {
					return item.email !== broker.Source.email;
				});
			}

			this.brokerList = this.brokerList.filter((item) => {
				return item.Source.email !== broker.Source.email;
			});
		},
		addToList() {
			if (!isValidEmail(this.emailAddress)) {
				sc.events.emit('dialog.error',
					{
						title: this.localizationDataProvider.localize('entity.editor.validationError'),
						message: this.localizationDataProvider.localize('entity.control.stringEditor.wrongEmailFormat')
					});
			} else {
				this.isCreated(this.emailAddress.toLowerCase()).then((isCreated) => {
					const isDuplicate = this.itemsToAdd.find((e) => {
						return e.email.toLowerCase() === this.emailAddress.toLowerCase();
					});

					if (isDuplicate || isCreated) {
						sc.events.emit('dialog.error',
							{
								title: this.localizationDataProvider.localize('entity.editor.validationError'),
								message: this.localizationDataProvider.localize('dialogues.addBrokers.duplicate', [this.emailAddress])
							});
					} else {
						const record = { email: this.emailAddress };

						this.brokerList.push({ Source: { email: this.emailAddress } });

						this.itemsToAdd.push(record);
						this.emailAddress = '';
					}
				});
			}
		},
		saveBroker(record) {
			return this.edgeDataProvider.create(this.entityLogicalName, record, {
				async: false,
				syncProcessMode: 'All'
			});
		},
		deleteBroker(logicalName, id) {
			return this.edgeDataProvider.deleteEntity(logicalName, id);
		},
		getBrokerList() {
			this.isLoading = true;

			const filters = [
				sc.classes.get('offsetFrom.filter', 0),
				sc.classes.get('offsetSize.filter', 9999),
				sc.classes.get('termFacet.filter', {
					logicalName: 'recordstate.id',
					query: ['8d113fa8-3015-4060-a107-14cedcd19dd3']
				}),
				sc.classes.get('orderBy.filter', {
					logicalName: 'modifiedon',
					query: false
				}),
				sc.classes.get('selectedFields.filter', [
					{ logicalname: 'email' }
				])
			];

			if (this.term && this.term !== '') {
				filters.push(sc.classes.get('field.filter', {
					logicalName: '_all',
					query: this.term
				}));
			}

			return this.edgeDataProvider.search({
				entities: [this.entityLogicalName],
				filters,
				relation: false
			})
				.then((brokerList) => {
					let results = brokerList.Results;

					// Remove items to delete from the list
					if (this.itemsToDelete.length) {
						const emailsToDelete = this.itemsToDelete.map((itemToDelete) => {
							return itemToDelete.Source.email;
						});

						results = results.filter((bl) => {
							return emailsToDelete.indexOf(bl.Source.email) === -1;
						});
					}
					// Filter and add new items to the list
					if (this.itemsToAdd) {
						this.itemsToAdd.forEach((itemToAdd) => {
							if (itemToAdd.email.indexOf(this.term) > -1 || !this.term || this.term === '') {
								results.push({ Source: itemToAdd });
							}
						});
					}

					this.brokerList = results;
				}).always(() => {
					this.isLoading = false;
				});
		},
		isCreated(emailAddress) {
			this.isLoading = true;
			const filters = [
				sc.classes.get('offsetFrom.filter', 0),
				sc.classes.get('offsetSize.filter', 1),
				sc.classes.get('termFacet.filter', {
					logicalName: 'email',
					query: [emailAddress]
				}),
				sc.classes.get('selectedFields.filter', [])
			];

			return this.edgeDataProvider.search({
				entities: ['systemuser', 'brokerslist'],
				filters,
				relation: false
			}).then((res) => {
				this.isLoading = false;
				return res.Total > 0;
			});
		}
	}
};
</script>
<style src="./add-brokers.less" scoped></style>
<style src="./../Control/Table/control-table-view.less" scoped></style>
<style src="./../Control/Table/control-table-view-row.less" scoped></style>
<style src="@/Components/ComponentSet/SearchResults/search-field/search-field.less" scoped></style>
