var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        {
          directives: [
            {
              name: "data-attr",
              rawName: "v-data-attr",
              value: _vm.dataAttr || {},
              expression: "dataAttr || {}"
            }
          ],
          staticClass: "control-boolean-editor",
          class: _vm.cssClass
        },
        [
          _vm.options.view === "slider"
            ? _c("label", { staticClass: "slider-wrapper" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.value,
                      expression: "value"
                    }
                  ],
                  attrs: { type: "checkbox", disabled: !_vm.enable },
                  domProps: {
                    checked: Array.isArray(_vm.value)
                      ? _vm._i(_vm.value, null) > -1
                      : _vm.value
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.value,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.value = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.value = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.value = $$c
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass: "slider round",
                  class: { disabled: !_vm.enable }
                })
              ])
            : _vm.options.view === "checkbox"
            ? _c("Checkbox", {
                attrs: { label: _vm.label, disabled: !_vm.enable },
                model: {
                  value: _vm.value,
                  callback: function($$v) {
                    _vm.value = $$v
                  },
                  expression: "value"
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }