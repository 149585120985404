<template>
	<stringeditor
		:value="value"
		:enable="enable"
		:showRequired="showRequiredMark"
		:placeholder="placeholder"
		:isValid="isValid"
		:stringFormatRegexp="stringFormatRegexp"
		:stringFormat="options.stringFormat"
		:dataAttr="dataAttr"
		@focused="onFocusedChange"
		@input="onInputChange"
	/>
</template>
<script>
/* eslint no-useless-escape: 0 */
import ControlMixins from '@/Components/Editor/Controls/control.mixins';
const Stringeditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './stringeditor');
export default {
	name: 'control-stringeditor',
	mixins: [ControlMixins],
	components: { Stringeditor },
	data() {
		return {
			focused: false,
			placeholder: '',
			stringFormatRegexp: null
		};
	},
	methods: {
		onFocusedChange({ value }) {
			this.focused = value;
		},
		onInputChange(value) {
			this.value = value;
		},
		async fieldRegistration() {
			if (this.options.stringFormat && this.options.stringFormat.mask) {
				switch (this.options.stringFormat.mask) {
				case 'email':
					this.options.regexpValidator = {
						pattern: /^[a-zA-Z0-9!#$%&\/\\'*+=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&\/\\'*+=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-zA-Z0-9](?:[a-z0-9-]*[a-z0-9])?$/m,
						message: {
							$type: 'expression.localizedLabel',
							key: 'entity.control.stringEditor.wrongEmailFormat'
						},
						enable: this.options.enable
						// TODO: revisit so validator's enable condition will be defaulted to control's enable condition or rework logic for validators in state
					};
					this.placeholder = 'email@example.com';
					break;
				case 'url':
					this.options.regexpValidator = {
						pattern: '^($|[a-zA-Z0-9_:&?=@!#;~/\\.\+-]+\\.[a-zA-Z0-9_:&?=@!#;~/\\.\+-]+)$',
						message: {
							$type: 'expression.localizedLabel',
							key: 'entity.control.stringEditor.wrongUrlFormat'
						},
						enable: this.options.enable
					};
					break;
				}
			}
			if (this.storeState) {
				await this.$store.dispatch(this.stateKey + '/registerField', {
					field: this.field,
					options: this.options
				});
			}
		}
	},
	created() {
		if (this.options.stringFormat && this.options.stringFormat.customRegEx) {
			this.stringFormatRegexp = new RegExp(this.options.stringFormat.customRegEx);
		}
	}
};
</script>
