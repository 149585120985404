var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "filter-content input-check-box include-empty panel-content"
    },
    [
      _c("label", { class: { disabled: !_vm.enabled } }, [
        _c("div", { staticClass: "facet-item-checkbox" }, [
          _c("span", { class: ["checkbox bordered", { checked: _vm.value }] }, [
            _c("svg", { staticClass: "svg-icon svg-12" }, [
              _c("use", {
                staticClass: "layout-icons-checkbox-small-checked",
                attrs: { "xlink:href": "#layout-icons-checkbox-small-checked" }
              })
            ])
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.included,
                expression: "included"
              }
            ],
            attrs: { disabled: !_vm.enabled, type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.included)
                ? _vm._i(_vm.included, null) > -1
                : _vm.included
            },
            on: {
              change: [
                function($event) {
                  var $$a = _vm.included,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.included = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.included = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.included = $$c
                  }
                },
                _vm.includeChanged
              ]
            }
          })
        ]),
        _vm._v(" "),
        _c("span", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "filtersPanel.includeEmpty" },
              expression: "{ key: 'filtersPanel.includeEmpty' }"
            }
          ],
          staticClass: "title"
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }