var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.controls, function(single) {
      return _vm.visible
        ? _c("control", {
            key: single.key,
            attrs: {
              name: single.$type,
              contentProps: single,
              evaluationContext: _vm.evaluationContext
            }
          })
        : _vm._e()
    })
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }