import helperMethods from '@/Components/ComponentSet/component-set-evaluation-context-helper.methods';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';
const ControlLookup = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Lookup/control-lookup');

export default {
	props: { options: Object },
	components: { ControlLookup },
	data() {
		return {
			isLoading: false,
			evaluationContext: createEvaluationContext(this.options.params.entity),
			lookupTemplate: null
		};
	},
	async created() {
		this.fork = sc.events.fork();
		this.logicalName = this.evaluationContext.eval(this.options.params.logicalname);
		this.configDataProvider = sc.classes.get('entityConfiguration.dataProvider');
		const userEntity = this.evaluationContext.eval(this.options.params.userEntity);
		this.lookupTemplate = await this.configDataProvider
			.getTemplate(userEntity, this.options.params.lookupTemplate.usage, this.options.params.lookupTemplate.subtype);
	},

	methods: {
		getLookupOptions() {
			return {
				placeholder: 'Search for a Contact to add',
				fetchData: async (searchQuery, startFrom, pageSize) => {
					startFrom = startFrom || 0;
					pageSize = pageSize || 1000;
					const filters = [
						sc.classes.get('offsetFrom.filter', startFrom),
						sc.classes.get('offsetSize.filter', pageSize),
						sc.classes.get('field.filter', {
							logicalName: 'email',
							query: '@'
						})
					];
					const entityConfigurations = await sc.classes.get('entityConfiguration.dataProvider').fetchEntity('contact');
					if (entityConfigurations.selectedFieldsSettings) {
						const selectedFieldsSection = entityConfigurations.selectedFieldsSettings.section.find(item => item.type === 'lookup');
						if (selectedFieldsSection) {
							const selectedFields = selectedFieldsSection.field.map(field => ({ logicalname: field }));
							filters.push(sc.classes.get('selectedFields.filter', selectedFields));
						}
					}
					if (searchQuery) {
						filters.push(sc.classes.get('field.filter', {
							logicalName: '_all',
							query: searchQuery
						}));
					}

					return sc.classes.get('edge.dataProvider').search({
						filters,
						// todo - add listmembers name into action as parameters
						entities: ['contact']
					}).then(({ Results, Total }) => {
						return {
							total: Total,
							template: this.lookupTemplate,
							items: Results.map(res => {
								return {
									displayName: res.Name,
									data: res
								};
							})
						};
					});
				},
				singleSelectionOnArray: this.OnSingleSelectionOnArray,
				template: this.lookupTemplate
			};
		},
		close() {
			if (this.options.params.refreshNamespace) {
				const refreshNamespace = helperMethods.eval(this.evaluationContext, this.options.params.refreshNamespace);
				this.$root.$emit(refreshNamespace + '.searching');
			}
			this.$parent.$emit('close');
		}
	},
	beforeDestroy() {
		if (this.fork) {
			this.fork.dispose();
		}
	}
};
