<template>
  <div class="dialog" v-bind:class="classes" v-bind:style="{ right: offsetX }">
    <div class="dialog-wrapper" :class="{ 'hide-close': hideClose }">
      <div class=" dialog-window" v-bind:class="{'active': active}"
        :style="{'max-width':dialogWidth, 'height':dialogHeight}">
        <dialog-header :dialogOptions="{
							   id,
							   title:initDialog.title,
							   localizedTitle:initDialog.localizedTitle,
							   icon:initDialog.icon,
							   onClose: onCloseCallback,
							   status,
							   allowClose
							   }"></dialog-header>
        <component class="dialog-content" @removeClose="hideClose = true"
          :is="prepareComponentName(initDialog.component)" @closeDialog="onClose(initDialog.id)" :dialogId="id"
          :options="initDialog"></component>
        <!--<div ref="id" class="dialog-content" v-kocomponent="dialogContentComponent()"></div>-->
      </div>
    </div>
  </div>
</template>
<script>
import { prepareComponentName } from '@acx-xms/data-functions/dist';
import StaticlistsMsCreate from '@/Components/MarketingLists/staticlists-ms-create';
const DialogHeader = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './dialog-header');

const MsSaveSearchDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '../SavedSearch/ms-save-search-dialog');
const ShareEntityDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '../SavedSearch/share-entity-dialog');
const MsAddlistingDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './../Dialogues/SearchResultDialogs/ms-addlisting-dialog');
const StaticlistsMsAddToList = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/MarketingLists/staticlists-ms-add-to-list');
const DealroomAddNda = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/AddNDA/dealroom-add-nda');
const StaticlistsMsEdit = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/MarketingLists/staticlists-ms-edit');
const ConfirmDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './confirm-dialog');
const EmailLinkDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './email-link-dialog');
const CopyLinkDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Dialogues/CopyLinkDialog/copy-link-dialog');
const ImageGalleryDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Images/ImageGallery/image-gallery-dialog');
const VueEditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Editor/editor');
const CreateDocumentEditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Editor/DocumentEditors/create-document-editor');
const AddBrokers = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './../AddBrokers/add-brokers');
const InviteParticipantsDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/InviteParticipants/invite-participants-dialog');
const ReportingserviceUiwDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Dialogues/ReportingserviceDialog/reportingservice-uiw-dialog');
const ImportDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Dialogues/ImportfromexcelDialog/import-dialog');
const CreateRelationDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/CreateRelation/create-relation-dialog');
const SharedToDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Dialogues/SharedToDialog/shared-to-dialog');
const ShareSearchResultsDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Dialogues/ShareSearchResultsDialog/share-search-results-dialog');
const SelectRecordDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Dialogues/SelectRecordDialog/select-record-dialog');
const MsExportDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/MsExportDialog/ms-export-dialog');
const CreateDealroomDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/CreateDealRoom/create-dealroom-dialog');
const CreateShareddocumentEditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Editor/DocumentEditors/create-shareddocument-editor');
const AcceptNdaDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/AcceptNDADialog/accept-nda-dialog');
const ManageSecurityRolesDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/ManageSecurityRolesDialog/manage-security-roles-dialog');
const ManageAccessToRoom = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/ManageAccessToRoom/manage-access-to-room');
const DeactivateDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/DeactivateDialog/deactivate-dialog');
const LeaveRoomDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/LeaveRoomDialog/leave-room-dialog');
const AssignLicenseDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Dialogues/AssignLicenseDialog/assign-license-dialog');
const VersionInfoDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Dialogues/VersionInfoDialog/version-info-dialog');
const InfoDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Dialogues/InfoDialog/info-dialog');
const ImportTemplateDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Dialogues/ImportTemplateDialog/import-template-dialog');
const ProgressDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './../ProgressDialog/progress-dialog');
const MappingEditDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './../Dialogues/MappingEditDialog/mapping-edit-dialog');
const RealteToContactInCrmDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/RelateToContactInCRMDialog/realte-to-contact-in-crm-dialog');
const AddPreferenceDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/AddPreference/add-preference-dialog');
const DeleteRestrictionDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Dialogues/DeleteRestrictionDialog/delete-restriction-dialog');
const DeleteDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Dialogues/DeleteDialog/delete-dialog');
const RemoveDocumentDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Dialogues/RemoveDocumentDialog/remove-document-dialog');
const ConvertInquiryDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Dialogues/ConvertInquiryDialog/convert-inquiry-dialog');
const ShareddocumentDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Dialogues/SearchResultDialogs/shareddocument-dialog');
const AddToStaticlistDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Dialogues/StaticLists/add-to-staticlist-dialog');
const TabsPopup = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/TabsPopup/tabs-popup.vue');
const AddServiceDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Dialogues/AddServiceDialog/add-service-dialog');
const DocumentFolderAddToDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Dialogues/DocumentFolderAddToDialog/document-folder-add-to-dialog');
const SendTestEmailDialog = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Wizard/WizardSets/EmailCampaign/Components/SendTestDialog/sendTestDialog');

export default {
	name: 'dialog-wrapper',
	props: { initDialog: Object },
	components: {
		DialogHeader,
		MsSaveSearchDialog,
		ShareEntityDialog,
		ConfirmDialog,
		EmailLinkDialog,
		CopyLinkDialog,
		MsAddlistingDialog,
		VueEditor,
		DealroomAddNda,
		AddBrokers,
		ReportingserviceUiwDialog,
		ImportDialog,
		SharedToDialog,
		ShareSearchResultsDialog,
		InviteParticipantsDialog,
		StaticlistsMsAddToList,
		StaticlistsMsEdit,
		CreateRelationDialog,
		SelectRecordDialog,
		MsExportDialog,
		CreateDealroomDialog,
		CreateShareddocumentEditor,
		AcceptNdaDialog,
		ImageGalleryDialog,
		ManageSecurityRolesDialog,
		DeactivateDialog,
		LeaveRoomDialog,
		AssignLicenseDialog,
		VersionInfoDialog,
		ImportTemplateDialog,
		ProgressDialog,
		MappingEditDialog,
		RealteToContactInCrmDialog,
		AddPreferenceDialog,
		InfoDialog,
		DeleteRestrictionDialog,
		DeleteDialog,
		RemoveDocumentDialog,
		StaticlistsMsCreate,
		ConvertInquiryDialog,
		CreateDocumentEditor,
		ManageAccessToRoom,
		ShareddocumentDialog,
		AddToStaticlistDialog,
		TabsPopup,
		AddServiceDialog,
		DocumentFolderAddToDialog,
		SendTestEmailDialog
	},
	data() {
		return {
			classes: 'maximized',
			offsetX: 0,
			active: (this.initDialog.active) ? this.initDialog.active : false,
			id: 0,
			status: null,
			allowClose: (this.initDialog.allowClose != null) ? this.initDialog.allowClose : true,
			onCloseCallback: this.initDialog.params && this.initDialog.params.closeCallback ? this.initDialog.params.closeCallback : this.onClose,
			hideClose: false
		};
	},
	computed: {
		dialogWidth() {
			const maximizedWidth = this.initDialog.maximizedWidth || '450px';
			return !sc.utils.userAgent.userAgent.isMobile ? maximizedWidth : '100%';
		},
		dialogHeight() {
			const maximizedHeight = (this.initDialog.maximizedHeight && `calc(${this.initDialog.maximizedHeight} - 40px)`) || 'auto';
			return !sc.utils.userAgent.userAgent.isMobile ? maximizedHeight : '100%';
		}
	},
	methods: {
		onClose(id) {
			this.$parent.$emit('closeDialog', id);
		},
		prepareComponentName(name) {
			return prepareComponentName(name);
		},
		calcDim(literal, maxDim, minMargin) {
			if (sc.utils.userAgent.userAgent.isMobile) {
				return '100%';
			}
			let dim = sc.utils.parsePixel(literal);
			if (dim == null) {
				dim = sc.utils.parsePercent(literal);

				if (dim != null) { dim = maxDim * dim; }
			}
			return dim + 'px';
		}

	},
	created() {
		this.id = this.initDialog.id;
		this.$on('close', () => {
			this.onClose(this.initDialog.id);
		});
		this.$on('minimize', () => {
			this.classes = 'minimized';
		});
	}
};
</script>
