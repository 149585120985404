var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.images.length
    ? _c("div", { staticClass: "image-gallery-dialog" }, [
        _c(
          "div",
          {
            class: {
              "dialog-gallery": true,
              "is-primary": _vm.currentImage.isPrimary
            }
          },
          [
            _vm._l(_vm.images, function(image) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentImage.id === image.id,
                      expression: "currentImage.id === image.id"
                    }
                  ],
                  key: image.$key,
                  staticClass: "dialog-gallery-image"
                },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: image.loaded && !image.error,
                        expression: "image.loaded && !image.error"
                      }
                    ],
                    attrs: { src: image.url },
                    on: {
                      load: function($event) {
                        image.loaded = true
                      },
                      error: function($event) {
                        image.error = true
                        image.loaded = true
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: image.error,
                        expression: "image.error"
                      }
                    ],
                    staticClass: "broken-image",
                    attrs: { src: "/Images/broken-file-icon-small.png" }
                  }),
                  _vm._v(" "),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !image.loaded,
                        expression: "!image.loaded"
                      }
                    ],
                    staticClass: "loading-overlay"
                  })
                ]
              )
            }),
            _vm._v(" "),
            _vm.images.length > 1
              ? [
                  _c(
                    "div",
                    { staticClass: "prev", on: { click: _vm.prevImage } },
                    [
                      _c("div", { staticClass: "image-gallery-arrow" }, [
                        _c("svg", { staticClass: "svg-icon" }, [
                          _c("use", {
                            attrs: {
                              "xlink:href": "#layout-icons-ms-arrow-left"
                            }
                          })
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "next", on: { click: _vm.nextImage } },
                    [
                      _c("div", { staticClass: "image-gallery-arrow" }, [
                        _c("svg", { staticClass: "svg-icon" }, [
                          _c("use", {
                            attrs: {
                              "xlink:href": "#layout-icons-ms-arrow-right"
                            }
                          })
                        ])
                      ])
                    ]
                  )
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.currentImage && _vm.menu && _vm.menu.item
              ? _c(
                  "div",
                  { staticClass: "image-menu" },
                  _vm._l(_vm.menu.item, function(menuItem, index) {
                    return _c(
                      "div",
                      { key: "menu-item" + index, staticClass: "item" },
                      [
                        _vm.currentImage
                          ? _c("ControlSvgIconAction", {
                              attrs: {
                                evaluationContext: _vm.actionContext,
                                options: menuItem
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  })
                )
              : _vm._e()
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "footer" },
          [
            _vm._l(_vm.images, function(image) {
              return _c(
                "div",
                {
                  key: image.$key,
                  staticClass: "footer-image",
                  class: { active: _vm.currentImage.id === image.id },
                  attrs: { id: "footer-image-" + image.id },
                  on: {
                    click: function($event) {
                      _vm.selectImage(image)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: image.loaded,
                          expression: "image.loaded"
                        }
                      ],
                      staticClass: "image-wrap",
                      class: { "broken-image": image.loaded && image.error }
                    },
                    [
                      !image.loaded
                        ? _c("img", {
                            attrs: { src: image.url },
                            on: {
                              load: function($event) {
                                image.loaded = true
                              },
                              error: function($event) {
                                image.error = true
                                image.loaded = true
                              }
                            }
                          })
                        : image.loaded && image.error
                        ? _c("img", {
                            attrs: { src: "/Images/broken-file-icon-small.png" }
                          })
                        : _c("div", {
                            staticClass: "bg-image",
                            style: {
                              "background-image": "url(" + image.url + ")"
                            }
                          }),
                      _vm._v(" "),
                      image.isPrimary
                        ? _c("div", { staticClass: "icon-primary" }, [
                            _c("svg", { staticClass: "svg-icon" }, [
                              _c("use", {
                                attrs: {
                                  "xlink:href":
                                    "#action-icons-image-gallery-primary"
                                }
                              })
                            ])
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !image.loaded,
                        expression: "!image.loaded"
                      }
                    ],
                    staticClass: "loading-overlay"
                  })
                ]
              )
            }),
            _vm._v(" "),
            _vm.images.length > 1
              ? [
                  _c(
                    "div",
                    {
                      staticClass: "prev image-gallery-arrow",
                      on: { click: _vm.prevImage }
                    },
                    [
                      _c("svg", { staticClass: "svg-icon" }, [
                        _c("use", {
                          attrs: { "xlink:href": "#layout-icons-ms-arrow-left" }
                        })
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "next image-gallery-arrow",
                      on: { click: _vm.nextImage }
                    },
                    [
                      _c("svg", { staticClass: "svg-icon" }, [
                        _c("use", {
                          attrs: {
                            "xlink:href": "#layout-icons-ms-arrow-right"
                          }
                        })
                      ])
                    ]
                  )
                ]
              : _vm._e()
          ],
          2
        )
      ])
    : _c("div", { staticClass: "info-message" }, [
        _c("span", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "imageGallery.noDataMessage" },
              expression: "{key: 'imageGallery.noDataMessage'}"
            }
          ],
          staticClass: "info-message-title"
        })
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }