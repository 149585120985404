var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drag-and-drop-editor" },
    [
      _c("ControlFileDraganddrop", {
        attrs: {
          dataAttr: _vm.evaluationContext.eval(_vm.options.qaDataAttribute),
          enable: _vm.enable,
          invalid: !_vm.isValid,
          acceptExtensions: _vm.options.acceptExtensions,
          allowedExtensions: _vm.options.allowedExtensions
        },
        model: {
          value: _vm.value,
          callback: function($$v) {
            _vm.value = $$v
          },
          expression: "value"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }