var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "drag-and-drop-results" }, [
    _vm.value && _vm.value.length
      ? _c("div", { staticClass: "progress" }, [
          _c("p", { staticClass: "h2" }, [
            _vm._v(_vm._s(_vm.value.length) + " file(s) added for upload.")
          ]),
          _vm._v(" "),
          _c("span", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.progress === null,
                expression: "progress===null"
              },
              {
                name: "localization",
                rawName: "v-localization",
                value: { key: "controls.dragAndDrop.clickUploadToStart.label" },
                expression:
                  "{key: 'controls.dragAndDrop.clickUploadToStart.label'}"
              }
            ],
            staticClass: "label"
          }),
          _vm._v(" "),
          _vm.progress !== "100" && _vm.progress !== null
            ? _c("div", [
                _c("span", { staticClass: "label" }, [
                  _vm._v(
                    "Uploading " +
                      _vm._s(_vm.validFiles.length) +
                      " files: " +
                      _vm._s(_vm.progress + "%")
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.progress === "100",
                  expression: "progress==='100'"
                }
              ],
              staticClass: "label"
            },
            [
              _vm._v(
                _vm._s(this.validFiles.length + " files uploaded successfully!")
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.progress !== null,
                  expression: "progress!==null"
                }
              ],
              staticClass: "content"
            },
            [
              _c("div", {
                staticClass: "progress-bar-container",
                style: { width: _vm.progress + "%" }
              })
            ]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.value && _vm.value.length
      ? _c(
          "div",
          {
            staticClass:
              "control-tableView control-component formcontrol-template"
          },
          [
            _c("div", { staticClass: "tableview-header" }, [
              _c(
                "div",
                {
                  staticClass: "cell first-cell",
                  staticStyle: { width: "70%" }
                },
                [
                  _c("span", {
                    directives: [
                      {
                        name: "localization",
                        rawName: "v-localization",
                        value: { key: "controls.dragAndDrop.tableHeader.name" },
                        expression:
                          "{key: 'controls.dragAndDrop.tableHeader.name'}"
                      }
                    ]
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cell", staticStyle: { width: "10%" } },
                [
                  _c("span", {
                    directives: [
                      {
                        name: "localization",
                        rawName: "v-localization",
                        value: { key: "controls.dragAndDrop.tableHeader.size" },
                        expression:
                          "{key: 'controls.dragAndDrop.tableHeader.size'}"
                      }
                    ]
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cell", staticStyle: { width: "20%" } },
                [
                  _c("span", {
                    directives: [
                      {
                        name: "localization",
                        rawName: "v-localization",
                        value: {
                          key: "controls.dragAndDrop.tableHeader.progress"
                        },
                        expression:
                          "{key: 'controls.dragAndDrop.tableHeader.progress'}"
                      }
                    ]
                  })
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tableview-content" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "perfectscroll",
                      rawName: "v-perfectscroll",
                      value: { enable: true },
                      expression: "{ enable: true }"
                    }
                  ],
                  staticClass: "content-scroll-wrapper"
                },
                _vm._l(_vm.value, function(data) {
                  return _c(
                    "div",
                    {
                      key: data.file.id,
                      staticClass: "row",
                      attrs: { "bind:is-odd": "index%2==0" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "table-cell",
                          staticStyle: { width: "70%" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "label-wrapper control-component  wrap"
                            },
                            [
                              _c("span", { staticClass: "label" }, [
                                _vm._v(_vm._s(data.file.name))
                              ])
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "table-cell",
                          staticStyle: { width: "10%" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "label-wrapper control-component wrap"
                            },
                            [
                              _c("span", { staticClass: "label" }, [
                                _vm._v(_vm._s(data.file.size))
                              ])
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "table-cell",
                          staticStyle: { width: "15%" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "label-wrapper control-component wrap",
                              class: {
                                p2: data.state === "uploading",
                                error: data.errors.length
                              }
                            },
                            [
                              _c("span", { staticClass: "label" }, [
                                _vm._v(_vm._s(_vm.getProgressLabel(data)))
                              ])
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "table-cell",
                          staticStyle: { width: "5%" }
                        },
                        [
                          data.state === "notStarted"
                            ? _c(
                                "span",
                                {
                                  staticClass: "icon-wrapper",
                                  on: {
                                    click: function($event) {
                                      _vm.remove(data)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "svg",
                                    { staticClass: "svg-icon svg-15" },
                                    [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "#action-icons-close-btn"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    ]
                  )
                })
              )
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }