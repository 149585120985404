var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "image-gallery-dialog loading" }, [
      _vm.loading ? _c("div", { staticClass: "loading-overlay" }) : _vm._e(),
      _vm._v(" "),
      _vm.images.length
        ? _c(
            "div",
            {
              class: {
                "dialog-gallery": true,
                "is-primary": _vm.currentImage.isPrimary
              }
            },
            [
              _vm._l(_vm.images, function(image) {
                return _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.currentImage.id === image.id,
                        expression: "currentImage.id === image.id"
                      }
                    ],
                    key: image.$key,
                    staticClass: "dialog-gallery-image"
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: image.loaded && !image.error,
                          expression: "image.loaded && !image.error"
                        }
                      ],
                      attrs: { src: image.url },
                      on: {
                        load: function($event) {
                          image.loaded = true
                        },
                        error: function($event) {
                          image.error = true
                          image.loaded = true
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: image.error,
                          expression: "image.error"
                        }
                      ],
                      staticClass: "broken-image",
                      attrs: { src: "/Images/broken-file-icon-small.png" }
                    }),
                    _vm._v(" "),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !image.loaded,
                          expression: "!image.loaded"
                        }
                      ],
                      staticClass: "loading-overlay"
                    })
                  ]
                )
              }),
              _vm._v(" "),
              _vm.images.length > 1
                ? [
                    _c(
                      "div",
                      { staticClass: "prev", on: { click: _vm.prevImage } },
                      [
                        _c("div", { staticClass: "image-gallery-arrow" }, [
                          _c("svg", { staticClass: "svg-icon" }, [
                            _c("use", {
                              attrs: {
                                "xlink:href": "#layout-icons-ms-arrow-left"
                              }
                            })
                          ])
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "next", on: { click: _vm.nextImage } },
                      [
                        _c("div", { staticClass: "image-gallery-arrow" }, [
                          _c("svg", { staticClass: "svg-icon" }, [
                            _c("use", {
                              attrs: {
                                "xlink:href": "#layout-icons-ms-arrow-right"
                              }
                            })
                          ])
                        ])
                      ]
                    )
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.currentImage && _vm.menu && _vm.menu.item
                ? _c(
                    "div",
                    {
                      staticClass: "image-menu",
                      class: { disabled: !_vm.saveOrderButton }
                    },
                    [
                      _c("span", { staticClass: "tooltip" }, [
                        _vm._v(
                          "\n          Save the new image ordering to perform this action\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.menuItemsComputed, function(menuItem, index) {
                        return _c(
                          "div",
                          { key: "menu-item" + index, staticClass: "item" },
                          [
                            _vm.currentImage
                              ? _c("ControlSvgIconAction", {
                                  attrs: {
                                    evaluationContext: _vm.actionContext,
                                    options: menuItem
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gallery-bar" },
        [
          _c("WizardButton", {
            staticClass: "close-dialog",
            attrs: { text: "Close" },
            on: { click: _vm.onCloseDialog }
          }),
          _vm._v(" "),
          _vm.images.length
            ? _c("WizardButton", {
                staticClass: "blue save-dialog-btn",
                attrs: { text: "Save Order", disabled: _vm.saveOrderButton },
                on: { click: _vm.updateImagesOrder }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.images.length
        ? _c(
            "div",
            { staticClass: "footer" },
            [
              _c("div", { staticClass: "tip" }, [
                _vm._v(
                  "First image in order is primary. Drag to reorder images"
                )
              ]),
              _vm._v(" "),
              _c(
                "draggable",
                {
                  directives: [
                    {
                      name: "perfectscroll",
                      rawName: "v-perfectscroll",
                      value: { suppressScrollX: true },
                      expression: "{ suppressScrollX: true }"
                    }
                  ],
                  staticClass: "footer-inner",
                  attrs: { group: "people" },
                  on: {
                    update: _vm.onUpdateDD,
                    start: function($event) {
                      _vm.drag = true
                    },
                    end: function($event) {
                      _vm.drag = false
                    }
                  },
                  model: {
                    value: _vm.images,
                    callback: function($$v) {
                      _vm.images = $$v
                    },
                    expression: "images"
                  }
                },
                _vm._l(_vm.images, function(image, index) {
                  return _c(
                    "div",
                    {
                      key: image.$key,
                      staticClass: "footer-image",
                      class: { active: _vm.currentImage.id === image.id },
                      attrs: { id: "footer-image-" + image.id },
                      on: {
                        click: function($event) {
                          _vm.selectImage(image)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: image.loaded,
                              expression: "image.loaded"
                            }
                          ],
                          staticClass: "image-wrap",
                          class: { "broken-image": image.loaded && image.error }
                        },
                        [
                          !image.loaded
                            ? _c("img", {
                                attrs: { src: image.url },
                                on: {
                                  load: function($event) {
                                    image.loaded = true
                                  },
                                  error: function($event) {
                                    image.error = true
                                    image.loaded = true
                                  }
                                }
                              })
                            : image.loaded && image.error
                            ? _c("img", {
                                attrs: {
                                  src: "/Images/broken-file-icon-small.png"
                                }
                              })
                            : _c("div", {
                                staticClass: "bg-image",
                                style: {
                                  "background-image": "url(" + image.url + ")"
                                }
                              }),
                          _vm._v(" "),
                          index === 0
                            ? _c("div", { staticClass: "icon-primary" }, [
                                _c("svg", { staticClass: "svg-icon" }, [
                                  _c("use", {
                                    attrs: {
                                      "xlink:href":
                                        "#action-icons-image-gallery-primary"
                                    }
                                  })
                                ])
                              ])
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !image.loaded,
                            expression: "!image.loaded"
                          }
                        ],
                        staticClass: "loading-overlay"
                      })
                    ]
                  )
                })
              ),
              _vm._v(" "),
              _vm.images.length > 1 ? void 0 : _vm._e()
            ],
            2
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    !_vm.images.length
      ? _c("div", { staticClass: "info-message" }, [
          _c("span", {
            directives: [
              {
                name: "localization",
                rawName: "v-localization",
                value: { key: "imageGallery.noDataMessage" },
                expression: "{key: 'imageGallery.noDataMessage'}"
              }
            ],
            staticClass: "info-message-title"
          })
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }