<template>
    <div class="dialog-step">
        <div class="loading-overlay large" v-show="isLoading"></div>
        <div class="list-content with-checkbox">
            <div class="control-tableView inner-search-results">
                <div class="tableview-header step-two">
                    <div class="cell checkbox">
                        <checkbox :value="checkedAll" @input="onSelectAll" :key="'checkedAll'+checkedAll"></checkbox>
                    </div>
                    <div class="cell first-column">
                        <span class="label-header" v-localization="{ key: 'import.sourceField'}"></span>
                    </div>
                    <div class="cell second-column">
                        <span class="label-header" v-localization="{ key: 'import.exampleOfFieldData'}"></span>
                    </div>
                    <div class="cell third-column">
                        <span class="label-header" v-localization="{ key: 'import.destination'}"></span>
                    </div>
                </div>
                <div class="tableview-content">
                    <div class="content-scroll-wrapper" v-perfectscroll="{enable: true}">
                        <div class="row" v-for="(item, index) in fieldsMapping" :key="item.fileField+item.checked">
                            <div class="table-cell checkbox">
                                <checkbox v-model="item.checked"
                                          :key="'item.fileField_'+'checked'"
                                          @input="onCheckedItemChanged(index, ...arguments)"></checkbox>
                            </div>
                            <div class="table-cell first-column">
                                <span class="label">{{item.fileField}}</span>
                            </div>
                            <div class="table-cell multiline second-column">
                                <span class="label">{{item.example}}</span>
                            </div>
                            <div class="table-cell third-column">
                                <control-lookup-editor v-model="item.data"
                                                       @input="onChangeInput(index, ...arguments)"
                                                       :class="{invalid: !item.unSelected}"
                                                       :evaluationContext="null"
                                                       :options="getLookupOptions(index)"></control-lookup-editor>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="options" class='footer'>
            <span class='buttons'>
                <button type="button"
                        @click="nextStep"
                        v-localization="{key: 'import.next'}"></button>
                <button type="button"
                        class="cancel"
                        @click="previousStep"
                        v-localization="{key: 'import.back'}"></button>
            </span>
        </div>

    </div>
</template>
<script>
/* eslint vue/no-unused-components: 0 */
const ControlTableView = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Table/control-table-view');
const ControlLookupEditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Lookup/control-lookup-editor');
const Checkbox = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Checkboxes/checkbox-base');
export default {
	name: 'import-step-two',
	props: {
		step: Number,
		options: Object
	},
	components: {
		ControlTableView,
		ControlLookupEditor,
		Checkbox
	},
	data() {
		return {
			selectedFields: {},
			isBack: true,
			checkedAll: true,
			fieldsMapping: this.options.fieldsMapping,
			metaFields: Object.values(this.options.metaFields),
			isLoading: false
		};
	},
	created() {
		this.localization = sc.classes.get('localization.dataProvider');
	},
	methods: {
		showErrorMessage(errorMessage) {
			sc.events.emit('dialog.info', {
				title: this.localization.localize('import.validationError'),
				message: errorMessage
			});
		},
		validateFieldsMapping() {
			const checkFieldsMapping = this.fieldsMapping.filter(fm => (fm.checked && !fm.data));
			if (checkFieldsMapping.length) {
				const newFieldsMapping = this.fieldsMapping.map(fm => {
					if (fm.checked && !fm.data) {
						fm.unSelected = false;
					}
					return fm;
				});
				this.fieldsMapping = [...newFieldsMapping];
				const errorMessage = this.localization.localize('import.wrongFieldsMapping');
				this.showErrorMessage(errorMessage);
			}
			return checkFieldsMapping;
		},
		getLookupOptions() {
			return {
				isRequired: true,
				placeholder: '',
				fetchData: searchQuery => {
					const availableFields = [];
					this.metaFields.forEach(mf => {
						if (mf.fieldName.toLowerCase().indexOf(searchQuery) === 0) {
							availableFields.push({
								displayName: mf.name.base || mf.fieldName,
								value: mf.fieldName
							});
						}
					});
					return {
						total: availableFields.length,
						items: availableFields
					};
				}
			};
		},
		onChangeInput(index, data) {
			let isSelected;
			const selectedMetaFields = this.fieldsMapping.filter(m => !!m.data);

			selectedMetaFields.forEach((mapping, mappingIdx) => {
				selectedMetaFields.forEach((m, i) => {
					if (m.data.value === mapping.data.value && i !== mappingIdx && !isSelected) {
						isSelected = true;
					}
				});
			});

			if (!isSelected) {
				this.$set(this.fieldsMapping, index, {
					...this.fieldsMapping[index],
					data
				});
			} else {
				this.showErrorMessage(`"${data.displayName}" field is already added.`);
				this.$set(this.fieldsMapping, index, {
					...this.fieldsMapping[index],
					data: null
				});
			}
		},
		onCheckedItemChanged(index, value) {
			this.$set(this.fieldsMapping, index, {
				...this.fieldsMapping[index],
				checked: value
			});
			if (!value) {
				this.$set(this.fieldsMapping, index, {
					...this.fieldsMapping[index],
					unSelected: !value
				});
			}
			const isAllChecked = this.fieldsMapping.filter(fm => {
				return fm.checked;
			});
			if (isAllChecked.length === this.fieldsMapping.length || !isAllChecked.length) {
				this.checkedAll = value;
			}
		},
		onSelectAll(value) {
			this.fieldsMapping.forEach(fm => {
				fm.checked = value;
			});
			this.checkedAll = value;
		},
		async nextStep() {
			this.isLoading = true;
			if (!this.validateFieldsMapping().length) {
				const selectedFields = {};
				this.fieldsMapping.forEach(fm => {
					if (fm.checked && fm.data) {
						selectedFields[fm.fileField] = fm.data.value;
					}
				});
				this.$parent.$emit('changeStep', {
					step: this.step + 1,
					result: {
						fileGuid: this.options.fileGuid,
						entityName: this.options.entityName,
						displayName: this.options.displayName,
						recordsCount: this.options.recordsCount,
						selectedFields,
						lengthFields: this.fieldsMapping.length,
						fieldsMapping: this.fieldsMapping
					}
				});
			}
			this.isLoading = false;
		},
		previousStep() {
			this.$parent.$emit('changeStep', {
				step: this.step - 1,
				result: null
			});
		}
	}
};
</script>
<style src="@/Components/Control/Table/control-table-view.less" scoped></style>
<style src="@/Components/Control/Table/control-table-view-row.less" scoped></style>
<style src="./import-steps.less" scoped></style>
