var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "panel",
      staticClass: "panel",
      class: { highlighted: _vm.isClicked }
    },
    [
      _vm.title !== "root"
        ? _c("div", { staticClass: "border-top" })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "panel-header with-margin" }, [
        _c("div", { staticClass: "icon-with-text" }, [
          _c(
            "span",
            {
              staticClass: "icon-holder",
              on: {
                click: function($event) {
                  _vm.isExpanded = !_vm.isExpanded
                }
              }
            },
            [
              _c("svg", { staticClass: "svg-icon svg-15" }, [
                _vm.isExpanded
                  ? _c("use", {
                      staticClass: "hover",
                      attrs: {
                        "xlink:href": "#layout-icons-filter-group-collapse"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isExpanded
                  ? _c("use", {
                      staticClass: "hover",
                      attrs: { "xlink:href": "#layout-icons-filter-group-open" }
                    })
                  : _vm._e()
              ])
            ]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "h2 title" }, [
            _vm._v(" " + _vm._s(_vm.title) + ":")
          ])
        ]),
        _vm._v(" "),
        _vm.allowItemActions
          ? _c(
              "div",
              { staticClass: "icons" },
              _vm._l(_vm.itemActions, function(itemAction, index) {
                return _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: itemAction.enable(_vm.currentIndex),
                        expression: "itemAction.enable(currentIndex)"
                      }
                    ],
                    key: index,
                    staticClass: "icon-action",
                    class: itemAction.class,
                    on: {
                      click: function($event) {
                        _vm.emitEvent(itemAction.event)
                      }
                    }
                  },
                  [
                    _c("svg", { staticClass: "svg-icon svg-15" }, [
                      _c("use", {
                        attrs: {
                          "xlink:href": "#layout-icons-" + itemAction.icon
                        }
                      })
                    ])
                  ]
                )
              })
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isExpanded,
              expression: "isExpanded"
            }
          ],
          staticClass: "with-left-border",
          class: { "single-type": _vm.types.length == 1 }
        },
        [
          _c(
            "div",
            { staticClass: "type-value-wrapper with-margin" },
            [
              _vm.types.length > 1
                ? _c("control-lookup-editor", {
                    staticClass: "types",
                    attrs: { options: _vm.getLookupOptions() },
                    model: {
                      value: _vm.selectedType,
                      callback: function($$v) {
                        _vm.selectedType = $$v
                      },
                      expression: "selectedType"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.types.length == 1,
                      expression: "types.length==1"
                    }
                  ],
                  staticClass: "type p4"
                },
                [_vm._v(_vm._s(_vm.types[0].displayName))]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.children, function(child, index) {
            return _c(
              "div",
              { key: child.title },
              [
                child.type === "panel"
                  ? _c("panel", {
                      attrs: {
                        title: child.title,
                        template: child.value,
                        base: child.base,
                        metaFields: _vm.metaFields
                      },
                      on: {
                        onChange: function($event) {
                          var i = arguments.length,
                            argsArray = Array(i)
                          while (i--) argsArray[i] = arguments[i]
                          _vm.onChange.apply(void 0, [index].concat(argsArray))
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                child.type === "enum"
                  ? _c("enums", {
                      staticClass: "with-margin",
                      attrs: {
                        enums: child.enums,
                        title: child.title,
                        currentValue: child.value
                      },
                      on: {
                        onChange: function($event) {
                          var i = arguments.length,
                            argsArray = Array(i)
                          while (i--) argsArray[i] = arguments[i]
                          _vm.onChange.apply(void 0, [index].concat(argsArray))
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                (child.type === "number" || child.type === "string") &&
                _vm.selectedType &&
                _vm.selectedType.displayName !== "FieldExpression" &&
                child.title !== "name"
                  ? _c("value", {
                      staticClass: "with-margin",
                      attrs: { title: child.title, currentValue: child.value },
                      on: {
                        onChange: function($event) {
                          var i = arguments.length,
                            argsArray = Array(i)
                          while (i--) argsArray[i] = arguments[i]
                          _vm.onChange.apply(void 0, [index].concat(argsArray))
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.selectedType &&
                _vm.selectedType.displayName === "FieldExpression" &&
                child.title === "name"
                  ? _c("lookup-value", {
                      staticClass: "with-margin",
                      attrs: {
                        title: child.title,
                        currentValue: child.value,
                        metaFields: _vm.metaFields
                      },
                      on: {
                        onChange: function($event) {
                          var i = arguments.length,
                            argsArray = Array(i)
                          while (i--) argsArray[i] = arguments[i]
                          _vm.onChange.apply(void 0, [index].concat(argsArray))
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                child.type === "boolean"
                  ? _c("boolean-value", {
                      staticClass: "with-margin",
                      attrs: {
                        title: child.title,
                        currentValue: !!child.value
                      },
                      on: {
                        onChange: function($event) {
                          var i = arguments.length,
                            argsArray = Array(i)
                          while (i--) argsArray[i] = arguments[i]
                          _vm.onChange.apply(void 0, [index].concat(argsArray))
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                child.type === "array"
                  ? _c("collection", {
                      attrs: {
                        title: child.title,
                        currentValue: child.value,
                        base: child.base,
                        metaFields: _vm.metaFields
                      },
                      on: {
                        onChange: function($event) {
                          var i = arguments.length,
                            argsArray = Array(i)
                          while (i--) argsArray[i] = arguments[i]
                          _vm.onChange.apply(void 0, [index].concat(argsArray))
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }