<template>
	<div class="sc-ndaFileUpload">
		<div class="nda-file-upload-wrapper row">
			<control-file-upload	class="control-wrapper"
									:class="{'invalid': !isValid}"
									v-model="uploadedFileName"
									:evaluationContext="evaluationContext"
									:options="options"
									:showRequiredMark="showRequiredMark"></control-file-upload>
		</div>
	</div>
</template>
<script>
import ControlMixins from '@/Components/Editor/Controls/control.mixins';
const ControlFileUpload = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/FileUpload/file-upload');

export default {
	name: 'control-file-upload-editor',
	mixins: [ControlMixins],
	components: { ControlFileUpload },
	props: {
		options: Object,
		evaluationContext: Object
	},
	computed: {
		showRequiredMark() {
			return this.storeState.requiredFields[this.field] && !this.uploadedFileName;
		},
		uploadedFileName: {
			get() {
				return this.value ? this.value.uploadedFileName : null;
			},
			set(value) {
				this.$set(this.value, 'uploadedFileName', value);
			}
		}
	},
	async created() {
		if (!this.value) {
			await this.$store.dispatch(this.stateKey + '/setField', {
				name: this.field,
				value: {},
				isFormInit: true
			});
		}
	}
};
</script>
<style src="./control-ndafile-editor.less" scoped></style>
