<template>
	<div class="tags-list">
		<transition-group
			:class="['list', { oneliner: height < 30 }]"
			name="list"
			tag="div"
			ref="list"
			v-perfectscroll="{ suppressScrollX: true }"
		>
			<span
				v-for="tag in tags"
				:key="tag.Id"
				class='tag group-item'
			>
				<span class="content">
					<span
						v-if="tag.prefix"
						:key="tag.Id"
						class="prefix"
					>
						{{ tag.prefix }}
					</span>
					{{ tag.Name }}
					<span
						v-if="tag.suffix"
						:key="tag.Id"
						class="suffix"
					>
						{{ tag.suffix }}
					</span>
				</span>
				<div class="delete-action" @click="remove(tag)">
					<svg class="delete-icon">
						<use xlink:href="#action-icons-options-remove" />
					</svg>
				</div>
			</span>
		</transition-group>
		<span v-show="!tags.length" class="no-selected">
			{{ placeholder || noSelected }}
		</span>
		<div v-if="$slots.default" class="after">
			<slot></slot>
		</div>
	</div>
</template>

<script>
import '@/Bindings/perfectscroll.directive.js';

const UNIQUE_CASES = ['document', 'shareddocument'];
const ENTITIES = {
	collaborationroom: 'collaboration rooms',
	dealroom: 'deal rooms',
	staticlist: 'static lists',
	marketingcampaign: 'email campaigns',
	inquiry: 'inquiries',
	systemuser: 'users',
	marketinglists: 'marketing lists',
	shareddocuments: 'documents',
	availability: 'availabilities'
};

export default {
	name: 'tags-list',
	props: {
		stateNamespace: {
			type: String,
			required: true
		},
		entityNamespace: {
			type: String,
			required: true
		},
		customSelectionsQuery: {
			type: Object,
			default: () => ({})
		},
		placeholder: {
			type: String,
			default: ''
		}
	},
	data: () => ({ height: 0 }),
	computed: {
		tags() {
			if (!(this.entityNamespace && this.stateNamespace)) return [];
			/* unique cases */
			if (UNIQUE_CASES.includes(this.entityNamespace)) {
				const selection = this.$store.getters[`${this.stateNamespace}/getSelection`];
				const result = [];
				if (Object.keys(selection).length) {
					Object.keys(selection).forEach(element => {
						result.push(...selection[element]);
					});
				}
				return result;
			}
			if (Object.keys(this.customSelectionsQuery || {}).length) {
				const { name, entity, target, value, field } = this.customSelectionsQuery;
				if (!name || !entity || !target || !value || !field) return;
				/* add in selection custom field by creating query for searhc in other entity */
				if (!(this.$store.getters[`${this.stateNamespace}/getSelection`] && this.$store.getters[`${this.stateNamespace}/getSelection`][this.entityNamespace])) return [];
				return this.$store.getters[`${this.stateNamespace}/getSelection`][this.entityNamespace].map(element => {
					return {
						...element,
						...{ [name]: this.$store.getters[`${this.stateNamespace}/fieldFromSharedSelection`](this.entityNamespace, entity, target, _.get(element, value), field)[0] }
					};
				});
			}
			return (this.$store.getters[`${this.stateNamespace}/getSelection`] && this.$store.getters[`${this.stateNamespace}/getSelection`][this.entityNamespace]) || []; /* default selection without */
		},
		noSelected() {
			const getNamespace = () => {
				const temp = this.stateNamespace.split('.');
				return temp[temp.length - 1];
			};
			const entity = Object.keys(ENTITIES).includes(getNamespace()) ? getNamespace() : this.entityNamespace;
			const result = Object.keys(ENTITIES).includes(entity) ? ENTITIES[entity] : `${entity}s`;
			return `No ${result} selected`;
		}
	},
	methods: {
		remove(record) {
			this.$store.commit(`${this.stateNamespace}/changeSelection`, {
				records: [record],
				select: false
			});
			if (record.Type === 'listing' && record.Source.availabilities.length) {
				record.Source.availabilities.forEach((i) => {
					this.$store.getters[`${this.stateNamespace}/isRecordSelected`]({
						Type: 'availability',
						Id: i.availabilityid
					}) && this.$store.commit(`${this.stateNamespace}/changeSelection`, {
						records: [{...i, Id: i.availabilityid, Type: i.type}],
						select: false
					});
				});
			}
			this.$emit('delete', record.Id);
		},
		updateHeight() {
			// Animation time - 150ms, nextTick doesn't work
			setTimeout(() => {
				this.height = (this.$refs && this.$refs.list) ? this.$refs.list.$el.offsetHeight : 0;
				this.updateTagsClasses();
			}, 160);
		},
		updateTagsClasses() {
			if (this.height < 30) {
				return;
			}

			// Update classes to create visual effects
			let elements = (this.$refs && this.$refs.list) && this.$refs.list.$el.children;
			elements = [...elements].filter(element => element.classList.contains('tag'));
			elements.forEach(element => element.classList.remove('top', 'bottom')); /* remove classes */

			// Get positions of elements
			const heights = elements.map(element => element.offsetTop);
			const first = Math.min(...heights);
			const last = Math.max(...heights);

			// Set classes by position in list
			const top = elements.filter(element => element.offsetTop === first);
			top.length && top[top.length - 1].classList.add('top'); /* set class for last top element */
			elements.length && elements.filter(element => element.offsetTop === last)[0].classList.add('bottom'); /* set class for first bottom element */
		}
	},
	mounted() {
		this.updateHeight();
	},
	updated() {
		this.updateHeight();
	}
};
</script>

<style lang="less" scoped src="./tags-list.less" />
