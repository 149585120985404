import {
	Search, Update, Create
} from '@acx-xms/data-functions/dist';
import { GetUserInfo } from '@/Data/Auth/authentication-service';

let notificationGroups;
let notificationSubGroups;
let availableNotifications;

const

	getNotificationGroups = async () => {
		if (notificationGroups) {
			return notificationGroups;
		}
		const userRoles = await sc.classes.get('authentication').getUserInfo();
		const isGuest = userRoles.CRMRoles.some(role => role.Name === 'Guest');
		const query = ['All'];
		if (!isGuest) {
			query.push('Broker');
		}
		const subgroupsFilters = [
			sc.classes.get('offsetSize.filter', 50),
			sc.classes.get('termFacet.filter', {
				logicalName: 'recepient',
				query
			}).fillQuery(),
			sc.classes.get('orderBy.filter', {
				query: [{
					logicalName: 'order',
					value: false
				}]
			})
		];
		if (sc.utils.hasCRMsettings) {
			const inquiryId = 'c13f3232-4bef-4fd0-b0eb-60597643a426';
			subgroupsFilters.push(
				sc.classes.get('termFacet.filter', {
					logicalName: 'lookupnotificationtypeid',
					query: [inquiryId],
					negative: true
				}).fillQuery()
			);
		}
		const [groups, subgroups] = await Promise.all([
			Search(['lookupnotificationgroup'], [sc.classes.get('offsetSize.filter', 3)]), // change size of the request if more groups are added
			Search(['lookupnotificationtype'], subgroupsFilters)
		]);

		notificationSubGroups = subgroups.Results.map(item => {
			return {
				id: item.Id,
				name: item.Name,
				parentlookup: item.Source.parentlookup,
				description: item.Source.description,
				readonly: item.Source.readonly || false
			};
		});

		if (isGuest) {
			groups.Results = groups.Results.filter(item => item.Name === 'Events');
		}

		notificationGroups = groups.Results
			.sort((a, b) => (a.Source.order > b.Source.order) ? 1 : -1)
			.map(item => {
				return {
					id: item.Id,
					name: item.Name,
					subgroups: notificationSubGroups.filter(subitem => {
						return subitem.parentlookup.id === item.Id;
					})
				};
			});

		return notificationGroups;
	};

const getAvailableNotifications = async () => {
	if (availableNotifications) {
		return availableNotifications;
	}
	const { systemuserid } = await GetUserInfo();
	const results = await Search(['systemusernotificationssettings'], [
		sc.classes.get('offsetSize.filter', 50),
		sc.classes.get('termFacet.filter', {
			logicalName: 'ownerid.id',
			query: [systemuserid]
		}).fillQuery(),
		sc.classes.get('selectedFields.filter', [
			{ logicalname: 'notificationtype.id' },
			{ logicalname: 'enabled' }
		]).fillQuery()
	]);

	availableNotifications = {};

	notificationSubGroups.forEach(item => {
		const resultItem = results.Results.find(res => {
			return item.id === res.Source.notificationtype.id;
		});

		availableNotifications[item.id] = resultItem
			? {
				id: resultItem.Id,
				enabled: resultItem.Source.enabled,
				name: resultItem.Name,
				notificationId: item.id
			}
			: {
				id: null,
				enabled: true,
				name: item.name,
				notificationId: item.id
			};
	});

	return availableNotifications;
};

const updateNotificationSetting = async (item) => {
	await updateNotificationSettingById(item.id, item.enabled);
};

const updateNotificationSettingById = async (notificationId, newstate) => {
	const item = availableNotifications[notificationId];
	if (item.enabled === newstate) {
		return;
	}

	if (!item.id) {
		const { systemuserid } = await GetUserInfo();
		const newItem = await Create('systemusernotificationssettings', {
			name: `${item.name} - ${systemuserid}`,
			enabled: newstate,
			systemuserid: {
				id: systemuserid,
				logicalname: 'systemuser'
			},
			notificationtype: {
				id: notificationId,
				logicalname: 'lookupnotificationtype'
			}
		});
		availableNotifications[notificationId].id = newItem.systemusernotificationssettingsid;
	} else {
		await Update('systemusernotificationssettings', item.id, { enabled: newstate });
	}

	availableNotifications[notificationId].enabled = newstate;
};

const updateNotificationSettings = async (items) => {
	items.forEach(updateNotificationSetting);
};

export {
	getNotificationGroups, getAvailableNotifications, updateNotificationSettings
};
