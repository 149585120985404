var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "import-dialog" },
    [
      _c(
        "ul",
        { staticClass: "steps" },
        _vm._l(_vm.stepsLabels, function(step, index) {
          return _c(
            "li",
            {
              key: index + _vm.stepKey,
              staticClass: "step",
              class: _vm.classObject(index + 1)
            },
            [
              _c("span", { staticClass: "step-icon" }),
              _vm._v(" "),
              _c("span", { staticClass: "step-label" }, [_vm._v(_vm._s(step))])
            ]
          )
        })
      ),
      _vm._v(" "),
      _c("importStepOne", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.step === 1,
            expression: "step === 1"
          }
        ],
        attrs: { step: _vm.step, options: _vm.options }
      }),
      _vm._v(" "),
      _vm.step === 2
        ? _c("importStepTwo", {
            attrs: { step: _vm.step, options: _vm.stepTwoData }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.step === 3
        ? _c("importStepThree", {
            attrs: { step: _vm.step, options: _vm.stepThreeData }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.step === 4
        ? _c("importStepFour", {
            attrs: { step: _vm.step, options: _vm.stepFourData }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }