var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-title adaptive" }, [
    _vm.icon
      ? _c("span", { staticClass: "icon-wrapper" }, [
          _c("i", { class: ["icon", _vm.icon] })
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.localizedTitle
      ? _c("span", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: _vm.localizedTitle },
              expression: "{ key: localizedTitle }"
            }
          ],
          staticClass: "caption"
        })
      : _vm.title
      ? _c(
          "span",
          {
            directives: [
              {
                name: "data-attr",
                rawName: "v-data-attr",
                value: {
                  title: _vm.dialogOptions.title,
                  $type: "dialog.header"
                },
                expression:
                  "{title: dialogOptions.title, $type: 'dialog.header'}"
              }
            ],
            staticClass: "caption"
          },
          [_vm._v(_vm._s(_vm.title))]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "control-box" }, [
      _vm.statusMessage
        ? _c("div", { staticClass: "status" }, [
            _c("span", { staticClass: "status-message" }, [
              _vm._v(_vm._s(_vm.statusMessage))
            ]),
            _vm._v(" "),
            _vm.statusIcon
              ? _c("span", { staticClass: "icon" }, [
                  _c("i", { class: ["status-icon", _vm.statusIcon] })
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.allowClose,
              expression: "allowClose"
            },
            {
              name: "data-attr",
              rawName: "v-data-attr",
              value: {
                title: _vm.dialogOptions.title,
                $type: "dialog.button.close"
              },
              expression:
                "{title: dialogOptions.title, $type: 'dialog.button.close'}"
            }
          ],
          staticClass: "control-button close-window tooltip",
          on: { click: _vm.close }
        },
        [
          _c("svg", { staticClass: "svg-icon" }, [
            _c("use", { attrs: { "xlink:href": "#layout-icons-dialog-close" } })
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }