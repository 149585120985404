export default {
	async checkIsRelatedRecordExists(relationType, relateFromField, relateFromId, relateToField, relateToId) {
		const dataProvider = sc.classes.get('edge.dataProvider');
		const filters = [
			sc.classes.get('termFacet.filter', {
				logicalName: relateFromField + '.id',
				query: [relateFromId]
			}),
			sc.classes.get('termFacet.filter', {
				logicalName: relateToField + '.id',
				query: [relateToId]
			}),
			sc.classes.get('offsetSize.filter', 0),
			sc.classes.get('selectedFields.filter', [])
		];

		const data = await dataProvider.search({
			entities: [relationType],
			filters,
			resultFormat: 'hit'
		});

		return data.Total > 0;
	},
	async findRelatedRecordIds(relationType, relateFromField, relateFromIds, relateToField) {
		const dataProvider = sc.classes.get('edge.dataProvider');
		const filters = [
			sc.classes.get('offsetFrom.filter', 0),
			sc.classes.get('offsetSize.filter', 9999),
			sc.classes.get('resultsFormat.filter', 'Source'),
			sc.classes.get('termFacet.filter', {
				logicalName: relateFromField + '.id',
				query: [relateFromIds]
			}).fillQuery()
		];
		const data = await dataProvider.search({
			entities: [relationType],
			filters
		});

		return data.Results.length ? data.Results.map(result => result[relateToField].id) : [];
	},
	createRelationRecord(relationType, relateFromField, relateFromRef, relateToField, relateToRef, documentName, additionalFields) {
		const dataProvider = sc.classes.get('edge.dataProvider');
		const hasAdditionalFields = additionalFields && Object.keys(additionalFields).length;
		const relationRecord = {
			name: documentName,
			...(!hasAdditionalFields && documentName && { title: documentName }),
			...(hasAdditionalFields && additionalFields),
			[relateFromField]: relateFromRef,
			[relateToField]: relateToRef
		};
		return dataProvider.create(relationType, relationRecord);
	}
};
