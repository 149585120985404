<template>
	<div v-if="visible" :class="['row', cssClass]">
		<component
			v-for="column in mappedRow.column"
			:is="column.columnType"
			:key="column.$key"
			:options="column"
			:evaluationContext="evaluationContext">
		</component>
	</div>
</template>
<script>
import Control from './../../Entity/control';
import helperMethods from './../component-set-evaluation-context-helper.methods';
const DetailsColumnControlNamespaced = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './details-column-control-namespaced');
const DetailsColumnWithRowControl = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './details-columnWithRow-control');

export default {
	name: 'details-row-control',
	props: {
		row: Object,
		evaluationContext: Object
	},
	components: {
		Control,
		DetailsColumnControl: () => import('./details-column-control'),
		DetailsColumnControlNamespaced,
		DetailsColumnWithRowControl
	},
	data() {
		return {
			mappedRow: {},
			cssClass: '',
			visible: true
		};
	},
	async created() {
		const columnMap = {
			'control.searchResultNamespacedDetailsColumn': 'details-column-control-namespaced',
			'control.searchResultDetailsColumn': 'details-column-control',
			'control.searchResultDetailsColumnWithRow': 'details-columnWithRow-control'
		};
		this.visible = this.row && this.row.visible ? await helperMethods.evalAsync(this.evaluationContext, this.row.visible) : true;
		this.cssClass = this.evaluationContext.eval(this.row && this.row.cssClass);
		this.cssClass = this.cssClass ? this.cssClass.split(' ') : [];
		this.mappedRow = {
			column: ((this.row && this.row.column) || []).map((column) => ({
				...column,
				columnType: columnMap[column.$type],
				$key: helperMethods.getRandomArbitary(1, 10000000000000000).toFixed()
			}))
		};
	}
};
</script>
<style src="./details-row.less" scoped></style>
