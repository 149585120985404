import { render, staticRenderFns } from "./manage-security-roles-dialog.vue?vue&type=template&id=67b50152&scoped=true&"
import script from "./manage-security-roles-dialog.vue?vue&type=script&lang=js&"
export * from "./manage-security-roles-dialog.vue?vue&type=script&lang=js&"
import style0 from "./manage-security-roles-dialog.less?vue&type=style&index=0&id=67b50152&scoped=true&lang=css&"
import style1 from "@/Components/Control/Checkboxes/virtual-checkbox.less?vue&type=style&index=1&id=67b50152&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67b50152",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\SCCore\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('67b50152', component.options)
    } else {
      api.reload('67b50152', component.options)
    }
    module.hot.accept("./manage-security-roles-dialog.vue?vue&type=template&id=67b50152&scoped=true&", function () {
      api.rerender('67b50152', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "Scripts/Vue/Components/ManageSecurityRolesDialog/manage-security-roles-dialog.vue"
export default component.exports