var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "switch-records-wrapper",
      class: [_vm.cssClass, { disabled: _vm.items.length < 2 }]
    },
    [
      _c("div", { staticClass: "prev", on: { click: _vm.selectPrevious } }, [
        _c("svg", { staticClass: "svg-icon" }, [
          _c("use", { attrs: { "xlink:href": "#layout-icons-ms-arrow-left" } })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "next", on: { click: _vm.selectNext } }, [
        _c("svg", { staticClass: "svg-icon" }, [
          _c("use", { attrs: { "xlink:href": "#layout-icons-ms-arrow-right" } })
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }