<template>
	<div class="control-hidden" v-show="false"></div>
</template>
<script>
import ControlMixins from '@/Components/Editor/Controls/control.mixins';
export default {
	name: 'control-hidden',
	mixins: [ControlMixins],
	created() {
		this.value = this.evaluationContext.eval(this.options.value);
	}
};
</script>
