import { BaseAspect } from './base.aspect';
import { Update, Create } from '@acx-xms/data-functions/dist';

export class CreatePreferenceAspect extends BaseAspect {
	constructor(options) {
		super();

		this.fields = [];
		this.stage = 'async';
		this.detach = true;
		this.preferenceNameField = options ? super.eval(options.preferenceNameField) : null;
		this.preferenceLink = options ? super.eval(options.preferenceLink) : null;

		this.init(options);
	}

	init(options) {
		super.init(options, options.evaluationContext);
	}

	async execute(options) {
		const isUpdate = !!options.entityRef.id;
		if (isUpdate && _.isEmpty(options.predefinedAspectsFields)) return;

		const entity = sc.classes.get('entityReference', options.saveResult);
		const nameValue = sc.utils.findProperty(options.saveResult, this.preferenceNameField, true) || sc.utils.findProperty(options.saveResult.Source, this.preferenceNameField, true);
		if (nameValue) {
			const preferenceData = {};
			preferenceData[this.preferenceLink] = entity;
			if (options.predefinedAspectsFields && options.predefinedAspectsFields.preferenceid) {
				return await Update('preference', options.predefinedAspectsFields.preferenceid, preferenceData).catch(err => { this.errorHandler(err); });
			} else if (options.store.preference) {
				return await Update('preference', options.store.preference.id, preferenceData).catch(err => { this.errorHandler(err); });
			} else {
				preferenceData.name = nameValue;
				return await Create('preference', preferenceData).catch(err => { this.errorHandler(err); });
			}
		}
	}
}
