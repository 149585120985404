var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        { staticClass: "color-set" },
        _vm._l(_vm.colors, function(color, index) {
          return _vm.colors
            ? _c("div", {
                key: index,
                staticClass: "color-item",
                style: { "background-color": color }
              })
            : _vm._e()
        })
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }