<template>
	<div class="manage-roles editor">
		<h5 class="subheader" v-localization="{ key: 'dialogues.manageAccessToRoom.selectRole'}"></h5>
		<div class="dialog-container">
			<div class="input-radio-wrapper">
				<p class="h6 title">Chat</p>
				<label>
					<input type="radio" v-model="selectedChatRole" :value="defaultRole.roleName" />
					<span class="p1">{{defaultRole.name}}</span>
				</label>
				<label v-for="role in chatRoles" :key="role.name">
					<input type="radio" v-model="selectedChatRole" :value="role.roleName" />
					<span class="p1">{{role.name}}</span>
				</label>
			</div>
			<div class="input-radio-wrapper">
				<p class="h6 title">Documents</p>
				<label>
					<input type="radio" v-model="selectedDocumentRole" :value="defaultRole.roleName" />
					<span class="p1">{{defaultRole.name}}</span>
				</label>
				<label v-for="role in documentRoles" :key="role.name">
					<input type="radio" v-model="selectedDocumentRole" :value="role.roleName" />
					<span class="p1">{{role.name}}</span>
				</label>
			</div>
		</div>
		<div class="footer">
			<button class="cancel" @click="onCancel" v-data-attr="{title: options.title, $type: 'dialog.button.cancel'}">Cancel</button>
			<button @click="onSubmit" v-data-attr="{title: options.title, $type: 'dialog.button.submit'}">Save</button>
		</div>
		<div class="loading-overlay" v-show="isLoading"></div>
	</div>
</template>
<script>
import {
	Search, Delete, CreateBulk
} from '@acx-xms/data-functions/dist';

export default {
	name: 'manage-access-to-room',
	props: { options: Object },
	data() {
		return {
			isLoading: false,
			chatRoles: [
				{
					name: 'Read',
					roleName: 'Participant With Read Access to Chat'
				},
				{
					name: 'Edit',
					roleName: 'Participant With Edit Access to Chat'
				}
			],
			documentRoles: [
				{
					name: 'Read',
					roleName: 'Participant With Read Access to Documents'
				}
			],
			defaultRole: {
				name: 'No Access',
				roleName: 'Non-Registered Participant'
			},
			assignedRoles: [],
			selectedChatRole: '',
			selectedDocumentRole: ''
		};
	},
	computed: {
		roleNames() {
			return this.chatRoles.concat(this.documentRoles).map(role => role.roleName);
		},
		chatRolesNames() {
			return this.chatRoles.map(role => role.roleName);
		},
		documentRolesNames() {
			return this.documentRoles.map(role => role.roleName);
		},
		assignedRolesNames() {
			return this.assignedRoles.map(role => role.rolename);
		}
	},
	async created() {
		this.isLoading = true;
		await this.getRoles();
		await this.getDummyUserRoles();
		this.selectedChatRole = this.chatRolesNames.find(chatRoleName => this.assignedRolesNames.includes(chatRoleName)) || this.defaultRole.roleName;
		this.selectedDocumentRole = this.documentRolesNames.find(documentRoleName => this.assignedRolesNames.includes(documentRoleName)) || this.defaultRole.roleName;
		this.isLoading = false;
	},
	methods: {
		async getRoles() {
			const filters = [
				sc.classes.get('offsetFrom.filter', 0),
				sc.classes.get('offsetSize.filter', 3),
				sc.classes.get('termFacet.filter', {
					logicalName: 'name',
					query: this.roleNames
				}).fillQuery(),
				sc.classes.get('selectedFields.filter', [
					{ logicalname: 'roleid' }
				]).fillQuery()
			];
			const results = await Search(['role'], filters);
			this.initialRoles = results.Results.map(role => {
				return {
					roleid: role.Source.roleid,
					name: role.Name
				};
			});
		},
		async getDummyUserRoles() {
			const filters = [
				sc.classes.get('offsetFrom.filter', 0),
				sc.classes.get('offsetSize.filter', 1),
				sc.classes.get('termFacet.filter', {
					logicalName: 'systemuserid',
					query: [this.options.parameters.accessuserid.id]
				}).fillQuery(),
				sc.classes.get('selectedFields.filter', [
					{ logicalname: 'rolemembers.rolename' },
					{ logicalname: 'rolemembers.rolememberid' }
				]).fillQuery()
			];
			const results = await Search(['systemuser'], filters);
			const user = results.Results[0];
			this.assignedRoles = user.Source.rolemembers.filter(role => {
				return role.rolename !== this.defaultRole.roleName;
			}).map(role => {
				return {
					rolename: role.rolename,
					rolememberid: role.rolememberid
				};
			});
		},
		onCancel() {
			this.close();
		},
		async onSubmit() {
			this.isLoading = true;
			const promiseArr = [];
			const rolesToAdd = [];
			const rolesToDelete = [];

			// getting actual roles
			await this.getDummyUserRoles();
			const alreadyAssignedChatRole = this.assignedRolesNames.includes(this.selectedChatRole);

			if (!alreadyAssignedChatRole) {
				if (this.selectedChatRole !== this.defaultRole.roleName) {
					this.chatRoles.forEach(chatRole => {
						if (this.selectedChatRole === chatRole.roleName) {
							const roleToAdd = this.initialRoles.find(role => role.name === this.selectedChatRole);
							rolesToAdd.push(roleToAdd);
						} else if (this.assignedRolesNames.includes(chatRole.roleName)) {
							const roleToDelete = this.initialRoles.find(role => role.name === chatRole.roleName);
							rolesToDelete.push(roleToDelete);
						}
					});
				} else {
					this.chatRoles.forEach(chatRole => {
						if (this.assignedRolesNames.includes(chatRole.roleName)) {
							const roleToDelete = this.initialRoles.find(role => role.name === chatRole.roleName);
							rolesToDelete.push(roleToDelete);
						}
					});
				};
			}

			const alreadyAssignedDocumentRole = this.assignedRolesNames.includes(this.selectedDocumentRole);

			if (!alreadyAssignedDocumentRole) {
				if (this.selectedDocumentRole !== this.defaultRole.roleName) {
					this.documentRoles.forEach(documentRole => {
						if (this.selectedDocumentRole === documentRole.roleName) {
							const roleToAdd = this.initialRoles.find(role => role.name === this.selectedDocumentRole);
							rolesToAdd.push(roleToAdd);
						}
						// we dont need "else" because we have only one role to assign
					});
				} else {
					this.documentRoles.forEach(documentRole => {
						if (this.assignedRolesNames.includes(documentRole.roleName)) {
							const roleToDelete = this.initialRoles.find(role => role.name === documentRole.roleName);
							rolesToDelete.push(roleToDelete);
						}
					});
				};
			}
			if (rolesToAdd.length) {
				const records = [];
				rolesToAdd.forEach(roleToAdd => {
					const record = {
						id: sc.utils.guid(),
						method: 'POST',
						url: 'rolemember',
						body: {
							type: 'rolemember',
							name: ' ', // required field in EDGE
							roleid: {
								id: roleToAdd.roleid,
								logicalname: 'role'
							},
							memberid: this.options.parameters.accessuserid,
							rolename: roleToAdd.name
						}
					};
					records.push(record);
				});
				promiseArr.push(CreateBulk(records, { async: true }));
			}
			if (rolesToDelete.length) {
				rolesToDelete.forEach(roleToDelete => {
					const rolememberRef = this.assignedRoles.find(role => role.rolename === roleToDelete.name);
					promiseArr.push(Delete('rolemember', rolememberRef.rolememberid));
				});
			}
			await Promise.all(promiseArr);
			this.$parent.$emit('close');
		},
		close() {
			this.$parent.$emit('close');
		}
	}
};
</script>
<style src="./manage-access-to-room.less" scoped></style>
<style src="@/Components/Control/Checkboxes/virtual-checkbox.less" scoped></style>
