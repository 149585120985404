var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "quote-message-wrapper" }, [
    _c("div", { staticClass: "quote-message" }, [
      _c("div", { staticClass: "first-line" }, [
        _c("p", { staticClass: "p2" }, [_vm._v(_vm._s(_vm.createBy) + ",")]),
        _vm._v(" "),
        _c("p", { staticClass: "p6 date" }, [_vm._v(_vm._s(_vm.date))])
      ]),
      _vm._v(" "),
      _c("p", {
        directives: [{ name: "parselinks", rawName: "v-parselinks" }],
        staticClass: "quote-message-content p1",
        domProps: { textContent: _vm._s(_vm.message.text) }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }