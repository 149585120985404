var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "editor" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "perfectscroll",
            rawName: "v-perfectscroll",
            value: { enable: true },
            expression: "{ enable: true }"
          }
        ],
        staticClass: "body create-shareddocument-editor"
      },
      [
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isLoading,
              expression: "isLoading"
            }
          ],
          staticClass: "loading-overlay large"
        }),
        _vm._v(" "),
        _vm.editorTemplate
          ? _c("control", {
              attrs: {
                name: _vm.editorTemplate.$type,
                contentProps: _vm.editorTemplate,
                evaluationContext: _vm.controlContext
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "entity-list" },
          [
            false
              ? _c("control", {
                  staticClass: "formcontrol-template",
                  attrs: {
                    name: _vm.documentRelatedRecordsTemplate.$type,
                    contentProps: _vm.documentRelatedRecordsTemplate,
                    evaluationContext: _vm.controlContext,
                    initResults: _vm.results
                  }
                })
              : _vm._e()
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c("button", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.state === "notStarted",
            expression: "state==='notStarted'"
          },
          {
            name: "localization",
            rawName: "v-localization",
            value: { key: "common.dictionary.buttons.cancel" },
            expression: "{key: 'common.dictionary.buttons.cancel'}"
          },
          {
            name: "data-attr",
            rawName: "v-data-attr",
            value: { title: _vm.options.title, $type: "dialog.button.cancel" },
            expression: "{title: options.title, $type: 'dialog.button.cancel'}"
          }
        ],
        staticClass: "cancel",
        on: { click: _vm.onCancel }
      }),
      _vm._v(" "),
      _c("button", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.state !== "finished",
            expression: "state!=='finished'"
          },
          {
            name: "localization",
            rawName: "v-localization",
            value: { key: "common.dictionary.buttons.upload" },
            expression: "{key: 'common.dictionary.buttons.upload'}"
          },
          {
            name: "data-attr",
            rawName: "v-data-attr",
            value: { title: _vm.options.title, $type: "dialog.button.submit" },
            expression: "{title: options.title, $type: 'dialog.button.submit'}"
          }
        ],
        attrs: { disabled: _vm.state === "started" },
        on: { click: _vm.saveSharedDoc }
      }),
      _vm._v(" "),
      _c("button", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.state === "finished",
            expression: "state==='finished'"
          },
          {
            name: "localization",
            rawName: "v-localization",
            value: { key: "common.dictionary.buttons.close" },
            expression: "{key: 'common.dictionary.buttons.close'}"
          },
          {
            name: "data-attr",
            rawName: "v-data-attr",
            value: { title: _vm.options.title, $type: "dialog.button.cancel" },
            expression: "{title: options.title, $type: 'dialog.button.cancel'}"
          }
        ],
        on: { click: _vm.onCancel }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }