var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sc-ndaFileUpload" }, [
    _c(
      "div",
      { staticClass: "nda-file-upload-wrapper row" },
      [
        _c("control-file-upload", {
          staticClass: "control-wrapper",
          class: { invalid: !_vm.isValid },
          attrs: {
            evaluationContext: _vm.evaluationContext,
            options: _vm.options,
            showRequiredMark: _vm.showRequiredMark
          },
          model: {
            value: _vm.uploadedFileName,
            callback: function($$v) {
              _vm.uploadedFileName = $$v
            },
            expression: "uploadedFileName"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }