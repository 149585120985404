<template>
	<div class='tabs-pop-up-wrapper' :class="{'justify-content-end' : isLoading || isValidating}">
		<tabs v-if="!isLoading && !isValidating">
			<tab title="Email Setup" :visible=hasSMTPApp>
				<div class="tab-content smtp">
					<div class="p4 tab-title">Please enter SMTP server credentials</div>
					<div class="default-smtp-wrapper">
						<div class="checkbox-wrapper">
							<checkbox :value="isDefaultSMTPEnabled" v-model="isDefaultSMTPEnabled"/>
							<label class="label">Use default server</label>
							<div class="tooltip">
								<span class="tooltip-icon">
									<svg class="svg-icon svg-16">
										<use xlink:href="/Images/login-info.svg#login-info"></use>
									</svg>
								</span>
								<p class="tooltip-text">Emails sent from this email address may end up in Spam</p>
							</div>
						</div>
						<p class="default-smtp-info" v-if="isDefaultSMTPEnabled">
							SMTP server is setup. Emails will be sent from the {{defaultSMTPDomain}}. If you want your emails to be sent from another domain, please set up your custom SMTP server.
						</p>
					</div>
					<div class="row row-1">
						<div class="server">
							<div class="title p4">SMTP Server:</div>
							<stringeditor :value="smtpUrl"
										@input="onValueChange($event, 'smtpUrl')"
										:isValid="fieldOptions.smtpUrl.valid"
										:dataAttr="{title: 'Org Settings', $type: 'dialog.input'}"
										:enable="!isDefaultSMTPEnabled"
										:hideText="isDefaultSMTPEnabled"/>
						</div>
						<div class="port">
							<div class="title p4">SMTP Port:</div>
							<stringeditor :value="smtpPort"
										@input="onValueChange($event, 'smtpPort')"
										:isValid="fieldOptions.smtpPort.valid"
										:dataAttr="{title: 'Org Settings', $type: 'dialog.input'}"
										:enable="!isDefaultSMTPEnabled"
										:hideText="isDefaultSMTPEnabled"/>
						</div>
					</div>
					<div class="row row-2">
						<div class="title p4">Login:</div>
						<stringeditor :value="smtpLogin"
									@input="onValueChange($event, 'smtpLogin')"
									:isValid="fieldOptions.smtpLogin.valid"
									:dataAttr="{title: 'Org Settings', $type: 'dialog.input'}"
									:enable="!isDefaultSMTPEnabled"
									:hideText="isDefaultSMTPEnabled"/>

					</div>
					<div class="row row-3">
						<div class="title p4">Password:</div>
						<passwordeditor :value="smtpPass"
										@input="onValueChange($event, 'smtpPass')"
										@onfocuschange="onFocusChange"
										:isValid="fieldOptions.smtpPass.valid"
										:dataAttr="{title: 'Org Settings', $type: 'dialog.input'}"
										:enable="!isDefaultSMTPEnabled"
										:hideText="isDefaultSMTPEnabled"
										:showUnhide="showUnhide"/>

					</div>
					<div class="row row-4">
						<div class="title p4">Email address to send test emails:</div>
						<stringeditor :value="testEmailAddress"
									@input="onValueChange($event, 'testEmailAddress')"
									:isValid="fieldOptions.testEmailAddress.valid"
									:dataAttr="{title: 'Org Settings', $type: 'dialog.input'}"
									:enable="!isDefaultSMTPEnabled"
									:hideText="isDefaultSMTPEnabled"/>
					</div>
					<div class="row row-5" :class="[ status ? 'with-status' : '' ]" v-if="!isDefaultSMTPEnabled">
						<p v-if="status" :class="[ status === 'Connected' ? 'success' : 'error' ]" class="status">
							Status: <span>{{status}}</span>
						</p>
						<button :disabled="!isValidateEnabled" @click="validateCreds" v-data-attr="{title: 'Org Settings', $type: 'dialog.button.submit'}">Validate credentials</button>
					</div>
				</div>
			</tab>
			<tab title="SEO" visible="true">
				<div class="tab-content seo">
					<checkbox :value="isSeoEnabled" v-model="isSeoEnabled"/>
					<label class="label" v-localization="{key: 'common.dictionary.orgsettings.indexing.label'}"></label>
				</div>
			</tab>
		</tabs>
		<div class="footer">
			<button class="cancel"
				@click="close"
				v-localization="{key: 'common.dictionary.buttons.cancel'}"
				v-data-attr="{title: 'Org Settings', $type: 'dialog.button.cancel'}"></button>
			<button @click="onSave"
				v-localization="{key: 'common.dictionary.buttons.save'}"
				:disabled="!isSaveButtonEnabled"
				v-data-attr="{title: 'Org Settings', $type: 'dialog.button.submit'}"></button>
		</div>
		<div class="loading-overlay" v-show="isLoading"></div>
	</div>
</template>

<script>
/* eslint no-useless-escape: 0 */
/* eslint prefer-regex-literals: 0 */
/* eslint prefer-promise-reject-errors: 0 */
import Checkbox from '@/Components/Control/Checkboxes/checkbox-base';
import Tabs from './Tabs/tabs.vue';
import Tab from './Tabs/Tab/tab.vue';
import {
	getOrgSettings, isObject, resourceIsRichable, Search, Update, Get, SendRequest, SendRequestWithAdditionalHeaders
} from '@acx-xms/data-functions/dist';
import { GetUserInfo } from '@/Data/Auth/authentication-service';
import Stringeditor from '@/Components/Editor/Controls/StringEditor/stringeditor';
import Passwordeditor from '@/Components/Editor/Controls/StringEditor/passwordeditor';

const statuses = {
	CONNECTED: 'Connected',
	NOT_CONNECTED: 'Not Connected'
};

const BLANK_SMTP_SETTINGS = {
	smtp: {
		port: '',
		host: '',
		auth: {
			user: '',
			pass: { secret: { value: '' } }
		}
	},
	defaults: {
		from: '',
		sender: ''
	}
};

export default {
	name: 'tabs-popup',
	components: {
		Tabs,
		Tab,
		Checkbox,
		Stringeditor,
		Passwordeditor
	},
	data() {
		return {
			isSeoEnabled: false,
			initSeoValue: false,
			isLoading: false,
			smtpUrl: '',
			smtpPort: '',
			smtpLogin: '',
			smtpPass: '',
			testEmailAddress: '',
			status: '',
			hasSMTPApp: true,
			isValidating: false,
			isDefaultSMTPEnabled: false,
			initDefaultSMTPValue: false,
			defaultSMTPDomain: window.location.hostname,
			fieldOptions: {
				smtpUrl: {
					valid: true,
					maxLength: 1024,
					fieldName: 'SMTP Server',
					regEx: new RegExp('^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$'),
					regExErrorMsg: '- SMTP Server: Server address should not contain any special characters.<br\>'
				},
				smtpPort: {
					valid: true,
					maxLength: 4,
					fieldName: 'SMTP Port'
				},
				smtpLogin: {
					valid: true,
					maxLength: 128,
					fieldName: 'SMTP Login'
				},
				smtpPass: {
					valid: true,
					maxLength: 128,
					fieldName: 'SMTP Password'
				},
				testEmailAddress: {
					valid: true,
					maxLength: 128,
					fieldName: 'Test Email Address',
					regEx: new RegExp('^($|[a-zA-Z0-9_\\.\+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-\\.]+)$'),
					regExErrorMsg: '- Test Email Address: Please enter a valid email address.<br\>'
				}
			}
		};
	},
	computed: {
		isValidateEnabled() {
			return (this.smtpLogin && this.smtpLogin !== this.loginInitial && this.smtpPass &&
					this.smtpPass !== this.dummyPass && this.smtpUrl && this.smtpPort && this.testEmailAddress) ||
					(this.isSavedCustomSettings && this.testEmailAddress);
		},
		isSavedCustomSettings() {
			return this.customSMTPAppSettings && this.customSMTPAppSettings.smtp &&
					this.customSMTPAppSettings.smtp.auth.user === this.smtpLogin &&
					this.customSMTPAppSettings.smtp.port === +this.smtpPort &&
					this.customSMTPAppSettings.smtp.host === this.smtpUrl &&
					this.smtpPass === this.dummyPass;
		},
		isSaveButtonEnabled() {
			if ((this.initDefaultSMTPValue !== this.isDefaultSMTPEnabled) || (this.initSeoValue !== this.isSeoEnabled) || this.status === statuses.CONNECTED) {
				if (this.isDefaultSMTPEnabled) {
					return this.isDefaultSMTPEnabled;
				} else {
					if (this.initDefaultSMTPValue !== this.isDefaultSMTPEnabled) {
						return this.status === statuses.CONNECTED;
					} else {
						return this.isSavedCustomSettings ? this.isSavedCustomSettings : this.status === statuses.CONNECTED;
					}
				}
			}
			return false;
		},
		showUnhide: {
			get() {
				return this.smtpPass !== this.dummyPass;
			},
			set(newVal) {
				return newVal;
			}
		}
	},
	async created() {
		this.isLoading = true;

		const orgSettings = await getOrgSettings(true);
		this.orgSettingId = orgSettings.orgsettingsid;
		this.initSeoValue = this.isSeoEnabled = orgSettings.enableseo;
		this.appName = orgSettings.application;
		const results = await Search(['application'], [
			sc.classes.get('offsetSize.filter', 11).fillQuery()
		]);
		this.dummyPass = '000-000-000-000';

		if (results.Results) {
			const app = results.Results.find(app => app.Source.name.toLowerCase() === this.appName.toLowerCase());
			const validateSMTPApp = results.Results.find(app => app.Source.name.toLowerCase() === `${this.appName}_smtp`.toLowerCase());
			const defaultSMTPApp = results.Results.find(app => app.Source.name.toLowerCase() === `${this.appName}_default_smtp`.toLowerCase());
			const customSMTPApp = results.Results.find(app => app.Source.name.toLowerCase() === `${this.appName}_custom_smtp`.toLowerCase());

			this.appId = app && app.Id;
			this.customSMTPId = customSMTPApp && customSMTPApp.Id;
			this.validateSMTPAppId = validateSMTPApp && validateSMTPApp.Id;
			this.defaultSMTPSettings = defaultSMTPApp && defaultSMTPApp.Source.sendEmailSettings;
			this.hasSMTPApp = !!this.validateSMTPAppId;
			this.initDefaultSMTPValue = this.isDefaultSMTPEnabled =
					Object.keys(app.Source.sendEmailSettings).length === 1 && !!app.Source.sendEmailSettings.secret;

			if (validateSMTPApp && validateSMTPApp.Source.sendEmailSettings && validateSMTPApp.Source.sendEmailSettings.smtp) {
				this.validateSMTPAppSettings = validateSMTPApp.Source.sendEmailSettings;
			}
			this.customSMTPAppSettings = customSMTPApp && customSMTPApp.Source.sendEmailSettings;

			if (app && app.Source.sendEmailSettings && app.Source.sendEmailSettings.smtp) {
				this.populateForm(app.Source.sendEmailSettings);
			} else if (this.customSMTPAppSettings && this.customSMTPAppSettings.smtp) {
				this.populateForm(this.customSMTPAppSettings);
			} else {
				this.populateForm();
			}
		};

		this.isLoading = false;
	},
	methods: {
		onValueChange(event, name) {
			this[name] = event;
			this.status = '';
		},
		onFocusChange({ value, isUnhideClicked }) {
			if (value && this.smtpPass === this.dummyPass) {
				this.smtpPass = '';
				this.showUnhide = true;
			}
			if (!value && !this.smtpPass && !isUnhideClicked && Object.keys(this.customSMTPAppSettings).length) {
				this.smtpPass = this.dummyPass;
			}
		},
		populateForm(settings) {
			if (settings) {
				this.settings = settings;
				this.smtpPort = settings.smtp.port.toString();
				this.smtpUrl = settings.smtp.host;
				this.smtpLogin = settings.smtp.auth && settings.smtp.auth.user;
				this.smtpPass = settings.smtp.auth && this.dummyPass;
			} else {
				this.settings = BLANK_SMTP_SETTINGS;
			}
		},
		async onSave() {
			this.isLoading = true;
			const promiseArr = [this.saveSEOValues()];

			if (this.status === statuses.CONNECTED && !this.isDefaultSMTPEnabled) {
				if (!this.isSavedCustomSettings) {
					promiseArr.push(this.saveCustomSMTP(this.appId, this.settings));
					promiseArr.push(this.saveCustomSMTP(this.customSMTPId, this.settings));
				}
				if (this.isSavedCustomSettings && this.initDefaultSMTPValue) {
					promiseArr.push(this.saveCustomSMTP(this.appId, this.settings));
				}
			}

			if (this.isDefaultSMTPEnabled && !this.initDefaultSMTPValue) {
				promiseArr.push(this.saveDefaultSMTP(this.appId, this.defaultSMTPSettings));
			}

			await Promise.all(promiseArr);

			this.isLoading = false;
			this.close();
		},
		close() {
			this.$parent.$emit('close');
		},
		async saveCustomSMTP(appId, settings) {
			if (!settings) {
				settings = BLANK_SMTP_SETTINGS;
			}
			const update = _.cloneDeep(settings);

			if (+settings.smtp.port !== +this.smtpPort) {
				update.smtp.port = +this.smtpPort;// TODO: add numericeditor
			}
			if (settings.smtp.host !== this.smtpUrl) {
				update.smtp.host = this.smtpUrl;
			}
			if (settings.smtp.auth.user !== this.smtpLogin) {
				update.smtp.auth = { user: this.smtpLogin };
				update.defaults = {
					from: this.smtpLogin,
					sender: this.smtpLogin
				};
			}
			if (this.smtpPass && !this.isSavedCustomSettings) {
				update.smtp.auth.pass = { secret: { value: this.smtpPass } };
			}
			if (this.isSavedCustomSettings) {
				update.smtp.auth.pass = this.customSMTPAppSettings.smtp.auth.pass;
			}
			if (!settings.defaults.sender) {
				update.defaults = {
					from: this.smtpLogin,
					sender: this.smtpLogin
				};
			}
			const objHasValueDeep = (json) => {
				let hasValue = false;
				if (!isObject(json)) {
					return hasValue;
				}
				Object.values(json).forEach(value => {
					if (isObject(value)) {
						hasValue = objHasValueDeep(value);
					} else {
						hasValue = !!value;
					}
				});
				return hasValue;
			};
			if ((appId && objHasValueDeep(update.smtp) && JSON.stringify(update) !== JSON.stringify(settings)) || this.isSavedCustomSettings) {
				const response = await Update('application', appId, { sendEmailSettings: update }, null, { syncProcessMode: 'All' });
				return response && response.sendEmailSettings;
			}
		},
		async saveDefaultSMTP(appId, settings) {
			await Update('application', appId, { sendEmailSettings: settings }, null, { syncProcessMode: 'All' });
		},
		async saveSEOValues() {
			if (this.initSeoValue !== this.isSeoEnabled) {
				await Update('orgsettings', this.orgSettingId, { enableseo: this.isSeoEnabled }, null, { syncProcessMode: 'All' });
				await getOrgSettings(true);

				SendRequest(
					'/api/sitemap/enableindex',
					'post',
					{ enable: this.isSeoEnabled },
					null,
					{ configKey: sc.cluster.configKey },
					false,
					false);
			}
		},
		validate() {
			const error = {
				message: '',
				invalidFields: []
			};
			Object.keys(this.fieldOptions).forEach(key => {
				const fieldOptions = this.fieldOptions[key];
				fieldOptions.valid = true;
				if (fieldOptions.regEx) {
					if (!fieldOptions.regEx.test(this[key])) {
						fieldOptions.valid = false;
						error.message += fieldOptions.regExErrorMsg;
						error.invalidFields.push(key);
					}
				}
				if (fieldOptions.maxLength && fieldOptions.maxLength < this[key].length) {
					error.message += `- ${fieldOptions.fieldName}: Length must be less than ${fieldOptions.maxLength}.<br\>`;
				}
			});
			if (error.message) {
				throw error;
			}
		},
		async validateCreds() {
			this.isLoading = true;
			this.isValidating = true;

			// saveCustomSMTP before generate jwt #172497
			await this.saveCustomSMTP(this.validateSMTPAppId, this.validateSMTPAppSettings);

			try {
				await this.validate();
			} catch (e) {
				sc.events.emit('dialog.error', {
					title: await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('entity.editor.validationError'),
					message: e.message
				});

				if (e.invalidFields.length) {
					e.invalidFields.forEach(field => { this[field] = ''; });
				}
				this.status = statuses.NOT_CONNECTED;
				this.isLoading = false;
				this.isValidating = false;
				return;
			}
			const isRichable = resourceIsRichable(this.smtpUrl);
			if (!isRichable) {
				this.status = statuses.NOT_CONNECTED;
				this.isLoading = false;
				this.isValidating = false;
				return;
			}
			const { systemuserid } = await GetUserInfo();
			const body1 = {
				ApplicationId: `${this.appName}_smtp`,
				Scope: [
					'edge.*.read',
					'edge.*.create',
					'edge.*.write',
					'edge.*.share',
					'edge.*.delete',
					`edge.maintenance.impersonation.${systemuserid}`
				],
				IsApplicationOnly: true,
				ExpirationTime: '0000.00:03:01.00'// We create token for 3 mins

			};
			const res = await SendRequest('/api/edgeproxy/api/oauth2/jwt', 'POST', body1);
			const authToken = res.EncodedContent;
			const headers = {
				'Authorization-Token': authToken,
				'Impersonation-Token': systemuserid
			};
			const savedSettings = await this.saveCustomSMTP(this.validateSMTPAppId, this.validateSMTPAppSettings);
			if (savedSettings) {
				this.validateSMTPAppSettings = savedSettings;
			}

			const body = {
				to: this.testEmailAddress,
				subject: 'Test SMTP APP',
				text: 'This test email is sent to verify SMTP connection settings'
			};
			const email = await SendRequestWithAdditionalHeaders(headers, '/api/edgeproxy/api/email', 'POST', body);
			const checkStatus = async (id, timeout, maxRetryCount) => {
				let retryCount = 0;
				return new Promise((resolve, reject) => {
					const exec = async () => {
						const email = await Get('email', id);
						if (email.Source.deliveryStatus !== 'sent' && !email.Source.deliveryErrorMsg && retryCount <= maxRetryCount) {
							retryCount++;
							setTimeout(exec, timeout);
						} else if (email.Source.deliveryStatus === 'sent') {
							this.status = statuses.CONNECTED;
							resolve();
						} else if (email.Source.deliveryErrorMsg) {
							this.status = statuses.NOT_CONNECTED;
							reject(email.Source.deliveryErrorMsg);
						} else if (retryCount > maxRetryCount) {
							this.status = statuses.NOT_CONNECTED;
							reject('Reached max retry attempts');
						}
					};
					setTimeout(exec, timeout);
				});
			};
			try {
				await checkStatus(email.emailid, 4000, 15);
			} catch (e) {
				this.$root.$emit('vue.dialog.info', {
					title: await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('dialogs.message'),
					message: e
				});
			}
			this.isLoading = false;
			this.isValidating = false;
		}
	}
};
</script>
<style src='./tabs-popup.less' scoped></style>
<style src='@/Components/Control/FieldWrapper/control-fieldwrapper.less' scoped></style>
