var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "action-menu popup-component" }, [
    _c(
      "div",
      {
        directives: [{ name: "data-attr", rawName: "v-data-attr" }],
        staticClass: "dropdown-wrapper"
      },
      [
        _c("div", { staticClass: "top-right-triangle" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "items" },
          _vm._l(_vm.fields, function(field, index) {
            return _c(
              "div",
              {
                key: field.logicalname + "-" + index,
                class: ["menu-item", { active: field.isSelected }],
                on: {
                  click: function($event) {
                    _vm.sort(field)
                  }
                }
              },
              [_vm._v("\n\t\t\t\t" + _vm._s(field.displayName) + "\n\t\t\t")]
            )
          })
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }