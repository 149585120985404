<template>
	<div class="remove-document-dialog-wrapper">
		<div class="delete-dialog-message">
			<span class="p4" v-localization="{ key: 'common.dictionary.confirmRemoving'}"></span>
		</div>
		<div class="footer">
			<button class="cancel" @click="close" v-localization="{ key: 'common.dictionary.buttons.cancel'}"></button>
			<button type="button" @click="onSubmit" v-localization="{ key: 'common.dictionary.buttons.delete'}"></button>
		</div>
		<div class="loading-overlay" v-show="isLoading"></div>
	</div>
</template>
<script>
import {
	showToastOrInfoDialog, Delete, Update
} from '@acx-xms/data-functions/dist';
import { evaluate } from '@/Data/EvaluationContext/evaluationContext';

export default {
	name: 'remove-document-dialog',
	props: { options: Object },
	data() {
		return { isLoading: false };
	},
	methods: {
		close() {
			this.$parent.$emit('close');
		},
		async onSubmit() {
			this.isLoading = true;
			const entityRef = sc.classes.get('entityReference', this.options.params.entity);
			const deactivateEntityRef = this.options.params.deactivateEntityRef;
			const data = await sc.classes.get('entityConfiguration.dataProvider').fetchEntity(deactivateEntityRef.logicalname);
			const closeCompleteStatuses = data.closeCompleteStatuses3;
			const closeCompleteState = evaluate(closeCompleteStatuses.inactiveStateCode.value);
			const stateLogicalName = closeCompleteStatuses.stateLogicalName;
			try {
				await Delete(entityRef.logicalname, entityRef.id);
				const param = {
					[stateLogicalName]: sc.classes.get('entityReference', {
						id: closeCompleteState.Id,
						logicalname: closeCompleteState.Type
					}).toObject()
				};
				await Update(deactivateEntityRef.logicalname, deactivateEntityRef.id, param, null, { resultsFormat: 'detail' });
				this.isLoading = false;
				this.close();
				this.options.params.refreshEvent && sc.events.emit(this.options.params.refreshEvent);
				await showToastOrInfoDialog({
					toastMessageKey: this.options.params.toastMessageKey || 'common.dictionary.toastText',
					informationDialogTextKey: this.options.params.informationDialogTextKey,
					options: { context: this }
				});
			} catch (error) {
				if (error && error.responseText) {
					sc.utils.errorMessage.byMessage(sc.classes.get('localization.dataProvider').localize('actions.entity.deactivate.oneOrMoreErrors'), error.responseText);
				}
				this.isLoading = false;
				this.close();
			};
		}
	}
};
</script>
<style src="./remove-document-dialog.less" scoped></style>
