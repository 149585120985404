<template>
	<div class="panel" :class="{'highlighted': isClicked}" ref="panel">
		<div class="border-top" v-if="title!=='root'"></div>
		<div class="panel-header with-margin">
			<div class="icon-with-text">
				<span class="icon-holder" @click="isExpanded=!isExpanded">
					<svg class='svg-icon svg-15'>
						<use :xlink:href="'#layout-icons-filter-group-collapse'" class="hover" v-if="isExpanded"></use>
						<use :xlink:href="'#layout-icons-filter-group-open'" class="hover" v-if="!isExpanded"></use>
					</svg>
				</span>
				<span class="h2 title"> {{ title }}:</span>
			</div>
			<div class="icons" v-if="allowItemActions">
				<div v-for="(itemAction, index) in itemActions" class="icon-action" :class="itemAction.class"
					 @click="emitEvent(itemAction.event)"
					 v-show="itemAction.enable(currentIndex)"
					 :key="index">
					<svg class="svg-icon svg-15">
						<use :xlink:href="'#layout-icons-'+itemAction.icon"></use>
					</svg>
				</div>
			</div>
		</div>
		<div v-show="isExpanded" class="with-left-border" :class="{'single-type':types.length==1}">
			<div class="type-value-wrapper with-margin">
				<control-lookup-editor class="types"
									   v-if="types.length>1"
									   v-model="selectedType"
									   :options="getLookupOptions()"></control-lookup-editor>
				<p v-show="types.length==1" class="type p4">{{types[0].displayName}}</p>
			</div>
			<div v-for="(child, index) in children" :key="child.title">
				<panel v-if="child.type==='panel'"
					   :title="child.title"
					   :template="child.value"
					   :base="child.base"
					   :metaFields="metaFields"
					   @onChange="onChange(index, ...arguments)"></panel>
				<enums v-if="child.type==='enum'"
					   :enums="child.enums"
					   :title="child.title"
					   class="with-margin"
					   :currentValue="child.value"
					   @onChange="onChange(index, ...arguments)"></enums>
				<value :title="child.title"
					   :currentValue="child.value"
					   class="with-margin"
					   v-if="(child.type === 'number' || child.type === 'string') && selectedType && selectedType.displayName !== 'FieldExpression' && child.title !== 'name'"
					   @onChange="onChange(index, ...arguments)"></value>
				<lookup-value :title="child.title"
							  :currentValue="child.value"
							  class="with-margin"
							  v-if="selectedType && selectedType.displayName === 'FieldExpression' && child.title === 'name'"
							  :metaFields="metaFields"
							  @onChange="onChange(index, ...arguments)"></lookup-value>
				<boolean-value :title="child.title"
							   :currentValue="!!child.value"
							   class="with-margin"
							   v-if="child.type === 'boolean'"
							   @onChange="onChange(index, ...arguments)"></boolean-value>
				<collection :title="child.title"
							v-if="child.type === 'array'"
							:currentValue="child.value"
							:base="child.base"
							:metaFields="metaFields"
							@onChange="onChange(index, ...arguments)"></collection>
			</div>
		</div>
	</div>
</template>

<script>
/* eslint vue/no-mutating-props: 0 */
import { ResolveByBase, ResolveByType } from './../resolver.js';
import {
	adjustTypeToJson, adjustTypeToSchema, getLookupOptions
} from './../helpersMethods.js';
import { generateId } from '@acx-xms/data-functions/dist';
const Value = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './../value/value');
const LookupValue = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './../value/lookup-value');
const BooleanValue = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './../value/boolean');
const Collection = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './../collection/collection');
const Enums = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './../enums/enums');
const ControlLookupEditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Lookup/control-lookup-editor');

export default {
	name: 'panel',
	props: {
		// TODO: add posibility to add more comprehensive error on Vue warn: type check failed
		template: Object,
		base: String,
		title: String,
		allowItemActions: Boolean,
		itemActions: Array,
		currentIndex: Number,
		metaFields: Array
	},
	components: {
		Value,
		BooleanValue,
		Collection,
		Enums,
		ControlLookupEditor,
		LookupValue
	},
	data() {
		return {
			children: [],
			types: [],
			isExpanded: false,
			selectedType: null,
			isClicked: false
		};
	},
	watch: {
		template() {
			this.getSelectedType();
			this.onTypeChanged();
			this.sortItems();
		}
	},
	created() {
		const types = ResolveByBase(this.base);
		// for lookup
		this.types = types.map(type => {
			type.Id = generateId();
			return {
				data: type,
				displayName: type.title
			};
		});
		this.getSelectedType();
		this.onTypeChanged();
		this.sortItems();
		if (this.template.$id) {
			this.$root.$on('config-tool-click', this.onClick);
		}
		this.$on('expand', this.onExpanded);
		this.$parent.$on('clickChild', (event, value, id) => {
			if (this.title === 'title') {
				this.onClick(event, value, id);
			}
		});
	},
	beforeDestroy() {
		if (this.template.$id) {
			this.$root.$off('config-tool-click', this.onClick);
		}
		this.$off('expand', this.onExpanded);
	},
	methods: {
		// TODO: rethink way of typescript schema generation (for easier mapping of types in json and TypeScript Classes)
		cleanRef(ref) {
			return ref.replace('#/definitions/', '');
		},
		// get all possible types for selectedType
		getSelectedType() {
			if (this.template && this.template.$type) {
				this.selectedType = adjustTypeToSchema(this.template.$type);
			}
			if (this.types.length > 0) {
				let selectedType = this.types[0];
				if (this.types.length > 1 && this.selectedType) {
					selectedType = this.types.find((item) => {
						return item.displayName === this.selectedType;
					});
					if (!selectedType) throw Error(`Type: ${this.selectedType} - is not found`);
				}
				this.selectedType = selectedType;
			}
		},
		// todo - move to utis or something, or refactor to remove
		// Consider adding default templates in for controls/expressions/etc in TS
		getDefaultPanel(type, key, isArray) {
			// todo - implement more switches for key
			const flexibleContainer = {
				$type: 'control.flexibleContainer',
				value: '',
				control: {
					$type: 'control.flexibleFieldWrapper',
					controlWidth: '',
					cssClass: {
						$type: 'expression.text',
						value: ''
					},
					displayStyle: '',
					title: {
						$type: 'expression.text',
						value: ''
					},
					titleWidth: '',
					value: {
						$type: 'control.label',
						value: ''
					}
				}
			};
			if (type.includes('Control')) {
				if (isArray) {
					switch (key) {
					case 'item':
						return [flexibleContainer];
					case 'row':
						return [{
							$type: 'control.searchResultDetailsRow',
							cssClass: {
								$type: 'expression.text',
								value: ''
							},
							column: [flexibleContainer]
						}];
					default:
						return [{
							$type: 'control.label',
							value: ''
						}];
					}
				} else {
					switch (key) {
					case 'control':
						return {
							$type: 'control.flexibleFieldWrapper',
							controlWidth: '',
							cssClass: {
								$type: 'expression.text',
								value: ''
							},
							displayStyle: '',
							minWidth: '',
							title: {
								$type: 'expression.text',
								value: ''
							},
							titleWidth: '',
							value: {
								$type: 'control.label',
								value: ''
							},
							visible: { $type: 'expression.true' }
						};
					default: return {
						$type: 'control.label',
						value: ''
					};
					}
				}
			} else if (type.includes('SearchResult')) {
				if (isArray) {
					switch (key) {
					case 'row':
						return [{
							$type: 'control.searchResultDetailsRow',
							cssClass: {
								$type: 'expression.text',
								value: ''
							},
							column: [flexibleContainer]
						}];
					default:
						return [{
							$type: 'control.label',
							value: ''
						}];
					}
				}
			} else if (type.includes('Expression')) {
				if (isArray) {
					switch (key) {
					case 'id':
						return [{
							$type: 'expression.text',
							value: '32413b0a-0e58-4e15-9de2-ef23c0e72eb2'
						}];
					default:
						return [{
							$type: 'expression.text',
							value: ''
						}];
					}
				} else {
					if (type.includes('BooleanExpression')) {
						switch (key) {
						case 'default':
							return { $type: 'expression.false' };
						default: return { $type: 'expression.true' };
						}
					} else {
						switch (key) {
						case 'condition':
							return {
								$type: 'expression.eq',
								value: ''
							};
						case 'visible':
							return { $type: 'expression.true' };
						default: return {
							$type: 'expression.text',
							value: ''
						};
						}
					}
				}
			} else if (type.includes('Menu')) {
				return [
					{
						$type: 'menu.menuItemSVG',
						display: 'image',
						default: false,
						text: {
							$type: 'expression.text',
							value: 'Edit'
						},
						icon: {
							$type: 'expression.text',
							value: 'action-icons-ms-entity-edit'
						},
						action: {
							$type: 'action.vue.editor',
							logicalname: {
								$type: 'expression.text',
								value: 'image'
							},
							selection: { $type: 'expression.contextRef' },
							source: 'context'
						}
					}
				];
			} else if (type.includes('SwitchCase')) {
				return [
					{
						$type: 'expression.switchCase',
						key: {
							$type: 'expression.text',
							value: ''
						},
						value: {
							$type: 'expression.text',
							value: ''
						}
					}
				];
			} else if (type.includes('ActionParameter')) {
				return [
					{
						$type: 'action.actionParameter',
						name: '',
						value: {
							$type: 'expression.text',
							value: ''
						}
					}
				];
			} else if (type.includes('UpdateData')) {
				return [
					{
						$type: 'action.updData',
						field: {
							$type: 'expression.text',
							value: ''
						},
						value: {
							$type: 'expression.text',
							value: ''
						}
					}
				];
			} else if (type.includes('Action')) {
				return {
					$type: 'action.vue.editor',
					enable: { $type: 'expression.true' }
				};
			} else if (type.includes('DataSource')) {
				return [
					{
						$type: 'imageGallery.relatedImagesDataSource',
						primaryId: { $type: 'expression.contextRef' },
						filter: [
							{
								$type: 'search.termFacetFilter',
								negative: false,
								filterViewKind: 'Default',
								item: [
									{
										$type: 'search.termFacetItem',
										value: {
											$type: 'expression.text',
											value: '8d113fa8-3015-4060-a107-14cedcd19dd3'
										}
									}
								],
								logicalName: 'recordstate.id'
							}
						],
						imageSize: 'thumbnail',
						previewSize: 'thumbnail',
						downloadSize: 'original',
						orderBy: 'primary'
					}
				];
			} else if (type.includes('AbstractSimpleEmbededQuerySearch')) {
				const abstractQuerySearchObject = {
					$type: 'entityTemplate.simpleEmbededQuery',
					name: '',
					enable: { $type: 'expression.false' },
					query: {
						$type: 'search.query',
						size: 1,
						recordType: 'source',
						entity: [
							{
								$type: 'expression.text',
								value: ''
							}
						],
						filter: [
							{
								$type: 'search.termFacetFilter',
								negative: false,
								filterViewKind: 'Default',
								item: [
									{
										$type: 'search.termFacetItem',
										value: {
											$type: 'expression.text',
											value: ''
										},
										enable: { $type: 'expression.true' }
									}
								],
								logicalName: ''
							}
						],
						fields: {
							$type: 'search.selectedFields',
							type: 'Default',
							field: [
								{
									$type: 'search.selectedField',
									name: {
										$type: 'expression.text',
										value: ''
									}
								}
							]
						}
					}
				};
				return isArray ? [abstractQuerySearchObject] : abstractQuerySearchObject;
			} else if (type.includes('TermFacetItem')) {
				return [{
					$type: 'search.termFacetItem',
					value: {
						$type: 'expression.text',
						value: '8d113fa8-3015-4060-a107-14cedcd19dd3'
					}
				}];
			} else if (type.includes('AbstractSelectedFields')) {
				return {
					$type: 'search.selectedFields',
					type: 'Default',
					field: [
						{
							$type: 'search.selectedField',
							name: {
								$type: 'expression.text',
								value: ''
							}
						}
					]
				};
			} else if (type === 'AbstractSelectedField') {
				return [
					{
						$type: 'search.selectedField',
						name: {
							$type: 'expression.text',
							value: ''
						}
					}
				];
			}
		},
		getDefaultEnum(key) {
			switch (key) {
			case 'size': return 'custom';
			case 'imageSize': return 'thumbnail';
			case 'schema': return 'source';
			case 'source': return 'context';
			case 'display': return 'auto';
			case 'uom': return 'area';
			case 'wrap': return 'noWrap';
			default: return '';
			}
		},
		emitEvent(event) {
			this.$emit(event);
		},
		// TODO: refactor method to more readable methods
		onTypeChanged(e) {
			// e === undefined means that this method was called not by user
			if (this.enums || !this.selectedType) {
				return;
			}
			this.children = [];

			const options = ResolveByType(this.selectedType.displayName);
			if (options.properties) {
				Object.keys(options.properties).forEach(key => {
					let defaultValue = '';

					if (key === '$parent') return;
					if (key === '$type') return;
					const child = Object.assign({ title: key }, options.properties[key]);
					if (child.$ref) {
						child.$ref = this.cleanRef(child.$ref);
						const childOptions = ResolveByType(child.$ref);
						if (childOptions.enum) {
							child.type = 'enum';
							child.enums = childOptions.enum;
							defaultValue = this.getDefaultEnum(key);
						}
						if (childOptions.oneOf) {
							child.type = 'panel';
							child.base = this.cleanRef(child.$ref);
							defaultValue = this.getDefaultPanel(child.base, key);
						}
					}
					if (child.type === 'array') {
						// TODO: need to rethink for array of primitive types. Consider arrayOfValues component that consitsts of collection of values
						child.base = this.cleanRef(child.items.$ref);
						defaultValue = this.getDefaultPanel(child.base, key, true);
					}
					// if user changed type - get 'defaultValue', otherwise - find if template[key] exists
					child.value = !e && this.template[key] !== void 0 ? this.template[key] : defaultValue;

					this.children.push(child);
				});
			}
			if (e) {
				// if user triggers type change we need to handle changes and emit 'onChange' event to parent
				const tmpl = {};
				tmpl.$type = adjustTypeToJson(this.selectedType.displayName);
				this.children.forEach(child => {
					tmpl[child.title] = child.value;
				});
				this.$emit('onChange', this.title, tmpl);
			}
		},
		onChange(index, key, value) {
			// update current template[key] with the current value and since this event came from child - update this element's children
			this.template[key] = value;
			this.children[index].value = value;
			// if it is root element - emit event to config-tool.vue, else - continue recursively emitting onChange until we reach root element
			if (this.title === 'root') {
				this.$emit('updateTemplate', this.template);
			} else {
				this.$emit('onChange', this.title, this.template);
			}
		},
		sortItems() {
			const itemOrder = ['number', 'string', 'enum', 'panel', 'array'];

			this.children.sort((a, b) => itemOrder.indexOf(a.type) - itemOrder.indexOf(b.type));
		},
		getLookupOptions() {
			return getLookupOptions(this.types, this.onTypeChanged);
		},
		onClick(event, value, id) {
			if (this.template.$id && id && id !== this.template.$id) {
				this.isClicked = false;
			} else {
				if (this.selectedType && this.selectedType.displayName.includes('Wrapper')) {
					this.$emit('clickChild', event, value, id);
				} else {
					this.isClicked = value;
					if (value) {
						this.specifiedElement = event && event.target;
						if (this.specifiedElement) {
							document.addEventListener('click', this.onDocumentClick);
						}
						this.isExpanded = true;
						this.$parent.$emit('expand', true);
						setTimeout(() => {
							// we need this check to prevent errors when user clicks LinkReference control and is redirected away from Config Tool
							this.$refs.panel && this.$refs.panel.scrollIntoView();
						}, 300); // this is related with animation "transition: max-width 0.3s";
					}
				}
			}
		},
		onExpanded(value) {
			// we need to expand the element and all parent elements of it
			this.isExpanded = value;
			this.$parent.$emit('expand', true);
		},
		onDocumentClick(event) {
			const isClickInside = this.specifiedElement.contains(event.target);
			if (!isClickInside && !event.target.classList.contains('highlighted')) {
				document.removeEventListener('click', this.onDocumentClick);
				this.onClick(event, false);
			}
		}
	}
};
</script>
<style src="./panel.less" scoped></style>
