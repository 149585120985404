import { BaseAspect } from './base.aspect';
import { Update } from '@acx-xms/data-functions/dist';

export class UpdateWithParameterAspect extends BaseAspect {
	constructor(options) {
		super();
		this.init(options);

		this.stage = 'afterSave';
		this.updateData = options.updateData;
	}

	init(options) {
		super.init(options, options.evaluationContext);
	}

	async execute(options) {
		const updateParameters = {};
		this.updateData.forEach(data => { updateParameters[super.eval(data.field)] = super.eval(data.value); });
		await Update(options.entityRef.logicalname, options.entityRef.id, updateParameters);
	}
}
