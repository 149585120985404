<template>
	<label class="checkbox-wrapper">
		<span class="checkbox bordered" v-bind:class="{ 'checked': value, 'disabled': disabled}">
			<svg class='svg-icon'>
				<use xlink:href='#layout-icons-ms-checkbox' class='layout-icons-ms-checkbox'></use>
			</svg>
		</span>
		<input type="checkbox" :checked="value" @change="$emit('input', $event.target.checked)" :disabled="disabled">
		<span v-if="label" class="p4">{{label}}</span>
	</label>
</template>
<script>
export default {
	props: {
		value: Boolean,
		label: String,
		disabled: {
			type: Boolean,
			default: false
		}
	}
};
</script>
<style src="./checkbox-base.less" scoped></style>
