var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "repotingservice4-templateslist" }, [
    _c("div", { staticClass: "filters" }, [
      _c("div", { staticClass: "back" }, [
        _c(
          "div",
          {
            staticClass:
              "control-button tooltip dialog-back-hover-parent clickable",
            on: { click: _vm.back }
          },
          [
            _c("svg", { staticClass: "svg-icon svg-12 back-button" }, [
              _c("use", {
                attrs: { "xlink:href": "#layout-icons-details-header-back" }
              })
            ])
          ]
        ),
        _vm._v(" "),
        _c("span", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "dialogues.RS4.changeTemplate" },
              expression: "{key: 'dialogues.RS4.changeTemplate'}"
            }
          ],
          staticClass: "caption"
        })
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "sections" },
        _vm._l(_vm.filters, function(filter, index) {
          return !filter.isMainSearchField && filter.visible
            ? _c("div", { key: index, staticClass: "section" }, [
                _c(
                  "div",
                  {
                    staticClass: "filter-header",
                    on: {
                      click: function($event) {
                        filter.isExpanded = !filter.isExpanded
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "caption" }, [
                      _vm._v(_vm._s(filter.sectionName))
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "icon" }, [
                      filter.isExpanded
                        ? _c(
                            "svg",
                            {
                              staticClass: "dropdown-arrow-open svg-icon svg-10"
                            },
                            [
                              _c("use", {
                                staticClass: "layout-icons-ms-arrow-up hover",
                                attrs: {
                                  "xlink:href": "#layout-icons-ms-arrow-up"
                                }
                              })
                            ]
                          )
                        : _c(
                            "svg",
                            { staticClass: "dropdown-arrow svg-icon svg-10" },
                            [
                              _c("use", {
                                staticClass: "layout-icons-ms-arrow-down hover",
                                attrs: {
                                  "xlink:href": "#layout-icons-ms-arrow-down"
                                }
                              })
                            ]
                          )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "ul",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: filter.isExpanded,
                        expression: "filter.isExpanded"
                      }
                    ],
                    staticClass: "filter-list"
                  },
                  _vm._l(filter.items, function(item, indexLi) {
                    return _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.visible,
                            expression: "item.visible"
                          }
                        ],
                        key: indexLi,
                        staticClass: "item",
                        class: { isChecked: filter.isSelected }
                      },
                      [
                        _c("label", [
                          _c("div", { staticClass: "item-checkbox" }, [
                            _c(
                              "div",
                              {
                                staticClass: "virtual-checkbox clickable",
                                class: { selected: item.isSelected }
                              },
                              [
                                _c("svg", { staticClass: "svg-icon" }, [
                                  _c("use", {
                                    staticClass: "layout-icons-ms-checkbox",
                                    attrs: {
                                      "xlink:href": "#layout-icons-ms-checkbox"
                                    }
                                  })
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: item.isSelected,
                                value: item.name
                              },
                              on: {
                                input: function($event) {
                                  item.isSelected = !item.isSelected
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "item-info" }, [
                            _c("div", { staticClass: "name" }, [
                              _c("span", { staticClass: "tooltip-wrapper" }, [
                                _c(
                                  "p",
                                  { attrs: { tooltip: item.displayName } },
                                  [_vm._v(_vm._s(item.displayName))]
                                )
                              ])
                            ])
                          ])
                        ])
                      ]
                    )
                  })
                )
              ])
            : _vm._e()
        })
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "templates" }, [
      _c("div", { staticClass: "search-area" }, [
        _c("div", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "dialogues.RS4.templates.templatesTitle" },
              expression: "{key: 'dialogues.RS4.templates.templatesTitle'}"
            }
          ],
          staticClass: "templates-title h5"
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "search-field-container" },
          [_c("search-field", { attrs: { options: _vm.searchFieldOptions } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "templates-list-wrapper" },
        [
          _vm.tableViewTemplate && _vm.templates.length && !_vm.isLoading
            ? _c("control", {
                key: _vm.templateKey,
                staticClass: "templates-list",
                attrs: {
                  name: _vm.tableViewTemplate.$type,
                  contentProps: _vm.tableViewTemplate,
                  initResults: _vm.templatesWithPagination,
                  onSelect: _vm.onSelect
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.templates.length && !_vm.isLoading
            ? _c("div", { staticClass: "no-results-message p13" }, [
                _c("div", {
                  directives: [
                    {
                      name: "localization",
                      rawName: "v-localization",
                      value: { key: "common.dictionary.noMatchesFound" },
                      expression: "{ key: 'common.dictionary.noMatchesFound' }"
                    }
                  ]
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.isLoading
            ? _c(
                "div",
                { staticClass: "footer" },
                [
                  _vm.paginationParams
                    ? _c("pagination", {
                        attrs: {
                          pageSize: _vm.paginationParams.pageSize,
                          total: _vm.paginationParams.total,
                          from: _vm.paginationParams.from,
                          showPaging: _vm.paginationParams.showPaging,
                          activePages: _vm.paginationParams.activePages,
                          showWrapperIfEmpty: true,
                          maxLength: _vm.paginationPages
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("button", {
                    directives: [
                      {
                        name: "localization",
                        rawName: "v-localization",
                        value: { key: "dialogues.RS4.dialog.manage" },
                        expression: "{key: 'dialogues.RS4.dialog.manage'}"
                      }
                    ],
                    staticClass: "secondary",
                    on: { click: _vm.openHtmlTemplates }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ],
      staticClass: "reportingservice-overlay loading-overlay large"
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }