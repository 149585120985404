<template>
	<div class="invite-participants-dialog">
		<div class="loading-overlay large" v-show="isLoading"></div>
		<div class="dialog-container" v-if="isViewReady">
			<div class="select-group" v-if="options.multiGroup">
				<span class="select-group-label" v-localization="{ key: 'inviteParticipantsPlugin.selectGroup' }"></span>
				<control-lookup-editor class="select-group-dropdown"
					v-model="selectedGroup"
					:evaluationContext="options.evaluationContext"
					:options="getGroupLookupOptions()"
					v-data-attr="{title: options.title, $type: 'dialog.input.search'}">
				</control-lookup-editor>
				<button class="cancel" v-localization="{ key: 'inviteParticipantsPlugin.addGroup' }" @click="createNewGroup" v-data-attr="{title: options.title, $type: 'dialog.submit.button'}"></button>
			</div>
			<div class="select-contacts">
				<div class="description">
					<h5 v-localization="{ key: 'inviteParticipantsPlugin.inviteTo', args: [entityName] }"></h5>
				</div>
				<div class="select-entity-wrapper">
					<div class="select-entity">
						<control-lookup :evaluationContext="options.evaluationContext" :options="getContactLookupOptions()" v-data-attr="{title: options.title, $type: 'dialog.input.search'}"></control-lookup>
					</div>
					<control-actionbutton :evaluationContext="options.evaluationContext" :options="actionBtnOptions" v-data-attr="{title: options.title, $type: 'dialog.submit.button'}"></control-actionbutton>
				</div>
			</div>
			<div class="entity-list">
				<control	class="formcontrol-template"
							v-if="userListTemplate"
							:name="userListTemplate.$type"
							:contentProps.once="userListTemplate"
							:evaluationContext="options.evaluationContext"
							:initResults="invited"></control>
			</div>
			<div class="info" v-if="!doNotShowAgainLocalStorage && !infoClosed">
				<div class="text-with-icon">
					<svg class='svg-icon'>
						<use xlink:href='#layout-icons-warning' class='layout-icons-warning'></use>
					</svg>
					<span class="p4">Co-owner participants of the room will have the same permissions as the room owner.</span>
				</div>
				<div class="do-not-show-again">
					<checkbox :value="doNotShowAgain" @input="doNotShowAgainOnChange" label="Do not display again"></checkbox>
					<svg class='svg-icon' @click="infoClosed = true">
						<use xlink:href='#layout-icons-dialog-close' class='layout-icons-dialog-close'></use>
					</svg>
				</div>
			</div>
			<div class="footer">
				<div class="dialog-buttons">
					<div class="group">
						<button @click="copyLink" class="cancel" type="button" v-localization="{key: 'inviteParticipantsPlugin.copyLink'}" v-data-attr="{title: options.title, $type: 'dialog.button.copyLink'}"></button>
						<button @click="emailLink(invited)" class="cancel" type="button" v-localization="{key: 'inviteParticipantsPlugin.emailLink'}" v-data-attr="{title: options.title, $type: 'dialog.button.emailLink'}"></button>
					</div>
					<div class="group">
						<button @click="cancel" class="cancel" type="button" v-localization="{key: 'common.dictionary.buttons.close'}" v-data-attr="{title: options.title, $type: 'dialog.button.close'}"></button>
						<button @click="save" type="button" v-localization="{key: 'inviteParticipantsPlugin.save'}" v-data-attr="{title: options.title, $type: 'dialog.button.submit'}"></button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import {
	getFullRecordLink, copyToClipboard, Get
} from '@acx-xms/data-functions/dist';
import ControlActionbutton from '@/Components/Control/control-actionbutton';
import Control from '@/Components/Entity/control';
import inviteParticipantsDataProvider from '@/Data/DataProviders/inviteParticipantsDataProvider';
import InviteParticipantsMixins from '@/Components/InviteParticipants/invite-participants.mixins';
import Checkbox from '@/Components/Control/Checkboxes/checkbox-base';
import { Get as GetItem, Set as SetItem } from '@/Data/DataProviders/userStorageDataProvider';
import { GetUserInfo } from '@/Data/Auth/authentication-service';
const ControlLookup = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Lookup/control-lookup');
const ControlLookupEditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Lookup/control-lookup-editor');

export default {
	name: 'invite-participants-dialog',
	components: {
		ControlLookup,
		Control,
		ControlLookupEditor,
		ControlActionbutton,
		Checkbox
	},
	props: { options: Object },
	mixins: [InviteParticipantsMixins],
	data() {
		return {
			isLoading: true,
			isViewReady: false,
			userListTemplate: null,
			userLookupTemplate: null,
			invited: [],
			entityName: '',
			chatLogicalName: 'chat',
			chatRelation: 'chatid.id',
			participantLogicalName: 'chatparticipant',
			participantRelation: 'contactid.id',
			parentRecord: sc.classes.get('entityReference', this.options.parentRecord),
			selectedGroup: this.options.selectedGroup && {
				displayName: (this.options.selectedGroup.name || this.options.selectedGroup.Name),
				data: this.options.selectedGroup
			},
			doNotShowAgain: false,
			doNotShowAgainLocalStorage: false,
			infoClosed: false
		};
	},
	async created() {
		this.fork = sc.events.fork();
		this.doNotShowAgainLocalStorage = await GetItem('doNotShowAgain');
		this.localizations = sc.classes.get('localization.dataProvider');
		this.dataProvider = sc.classes.get('edge.dataProvider');
		this.entityConfigDataProvider = sc.classes.get('entityConfiguration.dataProvider');
		this.fork.on('inviteParticipants.deleteParticipant', this.deleteParticipant);
		this.fork.on('inviteParticipants.addParticipant', this.addPredefineParticipant);
		if (this.options.invitedcontactid) {
			await this.addPredefineParticipant(this.options.invitedcontactid);
		}
		this.init().then(async () => {
			// find path to control.booleanEditor in template, check if user is owner or coowner, change enable condition by object reference
			// this was implemented because on invite participants screen => contact - we have evaluation context of the contact
			let path = 'content';
			const content = this.userListTemplate.content;
			for (let i = 0; i < content.length; i++) {
				let insidePath = '';
				if (Array.isArray(content[i].control)) {
					for (let j = 0; j < content[i].control.length; j++) {
						if (content[i].control[j].$type && content[i].control[j].$type === 'control.booleanEditor') {
							insidePath = `control.${j}`;
							break;
						}
					}
					if (insidePath.length) {
						path += `.${i}.${insidePath}`;
						break;
					}
				}
			}
			if (path !== 'content') {
				const { systemuserid } = await GetUserInfo();
				const enable = (this.options.evaluationContext.eval({
					$type: 'expression.field',
					name: 'chatparticipants',
					schema: 'source'
				}) || []).some(cp => { return (cp.systemuserid && cp.systemuserid.id === systemuserid) || cp.coowner === true; }) ||
						this.options.evaluationContext.eval({
							$type: 'expression.field',
							name: 'ownerid.id',
							schema: 'source'
						}) === systemuserid;
				sc.utils.findProperty(this.userListTemplate, path, true).enable = enable
					? { $type: 'expression.true' }
					: { $type: 'expression.false' };
			}
			const chat = await inviteParticipantsDataProvider.getChat(this.parentRecord, this.chatLogicalName);
			this.chatParticipants = (chat.Source.chatparticipants || []).filter(participant => !participant.recordstate || (participant.recordstate && participant.recordstate.id === '8d113fa8-3015-4060-a107-14cedcd19dd3'));
			this.isLoading = false;
			this.isViewReady = true;
		});
		this.actionBtnOptions = {
			$type: 'control.actionButton',
			caption: {
				$type: 'expression.text',
				value: 'Quick Create New Contact'
			},
			action: {
				$type: 'action.vue.editor',
				mode: 'quick',
				logicalname: {
					$type: 'expression.text',
					value: 'contact'
				},
				subtype: {
					$type: 'expression.text',
					value: 'quick-edit'
				},
				source: 'noSelection'
			}
		};
		this.$root.$on('coowner.changed', this.onCoownerChanged);
	},
	beforeDestroy() {
		this.fork.dispose();
		this.$root.$off('coowner.changed', this.onCoownerChanged);
	},
	methods: {
		async addPredefineParticipant(invitedcontactid) {
			const invitedcontact = await Get('contact', invitedcontactid);
			this.invited.push(invitedcontact);
		},
		async init() {
			this.userListTemplate = await this.entityConfigDataProvider
				.getTemplate(this.options.userEntity, this.options.userListTemplate.usage, this.options.userListTemplate.subtype);
			this.userListTemplate = this.userListTemplate && this.userListTemplate.content;
			this.userLookupTemplate = await this.entityConfigDataProvider
				.getTemplate(this.options.userEntity, this.options.userLookupTemplate.usage, this.options.userLookupTemplate.subtype);
			this.entityName = await this.getEntityDisplayName(this.parentRecord.logicalname);
		},
		getContactLookupOptions() {
			return {
				placeholder: 'Search for a Contact to add',
				fetchData: async (searchQuery, startFrom, pageSize) => {
					startFrom = startFrom || 0;
					pageSize = pageSize || 1000;
					let filters = [
						sc.classes.get('offsetFrom.filter', startFrom),
						sc.classes.get('offsetSize.filter', pageSize)
					];
					const entityConfigurations = await sc.classes.get('entityConfiguration.dataProvider').fetchEntity(this.options.userEntity);
					if (entityConfigurations.selectedFieldsSettings) {
						const selectedFieldsSection = entityConfigurations.selectedFieldsSettings.section.find(item => item.type === 'lookup');
						if (selectedFieldsSection) {
							const selectedFields = selectedFieldsSection.field.map(field => ({ logicalname: field }));
							filters.push(sc.classes.get('selectedFields.filter', [...selectedFields, { logicalname: 'contactid' }]));
						}
					}

					if (this.options.lookupFilters && this.options.lookupFilters.length) {
						filters = filters.concat(this.options.lookupFilters.map((filter) => {
							return sc.classes.get(filter.$type, filter, this.options.evaluationContext).toFilter();
						}));
					}
					if (searchQuery) {
						filters.push(sc.classes.get('field.filter', {
							logicalName: '_all',
							query: searchQuery
						}));
					}
					if (this.chatParticipants.length) {
						const query = this.chatParticipants.map(participant => participant.contactid.id);
						filters.push(sc.classes.get('termFacet.filter', {
							logicalName: 'contactid',
							query,
							negative: true
						}).fillQuery());
					}
					return this.dataProvider.search({
						filters,
						entities: [this.options.userEntity]
					}).then(({ Results, Total }) => {
						return {
							total: Total,
							items: Results.map(res => {
								return {
									displayName: res.Name,
									data: res
								};
							})
						};
					});
				},
				singleSelectionOnArray: this.selectEntity,
				template: this.userLookupTemplate
			};
		},
		getGroupLookupOptions() {
			return {
				isRequired: true,
				placeholder: '',
				fetchData: searchQuery => {
					const filters = [
						sc.classes.get('offsetSize.filter', 1000)
					];
					if (searchQuery) {
						filters.push(sc.classes.get('field.filter', {
							logicalName: '_all',
							query: searchQuery
						}));
					}
					return inviteParticipantsDataProvider.relatedChats(this.parentRecord, this.chatLogicalName).then((results) => {
						return {
							items: results.map(res => {
								return {
									displayName: res.Name,
									data: res
								};
							})
						};
					});
				}
			};
		},
		selectEntity(item) {
			if (item) {
				if (!this.invited.find(user => user.Id === item.data.Id)) {
					this.invited.push(item.data);
				}
			}
		},
		deleteParticipant(entity) {
			this.invited = this.invited.filter(item => item.Id !== entity.id);
		},
		async copyLink() {
			copyToClipboard(getFullRecordLink(this.$route, this.parentRecord));
			this.$root.$emit('toast.open', await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('common.dictionary.singleLinkToastText'));
		},
		async save() {
			this.isLoading = true;
			if (!this.invited.length) {
				sc.events.emit('dialog.open', {
					title: await this.localizations.getLabelForCurrentLanguage('common.dictionary.warning'),
					allowMinimize: false,
					allowMaximize: false,
					allowPin: false,
					allowClose: false,
					component: 'dialog.info',
					params: {
						message: await this.localizations.getLabelForCurrentLanguage('inviteParticipantsPlugin.noContactsWarning'),
						buttons: [
							{ title: 'OK' }
						]
					}
				});
				this.isLoading = false;
				return;
			}
			let chatEntity;

			if (this.options.multiGroup) {
				if (this.selectedGroup && this.selectedGroup.data) {
					chatEntity = this.selectedGroup.data;
				}
				if (!chatEntity) {
					sc.events.emit('dialog.error',
						{
							title: await this.localizations.getLabelForCurrentLanguage('inviteParticipantsPlugin.error'),
							message: await this.localizations.getLabelForCurrentLanguage('inviteParticipantsPlugin.drGroupRequired')
						});
					this.isLoading = false;
					return;
				}
			} else {
				chatEntity = await inviteParticipantsDataProvider.getChat(this.parentRecord, this.chatLogicalName);
			}
			const results = await inviteParticipantsDataProvider.saveContacts(chatEntity, this.invited, this.participantLogicalName, this.chatRelation, this.participantRelation);
			const invitedContacts = [];
			for (const [i, result] of results.entries()) {
				if (result.status === 'error') {
					sc.events.emit('dialog.error',
						{
							title: await this.localizations.getLabelForCurrentLanguage('inviteParticipantsPlugin.error'),
							message: result.data.responseText + ' ' + this.invited[i].Name
						});
				} else {
					invitedContacts.push(result.data);
				}
			}
			if (invitedContacts.length) {
				const invitedContactsIds = invitedContacts.map(contact => contact.contactid.id);
				const invited = this.invited.filter(contact => invitedContactsIds.indexOf(contact.Source.contactid) !== -1);

				const onButtonClick = () => {
					this.isLoading = false;
					this.close();
				};

				this.options.refreshNamespace && sc.events.emit('refreshSearch', this.options.refreshNamespace);
				this.openEmailInvitationDialog(chatEntity, invitedContacts, invited, onButtonClick);
			} else {
				this.options.refreshNamespace && sc.events.emit('refreshSearch', this.options.refreshNamespace);
				this.isLoading = false;
				this.close();
			}
		},
		cancel() {
			this.close();
		},
		close() {
			this.$parent.$emit('close');
		},
		createNewGroup() {
			sc.events.emit('dialog.info', {
				title: 'Feature is coming soon',
				message: 'Multiple Groups per 1 Deal Room feature is coming soon'
			});
		},
		onCoownerChanged({ value, id }) {
			const index = this.invited.findIndex(invited => invited.Id === id);
			this.invited[index].additionalParams = { coowner: value };
		},
		doNotShowAgainOnChange(value) {
			this.doNotShowAgain = value;
			const debouncedSet = _.debounce(() => SetItem('doNotShowAgain', this.doNotShowAgain), 1000);
			debouncedSet();
		}
	}
};
</script>
<style src="./invite-participants-dialog.less" scoped></style>
