import ActionMenu from '@/Components/Menu/action-menu';
import DetailsColumnControl from '@/Components/ComponentSet/Details/details-column-control';
import DetailsRowControl from '@/Components/ComponentSet/Details/details-row-control';
import DetailsColumnControlNamespaced from '@/Components/ComponentSet/Details/details-column-control-namespaced';
import helperMethods from '@/Components/ComponentSet/component-set-evaluation-context-helper.methods';
import Breadcrumbs from '@/Components/ComponentSet/breadcrumbs/breadcrumbs';
import { getBaseRouteUrl } from '@acx-xms/data-functions/dist';
import MiddlewareChatparticipant from '@/Data/Middlewares/MiddlewareChatparticipant';
import MiddlewareCheckNDA from '@/Data/Middlewares/MiddlewareCheckNDA';
import * as tracking from '@/Data/trackingModule';

export default {
	components: {
		DetailsColumnControl,
		DetailsColumnControlNamespaced,
		ActionMenu,
		DetailsRowControl,
		Breadcrumbs
	},
	props: {
		scrollNamespace: Object,
		template: Object,
		defaultRoute: Object,
		trackActivity: Boolean,
		accessByLink: {
			type: Boolean,
			default: true
		},
		redirectRouteIfNotExist: String
	},
	data() {
		return {
			evaluationContext: null,
			actionMenuComponent: null,
			isLoading: true,
			data: null,
			error: false,
			breadcrumbs: null
		};
	},
	created() {
		this.classesMapping = {
			Middlewarechatparticipant: MiddlewareChatparticipant,
			MiddlewarecheckNDA: MiddlewareCheckNDA
		};
	},
	methods: {
		onScroll() {
			this.$root.$emit(this.detailsScroll + '.scrolled');
		},
		async _applyMiddlewares(middlewares) {
			const promises = [];
			for await (const middleware of middlewares) {
				const name = helperMethods.eval(this.evaluationContext, middleware.name);

				let options = [];

				if (middleware.options && middleware.options.option.length) {
					options = middleware.options.option.reduce((res, o) => {
						res[helperMethods.eval(this.evaluationContext, o.name)] = helperMethods.eval(this.evaluationContext, o.value);
						return res;
					}, {});
				}
				const middle = new this.classesMapping['Middleware' + name](options, this);
				await new Promise(resolve => setTimeout(resolve, 500));
				promises.push(middle.execute());
			}

			return Promise.all(promises).catch(() => { this.isLoading = false; });
		},

		syncBreadcrumbs(item, breadcrumbs) {
			if (!this.defaultRoute) {
				this.breadcrumbs = null;
				return;
			}
			const breadcrumbsKey = 'routes.breadcrumbs';
			if (breadcrumbs) {
				sessionStorage.setItem(breadcrumbsKey, JSON.stringify(breadcrumbs));
				return;
			}
			breadcrumbs = JSON.parse(sessionStorage.getItem(breadcrumbsKey));
			if (item) {
				const routeBase = getBaseRouteUrl(this.$route);

				breadcrumbs = breadcrumbs && breadcrumbs.length > 0
					? breadcrumbs
					: [{
						id: null,
						title: this.defaultRoute.displayName,
						route: { path: `${routeBase}/${this.defaultRoute.path}` }
					}];
				const currentBreadCrumb = breadcrumbs[breadcrumbs.length - 1];
				if (currentBreadCrumb.id !== item.id) {
					item.route = { path: `${routeBase}/${item.logicalName}/${item.id}` };
					breadcrumbs.push(item);
					sessionStorage.setItem(breadcrumbsKey, JSON.stringify(breadcrumbs));
				} else if (currentBreadCrumb.id === item.id && currentBreadCrumb.title !== item.title) {
					item.route = currentBreadCrumb.route;
					breadcrumbs.splice(breadcrumbs.length - 1, 1);
					breadcrumbs.push(item);
					sessionStorage.setItem(breadcrumbsKey, JSON.stringify(breadcrumbs));
				}
			}
			this.breadcrumbs = breadcrumbs;
		},

		async trackUserActivity(reference) {
			if (!this.trackActivity) {
				return;
			}

			const { displayName } = await sc.classes.get('entityConfiguration.dataProvider').fetchEntity(reference.logicalname);
			tracking.AddUserActivity('details.opened.' + this.logicalName, await helperMethods.evalAsync(this.evaluationContext, displayName) + ' details opened', reference);
		}
	}

};
