<template>
	<div class="deactivate-dialog">
		<div class="body">
			<span class="p4">{{localizedMessage}}</span>
		</div>
		<div class="entity-list">
			<control class="formcontrol-template"
					 v-if="documentRelatedRecordsTemplate"
					 :name="documentRelatedRecordsTemplate.$type"
					 :contentProps="documentRelatedRecordsTemplate"
					 :initResults="results"></control>
		</div>
		<div class="footer">
			<button class="cancel" @click="onCancel" v-localization="{ key: 'common.dictionary.buttons.cancel'}" v-data-attr="{title, $type: 'dialog.button.submit'}"></button>
			<button type="button" @click="onSubmit" v-data-attr="{title , $type: 'dialog.button.cancel'}">{{localizedOkBtn}}</button>
		</div>
		<div class="loading-overlay" v-show="isLoading"></div>
	</div>
</template>
<script>
import Control from '@/Components/Entity/control.vue';
import {
	partition, showToastOrInfoDialog, CreateBulk, Get
} from '@acx-xms/data-functions/dist';
import {
	processResults, searchDocumentRelatedRecords, searchChilrenDocuments
} from '@/Components/Dialogues/DocumentFolderAddToDialog/docsRelationUtils';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';

export default {
	name: 'deactivate-dialog',
	components: { Control },
	props: { options: Object },
	data() {
		return {
			isLoading: false,
			documentRelatedRecordsTemplate: null,
			relateFromRecord: this.options.params.relateFromRecord || null,
			results: [],
			filteredResults: [],
			localizedMessage: '',
			title: '',
			localizedOkBtn: ''
		};
	},
	async created() {
		this.isLoading = true;
		this.localizationProvider = sc.classes.get('localization.dataProvider');
		this.entities = this.options.params.entities;
		this.isDeactivation = this.options.params.isDeactivation;
		this.title = await this.localizationProvider.getLabel('en', this.options.localizedTitle);
		const parameters = this.options.params.parameters;

		this.acceptCallback = this.options.params.acceptCallback;
		this.warnMessageKey = parameters && parameters.warnMessageKey
			? parameters.warnMessageKey
			: (this.isDeactivation ? 'common.dictionary.confirmDeactivation' : 'common.dictionary.confirmReactivation');
		this.okButtonMessageKey = (parameters && parameters.okButtonMessageKey) || 'common.dictionary.yes';
		// TODO: revisit. troubles w/ localization directive
		const currentLang = await this.localizationProvider.getLabelForCurrentLanguage();
		this.localizedMessage = await this.localizationProvider.getLabel(currentLang, this.warnMessageKey);
		this.localizedOkBtn = await this.localizationProvider.getLabel(currentLang, this.okButtonMessageKey);
		this.withSuccessMessage = (parameters && parameters.withSuccessMessage) === void 0 ? true : parameters.withSuccessMessage;
		this.fieldsToCheck = this.options.params.fieldsToCheck;
		this.refreshEvent = this.options.evaluationContext.eval(this.options.params.refreshEvent);
		this.entityRefs = this.entities.map(item => {
			return sc.classes.get('entityReference', item);
		});
		this.configDataProvider = sc.classes.get('entityConfiguration.dataProvider');
		const entity = await this.configDataProvider.fetchEntity(this.entityRefs[0].logicalname);
		const closeCompleteState = this.options.evaluationContext.eval(entity.closeCompleteStatuses3.inactiveStateCode.value);
		const activeCompleteState = this.options.evaluationContext.eval(entity.activeCompleteStatuses.activeStateCode.value);
		this.completeState = this.isDeactivation ? closeCompleteState : activeCompleteState;
		this.documentRelatedRecordsTemplate = this.options.documentRelatedRecordsTemplate && await this.configDataProvider
			.getTemplate(
				this.options.documentRelatedRecordsTemplate.entity,
				this.options.documentRelatedRecordsTemplate.usage,
				this.options.documentRelatedRecordsTemplate.subtype
			);
		if (this.documentRelatedRecordsTemplate && this.documentRelatedRecordsTemplate.name === 'sharedDoc-documentAddingRelatedRecords' && this.options.params.isFolder === true) {
			this.logicalName = 'folder';
			this.relateFromRef = sc.classes.get('entityReference', this.relateFromRecord);
			this.childrenDocuments = await searchChilrenDocuments(this.relateFromRef.id) || [];
			if (this.childrenDocuments && this.childrenDocuments.length) {
				const promises = this.childrenDocuments.map(document => {
					return searchDocumentRelatedRecords([document.id], this.options);
				});
				const responses = await Promise.all(promises);
				responses && responses.forEach(response => response.forEach(data => this.filteredResults.push(data.Source)));
			}
			this.results = processResults(this.filteredResults);
		} else if ((this.documentRelatedRecordsTemplate &&
						this.documentRelatedRecordsTemplate.name === 'sharedDoc-documentRelatedRecords') || ('sharedDoc-documentAddingRelatedRecords' &&
						this.options.params.isFolder === false)) {
			this.logicalName = 'document';
			const query = this.entityRefs.map(entity => entity.id);
			this.results = await searchDocumentRelatedRecords(query, this.options) || [];
		}

		this.isLoading = false;
	},
	methods: {
		async onSubmit() {
			this.isLoading = true;
			let recordsToDeactivate = [];
			let recordsFailedToDeactivate = [];
			let errors = [];
			if (this.fieldsToCheck && this.fieldsToCheck.length) {
				let failedToDeactivateIdsArr = [];

				this.fieldsToCheck.forEach(field => {
					const fieldVal = this.options.evaluationContext.eval(field.fieldValue);
					failedToDeactivateIdsArr = failedToDeactivateIdsArr.concat(this.filteredResults.filter(result => result[field.schema][field.fieldName] === fieldVal).map(result => result.Source.documentid.id));
				});
				failedToDeactivateIdsArr = [...new Set(failedToDeactivateIdsArr)];
				[recordsToDeactivate, recordsFailedToDeactivate] = partition(this.entities, e => !failedToDeactivateIdsArr.includes(e.Id || e.id)); // hardcode for inline action selection
			} else {
				recordsToDeactivate = this.entities;
			}

			if (recordsToDeactivate.length) {
				const records = [];
				const foldersIds = [];
				recordsToDeactivate = recordsToDeactivate.filter(item => {
					const entityReference = sc.classes.get('entityReference', item);
					const coxtext = createEvaluationContext(item);
					const isFolder = this.options.params.isFolder && coxtext.eval(this.options.params.isFolder);
					if (isFolder) {
						foldersIds.push({
							id: item.Id,
							entity: item.Type
						});
					} else {
						const record = {
							id: sc.utils.guid(),
							method: 'PUT',
							url: `${entityReference.logicalname}/${entityReference.id}/put`,
							body: {
								type: entityReference.logicalname,
								recordstate: {
									id: this.completeState.Id,
									logicalname: this.completeState.Type
								}
							}
						};
						records.push(record);
					}
					return !isFolder;
				});
				if (foldersIds.length) {
					this.$root.$emit(this.options.params.parameters.stateNamespace + '.setRecordsToDeactivate', foldersIds);
				}
				if (records.length) {
					const { Responses } = await CreateBulk(records, { async: true });
					errors = Responses.filter(response => { return !(response.Status === 200 || response.Status === 204); });
					const textKey = this.isDeactivation ? 'deactivated' : 'reactivated';
					if (errors.length) {
						const message = await this.localizationProvider.getLabelForCurrentLanguage(`actions.entity.deactivate.${textKey}ItemsFailedTo`, [errors.length, recordsToDeactivate.length]);
						const detailMessage = errors.map(error => error.Body).join('\r\n');
						sc.utils.errorMessage.byMessage(message, detailMessage);
					} else {
						this.acceptCallback && this.acceptCallback();
						if (this.withSuccessMessage) {
							const message = recordsToDeactivate.length === 1
								? await this.localizationProvider.getLabelForCurrentLanguage(`actions.entity.deactivate.${textKey}CompletedOne`, [1])
								: await this.localizationProvider.getLabelForCurrentLanguage(`actions.entity.deactivate.${textKey}CompletedMoreThanOne`, [recordsToDeactivate.length]);
							this.$root.$emit('toast.open', message, 3000);
						}
					}
				}
			}
			if (recordsFailedToDeactivate.length) {
				// todo: remove after selection for inline action is changed
				if (recordsFailedToDeactivate[0].id && recordsFailedToDeactivate[0].logicalname) { // this means that record came from inline action
					recordsFailedToDeactivate = [await Get(recordsFailedToDeactivate[0].logicalname, recordsFailedToDeactivate[0].id)]; // recieve record from edge by entity ref, remove when selection is reworked
				}
				const template = this.options.restrictedToDeactivateRecordsListTemplate && await this.configDataProvider
					.getTemplate(this.options.restrictedToDeactivateRecordsListTemplate.entity, this.options.restrictedToDeactivateRecordsListTemplate.usage, this.options.restrictedToDeactivateRecordsListTemplate.subtype);
				sc.events.emit('vue.dialog.info', {
					title: await this.localizationProvider.getLabelForCurrentLanguage('dialogs.restriction'),
					message: await this.localizationProvider.getLabelForCurrentLanguage('dialogs.deactivation.error'),
					template,
					records: recordsFailedToDeactivate
				});
			}
			if (recordsToDeactivate.length && errors.length !== recordsToDeactivate.length) {
				if (this.refreshEvent) {
					this.$root.$emit(this.refreshEvent);
				} else {
					this.$root.$emit('entity.changed', {
						entity: { logicalname: this.entityRefs[0].logicalname },
						isReactivation: !this.isDeactivation
					});
					if (this.options.params.parameters && this.options.params.parameters.refreshNamespace) {
						this.$root.$emit(this.options.params.parameters.refreshNamespace + '.searching');
						sc.events.emit(this.options.params.parameters.refreshNamespace + '.searching');
					}
				}
			}
			await showToastOrInfoDialog({
				toastMessageKey: this.options.params.toastMessageKey,
				informationDialogTextKey: this.options.params.informationDialogTextKey,
				options: { context: this }
			});
			this.close();
		},
		onCancel() {
			this.close();
		},
		close() {
			this.$parent.$emit('close');
		}
	}
};
</script>
<style src="./deactivate-dialog.less" scoped></style>
