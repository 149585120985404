<template>
	<div>
		<div class="flex-wrapper">
			<ControlLookup
				:options="{
					...lookupOptions,
					query: query
				}"
				:cssClasses="cssClasses"
				:isDisabled="buttonOptions.isDisabled"
				@input="query = $event"
			/>
			<button
				v-if="buttonOptions"
				@click="onSubmit"
				:disabled="buttonOptions.isDisabled"
			>
				{{ buttonOptions.label }}
			</button>
		</div>
		<div
			v-if="validationOptions && !validationOptions.isValid"
			class="error-wrapper"
		>
			<svg class="svg-icon">
				<use :xlink:href="'#ui-icon-exclamation-mark'"></use>
			</svg>
			<span>{{ validationOptions.message }}</span>
		</div>
	</div>
</template>
<script>
import ControlLookup from '@/Components/Control/Lookup/control-lookup';
export default {
	name: 'control-lookup-wrapper',
	components: { ControlLookup },
	props: {
		lookupOptions: {
			type: Object,
			default: () => ({})
		},
		buttonOptions: {
			type: Object,
			default: () => ({})
		},
		validationOptions: {
			type: Object,
			default: () => ({})
		},
		cssClasses: {
			type: Object,
			default: () => ({})
		}
	},
	data: () => ({ query: '' }),
	methods: {
		onSubmit() {
			const { searchQuery, searchResult } = this.$children.find(
				(child) => child.$options.name === 'control-lookup'
			);
			this.buttonOptions.onSubmit(searchQuery, searchResult);
			if (this.validationOptions && this.validationOptions.isValid) this.query = '';
		}
	}
};
</script>
<style scoped lang='less'>
	.flex-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;

		> button {
			background: none;
			border: 1px solid var(--theme-color);
			color: var(--theme-color);
			margin-left: 15px;

			&:disabled {
				border: 1px solid #858d9d;
				color: #6b7078;
				opacity: 0.5;
			}
		}
	}

	.error-wrapper {
		height: 15px;
		width: fit-content;
		display: flex;
		align-items: center;

		> span {
			color: var(--color23);
			font-size: 11px;
			margin-left: 3px;
		}

		> .svg-icon {
			width: 11px;
			height: 11px;
		}
	}
</style>
