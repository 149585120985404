<template>
	<div>
		<div v-if="accessDeniedMsg">{{ accessDeniedMsg }}</div>
		<template v-else>
			<div v-if="requestedAccess">
				<div v-localization="{key: 'common.dictionary.requestSentMessage'}"></div>
			</div>
			<div v-else :class="requestedAccess === void 0 && 'text'">
				<div v-localization="{key: 'common.dictionary.recordDetails.noAccess'}"></div>
				<div v-if="requestedAccess === void 0 && showRequestAccess" class="no-access">
					<div v-localization="{key: 'common.dictionary.recordDetails.requestAccess'}"></div>
					<div class="buttons pt10">
						<button class="cancel mr20" type="button" @click="cancel" v-localization="{key: 'common.dictionary.buttons.cancel'}"></button>
						<button type="button" @click="requestAccess" v-localization="{key: 'common.dictionary.buttons.request'}"></button>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>
<script>
import {
	getFullRecordLink, emailLink, getBaseRouteUrl, Create, Search
} from '@acx-xms/data-functions/dist';
import { GetUserInfo } from '@/Data/Auth/authentication-service';

export default {
	name: 'details-no-access',
	props: {
		options: Object,
		requestedAccess: Boolean
	},
	data() {
		return {
			accessDeniedMsg: '',
			isRecordExist: true,
			showRequestAccess: true,
			redirectRouteIfNotExist: ''
		};
	},
	async created() {
		if (this.options) {
			this.localizationDataProvider = sc.classes.get('localization.dataProvider');
			this.accessDeniedMsg = this.options.roomOwnerName
				? await this.localizationDataProvider.getLabelForCurrentLanguage('common.dictionary.accessDeniedByOwner', [this.options.roomOwnerName])
				: '';
			this.brokerConfig = this.options.brokerConfig;
			this.isRecordExist = this.options.isRecordExist;
			this.redirectRouteIfNotExist = this.options.redirectRouteIfNotExist;
			this.showRequestAccess = this.options.showRequestAccess && !this.options.participantExist;

			if (!this.isRecordExist && this.redirectRouteIfNotExist) {
				window.location = `${getBaseRouteUrl(this.$route)}/${this.redirectRouteIfNotExist}`;
			}
		}
	},
	methods: {
		cancel() {
			let searchResultsName = 'deal-rooms';
			if (this.options.logicalName === 'collaborationroom') {
				searchResultsName = 'collaboration-rooms';
			}
			this.$router.push({ path: `${getBaseRouteUrl(this.$route)}/${searchResultsName}` });
		},
		async emailLink() {
			const getField = (path) => {
				return sc.utils.findProperty(this.rod, path, true);
			};
			const roomOwnerEmail = getField('Source.roomopendataroomowneridsystemuser.email');
			const roomName = getField('Source.roomname');
			const cr = getField('Source.roomopendataroomidcollaborationroom');
			const dr = getField('Source.roomopendataroomiddealroom');
			const roomId = (dr && dr.drnumber) || (cr && cr.crnumber);
			// need for guest config to redirect in broker config
			let linkToRecord;
			if (this.brokerConfig) {
				const formattedName = roomName.split('').map(char => {
					if (char === ' ') {
						char = '-';
					}

					return char;
				}).join('');
				linkToRecord = `${window.location.origin}/${this.$route.params.clusterKey}/${this.brokerConfig}/${this.$route.params.layout}/${this.options.logicalName}/${encodeURIComponent(formattedName)}/${this.options.id}`;
			} else {
				linkToRecord = getFullRecordLink(this.$route, {
					id: this.options.id,
					logicalname: this.options.logicalName,
					name: roomName
				});
			}
			const emailBody = await this.localizationDataProvider.getLabelForCurrentLanguage('requestAccessToRoom.emailBody', [this.userInfo.fullname, roomName, roomId, linkToRecord]);
			const subj = `Access request from ${this.userInfo.fullname}`;
			const url = emailLink(subj, encodeURIComponent(emailBody), [roomOwnerEmail]);
			window.open(url);
		},
		async requestAccess() {
			this.$parent.$emit('onValueChange', 'isLoading', true);
			this.userInfo = await GetUserInfo();
			let [contact, rod] = await Promise.all([
				Search(['contact'],
					[
						sc.classes.get('offsetSize.filter', 1),
						sc.classes.get('termFacet.filter', {
							logicalName: 'systemuserid.id',
							query: [this.userInfo.Id]
						}).fillQuery(),
						sc.classes.get('selectedFields.filter', []).fillQuery()
					]
				),
				Search(['roomopendata'],
					[
						sc.classes.get('offsetFrom.filter', 0),
						sc.classes.get('offsetSize.filter', 1),
						sc.classes.get('termFacet.filter', {
							logicalName: 'roomid.id',
							query: [this.options.id]
						}).fillQuery(),
						sc.classes.get('selectedFields.filter', [
							{ logicalname: 'roomopendataroomowneridsystemuser.email' },
							{ logicalname: 'roomname' },
							{ logicalname: 'roomopendataroomidcollaborationroom.crnumber' },
							{ logicalname: 'roomopendataroomidcollaborationroom.recordstate.id' },
							{ logicalname: 'roomopendataroomiddealroom.drnumber' },
							{ logicalname: 'chatid' }
						]).fillQuery()
					]
				)
			]);

			contact = contact.Results.pop();
			this.rod = rod.Results.pop();
			this.showRequestAccess = !!this.rod;

			if (!this.showRequestAccess) {
				this.$parent.$emit('onValueChange', 'showRequestAccess', false);
				this.$parent.$emit('onValueChange', 'isLoading', false);
				return;
			}

			if (!this.rod.Source.chatid) {
				sc.utils.errorMessage.byMessage('Chatid field is empty. Please contact your system administrator.');
				return;
			}
			await Create('chatparticipant', {
				roomopendataid: {
					id: this.rod.Id,
					logicalname: this.rod.Type
				}, // we fill this field only for users that requested access
				chatid: this.rod.Source.chatid,
				contactid: {
					id: contact.Id,
					logicalname: 'contact'
				},
				ispending: true,
				requestedaccess: true,
				systemuserid: {
					id: this.userInfo.Id,
					logicalname: 'systemuser'
				}
			});
			this.$parent.$emit('onValueChange', 'requestedAccess', true);
			this.$parent.$emit('onValueChange', 'isLoading', false);
			this.emailLink();
			await Create('chatmessage', {
				roomopendataid: {
					id: this.rod.Id,
					logicalname: this.rod.Type
				},
				chatid: this.rod.Source.chatid,
				type: 'chatmessage',
				messagetext: 'has requested access to this room',
				messagetype: 'Action',
				visibleto: 'All Users',
				contactname: contact.Name,
				createdonfromui: new Date()
			});
		}
	}
};
</script>
<style src="./details-no-access.less" scoped></style>
