var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "image-fullscreen-preview-wrapper",
      on: {
        click: function($event) {
          if ($event.target !== $event.currentTarget) {
            return null
          }
          return _vm.close($event)
        }
      }
    },
    [
      _c("div", { staticClass: "close-wrapper", on: { click: _vm.close } }, [
        _c("svg", { staticClass: "svg-icon" }, [
          _c("use", { attrs: { "xlink:href": "#layout-icons-dialog-close" } })
        ])
      ]),
      _vm._v(" "),
      _c("image-wrapper", {
        staticClass: "image-wrapper",
        attrs: { url: _vm.options.url }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }