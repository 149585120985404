<template>
	<div class="control-dateeditor">
		<DatePicker
			v-model="date"
			v-data-attr="dataAttr || {}"
			:enable="enable"
			:show-required-mark="showRequiredMark"
			:valid="isValid"
			:has-today-action="options.showTodayAction"
		/>
	</div>
</template>
<script>
/* eslint vue/no-unused-components: 0 */
import ControlMixins from '@/Components/Editor/Controls/control.mixins';
const DatePicker = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Common/date-picker');
const ControlSvgIcon = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Images/control-svg-icon');

export default {
	name: 'control-dateeditor',
	mixins: [ControlMixins],
	components: {
		ControlSvgIcon,
		DatePicker
	},
	computed: {
		date: {
			get() {
				return Date.parse(this.value);
			},
			set(newVal) {
				this.value = newVal;
				return newVal;
			}
		}
	}
};
</script>
<style src="./control-dateeditor.less" scoped></style>
