<template>
	<div class="dialog" v-bind:class="classes" @focusin="$emit('set-active', id)" v-bind:style="{ right: offsetX }">
		<div class="dialog-wrapper">
			<div class="dialog-window" v-bind:class="{'active': active}" :style="{'max-width':dialogWidth, 'height':dialogHeight}">
				<dialog-header :dialogOptions="dialogOptionsForHeader()"></dialog-header>
				<div ref="id" class="dialog-content" v-kocomponent="dialogContentComponent()">
				</div>
			</div>
		</div>
	</div>

</template>
<script>
/* eslint no-unused-vars: 0 */
/* eslint vue/no-unused-components: 0 */
import DialogHeader from './dialog-header';
import KOComponent from '../../Bindings/kocomponent.directive';
import { firstDefined } from '@acx-xms/data-functions/dist';

export default {
	// This component hosts dialog header and content. It has been decomposed from dialog-manager with aim to have Vue reactive state for each dialog
	name: 'old-dialog-wrapper',
	props: { initDialog: Object },
	components: { DialogHeader },
	data() {
		return {
			id: this.initDialog.id,
			dialog: this.initDialog.vm,
			classes: this.initDialog.vm.dialogClasses(),
			active: this.initDialog.vm.active(),
			offsetX: this.initDialog.vm.offsetX()
		};
	},
	computed: {
		dialogWidth() {
			const maximizedWidth = this.initDialog.maximizedWidth || '450px';
			return !sc.utils.userAgent.userAgent.isMobile ? maximizedWidth : '100%';
		},
		dialogHeight() {
			const maximizedHeight = (this.initDialog.maximizedHeight && `calc(${this.initDialog.maximizedHeight} - 40px)`) || 'auto';
			return !sc.utils.userAgent.userAgent.isMobile ? maximizedHeight : '100%';
		}
	},
	methods: {
		dialogContentComponent: function () {
			const component = this.dialog.component();
			// Mock binding context with $dialog property (instead of controlFor ko binding logic)
			const bindingContextMock = { $dialog: this.dialog };
			if (component.propertyBag) {
				bindingContextMock.$propertyBag = {
					...component.propertyBag,
					...bindingContextMock.$propertyBag
				};
			}
			return {
				...{ $data: [component, void 0, bindingContextMock] },
				...component
			};
		},
		dialogOptionsForHeader: function () {
			return {
				id: this.id,
				title: this.dialog.title,
				localizedTitle: this.dialog.options.localizedTitle,
				icon: this.dialog.icon,
				onClose: this.dialog.close,
				status: this.dialog.status,
				allowClose: this.dialog.allowClose
			};
		},
		calcDim(el, calcCallback) {
			if (sc.utils.userAgent.userAgent.isMobile) {
				return '100%';
			}
			return calcCallback(el);
		}
	},
	created() {
		this.fork = sc.events.fork();
		this.classes = this.dialog.dialogClasses();

		this._setDialogSize = (el, dialog) => {
			el.style.height = this.calcDim(el, dialog.calcHeight);
			el.style.width = this.calcDim(el, dialog.calcWidth);
		};

		this._onDialogStateChanged = (options) => {
			if (!options || options.id !== this.id) { return; }

			this.classes = firstDefined(this.classes, options.newClasses);
			this.offsetX = firstDefined(this.offsetX, options.offsetX);
		};

		this._onDialogResized = (options) => {
			if (!options || !options.id === this.id) { return; }

			this._setDialogSize(this.$refs.id, this.dialog);
		};

		this.fork.on('dialog.state.changed', this._onDialogStateChanged);
		this.fork.on('dialog.resized', this._onDialogResized);

		this.$on('close', () => {
			if (this.initDialog.vm.onClose) {
				this.initDialog.vm.onClose();
			}
			this.$parent.$emit('closeDialog', this.id);
		});
	},
	mounted() {
		this.isMounted = true;
		this._setDialogSize(this.$refs.id, this.dialog);
	},
	beforeDestroy() {
		this.fork.dispose();
	}
};
</script>
