var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "control-lookup-editor-wrapper" },
    [
      _c("control-lookup-editor", {
        class: { invalid: !_vm.isValid },
        attrs: {
          evaluationContext: _vm.evaluationContext,
          options: _vm.lookupOptions,
          showRequired: _vm.showRequiredMark
        },
        model: {
          value: _vm.wrappedValue,
          callback: function($$v) {
            _vm.wrappedValue = $$v
          },
          expression: "wrappedValue"
        }
      }),
      _vm._v(" "),
      _vm.options.allowQuickAdd && _vm.logicalName
        ? _c(
            "button",
            {
              staticClass: "secondary",
              attrs: { type: "button", disabled: !_vm.enable },
              on: { click: _vm.quickCreate }
            },
            [_vm._v(_vm._s(_vm.quickCreateButtonName))]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }