var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dialog-content-wrapper copy-link-dialog editor" },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "field-wrapper" }, [
          _c("div", { staticClass: "p13" }, [_vm._v("Direct Link: ")]),
          _vm._v(" "),
          _c("div", { staticClass: "field" }, [
            _c("input", {
              ref: "text",
              attrs: { type: "text" },
              domProps: { value: _vm.getUrl() }
            }),
            _vm._v(" "),
            _c("button", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: { key: "dialogues.email.link.copy" },
                  expression: "{key: 'dialogues.email.link.copy'}"
                }
              ],
              attrs: { type: "submit" },
              on: { click: _vm.copy }
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field-wrapper" }, [
          _c("div", { staticClass: "p13" }, [
            _vm._v("Hyperlink to share with other Participants: ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "field" }, [
            _c("input", {
              attrs: { type: "text" },
              domProps: { value: _vm.roomName }
            }),
            _vm._v(" "),
            _c("button", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: { key: "dialogues.email.link.copy" },
                  expression: "{key: 'dialogues.email.link.copy'}"
                }
              ],
              attrs: { type: "submit" },
              on: { click: _vm.copyHyperLink }
            })
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "footer" }, [
        _c("span", { staticClass: "buttons" }, [
          _c("button", {
            directives: [
              {
                name: "localization",
                rawName: "v-localization",
                value: { key: "common.dictionary.buttons.cancel" },
                expression: "{key: 'common.dictionary.buttons.cancel'}"
              }
            ],
            staticClass: "cancel",
            attrs: { type: "button" },
            on: { click: _vm.cancel }
          })
        ])
      ]),
      _vm._v(" "),
      _c(
        "a",
        { ref: "link", staticClass: "link", attrs: { href: _vm.getUrl() } },
        [_vm._v(_vm._s(_vm.roomName))]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }