var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "control-lookup-editor input",
      class: {
        focused: _vm.searchFocused || _vm.isPopUpOpen,
        opened: _vm.isPopUpOpen,
        disabled: _vm.options.disabled
      },
      on: { click: _vm.focusInput }
    },
    [
      _c("div", { staticClass: "actions before" }, [
        _vm.showRequiredMark
          ? _c("div", { staticClass: "required-mark" }, [
              _c(
                "svg",
                { staticClass: "svg-icon svg-8 layout-icons-required" },
                [
                  _c("use", {
                    attrs: { "xlink:href": "#layout-icons-required" }
                  })
                ]
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showInput,
            expression: "showInput"
          }
        ],
        staticClass: "lookup-editor-input p1",
        class: { required: _vm.showRequiredMark },
        attrs: {
          disabled: _vm.options.disabled,
          type: "text",
          placeholder: _vm.placeholder
        },
        domProps: { value: _vm.searchQuery },
        on: {
          input: _vm.searchWithDebounce,
          keyup: [
            function($event) {
              if (
                !("button" in $event) &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.search($event)
            },
            function($event) {
              if (
                !("button" in $event) &&
                _vm._k($event.keyCode, "down", 40, $event.key, [
                  "Down",
                  "ArrowDown"
                ])
              ) {
                return null
              }
              _vm.$root.$emit("lookup.keyDown")
            },
            function($event) {
              if (
                !("button" in $event) &&
                _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
              ) {
                return null
              }
              _vm.$root.$emit("lookup.keyUp")
            }
          ],
          focus: _vm.onInputFocus,
          blur: _vm.onInputBlur
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showInput,
              expression: "!showInput"
            }
          ],
          staticClass: "selected-items"
        },
        [
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-name" }, [
              _vm._v(_vm._s(_vm.displayName))
            ]),
            _vm._v(" "),
            _c(
              "svg",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.options.disabled,
                    expression: "!options.disabled"
                  }
                ],
                staticClass: "svg-icon svg-15",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.clearSelection($event)
                  }
                }
              },
              [
                _c("use", {
                  staticClass: "action-icons-options-remove",
                  attrs: { "xlink:href": "#action-icons-options-remove" }
                })
              ]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.options.disabled,
              expression: "!options.disabled"
            }
          ],
          staticClass: "actions after clickable"
        },
        [
          _c(
            "div",
            {
              staticClass: "icon-wrapper",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.iconAction($event)
                }
              }
            },
            [
              _vm.customIcon
                ? [
                    _c("div", { staticClass: "icon" }, [
                      _c("svg", { staticClass: "svg-icon" }, [
                        _c("use", {
                          staticClass: "customIcon",
                          attrs: { "xlink:href": "#" + _vm.customIcon }
                        })
                      ])
                    ])
                  ]
                : [
                    !_vm.isPopUpOpen
                      ? _c("div", { staticClass: "icon" }, [
                          _c("svg", { staticClass: "svg-icon svg-10" }, [
                            _c("use", {
                              staticClass: "layout-icons-ms-arrow-down",
                              attrs: {
                                "xlink:href": "#layout-icons-ms-arrow-down"
                              }
                            })
                          ])
                        ])
                      : _c(
                          "div",
                          {
                            staticClass: "icon",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.closePopUp($event)
                              }
                            }
                          },
                          [
                            _c("svg", { staticClass: "svg-icon svg-10" }, [
                              _c("use", {
                                staticClass: "layout-icons-ms-arrow-up",
                                attrs: {
                                  "xlink:href": "#layout-icons-ms-arrow-up"
                                }
                              })
                            ])
                          ]
                        )
                  ]
            ],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }