<template>
	<div class="drag-and-drop-editor">
		<ControlFileDraganddrop v-model="value"
			:dataAttr="evaluationContext.eval(options.qaDataAttribute)"
			:enable="enable"
			:invalid="!isValid"
			:acceptExtensions="options.acceptExtensions"
			:allowedExtensions="options.allowedExtensions"
		/>
	</div>
</template>
<script>
import ControlMixins from '@/Components/Editor/Controls/control.mixins';
import ControlFileDraganddrop from '@/Components/Control/FileUpload/control-file-draganddrop';
export default {
	name: 'control-file-draganddrop-editor',
	mixins: [ControlMixins],
	props: {
		options: Object,
		evaluationContext: Object
	},
	components: { ControlFileDraganddrop }
};
</script>
