export default {
	props: {
		evaluationContext: Object,
		options: Object,
		// TODO: remove when new Lookup for Import will be created
		temporarySelectLookupItems: {
			type: Array,
			default: () => ([])
		},
		temporarySelectLookup: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			searchQuery: '',
			suggestionParams: {},
			searchFocused: false,
			isPopUpOpen: false,
			lastSearchQuery: '',
			placeholder: this.options.placeholder,
			pageSize: this.options.pageSize || 20,
			popupId: Math.random() * 10000000000000000 + 1,
			selectedItemChanged: false,
			searchResult: []
		};
	},
	/*watch: {
		'options.query': {
			handler(value, old) {
				if(old !== value) this.searchQuery = value;
			}
		}
	},*/
	mounted() {
		this.localizations = sc.classes.get('localization.dataProvider');
		if (this.placeholder === void 0) {
			this.localizations.getLabelForCurrentLanguage('common.dictionary.search').then(text => {
				this.placeholder = text;
			});
		}
		this.suggestionParams = {
			data: {},
			isLoading: false,
			displayName: this.options.displayName,
			onScrollToBottom: this.onScrollToBottom,
			hideFooter: this.options.hideFooter,
			template: this.options.template,
			treeLogicalName: this.options.treeLogicalName,
			rootName: this.rootPath,
			onSelect: selected => {
				this.onSelected && this.onSelected(selected);
				// selected - new value, this.selectedItem - old value
				this.options.singleSelectionOnArray && this.options.singleSelectionOnArray(selected, this.selectedItem);
				this.selectedItemChanged = true;
				this.clearSearchBox();
				this.closePopUp();
			},
			closeCallback: () => {
				this.isPopUpOpen = false;
				this.onClosePopup && this.onClosePopup(this.selectedItemChanged);
				this.selectedItemChanged = false;
				this.suggestionParams.data = {};
			}
		};
		// TODO: remove when new Lookup for Import will be created
		if (this.temporarySelectLookup) {
			this.initDomainsWatcher = this.$store.watch(
				(state, getters) => getters['wizard/getUserDomains'],
				(newVal) => {
					const domains = newVal;
					if (domains && domains.some(i => i.selected)) {
						this.selectedItemVal = domains.find(i => i.selected);
					} else if (domains && domains.length) {
						let firsDomain = domains[0];
						firsDomain.selected = true;
						this.selectedItemVal = firsDomain;
					}
				},
				{ immediate: true }
			);
		}
		this.debouncedSearch = _.debounce(this.search, 500);
	},
	methods: {
		search(force) {
			if (this.options.disabled || (!force && this.lastSearchQuery === this.searchQuery && this.searchQuery !== '')) {
				return;
			} else {
				this.lastSearchQuery = this.searchQuery;
			}
			!this.isPopUpOpen && this.openPopUp();
			this.fetchData();
		},
		onScrollToBottom() {
			if (this.suggestionParams.data.total && this.suggestionParams.data.items.length !== this.suggestionParams.data.total) {
				this.fetchData(this.suggestionParams.data.items.length);
			}
		},
		async fetchData(startFrom) {
			this.suggestionParams.isLoading = true;
			if (this.temporarySelectLookupItems.length) {
				const result = this.temporarySelectLookupItems;
				this.suggestionParams.data = {
					items: result,
					total: result.length
				};
				this.suggestionParams.selectedItem = this.selectedItem;
				this.searchResult = this.temporarySelectLookupItems;
			} else if (this.options.fetchData) {
				const result = await this.options.fetchData(this.searchQuery, startFrom, this.pageSize);
				let items = (startFrom && this.suggestionParams.data.items) || [];
				items = items.concat(result.items);

				this.suggestionParams.data = {
					...result,
					items
				};
				this.suggestionParams.selectedItem = this.selectedItem;
				this.searchResult = this.suggestionParams.data.items;
			}
			this.suggestionParams.isLoading = false;
		},
		onInputBlur() {
			this.searchFocused = false;
		},
		clearSearchBox() {
			this.searchQuery = '';
			this.$root.$emit('inputCleared');
		},

		openPopUp() {
			// We need this in case parent of lookup is hidden and we don't know the values on mounted hook
			this.suggestionParams.offsetLeft = -((this.$el.offsetWidth / 2));
			this.suggestionParams.offsetTop = this.$el.offsetHeight + 5;
			this.suggestionParams.value = this.value;

			this.$root.$emit('templatePopup.open', this.$el, this.popupId, {
				name: this.suggestionComponent || 'control-lookup-suggestion',
				params: this.suggestionParams
			}, 'click', this.$el.offsetWidth + 'px', true);

			this.isPopUpOpen = true;
		},

		closePopUp() {
			this.$root.$emit('templatePopup.close.' + this.popupId);
		},

		searchWithDebounce(e) {
			this.searchQuery = e.target.value;
			this.debouncedSearch();
			//this.$emit('input', e.target.value);
		}
	}
};
