<template>
	<div class="ms-edit-list">
		<div class="loading-overlay large" v-show="isLoading"></div>
		<div class="list-editor" v-if="lookupTemplate">
			<span class="h5" v-localization="{key: 'staticListsPanel.editor.searchLookupName'}"></span>
			<control-lookup :options="getLookupOptions()"></control-lookup>
		</div>
		<div class="marketspace-marketing-lists">
			<component-search-results :options="componentOptions" :evaluationContext="evaluationContext"></component-search-results>
		</div>

		<div class="footer-wrapper">
			<div class="footer">
				<span class='buttons'>
					<button type="button" @click="close" v-localization="{key: 'common.dictionary.buttons.close'}"></button>
				</span>
			</div>
		</div>
	</div>
</template>
<script>
import ComponentSearchResults from '@/Components/ComponentSet/SearchResults/component-search-results';
import StaticlistMSMixins from './staticlists-ms.mixin';
import { showToastOrInfoDialog } from '@acx-xms/data-functions/dist';

export default {
	name: 'staticlists-ms-edit',
	props: { options: Object },
	mixins: [StaticlistMSMixins],
	components: { ComponentSearchResults },
	data() {
		return { componentOptions: this.options.params.componentOptions };
	},
	methods: {
		async OnSingleSelectionOnArray(record) {
			this.isLoading = true;
			const dataProvider = sc.classes.get('edge.dataProvider');
			const existing = await dataProvider.search({
				entities: ['staticlistmember'],
				filters: [
					sc.classes.get('termFacet.filter', {
						logicalName: 'staticlist.id',
						query: [this.options.params.entity.id]
					}),
					sc.classes.get('termFacet.filter', {
						logicalName: 'entityreference.id',
						query: [record.data.Id]
					}),
					sc.classes.get('selectedFields.filter', [])
				]
			});
			if (existing.Total === 0) {
				const entity = this.options.params.entity;
				await dataProvider.create('staticlistmember', {
					staticlist: {
						id: entity.id || entity.Id,
						logicalname: 'staticlist'
					},
					entityreference: sc.classes.get('entityReference', record.data),
					name: record.data.Name,
					type: 'staticlistmember'
				}).then(async () => {
					await showToastOrInfoDialog({
						toastMessageKey: this.options.params.toastMessageKey,
						informationDialogTextKey: this.options.params.informationDialogTextKey,
						options: { context: this }
					});
					this.fork.emit('entity.changed', { entity: { logicalname: 'staticlist' } });
				});
			}
			this.isLoading = false;
		}
	}
};
</script>
<style src="./staticlists-ms-edit.less" scoped></style>
