var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "search-area" }, [
    _c("h5", {
      directives: [
        {
          name: "localization",
          rawName: "v-localization",
          value: {
            key: "dialogues.mapping.header",
            args: [_vm.logicalName || ""]
          },
          expression:
            "{ key: 'dialogues.mapping.header', args: [logicalName || ''] }"
        }
      ]
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "search-field-wrapper inline-block",
        class: { focused: _vm.searchFocused }
      },
      [
        _c("input", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: {
                key: "common.dictionary.search",
                attrs: ["placeholder"]
              },
              expression:
                "{ key: 'common.dictionary.search', attrs: ['placeholder'] }"
            },
            {
              name: "model",
              rawName: "v-model",
              value: _vm.term,
              expression: "term"
            }
          ],
          staticClass: "search-box",
          attrs: { type: "text" },
          domProps: { value: _vm.term },
          on: {
            focus: function($event) {
              _vm.searchFocused = true
            },
            blur: function($event) {
              _vm.searchFocused = false
            },
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.term = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "search-icon-wrapper" }, [
          _c("svg", { staticClass: "svg-icon" }, [
            _c("use", {
              staticClass: "layout-icons-ms-search",
              attrs: { "xlink:href": "#layout-icons-ms-search" }
            })
          ]),
          _vm._v(" "),
          _c(
            "svg",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.term && _vm.term.length > 0,
                  expression: "term && term.length > 0"
                }
              ],
              staticClass: "svg-icon svg-20 clear-button",
              on: { click: _vm.onClearSearchBox }
            },
            [
              _c("use", {
                staticClass: "layout-icons-textbox-delete",
                attrs: { "xlink:href": "#layout-icons-textbox-delete" }
              })
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }