import {
	processResults, searchDocumentRelatedRecords, searchChilrenDocuments
} from '../DocumentFolderAddToDialog/docsRelationUtils.js';
export default {
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return {};
	},
	async created() {
		const configDataProvider = sc.classes.get('entityConfiguration.dataProvider');
		this.logicalName = this.options.logicalName;
		// TODO: when rework entityReference class into ES module - add check if the record is already entity ref and return it, if it is the case
		this.relateFromRef = sc.classes.get('entityReference', this.options.params.relateFromRecord);
		this.documentRelatedRecordsTemplate = this.options.documentRelatedRecordsTemplate && await configDataProvider
			.getTemplate(this.options.documentRelatedRecordsTemplate.entity, this.options.documentRelatedRecordsTemplate.usage, this.options.documentRelatedRecordsTemplate.subtype);

		let filteredResults;
		if (this.options.documentRelatedRecordsTemplate) {
			if (this.logicalName === 'folder') {
				this.childrenDocuments = await searchChilrenDocuments(this.relateFromRef.id);
				if (this.childrenDocuments && this.childrenDocuments.length) {
					const promises = this.childrenDocuments.map(document => {
						return searchDocumentRelatedRecords([document.id], this.options);
					});
					const results = await Promise.all(promises);
					filteredResults = (results || []).flat().map(res => res.Source);
				}
			} else {
				const results = await searchDocumentRelatedRecords([this.relateFromRef.id], this.options);
				filteredResults = results.map(res => res.Source);
			}
			this.documentRelatedRecords = processResults(filteredResults);
		}
		this.isLoading = false;
	},
	methods: {
		cancel() {
			this.$parent.$emit('close');
		}
	}
};
