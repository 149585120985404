<template>
	<div class="content">
		<div v-if="isLoading" class="loading-wrapper">
			<div class="loading-overlay" />
		</div>
		<template v-else>
			<div v-if="error" class="no-data">
				<!-- TODO: research about component error -->
				<component
					:is="'details-no-access'"
					:options="getErrorComponentOptions()"
					:requestedAccess="requestedAccess"
				/>
			</div>
			<template v-else>
				<div class="header">
					<Breadcrumbs v-if="breadcrumbs" :breadcrumbs="breadcrumbs" :isDeactivated="isDeactivated"/>
					<template v-if="accessByLink">
						<ActionMenu
							v-if="actionMenuComponent"
							:options="actionMenuComponent"
							:record="data"
							:evaluationContext="evaluationContext"
							class="without-padding without-border"
						/>
					</template>
					<div v-else class="h1">{{ data.Name }}</div>
				</div>
				<div v-perfectscroll="{enable: true, onScroll: onScroll, updateScrollEvent}" class="rows content-scroll-wrapper">
					<DetailsRowControl
						v-for="row in rows"
						:evaluationContext="evaluationContext"
						:row="row"
						:key="row.$key"
						:style="{height: row.height}"
					/>
				</div>
			</template>
		</template>
	</div>
</template>
<script>
/* eslint no-mixed-operators: 0 */
import ComponentSetDetailsMixin from './componentsetdetails.mixins';
import helperMethods from '@/Components/ComponentSet/component-set-evaluation-context-helper.methods';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';
import { filterAsync, RECORD_STATE } from '@acx-xms/data-functions/dist';
import { GetUserInfo } from '@/Data/Auth/authentication-service';
const DetailsNoAccess = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Common/details-no-access');

export default {
	name: 'entitytemplate-componentsetdetails',
	components: { DetailsNoAccess },
	mixins: [ComponentSetDetailsMixin],
	data() {
		return {
			details: this.template.content.details,
			rows: [],
			requestedAccess: null,
			errorComponent: { componentName: 'details-no-access' },
			isRecordExist: true,
			showRequestAccess: true,
			metaTitle: '',
			metaDescription: '',
			participantExist: false,
			isDeactivated: false
		};
	},
	metaInfo() {
		return {
			title: this.metaTitle,
			meta: [
				{
					name: 'description',
					content: this.metaDescription
				}
			]
		};
	},
	async created() {
		this.fork = sc.events.fork();
		this.logicalName = this.$route.params.type;
		this.id = this.$route.params.id;
		if (this.template.content.brokerConfig) {
			this.errorComponent.options = this.getErrorComponentOptions();
		}
		this.updateScrollEvent = 'scroll.update';
		await this.trackUserActivity({
			id: this.id,
			logicalname: this.logicalName
		});

		this.$on('onValueChange', this.onValueChange);
		this.$root.$on('refreshCurrentDetails', this.getDetailsData);
		this.$root.$on('refreshCurrentDetailsWithMiddleware', this.getDetailsDataWithMiddleware);
		this.$root.$on('entity.changed', this.onEntityChange);
		this.$root.$on('parentScrollUpdate', this.onParentScrollUpdate);
		this.$root.$on('updateChatParticipantHandler', this.updateInformation); /* added update information */
		// todo - remove after aspects migrate to vue
		this.fork.on('refreshCurrentDetails', this.getDetailsData);
		this.fork.on('refreshCurrentDetailsWithMiddleware', this.getDetailsDataWithMiddleware);
		this.fork.on('entity.changed', this.onEntityChange);
		this.fork.on('entity.details.loading', (isLoading) => {
			this.isLoading = isLoading;
		});
		this.detailsScroll = helperMethods.eval(this.evaluationContext, this.scrollNamespace);
		this.rows = this.details.row.map((row) => {
			return {
				...row,
				$key: helperMethods.getRandomArbitary(1, 10000000000000000).toFixed()
			};
		});

		this.actionMenuComponent = {
			menu: this.details.menu,
			toolbarActions: this.details.actionToolbar,
			entityType: this.logicalname,
			$type: 'layoutcomponent.marketspaceActionMenu',
			scrollNamespace: this.detailsScroll
		};

		const entityConfig = await sc.classes.get('entityConfiguration.dataProvider').fetchEntity(this.logicalName);
		const beforeDetailsMiddlewares = await filterAsync((entityConfig.middlewares ? entityConfig.middlewares.middleware : []), async (middle) => {
			return middle && helperMethods.eval(this.evaluationContext, middle.target) === 'beforeDetails' &&
					(middle.enable === void 0 || await helperMethods.evalAsync(this.evaluationContext, middle.enable));
		});
		this.entityConfig = entityConfig;

		this._applyMiddlewares(beforeDetailsMiddlewares).then(() => {
			this.getDetailsData();
		});
		this.$on('.navigateBreadcrumbs', () => {
			const bread = this.breadcrumbs;
			// current
			bread.pop();
			// prev
			const prev = bread.pop();
			if (prev) {
				this.syncBreadcrumbs(null, bread);
				this.$router.push(prev.route);
			}
		});
	},
	beforeDestroy() {
		if (this.fork) {
			this.fork.dispose();
		}
		this.$root.$off('refreshCurrentDetails', this.getDetailsData);
		this.$root.$off('refreshCurrentDetailsWithMiddleware', this.getDetailsDataWithMiddleware);
		this.$root.$off('entity.changed', this.onEntityChange);
		this.$off('onValueChange', this.onValueChange);
		this.$root.$off('parentScrollUpdate', this.onParentScrollUpdate);
		this.$root.$off('updateChatParticipantHandler', this.updateInformation);
		this.evaluationContext = null;
		this.data = null;
		this.rows = [];
	},
	methods: {
		getErrorComponentOptions() {
			return {
				componentName: 'details-no-access',
				brokerConfig: this.template.content.brokerConfig,
				isRecordExist: this.isRecordExist,
				redirectRouteIfNotExist: this.redirectRouteIfNotExist,
				showRequestAccess: this.showRequestAccess,
				id: this.id,
				logicalName: this.logicalName,
				participantExist: this.participantExist
			};
		},
		onEntityChange(data) {
			if (this.logicalName === data.entity.logicalname) {
				this.getDetailsData();
			}
		},
		onParentScrollUpdate() {
			this.$emit(this.updateScrollEvent);
		},
		async getDetailsDataWithMiddleware() {
			this.isLoading = true;
			// TODO: workaround, find a better solution, in middleware we are setting chatparticipant into 'this'
			this.chatparticipantInfo = null;
			this.error = false;
			const detailsMiddlewares = await filterAsync((this.entityConfig.middlewares ? this.entityConfig.middlewares.middleware : []), async (middle) => {
				return middle && (middle.enable === void 0 || await helperMethods.evalAsync(this.evaluationContext, middle.enable));
			});
			await this._applyMiddlewares(detailsMiddlewares);
			await this.getDetailsData();
		},
		async getDetailsData() {
			this.isLoading = true;
			const getUser = await GetUserInfo();
			const isOrgAdmin = getUser.CRMRoles.some(role => role.Name === 'Organization Admin');
			const detailsFilters = this.entityConfig.detailsFilters && this.entityConfig.detailsFilters.filter.length
				? this.entityConfig.detailsFilters.filter.map(x => sc.classes.get(x.$type, x).toFilter())
				: [];
			const filters = [
				sc.classes.get('offsetFrom.filter', 0),
				sc.classes.get('offsetSize.filter', 1),
				sc.classes.get('search.idsFilter', { id: [this.id] }).toFilter(),
				...detailsFilters
			];
			const routeConfig = this.$route.params.config;
			const configTemplates = this.$store.getters.configTemplates;

			const templates = configTemplates[routeConfig][this.logicalName];
			let selectedFields = this.getDynamycSelectedFields(templates, this.$store.getters.previewTemplates[this.logicalName]) || [];
			selectedFields = selectedFields.concat(await this.getSelectedFieldsFromConfig());

			if (selectedFields.length) {
				filters.push(sc.classes.get('selectedFields.filter', selectedFields.map(field => ({ logicalname: field }))).fillQuery());
			}
			try {
				const [entityConfig, data] = await Promise.all([
					sc.classes.get('entityConfiguration.dataProvider').fetchEntity(this.logicalName),
					sc.classes.get('edge.dataProvider').search({
						entities: [this.logicalName],
						filters,
						withDefaultFilters: false
					})
				]);
				if ((!this.participantExist && isOrgAdmin && ['collaborationroom', 'dealroom'].some((i) => this.$route.fullPath.includes(i))) || data.Results.length === 0) {
					this.isRecordExist = false;
					this.error = true;
				} else {
					this.data = data.Results[0];
					this.isDeactivated = Object.prototype.hasOwnProperty.call(this.data.Source, 'isdisabled')
						? this.data.Source.isdisabled
						: this.data.Source.recordstate && this.data.Source.recordstate.id === RECORD_STATE.INACTIVE;
					this.error = false;

					if (this.logicalName === 'listing') {
						const listingName = this.data.Name;
						const propertyType = this.data.Source.listingchildpropertytypelookuppropertytype && this.data.Source.listingchildpropertytypelookuppropertytype.name || '';
						const saleOrLease = this.data.Source.listingsaleorleaselookupsaleorlease && this.data.Source.listingsaleorleaselookupsaleorlease.name || '';
						const city = this.data.Source.listingpropertyidproperty && this.data.Source.listingpropertyidproperty.city || '';
						const state = this.data.Source.listingpropertyidproperty && this.data.Source.listingpropertyidproperty.stateorprovince || '';
						const address = this.data.Source.listingpropertyidproperty && this.data.Source.listingpropertyidproperty.address || '';
						const cityAndState = (city || state ? ' in ' : '') + (city && state ? [city, state].join(', ') : city || state || '');
						let properTypeAndAdress = propertyType && address ? [propertyType, address].join(' - ') : propertyType || address || '';
						if (properTypeAndAdress) {
							properTypeAndAdress = ' ' + properTypeAndAdress;
						}
						this.metaTitle = `${listingName} - ${propertyType} for ${saleOrLease}${cityAndState}`;
						this.metaDescription = `${listingName} for ${saleOrLease}.${properTypeAndAdress}`;
						// Title meta tag: "%Listing name% - %Property type% for %Sale or Lease% in %City%, %State%"
						// Description meta tag: "%Listing name% for %Sale or Lease%". % Property type% - % Street %, % City %, % Country %, % State %, % Zip code %.
					}

					if (this.logicalName === 'availability') {
						const availabilityName = this.data.Name;
						const saleOrLease = this.data.Source.availabilitysaleorleaselookupsaleorlease && this.data.Source.availabilitysaleorleaselookupsaleorlease.name || '';
						this.metaTitle = `${availabilityName} for ${saleOrLease}`;
						this.metaDescription = `${availabilityName} for ${saleOrLease}`;
					}

					this.evaluationContext = createEvaluationContext(this.data);
					const title = this.evaluationContext.eval(entityConfig.entityItemDisplayName);
					const detailsMiddlewares = await filterAsync((entityConfig.middlewares ? entityConfig.middlewares.middleware : []), async (middle) => {
						return middle && helperMethods.eval(this.evaluationContext, middle.target) !== 'beforeDetails' &&
								(middle.enable === void 0 || await helperMethods.evalAsync(this.evaluationContext, middle.enable));
					});
					this._applyMiddlewares(detailsMiddlewares);

					this.syncBreadcrumbs({
						id: this.id,
						logicalName: this.logicalName,
						title
					});
				}
			} catch (error) {
				this.error = true;
				this.data = null;
				console.log(ko.unwrap(error));
			} finally {
				this.isLoading = false;
			}
		},

		onValueChange(name, value) {
			this[name] = value;
		},

		getDynamycSelectedFields(templates = [], previewTemplates = []) {
			const selectedFieldsSet = new Set();
			if (previewTemplates.length) {
				previewTemplates.forEach(template => {
					JSON.parse(template.selectedfields).forEach(field => selectedFieldsSet.add(field));
				});
			}

			templates.forEach(template => {
				if (!this.templateTypeOnPreview(previewTemplates, template)) {
					JSON.parse(template.selectedfields || '[]').forEach(field => selectedFieldsSet.add(field));
				}
			});
			return [...selectedFieldsSet];
		},

		templateTypeOnPreview(previewTemplates, template) {
			if (previewTemplates.length) {
				return previewTemplates.some(item => item.templatetype === template.templatetype);
			}
		},

		async getSelectedFieldsFromConfig() {
			const entitiesConfiguration = await sc.classes.get('entityConfiguration.dataProvider').fetchEntity(this.logicalName);
			if (entitiesConfiguration.selectedFieldsSettings) {
				const selectedFieldsSection = entitiesConfiguration.selectedFieldsSettings.section.find(item => item.type === 'details');
				if (selectedFieldsSection && selectedFieldsSection.field) {
					return selectedFieldsSection.field;
				}
			}
			return [];
		},
		async updateInformation(event) {
			if (this.error && !event) {
				await this.getDetailsData();
				if (this.error) setTimeout(async () => await this.updateInformation(), 5000);
				else {
					await this.getDetailsDataWithMiddleware();
					this.$root.$emit('resubscribe');
				}
			}
			this.$root.$emit('chat.innerChat.searching');
		}
	}
};
</script>
<style src="@/Components/ComponentSet/SearchResults/search-results.less" scoped></style>
<style src="@/Components/ComponentSet/Details/search-details.less" scoped></style>
