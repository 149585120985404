import { render, staticRenderFns } from "./add-brokers.vue?vue&type=template&id=133ffb03&scoped=true&"
import script from "./add-brokers.vue?vue&type=script&lang=js&"
export * from "./add-brokers.vue?vue&type=script&lang=js&"
import style0 from "./add-brokers.less?vue&type=style&index=0&id=133ffb03&scoped=true&lang=css&"
import style1 from "./../Control/Table/control-table-view.less?vue&type=style&index=1&id=133ffb03&scoped=true&lang=css&"
import style2 from "./../Control/Table/control-table-view-row.less?vue&type=style&index=2&id=133ffb03&scoped=true&lang=css&"
import style3 from "@/Components/ComponentSet/SearchResults/search-field/search-field.less?vue&type=style&index=3&id=133ffb03&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "133ffb03",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\SCCore\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('133ffb03', component.options)
    } else {
      api.reload('133ffb03', component.options)
    }
    module.hot.accept("./add-brokers.vue?vue&type=template&id=133ffb03&scoped=true&", function () {
      api.rerender('133ffb03', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "Scripts/Vue/Components/AddBrokers/add-brokers.vue"
export default component.exports