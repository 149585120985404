/* eslint new-cap: 0 */
import { AddQuickContactToParticipants } from './aspects/addQuickContactToParticipants.aspect';

export class AddQuickContact {
	constructor(options) {
		this.options = options;
	}

	installAspects() {
		return [new AddQuickContactToParticipants(this.options)];
	}
}
