var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "invite-participants-dialog" }, [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ],
      staticClass: "loading-overlay large"
    }),
    _vm._v(" "),
    _vm.isViewReady
      ? _c("div", { staticClass: "dialog-container" }, [
          _vm.options.multiGroup
            ? _c(
                "div",
                { staticClass: "select-group" },
                [
                  _c("span", {
                    directives: [
                      {
                        name: "localization",
                        rawName: "v-localization",
                        value: { key: "inviteParticipantsPlugin.selectGroup" },
                        expression:
                          "{ key: 'inviteParticipantsPlugin.selectGroup' }"
                      }
                    ],
                    staticClass: "select-group-label"
                  }),
                  _vm._v(" "),
                  _c("control-lookup-editor", {
                    directives: [
                      {
                        name: "data-attr",
                        rawName: "v-data-attr",
                        value: {
                          title: _vm.options.title,
                          $type: "dialog.input.search"
                        },
                        expression:
                          "{title: options.title, $type: 'dialog.input.search'}"
                      }
                    ],
                    staticClass: "select-group-dropdown",
                    attrs: {
                      evaluationContext: _vm.options.evaluationContext,
                      options: _vm.getGroupLookupOptions()
                    },
                    model: {
                      value: _vm.selectedGroup,
                      callback: function($$v) {
                        _vm.selectedGroup = $$v
                      },
                      expression: "selectedGroup"
                    }
                  }),
                  _vm._v(" "),
                  _c("button", {
                    directives: [
                      {
                        name: "localization",
                        rawName: "v-localization",
                        value: { key: "inviteParticipantsPlugin.addGroup" },
                        expression:
                          "{ key: 'inviteParticipantsPlugin.addGroup' }"
                      },
                      {
                        name: "data-attr",
                        rawName: "v-data-attr",
                        value: {
                          title: _vm.options.title,
                          $type: "dialog.submit.button"
                        },
                        expression:
                          "{title: options.title, $type: 'dialog.submit.button'}"
                      }
                    ],
                    staticClass: "cancel",
                    on: { click: _vm.createNewGroup }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "select-contacts" }, [
            _c("div", { staticClass: "description" }, [
              _c("h5", {
                directives: [
                  {
                    name: "localization",
                    rawName: "v-localization",
                    value: {
                      key: "inviteParticipantsPlugin.inviteTo",
                      args: [_vm.entityName]
                    },
                    expression:
                      "{ key: 'inviteParticipantsPlugin.inviteTo', args: [entityName] }"
                  }
                ]
              })
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "select-entity-wrapper" },
              [
                _c(
                  "div",
                  { staticClass: "select-entity" },
                  [
                    _c("control-lookup", {
                      directives: [
                        {
                          name: "data-attr",
                          rawName: "v-data-attr",
                          value: {
                            title: _vm.options.title,
                            $type: "dialog.input.search"
                          },
                          expression:
                            "{title: options.title, $type: 'dialog.input.search'}"
                        }
                      ],
                      attrs: {
                        evaluationContext: _vm.options.evaluationContext,
                        options: _vm.getContactLookupOptions()
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("control-actionbutton", {
                  directives: [
                    {
                      name: "data-attr",
                      rawName: "v-data-attr",
                      value: {
                        title: _vm.options.title,
                        $type: "dialog.submit.button"
                      },
                      expression:
                        "{title: options.title, $type: 'dialog.submit.button'}"
                    }
                  ],
                  attrs: {
                    evaluationContext: _vm.options.evaluationContext,
                    options: _vm.actionBtnOptions
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "entity-list" },
            [
              _vm.userListTemplate
                ? _c("control", {
                    staticClass: "formcontrol-template",
                    attrs: {
                      name: _vm.userListTemplate.$type,
                      contentProps: _vm.userListTemplate,
                      evaluationContext: _vm.options.evaluationContext,
                      initResults: _vm.invited
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          !_vm.doNotShowAgainLocalStorage && !_vm.infoClosed
            ? _c("div", { staticClass: "info" }, [
                _c("div", { staticClass: "text-with-icon" }, [
                  _c("svg", { staticClass: "svg-icon" }, [
                    _c("use", {
                      staticClass: "layout-icons-warning",
                      attrs: { "xlink:href": "#layout-icons-warning" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "p4" }, [
                    _vm._v(
                      "Co-owner participants of the room will have the same permissions as the room owner."
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "do-not-show-again" },
                  [
                    _c("checkbox", {
                      attrs: {
                        value: _vm.doNotShowAgain,
                        label: "Do not display again"
                      },
                      on: { input: _vm.doNotShowAgainOnChange }
                    }),
                    _vm._v(" "),
                    _c(
                      "svg",
                      {
                        staticClass: "svg-icon",
                        on: {
                          click: function($event) {
                            _vm.infoClosed = true
                          }
                        }
                      },
                      [
                        _c("use", {
                          staticClass: "layout-icons-dialog-close",
                          attrs: { "xlink:href": "#layout-icons-dialog-close" }
                        })
                      ]
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "footer" }, [
            _c("div", { staticClass: "dialog-buttons" }, [
              _c("div", { staticClass: "group" }, [
                _c("button", {
                  directives: [
                    {
                      name: "localization",
                      rawName: "v-localization",
                      value: { key: "inviteParticipantsPlugin.copyLink" },
                      expression: "{key: 'inviteParticipantsPlugin.copyLink'}"
                    },
                    {
                      name: "data-attr",
                      rawName: "v-data-attr",
                      value: {
                        title: _vm.options.title,
                        $type: "dialog.button.copyLink"
                      },
                      expression:
                        "{title: options.title, $type: 'dialog.button.copyLink'}"
                    }
                  ],
                  staticClass: "cancel",
                  attrs: { type: "button" },
                  on: { click: _vm.copyLink }
                }),
                _vm._v(" "),
                _c("button", {
                  directives: [
                    {
                      name: "localization",
                      rawName: "v-localization",
                      value: { key: "inviteParticipantsPlugin.emailLink" },
                      expression: "{key: 'inviteParticipantsPlugin.emailLink'}"
                    },
                    {
                      name: "data-attr",
                      rawName: "v-data-attr",
                      value: {
                        title: _vm.options.title,
                        $type: "dialog.button.emailLink"
                      },
                      expression:
                        "{title: options.title, $type: 'dialog.button.emailLink'}"
                    }
                  ],
                  staticClass: "cancel",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      _vm.emailLink(_vm.invited)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "group" }, [
                _c("button", {
                  directives: [
                    {
                      name: "localization",
                      rawName: "v-localization",
                      value: { key: "common.dictionary.buttons.close" },
                      expression: "{key: 'common.dictionary.buttons.close'}"
                    },
                    {
                      name: "data-attr",
                      rawName: "v-data-attr",
                      value: {
                        title: _vm.options.title,
                        $type: "dialog.button.close"
                      },
                      expression:
                        "{title: options.title, $type: 'dialog.button.close'}"
                    }
                  ],
                  staticClass: "cancel",
                  attrs: { type: "button" },
                  on: { click: _vm.cancel }
                }),
                _vm._v(" "),
                _c("button", {
                  directives: [
                    {
                      name: "localization",
                      rawName: "v-localization",
                      value: { key: "inviteParticipantsPlugin.save" },
                      expression: "{key: 'inviteParticipantsPlugin.save'}"
                    },
                    {
                      name: "data-attr",
                      rawName: "v-data-attr",
                      value: {
                        title: _vm.options.title,
                        $type: "dialog.button.submit"
                      },
                      expression:
                        "{title: options.title, $type: 'dialog.button.submit'}"
                    }
                  ],
                  attrs: { type: "button" },
                  on: { click: _vm.save }
                })
              ])
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }