<template>
	<div class="dialog-content-wrapper">
		<div class="loading-overlay large" v-show="isLoading"></div>
		<div class="save-search-dialog">
			<div class="dialog-container">
				<div class="title p4">
					{{ texts.desc }}
				</div>
				<div class="dialog-content">
					<div class="new-search">
						<div class="radio">
							<input type="radio" @change="clearInputs" v-model="createNew" v-bind:value="true" />
						</div>
						<input v-bind:disabled="!createNew" class="new-search-name sc-input p1" v-bind:placeholder="texts.newPlaceholder" type="text" v-model.trim="newSearchName" />
					</div>
					<div class="existing-search">
						<div class="radio">
							<input type="radio" @change="clearInputs" v-model="createNew" v-bind:value="false" />
						</div>
						<control-lookup-editor v-model="selectedSearchValue" :evaluationContext="evaluationContext" :options="getLookupOptions()"></control-lookup-editor>
					</div>
				</div>
			</div>

			<div class='footer'>
				<div class="statistic">
					<div class="selection-info error" v-if="validationError">
						<span v-localization="{key: validationError}"></span>
					</div>
				</div>
				<div class='buttons'>
					<span class="button-wrapper">
						<button v-bind:disabled="!saveEnabled" type="button" @click="save" v-localization="{key: 'common.dictionary.buttons.save'}"></button>
					</span>
					<button class="cancel" type="button" @click="cancel" v-localization="{key: 'common.dictionary.buttons.cancel'}"></button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
const ControlLookupEditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './../Control/Lookup/control-lookup-editor');
export default {
	name: 'ms-save-search-dialog',
	props: { options: Object },
	data() {
		return {
			validationError: null,
			isLoading: false,
			newSearchName: null,
			existingSearch: null,
			createNew: true,
			evaluationContext: this.options.evaluationContext,
			texts: {},
			selectedSearchValue: null
		};
	},
	components: { ControlLookupEditor },
	computed: {
		saveEnabled() {
			return (this.createNew && this.newSearchName) || (!this.createNew && this.selectedSearch);
		},
		selectedSearch() {
			return this.selectedSearchValue && sc.classes.get('entityReference', this.selectedSearchValue.data);
		}
	},
	methods: {
		save: function () {
			this.isLoading = true;
			let promise;
			const filters = this.$store.getters[this.options.params.stateNamespace + '/getFiltersRaw'](this.options.params.entity);
			const uiFilters = this.$store.getters[this.options.params.stateNamespace + '/getUiFiltersRaw'](this.options.params.entity);
			const queryString = this.$store.getters[this.options.params.stateNamespace + '/getSearchQuery'];
			const requestString = JSON.stringify({
				filters,
				queryString,
				uiFilters
			});
			if (this.createNew) {
				promise = this.dataProvider.create(this.options.params.savedSearchEntity,
					{
						name: this.newSearchName,
						request: this.dataProvider.fillFilters([]),
						requests: requestString,
						entities: [this.options.params.entity]
					},
					{
						async: false,
						syncProcessMode: 'All'
					}
				);
			} else if (this.selectedSearch) {
				promise = this.dataProvider.update(this.options.params.savedSearchEntity, this.selectedSearch.id, { requests: requestString });
			}
			promise.done(() => {
				this.close();
			}).fail(err => {
				if (err.responseText.includes('duplicate')) {
					this.localizations.getLabelForCurrentLanguage('dialogues.saveSearch.savedSearchNameExists').then(text => {
						sc.events.emit('dialog.open', {
							title: 'Warning',
							allowMinimize: false,
							allowMaximize: false,
							allowPin: false,
							allowClose: false,
							component: 'dialog.info',
							params: {
								message: text,
								buttons: [
									{ title: 'OK' }
								]
							}
						});
					});
				} else {
					sc.utils.errorMessage.byResponse(err);
				}
			}).always(() => {
				this.isLoading = false;
			});
		},
		cancel: function () {
			this.close();
		},
		close: function () {
			this.$parent.$emit('close');
		},
		getLookupOptions: function () {
			return {
				placeholder: this.texts.existingPlaceholder,
				disabled: this.createNew,
				fetchData: (searchQuery, startFrom, pageSize) => {
					startFrom = startFrom || 0;
					pageSize = pageSize || 1000;
					const filters = [
						...this.options.savedSearchFilters,
						sc.classes.get('offsetFrom.filter', startFrom),
						sc.classes.get('offsetSize.filter', pageSize),
						sc.classes.get('selectedFields.filter', [])
					];
					if (searchQuery) {
						filters.push(sc.classes.get('field.filter', {
							logicalName: '_all',
							query: searchQuery
						}));
					}
					return this.dataProvider.search({
						filters,
						entities: [this.options.params.savedSearchEntity]
					}).then(({ Results, Total }) => {
						return {
							total: Total,
							items: Results.map(res => {
								return {
									displayName: res.Name,
									data: res
								};
							})
						};
					});
				}
			};
		},
		clearInputs() {
			this.newSearchName = '';
			this.selectedSearchValue = null;
		}
	},
	created() {
		this.dataProvider = sc.classes.get('edge.dataProvider');
		this.localizations = sc.classes.get('localization.dataProvider');
		this.localizations.getLabelForCurrentLanguage('dialogues.saveSearch.createOrUpdate').then(text => {
			this.texts.desc = text;
		});
		this.localizations.getLabelForCurrentLanguage('dialogues.saveSearch.nameForNew').then(text => {
			this.texts.newPlaceholder = text;
		});
		this.localizations.getLabelForCurrentLanguage('dialogues.saveSearch.selectExisting').then(text => {
			this.texts.existingPlaceholder = text;
		});
	}
};
</script>
<style src="./saved-search-dialog.less" scoped></style>
