var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-content-wrapper" }, [
    _c("div", { staticClass: "search-result-dialog" }, [
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading,
            expression: "isLoading"
          }
        ],
        staticClass: "loading-overlay large"
      }),
      _vm._v(" "),
      !_vm.isLoading
        ? _c(
            "div",
            { staticClass: "dialog-container" },
            [
              _c(_vm.searchResultComponentName, {
                tag: "component",
                attrs: {
                  options: _vm.componentOptions,
                  evaluationContext: _vm.evaluationContext
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "footer" }, [
        _c("div", { staticClass: "buttons" }, [
          _c("button", {
            directives: [
              {
                name: "localization",
                rawName: "v-localization",
                value: { key: "common.dictionary.buttons.close" },
                expression: "{key: 'common.dictionary.buttons.close'}"
              }
            ],
            attrs: { type: "button" },
            on: { click: _vm.close }
          })
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }