var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "editor" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "perfectscroll",
            rawName: "v-perfectscroll",
            value: { enable: _vm.isScrollEnabled, onScroll: _vm.onScroll },
            expression: "{enable: isScrollEnabled, onScroll: onScroll}"
          }
        ],
        staticClass: "body"
      },
      [
        _vm.editorInitializationReady
          ? [
              _vm.editorTemplate
                ? _c("control", {
                    attrs: {
                      name: _vm.editorTemplate.$type,
                      contentProps: _vm.editorTemplate,
                      evaluationContext: _vm.controlContext
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.relatedEntityTemplate
                ? _c("control", {
                    attrs: {
                      name: _vm.relatedEntityTemplate.$type,
                      contentProps: _vm.relatedEntityTemplate,
                      evaluationContext: _vm.relatedContext
                    }
                  })
                : _vm._e()
            ]
          : _vm._e()
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c(
        "button",
        {
          directives: [
            {
              name: "data-attr",
              rawName: "v-data-attr",
              value: { title: _vm.options.title, $type: "dialog.button.close" },
              expression:
                "{ title: options.title, $type: 'dialog.button.close' }"
            }
          ],
          staticClass: "cancel",
          on: { click: _vm.onCancel }
        },
        [_vm._v("Cancel")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          directives: [
            {
              name: "data-attr",
              rawName: "v-data-attr",
              value: {
                title: _vm.options.title,
                $type: "dialog.button.submit"
              },
              expression:
                "{ title: options.title, $type: 'dialog.button.submit' }"
            }
          ],
          on: { click: _vm.onSubmit }
        },
        [_vm._v("Save")]
      )
    ]),
    _vm._v(" "),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ],
      staticClass: "loading-overlay"
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }