var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("control-lookup-editor", {
    class: { invalid: !_vm.isValid },
    attrs: {
      evaluationContext: _vm.evaluationContext,
      options: _vm.getLookupOptions(),
      showRequired: _vm.showRequiredMark
    },
    model: {
      value: _vm.wrappedValue,
      callback: function($$v) {
        _vm.wrappedValue = $$v
      },
      expression: "wrappedValue"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }