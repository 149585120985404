<template>
	<div class="version-dialog">
		<div class="versions">
			<div class="version-item" v-for="version in versions" :key="version.label">
				<span class="label p4">{{version.label}} </span>
				<span class="value h5">{{version.value}}</span>
			</div>
		</div>
		<div class="privacy-policy" v-if="termsenabled">
			<a :href="ppLink" @click.prevent="download(tosLink,'Terms Of Use')">Terms of Use</a> and <a :href="tosLink" @click.prevent="download(ppLink, 'Privacy Policy')">Privacy Policy</a>
		</div>
		<div class="powered-by">
			<span class="label p1">Powered by</span>
			<span class="value p2"><a target="_blank" href="https://ascendix.com">Ascendix Technologies</a></span>
		</div>
		<div class="loading-overlay large" v-show="isLoading"></div>
	</div>
</template>
<script>
import { downloadExternalUrl } from '@acx-xms/data-functions/dist';

export default {
	name: 'version-info-dialog',
	props: { options: Object },
	data() {
		return {
			isLoading: false,
			versions: [],
			ppLink: '',
			tosLink: '',
			termsenabled: sc.cluster.features.termsofuseandprivacypolicy
		};
	},
	async created() {
		this.isLoading = true;
		this.versions = await this.getVersions();
		if (this.termsenabled) {
			const environmentSettings = await sc.classes.get('settings.dataProvider').getEnvironmentSettings(sc.cluster.key);
			this.ppLink = environmentSettings.privacyPolicyUrl;
			this.tosLink = environmentSettings.termsOfUseUrl;
		}
		this.isLoading = false;
	},
	methods: {
		async getVersions() {
			const localizationDataProvider = sc.classes.get('localization.dataProvider');
			this.versions = [
				{
					label: await localizationDataProvider.getLabelForCurrentLanguage('dialogues.versionInfo.ms'),
					value: (sc.cluster.version || {}).Version
				},
				{
					label: await localizationDataProvider.getLabelForCurrentLanguage('dialogues.versionInfo.sc.configuration', [sc.cluster.configKey]),
					value: sc.cluster.configVersion
				}
			];

			return this.versions;
		},
		download(source, name) {
			downloadExternalUrl(source, name);
		}
	}
};
</script>
<style src="./version-info-dialog.less" scoped></style>
