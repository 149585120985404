<template>
	<div
		v-if="visible"
		:class="['filter-group filter-group-toggle-panel', {
			selected: isSelected,
			open: isExpanded,
			collapsible: collapsible
		}]"
	>
		<div
			v-data-attr="{ options, stateNamespace }"
			class="panel-title panel-opener"
			@click="toggle"
		>
			<h3>
				{{ name }}
			</h3>
			<ControlSvgIcon
				v-if="showSaveSearchButton"
				:options="{ name: 'action-icons-search-save' }"
				:evaluationContext="evaluationContext"
				class="action-icon"
				@click.stop.native="saveSearch"
			/>
			<div v-if="collapsible" class="icon-holder">
				<svg class="svg-icon svg-15">
					<use xlink:href="#layout-icons-filter-group-collapse" class="hover"/>
				</svg>
			</div>
		</div>
		<ul v-show="isExpanded" class="filter-items filter-group-content">
			<li :class="['filter', { open: isMySearchExpanded }]">
				<div class="filter-opener items-opener" @click="openMySearches">
					<span class="filter-name-truncate">
						<span>
							{{ texts.mySearches }}
						</span>
					</span>
					<div class="icon-wrapper">
						<svg class="svg-icon svg-15">
							<use xlink:href="#layout-icons-filter-opener" class="hover layout-icons-filter-opener" />
						</svg>
					</div>
				</div>
			</li>
			<li :class="['filter', { open: isSharedSearchExpanded }]">
				<div class="filter-opener items-opener" @click="openSharedSearches">
					<span class="filter-name-truncate">
						<span>
							{{ texts.sharedWithMe }}
						</span>
					</span>
					<div class="icon-wrapper">
						<svg class="svg-icon svg-15">
							<use xlink:href="#layout-icons-filter-opener" class="hover layout-icons-filter-opener" />
						</svg>
					</div>
				</div>
			</li>
		</ul>
	</div>
</template>
<script>
/* eslint vue/no-unused-components: 0 */
import '@/Bindings/ui-attribute.directive';
import helperMethods from '@/Components/ComponentSet/component-set-evaluation-context-helper.methods';
import FilterMixins from './../filter.mixins';
const FilterInline = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './../filter-inline/filter-inline');
const FilterInlineAsync = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './../filter-inline/filter-inline-async');
const ControlSvgIcon = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './../../Control/Images/control-svg-icon');

export default {
	name: 'saved-searches-group',
	mixins: [FilterMixins],
	props: {
		options: Object,
		evaluationContext: Object,
		filters: Object,
		stateNamespace: String
	},
	data() {
		return {
			visible: false,
			isSelected: false,
			isExpanded: this.options.defaultExpanded ? helperMethods.eval(this.evaluationContext, this.options.defaultExpanded) : true,
			collapsible: true,
			savedSearchEntity: helperMethods.eval(this.evaluationContext, this.options.savedSearchEntity),
			entity: helperMethods.eval(this.evaluationContext, this.options.entity),
			name: '',
			texts: {},
			savedSearchRequestField: helperMethods.eval(this.evaluationContext, this.options.savedSearchRequestField)
		};
	},
	components: {
		FilterInline,
		FilterInlineAsync,
		ControlSvgIcon
	},
	async created() {
		this.visible = sc.user && sc.user.hasPrivilege(this.savedSearchEntity, 'Read');
		this.showSaveSearchButton = sc.user && sc.user.hasPrivilege(this.savedSearchEntity, 'Create');
		this.$on('openInfoPanel', function (options) {
			this.$parent.$emit('openInfoPanel', options);
		});
		const localizations = sc.classes.get('localization.dataProvider');
		localizations.getLabelForCurrentLanguage('filtersPanel.savedSearch.mySearches').then(text => {
			this.texts.mySearches = text;
		});
		localizations.getLabelForCurrentLanguage('filtersPanel.savedSearch.sharedWithMe').then(text => {
			this.texts.sharedWithMe = text;
		});
		const entityConfigurationDataProvider = sc.classes.get('entityConfiguration.dataProvider');
		localizations.getLabelForCurrentLanguage('dialogues.saveSearch.saveSearch').then(localizedText => {
			this.texts.dialogTitle = localizedText;
			entityConfigurationDataProvider.getEntityCaptions([this.entity], 'plural').then(entityName => {
				if (entityName && entityName[0]) {
					this.texts.dialogTitle = `${localizedText}: ${entityName[0]}`;
				}
			});
		});
		this.name = await helperMethods.evalAsync(this.evaluationContext, this.options.categoryName);
		this.mySearchFilters = [
			sc.classes.get(this.options.ownSearchFilter.$type, this.options.ownSearchFilter, this.evaluationContext).toFilter(),
			sc.classes.get('termFacet.filter', {
				logicalName: 'entities',
				query: [this.entity]
			})
		];
		this.sharedSearchFilters = [
			sc.classes.get(this.options.sharedSearchFilter.$type, this.options.sharedSearchFilter, this.evaluationContext).toFilter(),
			sc.classes.get('termFacet.filter', {
				logicalName: 'entities',
				query: [this.entity]
			})
		];
	},
	computed: {
		isMySearchExpanded() {
			const options = this.$store.getters[this.stateNamespace + '/getInfoPanelOptions'];
			return (options) ? helperMethods.eval(this.evaluationContext, options.logicalName) === 'mySearches' : false;
		},
		isSharedSearchExpanded() {
			const options = this.$store.getters[this.stateNamespace + '/getInfoPanelOptions'];
			return (options) ? helperMethods.eval(this.evaluationContext, options.logicalName) === 'sharedSearches' : false;
		}
	},
	methods: {
		toggle() {
			this.isExpanded = !this.isExpanded;
		},
		evalLogicalName() {
			return helperMethods.eval(this.evaluationContext, this.options.logicalName);
		},
		saveSearch() {
			this.$store.commit(this.stateNamespace + '/openInfoPanel', null);
			sc.events.emit('vue.dialog.open', {
				icon: 'action-icons-search-save',
				title: this.texts.dialogTitle,
				allowPin: false,
				component: 'ms.save.search.dialog',
				maximizedWidth: '450px',
				maximizedHeight: '325px',
				evaluationContext: this.evaluationContext,
				savedSearchFilters: this.mySearchFilters,
				params: {
					modalNamespace: 'savesearchpopup',
					stateNamespace: this.stateNamespace,
					entity: this.entity,
					savedSearchEntity: this.savedSearchEntity
				}
			});
		},
		openMySearches() {
			this.$store.commit(
				this.stateNamespace + '/openInfoPanel',
				{
					$type: 'entity.filter.savedSearch',
					savedSearchFilters: this.mySearchFilters,
					savedSearchEntity: this.options.savedSearchEntity,
					entity: this.options.entity,
					logicalName: 'mySearches',
					disableResetFilter: true,
					displayName: this.texts.mySearches,
					savedSearchRequestField: this.savedSearchRequestField,
					menu: this.options.mySearchActionMenu
				}
			);
		},
		openSharedSearches() {
			this.$store.commit(
				this.stateNamespace + '/openInfoPanel',
				{
					$type: 'entity.filter.savedSearch',
					savedSearchFilters: this.sharedSearchFilters,
					savedSearchEntity: this.options.savedSearchEntity,
					entity: this.options.entity,
					logicalName: 'sharedSearches',
					disableResetFilter: true,
					displayName: this.texts.sharedWithMe,
					savedSearchRequestField: this.savedSearchRequestField,
					menu: this.options.sharedSearchActionMenu
				}
			);
		}
	}
};
</script>
<style src="./filters-group.less" scoped></style>
<style src="./../filter-inline/filter-inline.less" scoped></style>
<style scoped>
	.action-icon {
		position: absolute;
		right: 50px;
	}
</style>
