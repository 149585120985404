var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "search-results" }, [
    _vm.currentcomponentset
      ? _c(
          "div",
          { staticClass: "search-results-wrapper" },
          [
            _c(
              "div",
              { staticClass: "search-results-header" },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "data-attr",
                        rawName: "v-data-attr",
                        value: {
                          options: _vm.options,
                          stateNamespace: "button"
                        },
                        expression:
                          "{ options: options, stateNamespace: 'button' }"
                      }
                    ],
                    class: [
                      "filter-button active",
                      { open: !!_vm.showFilterPanel }
                    ],
                    on: {
                      click: function($event) {
                        _vm.toggleFilterPanel()
                      }
                    }
                  },
                  [
                    _c("svg", { staticClass: "svg-icon" }, [
                      _c("use", {
                        staticClass: "ms-filter-icon",
                        attrs: { "xlink:href": "#layout-icons-ms-filter-icon" }
                      })
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("h1", { staticClass: "search-results-title" }, [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(
                        +_vm.$store.getters["wizard.navigation/getActive"] === 3
                          ? _vm.collectionTitle
                          : _vm.title
                      ) +
                      "\n\t\t\t"
                  )
                ]),
                _vm._v(" "),
                _c("SearchField", { staticClass: "search-results-field" }),
                _vm._v(" "),
                _c("ViewSwitcher", {
                  staticClass: "search-results-switcher",
                  attrs: {
                    items: _vm.items,
                    stateNamespace: _vm.stateNamespace
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.visibleSelectionList && !(+this.getActive === 3)
              ? _c(
                  "TagsList",
                  {
                    staticClass: "search-results-selection",
                    attrs: {
                      stateNamespace: _vm.stateNamespace,
                      entityNamespace: _vm.logicalName,
                      placeholder: _vm.selectedTagsPlaceholder
                    },
                    on: { delete: _vm.unselect }
                  },
                  [
                    [
                      !!_vm.selectedItems &&
                      _vm.selectedItems.length &&
                      [3, 4].includes(
                        _vm.$store.getters["wizard.navigation/getActive"]
                      )
                        ? _c("span", [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(_vm.selectedTagsCounter) +
                                "\n\t\t\t\t"
                            )
                          ])
                        : _vm._e()
                    ]
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isCollapsed,
                    expression: "!isCollapsed"
                  }
                ],
                class: [
                  "content-wrapper",
                  {
                    withPanel: _vm.showFilterPanel,
                    loading: _vm.isLoading
                  }
                ]
              },
              [
                +this.getActive === 3
                  ? _c("FiltersPanel", {
                      class: ["filter", { opened: _vm.showFilterPanel }],
                      attrs: {
                        options: _vm.options.filterPanel,
                        evaluationContext: _vm.filterPanelEvaluationContext,
                        isLoading: _vm.isLoading,
                        entityName: _vm.logicalName,
                        stateNamespace: _vm.stateNamespace
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _vm.visibleSelectionList && +this.getActive === 3
                      ? _c(
                          "TagsList",
                          {
                            class: [
                              "search-results-selection",
                              _vm.getCollectionType
                            ],
                            attrs: {
                              stateNamespace: _vm.stateNamespace,
                              entityNamespace: _vm.getCollectionType,
                              customSelectionsQuery: _vm.tagsCustomQuery
                            },
                            on: { delete: _vm.unselect }
                          },
                          [
                            [
                              !!_vm.selectedItems &&
                              _vm.selectedItems.length &&
                              [3, 4].includes(
                                _vm.$store.getters[
                                  "wizard.navigation/getActive"
                                ]
                              )
                                ? _c("span", [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t" +
                                        _vm._s(_vm.selectedTagsCounter) +
                                        "\n\t\t\t\t\t\t"
                                    )
                                  ])
                                : _vm._e()
                            ]
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !(+this.getActive === 3)
                      ? _c("FiltersPanel", {
                          class: ["filter", { opened: _vm.showFilterPanel }],
                          attrs: {
                            options: _vm.options.filterPanel,
                            evaluationContext: _vm.filterPanelEvaluationContext,
                            isLoading: _vm.isLoading,
                            entityName: _vm.logicalName,
                            stateNamespace: _vm.stateNamespace
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("sortpanel", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: false,
                          expression: "false"
                        }
                      ],
                      staticClass: "sortby-filter-wrapper",
                      attrs: {
                        fields: _vm.options.sortByFields,
                        entityName: _vm.logicalName,
                        evaluationContext: _vm.evaluationContext,
                        scrollNamespace: _vm.scrollNamespace,
                        namespace: _vm.namespace,
                        internalNamespace: _vm.internalNamespace,
                        stateNamespace: _vm.stateNamespace
                      }
                    }),
                    _vm._v(" "),
                    _c(_vm.content.name, {
                      tag: "component",
                      class: [
                        "content-inherit-height",
                        { "with-footer": !!_vm.results.total }
                      ],
                      attrs: {
                        options: Object.assign({}, _vm.content, {
                          withSelectionList: false
                        }),
                        isActive: _vm.isActive,
                        results: _vm.results.results,
                        stateNamespace: _vm.stateNamespace,
                        combinedNamespace: !_vm.options.filterPanel
                          ? _vm.combinedNamespace
                          : "",
                        paginationParams: _vm.paginationParams
                      }
                    }),
                    _vm._v(" "),
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isLoading,
                          expression: "isLoading"
                        }
                      ],
                      staticClass: "loading-overlay"
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }