var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-step" }, [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ],
      staticClass: "loading-overlay large"
    }),
    _vm._v(" "),
    _c("div", { staticClass: "list-content" }, [
      _c("div", { staticClass: "control-tableView inner-search-results" }, [
        _c("div", { staticClass: "tableview-header" }, [
          _c("div", { staticClass: "cell first-column" }, [
            _c("span", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: { key: "import.TypeOfRecords" },
                  expression: "{ key: 'import.TypeOfRecords'}"
                }
              ],
              staticClass: "label-header"
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "cell second-column" }, [
            _c("span", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: { key: "import.fieldsIncluded" },
                  expression: "{ key: 'import.fieldsIncluded'}"
                }
              ],
              staticClass: "label-header"
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "cell third-column" }, [
            _c("span", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: { key: "import.fieldsExcluded" },
                  expression: "{ key: 'import.fieldsExcluded'}"
                }
              ],
              staticClass: "label-header"
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "tableview-content" }, [
          _c("div", { staticClass: "content-scroll-wrapper" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "table-cell first-column" }, [
                _c("span", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.options.displayName))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "table-cell second-column" }, [
                _c("span", { staticClass: "label" }, [
                  _vm._v(_vm._s(Object.keys(_vm.options.selectedFields).length))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "table-cell third-column" }, [
                _c("span", { staticClass: "label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.options.lengthFields -
                        Object.keys(_vm.options.selectedFields).length
                    )
                  )
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c("span", { staticClass: "buttons" }, [
        _c("button", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "import.startImport" },
              expression: "{key: 'import.startImport'}"
            }
          ],
          attrs: { type: "button" },
          on: { click: _vm.nextStep }
        }),
        _vm._v(" "),
        _c("button", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "import.back" },
              expression: "{key: 'import.back'}"
            }
          ],
          staticClass: "cancel",
          attrs: { type: "button" },
          on: { click: _vm.previousStep }
        })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }