<template>
	<div class="sc-ndaFileUpload">
		<div class="nda-file-upload-wrapper row">
			<div class="input-radio-wrapper" v-if="!disableFileLookupEditor" v-bind:class="{'disabled': !enable}">
				<input type="radio" v-model="isExisting" :value="false" />
			</div>
			<ControlFileDraganddrop class="control-wrapper"
									v-model="files"
									:allowedExtensions="options.allowedExtensions"
									:acceptExtensions="options.acceptExtensions"
									:enable="!isExisting && enable"
									:invalid="!isValid && !isExisting"
									:dataAttr="evaluationContext.eval(options.qaDataAttribute)"
									></ControlFileDraganddrop>
		</div>
		<div class="nda-file-select-document-wrapper row" v-if="!disableFileLookupEditor">
			<div class="input-radio-wrapper" v-bind:class="{'disabled': !enable}">
				<input type="radio" v-model="isExisting" :value="true" />
			</div>
			<control-folder-tree-view-editor	class="control-wrapper" v-if="componentOptions"
												:invalid="!isValid && isExisting"
												:visible="isExisting"
												@existingDocument="setExisting"
												:evaluationContext="evaluationContext"
												:options="componentOptions && componentOptions.component[0]">
			</control-folder-tree-view-editor>
		</div>
	</div>
</template>
<script>
/* eslint vue/no-unused-components: 0 */
import ControlFileDraganddrop from '@/Components/Control/FileUpload/control-file-draganddrop';
import ControlFolderTreeViewEditor from '@/Components/Editor/Controls/FolderTreeViewEditor/control-folder-tree-view-editor';

import ControlMixins from '@/Components/Editor/Controls/control.mixins';
const ControlLookupEditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Lookup/control-lookup-editor');

export default {
	name: 'control-document-multiupload-editor',
	mixins: [ControlMixins],
	components: {
		ControlLookupEditor,
		ControlFileDraganddrop,
		ControlFolderTreeViewEditor
	},
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return {
			isExisting: false,
			componentOptions: null
		};
	},
	async created() {
		this.tempFiles = [];
		this.filesFieldName = 'files';
		this.existingDocumentFieldName = 'existingDocument';
		this.disableFileLookupEditor = this.evaluationContext.eval(this.options.disableFileLookupEditor);
		await Promise.all([
			this.$store.dispatch(this.stateKey + '/setField', {
				name: this.filesFieldName,
				value: []
			}),
			this.$store.dispatch(this.stateKey + '/setField', {
				name: this.existingDocumentFieldName,
				value: null
			})
		]);

		this.componentOptions = await sc.classes.get('marketspaceComponents.dataProvider').getComponentSetById('document.share');
	},
	computed: {
		showRequiredMark() {
			return this.storeState.requiredFields[this.field] && !this.selectedFile && !this.uploadedFileName;
		},
		existingDocument: {
			get() {
				return this.getField(this.existingDocumentFieldName);
			},
			set(value) {
				this.setField(value, false, this.existingDocumentFieldName);
			}
		},
		files: {
			get() {
				return this.getField(this.filesFieldName);
			},
			set(value) {
				this.setField(value, false, this.filesFieldName);
			}
		}
	},
	watch: {
		isExisting(val) {
			if (val) {
				this.tempFiles = this.files;
				this.files = [];
			} else {
				if (this.tempFiles.length) {
					this.files = this.tempFiles;
					this.tempFiles = [];
				}
				this.existingDocument = null;
			}
		}
	},
	beforeDestroy() {
		if (this.componentOptions && this.componentOptions.component[0] && this.componentOptions.component[0]) {
			const stateNamespace = this.evaluationContext.eval(this.componentOptions.component[0].stateNamespace);
			if (this.$store.state[stateNamespace]) this.$store.commit(`${stateNamespace}/cleanSelection`);
		}
	},
	methods: {
		setExisting(selection) {
			this.existingDocument = selection;
		}
	}

};
</script>
<style src="./control-ndafile-editor.less" scoped></style>
