
<template>
	<div>
		<div class="filter-content range-dates panel-content">
			<div class="range-dates">
				<div class="range-date">
					<span v-localization="{ key: 'filtersPanel.from' }" class="range-date-cell range-date-caption" />
					<div class="range-date-cell">
						<div class="clear-wrapper">
							<DatePicker
								v-model="from"
								v-data-attr="{ options, stateNamespace: 'from' }"
								:enabled-dates="[to]"
								:has-today-action="options.hasTodayAction"
							/>
						</div>
					</div>
				</div>
				<div class="range-date">
					<span v-localization="{ key: 'filtersPanel.to' }" class="range-date-cell range-date-caption" />
					<div class="range-date-cell">
						<div class="clear-wrapper">
							<DatePicker
								v-model="to"
								v-data-attr="{ options, stateNamespace: 'to' }"
								:has-today-action="options.hasTodayAction"
								:enabled-dates="[,from]"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>

		<IncludeEmpty
			v-if="options.isIncludeEmptyVisible"
			v-model="includeEmpty"
			v-data-attr="{ options, stateNamespace: 'empty' }"
			:enabled="enableIncludeEmpty"
		/>
	</div>
</template>

<script>
/* eslint vue/no-unused-components: 0 */
/* eslint eqeqeq: 0 */
/* eslint no-mixed-operators: 0 */
import '@/Bindings/ui-attribute.directive';
import helperMethods from '@/Components/ComponentSet/component-set-evaluation-context-helper.methods';
import FilterMixins from './../../filter.mixins';
const IncludeEmpty = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './include-empty');
const ControlSvgIcon = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Images/control-svg-icon');
const DatePicker = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */'@/Components/Common/date-picker');

export default {
	name: 'filter-info-panels-date-range',
	mixins: [FilterMixins],
	props: {
		options: Object,
		evaluationContext: Object,
		stateNamespace: String
	},
	data() {
		return {
			from: null,
			to: null,
			includeEmpty: false
		};
	},
	computed: {
		enableIncludeEmpty() {
			return !!(this.to || this.from);
		}
	},
	watch: {
		to(newVal) {
			this.to = Date.parse(newVal);
		},
		from(newVal) {
			this.from = Date.parse(newVal);
		}
	},
	components: {
		DatePicker,
		IncludeEmpty,
		ControlSvgIcon
	},
	created() {
		this.logicalName = helperMethods.eval(this.evaluationContext, this.options.logicalName);
		// no need to selected filter here
		this.entity = this.options.entities[0];

		this.filterValue = this.$store.getters[this.stateNamespace + '/getFilterValue']({
			entity: this.entity,
			logicalName: this.logicalName
		});

		if (this.filterValue) {
			this.applyFilterValue(this.filterValue);
			this.includeEmpty = this.filterValue.data.includeEmpty;
		}

		this.$parent.$on(this.stateNamespace + 'resetFilter', this.resetFilter);
	},
	async beforeDestroy() {
		const hasValue = () => this.min != this.from || this.max != this.to || this.includeEmpty;
		const checkBorder = (fromFilter, fromValue) => {
			if (fromFilter && fromValue) { return fromFilter.getTime() !== fromValue.getTime(); } else { return !!fromFilter !== !!fromValue; }
		};
		const wasChanged = () => {
			return (!this.filterValue && hasValue()) ||
					(this.filterValue && !hasValue()) ||
					(this.filterValue &&
						(checkBorder(this.filterValue.data.items[0].Range.From, this.from) ||
							checkBorder(this.filterValue.data.items[0].Range.To, this.to)) ||
						(this.filterValue && (this.filterValue.data.includeEmpty != this.includeEmpty)));
		};
		if (wasChanged()) {
			const filter = (!hasValue()) ? null : await this.getSearchFilter(this.logicalName);
			this.emitFilterChanged(filter);
		}
	}
};
</script>
<style src="./panels.less" scoped></style>
