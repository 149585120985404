<template>
	<div class="tabs-wrapper" v-perfectscroll="{enable: true}">
		<ul class='tabs-header'>
			<li v-for="(tab, idx) in tabs" :key='tab.title'
				@click='selectTab(idx)'
				:class='{"selected-tab": (idx == selectedIdx)}'
				class='tab'
				v-show="tab.visible == undefined || tab.visible">
				{{ tab.title }}
			</li>
		</ul>
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: 'tabs',
	data() {
		return {
			selectedIdx: 0,
			tabs: []
		};
	},
	created() {
		this.tabs = this.$children;
	},
	mounted() {
		this.selectTab(this.tabs.findIndex(item => item.visible));
	},
	methods: {
		selectTab(i) {
			this.selectedIdx = i;
			this.tabs.forEach((tab, index) => {
				tab.isActive = (i === index);
			});
		}
	}
};
</script>
<style src='./tabs.less' scoped></style>
