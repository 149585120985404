<template>
	<div class="ms-edit-list create">
		<div
			class="loading-overlay large"
			v-show="isLoading"
		></div>
		<div class="list-editor">
			<template v-if="!isLoading">
				<control
					v-if="editorTemplate"
					:name="editorTemplate.$type"
					:contentProps="editorTemplate"
					:evaluationContext="evaluationContext"
				></control>
				<span
					class="h5"
					v-localization="{ key: 'staticListsPanel.editor.searchLookupName' }"
				></span>
				<div class="intermediate-wrapper">
					<control-lookup :options="getLookupOptions()"></control-lookup>
					<!-- uncomment when fixes for import will be applied -->
					<!-- <div
						v-if="!hasCRM"
						class="import-wrapper"
					>
						<div class="partition"></div>
						<button
							@click="openImportDialog"
							type="button"
							class="cancel"
							v-localization="{ key: 'common.dictionary.buttons.importContacts' }"
							v-data-attr="{ title: options.title, $type: 'dialog.button.submit' }"
						>
						</button>
					</div> -->
				</div>
			</template>
		</div>
		<div class="header">
			<div class="region">
				<h5 class="title">List Content</h5>
			</div>
			<search-field
				:placeholderLocalizationKey="'common.dictionary.searchSelectedContancts'"
			/>
		</div>
		<div class="marketspace-marketing-lists">
			<div
				class="loading-overlay large"
				v-show="isTableLoading"
			>
			</div>
			<control
				class="formcontrol-template"
				v-if="listTemplate && !isLoading"
				:name="listTemplate.$type"
				:contentProps="listTemplate"
				:evaluationContext="evaluationContext"
				:initResults="membersVisible"
			>
			</control>
			<pagination
				v-if="paginationParams"
				:pageSize="paginationParams.pageSize"
				:total="paginationParams.total"
				:from="paginationParams.from"
				:showPaging="paginationParams.showPaging"
				:activePages="paginationParams.activePages"
			/>
		</div>
		<div class="footer-wrapper">
			<div class="footer">
				<span class='buttons'>
					<button
						@click="save"
						type="button"
						v-localization="{ key: 'common.dictionary.buttons.save' }"
						v-data-attr="{title: options.title, $type: 'dialog.button.submit'}"
					>
					</button>
					<button
						class="cancel"
						type="button"
						@click="close"
						v-localization="{ key: 'common.dictionary.buttons.cancel' }"
						v-data-attr="{title: options.title, $type: 'dialog.button.cancel'}"
					>
					</button>
				</span>
			</div>
		</div>
	</div>
</template>
<script>
import Control from '@/Components/Entity/control';
import editorMixins from '@/Components/editor.mixins.js';
import StaticlistMSMixins from './staticlists-ms.mixin';
import {
	showToastOrInfoDialog, Create, SendRequest, Search
} from '@acx-xms/data-functions/dist';

const Pagination = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */'@/Components/ComponentSet/pagination/pagination');
const SearchField = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */'@/Components/ComponentSet/SearchResults/search-field/search-field');
export default {
	name: 'staticlists-ms-create',
	props: { options: Object },
	mixins: [editorMixins, StaticlistMSMixins],
	components: {
		Control,
		Pagination,
		SearchField
	},
	data() {
		return {
			isLoading: true,
			staticlistmembers: [],
			membersVisible: [],
			listTemplate: null,
			editorTemplate: null,
			hasCRM: sc.utils.hasCRMsettings,
			isTableLoading: false,
			paginationParams: {
				activePages: [1],
				from: 0,
				total: 0,
				pageSize: 25,
				showPaging: true
			}
		};
	},
	watch: {
		'staticlistmembers.length': function () {
			this.paginationParams.total = this.staticlistmembers.length;
		}
	},
	async created() {
		const listTemplate = await this.configDataProvider.getTemplate(
			'contact',
			this.options.userListTemplate.usage,
			this.options.userListTemplate.subtype
		);

		const editorTemplate = await this.configDataProvider.getTemplate(
			this.logicalName,
			this.options.editorTemplate.usage,
			this.options.editorTemplate.subtype
		);

		this.editorTemplate = editorTemplate && editorTemplate.content;

		this.listTemplate = listTemplate && listTemplate.content;

		if (this.options.params.predefinedContactFilters && this.options.params.predefinedContactFilters.length) {
			const { Results: predefinedContacts } = await Search(
				['contact'],
				[
					...this.options.params.predefinedContactFilters,
					sc.classes.get('selectedFields.filter', [
						{ logicalname: 'fullname' },
						{ logicalname: 'email' },
						{ logicalname: 'donotemail' },
						{ logicalname: 'account' },
						{ logicalname: 'jobtitle' }
					])
						.fillQuery()
				]
			);
			this.staticlistmembers = [...predefinedContacts];
		}
		this.membersVisible = this.staticlistmembers.slice(0, this.paginationParams.pageSize);
		this.initResults = [...this.staticlistmembers];
		this.fork.on('createMarketingList.deleteMember', this.deleteMember);
		this.$root.$on(
			'createMarketingList.importedContacts',
			this.processImported
		);
		this.$on('.pageChanged', (activePages) => {
			this.isTableLoading = true;
			this.paginationParams.activePages = activePages;
			this.paginationParams.from = (activePages[0] - 1) * this.paginationParams.pageSize;
			this.membersVisible = this.staticlistmembers.slice(
				this.paginationParams.from,
				this.paginationParams.pageSize * activePages[activePages.length - 1]
			);
			setTimeout(() => { this.isTableLoading = false; }, 200);
		});

		this.$on('clear-search-query', () => {
			this.isTableLoading = true;
			this.staticlistmembers = this.initResults;
			this.membersVisible = this.staticlistmembers.slice(0, this.paginationParams.pageSize);
			setTimeout(() => { this.isTableLoading = false; }, 200);
		});
		this.$on(['execute-search', 'search-query-changed'], (query) => {
			this.staticlistmembers = this.initResults.filter(({ Source: m }) => {
				const trimmedQuery = query.trim();
				return m.fullname.toString().trim() === trimmedQuery ||
						m.email.toString().trim() === trimmedQuery ||
						m.fullname.toString().split(' ').some(word => word.trim().startsWith(trimmedQuery)) ||
						m.email.toString().split(' ').some(word => word.trim().startsWith(trimmedQuery));
			});
			this.$emit('.pageChanged', [1]);
		});
		this.localization = sc.classes.get('localization.dataProvider');
		this.stateKey = this.registerModule(this.logicalName);
		this.isLoading = false;
	},

	methods: {
		deleteMember(entity) {
			this.staticlistmembers = this.staticlistmembers.filter(item => item.Id !== entity.id);
			this.membersVisible = this.membersVisible.filter(item => item.Id !== entity.id);
			this.initResults = this.initResults.filter(item => item.Id !== entity.id);
			let lastActivePageNumber = this.paginationParams.activePages[this.paginationParams.activePages.length - 1];

			if (this.membersVisible.length === 0 && lastActivePageNumber > 1) {
				if (this.paginationParams.activePages.length > 1) {
					lastActivePageNumber = this.paginationParams.activePages.pop() - 1;
				}
				this.paginationParams.activePages[this.paginationParams.activePages.length - 1] = lastActivePageNumber - 1;
			}
			// recalculate page content
			this.$emit('.pageChanged', this.paginationParams.activePages);
		},
		OnSingleSelectionOnArray(item) {
			if (item) {
				if (!this.staticlistmembers.find(user => user.Id === item.data.Id) && !this.initResults.find(user => user.Id === item.data.Id)) {
					if (this.membersVisible.length < this.paginationParams.pageSize) {
						this.membersVisible.push(item.data);
					}
					this.initResults.push(item.data);
					this.staticlistmembers.push(item.data);
				}
			}
		},
		async save() {
			this.isLoading = true;
			const validationResult = await this.validate(this.stateKey);
			if (!validationResult.isValid) {
				this.showValidationError(validationResult.message);
				this.isLoading = false;
				return;
			}
			const formData = this.$store.state[this.stateKey].entityData;

			try {
				const staticlist = await Create('staticlist', formData);
				const membersData = this.initResults.map((member) => {
					return {
						name: member.Name,
						type: 'staticlistmember',
						staticlist: {
							id: staticlist.staticlistid,
							logicalname: 'staticlist'
						},
						entityreference: {
							id: member.Id,
							logicalname: 'contact'
						}
					};
				});
				SendRequest(
					'/api/MarketingList/CreateMembers',
					'POST',
					membersData
				);
				await showToastOrInfoDialog({
					toastMessageKey: this.options.params.toastMessageKey,
					informationDialogTextKey:
							this.options.params.informationDialogTextKey,
					options: {
						context: this,
						params: [staticlist.name]
					}
				});
				this.isLoading = false;
				this.close();
			} catch (error) {
				this.errorhandler(error);
			}
			if (this.options.params.refreshEvents) {
				this.options.params.refreshEvents.forEach((event) =>
					this.$root.$emit(event)
				);
			}
		},
		openImportDialog() {
			this.$root.$emit('vue.dialog.open', this.options.params.importOptions);
		},
		processImported(records) {
			if (records.length) {
				this.staticlistmembers = this.staticlistmembers.concat(
					records.map(({ body: record }) => ({
						Source: record,
						Name: record.fullname,
						Id: record.id,
						Type: 'contact'
					}))
				);
				this.membersVisible = this.staticlistmembers.slice(0, this.paginationParams.pageSize);
				this.initResults = this.initResults.concat(this.staticlistmembers);
			}
		}
	}
};
</script>
<style src='./staticlists-ms-edit.less' scoped></style>
