<template>
	<div>
		<control-lookup
			v-if="!disableCustomItems"
			:evaluationContext="evaluationContext"
			:options="getLookupOptions()"
		/>

		<div class="filter-content panel-content" v-perfectscroll="{ enable: true }">
			<!-- filter-list-holder -->
			<div class="filter-list-holder">
				<div
					v-if="facets.length == 0"
					v-localization=" { key: 'filtersPanel.unavailable' }"
					class="warning-message"
				/>
				<ul v-if="facets.length > 0" class="filter-list">
					<li
						v-data-attr="{ options, stateNamespace: facet.displayName }"
						v-for="facet in facets"
						:key="facet.count + facet.displayName"
						class="item"
						data-bind="visible: isVisible"
					>
						<label>
							<div class="facet-item-checkbox">
								<span :class="['checkbox bordered', { checked: facet.checked }]">
									<svg class="svg-icon svg-12">
										<use xlink:href="#layout-icons-checkbox-small-checked" class="layout-icons-checkbox-small-checked" />
									</svg>
								</span>
								<input v-model="facet.checked" type="checkbox">
							</div>
							<div :class="['item-info', { selected: facet.checked }]">
								<div class="name">
									<span class="tooltip-wrapper">
										{{ facet.displayName }}
									</span>
								</div>
								<div v-if="!facet.emptyCounter" class="icon">
									<div class="icon-wrapper item-info-count">
										<i>
											(<i>
												{{ facet.count }}
											</i>)
										</i>
									</div>
								</div>
							</div>
						</label>
					</li>
				</ul>
				<include-empty
					v-if="options.isIncludeEmptyVisible"
					v-model="includeEmpty"
					v-data-attr="{ options, stateNamespace: 'empty' }"
					:enabled="enableIncludeEmpty"
				/>
			</div>
		</div>
	</div>
</template>

<script>
/* eslint eqeqeq: 0 */
/* eslint no-mixed-operators: 0 */
import '@/Bindings/ui-attribute.directive';
import helperMethods from '@/Components/ComponentSet/component-set-evaluation-context-helper.methods';
import FilterInfoListsMixins from './filter.info.lists.mixins';
const IncludeEmpty = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './include-empty');
const ControlLookup = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Lookup/control-lookup');

export default {
	name: 'filter-info-panels-list',
	components: {
		IncludeEmpty,
		ControlLookup
	},
	mixins: [FilterInfoListsMixins],
	props: {
		options: Object,
		evaluationContext: Object,
		stateNamespace: String
	},
	data() {
		return {
			facets: [],
			includeEmpty: null
		};
	},
	computed: {
		enableIncludeEmpty() {
			const value = this.facets
				.filter((item) => item.checked)
				.map((item) => item.value);
			return !!value.length;
		}
	},
	created() {
		this.logicalName = helperMethods.eval(this.evaluationContext, this.options.logicalName);
		this.disableCustomItems = helperMethods.eval(this.evaluationContext, this.options.disableCustomItems);
		this.collectionName = helperMethods.eval(this.evaluationContext, this.options.collectionName);

		// todo be sure filter is used only for one entity
		this.entity = this.options.entities[0];

		// do we need computed here
		this.statistic = this.$store.getters[this.stateNamespace + '/getStatistic'](this.entity);

		this.prepareFacets();

		this.filterValue = this.$store.getters[this.stateNamespace + '/getFilterValue']({
			entity: this.entity,
			logicalName: this.logicalName
		});
		if (this.filterValue) {
			this.applyFilterValue(this.filterValue);
			this.includeEmpty = this.filterValue.data.includeEmpty;
		}
		this.$parent.$on(this.stateNamespace + 'resetFilter', this.resetFilter);
	},
	methods: {
		getLookupOptions() {
			return {
				fetchData: (searchQuery, startFrom, pageSize) => {
					const filters = [];

					const options = {
						logicalName: this.collectionName ? `${this.collectionName}.${this.logicalName}` : this.logicalName,
						query: searchQuery

					};
					if (this.options.kind) {
						options.additionalOptions = { kind: this.options.kind };
					}
					const fieldFilter = sc.classes.get('field.filter', options);

					return this.searchAdditionalItems(fieldFilter, filters).then((termFacet) => {
						const data = termFacet.Items.map(facetItem => {
							const formFacetItem = this.formFacets([facetItem], true)[0];

							return {
								displayName: formFacetItem.displayName,
								data: facetItem,
								formFacet: formFacetItem
							};
						});

						return { items: data };
					});
				},
				singleSelectionOnArray: this.onSelectAdditionalItem,
				template: this.options.template
			};
		},
		prepareFacets() {
			/* default setter */
			this.facets = this.readStatistic(this.statistic);
			/* if predefined filter values */
			if (this.options.predefinedFacets) {
				this.options.predefinedFacets.forEach(element => {
					const facet = this.facets.find(el => el.value === element.value.value || el.displayName === element.value.value);
					const enable = helperMethods.eval(this.evaluationContext, element.enable);
					/* only if not founded value and in value with 'enable' prop */
					if (!facet && enable) {
						const value = {
							checked: helperMethods.eval(this.evaluationContext, element.isChecked),
							count: 0,
							displayName: helperMethods.eval(this.evaluationContext, element.value),
							value: helperMethods.eval(this.evaluationContext, element.value),
							emptyCounter: helperMethods.eval(this.evaluationContext, element.emptyCounter),
							enable
						};
						this.facets.push(value);
					}
				});
			}
			/* enable\disable counter after filter value */
			this.facets.map(facet => {
				const empty = ((this.options.predefinedFacets && this.options.predefinedFacets.find(el => el.value.value === facet.value)) || {}).emptyCounter;
				if (empty) facet.emptyCounter = helperMethods.eval(this.evaluationContext, empty);
				return facet;
			});
		}
	},
	async beforeDestroy() {
		const value = this.facets.filter((item) => {
			return item.checked;
		}).map((item) => {
			return item.value;
		});

		const hasValue = () => {
			return value.length || (this.enableIncludeEmpty && this.includeEmpty);
		};
		const wasChanged = () => {
			return (!this.filterValue && hasValue()) ||
					(this.filterValue && !hasValue()) ||
					(this.filterValue && JSON.stringify(value) != JSON.stringify(this.filterValue.data.items) ||
						(this.enableIncludeEmpty && this.filterValue.data.includeEmpty != this.includeEmpty));
		};
		if (wasChanged()) {
			const filter = await this.getSearchFilter(this.logicalName);
			this.emitFilterChanged(filter);
		}
	}
};
</script>
<style src="./panels.less" scoped></style>
