<template>
	<div class="reportingservice-dialog">
		<div class="reportingservice-overlay loading-overlay large" v-if="isLoading || isInitialization || isInitializationMCI">
			<div class="initialization-message p13" v-if="isInitialization">Your authorization to Composer Online is in progress. Please wait.</div>
			<div class="initialization-message p13" v-else-if="isInitializationMCI">Your authorization to MailChimp is in progress. Please wait.</div>
		</div>
		<div class="reportingservice-overlay error-overlay" v-else-if="isServerError">
			<div class="initialization-message p13">Couldn't sign in to Composer Online. Please try again later.</div>
		</div>
		<template v-else>
			<div class="main-panel" v-show="templatelistComponent === null">
				<div class="left-panel with-preview">
					<div v-if="isElementListEmpty">
						<div class="group-title" v-localization="{key: 'dialogues.RS4.reports.elements'}"></div>
						<div class="no-elements" v-localization="{key: 'dialogues.RS4.reports.noelements'}"></div>
					</div>
					<div class="entities detail" v-if="!isElementListEmpty && activeGroup">
						<div class="entities-list">
							<div class="records-wrapper">
								<div class="entity-title">
									<div class="back">
										<div class="control-button tooltip dialog-back-hover-parent clickable" @click="setActiveGroup(null)">
											<svg class="svg-icon svg-12 back-button">
												<use xlink:href="#layout-icons-details-header-back"></use>
											</svg>
										</div>
									</div>
									<div class="title tooltip">
										{{activeGroup.entity}}
									</div>
									<div class="count">{{activeGroup.items.length}}</div>
									<div class="clear-all" @click.stop="activeGroup.removeAll(activeGroup)">
										<span class="clear clickable" v-localization="{key: 'dialogues.RS4.reports.clearAll'}"></span>
									</div>
								</div>
								<div v-perfectscroll="{enable: true}" v-if="activeGroup.items.length">
									<div class="entity-data">
										<div class="record" v-for="item in activeGroup.items" :key="item.id">
											<control :name="activeGroup.template.$type"
													 :contentProps.once="activeGroup.template"
													 :evaluationContext="createEvaluationContext(item)">
											</control>
											<div class="tooltip delete-control" @click="activeGroup.remove(activeGroup, item)">
												<svg class="svg-icon clickable">
													<use xlink:href='#layout-icons-dialog-close'></use>
												</svg>
											</div>
										</div>
									</div>
								</div>
								<div class="entity-overlay" v-show="!activeGroup.matchedToTemplate || (activeGroup.itemsCount > activeGroup.itemsLimitToGenerateCO && selectedTemplate.isCO)"></div>
								<div class="entity-overlay-text" v-show="!activeGroup.matchedToTemplate"
									 v-localization="{key: 'dialogues.RS4.recordsDontMatch'}"></div>
								<div class="entity-overlay-text" :class="{'offset': !activeGroup.matchedToTemplate}" v-show="activeGroup.itemsCount > activeGroup.itemsLimitToGenerateCO && selectedTemplate.isCO"
									 v-localization="{key: 'dialogues.RS4.coReachedLimitOfRecord'}"></div>
							</div>
						</div>
					</div>
					<div v-if="!isElementListEmpty && !activeGroup">
						<div class="group-title" v-localization="{key: 'dialogues.RS4.reports.elements'}"></div>
						<div class="entities groups" v-perfectscroll="{enable: true}">
							<div class="entities-list" v-if="recordsList.length" v-for="item in recordsList" :key="item.entityName">
								<div class="records-wrapper group" v-if="item.items.length">
									<div class="entity-title clickable" @click="setActiveGroup(item)">
										<div class="title tooltip">
											{{item.entity}}
										</div>
										<div class="count">{{item.items.length}}</div>
										<div class="clear-all" @click.stop="item.removeAll(item)">
											<span class="clear tooltip" v-localization="{key: 'dialogues.RS4.reports.clearAll'}"></span>
										</div>
									</div>
									<div class="entity-overlay-text" v-show="!item.matchedToTemplate" v-localization="{key: 'dialogues.RS4.recordsDontMatch'}"></div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="editor-component" v-show="!templatelistComponent && !isGenerating">
					<component v-perfectscroll="{ enable: true }"
							   :is="editorComponent"
							   :key="templateKey"
							   v-if="editorOptions.selectedFormat"
							   :options="editorOptions"
							   :selectedTemplate="selectedTemplate"
							   :stateKey="stateKey"
							   :totalCOTemplates="totalCOTemplates"
							   @close="close"
							   @openTemplateList="openTemplateList">
					</component>
				</div>
				<div class="reportingservice-overlay loading-overlay large" v-if="isGenerating"></div>
			</div>
			<reportingservice-uiw-templateslist v-if="templatelistComponent" :options="templatelistComponent"></reportingservice-uiw-templateslist>
		</template>
	</div>
</template>
<script>
import helperMethods from '@/Components/ComponentSet/component-set-evaluation-context-helper.methods';
import Control from '@/Components/Entity/control';
import MsEditorState from '@/States/modules/ms-editor-state';
import { GetUserInfo } from '@/Data/Auth/authentication-service';
import dataProvider from '@/Data/DataProviders/reportingserviceDataprovider';
import coDataProvider from '@/Data/DataProviders/coDataprovider';
import coAuthDataProvider from '@/Data/DataProviders/coAuthDataprovider';
import {
	authenticate as authenticateMCI, getMCIUrlSwagger, getMCIUserInfo
} from '@/Data/DataProviders/coMCIDataprovider';
import RS4Utils from './reportingservice.Utils';
import { GetPerConfig, SetPerConfig } from '@/Data/DataProviders/userStorageDataProvider';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';
import {
	resourceIsRichable, isNullOrUndefined, SearchByIds, Search, Create, Update
} from '@acx-xms/data-functions/dist';
const reportingserviceUiwEditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './reportingservice-uiw-editor');
const reportingserviceMciEditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './reportingservice-mci-editor');
const reportingserviceUiwTemplateslist = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './reportingservice-uiw-templateslist');

export default {
	name: 'reportingservice-uiw-dialog',
	props: { options: Object },
	components: {
		Control,
		reportingserviceUiwEditor,
		reportingserviceMciEditor,
		reportingserviceUiwTemplateslist
	},
	data() {
		return {
			settings: void 0,
			fieldsSettings: [],
			selectedFormat: null,
			isGenerating: false,
			isLoading: true,
			isServerError: false,
			selectedTemplate: {},
			selectedTemplateEntity: null,
			templatelistComponent: null,
			recordsList: [],
			editorOptions: {},
			activeGroup: null,
			dataItems: null,
			isInitialization: false,
			isInitializationMCI: false,
			editorComponent: this.options.params.editorComponent || 'reportingservice-uiw-editor'
		};
	},
	computed: {
		isAddAvailable() {
			return !this.isGenerating;
		},
		isElementListEmpty() {
			let result = this.recordsList.length;
			if (!result) {
				this.$emit('isElementListEmpty', true);
				return true;
			}
			result = this.recordsList.every(record => {
				return !record.items.length;
			});
			this.$emit('isElementListEmpty', result);

			return result;
		},
		templateKey() {
			return this.selectedTemplate.templateName;
		}
	},
	watch: {
		selectedTemplate: {
			async handler(template) {
				const formats = (template.targetOutputFormats || template.properties.targetOutputFormats).split(',').filter(item => item.toLowerCase() !== 'html' && item.toLowerCase() !== 'printable'); // removing html from available formats

				this.availableFormatTranslations = [];
				for (const item of formats) {
					const translatedItem = this.allAvailableFormatTranslations.find(i => i.value === item) || {
						displayName: item,
						value: item
					};
					translatedItem && this.availableFormatTranslations.push(translatedItem);
				}
				this.$set(this.editorOptions, 'availableFormatTranslations', this.availableFormatTranslations);
				const findSelectedFormat = (format) => {
					return this.availableFormatTranslations.find(item => item.value === format) || this.availableFormatTranslations[0];
				};

				if (this.defaultSelectedFormat) {
					this.selectedFormat = findSelectedFormat(this.defaultSelectedFormat);
					this.defaultSelectedFormat = null;
				} else {
					this.selectedFormat = findSelectedFormat('pdf');
				}

				this.$set(this.editorOptions, 'selectedFormat', this.selectedFormat);
				this.selectedTemplateEntity = template.inputTypes || template.properties.inputTypes;
			}
		},
		selectedTemplateEntity(logicalName) {
			this.recordsList.forEach(item => {
				const result = item.entityName === logicalName;
				item.matchedToTemplate = result;
				item.isVisible = result;
			});
		},
		isAddAvailable(val) {
			if (val && this.addItemsQueue.length) {
				this.addItemsQueue.forEach(items => {
					this.addItems(items);
				});
				this.addItemsQueue = [];
			}
		}
	},
	async created() {
		this.isLoading = true;
		this.logicalname = 'reports';
		this.stateKey = `editor.${this.logicalname}`;
		if (!this.$store.state[this.stateKey]) {
			this.$store.registerModule(this.stateKey, MsEditorState);
		}
		this.userDataProvider = sc.classes.get('user.dataProvider');
		this.localizationDataProvider = sc.classes.get('localization.dataProvider');
		this.userDefaultdParameters = void 0;
		this.addItemsQueue = [];
		this.availableFormatTranslations = [];
		this.allAvailableFormatTranslations = [];
		this.defaultSelectedFormat = null;

		this.firstAddedItemLogicalName = this.options.params.data[0].logicalname || this.options.params.data[0].Type;
		this.$on('isGenerating', this.onGenerationChange);
		const getTranslations = async () => {
			for (const item of ['pdf', 'pptx', 'html', 'printable']) {
				const format = {
					// TODO: find a better way to handle localization in watchers
					displayName: (await this.localizationDataProvider.getLabelForCurrentLanguage('dialogues.RS4.format.' + item)) || item,
					value: item
				};
				this.allAvailableFormatTranslations.push(format);
			}
		};

		await Promise.all([this.init(), getTranslations()]);
	},
	beforeDestroy() {
		this.$store.unregisterModule(this.stateKey);
		this.$off('isGenerating', this.onGenerationChange);

		window.onstorage = null;
	},
	methods: {
		createEvaluationContext(record = {}) {
			return createEvaluationContext(record);
		},
		openTemplateList() {
			this.templatelistComponent = {
				settings: this.settings,
				recordsList: this.recordsList,
				onClose: () => {
					this.templatelistComponent = null;
				},
				onSelect: (template) => {
					const selectedTemplate = {
						...template,
						...{ logicalname: this.logicalname }
					};
					if (template.templateIds) {
						selectedTemplate.colors = template.templateIds.map(item => item.color);
					}
					this.selectedTemplate = selectedTemplate;
					this.templatelistComponent = null;
				},
				isManager: this.userInfo.CRMRoles.some(role => role.Name === 'OHC_MANAGER'),
				isMci: this.options.params.withMCIAuth
			};
		},
		async init() {
			const resourcesPromiseArr = [resourceIsRichable(await coAuthDataProvider.getCOAuthURLSwagger())];
			if (this.options.params.withMCIAuth) {
				resourcesPromiseArr.push(resourceIsRichable(await getMCIUrlSwagger()));
			}
			let [coAuthIsRichable, mciAuthIsRichable] = await Promise.all(resourcesPromiseArr);
			mciAuthIsRichable = isNullOrUndefined(mciAuthIsRichable) ? true : mciAuthIsRichable; // because we may have no mciRichable promise
			if (!coAuthIsRichable || !mciAuthIsRichable) {
				this.isServerError = true;
				this.isLoading = false;
				return;
			}
			let token;

			[token, this.userInfo] = await Promise.all([GetPerConfig('co-auth-key'), GetUserInfo()]);
			const getFirstTemplate = async (refresh, includeThumbnail = true) => {
				try {
					const { totalCount, htmlTemplates: CoTemplate } = await coDataProvider.searchTemplates({
						offset: 0,
						limit: 1,
						mainEntitiesFilter: { values: [this.firstAddedItemLogicalName] },
						searchType: 'ALL',
						templateTypeFilter: {
							exactMatch: true,
							values: this.options.params.withMCIAuth ? ['email'] : ['html', 'printable']
						}

					}, includeThumbnail);
					await this._authenticateMCI();
					await this.setItems(this.options.params.data);
					this.totalCOTemplates = totalCount;
					await this.setDefaultData(CoTemplate.length && CoTemplate[0]);
				} catch (err) {
					if (refresh && (err.toString() === 'Error: JWT token is absent or invalid' || err.message === 'JWT token is absent or invalid')) {
						this.isInitialization = true;
						const refreshSuccesfull = await this.refreshToken();
						refreshSuccesfull && await this._authenticateMCI();
						await getFirstTemplate(false, true);
						this.isInitialization = false;
					} else {
						this.isServerError = true;
						this.isLoading = false;
					}
				}
			};

			if (token) {
				await getFirstTemplate(true, true);
			} else {
				this.isInitialization = true;
				await this.processCoAuth().then(async () => {
					await getFirstTemplate(true, true).then(() => {
						this.isInitialization = false;
					});
				});
			}
			window.onstorage = async () => {
				this.isInitialization = false;
				const newToken = await GetPerConfig('co-auth-key');
				if (newToken && token !== newToken) {
					token = newToken;
					getFirstTemplate(false, true);
				} else if (newToken === 'undefined') {
					this.isServerError = true;
					this.isLoading = false;
					window.onstorage = null;
				}
			};
		},

		async refreshToken() {
			try {
				await coAuthDataProvider.refreshToken();
				return true;
			} catch {
				await this.processCoAuth();
				return false;
			}
		},
		async processCoAuth() {
			if (this.options.params.withMCIAuth) {
				const res = await Search('systemuserserviceaccount', [
					sc.classes.get('offsetSize.filter', 1),
					sc.classes.get('termFacet.filter', {
						logicalName: 'systemuserid.id',
						query: [this.userInfo.systemuserid]
					}).fillQuery(),
					sc.classes.get('termFacet.filter', {
						logicalName: 'serviceaccounttype.id',
						query: ['b08a0bf8-d86b-4a16-b3c8-65846f74ffcc']
					}).fillQuery(),
					sc.classes.get('termFacet.filter', {
						logicalName: 'inuse',
						query: [true]
					}).fillQuery()
				]);
				if (res.Total) {
					this.systemuserserviceaccount = res.Results[0];
					await this.authenticate(false);
				} else {
					await this.authenticate(this.options.params.withMCIAuth);
				}
			} else {
				await this.authenticate(this.options.params.withMCIAuth);
			}
		},
		async authenticate(isWithMci) {
			await coAuthDataProvider.authenticate(`${window.location.origin}/${this.$route.params.clusterKey}/${this.$route.params.config}/coauth`, isWithMci);
		},
		async _authenticateMCI() {
			if (!this.options.params.withMCIAuth || this.systemuserserviceaccount) {
				return Promise.resolve();
			}
			this.isInitializationMCI = true;

			try {
				this.mciUser = JSON.parse(await authenticateMCI());
				await this.processMCIUser();

				this.isInitializationMCI = false;
				this.isServerError = false;
			} catch (e) {
				try {
					await this.authenticate(true);
				} catch (e) {
					if (e.message === 'pop-ups are blocked') {
						this.$parent.$emit('close');
						sc.events.emit('vue.dialog.info', {
							title: 'Start Email Campaign',
							message: 'Please, allow pop-ups on in the browser\'s settings and re-open the window to continue',
							showFooter: false
						});
					}
				}
			}
		},
		async setItems(data) {
			this.recordsList = [];
			await this.addItems(data);
		},
		async addItems(data) {
			if (!this.isAddAvailable) {
				this.addItemsQueue.push(data);
				return;
			}
			this.dataItems = data;
			try {
				const mappedItems = await this.mapItems(data);
				const selectedTemplateEntity = this.selectedTemplateEntity;

				if (mappedItems.length) {
					const grouped = _.groupBy(mappedItems, (item) => {
						return item.logicalname;
					});
					const checkForEmpty = list => {
						if (!list.items.length) {
							this.setActiveGroup(null);
						}
					};
					const promises = Object.entries(grouped).map(async ([key, items]) => {
						this.recordsList.push({
							matchedToTemplate: selectedTemplateEntity === key || !selectedTemplateEntity,
							removeAll: (item) => {
								this.setActiveGroup(null);
								item.items = [];
								item.itemsCount = 0;
								checkForEmpty(item);
							},
							remove: (group, item) => {
								group.items = group.items.filter(itemVal => itemVal.id !== item.id);
								item.itemsCount--;
								checkForEmpty(group);
							},
							entityName: key,
							entity: await this.getDisplayName(key),
							items,
							isVisible: true
						});
					});
					await Promise.all(promises);
				}
				this.recordsList.forEach(list => {
					const result = (selectedTemplateEntity === list.entityName) || !selectedTemplateEntity;
					list.matchedToTemplate = result;
					list.isVisible = result;
					list.itemsCount = list.items.length;
					list.itemsLimitToGenerateCO = 50;
				});

				if (this.recordsList.length === 1) {
					this.setActiveGroup(this.recordsList[0]);
				} else {
					this.setActiveGroup(null);
				}
			} catch (err) {
				console.log(err);
			}
		},
		async getEntityTemplate(entity, usage, subtype) {
			return await sc.classes.get('entityConfiguration.dataProvider').getTemplate(entity, usage, subtype);
		},

		async setDefaultData(coTemplate) {
			this.settings = this.options.params.settings;
			this.languages = this.localizationDataProvider.getAllLanguages();
			const [reportingDefaultParams, editorTemplate] = await Promise.all([
				GetPerConfig(dataProvider.reportingDefaultParamsKey),
				this.getEntityTemplate(this.settings.editorTemplate.entity, this.settings.editorTemplate.usage, this.settings.editorTemplate.subtype)
			]);
			this.reportingDefaultParams = this.unwrapDefaultData(reportingDefaultParams);
			this.userDefaultdParameters = reportingDefaultParams;

			try {
				let currentTemplate;
				if (reportingDefaultParams && reportingDefaultParams[this.firstAddedItemLogicalName] && this.options.params.settings.editorTemplate.subtype === 'generate-report') {
					if (reportingDefaultParams[this.firstAddedItemLogicalName].isCO) {
						currentTemplate = await coDataProvider.getTemplateById(reportingDefaultParams.templateId);
						coDataProvider.getTemplateImageById(reportingDefaultParams.templateId, currentTemplate.templateName)
							.then(template => this.$root.$emit('co.thumbnails.loaded', [template]));
					} else {
						currentTemplate = await this.getDataFromOldCOmposer();
					}
				} else {
					if (coTemplate) {
						currentTemplate = coTemplate;
					} else {
						currentTemplate = await this.getDataFromOldCOmposer();
					}
				}

				if (currentTemplate) {
					const selectedTemplate = {
						...currentTemplate,
						...{ logicalname: this.logicalname }
					};
					this.selectedTemplate = selectedTemplate;

					if (reportingDefaultParams) {
						const defVal = reportingDefaultParams[this.firstAddedItemLogicalName];
						this.defaultSelectedFormat = defVal && defVal['output-format'];
					}
				}

				this.selectedTemplateEntity = this.selectedTemplate.inputTypes;
				// Unwrap saved as default data
				if (this.userDefaultdParameters) {
					editorTemplate.content.item.forEach(async (field) => {
						if (this.userDefaultdParameters[field.control.value.field]) {
							await this.$store.dispatch(this.stateKey + '/setField', {
								name: field.control.value.field,
								value: this.userDefaultdParameters[field.control.value.field]
							});
						}
					});
				}

				this.editorOptions = {
					recordsList: this.recordsList,
					settings: this.settings,
					editorTemplate,
					selectedFormat: this.selectedFormat,
					availableFormatTranslations: this.availableFormatTranslations,
					userDefaultdParameters: this.userDefaultdParameters,
					firstAddedItemLogicalName: this.firstAddedItemLogicalName,
					...(this.systemuserserviceaccount && { systemuserserviceaccount: this.systemuserserviceaccount })
				};
			} catch (err) {
				await this.handleSearchError(err, this.reportingDefaultParams.templateId, () => this.setDefaultData(coTemplate));
			} finally {
				this.isLoading = false;
				this.isServerError = false;
			}
		},
		async processMCIUser() {
			if (this.systemuserserviceaccount) {
				return Promise.resolve();
			}
			if (!this.mciUser) {
				this.mciUser = await getMCIUserInfo();
			}
			const res = await Search('systemuserserviceaccount', [
				sc.classes.get('offsetSize.filter', 1),
				sc.classes.get('termFacet.filter', {
					logicalName: 'serviceaccountuserid',
					query: [this.mciUser.mcUserId]
				}).fillQuery(),
				sc.classes.get('termFacet.filter', {
					logicalName: 'systemuserid.id',
					query: [this.mciUser.userId]
				}).fillQuery(),
				sc.classes.get('termFacet.filter', {
					logicalName: 'serviceaccounttype.id',
					query: ['b08a0bf8-d86b-4a16-b3c8-65846f74ffcc']
				}).fillQuery(),
				sc.classes.get('selectedFields.filter', [
					{ logicalname: 'inuse' }
				]).fillQuery()
			]);

			if (res.Total) {
				if (res.Results[0].Source.inuse) {
					this.systemuserserviceaccount = res.Results[0];
				} else {
					this.systemuserserviceaccount = await Update('systemuserserviceaccount', res.Results[0].Id, { inuse: true });
				}
			} else {
				this.systemuserserviceaccount = await Create('systemuserserviceaccount', {
					name: this.mciUser.mcName || this.userInfo.firstname + this.userInfo.lastname,
					systemuserid: {
						id: this.mciUser.userId,
						logicalname: 'systemuser'
					},
					serviceaccountuserid: this.mciUser.mcUserId,
					email: this.mciUser.email,
					firstname: this.userInfo.firstname,
					lastname: this.userInfo.lastname,
					serviceaccounttype: {
						id: 'b08a0bf8-d86b-4a16-b3c8-65846f74ffcc', // MailChimp
						logicalname: 'lookupserviceaccounttype'
					},
					inuse: true
				});
			}
		},
		async getDataFromOldCOmposer() {
			let template;
			let selectedFormat = null;
			const request = {
				limit: 2,
				nameFilter: {
					exactMatch: true,
					values: []
				},
				offset: 0
			};

			const reportingDefaultReportName = this.userDefaultdParameters && this.userDefaultdParameters[this.firstAddedItemLogicalName] ? this.userDefaultdParameters[this.firstAddedItemLogicalName]['report-name'] : null;

			if (reportingDefaultReportName && reportingDefaultReportName.length) {
				request.nameFilter.values.push(reportingDefaultReportName);
			}

			const templates = request.nameFilter.values.length === 0
				? {
					templates: [],
					totalCount: 0
				}
				: await dataProvider.searchTemplates(request);
			if (this.userDefaultdParameters && templates.templates.length) {
				template = this.getTemplateByName(templates, reportingDefaultReportName);
				selectedFormat = this.userDefaultdParameters['output-format'];
				if (selectedFormat) {
					this.selectedFormat = this.availableFormatTranslations.find(item => item.value === selectedFormat) || this.availableFormatTranslations[0];
				}
			}
			const currentTemplate = template || await this.getDefaultTemplate();
			if (currentTemplate && currentTemplate.templateIds) {
				currentTemplate.colors = currentTemplate.templateIds.map(item => item.color);
			}
			return currentTemplate;
		},
		unwrapDefaultData(props) {
			if (props && props[this.firstAddedItemLogicalName]) {
				props['report-name'] = props[this.firstAddedItemLogicalName]['report-name'];
				props['output-format'] = props[this.firstAddedItemLogicalName]['output-format'];
			};
			return props;
		},
		async getDefaultTemplate() {
			const request = {
				limit: 1,
				offset: 0,
				propertiesFilters: []
			};

			request.propertiesFilters.push(
				{
					exactMatch: false,
					name: RS4Utils.filterMapping.inputType.targetPath,
					values: [this.firstAddedItemLogicalName]
				}
			);
			try {
				const templates = await dataProvider.searchTemplates(request);
				if (templates && templates.templates && templates.templates.length) {
					return templates.templates[0];
				} else {
					return null;
				}
			} catch (err) {
				await this.handleSearchError(err);
			}
		},
		getTemplateByName(templates, templateName) {
			if (!templates && templates.templates) {
				return null;
			}
			return templates.templates.find(template => {
				return template.templateName === templateName;
			});
		},
		async setActiveGroup(data) {
			if (data) {
				data.template = await this.getEntityTemplate(data.items[0].logicalname, 'custom', 'reporting-records-list');
			}

			this.activeGroup = data;
		},

		async mapItems(data) {
			const items = data || [];
			const result = [];
			const searchItems = items.filter(item => {
				return !item.Source;
			});
			let searchResults;
			if (searchItems && searchItems.length) {
				searchResults = await SearchByIds(searchItems[0].logicalname, searchItems.map(item => item.id));
				searchResults = searchResults.Results;
			} else {
				searchResults = items;
			}
			if (searchResults && searchResults.length) {
				searchResults.forEach(item => {
					const mappedItem = this.mapItem(item);
					result.push(mappedItem);
				});
			}
			return result;
		},

		mapItem(entity) {
			const entityRef = sc.classes.get('entityReference', entity);
			entityRef.Source = entity.Source;

			return entityRef;
		},

		async getDisplayName(logicalName, number) {
			const names = await sc.classes.get('entityConfiguration.dataProvider').getEntityCaptions([logicalName], number || 'plural', true);
			// TODO: remove eval after getEntityCaptions method is refactored
			return helperMethods.eval(null, (names[0] || logicalName));
		},
		async handleSearchError(errorObject, templateId = null, errorThrowerFn = null) {
			if (!errorObject.status) {
				console.log(JSON.stringify(errorObject));
			}
			switch (errorObject.status) {
			case 404:
				if (templateId && errorObject.message.includes(templateId) && errorThrowerFn) {
					await Promise.all([
						SetPerConfig(dataProvider.reportingDefaultParamsKey, null),
						errorThrowerFn()
					]);
					return;
				}
				sc.utils.errorMessage.byMessage(this.localizationDataProvider.localize('dialogues.RS4.reports.NoValidURLError'));
				break;
			case 401:
				RS4Utils.parseComposerError(errorObject);
				break;
			default:
				sc.utils.errorMessage.byMessage(this.localizationDataProvider.localize('dialogues.RS4.somethingWentWrong'));
			}
			this.close();
		},
		onGenerationChange(value) {
			this.isGenerating = value;
		},
		close() {
			this.options.closeCallback && this.options.closeCallback();
			if (this.options.params.refreshEvents) {
				this.options.params.refreshEvents.forEach(e => this.$root.$emit(e));
			}
			this.$parent.$emit('close');
		}
	}
};
</script>
<style src="./reportingservice-uiw-dialog.less" scoped></style>
