<template>
	<tree ref="tree" :data="treeData" :options="treeOptions" v-perfectscroll="{ enabled:true, addMouseoverEvent: true, onceMouseover: false }" class="folder-tree-main">
		<span class="tree-text" slot-scope="{ node }">
			<template v-if="node.children.length && !node.expanded()">
				<svg class="svg-icon-folder">
					<use xlink:href="#layout-icons-folder-plus"></use>
				</svg>
			</template>
			<template v-if="node.children.length && node.expanded()">
				<svg class="svg-icon-folder">
					<use xlink:href="#layout-icons-folder-minus"></use>
				</svg>
			</template>
			<template>
				<svg class="layout-icons-folder">
					<use :xlink:href="[node.expanded() ? '#layout-icons-folder-opened' : '#layout-icons-folder-closed']"></use>
				</svg>
				<p :class="[node.data.active ? 'p2':'p13']">{{ node.text }}</p>
			</template>
		</span>
	</tree>
</template>
<script>
import LiquorTree from 'liquor-tree';
import { Search } from '@acx-xms/data-functions/dist';
import MsFolderState from '@/States/modules/ms-folder-state';
import { GetUserInfo } from '@/Data/Auth/authentication-service';

export default {
	name: 'folder-tree-main',
	props: {
		rootName: String,
		configTreeFilters: Array,
		srNamespace: {
			type: String,
			required: true
		},
		namespace: {
			type: String,
			required: true
		},
		logicalname: {
			type: String,
			required: true
		},
		treeLogicalName: {
			type: String,
			required: true
		},
		watchSearchResults: Boolean
	},
	data() {
		return {
			treeData: [],
			foldersSr: [],
			stateNamespace: `${this.namespace}.folder-tree`,
			currentActiveId: null,
			treeOptions: { parentSelect: true }
		};
	},
	components: { tree: LiquorTree },
	computed: {
		state() {
			return this.$store.state[this.stateNamespace];
		},
		folderTree() {
			return this.state && this.state.folderTree;
		},
		breadcrumbs() {
			return this.state && this.state.breadcrumbs;
		},
		storeFoldersSr() {
			return this.state && this.state.foldersSr;
		}
	},
	watch: {
		treeData: {
			handler: function (newVal) {
				this.$refs.tree.setModel(newVal);
				this.$store.commit(`${this.stateNamespace}/setFolderTree`, newVal);
				this.$store.commit(`${this.stateNamespace}/setFolderSearchResults`, this.foldersSr);
			},
			deep: true
		}
	},
	async created() {
		if (!this.state) {
			this.$store.registerModule(this.stateNamespace, MsFolderState);
		}
		this.$parent.$on(this.srNamespace + '.componentSetChanged', () => {
			this.clearActiveNodes(this.treeData);
		});

		this.$parent.$on(this.srNamespace + '.resultsChanged', async () => {
			await this.updateTree();
		});

		if (this.folderTree) {
			this.treeData = this.folderTree;
			this.foldersSr = this.storeFoldersSr;
		} else {
			if (!this.watchSearchResults) {
				this.init();
				this.$store.commit(`${this.stateNamespace}/setBreadcrumbs`, [
					{
						data: { folderId: null },
						text: this.treeData[0].text
					}
				]);
			}
		}
		this.$emit('setBreadcrumbs', this.breadcrumbs);
		this.$root.$on(this.srNamespace + '.setActiveFolder', this.onSetActiveFolder);
		this.$root.$on(['entity.created', 'entity.changed'], this.checkForUpdateFolder);
		await this.updateTree();
	},
	mounted() {
		this.$refs.tree.$on('node:clicked', this.onClick);
		this.$refs.tree.$on('node:expanded', this.onExpand);
		this.$refs.tree.$on('node:collapsed', this.onCollapse);
	},
	beforeDestroy() {
		this.$root.$off(this.srNamespace + '.setActiveFolder', this.onSetActiveFolder);
		this.$root.$off(['entity.created', 'entity.changed'], this.checkForUpdateFolder);
	},
	methods: {
		async init() {
			this.treeData = [{
				data: {
					folderId: null,
					active: true,
					callback: (stateNamespace) => {
						this.$store.commit(stateNamespace + '/addOrUpdateFilter', {
							entity: this.logicalname,
							logicalName: 'parentfolder.id',
							filter:
									sc.classes.get('termFacet.filter', {
										logicalName: 'parentfolder.id',
										query: [null]
									}).fillQuery()
						});
					}
				},
				text: this.rootName || 'My Documents',
				state: { expanded: true },
				expanded() {
					return this.state.expanded;
				}
			}];
			await this.buildTree();
			this.fillTree(this.treeData);
		},
		async buildTree(isdescending = false) {
			const userInfo = await GetUserInfo();
			const filters = [
				...this.configTreeFilters,
				sc.classes.get('offsetFrom.filter', 0),
				sc.classes.get('offsetSize.filter', 9999),
				sc.classes.get('selectedFields.filter', [
					{ logicalname: 'title' },
					{ logicalname: `${this.treeLogicalName}id` },
					{ logicalname: 'parentfolder' }
				]).fillQuery(),
				sc.classes.get('termFacet.filter', {
					logicalName: 'recordstate.id',
					query: ['8d113fa8-3015-4060-a107-14cedcd19dd3']
				}).fillQuery(),
				sc.classes.get('orderBy.filter', {
					query: [{
						logicalName: 'title',
						value: isdescending
					}]
				})
			];

			this.treeLogicalName !== 'sharedfolder' && filters.push(sc.classes.get('termFacet.filter', {
				logicalName: 'ownerid.id',
				query: [userInfo.systemuserid]
			}).fillQuery());
			const { Results } = await Search(
				[this.treeLogicalName],
				filters
			);
			this.foldersSr = Results.map(result => {
				return {
					...result.Source,
					Id: result.Id,
					Type: result.Type
				};
			});
		},
		fillTree(arr) {
			if (!arr.length) {
				return;
			}
			let children = [];
			arr.forEach(folder => {
				children = this.filterFolder(folder.data.folderId);
				this.$set(folder, 'children', children);
				this.fillTree(folder.children);
			});
		},
		filterFolder(parentId) {
			const callback = (stateNamespace, item) => {
				this.$store.commit(stateNamespace + '/addOrUpdateFilter', {
					entity: this.logicalname,
					logicalName: 'parentfolder.id',
					filter:
							sc.classes.get('termFacet.filter', {
								logicalName: 'parentfolder.id',
								query: [item[`${this.treeLogicalName}id`]]
							}).fillQuery()
				});
			};
			if (!parentId) { // root folder
				return this.foldersSr.filter(item => !item.parentfolder).map(item => {
					return {
						text: item.title,
						data: {
							folderId: item[`${this.treeLogicalName}id`],
							parentId: null,
							callback: (stateNamespace) => {
								callback(stateNamespace, item);
							}
						}
					};
				});
			}
			return this.foldersSr.filter(item => item.parentfolder && item.parentfolder.id === parentId).map(item => {
				return {
					text: item.title,
					data: {
						folderId: item[`${this.treeLogicalName}id`],
						parentId: item.parentfolder.id,
						callback: (stateNamespace) => {
							callback(stateNamespace, item);
						}
					}
				};
			});
		},
		searchTree(element, matchingId) {
			if (element.data.folderId === matchingId) {
				return element;
			} else if (element.children != null) {
				let result = null;
				for (let i = 0; result == null && i < element.children.length; i++) {
					result = this.searchTree(element.children[i], matchingId);
				}
				return result;
			}
			return null;
		},
		onSetActiveFolder(node) {
			const result = this.searchTree(this.treeData[0], node.data.folderId);

			if (!result) return;

			this.onClick(result);
			this.expandParent(result.data.parentId);
		},
		setActive(id) {
			const result = this.searchTree(this.treeData[0], id);
			this.$set(result.data, 'active', true);
			this.$store.commit(`${this.stateNamespace}/setBreadcrumbs`, [
				result,
				...this.breadcrumbs
			]);
			if (result.data.hasOwnProperty('parentId')) {
				this.setActive(result.data.parentId);
			}
		},
		expandParent(parentId) {
			if (parentId) {
				const parent = this.searchTree(this.treeData[0], parentId);
				this.$set(parent, 'state', { expanded: true });
				this.expandParent(parent.data.parentId);
			}
		},
		setExpanded(node) {
			const result = this.searchTree(this.treeData[0], node.data.folderId);
			this.$set(result, 'state', { expanded: node.expanded() });
		},
		setCollapsed(node) {
			const result = this.searchTree(this.treeData[0], node.data.folderId);
			this.$set(result, 'state', { collapsed: node.collapsed() });
		},
		clearActiveNodes(arr) {
			if (!arr || !arr.length) {
				return;
			}
			arr.forEach(folder => {
				this.$set(folder.data, 'active', false);
				this.clearActiveNodes(folder.children);
			});
		},
		onClick(node) {
			const menuPopupKey = this.$store.getters.menuPopupKey;
			if (menuPopupKey) {
				this.$root.$emit(menuPopupKey + '.absoluteMenu.close');
			}
			this.currentActiveId = node.data.folderId;
			this.processClick(node);
			this.$emit('treeClicked', node, this.srNamespace);
			this.createLookupPath(node);
		},
		createLookupPath(node) {
			const searchResult = this.foldersSr.find(result => result.Id === node.data.folderId);

			const predefinedParent = searchResult
				? {
					id: searchResult.Id,
					logicalname: searchResult.Type
				}
				: null;

			this.$store.commit(`${this.stateNamespace}/setPredefinedParent`, predefinedParent);
		},
		processClick(node) {
			this.$store.commit(`${this.stateNamespace}/setBreadcrumbs`, []);
			this.clearActiveNodes(this.treeData);
			this.setActive(node.data.folderId);
			this.$emit('setBreadcrumbs', this.breadcrumbs);
		},
		onExpand(node) {
			this.setExpanded(node);
		},
		onCollapse(node) {
			this.setCollapsed(node);
		},
		setDefaultBread() {
			this.$store.commit(`${this.stateNamespace}/setBreadcrumbs`, [
				{
					data: { folderId: null },
					text: this.treeData[0].text
				}
			]);
			this.$emit('setBreadcrumbs', this.breadcrumbs);
		},
		async updateTree() {
			await this.init();
			if (this.currentActiveId) {
				const result = this.searchTree(this.treeData[0], this.currentActiveId);
				this.processClick(result);
				this.expandParent(this.currentActiveId);
			} else {
				this.setDefaultBread();
			}
		},
		async checkForUpdateFolder({ entity, isReactivation = false }) {
			if ((this.treeLogicalName === entity.logicalname) || (isReactivation && this.logicalname === entity.logicalname)) {
				await this.updateTree();
			}
		}

	}
};
</script>
<style src="./folder-tree.less" scoped></style>
