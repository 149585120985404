<template>
	<!--tooltip-->
	<div class="color-set" v-if="visible">
		<div class="color-item" v-if="colors" v-for="(color, index) in colors" :key="index" :style="{ 'background-color': color }"></div>
	</div>
</template>
<script>
export default {
	name: 'control-color-set',
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return {
			visible: this.options.visible !== void 0 ? this.evaluationContext.eval(this.options.visible) : true,
			colors: null
		};
	},
	created() {
		this.colors = this.options.color && this.options.color.map(color => `#${this.evaluationContext.eval(color)}`);
	}
};
</script>
<style src="./control-color-set.less" scoped></style>
