var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dialog-content send-test-dialog" },
    [
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading,
            expression: "isLoading"
          }
        ],
        staticClass: "loading-overlay"
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "lookup-wrapper" },
        [
          _c("ControlLookupWrapper", {
            attrs: {
              lookupOptions: _vm.getLookupOptions(),
              buttonOptions: _vm.buttonOptions,
              validationOptions: _vm.validationOptions,
              cssClasses: _vm.cssClasses
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-header mci-send-test-info" },
        [
          _c("div", { staticClass: "info-text" }, [
            _vm._v(
              "Add up to " +
                _vm._s(
                  _vm.maxRecipients - _vm.contacts.length > 0
                    ? _vm.maxRecipients - _vm.contacts.length
                    : 0
                ) +
                " recipients"
            )
          ]),
          _vm._v(" "),
          _c("InfoBadge", {
            staticClass: "info-icon",
            attrs: {
              tooltipText: _vm.getQuantityInfo(),
              direction: "bottom",
              fitIntoContainerClass: "send-test-dialog",
              fitInPostition: "center"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.error
          ? _c("div", { staticClass: "error" }, [
              _vm._v("\n\t\t\t" + _vm._s(_vm.error) + "\n\t\t\t"),
              _c(
                "svg",
                {
                  staticClass: "svg-icon svg-15",
                  on: {
                    click: function($event) {
                      _vm.setError("")
                    }
                  }
                },
                [
                  _c("use", {
                    attrs: { "xlink:href": "#action-icons-close-btn" }
                  })
                ]
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "perfectscroll",
              rawName: "v-perfectscroll",
              value: { enable: true },
              expression: "{ enable: true }"
            }
          ],
          staticClass: "table-wrapper"
        },
        [
          _vm.tableTemplate
            ? _c("Control", {
                directives: [
                  {
                    name: "perfectscroll",
                    rawName: "v-perfectscroll",
                    value: { enable: true },
                    expression: "{ enable: true }"
                  }
                ],
                attrs: {
                  name: _vm.tableTemplate.$type,
                  contentProps: _vm.tableTemplate,
                  initResults: _vm.contacts
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "footer" }, [
        _c(
          "button",
          {
            staticClass: "cancel",
            attrs: { type: "button" },
            on: { click: _vm.onCancel }
          },
          [_vm._v("\n\t\t\t" + _vm._s(_vm.options.cancelLabel) + "\n\t\t")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            attrs: { type: "button", disabled: !_vm.contacts.length },
            on: { click: _vm.onSubmit }
          },
          [_vm._v("\n\t\t\t" + _vm._s(_vm.options.submitLabel) + "\n\t\t")]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }