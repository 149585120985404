<template>
	<div class="dialog-title adaptive">
		<span v-if="icon" class="icon-wrapper">
			<i :class="['icon', icon]"></i>
		</span>
		<span v-if="localizedTitle" v-localization="{ key: localizedTitle }" class="caption"></span>
		<span v-else-if="title" class="caption" v-data-attr="{title: dialogOptions.title, $type: 'dialog.header'}">{{ title }}</span>
		<div class="control-box">
			<div v-if="statusMessage" class="status">
				<span class="status-message">{{ statusMessage }}</span>
				<span v-if="statusIcon" class="icon">
					<i :class="['status-icon', statusIcon]" />
				</span>
			</div>
			<div v-show="allowClose" class="control-button close-window tooltip" @click="close" v-data-attr="{title: dialogOptions.title, $type: 'dialog.button.close'}">
				<svg class="svg-icon">
					<use xlink:href='#layout-icons-dialog-close' />
				</svg>
			</div>
		</div>
	</div>
</template>
<script>
import { firstDefined } from '@acx-xms/data-functions/dist';

export default {
	// This component is responsible for dialog header only. Initial state is initialized from dialog object passed in props. Futher changes are tracked via SC events bus
	// TODO: Get rid of dependency on ko when dialog view model is moved to Vue
	__ko_proto__: ko,
	name: 'dialog-header',
	props: { dialogOptions: Object },
	data() {
		return {
			dialogId: this.dialogOptions.id,
			icon: ko.unwrap(this.dialogOptions.icon),
			title: ko.unwrap(this.dialogOptions.title),
			localizedTitle: ko.unwrap(this.dialogOptions.localizedTitle),
			statusMessage: (ko.unwrap(this.dialogOptions.status) || {}).message,
			statusIcon: (ko.unwrap(this.dialogOptions.status) || {}).icon,
			allowClose: ko.unwrap(this.dialogOptions.allowClose)
		};
	},
	methods: {
		close: function () {
			if (this.dialogOptions.onClose) {
				this.dialogOptions.onClose();
			}
			this.$parent.$emit('close');
		}
	},
	created() {
		this.fork = sc.events.fork();
		const self = this;
		this._onDialogStateChanged = function (options) {
			if (!options || options.id !== self.dialogId) { return; }

			self.icon = firstDefined(self.icon, options.icon);
			self.title = firstDefined(self.title, options.title);
			self.statusMessage = firstDefined(self.statusMessage, options.statusMessage);
			self.statusIcon = firstDefined(self.statusIcon, options.statusIcon);
			self.allowClose = firstDefined(self.allowClose, options.allowClose);
		};

		this.fork.on('dialog.state.changed', this._onDialogStateChanged);
	},
	beforeDestroy() {
		this.fork.dispose();
	}
};
</script>
<style src="./dialog-header.less" scoped></style>
