<template>
	<div class="control-lookup-editor-wrapper">
		<control-lookup-editor v-model="wrappedValue"
							   :evaluationContext="evaluationContext"
							   :options="lookupOptions"
							   :showRequired="showRequiredMark"
							   :class="{invalid: !isValid}"></control-lookup-editor>
		<button type="button" class="secondary" :disabled="!enable" v-if="options.allowQuickAdd && logicalName" @click="quickCreate">{{quickCreateButtonName}}</button>
	</div>
</template>

<script>
import LookupEditorWrapperMixins from './control-lookup-editor-wrapper.mixin';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';
const ControlLookupEditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Lookup/control-lookup-editor');

export default {
	name: 'control-lookup-editor-wrapper',
	data() {
		return {
			displayName: '',
			quickCreateButtonName: ''
		};
	},
	mixins: [
		LookupEditorWrapperMixins
	],
	components: { ControlLookupEditor },
	computed: {
		lookupOptions() {
			return (this.options && this.options.lookupOptions) || {
				placeholder: this.options.placeholder || '',
				disabled: !this.enable,
				template: this.options.template,
				dataAttr: this.options.qaDataAttribute
					? this.evaluationContext.eval(this.options.qaDataAttribute)
					: {},
				fetchData: async (searchQuery, startFrom, pageSize) => {
					if (this.options.hasLogicalFieldFilter) {
						this.$store.commit(this.stateKey + '/setFieldValue', {
							field: 'searchQuery',
							value: searchQuery
						});
					}
					// this we need to handle form changes
					const context = this.storeState && !this.options.takeParentContext
						? createEvaluationContext(this.storeState.entityData)
						: this.evaluationContext;

					const configFilters = this.options.filters
						? this.options.filters.map((filter) => {
							return sc.classes.get(filter.$type, filter, context).toFilter();
						})
						: [];
					const filters = [
						...configFilters,
						sc.classes.get('offsetFrom.filter', startFrom || 0),
						sc.classes.get('offsetSize.filter', pageSize)
					];
					if (!this.options.template) {
						filters.push(sc.classes.get('selectedFields.filter', []));
					} else {
						const entitiesConfigurations = await sc.classes.get('entityConfiguration.dataProvider').fetchEntity(this.options.template.entity);
						if (entitiesConfigurations.selectedFieldsSettings) {
							const selectedFieldsSection = entitiesConfigurations.selectedFieldsSettings.section.find(item => item.type === this.options.selectedFieldsSectionType);
							if (selectedFieldsSection && selectedFieldsSection.field) {
								const selectedFields = selectedFieldsSection.field.map(item => ({ logicalname: item }));
								selectedFields.length && filters.push(sc.classes.get('selectedFields.filter', selectedFields));
							}
						}
					}

					if (this.isStandartLookup) {
						filters.push(sc.classes.get('termFacet.filter', {
							logicalName: 'recordstate.id',
							query: ['9e817a63-452c-e811-8320-7824af3b452f'],
							negative: true
						}).fillQuery());
						filters.push(sc.classes.get('orderBy.filter',
							{
								query: [
									{
										logicalName: 'order',
										value: false
									},
									{
										logicalName: 'name',
										value: false
									}
								]
							})
						);
					}

					if (searchQuery && !this.options.hasLogicalFieldFilter) {
						filters.push(sc.classes.get('field.filter', {
							logicalName: '_all',
							query: searchQuery
						}));
					}
					const results = await sc.classes.get('edge.dataProvider').search({
						filters,
						entities: this.options.entityTypes
					});
					return {
						total: results.Total,
						items: results.Results.map(res => {
							return {
								displayName: res.Name,
								data: res
							};
						})
					};
				}
			};
		}
	}
};
</script>
<style src="./control-lookup-editor-wrapper.less" scoped></style>
