var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "create-relation-dialog editor" }, [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ],
      staticClass: "loading-overlay large"
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "entity-list" },
      [
        _vm.documentRelatedRecordsTemplate && !_vm.isLoading
          ? _c("control", {
              staticClass: "formcontrol-template",
              attrs: {
                name: _vm.documentRelatedRecordsTemplate.$type,
                contentProps: _vm.documentRelatedRecordsTemplate,
                initResults: _vm.documentRelatedRecords
              }
            })
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c("div", { staticClass: "dialog-buttons" }, [
        _c("button", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "common.dictionary.buttons.cancel" },
              expression: "{key: 'common.dictionary.buttons.cancel'}"
            },
            {
              name: "data-attr",
              rawName: "v-data-attr",
              value: {
                title: _vm.options.title,
                $type: "dialog.button.cancel"
              },
              expression:
                "{title: options.title, $type: 'dialog.button.cancel'}"
            }
          ],
          staticClass: "cancel",
          attrs: { type: "button" },
          on: { click: _vm.cancel }
        })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }