<template>
	<div class="select-record-dialog">
		<div class="search-record">
			<span class="h5">{{ lookupTitle }}</span>
			<div class="loading-overlay large" v-show="isLoading"></div>
			<div class="select-record">
				<control-lookup-editor v-model="selectedRecord" :evaluationContext="options.params.evaluationContext" :options="getLookupOptions()"></control-lookup-editor>
			</div>
			<Checkbox v-model="onlyActiveRecords" :label="checkboxTitle" />
		</div>
		<div class="footer">
			<div class="dialog-buttons">
				<button @click="close" class="cancel" type="button" v-localization="{key: 'common.dictionary.buttons.cancel'}"></button>
				<button @click="save" type="button" v-localization="{key: 'common.dictionary.buttons.save'}" :disabled="!selectedRecord"></button>
			</div>
		</div>
	</div>
</template>
<script>
/* eslint vue/no-mutating-props: 0 */
// TODO review
import Checkbox from '@/Components/Control/Checkboxes/checkbox-base';
import { showToastOrInfoDialog, Search } from '@acx-xms/data-functions/dist';
const ControlLookupEditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Lookup/control-lookup-editor');

export default {
	name: 'select-record-dialog',
	components: {
		ControlLookupEditor,
		Checkbox
	},
	props: { options: Object },
	data() {
		return {
			isLoading: true,
			selectedRecord: null,
			lookupTemplate: null,
			results: null,
			lookupTitle: null,
			onlyActiveRecords: true,
			checkboxTitle: null
		};
	},
	async created() {
		this.localizations = sc.classes.get('localization.dataProvider');
		this.options.params.lookupTemplate.entity = this.options.params.logicalName;
		const entityName = this.options.params.logicalName.charAt(0).toUpperCase() + this.options.params.logicalName.slice(1);
		const localizationDataProvider = sc.classes.get('localization.dataProvider');
		this.lookupTitle = await localizationDataProvider.getLabelForCurrentLanguage('common.dictionary.selectEntity', [entityName]);
		this.checkboxTitle = await localizationDataProvider.getLabelForCurrentLanguage('select-record-dialog.checkbox.title');
		this.isLoading = false;
	},
	methods: {
		async save() {
			this.$root.$emit(this.options.params.eventName, this.selectedRecord);
			await showToastOrInfoDialog({
				toastMessageKey: this.options.params.toastMessageKey,
				informationDialogTextKey: this.options.params.informationDialogTextKey,
				options: { context: this }
			});
			this.close();
		},

		close() {
			this.$parent.$emit('close');
		},

		getLookupOptions: function () {
			return {
				placeholder: '',
				customIcon: 'layout-icons-ms-search',
				fetchData: async (searchQuery, startFrom = 0, pageSize = 1000) => {
					const filters = [
						sc.classes.get('offsetFrom.filter', startFrom),
						sc.classes.get('offsetSize.filter', pageSize),
						sc.classes.get('termFacet.filter', {
							logicalName: 'serviceuser',
							query: [true],
							negative: true
						}).fillQuery()
					];

					const entityConfigurations = await sc.classes.get('entityConfiguration.dataProvider').fetchEntity(this.options.params.logicalName);
					if (entityConfigurations.selectedFieldsSettings && !this.options.params.ignoreSelectedFields) {
						const selectedFieldsSection = entityConfigurations.selectedFieldsSettings.section.find(item => item.type === 'lookup');
						if (selectedFieldsSection) {
							const selectedFields = selectedFieldsSection.field.map(field => ({ logicalname: field }));
							filters.push(sc.classes.get('selectedFields.filter', selectedFields).fillQuery());
						}
					}
					if (searchQuery) {
						filters.push(sc.classes.get('field.filter', {
							logicalName: '_all',
							query: searchQuery
						}));
					}

					if (this.onlyActiveRecords) {
						filters.push(
							sc.classes.get('termFacet.filter', {
								logicalName: 'recordstate.id',
								query: ['8d113fa8-3015-4060-a107-14cedcd19dd3']
							}).fillQuery()
						);
					}

					return Search(this.options.params.logicalName, filters).then(({ Results, Total }) => {
						return {
							total: Total,
							items: Results.map(res => {
								return {
									displayName: res.Name,
									data: res
								};
							})
						};
					});
				},
				template: this.options.params.lookupTemplate
			};
		}
	}
};
</script>
<style src="./select-record-dialog.less" scoped></style>
