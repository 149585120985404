import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';

export default {
	props: { options: Object },
	mounted() {
		this.$root.$on('lookup.keyDown', this.scrollToBottom);
		this.$root.$on('lookup.keyUp', this.scrollToTop);
	},
	watch: {
		'options.data.items': function () {
			this.$parent.$emit('itemsUpdated', true);
		}
	},
	beforeDestroy() {
		this.$root.$off('lookup.keyDown', this.scrollToBottom);
		this.$root.$off('lookup.keyUp', this.scrollToTop);
	},
	methods: {
		getContext(item) {
			return createEvaluationContext(item);
		},
		scrollToBottom() {
			if (!this.$refs.scrollArea) {
				return;
			}
			this.$refs.scrollArea.scrollBy(0, 100);
		},
		scrollToTop() {
			if (!this.$refs.scrollArea) {
				return;
			}
			this.$refs.scrollArea.scrollBy(0, -100);
		}
	}
};
