<template>
  <div>
    <div class="image-gallery-dialog loading">
      <div v-if="loading" class="loading-overlay"></div>
      <div v-if="images.length" :class="{'dialog-gallery': true, 'is-primary': currentImage.isPrimary}">
        <div class="dialog-gallery-image" v-for="image in images" v-show="currentImage.id === image.id"
          :key="image.$key">
          <img :src="image.url" v-show="image.loaded && !image.error" @load="image.loaded = true"
            @error="image.error = true; image.loaded = true" />
          <img src="/Images/broken-file-icon-small.png" v-show="image.error" class="broken-image" />
          <i class="loading-overlay" v-show="!image.loaded"></i>
        </div>
        <template v-if="images.length > 1">
          <div class="prev" @click="prevImage">
            <div class="image-gallery-arrow">
              <svg class="svg-icon">
                <use xlink:href="#layout-icons-ms-arrow-left"></use>
              </svg>
            </div>
          </div>
          <div class="next" @click="nextImage">
            <div class="image-gallery-arrow">
              <svg class="svg-icon">
                <use xlink:href="#layout-icons-ms-arrow-right"></use>
              </svg>
            </div>
          </div>
        </template>

        <div class="image-menu" :class="{ disabled: !saveOrderButton }" v-if="currentImage && menu && menu.item">
          <span class="tooltip">
            Save the new image ordering to perform this action
          </span>
          <div class="item" v-for="(menuItem, index) in menuItemsComputed" :key="'menu-item' + index">
            <ControlSvgIconAction v-if="currentImage" :evaluationContext="actionContext" v-bind:options="menuItem" />
          </div>
        </div>
      </div>
      <div class="gallery-bar">
        <WizardButton class="close-dialog" text="Close" @click="onCloseDialog" />
        <WizardButton v-if="images.length" class="blue save-dialog-btn" text="Save Order" :disabled="saveOrderButton"
          @click="updateImagesOrder" />
      </div>
      <div class="footer" v-if="images.length">
        <div class="tip">First image in order is primary. Drag to reorder images</div>
        <draggable v-perfectscroll="{ suppressScrollX: true }" @update=" onUpdateDD" class="footer-inner"
          v-model="images" group="people" @start="drag=true" @end="drag=false">
          <div @click="selectImage(image)" :id="'footer-image-' + image.id" class="footer-image"
            v-for="(image, index) in images" :class="{ active: currentImage.id === image.id }" :key="image.$key">
            <div class="image-wrap" v-show="image.loaded" :class="{ 'broken-image': image.loaded && image.error}">
              <img :src="image.url" @load="image.loaded = true" v-if="!image.loaded"
                @error="image.error = true; image.loaded = true" />
              <img src="/Images/broken-file-icon-small.png" v-else-if="image.loaded && image.error" />
              <div v-else class="bg-image" :style="{'background-image': `url(${image.url})`}"></div>
              <div v-if="index === 0" class="icon-primary">
                <svg class="svg-icon">
                  <use xlink:href="#action-icons-image-gallery-primary"></use>
                </svg>
              </div>
            </div>
            <i class="loading-overlay" v-show="!image.loaded"></i>
          </div>
        </draggable>
        <template v-if="images.length > 1">
        </template>
      </div>
    </div>
    <div v-if="!images.length" class="info-message">
      <span class="info-message-title" v-localization="{key: 'imageGallery.noDataMessage'}"></span>
    </div>
  </div>
</template>
<script>
import { GetUserInfo } from '@/Data/Auth/authentication-service';
import ActionResolver from '@/Actions/actionResolver';
import helperMethods from '@/Components/ComponentSet/component-set-evaluation-context-helper.methods';
import * as tracking from '@/Data/trackingModule';
import { CreateBulk, generateGuid } from '@acx-xms/data-functions/dist';
import WizardButton from '@/Components/Wizard/WizardSets/EmailCampaign/Components/Buttons/NavBtn/navBtn.vue';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';
const ControlSvgIconAction = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Images/SvgIconAction/control-svg-icon-action');

export default {
	name: 'image-gallery-dialog',
	props: {
		options: Object,
		dialogId: String
	},
	components: {
		ControlSvgIconAction,
		WizardButton
	},
	data() {
		return {
			userInfo: null,
			images: [],
			menu: null,
			currentImage: null,
			currentIndex: 0,
			saveOrderButton: true,
			loading: false
		};
	},
	methods: {
		async onUpdateDD() {
			this.saveOrderButton = false;
		},
		async updateImagesOrder(closeDialog = false) {
			this.loading = true;
			this.saveOrderButton = true;
			const records = [];
			this.images.forEach((rec, index) => {
				const record = {
					id: generateGuid(),
					method: 'PUT',
					url: `image/${rec.data.Id}?syncProcessMode=All`,
					body: {
						type: 'image',
						order: index,
						primary: index === 0
					}
				};
				records.push(record);
			});

		  await CreateBulk(records, { async: true }).then((res) => {
				this.$root.$emit('toast.open', 'Image order is saved');
				if (closeDialog) {
					this.$emit('closeDialog');
				}
			}).catch(e => {
				this.$root.$emit('toast.open', 'Image order is not saved. Please, try again');
				console.log('records createBulk error ', e);
			}).finally(() => {
				this.$root.$emit('refreshCurrentDetails', this.afterUpdate());
			});
		},
		afterUpdate() {
			setTimeout(() => {
				this.loading = false;
			}, 1000);
		},
		nextImage() {
			const currentIndex = this.images.findIndex((image) => {
				return image.id === this.currentImage.id;
			});
			const nextIndex = currentIndex === this.images.length - 1 ? 0 : currentIndex + 1;
			this.selectImage(this.images[nextIndex]);

			tracking.AddUserActivity('imagegallery.navigate.next', 'Image gallery navigation - next image', this.options.parentEntity);
		},
		prevImage() {
			const currentIndex = this.images.findIndex((image) => {
				return image.id === this.currentImage.id;
			});
			const prevIndex = currentIndex === 0 ? this.images.length - 1 : currentIndex - 1;
			this.selectImage(this.images[prevIndex]);

			tracking.AddUserActivity('imagegallery.navigate.prev', 'Image gallery navigation - prev image', this.options.parentEntity);
		},
		selectImage(image) {
			this.currentImage = image;
			if (!this.currentImage) {
				this.currentIndex = null;
				return;
			}
			const imageElement = this.$el.querySelector('#footer-image-' + image.id);
			const footerElement = this.$el.querySelector('.footer');
			if (!imageElement) {
				return;
			}
			if (imageElement.offsetLeft < footerElement.scrollLeft) {
				footerElement.scrollTo({ left: imageElement.offsetLeft });
			} else if (imageElement.offsetLeft + imageElement.offsetWidth > footerElement.scrollLeft + footerElement.offsetWidth) {
				footerElement.scrollTo({ left: imageElement.offsetLeft + imageElement.offsetWidth - footerElement.offsetWidth });
			}
			this.currentIndex = this.images.findIndex(img => img.id === this.currentImage.id);
			this.updateTitle();
		},

		executeMenuAction(item) {
			const action = this.actionResolver.resolve(item.action.$type);
			if (!action) return;
			const localContext = createEvaluationContext(this.currentImage.data);
			let preenabled = true;
			if (item.action.enable) {
				preenabled = localContext.eval(item.action.enable);
			}
			if (preenabled) {
				const action = this.actionResolver.resolve(item.action.$type);
				if (action) {
					action.execute(item.action, localContext, [this.currentImage.data]);
				}
			}
		},

		async updateTitle() {
			if (this.dialogId) {
				const title = await sc.classes.get('localization.dataProvider')
					.getLabelForCurrentLanguage('imageGallery.dialog.header.images', [
						this.options.parentEntity.name,
						this.currentIndex + 1, this.images.length,
						this.currentImage.title
					]);
				sc.events.emit('dialog.state.changed', {
					id: this.dialogId,
					title
				});
			}
		},

		updateImages(images) {
			const token = localStorage.getItem(sc.classes.get('authentication').getTokenKey(sc.cluster.key));
			this.images = [];
			images.forEach((item) => {
				const id = item.imageid || item.Id;
				let url = sc.classes.get('edge.dataProvider').getImageUrl('image', id, 'fine');
				url += '?token=' + token;
				this.images.push({
					url,
					title: (item.Source && item.Source.title) || item.title,
					data: item,
					id,
					loaded: false,
					error: false,
					isPrimary: (item.Source && item.Source.primary) || item.primary,
					$key: helperMethods.getRandomArbitary(1, 10000000000000000).toFixed()
				});
			});
			this.currentImage = this.images[0];
		},

		onCloseDialog() {
			if (!this.saveOrderButton) {
				sc.events.emit('vue.dialog.open', {
					icon: 'action-icons-search-save',
					title: 'Unsaved image order',
					component: 'confirm.dialog',
					maximizedWidth: '450px',
					maximizedHeight: 'auto',
					message: 'The image order is unsaved and will be reset. Would you like to save changes?',
					okLabel: 'Save',
					cancelLabel: 'Cancel',
					onSubmit: () => {
						this.updateImagesOrder(true);
					},
					onCancel: () => {
						this.$emit('closeDialog');
					}
				});
			} else {
				this.$emit('closeDialog');
			}
		}
	},
	async created() {
		this.$emit('removeClose');
		this.userInfo = await GetUserInfo();
		this.menu = this.options.menu;
		this.updateImages(this.options.images.slice().sort((a, b) => a.Source.order - b.Source.order));
		this.actionResolver = ActionResolver();
		this.$root.$on('imageGalleryUpdate', ({ items, menu }) => {
			this.menu = menu;
			this.updateImages(items.slice().sort((a, b) => a.Source.order - b.Source.order));
			this.$nextTick(() => {
				this.selectImage(this.images[0]);
			});
		});
		this.updateTitle();
	},
	computed: {
		actionContext() {
			return createEvaluationContext(this.currentImage.data);
		},
		menuItemsComputed() {
			const icons = this.menu.item;
			if (icons) {
				return icons.filter((i) => i.icon.value !== 'action-icons-image-gallery-primary');
			}
			return [];
		}
	}
};
</script>
<style src="./imageGalleryDDDialog.less" scoped></style>
