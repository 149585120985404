var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "step" },
    [
      _vm.templateProcess
        ? _c("Loader", {
            attrs: {
              height: "100%",
              text:
                "The publication with chosen data is being generated for the future preview. This process can take some time and no changes can be made with a template until it ends. Please, wait a while."
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "wizard-step-header" }, [
        _c("div", { staticClass: "wizard-step-header-title" }, [
          _c("div", { staticClass: "wizard-step-header-icon" }, [
            _c(
              "div",
              {
                class: ["filter-button active", { open: _vm.isPanelOpen }],
                on: {
                  click: function($event) {
                    _vm.isPanelOpen = !_vm.isPanelOpen
                  }
                }
              },
              [
                _c("svg", { staticClass: "svg-icon" }, [
                  _c("use", {
                    staticClass: "ms-filter-icon",
                    attrs: { "xlink:href": "#layout-icons-ms-filter-icon" }
                  })
                ])
              ]
            )
          ]),
          _vm._v("\n\t\t\tSelect Template\n\t\t")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "wizard-step-header-search" },
          [
            _c("SearchField", {
              attrs: { options: { debouncedWatchSearchQuery: { delay: 1500 } } }
            }),
            _vm._v(" "),
            _c("button", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: { key: "dialogues.RS4.dialog.manage" },
                  expression: "{ key: 'dialogues.RS4.dialog.manage' }"
                }
              ],
              staticClass: "secondary",
              on: { click: _vm.openEmailTemplates }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "email-campaign-step-root select-template" }, [
        _c("div", { staticClass: "select-template-wrapper" }, [
          _c(
            "div",
            { staticClass: "filters", class: { hidden: !_vm.isPanelOpen } },
            [
              _c("div", { staticClass: "sections" }, [
                _c("div", { staticClass: "section" }, [
                  _c(
                    "div",
                    {
                      staticClass: "filter-header",
                      on: {
                        click: function($event) {
                          _vm.isFiltersOpen = !_vm.isFiltersOpen
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "caption" }, [
                        _vm._v("Input Type")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "icon" }, [
                        _c(
                          "svg",
                          {
                            staticClass: "svg-10 svg-icon",
                            class: [
                              _vm.isFiltersOpen
                                ? "dropdown-arrow-open"
                                : "dropdown-arrow"
                            ]
                          },
                          [
                            _c("use", {
                              staticClass: "hover",
                              class: [
                                _vm.isFiltersOpen
                                  ? "layout-icons-ms-arrow-up"
                                  : "layout-icons-ms-arrow-down"
                              ],
                              attrs: {
                                "xlink:href": [
                                  _vm.isFiltersOpen
                                    ? "#layout-icons-ms-arrow-up"
                                    : "#layout-icons-ms-arrow-down"
                                ]
                              }
                            })
                          ]
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "ul",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isFiltersOpen,
                          expression: "isFiltersOpen"
                        }
                      ],
                      staticClass: "filter-list"
                    },
                    _vm._l(_vm.filters, function(filter, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          staticClass: "item",
                          class: {
                            isChecked: _vm.filters.find(function(i) {
                              return i.name === filter.name
                            }).selected
                          }
                        },
                        [
                          _c("label", [
                            _c("div", { staticClass: "item-checkbox" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "virtual-checkbox clickable",
                                  class: {
                                    selected: _vm.filters.find(function(i) {
                                      return i.name === filter.name
                                    }).selected
                                  }
                                },
                                [
                                  _c("svg", { staticClass: "svg-icon" }, [
                                    _c("use", {
                                      staticClass: "layout-icons-ms-checkbox",
                                      attrs: {
                                        "xlink:href":
                                          "#layout-icons-ms-checkbox"
                                      }
                                    })
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                attrs: {
                                  type: "checkbox",
                                  disabled: _vm.isLoading
                                },
                                domProps: {
                                  checked: _vm.filters.find(function(i) {
                                    return i.name === filter.name
                                  }).selected,
                                  value: filter.name
                                },
                                on: {
                                  input: function($event) {
                                    _vm.checkboxHandler(filter)
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "item-info" }, [
                              _c("div", { staticClass: "name" }, [
                                _c("span", { staticClass: "tooltip-wrapper" }, [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(filter.displayName || filter.name)
                                    )
                                  ])
                                ])
                              ])
                            ])
                          ])
                        ]
                      )
                    })
                  )
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "templates" }, [
            _c(
              "div",
              { staticClass: "templates-list-wrapper" },
              [
                _vm.filtersSelected
                  ? _c("SelectTemplatesTable", {
                      class: {
                        empty: !_vm.filtersSelected || !_vm.templates.length
                      },
                      attrs: { templates: _vm.templates },
                      on: {
                        click: function($event) {
                          _vm.actionTemplateHandler("select")
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                (!_vm.filtersSelected || !_vm.templates.length) &&
                !_vm.isLoading
                  ? _c("div", { staticClass: "no-results-message p13" }, [
                      _c("div", {
                        directives: [
                          {
                            name: "localization",
                            rawName: "v-localization",
                            value: { key: "common.dictionary.noMatchesFound" },
                            expression:
                              "{ key: 'common.dictionary.noMatchesFound' }"
                          }
                        ]
                      })
                    ])
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isLoading,
                  expression: "isLoading"
                }
              ],
              staticClass: "reportingservice-overlay loading-overlay large"
            })
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }