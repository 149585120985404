var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "action-menu popup-component" }, [
    _c(
      "div",
      { staticClass: "rating-tooltip dropdown-wrapper" },
      [
        _c("div", { staticClass: "top-right-triangle" }),
        _vm._v(" "),
        _vm._l(_vm.ratings, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "icon",
              class: {
                active:
                  index < _vm.currentActiveIndex ||
                  index === _vm.currentActiveIndex
              },
              on: {
                click: function($event) {
                  _vm.updateRating(item.value)
                },
                mouseleave: function($event) {
                  _vm.currentActiveIndex = null
                },
                mouseover: function($event) {
                  _vm.currentActiveIndex = index
                }
              }
            },
            [
              _c("svg", { staticClass: "svg-icon" }, [
                index < _vm.currentActiveIndex ||
                index === _vm.currentActiveIndex
                  ? _c("use", {
                      attrs: {
                        "xlink:href": "#layout-icons-" + item.iconFilled
                      }
                    })
                  : _c("use", {
                      attrs: { "xlink:href": "#layout-icons-" + item.icon }
                    })
              ])
            ]
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }