<template>
	<div @click="focusInput"
		 class="control-lookup-editor input"
		 :class="{focused: searchFocused || isPopUpOpen, opened: isPopUpOpen, disabled: options.disabled}">
		<div class="actions before">
			<div class="required-mark" v-if="showRequiredMark">
				<svg class="svg-icon svg-8 layout-icons-required">
					<use xlink:href="#layout-icons-required"></use>
				</svg>
			</div>
		</div>
		<input v-show="showInput"
			   :disabled="options.disabled"
			   @input="searchWithDebounce"
			   class="lookup-editor-input p1"
			   :class="{'required': showRequiredMark}"
			   type="text"
			   :placeholder="placeholder"
			   @keyup.enter="search"
			   @keyup.down="$root.$emit('lookup.keyDown')"
			   @keyup.up="$root.$emit('lookup.keyUp')"
			   :value="searchQuery"
			   @focus="onInputFocus"
			   @blur="onInputBlur" />
		<div class="selected-items" v-show="!showInput">
			<div class="item">
				<span class="item-name">{{ displayName }}</span>
				<svg class="svg-icon svg-15" @click.stop="clearSelection" v-show="!options.disabled">
					<use :xlink:href="'#action-icons-options-remove'" class='action-icons-options-remove'></use>
				</svg>
			</div>
		</div>
		<div class="actions after clickable" v-show="!options.disabled">
			<div class="icon-wrapper" @click.stop="iconAction">
				<template v-if="customIcon">
					<div class="icon">
						<svg class="svg-icon">
							<use :xlink:href="'#'+customIcon" class=customIcon></use>
						</svg>
					</div>
				</template>
				<template v-else>
					<div class="icon" v-if="!isPopUpOpen">
						<svg class="svg-icon svg-10">
							<use :xlink:href="'#layout-icons-ms-arrow-down'" class='layout-icons-ms-arrow-down'></use>
						</svg>
					</div>
					<div class="icon" v-else @click.stop="closePopUp">
						<svg class="svg-icon svg-10">
							<use :xlink:href="'#layout-icons-ms-arrow-up'" class='layout-icons-ms-arrow-up'></use>
						</svg>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>
<script>
import LookupEditorMixins from './lookup-editor.mixin';
export default {
	name: 'control-lookup-editor-witch-custom-value',
	data() {
		return {
			selectedItemVal: null,
			isRequired: this.options.isRequired,
			customIcon: this.options.customIcon
		};
	},
	mixins: [
		LookupEditorMixins
	],
	computed: {
		showInput() {
			return this.isPopUpOpen || !this.selectedItem || this.searchFocused;
		},
		displayName() {
			// fix to display predefined parameters
			return this.selectedItem ? (this.selectedItem.displayName ? this.selectedItem.displayName : this.selectedItem.data.name) : '';
		}
	},
	methods: {
		clearSelection() {
			if (this.options.disabled) {
				return;
			}
			this.selectedItem = null;
			// null - new value, this.selectedItem - old value
			this.options.singleSelectionOnArray && this.options.singleSelectionOnArray(null, this.selectedItem);
			this.clearSearchBox();
		},
		onClosePopup(itemChanged) {
			if (!itemChanged && this.searchQuery) {
				const selectedItem = { displayName: this.searchQuery };
				this.options.singleSelectionOnArray && this.options.singleSelectionOnArray(selectedItem, this.selectedItem);
				this.clearSearchBox();
			}
			this.searchFocused = false;
		}
	}
};
</script>
<style scoped>
	.control-lookup-editor.opened:not(.disabled).top-positioned input {
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
</style>
<style src="@/Components/Editor/Controls/editor.mixins.less" scoped></style>
<style src="./lookup.styles.less" scoped></style>
