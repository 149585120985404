var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { "open-folder": true, showLink: _vm.showLink },
      attrs: { title: _vm.title },
      on: { click: _vm.openFolder }
    },
    [
      _c("svg", { staticClass: "folder-icon" }, [
        _c("use", { attrs: { "xlink:href": "#layout-icons-folder-closed" } })
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "text oh-ellipsis" }, [
        _vm._v(_vm._s(_vm.title))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }