<template>
	<div class="component-search-results is-parent mobile">
		<filters-panel v-if="options.filterPanel" v-show="showFilterPanel"
					   :options="options.filterPanel"
					   :evaluationContext="filterPanelEvaluationContext"
					   :isLoading="isLoading"
					   :entityName="logicalName"
					   :stateNamespace="stateNamespace"></filters-panel>
		<div class="search-results-wrapper">
			<div class="header" v-show="!hideHeader">
				<div class="top-wrapper">
					<search-field class="mobile" />
					<sortpanel class="sortby-filter-wrapper mobile"
							   v-show="sortByFields"
							   v-if="options.sortByFields"
							   :fields="options.sortByFields"
							   :entityName="logicalName"
							   :evaluationContext="evaluationContext"
							   :scrollNamespace="scrollNamespace"
							   :namespace="namespace"
							   :internalNamespace="internalNamespace"
							   :stateNamespace="stateNamespace"></sortpanel>

					<view-switcher :items="items" :stateNamespace="stateNamespace" class="mobile" />
				</div>
				<div class="bottom-wrapper">
					<div class="region">
						<div v-if="options.filterPanel" class="filter-button active" @click="toggleFilterPanel()" :class="{ open: !!showFilterPanel}">
							<svg class='svg-icon'>
								<use class="'ms-filter-icon'" xlink:href="#layout-icons-ms-filter-icon"></use>
							</svg>
						</div>
						<slot name="title-slot"><div class="title h1">{{ title }}</div></slot>
						<slot name="toggle-slot">
							<results-toggle :namespace="namespace"
											class="toggle-padding"
											v-if="withToggleAll"
											:internalNamespace="internalNamespace"
											:toggleNamespace="toggleNamespace"
											:isParent="true"></results-toggle>
						</slot>
						<component-set-search-results-selected-records :stateNamespace="stateNamespace"
																	   :showSelectedCount="showSelectedCount"
																	   :targetEntity="logicalName" />
					</div>
					<div class="region">
						<reactive-action-menu :options="actionMenuComponent"
											  :stateNamespace="stateNamespace"
											  :class="{'without-border': !(sortByFields)&&(items||[]).length<2}"
											  v-if="actionMenuComponent && (actionMenuComponent.menu || actionMenuComponent.toolbarActions)"
											  :evaluationContext="evaluationContext"
											  class="mobile"></reactive-action-menu>
					</div>
				</div>
			</div>
			<div class="content-wrapper" v-show="!isCollapsed" :class="{'withPanel':showFilterPanel,'loading':isLoading}">
				<div class="content">
					<!-- remove results.results after we migrate all views to vue-->
					<div class="content-inherit-height"
						 v-bind:class="{'with-footer': !!results.total}"
						 v-bind:is="content.name"
						 v-bind:options="content"
						 v-bind:results="results.results"
						 v-bind:stateNamespace="stateNamespace"
						 v-bind:paginationParams="paginationParams"></div>
					<div class="loading-overlay" v-show="isLoading"></div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
// TODO: investigate if we need to load styles like this
import '@acx-xms/styles-mixins/style.mixins.less';
import RouteSearchResultsBaseMixins from './route-search-results.base.mixins';

export default {
	name: 'mobile-component-search-results',
	mixins: [RouteSearchResultsBaseMixins],
	computed: {
		filtersReady() {
			return this.$store.getters[this.stateNamespace + '/filtersReady'](this.logicalName);
		}
	},
	created() {
		this.fork = sc.events.fork();
		this.$on('.pageChanged', this._onPageChangedHandler);

		this.fork.on(this.combinedNamespace + '.getSearchResults', (callback) => {
			callback(this.results);
		});
		// todo - replace with vue event bus
		this.fork.on(this.combinedNamespace + '.searching', () => {
			this._search();
		});
		// todo remove when migrate edit dialog to vue
		this.$root.$on(this.combinedNamespace + '.searching', () => {
			this._search();
		});

		if (this.options.filterPanel) {
			this.$root.$on(this.stateNamespace + 'search', (...params) => {
				this._search(...params);
			});
		}

		this.$on('.sortChanged', () => {
			this._search();
		});

		// todo change using vue events
		this.fork.on(['entity.changed', 'entity.created', 'entity.deleted'], (data) => {
			if (this.logicalName === data.entity.logicalname) {
				this._search(true);
			}
		});
	},

	mounted() {
		if (this.autoSearch) {
			this.unwatchFiltersReady = this.$watch('filtersReady', (newVal, oldVal) => {
				if (newVal) {
					this.doSearch({ initSearch: false });
					this.unwatchFiltersReady();
				}
			});
		}
	},

	beforeDestroy() {
		if (this.fork) {
			this.fork.dispose();
		}
		this.$root.$off(this.stateNamespace + 'search');
		this.$off('.pageChanged', this._onPageChangedHandler);
		this.showFilterPanel && this.$store.commit(this.stateNamespace + '/toggleFiltersPanel');
	}
};
</script>
<style src="./mobile-route-search-results.less" scoped></style>
<!--<style src="./../../style.mixins.less" scoped></style>-->
<!--TODO:investigate for future-->
<!--<style src="~/@acx-xms/styles-mixins/style.mixins.less"></style>-->
