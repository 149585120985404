<template>
	<div class="switch-records-wrapper" :class="[cssClass, {'disabled': items.length < 2}]">
		<div class="prev" @click="selectPrevious">
			<svg class="svg-icon">
				<use xlink:href="#layout-icons-ms-arrow-left"></use>
			</svg>
		</div>
		<div class="next" @click="selectNext">
			<svg class="svg-icon">
				<use xlink:href="#layout-icons-ms-arrow-right"></use>
			</svg>
		</div>
	</div>

</template>
<script>
export default {
	name: 'control-switch-record-details',
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return {
			isLoading: false,
			currentIndex: null,
			items: []
		};
	},
	created() {
		this.cssClass = this.evaluationContext.eval(this.options.cssClass);
		this.stateKey = this.evaluationContext.eval(this.options.stateKey);
		this.entityName = this.evaluationContext.eval(this.options.entityName);
		const items = this.$store.getters[this.stateKey + '/getSearchResults'](this.entityName);
		if (items) {
			Object.entries(items).forEach(([key, value]) => {
				this.items = [...this.items, ...value];
			});
		}
		if (this.items.length > 1) {
			this.currentIndex = this.items.findIndex(item => {
				let id;
				if ((this.entityName === 'cravailability')) {
					id = item.Source.availabilityid.id;
				} else {
					id = item.Id;
				}
				return id === this.evaluationContext.entity.id;
			});
		}
		this.templateRef = this.evaluationContext.eval(this.options.templateRef);
	},
	methods: {
		selectPrevious() {
			const index = this.currentIndex - 1 < 0 ? this.items.length - 1 : this.currentIndex - 1;
			let entityRef;
			if (this.entityName === 'cravailability') {
				entityRef = sc.classes.get('entityReference', {
					id: this.items[index].Source.availabilityid.id,
					logicalname: 'availability'
				});
			} else {
				entityRef = sc.classes.get('entityReference', {
					id: this.items[index].Id,
					logicalname: this.entityName
				});
			}
			this.$root.$emit(this.stateKey + '.switchDetailsItem', entityRef);
		},
		selectNext() {
			const index = this.currentIndex + 1 === this.items.length ? 0 : this.currentIndex + 1;
			let entityRef;
			if (this.entityName === 'cravailability') {
				entityRef = sc.classes.get('entityReference', {
					id: this.items[index].Source.availabilityid.id,
					logicalname: 'availability'
				});
			} else {
				entityRef = sc.classes.get('entityReference', {
					id: this.items[index].Id,
					logicalname: this.entityName
				});
			}
			this.$root.$emit(this.stateKey + '.switchDetailsItem', entityRef);
		}
	}
};
</script>
<style src="./control-switch-record-details.less" scoped></style>
