/* eslint prefer-promise-reject-errors: 0 */
import { BaseAspect } from './base.aspect';
import { findInString } from '@acx-xms/data-functions/dist';
import { createEvaluationContext } from '../../../../Data/EvaluationContext/evaluationContext';

export class BeforeSaveAspect extends BaseAspect {
	constructor(options) {
		super();
		this.init(options);

		this.latitudeField = options ? options.latitudeField : null;
		this.longitudeField = options ? options.longitudeField : null;

		this.fields = this.options.fields || [];
		this.stage = this.options.stage || '';
		this.detach = this.options.detach || false;
	}

	init(options) {
		const config = _.extend({
			fields: [],
			detach: true,
			stage: 'beforeSave'
		}, options);

		super.init(config, options.evaluationContext);
	}

	async execute(options) {
		const context = createEvaluationContext(options.saveResult);
		const changedFields = options.saveResult ? _.keys(options.saveResult) : [];
		const intersection = _.intersection(_.map(this.fields, field => context.eval(field.fieldLogicalName)), changedFields);
		const googleMapsDataProvider = sc.classes.get('googleMaps.dataProvider');
		const isNewEntity = options.entityRef ? options.entityRef.id === void 0 : true;

		if (!intersection.length && !isNewEntity) return;

		const queryValues = _.reduce(this.fields, (result, field) => {
			let value = context.eval(field.fieldValue);
			if (_.isObject(value)) {
				const name = sc.classes.get('entityReference').nameByType(value.logicalname);
				if (name) value = value[name];
			}
			if (value) result.push(value);
			return result;
		}, []);

		if (!queryValues.length) await this.showNotFoundDialog(options);

		try {
			const result = await googleMapsDataProvider.getCoordinates(queryValues.join(', '));
			options.saveResult.geocoordinates = {
				lat: result.data[0].lat,
				lon: result.data[0].long
			};
		} catch (error) {
			await this.showNotFoundDialog(options);
		}
	}

	async showNotFoundDialog(options) {
		await new Promise((resolve, reject) => {
			this.fork.emit('dialog.open', {
				title: this.localize('geoCode.dialog.specifyAddress'),
				component: 'dialog.info',
				allowPin: false,
				allowMinimize: false,
				allowMaximize: false,
				onClose: () => {
					reject({ disableErrorDialog: true });
				},
				params: {
					evaluationContext: options.evaluationContext,
					message: this.localize('geoCode.dialog.notFoundOnMap'),
					buttons: [
						{
							title: this.localize('geoCode.dialog.saveWithoutGeo'),
							callback: () => {
								if (findInString(this.latitudeField, '.') && findInString(this.longitudeField, '.')) {
									options.saveResult[this.latitudeField.split('.')[0]] = null;
								} else {
									options.saveResult[this.latitudeField] = null;
									options.saveResult[this.longitudeField] = null;
								}
								resolve(undefined);
							}
						},
						{
							title: this.localize('geoCode.dialog.back'),
							isCancelBtn: true,
							callback: () => {
								reject({ disableErrorDialog: true });
							}
						}
					]
				}
			});
		});
	}

	localize(label, options) {
		return sc.classes.get('localization.dataProvider').getObservableLabel(label, options);
	}
}
