<template>
	<div class="enums-wrapper">
		<span class="p4">{{title}}</span>
		<control-lookup-editor class="lookup"
							   v-model="value"
							   :options="getLookupOptions()"></control-lookup-editor>
	</div>

</template>
<script>
import { getLookupOptions } from './../helpersMethods.js';
const ControlLookupEditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Lookup/control-lookup-editor');

export default {
	name: 'enums',
	props: {
		enums: Array,
		currentValue: String,
		title: String
	},
	components: { ControlLookupEditor },
	data() {
		return { value: { data: '' } };
	},
	watch: {
		currentValue: {
			handler(newVal, oldVal) {
				this.value = {
					data: { title: newVal },
					displayName: newVal
				};
			},
			immediate: true
		}
	},
	created() {
		// for lookup
		this.internalEnums = this.enums.map(e => {
			return {
				data: {
					Id: sc.utils.guid(),
					title: e
				},
				displayName: e
			};
		});
		if (!this.currentValue) {
			this.value = {
				data: { title: this.enums[0] },
				displayName: this.enums[0]
			};
		}
	},
	methods: {
		onChange(value) {
			if (!value) { // todo: implement defaults for enums if needed or disable remove option in lookup
				if (this.title === 'schema') { this.$emit('onChange', this.title, 'source'); }
			} else {
				this.$emit('onChange', this.title, value.displayName);
			}
		},
		getLookupOptions() {
			return getLookupOptions(this.internalEnums, this.onChange);
		}
	}
};
</script>
<style src="./enums.less" scoped></style>
