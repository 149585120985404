import { showToastOrInfoDialog, Create } from '@acx-xms/data-functions/dist';

export default {
	data() {
		return {
			saveButtonEnabled: false,
			files: [],
			state: 'notStarted'
		};
	},
	methods: {
		onCancel() {
			this.close();
		},
		close() {
			this.$parent.$emit('close');
		},
		async onSubmit({ afterCreateDocumentAspect }) {
			const validationResult = await this.validate(this.stateKey);

			if (!validationResult.isValid) {
				this.showValidationError(validationResult.message);
				return;
			}

			this.state = 'started';
			const state = this.$store.state[this.stateKey];
			const data = { ...state.entityData };
			if (data.parentRecord) {
				delete data.parentRecord;
			}
			if (data.parentfolder === this.selectedSharedParentFolder) {
				delete data.parentfolder;
			}
			delete data.files;
			const files = state.entityData.files.filter(f => !f.errors.length);

			files.forEach((file) => {
				file.state = 'queued';
			});

			state.disabledFields = state.fieldsOptions;

			try {
				for (const fileData of files) {
					fileData.state = 'uploading';
					const file = fileData.file;

					try {
						const document = await Create(this.logicalName,
							{
								...data,
								...{ title: file.name.split('.')[0] }
							},
							{
								body: file,
								name: file.name
							});

						if (afterCreateDocumentAspect) {
							const documentRef = sc.classes.get('entityReference', document);
							await afterCreateDocumentAspect(documentRef, document.title, document.category);
						}

						fileData.state = 'uploaded';
					} catch (e) {
						fileData.state = 'failed';
					}
				}
				const refreshNamespace = this.options.parameters && (this.options.parameters.refreshNamespace ||
					(this.options.parameters.predefinedFields && this.options.parameters.predefinedFields.refreshNamespace));
				if (refreshNamespace) {
					this.$root.$emit(refreshNamespace + '.searching');
					sc.events.emit(refreshNamespace + '.searching');
				}
			} catch (e) {
				sc.utils.errorMessage.byResponse(e);
				throw e;
			} finally {
				this.notifyEntityChanged('entity.changed', { logicalName: this.logicalName });
				await showToastOrInfoDialog({
					toastMessageKey: this.options.toastMessageKey || 'controls.dragAndDrop.documentsUploaded.toast',
					informationDialogTextKey: this.options.informationDialogTextKey,
					options: { context: this }
				});

				this.state = 'finished';
			}
		}
	}
};
