<template>
	<div class="breadcrumbs">
		<span :class="{'icon': true, 'disabled': disabled}" @click="navigatePrev">
			<svg class='svg-icon svg-12 back-button'>
				<use xlink:href='#layout-icons-details-header-back'></use>
			</svg>
		</span>
		<div class="breadcrumbs-wrapper">
			<div class="breadcrumb-item" v-if="breadcrumbs.length > maxBreadcrumbsLength">
				<div class="title">...</div>
			</div>
			<div :class="{'breadcrumb-item': true, 'disabled': item.data && item.data.disabled}" v-for="(item, index) in filteredBreadcrumbs" :key="index" @click="navigate(item)">
				<div class="title">{{ item.text }}</div>
			</div>
		</div>
	</div>
</template>
<script>

export default {
	name: 'control-breadcrumbs',
	props: {
		maxBreadcrumbsLength: {
			type: Number,
			required: true
		},
		breadcrumbs: Array,
		stateNamespace: {
			type: String,
			required: true
		}
	},
	computed: {
		filteredBreadcrumbs() {
			return this.breadcrumbs.slice(-this.maxBreadcrumbsLength);
		},
		disabled() {
			return this.breadcrumbs.filter(item => (item.data && !item.data.disabled) || true).length === 1;
		},
		currentIndex() {
			return this.breadcrumbs.length - 1;
		}

	},
	methods: {
		navigatePrev() {
			if (this.disabled) {
				return;
			}
			const prev = this.breadcrumbs[this.currentIndex - 1];
			this.navigate(prev);
		},
		navigate(item) {
			if (!item.data.disabled) {
				this.$root.$emit(this.stateNamespace + '.setActiveFolder', item);
			}
		}
	}
};
</script>
<style src="./tree-breadcrumbs.less" scoped></style>
