import { SendRequest } from '@acx-xms/data-functions/dist';
const importPluginPath = '/api/plugins/endpoint/import';

export default {
	async importStatus(fileGuid, total) {
		const progress = {
			total: 0,
			processed: 0,
			message: 'Records are being added',
			title: 'Import records'
		};
		const path = `${importPluginPath}/ImportStatus`;
		const body = { fileGuid };
		progress.total = total;
		return new Promise((resolve, reject) => {
			sc.events.emit('vue.progressDialog.open', progress);
			const exec = async () => {
				const status = await SendRequest(path, 'POST', body);
				progress.processed = status.skipped + status.created + status.updated;
				switch (status.finished) {
				case true: {
					resolve(status);
					break;
				}
				case false: {
					setTimeout(exec, 5000);
					break;
				}
				};
			};
			exec();
		});
	},

	async submit(fileGuid, selectedEntity, fieldMapping) {
		const path = `${importPluginPath}/Submit`;
		const body = {
			fileGuid,
			selectedEntity,
			fieldMapping,
			currentConfiguration: sc.cluster.configKey
		};
		return SendRequest(path, 'POST', body);
	},

	async parseFields(fileGuid, selectedEntity) {
		const lng = sc.classes.get('localization.dataProvider').getCurrentLanguage();
		const path = `${importPluginPath}/ParseFields`;
		const body = {
			fileGuid,
			selectedEntity,
			localization: lng.code || 'en-us',
			browserLanguage: Date.CultureInfo.name,
			currentConfiguration: sc.cluster.configKey
		};
		return SendRequest(path, 'POST', body);
	},

	async getImportedRecords(selectedEntity, fileGuid) {
		const path = `${importPluginPath}/ImportedRecords`;
		const body = {
			selectedEntity,
			fileGuid
		};
		return SendRequest(path, 'POST', body);
	}
};
