<template>
	<div v-if="enable" class="column withRow" v-bind:style="{ width: width || '100%', height: height || 'auto' }">
		<details-row-control v-bind:evaluationContext="evaluationContext" v-bind:row="row"></details-row-control>
	</div>
</template>
<script>
/* eslint no-unused-vars: 0 */
/* eslint vue/no-unused-components: 0 */
import Control from './../../Entity/control';

export default {
	name: 'details-columnWithRow-control',
	props: {
		options: Object,
		evaluationContext: Object
	},
	components: {
		Control,
		'details-row-control': () => import('./details-row-control.vue')
	},
	data() {
		return {
			enable: this.options.enable === void 0 || this.evaluationContext.eval(this.options.enable),
			row: this.options.row,
			width: this.options.width,
			height: this.options.height
		};
	}
};
</script>
<style src="./details-column-control.less" scoped></style>
