import { ValidatePreferenceAspect } from './aspects/validatePreference.aspect';

export class ValidatePreference {
	constructor(options) {
		this.options = options;
	}

	installAspects() {
		return [new ValidatePreferenceAspect(this.options)];
	}
}
