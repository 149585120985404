<template>
	<div class="boolean-value">
		<span class="p4">{{ title }}</span>
		<label class="checkbox-wrapper">
			<input type="checkbox" @change="onChange" :value="currentValue" :checked="currentValue"/>
			<span class="slider round"></span>
		</label>
	</div>
</template>
<script>
export default {
	name: 'boolean',
	props: {
		title: String,
		currentValue: Boolean
	},
	methods: {
		onChange(e) {
			this.$emit('onChange', this.title, e.target.checked);
		}
	}
};
</script>
<style src="./boolean.less" scoped></style>
