var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "password-editor-wrapper" },
    [
      _c("stringeditor", {
        attrs: {
          value: _vm.value,
          enable: _vm.enable,
          showRequired: _vm.showRequired,
          isValid: _vm.isValid,
          type: _vm.type,
          hideText: _vm.hideText,
          dataAttr: _vm.dataAttr,
          isUnhideEnabled: _vm.isUnhideEnabled
        },
        on: { input: _vm.onInputChange, focused: _vm.onFocusChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }