var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "account-wrapper" }, [
    _c(
      "div",
      { staticClass: "account" },
      [
        _c("p", { staticClass: "message" }, [
          _vm._v("Select the account you want to add:")
        ]),
        _vm._v(" "),
        _vm._l(_vm.accountTypes, function(type) {
          return _c(
            "div",
            {
              key: type.Source.name,
              staticClass: "service-account",
              on: {
                click: function($event) {
                  _vm.onClick(type.Source.optionname)
                }
              }
            },
            [
              _c("svg", { staticClass: "svg-icon svg-15" }, [
                _c("use", {
                  staticClass: "icon",
                  attrs: { "xlink:href": "#action-icons-mailchimp-logo" }
                })
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "service-name" }, [
                _vm._v(_vm._s(type.Source.name))
              ])
            ]
          )
        }),
        _vm._v(" "),
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isLoading,
              expression: "isLoading"
            }
          ],
          staticClass: "loading-overlay"
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }