<template>
	<div class="create-relation-dialog editor">
		<div class="loading-overlay large" v-show="isLoading"></div>
		<div class="entity-list">
			<control class="formcontrol-template"
					 v-if="documentRelatedRecordsTemplate && !isLoading"
					 :name="documentRelatedRecordsTemplate.$type"
					 :contentProps="documentRelatedRecordsTemplate"
					 :initResults="documentRelatedRecords"></control>
		</div>
		<div class="footer">
			<div class="dialog-buttons">
				<button @click="cancel"
					class="cancel"
					type="button"
					v-localization="{key: 'common.dictionary.buttons.cancel'}"
					v-data-attr="{title: options.title, $type: 'dialog.button.cancel'}">
				</button>
			</div>
		</div>
	</div>
</template>
<script>
import Control from '@/Components/Entity/control.vue';
import sharedToMixin from './sharedTo.mixins.js';

export default {
	name: 'shared-to-dialog',
	components: { Control },
	props: { options: Object },
	mixins: [sharedToMixin],
	data() {
		return {
			isLoading: true,
			relateFromRecord: this.options.params.relateFromRecord,
			documentRelatedRecordsTemplate: null,
			documentRelatedRecords: null
		};
	}
};
</script>
<style src="@/Components/CreateRelation/create-relation-dialog.less" scoped></style>
