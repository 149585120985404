<template>
	<div class="searchResults-rowsview">
		<div v-if="results && results.length && withCheckAll" class="searchResults-map-view-header tableview-header">
			<ControlSelectAll
				:stateNamespace="stateNamespace"
				:results="results"
				:withCheckAll="withCheckAll"
				:isParent="options.isParent"
				:syncSelection="options.syncSelection"
				:targetEntity="targetEntity"
			/>
			<div>
				<span v-localization="{ key: 'common.dictionary.selectAll' }" />
			</div>
		</div>
		<div :class="['rowsview-wrapper', options.cssClass, { 'without-tags': !options.withSelectionList }]">
			<TagList
				v-if="options.withSelectionList && stateNamespace && targetEntity && results && results.length"
				:stateNamespace="stateNamespace"
				:entityNamespace="targetEntity && targetEntity[0]"
			/>
			<div
				v-if="results && results.length"
				v-perfectscroll="{ enable: true, onScroll: onScroll, scrollToTopEvent }"
				class="perfectscroll-wrapper search-results-row-items-wrapper"
			>
				<div :class="['sc-grid', 'columns-'+columns]">
					<div
						v-for="item in results"
						:key="recordKey(item)"
						class="grid-cell column-span-1 item"
					>
						<div class="item-wrapper">
							<ControlMapViewRow
								:options="options"
								:stateNamespace="stateNamespace"
								:rowItem="item"
							/>
						</div>
					</div>
				</div>
			</div>
			<div v-show="!results || !results.length" class="no-results-message">
				<div v-localization="{ key: 'common.dictionary.noMatchesFound' }" />
				<div v-localization="{ key: 'common.dictionary.noMatchesFoundSubTitle' }" />
			</div>
		</div>
		<Pagination
			:pageSize="paginationParams.pageSize"
			:total="paginationParams.total"
			:from="paginationParams.from"
			:activePages="paginationParams.activePages"
		/>
	</div>
</template>
<script>
import ComponentSetSearchResultsMapViewBase from '../map-view/search-results-map-view-base.mixins';
import ComponentMixins from '@/Components/component.mixins';

const TagList = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */'@/Components/Common/TagsList/tags-list');
const Pagination = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */'../../../pagination/pagination');
const ControlMapViewRow = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */'../map-view/map-view-row');
const ControlSelectAll = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */'../../../../Control/Checkboxes/control-select-all');

export default {
	name: 'component-set-search-results-rowsview',
	mixins: [ComponentSetSearchResultsMapViewBase],
	components: {
		Pagination,
		ControlMapViewRow,
		ControlSelectAll,
		TagList
	},
	methods: {
		recordKey(item) {
			return item.Source ? ComponentMixins.recordKey(item) : item.templateId;
		}
	}
};
</script>
<style src='./search-results-rows-view.less' scoped></style>
