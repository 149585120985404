import * as tracking from '@/Data/trackingModule';

export default {
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return {
			visible: this.options.visible !== void 0 ? this.evaluationContext.eval(this.options.visible) : true,
			url: '',
			title: ''
		};
	},
	async created() {
		this.title = this.options.title ? await this.evaluationContext.evalAsync(this.options.title) : '';
		this.dataProvider = sc.classes.get(this.options.dataProvider.concat('.dataProvider'));
		this.value = this.evaluationContext.eval(this.options.value);
		this.url = this.dataProvider.getFileUrl(this.value, null, this.evaluationContext.entity.logicalname);
		if (this.url) {
			// token parameter to handle authentication
			this.url += (this.url.indexOf('?') > -1) ? '&' : '?';
			this.url += 'token=' + localStorage.getItem(sc.classes.get('authentication').getTokenKey(sc.cluster.key));
		}
	},
	methods: {
		trackActivity() {
			tracking.AddUserActivity('download.document', 'Document download', this.value, this.evaluationContext.eval(this.options.parentRecord));
		}
	}
};
