import ControlMixins from '@/Components/Editor/Controls/control.mixins';
import { Search } from '@acx-xms/data-functions/dist';
import VueEditor from '@/Actions/action.vue.editor';

export default {
	mixins: [ControlMixins],
	async created() {
		this.isStandartLookup = this.options.entityTypes && this.options.entityTypes.some(x => x.startsWith('lookup'));
		const skip = this.evaluationContext.eval(this.options.skipDefaultValueIfValueExists) || false;

		if (this.isStandartLookup && this.initEntity && !Object.keys(this.initEntity).length && !skip) {
			const configFilters = this.options.filters
				? this.options.filters.map((filter) => {
					return sc.classes.get(filter.$type, filter, this.evaluationContext).toFilter();
				})
				: [];
			const filters = [
				sc.classes.get('offsetSize.filter', 1),
				sc.classes.get('termFacet.filter', {
					logicalName: 'default',
					query: [true],
					negative: false
				}).fillQuery(),
				sc.classes.get('termFacet.filter', {
					logicalName: 'recordstate.id',
					query: ['9e817a63-452c-e811-8320-7824af3b452f'],
					negative: true
				}).fillQuery(),
				sc.classes.get('selectedFields.filter', []).fillQuery(),
				...configFilters.filter(filter => filter !== null)
			];
			const defaultValueResponse = await Search(this.options.entityTypes, filters);
			const defaultValue = defaultValueResponse.Results.length ? defaultValueResponse.Results[0] : null;

			if (defaultValue) {
				this.wrappedValue = {
					data: defaultValue,
					displayName: defaultValue.Name
				};
			} else {
				if ((this.storeState && !this.storeState.entityData[this.field]) || !this.storeState) {
					this.wrappedValue = null;
				}
			}
		}

		if (this.options.lookupNameField) {
			this.displayName = this.evaluationContext.eval(this.options.lookupNameField);
		}

		this.logicalName = this.options.entityTypes && this.options.entityTypes[0];
		if (this.options.allowQuickAdd && this.logicalName) {
			const entityCaptionsFromMetadata = await sc.classes.get('entityConfiguration.dataProvider').getEntityCaptionsFromMetadata([this.logicalName], 'singular');
			if (entityCaptionsFromMetadata && entityCaptionsFromMetadata.length) {
				const entityDisplayName = entityCaptionsFromMetadata[0].base || this.logicalName;
				this.quickCreateButtonName = `Create New ${entityDisplayName}`;
			}

			this.$root.$on('entity.created', ({ entity }) => {
				if (this.logicalName === entity.logicalname) {
					this.wrappedValue = {
						data: entity.toObject(),
						displayName: entity.name
					};
				}
			});
		}
	},
	computed: {
		wrappedValue: {
			get() {
				return this.value
					? {
						displayName: this.displayName,
						data: this.value
					}
					: null;
			},
			set(val) {
				if (val) {
					this.value = val.data ? sc.classes.get('entityReference', val.data) : val.value;
					this.displayName = val.displayName;
				} else {
					this.value = null;
					this.displayName = '';
				}
			}
		}
	},
	methods: {
		quickCreate() {
			const lookupRootName = this.lookupRootName || (this.options.rootName ? this.evaluationContext.eval(this.options.rootName) : '');
			const actionOptions = {
				$type: 'action.vue.editor',
				mode: 'quick',
				logicalname: {
					$type: 'expression.text',
					value: this.logicalName
				},
				subtype: {
					$type: 'expression.text',
					value: 'quick-edit'
				},
				source: 'noSelection'
			};
			if (this.parentRecord) {
				actionOptions.parameter = [
					{
						$type: 'action.actionParameter',
						name: `${this.parentRecord.logicalname}id`,
						value: {
							$type: 'expression.object',
							property: [
								{
									$type: 'expression.objectProperty',
									key: {
										$type: 'expression.text',
										value: 'logicalname'
									},
									value: {
										$type: 'expression.text',
										value: this.parentRecord.logicalname
									}
								},
								{
									$type: 'expression.objectProperty',
									key: {
										$type: 'expression.text',
										value: 'id'
									},
									value: {
										$type: 'expression.text',
										value: this.parentRecord.id
									}
								}
							]
						}
					},
					{
						$type: 'action.actionParameter',
						name: 'lookupRootName',
						value: {
							$type: 'expression.text',
							value: lookupRootName
						}
					}
				];
			}

			VueEditor.execute(actionOptions, this.evaluationContext);
		}
	}
};
