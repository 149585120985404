var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "import-template-dialog" }, [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ],
      staticClass: "loading-overlay large"
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "select-record" },
      [
        _c("file-to-json", {
          staticClass: "control-wrapper",
          attrs: {
            options: _vm.options,
            showRequiredMark: !_vm.uploadedFileContent
          },
          model: {
            value: _vm.uploadedFileContent,
            callback: function($$v) {
              _vm.uploadedFileContent = $$v
            },
            expression: "uploadedFileContent"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c("div", { staticClass: "dialog-buttons" }, [
        _c("button", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "common.dictionary.buttons.cancel" },
              expression: "{key: 'common.dictionary.buttons.cancel'}"
            }
          ],
          staticClass: "cancel",
          attrs: { type: "button" },
          on: { click: _vm.close }
        }),
        _vm._v(" "),
        _c("button", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "dialogs.import" },
              expression: "{key: 'dialogs.import'}"
            }
          ],
          attrs: { type: "button", disabled: !this.uploadedFileContent },
          on: { click: _vm.save }
        })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }