<template>
    <div class='select-template-table'>
        <div class='select-template-table-header'>
            <div class='select-template-table-col'>
                Template
            </div>
            <div class='select-template-table-col'>
                Input Type
            </div>
            <div class='select-template-table-col'>
                Actions
            </div>
        </div>
        <div class='select-template-table-content' v-perfectscroll='{ enabled: true, onScroll }'>
            <div>
                <div
                    v-if="deletedTemplate"
                    class="select-template-table-item deleted"
                >
                    <div class='dot'></div>
                    <div class='select-template-table-col'>
                        <div class='select-template-table-item-img'>
                            <img :src='deletedTemplate.preview' />
                        </div>
                        <div class='select-template-table-text'>{{deletedTemplate.templateName || "Template is deleted"}}</div>
                        <InfoBadge class="info-icon" :tooltipText="deletedTemplateTooltipText" />
                    </div>
                    <div class='select-template-table-col'>
                        {{deletedTemplate.inputTypes}}
                    </div>
                    <div class='select-template-table-col'></div>
                </div>
                <div v-for='(item, key) in templates' :key='key'
                    :class="['select-template-table-item', {
                        selected: getTemplates.find(i => i.templateId === item.templateId) && getTemplates.find(i => i.templateId === item.templateId).selected,
                        deleted: getTemplates.find(i => i.templateId === item.templateId) && getTemplates.find(i => i.templateId === item.templateId).deleted
                    }]"
                    @click='click(item.templateId)'
                >
                    <div class='dot'></div>
                    <div class='select-template-table-col'>
                        <div class='select-template-table-item-img'>
							<!-- TODO: rework to component -->
                            <img :src='item && item.preview ? item.preview : `/Images/co-default-thumbnail.png`'/>
                        </div>
                        <div class='select-template-table-text'>{{item.templateName}}</div>
                    </div>
                    <div class='select-template-table-col'>
                        {{item.inputTypes}}
                    </div>
                    <div class='select-template-table-col'>
                        <action-menu
                            :hasDropDownItems="true"
                            :options="getMenuOptions(item)"
                            :record="{ Id: item.templateId, Type: 'template' }"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ActionMenu from '@/Components/Menu/action-menu';
import { InfoBadge } from "@acx-xms/components/dist";
import { GetUserInfo } from '@/Data/Auth/authentication-service';


export default {
	name: 'select-templates-table',
    components: { ActionMenu, InfoBadge },
	props: {
		templates: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
            deletedTemplate: null,
            isManager: false,
            userId: null,
            scrollNamespace: 'email-campaign-wizard-template'
		};
	},
	computed: {
		...mapState('wizard', ['steps']),
		getTemplates() {
			return this.steps.find(i => i.name === 'template').templates || null
		},
		isArtifactAvailable() {
			return this.steps.find(i => i.name === 'template').artifact;
		},
		deletedTemplateTooltipText() {
			return this.isArtifactAvailable ?
			'<div>This template has been deleted, but publication for the selected items has been generated.</div><div>If you don’t want to change anything you can start an email campaign even with a deleted template.</div>' :
			'<div>This template has been deleted. Please, select another one to proceed with email campaign preparation</div>';
		},
	},
    watch: {
        getTemplates(newVal, oldVal) {
            if(newVal.some(i => i.deleted)) {
                this.deletedTemplate = newVal.find(i => i.deleted)
            } else {
                this.deletedTemplate = null
            }
        }
    },
    async created() {
        const userInfo = await GetUserInfo();
        this.isManager = userInfo.CRMRoles.some(role => role.Name === 'OHC_MANAGER');
        this.userId = userInfo.Id;
    },
	methods: {
		...mapActions('wizard', ['updateSelectedTemplate', 'removeError']),
		click(id) {
			if (this.getTemplates.find(i => i.templateId === id) && this.getTemplates.find(i => i.templateId === id).deleted) return;
			this.updateSelectedTemplate(id);
			this.removeError({name: 'templateExist', step: 'template'});
		},
		onScroll() {
			this.$root.$emit(`${this.scrollNamespace}.scrolled`);
        },
        getMenuOptions(item) {
            let actionText = (this.isManager || item.creatorId === this.userId) ? 'Manage' : 'View';
            return {
                enable: true,
                entityType: 'template',
                menu: {
                    item: [{
                        $type: 'menu.menuItem',
                        default: false,
                        display: 'text',
                        text: {
                            $type: 'expression.text',
                            value: `${actionText} in Composer Online`
                        },
                        action: {
                            $type: 'action.openComposerEntityDetails',
                            source: 'singleSelection',
                        }
                    }]
                },
                scrollNamespace: 'email-campaign-wizard-template'
            };
        }
	}
};
</script>

<style scoped lang='less'>
@import './selectTemplatesTable.less';
</style>
