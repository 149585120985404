var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        { class: ["row", _vm.cssClass] },
        _vm._l(_vm.mappedRow.column, function(column) {
          return _c(column.columnType, {
            key: column.$key,
            tag: "component",
            attrs: { options: column, evaluationContext: _vm.evaluationContext }
          })
        })
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }