<template>
	<div>
		<control-lookup :evaluationContext="evaluationContext" :options="getLookupOptions()" />
		<div v-perfectscroll="{ enable: true }" class="filter-content panel-content">
			<div v-if="isLoading" class="loading-overlay" />
			<div
				v-else
				class="filter-list-holder"
			>
				<ul v-if="items.length" class="filter-list">
					<li
						v-for="item in items"
						v-data-attr="{ options, stateNamespace: item.Name }"
						:key="recordKey(item)"
						class="item"
					>
						<div @click="applySearch(item)">
							<ControlSvgIcon
								v-if="isShared(item) && logicalName !== 'sharedSearches'"
								class="layout-icon"
								:options="{ name: 'layout-icons-shared-saved-search' }"
								:evaluationContext="evaluationContext"
							/>
							<span>
								{{ item.Name }}
							</span>
						</div>
						<action-menu
							v-if="!!options.menu"
							:options="getMenuParams(item)"
							:record="getMenuParams(item).selection"
							:evaluationContext="evaluationContext"
							class="without-padding without-border"
						/>
					</li>
				</ul>
				<div
					v-else
					v-localization=" { key: 'filtersPanel.unavailable' }"
					class="warning-message"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import '@/Bindings/ui-attribute.directive';
import helperMethods from '@/Components/ComponentSet/component-set-evaluation-context-helper.methods';
import FilterInfoListsMixins from './filter.info.lists.mixins';
import ActionMenu from '@/Components/Menu/action-menu';
import ComponentMixins from '@/Components/component.mixins';
const ControlLookup = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Lookup/control-lookup');
const ControlSvgIcon = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Images/control-svg-icon');

export default {
	name: 'saved-search-info-panel',
	mixins: [FilterInfoListsMixins],
	props: {
		options: Object,
		evaluationContext: Object,
		stateNamespace: String
	},
	components: {
		ControlLookup,
		ControlSvgIcon,
		ActionMenu
	},
	data() {
		return {
			isLoading: false,
			items: [],
			savedSearchEntity: helperMethods.eval(this.evaluationContext, this.options.savedSearchEntity),
			entity: helperMethods.eval(this.evaluationContext, this.options.entity)
		};
	},
	methods: {
		recordKey(item) {
			return ComponentMixins.recordKey(item);
		},
		getMenuParams(item) {
			if (!this.menuParams[item.Id]) {
				this.menuParams[item.Id] = {
					menu: this.options.menu,
					namespace: this.stateNamespace,
					internalNamespace: 'mySearches',
					selection: item
				};
			}
			return this.menuParams[item.Id];
		},
		isShared(item) {
			return item && item.Source.ReadAccessList && item.Source.ReadAccessList.Shared && item.Source.ReadAccessList.Shared.length;
		},
		getLookupOptions() {
			return {
				fetchData: (searchQuery, startFrom, pageSize) => {
					startFrom = startFrom || 0;
					pageSize = pageSize || 1000;
					const filters = [
						...this.options.savedSearchFilters,
						sc.classes.get('offsetFrom.filter', startFrom),
						sc.classes.get('offsetSize.filter', pageSize),
						sc.classes.get('selectedFields.filter', [
							{ logicalname: 'ReadAccessList' },
							{ logicalname: 'requests' }
						])
					];
					if (searchQuery) {
						filters.push(sc.classes.get('field.filter', {
							logicalName: '_all',
							query: searchQuery
						}));
					}
					return this.dataProvider.search({
						filters,
						entities: [this.savedSearchEntity]
					}).then(({ Results, Total }) => {
						return {
							total: Total,
							items: Results.map(res => {
								return {
									displayName: res.Name,
									data: res
								};
							})
						};
					});
				},
				singleSelectionOnArray: this.selectSearch
			};
		},

		applySearch(item) {
			this._applySearch(item);
		},

		selectSearch(item) {
			this._applySearch(item.data);
		}
	},
	created() {
		this.fork = sc.events.fork();
		this.dataProvider = sc.classes.get('edge.dataProvider');
		this.logicalName = helperMethods.eval(this.evaluationContext, this.options.logicalName);
		this.menuParams = {};
		this._applySearch = (item) => {
			const request = sc.utils.findProperty(item, `Source.${this.options.savedSearchRequestField}`, true);
			if (request) {
				let savedSearchData = {};
				try {
					savedSearchData = JSON.parse(request);
				} catch (err) {
					console.error(err);
				}

				this.$store.commit(this.stateNamespace + '/replaceUiFilters', {
					entity: this.entity,
					uiFilters: savedSearchData.uiFilters
				});
				this.$store.commit(this.stateNamespace + '/replaceFilters', {
					entity: this.entity,
					filters: savedSearchData.filters
				});
				this.$root.$emit(this.stateNamespace + 'search', false, savedSearchData.queryString);
				this.$store.commit(this.stateNamespace + '/openInfoPanel', null);
			}
		};
		this._updateList = () => {
			this.isLoading = true;
			this.dataProvider.search({
				filters: [
					...this.options.savedSearchFilters,
					sc.classes.get('offsetSize.filter', 1000)
				],
				selectedFieldsSection: 'savedSearchInfoPanel',
				entities: [this.savedSearchEntity]
			}).done(({ Results }) => {
				this.items = Results;
				this.menuParams = {};
			}).always(() => {
				this.isLoading = false;
			});
		};
		this._updateList();
		// todo change using vue events
		this.fork.on(['entity.created', 'entity.changed', 'entity.deleted'], data => {
			if (this.savedSearchEntity === data.entity.logicalname) {
				this._updateList();
			}
		});
	},
	unmounted() {
		if (this.fork) {
			this.fork.dispose();
		}
	}
};
</script>
<style scoped>
	ul.filter-list > li.item {
		min-height: 34px;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		cursor: pointer;
	}

		ul.filter-list > li.item > .svg-icon {
			flex-shrink: 0;
			margin-right: 5px;
		}

		ul.filter-list > li.item > div > span {
			flex-grow: 1;
			overflow: hidden;
			margin-right: 5px;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		ul.filter-list > li.item > div > svg {
			width: 18px;
			height: 18px;
			vertical-align: bottom;
		}
</style>
<style src="./panels.less" scoped></style>
