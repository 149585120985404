<template>
	<div class="value">
		<span class="title p4">{{ title }}</span>
		<input type="text" @change="onChange" :value="currentValue" />
	</div>
</template>

<script>
export default {
	name: 'value',
	props: {
		title: String,
		currentValue: [Number, String]
	},
	methods: {
		onChange(e) {
			this.$emit('onChange', this.title, e.target.value);
		}
	}
};
</script>
<style src="./value.less" scoped></style>
