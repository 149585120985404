var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dialog",
      class: _vm.classes,
      style: { right: _vm.offsetX }
    },
    [
      _c(
        "div",
        {
          staticClass: "dialog-wrapper",
          class: { "hide-close": _vm.hideClose }
        },
        [
          _c(
            "div",
            {
              staticClass: " dialog-window",
              class: { active: _vm.active },
              style: { "max-width": _vm.dialogWidth, height: _vm.dialogHeight }
            },
            [
              _c("dialog-header", {
                attrs: {
                  dialogOptions: {
                    id: _vm.id,
                    title: _vm.initDialog.title,
                    localizedTitle: _vm.initDialog.localizedTitle,
                    icon: _vm.initDialog.icon,
                    onClose: _vm.onCloseCallback,
                    status: _vm.status,
                    allowClose: _vm.allowClose
                  }
                }
              }),
              _vm._v(" "),
              _c(_vm.prepareComponentName(_vm.initDialog.component), {
                tag: "component",
                staticClass: "dialog-content",
                attrs: { dialogId: _vm.id, options: _vm.initDialog },
                on: {
                  removeClose: function($event) {
                    _vm.hideClose = true
                  },
                  closeDialog: function($event) {
                    _vm.onClose(_vm.initDialog.id)
                  }
                }
              })
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }