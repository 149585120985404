var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "control-stringeditor" }, [
    _vm.fieldTitle
      ? _c("div", { staticClass: "stringeditor-title" }, [
          _vm._v("\n            " + _vm._s(_vm.fieldTitle) + "\n        ")
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "input",
        class: {
          disabled: !_vm.enable,
          invalid: !_vm.isValid,
          "splitted-input": _vm.adValue
        }
      },
      [
        _c("div", { staticClass: "actions before" }, [
          _vm.showRequired
            ? _c("div", { staticClass: "required-mark" }, [
                _c(
                  "svg",
                  { staticClass: "svg-icon svg-8 layout-icons-required" },
                  [
                    _c("use", {
                      attrs: { "xlink:href": "#layout-icons-required" }
                    })
                  ]
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "data-attr",
              rawName: "v-data-attr",
              value: _vm.dataAttr || {},
              expression: "dataAttr || {}"
            }
          ],
          ref: "input" + _vm.type,
          class: {
            required: _vm.showRequired,
            grey: _vm.color === "grey",
            "hidden-text": _vm.hideText
          },
          attrs: {
            type: _vm.localType,
            placeholder: _vm.placeholder,
            disabled: !_vm.enable,
            maxlength: _vm.maxLength
          },
          domProps: { value: _vm.localValue },
          on: {
            focus: function($event) {
              _vm.focused = true
            },
            blur: function($event) {
              _vm.onInputBlur($event)
            },
            keyup: _vm.onKeyUp,
            change: _vm.adjustValue
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "actions after" }, [
          _vm.isUnhideEnabled
            ? _c(
                "div",
                {
                  staticClass: "action unhide",
                  style: { right: _vm.value ? "30px" : "10px" },
                  attrs: { tabindex: "-1" },
                  on: { focus: _vm.onUnhideClick }
                },
                [
                  _vm.localType === "password"
                    ? _c("svg", { staticClass: "svg-icon" }, [
                        _c("use", {
                          attrs: { "xlink:href": "#ui-icon-show-password-icon" }
                        })
                      ])
                    : _c("svg", { staticClass: "svg-icon" }, [
                        _c("use", {
                          attrs: {
                            "xlink:href": "#ui-icon-show-password-active-icon"
                          }
                        })
                      ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !!_vm.localValue && _vm.enable
            ? _c(
                "div",
                { staticClass: "action", on: { click: _vm.clear } },
                [
                  _c("control-svg-icon", {
                    attrs: { options: { name: "layout-icons-textbox-delete" } }
                  })
                ],
                1
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm.adValue
          ? _c(
              "div",
              { staticClass: "additional-value" },
              [
                _c("ControlLookupEditor", {
                  attrs: {
                    temporarySelectLookup: true,
                    temporarySelectLookupItems: _vm.adValue,
                    options: _vm.adValueOptions,
                    showRequired: false
                  },
                  on: { input: _vm.temporaryInputHandler }
                })
              ],
              1
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }