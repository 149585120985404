var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass: "custom-checkbox mobile tooltip",
      class: _vm.isAnonymous && "disabled"
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.selected,
            expression: "selected"
          }
        ],
        ref: "input",
        attrs: { type: "checkbox" },
        domProps: {
          checked: Array.isArray(_vm.selected)
            ? _vm._i(_vm.selected, null) > -1
            : _vm.selected
        },
        on: {
          change: function($event) {
            var $$a = _vm.selected,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.selected = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.selected = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.selected = $$c
            }
          }
        }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "value" }, [
        _c("span", { staticClass: "value-checked" }, [
          _c("span", { staticClass: "value-title" }),
          _vm._v(" "),
          _c("svg", { staticClass: "svg-icon svg-15" }, [
            _c("use", {
              staticClass: "boolean-icon layout-icons-bool-editor-on",
              attrs: { "xlink:href": "#layout-icons-bool-editor-on" }
            })
          ])
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "value-unchecked" }, [
          _c("span", { staticClass: "value-title" }),
          _vm._v(" "),
          _c("svg", { staticClass: "svg-icon svg-15" }, [
            _c("use", {
              staticClass: "boolean-icon layout-icons-bool-editor-off",
              attrs: { "xlink:href": "#layout-icons-bool-editor-off" }
            })
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }