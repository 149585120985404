<template>
	<div class="action-menu popup-component">
		<div v-data-attr class="dropdown-wrapper">
			<div class="top-right-triangle" />
			<div class="items">
				<div
					v-for="(field, index) in fields"
					:key="`${field.logicalname}-${index}`"
					:class="['menu-item', { active: field.isSelected }]"
					@click="sort(field)"
				>
					{{ field.displayName }}
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import '@/Bindings/ui-attribute.directive';

export default {
	name: 'sortpanel-dropdown',
	props: { options: Object },
	computed: {
		fields() {
			return this.options.filterFields.filter(field => field.visible);
		}
	},
	methods: {
		sort(field) {
			this.$root.$emit('templatePopup.close.sortPanel');
			this.options.onSelect && this.options.onSelect(field);
		}
	}
};
</script>
<style src="./../Menu/action-menu.less" scoped></style>
