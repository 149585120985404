<template>
	<div class="create-dealroom-dialog editor">
		<div class="loading-overlay" v-show="isLoading"></div>
		<div class="body" v-perfectscroll="{enable: true}">
			<control v-if="editorTemplate" :name="editorTemplate.$type"
					 :contentProps.once="editorTemplate"
					 :evaluationContext="controlContext"></control>
		</div>

		<div class="footer">
			<div class="dialog-buttons">
				<button
					@click="cancel"
					class="cancel" type="button"
					v-localization="{key: 'common.dictionary.buttons.cancel'}"
					v-data-attr="{title: options.title, $type: 'dialog.button.cancel'}">
				</button>
				<button
					@click="save"
					type="button"
					v-localization="{key: 'common.dictionary.buttons.save'}"
					v-data-attr="{title: options.title, $type: 'dialog.button.submit'}">
				</button>
			</div>
		</div>
	</div>
</template>
<script>
import Control from '@/Components/Entity/control.vue';
import { Create, Update } from '@acx-xms/data-functions/dist';
import createDocumentMixins from '@/Components/editor.mixins.js';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';

export default {
	name: 'create-dealroom-dialog',
	components: { Control },
	props: { options: Object },
	mixins: [createDocumentMixins],
	data() {
		return {
			editorTemplate: null,
			saveButtonEnabled: false,
			isLoading: false,
			stateKey: null,
			progress: {}
		};
	},
	async created() {
		this.logicalName = 'dealroom';
		this.localization = sc.classes.get('localization.dataProvider');
		this.stateKey = this.registerModule(this.logicalName);
		this.editorTemplate = this.options.params.template;
		this.controlContext = createEvaluationContext({ Type: this.logicalName });

		const availabilityCount = this.options.params.availabilitiesSelection ? this.options.params.availabilitiesSelection.length : 0;
		this.progress = {
			total: availabilityCount + 3,
			processed: 0,
			message: await this.localization.getLabelForCurrentLanguage('CreateDealRoomPlugin.progress.message'),
			title: await this.localization.getLabelForCurrentLanguage('CreateDealRoomPlugin.progress.title')
		};
	},
	methods: {
		async processNda() {
			const data = this.$store.state[this.stateKey].entityData;
			if (!data.ndarequired) {
				return null;
			}
			let documentRef = null;
			let documentTitle = '';
			if (!data.ndaid.isExisting && this.file) {
				this.progress.total++;
				const document = await this.createRecord('document',
					{
						category: 'NDA',
						filename: data.ndaid.uploadedFileName,
						title: data.ndaid.uploadedFileName.split('.').slice(0, -1).join('.')

					}, this.file);

				documentRef = sc.classes.get('entityReference', document);
				documentTitle = document.title;
			} else {
				documentRef = sc.classes.get('entityReference', data.ndaid.selectedFile.data);
				documentTitle = documentRef.name;
			}
			return {
				type: 'shareddocument',
				title: documentTitle,
				documentid: documentRef,
				nda: true
			};
		},
		async save() {
			const validationResult = await this.validate(this.stateKey);
			if (!validationResult.isValid) {
				this.showValidationError(validationResult.message);
				return;
			}

			this.openPogressDialog();
			const data = this.$store.state[this.stateKey].entityData;
			this.isLoading = true;

			const sharedDocBody = await this.processNda();
			let shareddoc = null;
			if (sharedDocBody) {
				shareddoc = await this.createRecord(sharedDocBody.type, sharedDocBody);
			} else {
				this.progress.processed++;
			}

			const dealroomBody = {
				type: 'dealroom',
				name: data.name,
				ndaid: shareddoc ? sc.classes.get('entityReference', shareddoc) : null,
				listingid: this.options.params.dealroomListing,
				propertyid: this.options.params.dealroomProperty
			};

			const dealroom = await this.createRecord(dealroomBody.type, dealroomBody);
			const dealroomRef = sc.classes.get('entityReference', dealroom);

			if (shareddoc) {
				await Update(sharedDocBody.type, shareddoc.Id, { dealroomid: dealroomRef });
			}

			await this.createRecord('chat', {
				dealroomid: dealroomRef,
				name: dealroomBody.name,
				public: true,
				teamid: dealroom.teamid,
				coownerteamid: dealroom.coownerteamid,
				brokerteamid: dealroom.brokerteamid,
				isroomwithnda: !!dealroom.ndaid,
				ndaid: dealroom.ndaid,
				type: 'chat'
			});

			const createDRAvailabilities = async (availabilities) => {
				return await Promise.all(availabilities.map(async (availability) => {
					return await this.createRecord('dravailability', {
						type: 'dravailability',
						dealroomid: dealroomRef,
						availabilityid: sc.classes.get('entityReference', {
							...availability,
							Type: 'availability'
						}),
						name: availability.name || availability.Name,
						teamid: dealroom.teamid,
						brokerteamid: dealroom.brokerteamid
					});
				}));
			};

			if (this.options.params.availabilitiesSelection) {
				await createDRAvailabilities(this.options.params.availabilitiesSelection);
			}

			this.isLoading = false;
			const openDetailsCallback = () => {
				this.options.params.combinedNamespace && this.$root.$emit(this.options.params.combinedNamespace + '.searching');

				this.$root.$emit('showDetails', dealroomRef);
			};

			this.$parent.$emit('close');
			if (!this.options.params.dealroomListing) {
				const title = await this.localization.getLabelForCurrentLanguage('crAddListingPlugin.title');
				sc.events.emit('vue.dialog.open', {
					icon: 'entity-icons-deal-small',
					title,
					allowPin: false,
					component: 'ms.addlisting.dialog',
					allowClose: false,
					maximizedWidth: '90%',
					maximizedHeight: '100%',
					params: {
						componentSet: this.options.params.addListingComponent,
						parentRecord: dealroomRef,
						searchResultComponentName: this.options.params.searchResultComponentName,
						modalDialogCallback: async (listings, availabilities) => {
							if (!listings || listings.length < 1) return;
							this.isLoading = true;
							const listing = listings.pop();
							this.progress = {
								total: availabilities ? availabilities.length + 1 : 1,
								processed: 0,
								message: await this.localization.getLabelForCurrentLanguage('crAddListingPlugin.progress.message'),
								title: await this.localization.getLabelForCurrentLanguage('crAddListingPlugin.progress.title')
							};
							this.openPogressDialog();
							await Update(dealroomRef.logicalname, dealroomRef.id, {
								listingid: sc.classes.get('entityReference', listing),
								propertyid: listing.Source.propertyid
							});
							this.progress.processed++;

							if (availabilities && availabilities.length > 0) {
								this.progress.total = availabilities.length;
								this.progress.processed = 0;
								await createDRAvailabilities(availabilities);
							}
							this.isLoading = false;
							openDetailsCallback();
						},
						cancelCallback: openDetailsCallback,

						validateSelection: function (selection) {
							if (!selection.listing || selection.listing.length !== 1) { return false; } else { return true; }
						},

						allowCancel: {
							value: false,
							errorMessageKey: 'CreateDealRoomPlugin.error.onCancelError'
						},
						namespace: null,
						internalNamespace: null,
						modalNamespace: 'addlistingpopup',
						preferenceRecord: null,
						filterMappingRules: null,
						crListingEntity: 'drlisting',
						crAvailabilityEntity: 'dravailability'
					}
				});
			} else {
				openDetailsCallback();
			}
		},
		createRecord(entity, data, file) {
			return Create(entity, data, file)
				.then((results) => {
					this.progress.processed++;
					return results;
				})
				.catch((e) => {
					this.progress.total--;
					sc.utils.errorMessage.byMessage(e);
				});
		},
		openPogressDialog() {
			sc.events.emit('vue.progressDialog.open', this.progress);
		},
		cancel() {
			this.$parent.$emit('close');
		}
	}
};
</script>
<style src="./create-dealroom-dialog.less" scoped></style>
