<template>
	<div class="control-geocoordinateseditor" :class="this.options.orientation">
		<div class="latitude">
			<div class="field-title">Latitude</div>
			<div class="input" :class="{'disabled': !enable, 'invalid': !isLatValid}">
				<input @focus="focusedlat = true" @blur="focusedlon = false" v-model.lazy="valuelat" type="number" @change="adjustValueLat" />
				<div class="actions after">
					<div class="action" @click="clearLat" v-if="!!valuelat">
						<control-svg-icon :options="{ name: 'layout-icons-textbox-delete' }" />
					</div>
				</div>
			</div>
		</div>
		<div class="longitude">
			<div class="field-title">Longitude</div>
			<div class="input" :class="{'disabled': !enable, 'invalid': !isLonValid}">
				<input @focus="focusedlon = true" @blur="focusedlon = false" v-model.lazy="valuelon" type="number" @change="adjustValueLon" />
				<div class="actions after">
					<div class="action" @click="clearLon" v-if="!!valuelon">
						<control-svg-icon :options="{ name: 'layout-icons-textbox-delete' }" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
/* eslint no-useless-escape: 0 */
import ControlMixins from '@/Components/Editor/Controls/control.mixins';
const ControlSvgIcon = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Images/control-svg-icon');
export default {
	name: 'control-geocoordinateseditor',
	mixins: [ControlMixins],
	components: { ControlSvgIcon },
	data() {
		return {
			focusedlon: false,
			focusedlat: false
		};
	},
	computed: {
		valuelat: {
			get() {
				return this.getFieldLat();
			},
			set(value) {
				this.setFieldLat(value);
			}
		},
		valuelon: {
			get() {
				return this.getFieldLon();
			},
			set(value) {
				this.setFieldLon(value);
			}
		},
		isLatValid() {
			if (this.storeState) {
				return this.storeState.validFields[this.field];
			}
			return true;
		},
		isLonValid() {
			if (this.storeState) {
				return this.storeState.validFields[this.field];
			}
			return true;
		}
	},
	methods: {
		setFieldLat(value) {
			this.setFld(value, this.options.latField);
		},
		setFieldLon(value) {
			this.setFld(value, this.options.lonField);
		},
		setFld(value, field) {
			const fldValue = this.getField() || {};
			fldValue[field] = value;

			const checkIfValid = (val) => {
				if (val === 0) { return true; }

				return !!val;
			};

			this.setField(!checkIfValid(fldValue[this.options.latField]) && !checkIfValid(fldValue[this.options.lonField]) ? null : fldValue);
		},

		getFieldLat() {
			return (this.getField() || {})[this.options.latField];
		},
		getFieldLon() {
			return (this.getField() || {})[this.options.lonField];
		},

		async fieldRegistration() {
			this.options.minLonValueValidator = {
				number: -180,
				field: this.options.lonField,
				title: 'Longitude'
			};
			this.options.minLatValueValidator = {
				number: -90,
				field: this.options.latField,
				title: 'Latitude'
			};
			this.options.maxLonValueValidator = {
				number: 180,
				field: this.options.lonField,
				title: 'Longitude'
			};
			this.options.maxLatValueValidator = {
				number: 90,
				field: this.options.latField,
				title: 'Latitude'
			};

			if (this.storeState) {
				await this.$store.dispatch(this.stateKey + '/registerField', {
					field: this.field,
					options: this.options
				});
			}
		},

		clearLon() {
			this.valuelon = '';
		},
		clearLat() {
			this.valuelat = '';
		},

		adjustValueLat(e) {
			this.adjustValue(e.target.value, this.valuelat);
		},
		adjustValueLon(e) {
			this.adjustValue(e.target.value, this.valuelon);
		},
		adjustValue(val, value) {
			const validationRegex = /^\-?(\d*\.)?\d+$/igm;
			const isValid = validationRegex.test(val);
			let adjusted = '';
			if (isValid) {
				adjusted = this.options.precision ? parseFloat(val).toFixed(this.options.precision) : val;
			}
			// we need this to clean invalid value anyway
			value = null;
			this.$nextTick(() => {
				value = adjusted;
			});
		}
	}
};
</script>
<style src="./control-geocoordinateseditor.less" scoped></style>
