var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "filter-content range-dates panel-content" }, [
        _c("div", { staticClass: "range-dates" }, [
          _c("div", { staticClass: "range-date" }, [
            _c("span", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: { key: "filtersPanel.from" },
                  expression: "{ key: 'filtersPanel.from' }"
                }
              ],
              staticClass: "range-date-cell range-date-caption"
            }),
            _vm._v(" "),
            _c("div", { staticClass: "range-date-cell" }, [
              _c(
                "div",
                { staticClass: "clear-wrapper" },
                [
                  _c("DatePicker", {
                    directives: [
                      {
                        name: "data-attr",
                        rawName: "v-data-attr",
                        value: { options: _vm.options, stateNamespace: "from" },
                        expression: "{ options, stateNamespace: 'from' }"
                      }
                    ],
                    attrs: {
                      "enabled-dates": [_vm.to],
                      "has-today-action": _vm.options.hasTodayAction
                    },
                    model: {
                      value: _vm.from,
                      callback: function($$v) {
                        _vm.from = $$v
                      },
                      expression: "from"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "range-date" }, [
            _c("span", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: { key: "filtersPanel.to" },
                  expression: "{ key: 'filtersPanel.to' }"
                }
              ],
              staticClass: "range-date-cell range-date-caption"
            }),
            _vm._v(" "),
            _c("div", { staticClass: "range-date-cell" }, [
              _c(
                "div",
                { staticClass: "clear-wrapper" },
                [
                  _c("DatePicker", {
                    directives: [
                      {
                        name: "data-attr",
                        rawName: "v-data-attr",
                        value: { options: _vm.options, stateNamespace: "to" },
                        expression: "{ options, stateNamespace: 'to' }"
                      }
                    ],
                    attrs: {
                      "has-today-action": _vm.options.hasTodayAction,
                      "enabled-dates": [, _vm.from]
                    },
                    model: {
                      value: _vm.to,
                      callback: function($$v) {
                        _vm.to = $$v
                      },
                      expression: "to"
                    }
                  })
                ],
                1
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.options.isIncludeEmptyVisible
        ? _c("IncludeEmpty", {
            directives: [
              {
                name: "data-attr",
                rawName: "v-data-attr",
                value: { options: _vm.options, stateNamespace: "empty" },
                expression: "{ options, stateNamespace: 'empty' }"
              }
            ],
            attrs: { enabled: _vm.enableIncludeEmpty },
            model: {
              value: _vm.includeEmpty,
              callback: function($$v) {
                _vm.includeEmpty = $$v
              },
              expression: "includeEmpty"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }