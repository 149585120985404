var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.enable
    ? _c(
        "div",
        {
          staticClass: "column withRow",
          style: { width: _vm.width || "100%", height: _vm.height || "auto" }
        },
        [
          _c("details-row-control", {
            attrs: { evaluationContext: _vm.evaluationContext, row: _vm.row }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }