<template>
	<div class="dialog-content-wrapper">
		<div class="ms-export-dialog">
			<div class="loading-overlay large" v-show="isLoading"></div>
			<div class="dialog-container">
				<div class="export-options">
					<h5 class="subheader" v-localization="{ key: 'msExportPlugin.exportOptions' }"></h5>
					<div class="controls">
						<label v-if="selection && selection.length">
							<input type="radio" value="selected" v-model="exportMode" v-data-attr="{title: options.title, $type: 'dialog.input.radio'}"/>
							<span class="p1" v-localization="{ key: 'msExportPlugin.exportSelected' }"></span>
						</label>
						<label>
							<input type="radio" value="page" v-model="exportMode" v-data-attr="{title: options.title, $type: 'dialog.input.radio'}"/>
							<span class="p1" v-localization="{ key: 'msExportPlugin.exportPage' }"></span>
						</label>
						<label>
							<input type="radio" value="all" v-model="exportMode" v-data-attr="{title: options.title, $type: 'dialog.input.radio'}"/>
							<span class="p1" v-localization="{ key: 'msExportPlugin.exportAll' }"></span>
						</label>
					</div>
				</div>
				<div class="export-fields">
					<h5 class="subheader" v-localization="{ key: 'msExportPlugin.selectFields' }"></h5>
					<div class="items">
						<div class="control-tableView allowSelection">
							<div class="tableview-header with-check">
								<div class="checkbox-wrapper toggle-all" v-on:click="selectAll">
									<div class="virtual-checkbox clickable" v-bind:class="checkAllState">
										<svg class='svg-icon'>
											<use xlink:href='#layout-icons-ms-checkbox' v-if="checkAllState !== 'indeterminate'" class='layout-icons-ms-checkbox'></use>
											<use xlink:href='#action-icons-selection-checked-partially' v-if="checkAllState === 'indeterminate'" class='action-icons-selection-checked-partially'></use>
										</svg>
									</div>
								</div>
								<div style="width: 100%;" class="first-cell">
									<span v-localization="{ key: 'msExportPlugin.fields' }"></span>
								</div>
							</div>
							<div class="tableview-content">
								<div class="content-scroll-wrapper" v-perfectscroll="{ enable: true }">
									<div class="row" v-for="field in exportFields" :key="field.title">
										<div class="table-cell" style="width: 100%;">
											<div class="checkbox-wrapper" @click="selectField(field)">
												<div class="virtual-checkbox clickable" v-bind:class="field.selected ? 'selected' : 'unselected'">
													<svg class='svg-icon'>
														<use xlink:href='#layout-icons-ms-checkbox' class='layout-icons-ms-checkbox'></use>
													</svg>
												</div>
											</div>
											<div class="label p2">{{ field.title }}</div>
										</div>
									</div>
									<div class="row no-results-message" v-if="!exportFields.length">
										<div class="table-cell" v-bind:style="{ width: '100%' }">
											<div v-localization="{ key: 'common.dictionary.noMatchesFound' }"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class='footer'>
				<div class='buttons'>
					<span class="button-wrapper">
						<button
							v-bind:disabled="!exportEnabled"
							type="button"
							@click="runExport"
							v-localization="{key: 'common.dictionary.buttons.export'}"
							v-data-attr="{title: options.title, $type: 'dialog.button.submit'}">
						</button>
					</span>
					<button
						class="cancel"
						type="button"
						@click="cancel"
						v-localization="{key: 'common.dictionary.buttons.cancel'}"
						v-data-attr="{title: options.title, $type: 'dialog.button.cancel'}">
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import ExportMethods from './export.methods';
import { showToastOrInfoDialog } from '@acx-xms/data-functions/dist';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';

export default {
	name: 'ms-export-dialog',
	props: {
		/**
			 * @property Object options.evaluationContext
			 * @property String options.stateNamespace
			 * @property String options.logicalName
			 * @property Int options.maxRecordsToExport
			 * @property String options.customFileName
			 * @property String options.parentEntity
			 * @property String options.relationField
			 * @property String options.parentReference
			**/
		options: Object
	},
	data() {
		return {
			isLoading: false,
			exportFields: [],
			evaluationContext: this.options.evaluationContext || createEvaluationContext({}),
			selection: [],
			exportMode: null,
			total: this.options.maxRecordsToExport || 1000,
			orderBy: null,
			asc: null
		};
	},
	computed: {
		selectedFields() {
			return this.exportFields.filter(field => field.selected);
		},
		checkAllState() {
			if (this.selectedFields.length && this.selectedFields.length === this.exportFields.length) {
				return 'selected';
			}
			if (this.selectedFields.length) {
				return 'indeterminate';
			}
			return 'unselected';
		},
		exportEnabled() {
			return !!this.selectedFields.length && !this.isLoading;
		}
	},
	methods: {
		runExport() {
			if (this.options.parentEntity && this.options.relationField && this.options.parentReference) {
				return this.exportChild();
			}
			return this.exportParent();
		},
		async exportChild() {
			this.isLoading = true;
			const dataProvider = sc.classes.get('edge.dataProvider');
			let childFilters = this.$store.getters[this.options.stateNamespace + '/getFilters'](this.options.logicalName);
			const parentFilters = this.$store.getters[this.options.stateNamespace + '/getFilters'](this.options.parentEntity);
			const paginationParams = this.$store.getters[this.options.stateNamespace + '/getPaginationParams'];
			const selection = this.exportMode === 'selected' ? this.selection : this.$store.getters[this.options.stateNamespace + '/getSharedSearchSelectionExport'] ? this.getSelectionAvailabilities(this.$store.getters[this.options.stateNamespace + '/getSearchResults']('listing').sharedSearch) : [];
			const total = this.total;
			let parentTotal = 1000;
			const searchQueryFilter = this.$store.getters[this.options.stateNamespace + '/getSearchQueryFilter'];
			const entityConfig = await this.entitySettings.fetchEntity(this.options.logicalName);
			if (entityConfig.defaultFilters && entityConfig.defaultFilters.filter && entityConfig.defaultFilters.filter.length) {
				const defaultFilters = entityConfig.defaultFilters.filter.map(f => sc.classes.get(f.$type, f).toFilter());
				childFilters = childFilters.concat(defaultFilters);
			}
			if (searchQueryFilter) {
				parentFilters.push(searchQueryFilter);
			}
			if (this.exportMode === 'page') {
				parentFilters.push(sc.classes.get('offsetFrom.filter', paginationParams.from));
				parentTotal = paginationParams.activePages.length * paginationParams.pageSize;
			}
			let childOrderFilterData = [];
			if (this.orderBy) {
				childOrderFilterData.push({
					logicalName: this.orderBy,
					value: !this.asc
				});
			} else {
				const childOrderFilter = this.$store.getters[this.options.stateNamespace + '/getOrder'](this.options.logicalName);
				if (childOrderFilter && childOrderFilter.data) {
					childOrderFilterData = childOrderFilterData.concat(childOrderFilter.data.map(filter => {
						return {
							logicalName: filter.field,
							value: filter.isDescending
						};
					}));
				}
			}
			if (childOrderFilterData.length) {
				childFilters.push(sc.classes.get('orderBy.filter', { query: childOrderFilterData }));
			}
			if (this.exportMode !== 'selected') {
				let parentIds = [];
				const { Results } = await dataProvider.search({
					filters: [
						...parentFilters,
						sc.classes.get('offsetSize.filter', parentTotal),
						this.$store.getters[this.options.stateNamespace + '/getOrder'](this.options.parentEntity),
						sc.classes.get('selectedFields.filter', [])
					].filter((fltr) => !!fltr),
					entities: [this.options.parentEntity]
				});
				if (Results) {
					parentIds = Results.map(result => result.Id);
					childFilters.push(sc.classes.get('termFacet.filter', {
						logicalName: this.options.relationField,
						query: parentIds
					}));
				}
			}
			const { Total } = await dataProvider.search({
				filters: [
					...childFilters,
					sc.classes.get('selectedFields.filter', [])
				],
				entities: [this.options.logicalName]
			});
			if ((this.exportMode !== 'selected' && Total > total) || selection.length > total) {
				this.displayWarning();
				return;
			}
			ExportMethods.sendExportRequest(this.options.logicalName, this.selectedFields, total, childFilters, selection, this.options.customFileName).catch(err => {
				console.error(err);
			}).finally(async () => {
				await showToastOrInfoDialog({
					toastMessageKey: this.options.toastMessageKey,
					informationDialogTextKey: this.options.informationDialogTextKey,
					options: { context: this }
				});
				this.isLoading = false;
				this.close();
			});
		},
		async exportParent() {
			this.isLoading = true;
			let filters = this.$store.getters[this.options.stateNamespace + '/getFilters'](this.options.logicalName);
			const paginationParams = this.$store.getters[this.options.stateNamespace + '/getPaginationParams'];
			const searchQueryFilter = this.$store.getters[this.options.stateNamespace + '/getSearchQueryFilter'];
			const selection = this.exportMode === 'selected' ? this.selection : this.$store.getters[this.options.stateNamespace + '/getSharedSearchSelectionExport'] ? this.$store.getters[this.options.stateNamespace + '/getSearchResults']('listing').sharedSearch : [];
			let total = this.total;
			const entityConfig = await this.entitySettings.fetchEntity(this.options.logicalName);
			if (entityConfig.defaultFilters && entityConfig.defaultFilters.filter && entityConfig.defaultFilters.filter.length) {
				const defaultFilters = entityConfig.defaultFilters.filter.map(f => sc.classes.get(f.$type, f).toFilter());
				filters = filters.concat(defaultFilters);
			}
			if (searchQueryFilter) {
				filters.push(searchQueryFilter);
			}
			if (this.exportMode === 'page' && !this.$store.getters[this.options.stateNamespace + '/getSharedSearchSelectionExport']) {
				filters.push(sc.classes.get('offsetFrom.filter', paginationParams.from));
				total = paginationParams.activePages.length * paginationParams.pageSize;
				if (total > this.total) {
					this.displayWarning();
					return;
				}
			} else if ((this.exportMode !== 'selected' && paginationParams.total > this.total) || selection.length > this.total) {
				this.displayWarning();
				return;
			}
			if (this.orderBy) {
				filters.push(sc.classes.get('orderBy.filter', {
					logicalName: this.orderBy,
					query: !this.asc
				}));
			} else {
				const orderFilter = this.$store.getters[this.options.stateNamespace + '/getOrder'](this.options.logicalName);
				if (orderFilter) {
					filters.push(orderFilter);
				}
			}
			ExportMethods.sendExportRequest(this.options.logicalName, this.selectedFields, total, filters, selection, this.options.customFileName).catch(err => {
				console.error(err);
			}).finally(async () => {
				await showToastOrInfoDialog({
					toastMessageKey: this.options.toastMessageKey,
					informationDialogTextKey: this.options.informationDialogTextKey,
					options: { context: this }
				});
				this.isLoading = false;
				this.close();
			});
		},
		cancel() {
			this.close();
		},
		close() {
			this.$parent.$emit('close');
		},
		selectField(field) {
			field.selected = !field.selected;
		},
		selectAll() {
			const status = this.checkAllState !== 'selected';
			for (const field of this.exportFields) {
				field.selected = status;
			}
		},
		displayWarning() {
			this.localizations.getLabelForCurrentLanguage('msExportPlugin.maximumRecords', [this.total]).then(text => {
				sc.events.emit('dialog.open', {
					title: 'Warning',
					allowMinimize: false,
					allowMaximize: false,
					allowPin: false,
					allowClose: false,
					component: 'dialog.info',
					params: {
						message: text,
						buttons: [
							{
								title: 'OK',
								callback: () => { this.isLoading = false; }
							}
						]
					}
				});
			});
		},
		async parseSettings() {
			const settings = await this.settingsDataProvider.get('exportSettings');
			const entitySettings = settings.entity.find(entity => entity.logicalname === this.options.logicalName);
			const formats = {
				longDate: 'D',
				shortDate: 'd',
				dateTime: 'g',
				fullDateTime: 'f',
				custom: ''
			};
			if (entitySettings) {
				this.orderBy = entitySettings.orderBy;
				this.asc = entitySettings.asc;
				let index = 0;
				for (const field of entitySettings.field) {
					let title;
					const fieldLogicalName = this.evaluationContext.eval(field.logicalname);
					if (field.title) {
						title = await this.evaluationContext.evalAsync(field.title);
					}
					if (!title) {
						const fieldMetadata = await this.entitySettings.getFieldMetadata(this.options.logicalName, fieldLogicalName, 'index');
						if (fieldMetadata) {
							title = fieldMetadata.name.base;
						}
					}
					const exportField = {
						title,
						logicalname: fieldLogicalName,
						selected: this.evaluationContext.eval(field.selected),
						columnNumber: index++,
						TimeZoneOffset: sc.utils.userAgent.timeZone.offset
					};
					if (field.format) {
						exportField.format = formats[field.format] || field.format;
					}
					this.exportFields.push(exportField);
				}
			}
		},
		getSelectionAvailabilities(listings) {
			return listings.reduce((acc, listing) => {
				listing.Source.availabilities.map((availability) => {
					acc.push({ Id: availability.availabilityid }); return undefined;
				});
				return acc;
			}, []);
		}
	},
	created() {
		this.isLoading = true;
		this.localizations = sc.classes.get('localization.dataProvider');
		this.settingsDataProvider = sc.classes.get('settings.dataProvider');
		this.entitySettings = sc.classes.get('entityConfiguration.dataProvider');
		const selection = this.$store.getters[this.options.stateNamespace + '/getSelection'];
		this.selection = selection[this.options.logicalName];
		this.exportMode = this.selection && this.selection.length ? 'selected' : 'page';
		this.parseSettings().finally(() => {
			this.isLoading = false;
		});
	}
};
</script>
<style src="./ms-export-dialog.less" scoped></style>
<style src="@/Components/Control/Checkboxes/virtual-checkbox.less" scoped></style>
