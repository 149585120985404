<template>
	<div class="assign-license-dialog">
		<div class="pb5">
			<h5>{{ unassignedLicenseCountText }}</h5>
		</div>
		<h5 class="pb5" v-localization="{key: 'dialogues.assignLicense.selectLicense'}"></h5>
		<div class="loading-overlay large" v-show="isLoading"></div>
		<div class="select-record">
			<control-lookup-editor v-if="!isLoading" v-model="selectedRecord" :evaluationContext="options.params.evaluationContext" :options="getLookupOptions()" :showRequired="!this.selectedRecord"></control-lookup-editor>
		</div>
		<div class="footer">
			<div class="dialog-buttons">
				<button @click="close"
					class="cancel"
					type="button"
					v-localization="{key: 'common.dictionary.buttons.cancel'}"
					v-data-attr="{title: options.title, $type: 'dialog.button.cancel'}"></button>
				<button @click="save"
					type="button"
					v-localization="{key: 'dialogues.assignLicense.assign'}"
					:disabled="!this.selectedRecord"
					v-data-attr="{title: options.title, $type: 'dialog.button.submit'}"></button>
			</div>
		</div>
	</div>
</template>
<script>
import { showToastOrInfoDialog } from '@acx-xms/data-functions/dist';
const ControlLookupEditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Lookup/control-lookup-editor');

export default {
	name: 'assign-license-dialog',
	components: { ControlLookupEditor },
	props: { options: Object },
	data() {
		return {
			isLoading: true,
			selectedRecord: null,
			lookupTemplate: null,
			unassignedLicenseCountText: null
		};
	},
	computed: {
		saveButtonEnabled() {
			return !!this.selectedRecord;
		}
	},
	async created() {
		this.localizations = sc.classes.get('localization.dataProvider');
		const entityConfigDataProvider = sc.classes.get('entityConfiguration.dataProvider');
		this.dataProvider = sc.classes.get('edge.dataProvider');
		this.lookupTemplate = await entityConfigDataProvider.getTemplate('license', 'list');
		const startOfDay = new Date();
		startOfDay.setUTCHours(0, 0, 0, 0);
		startOfDay.toUTCString();

		const filters = [
			sc.classes.get('offsetFrom.filter', 0),
			sc.classes.get('offsetSize.filter', 0),
			sc.classes.get('termFacet.filter', {
				logicalName: 'systemuserid.id',
				query: ['00000000-0000-0000-0000-000000000000'],
				additionalOptions: { includeEmpty: true }
			}),
			sc.classes.get('dateTimeFacet.filter', {
				logicalName: 'validto',
				query: [{
					isChecked: true,
					Range: { From: startOfDay }
				}]
			}),
			sc.classes.get('selectedFields.filter', [])
		];
		const unassignedLicensesCount = await this.dataProvider.search({
			filters,
			entities: ['license']
		}).then(({ Total }) => { return Total; });
		this.unassignedLicenseCountText = ko.unwrap(this.localizations.localize('dialogues.assignLicense.numberOfLicencesTitle', [unassignedLicensesCount]));

		this.isLoading = false;
	},
	methods: {
		async save() {
			this.isLoading = true;
			try {
				await this.dataProvider.update('license', this.selectedRecord.data.Id, { systemuserid: this.options.params.userRef });
				this.$root.$emit('mainSearch.systemuser.licenses.searching');
			} catch (error) {
				sc.events.emit('dialog.error', {
					title: this.localizations.localize('entity.editor.validationError'),
					message: error.responseText
				});
			}
			this.isLoading = false;
			await showToastOrInfoDialog({
				toastMessageKey: this.options.params.toastMessageKey,
				informationDialogTextKey: this.options.params.informationDialogTextKey,
				options: { context: this }
			});

			this.options.params.refreshEvent && this.$root.$emit(this.options.params.refreshEvent);
			this.close();
		},

		close() {
			this.$parent.$emit('close');
		},

		getLookupOptions: function () {
			return {
				placeholder: '',
				customIcon: 'layout-icons-ms-search',
				template: this.lookupTemplate,
				dataAttr: {
					title: this.options.title,
					$type: 'dialog.input.lookup'
				},
				fetchData: (searchQuery, startFrom, pageSize) => {
					startFrom = startFrom || 0;
					pageSize = pageSize || 1000;
					let filters = [
						sc.classes.get('offsetFrom.filter', startFrom),
						sc.classes.get('offsetSize.filter', pageSize),
						sc.classes.get('selectedFields.filter', [
							{ logicalname: 'licenselicensetypelookuplicensetype.name' },
							{ logicalname: 'istrial' },
							{ logicalname: 'name' }
						])
					];
					if (this.options.params.lookupFilters && this.options.params.lookupFilters.length) {
						filters = filters.concat(this.options.params.lookupFilters.map((filter) => {
							return sc.classes.get(filter.$type, filter, this.options.params.evaluationContext).toFilter();
						}));
					}
					if (searchQuery) {
						filters.push(sc.classes.get('field.filter', {
							logicalName: '_all',
							query: searchQuery
						}));
					}
					return this.dataProvider.search({
						filters,
						entities: ['license']
					}).then(({ Results, Total }) => {
						return {
							total: Total,
							template: this.lookupTemplate,
							items: Results.map(res => {
								return {
									displayName: res.Name,
									data: res
								};
							})
						};
					});
				}
			};
		}
	}
};
</script>
<style src="./assign-license-dialog.less" scoped></style>
