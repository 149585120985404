<template>
	<div>
		<div class="filter-content slide-holder panel-content">
			<div v-if="available" class="slider-range number">
				<div :class="['wrapper', { error: error.from }]">
					<input
						v-data-attr="{ options, stateNamespace: 'from' }"
						:value="from"
						:class="{ changed: from != min || rangeChanged }"
						type="text"
						@input="set('from', $event.target.value)"
					/>
				</div>
				<span v-localization="{ key: 'filtersPanel.slider.to' }" />
				<div :class="['wrapper', { error: error.to }]">
					<input
						v-data-attr="{ options, stateNamespace: 'to' }"
						:value="to"
						:class="{ changed: to != max || rangeChanged }"
						type="text"
						@input="set('to', $event.target.value)"
					/>
				</div>
			</div>
			<div v-else class="slider-range">
				<span v-localization="{ key: 'filtersPanel.unavailable' }" />
			</div>
		</div>
		<IncludeEmpty
			v-if="options.isIncludeEmptyVisible"
			v-model="includeEmpty"
			v-data-attr="{ options, stateNamespace: 'empty' }"
			:enabled="enableIncludeEmpty"
		/>
	</div>
</template>

<script>
/* eslint eqeqeq: 0 */
/* eslint no-mixed-operators: 0 */
import helperMethods from '@/Components/ComponentSet/component-set-evaluation-context-helper.methods';
import FilterMixins from '@/Components/FiltersPanel/filter.mixins';
const IncludeEmpty = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './include-empty');

export default {
	name: 'filter-info-panels-slider',
	mixins: [FilterMixins],
	components: { IncludeEmpty },
	props: {
		options: Object,
		evaluationContext: Object,
		stateNamespace: String
	},
	data() {
		return {
			available: true,
			min: null,
			max: null,
			from: 0,
			to: 0,
			includeEmpty: false,
			error: {
				from: false,
				to: false
			}
		};
	},
	computed: {
		enableIncludeEmpty() {
			return (this.from != this.min || this.to != this.max);
		}
	},
	created() {
		this.logicalName = helperMethods.eval(this.evaluationContext, this.options.logicalName);
		this.entity = this.options.entities[0];
		this.precision = helperMethods.eval(this.evaluationContext, this.options.precision);

		const selectedFilter = this.getSelectedFilter();
		if (selectedFilter) {
			// do we need computed here
			const statistic = this.$store.getters[this.stateNamespace + '/getStatistic'](this.entity);
			const numericProps = this.readStatistic(statistic);
			if (numericProps) {
				this.min = numericProps.NumericFacetProperties.Min;
				this.max = numericProps.NumericFacetProperties.Max;
				this.available = true;
			}
		}

		this.filterValue = this.$store.getters[this.stateNamespace + '/getFilterValue']({
			entity: this.entity,
			logicalName: this.logicalName
		});
		if (this.filterValue) {
			this.applyFilterValue(this.filterValue);
			this.includeEmpty = this.filterValue.data.includeEmpty;
			this.rangeChanged = (this.from != this.min) || (this.to != this.max);
		} else {
			this.from = this.min;
			this.to = this.max;
			this.rangeChanged = false;
		}
		this.$parent.$on(this.stateNamespace + 'resetFilter', this.resetFilter);
	},
	methods: {
		set(type, value) {
			if (value) {
				if (this.precision !== void 0) {
					const target = typeof value === 'number' ? value : value.replace(/[^0-9,]+/, '');
					this[type] = target ? parseFloat(target).toFixed(this.precision) : '';
				} else {
					const target = typeof value === 'number' ? value : value.replace(/[^0-9]+/, '');
					this[type] = target ? parseFloat(target) : '';
				}
			} else this[type] = '';
			const valid = this.from && (typeof this.to === 'number' ? this.to >= 0 : this.to);
			if (type === 'from') {
				this.error.to = false;
				this.error.from = valid ? (this.from > this.to) : false;
				this.$emit('filterError', this.error.from);
			} else if (type === 'to') {
				this.error.from = false;
				this.error.to = valid ? (this.to < this.from) : false;
				this.$emit('filterError', this.error.to);
			}
		}
	},
	async beforeDestroy() {
		if (this.error.to || this.error.from) return;
		const hasValue = () => {
			return (this.from !== null && this.min != this.from) || (this.to !== null && this.max != this.to) || this.includeEmpty;
		};
		const wasChanged = () => {
			return (!this.filterValue && hasValue()) ||
					(this.filterValue && !hasValue()) ||
					(this.filterValue && ((this.initialFrom != this.from) || (this.initialTo != this.to)) ||
						(this.filterValue && (this.filterValue.data.includeEmpty != this.includeEmpty)));
		};
		if (wasChanged()) {
			const filter = (!hasValue()) ? null : await this.getSearchFilter(this.logicalName);
			this.emitFilterChanged(filter);
		}
	}
};
</script>
<style src="./panels.less" scoped></style>
