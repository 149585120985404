<template>
	<div class="control-color-picker">
		<input v-if="enable" class="color-text" type="text" @change="updateInput" :value="value" />
		<input v-bind:disabled="!enable" class="color-picker" type="color" v-model.lazy="value" />
		<span v-if="!enable" class="color-label">{{value}}</span>
	</div>
</template>
<script>
import ControlMixins from '@/Components/Editor/Controls/control.mixins';
export default {
	name: 'control-color-picker',
	mixins: [ControlMixins],
	data() {
		return {
			lastValid: null,
			validationPattern: /^#?([a-f0-9]{6})$/i
		};
	},
	created() {
		if (!this.value) {
			this.value = '#000000';
		}
		this.lastValid = this.value;
	},
	methods: {
		updateInput(e) {
			this.value = e.target.value;
		}
	},
	computed: {
		value: {
			get() {
				const stateField = this.getField();
				return stateField ? '#' + stateField : null;
			},
			set(value) {
				this.setField(null); // reset state to trigger update
				if (this.validationPattern.test(value)) {
					this.lastValid = value;
				}
				this.setField(this.lastValid.replace('#', ''));
			}
		}
	}
};
</script>
<style src="./control-color-picker.less" scoped></style>
