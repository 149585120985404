<template>
	<div class="config-tool-containter" v-show="!isLoading">
		<div class="tools-container" :class="{expanded: isExpanded}">
			<div class="header">
				<div class="left-menu">
					<div class="undo" :disabled="currentStateIndex<=0" @click="undo">
						<span class="icon-holder">
							<svg class='svg-icon svg-15'>
								<use :xlink:href="'#action-icons-undo'" class="hover"></use>
							</svg>
						</span>
					</div>
					<div class="redo" :disabled="state.length-1===currentStateIndex" @click="redo">
						<span class="icon-holder">
							<svg class='svg-icon svg-15'>
								<use :xlink:href="'#action-icons-redo'" class="hover"></use>
							</svg>
						</span>
					</div>
				</div>
				<div class="right-menu">
					<button class="save" @click="save" :disabled="!isDirty">Save</button>
					<div class="toggle">
						<span class="icon-holder" @click="isExpanded=!isExpanded">
							<svg class='svg-icon svg-15'>
								<use :xlink:href="'#layout-icons-filter-group-collapse'" class="hover"></use>
							</svg>
						</span>
					</div>
				</div>
			</div>
			<div class="panels-wrapper">
				<div v-perfectscroll="{enable: true, onScroll: onScroll}">
					<div class="inner-panels-wrapper">
						<panel v-if="template"
							   title="root"
							   :template="template"
							   :base="adjustTypeToSchema(template['$type'])"
							   :metaFields="metaFields"
							   @updateTemplate="onUpdateTemplate"></panel>
					</div>
				</div>
			</div>
		</div>
		<div class="absolute-toggle-wrapper" v-if="!isExpanded">
			<div class="toggle">
				<span class="icon-holder" @click="isExpanded=!isExpanded">
					<svg class='svg-icon svg-15'>
						<use :xlink:href="'#layout-icons-filter-group-open'" class="hover"></use>
					</svg>
				</span>
			</div>
		</div>
		<div class="preview-container">
			<div class="preview-header">
				<p class="h2">{{title}}</p>
				<p v-localization="{ key: 'common.dictionary.configToolBeta' }"></p>
			</div>
			<div v-perfectscroll="{ enable: true }">
				<control class="template"
						 v-if="template"
						 :name="template.$type"
						 :contentProps="template"
						 :evaluationContext="evaluationContext"
						 :key="previewRecord.Id+templateString"></control>

			</div>
		</div>

	</div>
</template>

<script>
import { LoadSchema, fillTypesDictionary } from './resolver';
import { adjustTypeToSchema, generateSelectedFields } from './helpersMethods';
import {
	updateConfigTemplate, previewConfigTemplate, applyConfigTemplate
} from '@/Data/DataProviders/templatesDataProvider';
import {
	isObject, generateId, traverseObj, showToastOrInfoDialog, Search, Update, IndexMetadata
} from '@acx-xms/data-functions/dist';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';
const Panel = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './panel/panel.vue');
const Control = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Entity/control');

export default {
	name: 'config-tool',
	props: { templateRecord: Object },
	components: {
		Panel,
		Control
	},
	data() {
		return {
			root: 'root',
			template: null,
			initTemplate: null,
			isLoading: true,
			previewRecord: null,
			isExpanded: false,
			templateString: null,
			state: [],
			currentStateIndex: 0,
			title: null
		};
	},
	watch: {
		isLoading(val) {
			this.$emit('onLoadingChange', val);
		}
	},
	computed: {
		isDirty() {
			const isDirty = this.initTemplate !== JSON.stringify(this.template);
			this.$emit('onIsDirtyChange', isDirty);
			return isDirty;
		}
	},
	async created() {
		this.fork = sc.events.fork();
		this.fork.on('saveTemplate', params => this.save(params));
		// convert 'details-header' to Details Header
		this.title = this.templateRecord.Source.templatetype
			.split('-')
			.map(word => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
		this.logicalName = sc.utils.findProperty(this.templateRecord, 'Source.entity', true);
		const [context, meta] = await Promise.all([this.getContext(), IndexMetadata(this.logicalName), LoadSchema()]);
		fillTypesDictionary();
		this.evaluationContext = context;
		this.metaFields = this.processMetadata(meta[0]);
		const initTemplateString = sc.utils.findProperty(this.templateRecord, 'Source.content', true);
		const initTemplate = JSON.parse(initTemplateString);
		traverseObj(initTemplate, value => {
			if (isObject(value) && value.hasOwnProperty('$type') && value.$type.includes('control.') && !value.$type.includes('Container') && !value.$type.includes('Panel')) {
				value.$id = generateId();
			}
		});
		this.initTemplate = JSON.stringify(initTemplate);
		this.state.push(this.initTemplate);
		this.template = initTemplate;
		// refresh record after it has been selected in 'Selected Record' action
		this.$root.$on('configToolRecordSelected', async (data) => {
			this.evaluationContext = await this.getContext(data.data);
		});
		// for refresh after smart-image sends this event
		this.fork.on('refreshCurrentDetails', this.refreshPage);
		this.$on('expand', () => { this.isExpanded = true; });
		this.isLoading = false;
	},
	beforeDestroy() {
		this.$root.$off('configToolRecordSelected');
		this.$off('expand');
		if (this.fork) {
			this.fork.dispose();
		}
	},
	methods: {
		adjustTypeToSchema,
		onScroll() {
			this.$root.$emit('lookupArea.scrolled');
		},
		undo() {
			this.currentStateIndex--;
			this.template = JSON.parse(this.state[this.currentStateIndex]);
			this.templateString = this.state[this.currentStateIndex];
		},
		redo() {
			this.currentStateIndex++;
			this.template = JSON.parse(this.state[this.currentStateIndex]);
			this.templateString = this.state[this.currentStateIndex];
		},
		onUpdateTemplate(template) {
			this.template = null;
			this.template = { ...template };
			// used as a part of :key
			this.templateString = JSON.stringify(this.template);
			// if redo button is active - clear state that goes after current state
			if (this.state.length - 1 > this.currentStateIndex) {
				this.state.splice(this.currentStateIndex + 1, this.state.length - 1 - this.currentStateIndex);
			}
			// max number of records in state is 20
			if (this.state.length >= 20) {
				this.state.shift();
			}
			this.state.push(this.templateString);
			this.currentStateIndex = this.state.length - 1;
		},
		async save(params) {
			this.isLoading = true;
			try {
				traverseObj(this.template, value => {
					if (value.$id) {
						delete value.$id;
					}
				});
				const selectedFields = generateSelectedFields(this.template);
				const updatedTemplate = await Update('configtemplate', this.templateRecord.Id, {
					content: JSON.stringify(this.template),
					selectedfields: selectedFields
				});
					// update template in templatesDataProvider so if we update and apply template - changes will be seen
				updateConfigTemplate(this.templateRecord.Id, updatedTemplate);
				this.initTemplate = sc.utils.findProperty(updatedTemplate, 'content', true);
				this.template = JSON.parse(this.initTemplate);
				this.isLoading = false;
				// action 'export' template
				if (params.callback && params.fieldsToExport) {
					const objectToExport = {};
					params.fieldsToExport.forEach(f => { objectToExport[f] = updatedTemplate[f]; });
					params.callback(objectToExport, objectToExport.name);
					// action 'preview' template
				} else if (params.isPreviewAction) {
					await previewConfigTemplate(params.id);
					await showToastOrInfoDialog({
						toastMessageKey: 'common.dictionary.configPreviwed',
						informationDialogTextKey: 'common.dictionary.configPreviwed'
					});
					// action 'apply' template
				} else if (params.isApplyAction) {
					await applyConfigTemplate(params.id);
					sc.events.emit(params.refreshEvent);
					// save before leaving the page (from config-tool-wrapper)
				} else if (params.callback) {
					params.callback();
				}
				// refresh state
				this.state = [this.initTemplate];
				this.currentStateIndex = 0;
			} catch (err) {
				this.isLoading = false;
				sc.utils.errorMessage.byResponse(err);
			}
		},
		refreshPage() {
			this.isLoading = true;
			setTimeout(() => {
				this.isLoading = false;
			}, 100);
		},
		async getContext(data) {
			const cacheKey = 'config_tool_last_records';
			const cache = localStorage.getItem(cacheKey) || {};

			if (data) {
				this.previewRecord = data;
				cache[this.logicalName] = data.Id;

				localStorage.setItem(cacheKey, cache);
			} else {
				this.previewRecord = await this.getRecord(this.logicalName, cache[this.logicalName]);
			}

			return createEvaluationContext(this.previewRecord);
		},

		async getRecord(entity, id) {
			const filters = [
				sc.classes.get('offsetSize.filter', 1)
			];
			if (id) { filters.push(sc.classes.get('ids.filter', [id])); }
			const record = await Search(entity, filters);

			return record.Results ? record.Results[0] : null;
		},
		processMetadata(meta) {
			let metaFields = this.processMetaFields(meta.Fields);
			// nestedcollections
			const metaNestedCollections = (meta.NestedCollections || []).map(collection => this.processMetaFields(collection.Fields, `${collection.CollectionName}.`));
			meta.NestedCollections.forEach((item, i) => {
				const DisplayName = item.CollectionName;
				const LogicalName = item.CollectionName;
				// add nested collection "main" field and all others
				metaFields = metaFields.concat([this.produceLookupObject(DisplayName, LogicalName, 1)], metaNestedCollections[i]);
			});

			// extendedEntityReferences
			const metaExtEntityRefs = (meta.References || []).map(ref => this.processMetaFields(ref.Fields, `${ref.LogicalName}.`));
			meta.References.forEach((item, i) => {
				const DisplayName = item.DisplayName || item.Type; const // TODO: fix in EDGE Schema
					LogicalName = item.LogicalName;
					// add extended entity refs "main" field and all others
				metaFields = metaFields.concat([this.produceLookupObject(DisplayName, LogicalName, 1)], metaExtEntityRefs[i]);
			});

			return metaFields;
		},
		processMetaFields(fields, logicalNamePrefix = '') {
			const metaFields = fields.map(res => {
				const DisplayName = res.DisplayName;
				const LogicalName = logicalNamePrefix + res.LogicalName;
				return this.produceLookupObject(DisplayName, LogicalName);
			});
			return this.processRefsAndSortFields(metaFields);
		},
		processRefsAndSortFields(fields) {
			fields.forEach(field => {
				if (field.data.logicalName.includes('.id')) {
					const logicalName = field.data.logicalName.slice(0, -3);
					const displayName = field.data.displayName;
					// we receive only id of entity reference field, so we need to add full entity ref and ref.logicalname fields for user to choose
					fields = fields.concat([
						this.produceLookupObject(displayName, logicalName),
						this.produceLookupObject(displayName, `${logicalName}.logicalname`)
					]);
				}
			});
			return fields.sort((a, b) => {
				if (a.order === b.order) {
					return (a.data.logicalName > b.data.logicalName) ? 1 : ((b.data.logicalName > a.data.logicalName) ? -1 : 0);
				} else {
					return b.order - a.order;
				}
			});
		},
		produceLookupObject(displayName, logicalName, order = 0) {
			return {
				displayName: `${logicalName}`,
				data: {
					Id: logicalName,
					logicalName,
					displayName,
					order
				}
			};
		}
	}
};

</script>
<style src="./config-tool.less" scoped></style>
