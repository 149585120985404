import helperMethods from '@/Components/ComponentSet/component-set-evaluation-context-helper.methods';
import { GetPerConfig, SetPerConfig } from '@/Data/DataProviders/userStorageDataProvider';
import { mapGetters } from 'vuex';

const TITLES = {
	listing: 'listing',
	availability: 'availability',
	recipient: 'recipient'
};

const PREFIX = {
	default: 's',
	y: 'ies'
};

export default {
	data() {
		return {
			visibleSelectionList: false,
			isActive: true
		};
	},
	computed: {
		...mapGetters('wizard', ['getCollectionType']),
		...mapGetters('wizard.navigation', ['getActive']),
		selectedItems() {
			return this.$store.getters[this.stateNamespace + '/getSelection'][this.targetEntity];
		},
		selectedTagsCounter() {
			// TODO: create some global function for listings & availabilities
			if (+this.getActive === 3) {
				/* path to selection */
				const path = this.$store.state && this.$store.state['wizard.listing'] && this.$store.state['wizard.listing'].selection;
				/* check if has selected elements */
				const hasSelection = path &&
					(Array.isArray(this.$store.state['wizard.listing'].selection[this.getCollectionType]) &&
						this.$store.state['wizard.listing'].selection[this.getCollectionType].length);
				/* get count of selected element */
				const selection = (hasSelection && path[this.getCollectionType].length) || 0;
				/* show count by selection name */
				return `Selected ${selection} ${this.selectionPrefix(TITLES[this.getCollectionType], selection)}`;
			} else {
				const recepients = this.$store.state &&
				this.$store.state['wizard.ml'] &&
				this.$store.state['wizard.ml'].selection &&
				this.$store.state['wizard.ml'].selection.staticlist &&
				this.$store.state['wizard.ml'].selection.staticlist.reduce((a, i) => {
					return +a + +i.Source.RelationCounts.contacts;
				}, '');
				return `Selected ${recepients} ${this.selectionPrefix(TITLES.recipient, recepients)}`;
			}
		},
		collectionTitle() {
			return `Select ${`${TITLES[this.getCollectionType][0].toUpperCase()}${TITLES[this.getCollectionType].slice(1)}`}`;
		},
		selectedTagsPlaceholder() {
			if (+this.getActive === 3) return `No ${this.selectionPrefix(TITLES[this.getCollectionType], 0)} selected`;
			return 'No marketing lists selected';
		},
		tagsCustomQuery() {
			/* TODO: move to xml? */
			if (this.getCollectionType !== 'availability') return {};
			return {
				name: 'prefix',
				entity: 'listing',
				target: 'Name',
				value: 'Source.listingid.id',
				field: 'Id'
			};
		}
	},
	methods: {
		unselect(record) {
			/* unselect listing if all child availability unselected */
			if (+this.getActive === 3 && this.getCollectionType === 'availability') {
				const parent = this.selectedItems.find(listing => listing.Source.availabilities.find(availability => availability.availabilityid === record));
				const parentHasSelectedChild = this.$store.getters[`${this.stateNamespace}/getSelection`][this.getCollectionType].find(el => el.Source.listingid.id === parent.Id);
				if (!parentHasSelectedChild) {
					this.$store.commit(`${this.stateNamespace}/changeSelection`, {
						records: [parent],
						select: false
					});
				}
			}
		},
		selectionPrefix(word, size) {
			const wordLetters = word.split('');
			if (+size !== 1) {
				return wordLetters.reverse()[0] === 'y' ? word.slice(0, -1) + PREFIX.y : word + PREFIX.default;
			} else return word;
		}
	},
	created() {
		if (this.options && this.options.visibleSelectionList) this.visibleSelectionList = helperMethods.eval(this.evaluationContext, this.options.visibleSelectionList);
	},
	async mounted() {
		if (!(this.fork && this.fork.listeners)) this.fork = sc.events.fork();

		this.$on('.pageChanged', this._onPageChangedHandler);

		this.fork.on(this.combinedNamespace + '.loading', (isLoading) => this.isLoading = isLoading);
		this.$on('.removeSearchSubscriptions', this.unsubscribeSearchSubscribtions);
		this.$on('.changeSearchSubscriptions', this.initSearchSubscribtions);
		this.initSearchSubscribtions();

		this.$root.$on(['entity.changed', 'entity.created', 'entity.deleted'], this.onEntityCallback);
		this.fork.on(['entity.changed', 'entity.created', 'entity.deleted'], this.onEntityCallback);

		this.isActive = true;

		if (this.autoSearch) {
			this.unwatchFiltersReady = this.$watch('filtersReady', (newVal, oldVal) => {
				if (newVal) {
					this.doSearch({ initSearch: true });
					this.unwatchFiltersReady();
				}
			});
		}

		let recordsToHide = await GetPerConfig('hiddenRecords') || [];
		recordsToHide = recordsToHide.filter(record => Math.abs(Date.now() - record.time) < 1 * 1000 * 60);
		await SetPerConfig('hiddenRecords', recordsToHide);
	},
	unmounted() {
		this.unwatch && this.unwatch();

		if (this.fork) this.fork.dispose();

		this.stateNamespace && this.$store.commit(this.stateNamespace + '/cleanSelection');
		this.$root.$off(['entity.changed', 'entity.created', 'entity.deleted'], this.onEntityCallback);
		this.unsubscribeSearchSubscribtions();
		this.$off('.pageChanged', this._onPageChangedHandler);
		this.isActive = false;
	}
};
