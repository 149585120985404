import { EditComplete } from './editComplete.behavior.js';
import { SEO } from './seo.behavior.js';
import { AddQuickContact } from './addQuickContactToParticipants.behavior.js';
import { CreatePreference } from './createPreference.behavior.js';
import { InviteParticipants } from './inviteParticipants.behavior.js';
import { SaveContactToSF } from './saveContactToSF.behavior.js';
import { UpdateWithParameter } from './updateWithParameter.behavior.js';
import { ValidatePreference } from './validatePreference.behavior.js';
import { ContactUs } from './contactUs.behavior.js';
import { EntityEditor } from './entityEditor.behavior.js';
import { AvatarUpdate } from './avatarUpdate.behavior.js';

const behaviors = {
	'behavior.addQuickContactToParticipants': AddQuickContact,
	'behavior.createPreferenceAsync': CreatePreference,
	'behavior.inviteParticipantsBehavior': InviteParticipants,
	'behavior.marketSpaceEditCompleteBehavior': EditComplete,
	'behavior.saveContactToSFBehavior': SaveContactToSF,
	'behavior.seo': SEO,
	'behavior.updateWithParameter': UpdateWithParameter,
	'behavior.validatePreferenceBehavior': ValidatePreference,
	'behavior.contactUsBehavior': ContactUs,
	'geoCoding.entityEditorBehavior': EntityEditor,
	'behavior.avatar.onUpdate': AvatarUpdate,
	'behavior.avatar.onCreate': AvatarUpdate
};

export function getBehavior(name, options) {
	let behavior = behaviors[name];
	return behavior ? new behavior(options) : null;
}
