var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", { staticClass: "checkbox-wrapper" }, [
    _c(
      "span",
      {
        staticClass: "checkbox bordered",
        class: { checked: _vm.value, disabled: _vm.disabled }
      },
      [
        _c("svg", { staticClass: "svg-icon" }, [
          _c("use", {
            staticClass: "layout-icons-ms-checkbox",
            attrs: { "xlink:href": "#layout-icons-ms-checkbox" }
          })
        ])
      ]
    ),
    _vm._v(" "),
    _c("input", {
      attrs: { type: "checkbox", disabled: _vm.disabled },
      domProps: { checked: _vm.value },
      on: {
        change: function($event) {
          _vm.$emit("input", $event.target.checked)
        }
      }
    }),
    _vm._v(" "),
    _vm.label
      ? _c("span", { staticClass: "p4" }, [_vm._v(_vm._s(_vm.label))])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }