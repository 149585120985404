var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "collection", class: { expanded: _vm.isExpanded } },
    [
      _c("div", { staticClass: "border-top" }),
      _vm._v(" "),
      _c("div", { staticClass: "header with-margin" }, [
        _c("div", { staticClass: "icon-with-text" }, [
          _c(
            "span",
            {
              staticClass: "icon-holder",
              on: {
                click: function($event) {
                  _vm.isExpanded = !_vm.isExpanded
                }
              }
            },
            [
              _c("svg", { staticClass: "svg-icon svg-15" }, [
                _vm.isExpanded
                  ? _c("use", {
                      staticClass: "hover",
                      attrs: {
                        "xlink:href": "#layout-icons-filter-group-collapse"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isExpanded
                  ? _c("use", {
                      staticClass: "hover",
                      attrs: { "xlink:href": "#layout-icons-filter-group-open" }
                    })
                  : _vm._e()
              ])
            ]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "h2 title" }, [
            _vm._v(_vm._s(_vm.title) + ":")
          ])
        ]),
        _vm._v(" "),
        _c("button", { on: { click: _vm.add } }, [_vm._v("Add")])
      ]),
      _vm._v(" "),
      _vm._l(_vm.items, function(item, index) {
        return _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isExpanded,
                expression: "isExpanded"
              }
            ],
            key: item["$type"] + index,
            staticClass: "collection-item"
          },
          [
            _c("panel", {
              attrs: {
                title: "" + index,
                template: _vm.getCurrentItemValue(index),
                base: _vm.base,
                allowItemActions: true,
                currentIndex: index,
                itemActions: _vm.itemActions,
                metaFields: _vm.metaFields
              },
              on: {
                copyItem: function($event) {
                  _vm.copy(index)
                },
                topUpItem: function($event) {
                  _vm.topUpItem(index)
                },
                topDownItem: function($event) {
                  _vm.topDownItem(index)
                },
                oneUpItem: function($event) {
                  _vm.oneUpItem(index)
                },
                oneDownItem: function($event) {
                  _vm.oneDownItem(index)
                },
                removeItem: function($event) {
                  _vm.remove(index)
                },
                onChange: function($event) {
                  var i = arguments.length,
                    argsArray = Array(i)
                  while (i--) argsArray[i] = arguments[i]
                  _vm.onChange.apply(void 0, [index].concat(argsArray))
                }
              }
            })
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }