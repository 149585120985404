var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dialog",
      class: _vm.classes,
      style: { right: _vm.offsetX },
      on: {
        focusin: function($event) {
          _vm.$emit("set-active", _vm.id)
        }
      }
    },
    [
      _c("div", { staticClass: "dialog-wrapper" }, [
        _c(
          "div",
          {
            staticClass: "dialog-window",
            class: { active: _vm.active },
            style: { "max-width": _vm.dialogWidth, height: _vm.dialogHeight }
          },
          [
            _c("dialog-header", {
              attrs: { dialogOptions: _vm.dialogOptionsForHeader() }
            }),
            _vm._v(" "),
            _c("div", {
              directives: [
                {
                  name: "kocomponent",
                  rawName: "v-kocomponent",
                  value: _vm.dialogContentComponent(),
                  expression: "dialogContentComponent()"
                }
              ],
              ref: "id",
              staticClass: "dialog-content"
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }