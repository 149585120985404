var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "custom-select" }, [
    _c("div", { staticClass: "region-wrapper popuparea" }, [
      _c("div", { staticClass: "popup-holder striped" }, [
        _vm.options.data.items
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "perfectscroll",
                    rawName: "v-perfectscroll",
                    value: { enable: true, refreshScroll: false },
                    expression: "{enable: true, refreshScroll: false}"
                  }
                ],
                ref: "scrollArea",
                staticClass: "items-wrapper"
              },
              [
                _c("folder-tree", {
                  attrs: {
                    foldersSr: _vm.getFoldersSr(_vm.options.data.items),
                    rootName: _vm.options.rootName,
                    treeLogicalName: _vm.options.treeLogicalName,
                    predefinedId:
                      _vm.options.selectedItem &&
                      _vm.options.selectedItem.data.id
                  },
                  on: { treeClicked: _vm.selectItem }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.options.isLoading,
                expression: "options.isLoading"
              }
            ],
            staticClass: "items-footer"
          },
          [_c("div", { staticClass: "loading-overlay inline" })]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }