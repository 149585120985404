/* eslint no-async-promise-executor: 0 */

import MiddlewareBase from './MiddlewareBase';
import middlewareDataProvider from '@/Data/DataProviders/middlewareDataProvider';
import { Get as GetItem } from '@/Data/DataProviders/userStorageDataProvider';
import { evaluateAsync } from '@/Data/EvaluationContext/evaluationContext';

export default class extends MiddlewareBase {
	execute() {
		const chatparticipant = this.context.chatparticipantInfo || null;
		return new Promise(async (resolve, reject) => {
			const hasCRMRoleExpr = {
				$type: 'expression.hasCRMRole',
				name: 'Broker'
			};
			const isBroker = await evaluateAsync(hasCRMRoleExpr);
			if (this.context && !chatparticipant && this.context.id) {
				try {
					const filters = [];
					const path = `${this.context.$route.params.layout}_${this.context.$route.params.type}_${this.context.$route.params.id}`;
					const cpName = await GetItem(path);
					if (cpName && this.context.$route.query && this.context.$route.query.accessToken) {
						filters.push(sc.classes.get('termFacet.filter', {
							logicalName: 'name',
							query: [cpName]
						}).fillQuery());
					}
					const cpContext = await middlewareDataProvider.getParticipantContext({
						id: this.context.id,
						logicalname: this.context.logicalName
					}, filters);
					this.context.requestedAccess = cpContext.chatparticipant.requestedAccess;
					this.context.chatparticipantInfo = cpContext.chatparticipant;
					// If chatparticipant not from dealroom or he is not broker - we need to update 'ispending' field - otherwise it will be handled in checkNDA middleware
					if (!cpContext.chatparticipant.Source.chatparticipantchatidchat.dealroomid || isBroker) {
						const data = await middlewareDataProvider.changeParticipantState(cpContext.chatparticipant);
						if (data) this.context.chatparticipantInfo = data;
						this.fork = sc.events.fork();
						this.fork.emit('messages-resubscribe');
					}
					if (cpContext.chatparticipant.Source) this.context.participantExist = true;
					resolve();
				} catch (e) {
					this.context.data = null;
					this.context.error = true;
					this.context.participantExist = false;
					this.context.requestedAccess = e.requestedAccess;
					if (e.roomOwnerName) {
						if (this.context.errorComponent && this.context.errorComponent.options) {
							this.context.errorComponent.options.roomOwnerName = e.roomOwnerName;
						} else {
							this.context.errorComponent = { options: { roomOwnerName: e.roomOwnerName } };
						}
					}
					reject(e);
				}
			} else {
				if (!this.context.chatparticipantInfo.Source.chatparticipantchatidchat.dealroomid || isBroker) {
					const data = await middlewareDataProvider.changeParticipantState(this.context.chatparticipantInfo);
					if (data) this.context.chatparticipantInfo = data;
					this.fork = sc.events.fork();
					this.fork.emit('messages-resubscribe');
				}
				resolve();
			}
		});
	}
}
