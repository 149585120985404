<template>
	<div class="map-search-results-tooltip" v-bind:class="showPagination && !selectedItem && 'with-pagging'">
		<div class="left-top-triangle"></div>
		<div class="results-wrapper" v-perfectscroll="{ enable: !selectedItem, addMouseoverEvent: psAddMouseOverEvent, refreshScroll: true, refreshEvent: refreshEvent }" v-bind:style="{height: itemHeight}">
			<div v-bind:class="additionalClass + ' preview-item'" v-if="selectedItem">
				<entitytemplate-template	:options="previewTemplate"
											:key="selectedItem.Id"
											:evaluationContext="getContext(selectedItem)">
				</entitytemplate-template>
			</div>
			<div class="records-list" v-else-if="!selectedItem">
				<entitytemplate-template	:options="listTemplate"
											:evaluationContext="getContext(item)"
											@click.native="selectItem(item)"
											v-for="item in visibleItems" :key="item.Id">
				</entitytemplate-template>
			</div>
		</div>
		<pagination :is="pagination"
					:pageSize="paginationParams.pageSize"
					:total="paginationParams.total"
					:from="paginationParams.from"
					:activePages="paginationParams.activePages"></pagination>
	</div>
</template>
<script>
import EntitytemplateTemplate from './../EntityTemplate/entitytemplate-template.vue';
import Pagination from '../ComponentSet/pagination/pagination';
import MobilePagination from '../ComponentSet/pagination/mobile-pagination';
import { isUndefined } from '@acx-xms/data-functions/dist';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';

export default {
	name: 'map-tooltip-base',
	props: { options: Object },
	components: {
		EntitytemplateTemplate,
		Pagination,
		MobilePagination
	},
	computed: {
		selectedItem() {
			this.$root.$emit('tooltipTemplateChanged');
			return this.options.entities.length === 1 ? this.options.entities[0] : this.selectedItemInternal;
		},
		visibleItems() {
			return this.showPagination
				? this.options.entities.slice(this.paginationParams.from, this.paginationParams.activePages[this.paginationParams.activePages.length - 1] * this.paginationParams.pageSize)
				: this.options.entities;
		},
		itemHeight() {
			return !this.selectedItemInternal && this.listHeight ? this.listHeight : '100%';
		}
	},
	data() {
		return {
			visible: false,
			target: null,
			previewTemplate: null,
			listTemplate: null,
			selectedItemInternal: null,
			additionalClass: this.options.entities.length === 1 ? 'single-item' : 'multiple-items',
			paginationParams: {
				pageSize: this.options.tooltipSettings.pageSize || 6,
				total: this.options.entities.length,
				from: 0,
				activePages: [1]
			},
			showPagination: this.options.entities.length > (this.options.tooltipSettings.pageSize || 6),
			refreshEvent: 'map-toolip-scroll-refresh',
			listHeight: null,
			pagination: this.options.paginationComponent || 'pagination',
			psAddMouseOverEvent: isUndefined(this.options.psAddMouseOverEvent) ? true : this.options.psAddMouseOverEvent
		};
	},
	watch: {
		selectedItemInternal: function () {
			setTimeout(() => { this.$el.getElementsByClassName('perfectscroll-wrapper')[0].dispatchEvent(this.event); }, 0);
		}
	},
	created() {
		const dp = sc.classes.get('entityConfiguration.dataProvider');
		$.when(
			dp.getTemplate(this.options.entities[0].Type, this.options.tooltipSettings.listTemplate.usage, this.options.tooltipSettings.listTemplate.subtype),
			dp.getTemplate(this.options.entities[0].Type, this.options.tooltipSettings.previewTemplate.usage, this.options.tooltipSettings.previewTemplate.subtype))
			.then((list, preview) => {
				this.previewTemplate = preview;
				this.listTemplate = list;
			});

		this.fork = sc.events.fork();
		this.fork.on('map-tooltip.back', () => {
			this.selectedItemInternal = null;
		});

		this.event = new CustomEvent(this.refreshEvent, {
			bubbles: true,
			cancelable: false
		});

		this.$on('.pageChanged', (activePages) => {
			this.paginationParams.from = (activePages[0] - 1) * this.paginationParams.pageSize;
			this.paginationParams.activePages = activePages;

			if (!this.listHeight) {
				this.listHeight = this.$el.getElementsByClassName('results-wrapper')[0].clientHeight + 'px';
			}

			setTimeout(() => { this.$el.getElementsByClassName('perfectscroll-wrapper')[0].dispatchEvent(this.event); }, 0);
		});

		this.internalDictionary = {};
	},
	methods: {
		onScroll: function () {
			this.$root.$emit(this.options.namespace + '.scrolled');
		},
		getContext: function (item) {
			let context = this.internalDictionary[item.Id];
			if (context) { return context; }
			context = createEvaluationContext(item);
			this.internalDictionary[item.Id] = context;
			return context;
		},
		selectItem: function (item) {
			this.selectedItemInternal = item;
		}
	},
	beforeDestroy() {
		this.fork && this.fork.dispose();
	}
};
</script>
<style src="./map-tooltip.less" scoped></style>
