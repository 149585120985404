<template>
	<div class="search-results">
		<div v-if="currentcomponentset" class="search-results-wrapper">
			<div class="search-results-header">
				<div
					v-data-attr="{ options: options, stateNamespace: 'button' }"
					:class="['filter-button active', { open: !!showFilterPanel }]"
					@click="toggleFilterPanel()"
				>
					<svg class="svg-icon">
						<use
							class="ms-filter-icon"
							xlink:href="#layout-icons-ms-filter-icon"
						/>
					</svg>
				</div>
				<h1 class="search-results-title">
					{{ +$store.getters['wizard.navigation/getActive'] === 3 ? collectionTitle : title }}
				</h1>
				<SearchField class="search-results-field" />
				<ViewSwitcher
					:items="items"
					:stateNamespace="stateNamespace"
					class="search-results-switcher"
				/>
			</div>
			<TagsList
				v-if="(visibleSelectionList && !(+this.getActive === 3))"
				:stateNamespace="stateNamespace"
				:entityNamespace="logicalName"
				:placeholder="selectedTagsPlaceholder"
				class="search-results-selection"
				@delete="unselect"
			>
				<template>
					<span v-if="!!selectedItems && selectedItems.length && [3, 4].includes($store.getters['wizard.navigation/getActive'])">
						{{ selectedTagsCounter }}
					</span>
				</template>
			</TagsList>
			<div
				v-show="!isCollapsed"
				:class="['content-wrapper', {
					withPanel: showFilterPanel,
					loading: isLoading
				}]"
			>
				<FiltersPanel
					v-if="(+this.getActive === 3)"
					:options="options.filterPanel"
					:evaluationContext="filterPanelEvaluationContext"
					:isLoading="isLoading"
					:entityName="logicalName"
					:stateNamespace="stateNamespace"
					:class="['filter', { opened: showFilterPanel}]"
				/>
				<div class="content">
					<TagsList
						v-if="(visibleSelectionList && +this.getActive === 3)"
						:stateNamespace="stateNamespace"
						:entityNamespace="getCollectionType"
						:customSelectionsQuery="tagsCustomQuery"
						:class="['search-results-selection', getCollectionType]"
						@delete="unselect"
					>
						<template>
							<span v-if="!!selectedItems && selectedItems.length && [3, 4].includes($store.getters['wizard.navigation/getActive'])">
								{{ selectedTagsCounter }}
							</span>
						</template>
					</TagsList>
					<FiltersPanel
						v-if="!(+this.getActive === 3)"
						:options="options.filterPanel"
						:evaluationContext="filterPanelEvaluationContext"
						:isLoading="isLoading"
						:entityName="logicalName"
						:stateNamespace="stateNamespace"
						:class="['filter', { opened: showFilterPanel}]"
					/>
					<sortpanel
						v-show="false"
						:fields="options.sortByFields"
						:entityName="logicalName"
						:evaluationContext="evaluationContext"
						:scrollNamespace="scrollNamespace"
						:namespace="namespace"
						:internalNamespace="internalNamespace"
						:stateNamespace="stateNamespace"
						class="sortby-filter-wrapper"
					/>
					<component
						:is="content.name"
						:class="['content-inherit-height', { 'with-footer': !!results.total } ]"
						:options="{ ...content, withSelectionList: false }"
						:isActive="isActive"
						:results="results.results"
						:stateNamespace="stateNamespace"
						:combinedNamespace="!options.filterPanel ? combinedNamespace : ''"
						:paginationParams="paginationParams"
					/>
					<div v-show="isLoading" class="loading-overlay" />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import '@acx-xms/styles-mixins/style.mixins.less';
import '@/Bindings/ui-attribute.directive';

import RouteSearchResultsMixins from '@/Components/ComponentSet/SearchResults/route-search-results.mixins';
import WizardSearchResultMixin from './wizard-search-result.mixin';

const TagsList = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */'@/Components/Common/TagsList/tags-list');

export default {
	name: 'wizard-search-results',
	mixins: [RouteSearchResultsMixins, WizardSearchResultMixin],
	components: { TagsList }
};
</script>
/* Component styles */
<style lang="less" scoped src="./search-result.less"></style>
/* Child styles fixes */
<style lang="less">
.filter {
	.aside-panel {
		height: calc(100% - 1px);
		top: unset;
		bottom: 1px;
		border-radius: 0;
		transform: translateX(-100%);
		transition: all 150ms;
		box-shadow: none;
	}

	&.opened {
		.aside-panel {
			box-shadow: 10px 0px 30px rgba(0, 0, 0, 0.2);
			transform: translateX(0);
		}
	}
}
</style>
