<template>
    <div class='email-campaign-step-root select-listing'>
        <Loader v-if='templateProcess' :text='`The publication with chosen data is being generated for the future preview. This process can take some time and no changes can be made with listings until it ends. Please, wait a while`' />
        <SearchResults />
    </div>
</template>

<script>
const SearchResults = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */'../../Components/SearchResults/searchResults.vue');
import { mapActions, mapState, mapGetters } from 'vuex';
import { Search } from '@acx-xms/data-functions/dist';
import Loader from '../../Components/Loader/loader';
export default {
    name: 'email-campaign-step-select-listing',
    components: { SearchResults, Loader },
    created() {
        this.$route.params.componentSet = 'email-campaign-wizard-listing';
    },
    methods: {
        ...mapActions('wizard', ['setListingInitSelection', 'setErrors', 'updateEntryPointStatus', 'updateAvailabilitiesSelection']),
        async searchAvailabilites() {
            let listings = this.steps[2].selection.listing.map(i => i.Id);
            await Search('availability', [
                sc.classes.get('offsetSize.filter', 10000),
                sc.classes.get('termFacet.filter', {
                    logicalName: 'listingid.id',
                    query: !!listings.length ? [...listings] : [...this.getWizardListingState.map(i => i.Id)]
                }).fillQuery(),
                sc.classes.get('selectedFields.filter', []).fillQuery()
            ]).then(res => {
                this.$store.commit(`wizard.listing/changeSelection`, { records: res.Results, select: true });
                this.initAvailabilitySelection = res.Results;
                this.setListingInitSelection({listing:[], availability: []});
            }).finally(() => {
                this.updateEntryPointStatus(null);
            })
        }
    },
    computed: {
        ...mapState('wizard', ['steps', 'templateProcess']),
        ...mapGetters('wizard', ['isEdited']),
        getWizardListingState() {
            return this.$store.state 
                && this.$store.state['wizard.listing'] 
                && this.$store.state['wizard.listing'].selection 
                && this.$store.state['wizard.listing'].selection.listing;
        },
        getWizardAvailabilityState() {
            return this.$store.state 
                && this.$store.state['wizard.listing'] 
                && this.$store.state['wizard.listing'].selection
                && this.$store.state['wizard.listing'].selection.availability;
        },
        listingModuleReady() {
            return this.$store.state && this.$store.state['wizard.listing']
        }
    },
    mounted() {
		// TODO: add entryPointStatus field to Save As Draft
        if (this.$store.state['wizard'].entryPointStatus && 
            this.steps.find(i => i.name === 'template').templates.find(i => i.selected) && 
            this.steps.find(i => i.name === 'template').templates.find(i => i.selected).inputTypes && 
            this.steps.find(i => i.name === 'template').templates.find(i => i.selected).inputTypes === 'listing') {
                this.searchAvailabilites();
        }
        if (this.steps.find(i => i.name === 'collection').collectionLimitWarning) {
            this.setErrors([{
                text: 'It seems like no listings with availabilities were selected. For the “Availability” template type, only listings that contain availabilities must be used for successful report generation. Ensure that your selection meets this requirement before starting report generating.',
                type: 'warning',
                close: true,
                step: 'collection',
                name: 'emptyAvailabilitiesError',
            }])
        }
    },
    watch: {
        getWizardListingState (newVal, oldVal) {
            if (!this.isEdited && this.$route.params.isDetails && newVal) {
                this.$store.commit('wizard/UPDATE_EDITED_FLAG', true);
            }
            if (!this.isEdited && !this.$route.params.isDetails && (oldVal || !this.initListingSelection.length && newVal.length)) {
                this.$store.commit('wizard/UPDATE_EDITED_FLAG', true);
            }
        },
        getWizardAvailabilityState (newVal, oldVal) {
            if (!this.isEdited && this.$route.params.isDetails && newVal) {
                this.$store.commit('wizard/UPDATE_EDITED_FLAG', true);
            }
            if (!this.isEdited && !this.$route.params.isDetails && (oldVal || !this.initAvailabilitySelection.length && newVal.length)) {
                this.$store.commit('wizard/UPDATE_EDITED_FLAG', true);
            }
        },
        listingModuleReady (data) {
            if (data) { 
                this.$store.commit(`wizard.listing/changeSelection`, { records: [...this.steps[2].selection.listing], select: true });
                this.initListingSelection = this.steps[2].selection.listing;
                if (this.$store.state['wizard'].entryPointStatus && 
                this.steps.find(i => i.name === 'template').templates.find(i => i.selected) && 
                this.steps.find(i => i.name === 'template').templates.find(i => i.selected).inputTypes && 
                this.steps.find(i => i.name === 'template').templates.find(i => i.selected).inputTypes === 'listing') {
                    this.searchAvailabilites();
                } else {
                    if (this.steps[2].selection.availability) {
                        this.$store.commit(`wizard.listing/changeSelection`, { records: [...this.steps[2].selection.availability], select: true });
                        this.initAvailabilitySelection = this.steps[2].selection.availability;
                    }
                    this.setListingInitSelection({listing:[], availability: []});
                }
            }
        }
    }
};
</script>

<style lang='less' scoped>
@import '../../Assets/Styles/default.less';
@import './select-campaign-listing.less';
</style>