<template>
	<div class="create-relation-dialog editor">
		<div class="search-record">
			<div class="loading-overlay large" v-show="isLoading"></div>
			<div class="body">
				<control v-if="editorTemplate" :name="editorTemplate.$type"
						 :contentProps="editorTemplate"
						 :evaluationContext="options.params.evaluationContext"></control>
			</div>
		</div>
		<div class="footer">
			<div class="dialog-buttons">
				<button
					@click="cancel"
					class="cancel"
					type="button"
					v-localization="{ key: 'common.dictionary.buttons.cancel' }"
					v-data-attr="{ title: options.title , $type: 'dialog.button.cancel' }"
				>
				</button>
				<button
					@click="save"
					type="button"
					:disabled="!selectedRecord"
					v-localization="{ key: 'common.dictionary.buttons.save' }"
					v-data-attr="{ title: options.title , $type: 'dialog.button.cancel' }"
				>
				</button>
			</div>
		</div>
	</div>
</template>
<script>
/* eslint  vue/no-unused-components: 0 */
import Control from '@/Components/Entity/control.vue';
import ControlLookupEditor from '@/Components/Control/Lookup/control-lookup-editor';
import createRelationDataProvider from '@/Data/DataProviders/createRelationDataProvider';
import editorMixins from '@/Components/editor.mixins.js';
import { showToastOrInfoDialog } from '@acx-xms/data-functions/dist';

export default {
	name: 'create-relation-dialog',
	components: {
		Control,
		ControlLookupEditor
	},
	props: { options: Object },
	mixins: [editorMixins],
	data() {
		return {
			editorTemplate: null,
			isLoading: false
		};
	},
	computed: {
		selectedRecord() {
			const entity = this.$store.state[this.stateKey] && this.$store.state[this.stateKey].entityData;
			return entity ? entity.parentRecord : null;
		}
	},
	async created() {
		this.isLoading = true;

		this.logicalName = this.options.logicalName;
		this.stateKey = this.registerModule(this.logicalName);
		this.relationRecordName = this.options.params.relationRecordName;
		this.relateToEntityName = this.options.params.relateToEntityName;

		let relateFromRecord = this.options.params.relateFromRecord;
		if (relateFromRecord && !relateFromRecord.hasOwnProperty('Source')) {
			const edgeDataProvider = sc.classes.get('edge.dataProvider');
			relateFromRecord = await edgeDataProvider.get(relateFromRecord.logicalname, relateFromRecord.id);
		}
		const configDataProvider = sc.classes.get('entityConfiguration.dataProvider');
		let editorTemplate = this.options.editorTemplate && await configDataProvider
			.getTemplate(this.options.editorTemplate.entity, this.options.editorTemplate.usage, this.options.editorTemplate.subtype);
			// TODO: when rework entityReference class into ES module - add check if the record is already entity ref and return it, if it is the case
		this.relateFromRef = sc.classes.get('entityReference', relateFromRecord);
		if (this.options.params.excludeAlreadyAddedItems) {
			editorTemplate = await this.addFiltersToLookupInTemplate(editorTemplate);
		}
		this.editorTemplate = editorTemplate && editorTemplate.content;
		this.onRelationExistsMessage = this.options.params.onRelationExistsMessage;

		this.isLoading = false;
	},
	methods: {
		async addFiltersToLookupInTemplate(template) {
			if (!template) { return null; }

			const relatedRecordsIds = await createRelationDataProvider
				.findRelatedRecordIds(this.relationRecordName, this.relateFromRef.logicalname + 'id', this.relateFromRef.id, this.relateToEntityName + 'id');

			if (!relatedRecordsIds.length) { return template; }

			let path = this.getPath(template.content, '$type', 'control.lookupEditor');
			const n = path.lastIndexOf('.');
			path = path.substring(0, n !== -1 ? n : path.length);
			const lookup = sc.utils.findProperty(template.content, path, true);
			const ids = relatedRecordsIds.map(id => {
				return {
					$type: 'search.termFacetItem',
					value: {
						$type: 'expression.text',
						value: id
					}
				};
			});
			lookup.filters.push({
				$type: 'search.termFacetFilter',
				negative: true,
				filterViewKind: 'Default',
				item: ids,
				logicalName: this.relateToEntityName + 'id'
			});

			return template;
		},
		getPath(obj, key, val, currentPath = '') {
			let matchingPath;

			if (!obj || typeof obj !== 'object') return;

			if (obj[key] === val) {
				return `${currentPath}.${key}`;
			}

			for (const k of Object.keys(obj)) {
				if (k === key && obj[k] === val) {
					matchingPath = currentPath;
				} else {
					const newPath = currentPath ? `${currentPath}.${k}` : `${k}`;
					matchingPath = this.getPath(obj[k], key, val, newPath);
				}

				if (matchingPath) break;
			}

			return matchingPath;
		},
		async save() {
			this.isLoading = true;
			const relateFromField = this.relateFromRef.logicalname + 'id';
			const relateToField = this.relateToEntityName + 'id';
			const relateToRef = {
				id: this.selectedRecord.id,
				logicalname: this.relateToEntityName
			};

			try {
				const isExist = await createRelationDataProvider
					.checkIsRelatedRecordExists(this.relationRecordName, relateFromField, this.relateFromRef.id, relateToField, relateToRef.id);

				if (isExist) {
					this.isLoading = false;
					this.openInfoDialog('Warning', this.onRelationExistsMessage);
				} else {
					await createRelationDataProvider.createRelationRecord(
						this.relationRecordName,
						relateFromField,
						this.relateFromRef,
						relateToField,
						relateToRef,
						this.relateFromRef.name,
						this.options.params.additionalFields
					);
				}
			} catch (err) {
				let error;
				typeof err === 'object' && (error = err.message || err.Message || null);
				const errorMessage = error ? `Error message: ${error}` : 'Unhandled error.';
				this.isLoading = false;
				this.openInfoDialog('Something went wrong', errorMessage);
			}

			this.isLoading = false;
			await showToastOrInfoDialog({
				toastMessageKey: this.options.params.toastMessageKey,
				informationDialogTextKey: this.options.params.informationDialogTextKey,
				options: { context: this }
			});
		},
		openInfoDialog(title, message) {
			this.$root.$emit('vue.dialog.info', {
				title,
				message
			});
		},
		cancel() {
			this.$parent.$emit('close');
		}
	}
};
</script>
<style src="./create-relation-dialog.less" scoped></style>
