var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.enable && _vm.visible
    ? _c(
        "div",
        {
          staticClass: "column",
          class: [_vm.cssClass, { collapsed: !_vm.collapsed }],
          style: { width: _vm.width || "100%", height: _vm.height }
        },
        [
          _vm.allowToggle
            ? _c("div", {
                staticClass: "toggle",
                class: { maximized: _vm.collapsed },
                on: { click: _vm.toggle }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.hideHeader
            ? _c(
                "div",
                {
                  staticClass: "column-title",
                  class: {
                    "with-check": _vm.options.withCheckAll,
                    "column-title-pointer": _vm.allowToggle
                  },
                  on: {
                    click: function($event) {
                      _vm.allowToggle ? _vm.toggle() : null
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex-wrapper" },
                    [
                      _vm._v("\n\t\t\t\t" + _vm._s(_vm.title) + "\n\t\t\t\t"),
                      _vm.actionMenuComponent
                        ? _c("action-menu", {
                            staticClass: "without-border",
                            attrs: {
                              options: _vm.actionMenuComponent,
                              evaluationContext: _vm.evaluationContext
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.collapsed,
                  expression: "collapsed"
                },
                {
                  name: "perfectscroll",
                  rawName: "v-perfectscroll",
                  value: { enabled: true },
                  expression: "{enabled:true}"
                }
              ],
              staticClass: "column-content",
              class: { "no-header": _vm.hideHeader }
            },
            [
              _c("control", {
                attrs: {
                  name: _vm.options.control.$type,
                  contentProps: _vm.options.control,
                  evaluationContext: _vm.evaluationContext
                }
              })
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }