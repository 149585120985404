import LookupMixins from './lookup.mixins';

export default {
	props: {
		value: Object,
		showRequired: Boolean,
		defaultValue: Object
	},
	mixins: [
		LookupMixins
	],
	computed: {
		showRequiredMark() {
			const requiredFlag = this.showRequired !== void 0 ? this.showRequired : this.isRequired;
			this.options.focusCallback && this.options.focusCallback(this.searchFocused || this.isPopUpOpen);
			return !this.isPopUpOpen && requiredFlag && !this.value && !this.searchFocused;
		},
		selectedItem: {
			get() {
				return this.value === void 0 ? this.selectedItemVal : this.value;
			},
			set(value) {
				this.$emit('input', value);
				this.selectedItemVal = value;
			}
		}
	},
	mounted() {
		this.input = this.$el.querySelector('.lookup-editor-input');
	},
	methods: {
		onSelected(item) {
			this.selectedItem = item;
		},
		onClosePopup() {
			this.searchFocused = false;
		},
		onInputFocus(e) {
			if (this.selectedItem) {
				this.$nextTick(() => {
					e.target.select();
				});
			}
		},
		focusInput() {
			// TODO: remove when new Lookup for Import will be created
			if (this.options.disabled || this.temporarySelectLookupItems.length) {
				return;
			}
			this.searchFocused = true;
			this.$nextTick(() => {
				this.input.focus();
			});
		},
		iconAction() {
			this.$root.$once('lookupArea.scrolled', this.closePopUp);
			if (this.options.disabled) {
				return;
			}
			if (!this.isPopUpOpen) {
				this.focusInput();
			}
			this.$nextTick(() => {
				this.search(true);
			});
		}
	}
};
