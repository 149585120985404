<template>
	<div v-if='isActive && visible'>
		<slot></slot>
	</div>
</template>

<script>
export default {
	props: {
		title: String,
		visible: {
			Type: Boolean,
			default: false
		}
	},
	data() {
		return { isActive: true };
	}
};
</script>
