<template>
	<div class="sc-ndaFileUpload">
		<div class="nda-file-upload-wrapper row">
			<div class="input-radio-wrapper" v-if="!disableFileLookupEditor">
				<input type="radio" v-model="isExisting" v-bind:value="false" v-data-attr="{title: dataAttr.title, $type: 'dialog.input.radio'}"/>
			</div>
			<control-file-upload	class="control-wrapper"
									:class="{'invalid': !isExisting&&!isValid}"
									v-model="uploadedFileName"
									:key="isExisting"
									:showRequiredMark="showRequiredMark"
									:evaluationContext="evaluationContext"
									:options="uploaderOptions"
									v-data-attr="{title: dataAttr.title, $type: 'dialog.input.upload'}">
			</control-file-upload>
		</div>
		<div class="nda-file-select-document-wrapper row" v-if="!disableFileLookupEditor">
			<div class="input-radio-wrapper">
				<input type="radio" v-model="isExisting" v-bind:value="true" v-data-attr="{title: dataAttr.title, $type: 'dialog.input.radio'}"/>
			</div>

			<control-folder-tree-view-editor	class="control-wrapper" v-if="componentOptions"
												:invalid="!isValid && isExisting"
												:visible="isExisting"
												@existingDocument="setExisting"
												:showRequiredMark="showRequiredMark"
												:evaluationContext="evaluationContext"
												:options="componentOptions && componentOptions.component[0]">
			</control-folder-tree-view-editor>
		</div>
	</div>
</template>
<script>
import ControlFolderTreeViewEditor from '@/Components/Editor/Controls/FolderTreeViewEditor/control-folder-tree-view-editor';
import ControlMixins from '@/Components/Editor/Controls/control.mixins';
const ControlFileUpload = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/FileUpload/file-upload');

export default {
	name: 'control-ndafile-editor',
	mixins: [ControlMixins],
	components: {
		ControlFileUpload,
		ControlFolderTreeViewEditor
	},
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return { componentOptions: null };
	},
	async created() {
		this.disableFileLookupEditor = this.evaluationContext.eval(this.options.disableFileLookupEditor);
		if (!this.value) {
			await this.$store.dispatch(this.stateKey + '/setField', {
				name: this.field,
				value: {},
				isFormInit: true
			});
		}
		this.componentOptions = await sc.classes.get('marketspaceComponents.dataProvider').getComponentSetById('document.create.dealroom');
	},
	beforeDestroy() {
		if (this.componentOptions && this.componentOptions.component[0] && this.componentOptions.component[0]) {
			const stateNamespace = this.evaluationContext.eval(this.componentOptions.component[0].stateNamespace);
			if (this.$store.state[stateNamespace]) this.$store.commit(`${stateNamespace}/cleanSelection`);
		}
	},
	computed: {
		showRequiredMark() {
			return this.storeState.requiredFields[this.field] && !this.selectedFile && !this.uploadedFileName;
		},
		uploaderOptions() {
			// todo - find a better way to proxy config options
			return Object.assign(this.options, { disabled: this.isExisting });
		},

		selectedFile: {
			get() {
				return this.value ? this.value.selectedFile : null;
			},
			set(value) {
				this.$set(this.value, 'selectedFile', value);
			}
		},
		uploadedFileName: {
			get() {
				return this.value ? this.value.uploadedFileName : null;
			},
			set(value) {
				this.$set(this.value, 'uploadedFileName', value);
			}
		},
		isExisting: {
			get() {
				return this.value ? !!this.value.isExisting : false;
			},
			set(value) {
				this.$set(this.value, 'isExisting', value);
				!value && this.$set(this.value, 'selectedFile', null);
			}
		}
	},
	methods: {
		setExisting(selection) {
			if (_.isEmpty(selection)) {
				this.selectedFile = null;
				return;
			}
			this.selectedFile = { data: Object.values(selection)[0][0] };
		}
	}
};
</script>
<style src="./control-ndafile-editor.less" scoped></style>
