var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _vm.isLoading
        ? _c("div", { staticClass: "loading-wrapper" }, [
            _c("div", { staticClass: "loading-overlay" })
          ])
        : [
            _vm.error
              ? _c(
                  "div",
                  { staticClass: "no-data" },
                  [
                    _c("details-no-access", {
                      tag: "component",
                      attrs: {
                        options: _vm.getErrorComponentOptions(),
                        requestedAccess: _vm.requestedAccess
                      }
                    })
                  ],
                  1
                )
              : [
                  _c(
                    "div",
                    { staticClass: "header" },
                    [
                      _vm.breadcrumbs
                        ? _c("Breadcrumbs", {
                            attrs: {
                              breadcrumbs: _vm.breadcrumbs,
                              isDeactivated: _vm.isDeactivated
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.accessByLink
                        ? [
                            _vm.actionMenuComponent
                              ? _c("ActionMenu", {
                                  staticClass: "without-padding without-border",
                                  attrs: {
                                    options: _vm.actionMenuComponent,
                                    record: _vm.data,
                                    evaluationContext: _vm.evaluationContext
                                  }
                                })
                              : _vm._e()
                          ]
                        : _c("div", { staticClass: "h1" }, [
                            _vm._v(_vm._s(_vm.data.Name))
                          ])
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "perfectscroll",
                          rawName: "v-perfectscroll",
                          value: {
                            enable: true,
                            onScroll: _vm.onScroll,
                            updateScrollEvent: _vm.updateScrollEvent
                          },
                          expression:
                            "{enable: true, onScroll: onScroll, updateScrollEvent}"
                        }
                      ],
                      staticClass: "rows content-scroll-wrapper"
                    },
                    _vm._l(_vm.rows, function(row) {
                      return _c("DetailsRowControl", {
                        key: row.$key,
                        style: { height: row.height },
                        attrs: {
                          evaluationContext: _vm.evaluationContext,
                          row: row
                        }
                      })
                    })
                  )
                ]
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }