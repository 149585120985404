var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.enable
    ? _c(
        "div",
        {
          staticClass: "column",
          class: _vm.cssClass,
          style: { width: _vm.width || "100%", height: _vm.height || "auto" }
        },
        [
          _c(
            "div",
            {
              staticClass: "column-wrapper",
              class: { minimized: !_vm.collapsed }
            },
            [
              !_vm.hideHeader
                ? _c("div", { staticClass: "column-title" }, [
                    _c("div", { staticClass: "region-left" }, [
                      _c(
                        "div",
                        { staticClass: "region" },
                        [
                          _vm._t("title-slot", [
                            _c("p", {
                              domProps: { textContent: _vm._s(_vm.title) }
                            })
                          ]),
                          _vm._v(" "),
                          _vm._t("toggle-slot", null, { slot: "toggle-slot" })
                        ],
                        2
                      )
                    ]),
                    _vm._v(" "),
                    !_vm.computedLoading
                      ? _c(
                          "div",
                          { staticClass: "action region-right" },
                          [
                            _vm.innerContext !== null &&
                            _vm.actionMenuComponent &&
                            (_vm.actionMenuComponent.menu ||
                              _vm.actionMenuComponent.toolbarActions)
                              ? _c("action-menu", {
                                  staticClass: "without-padding without-border",
                                  attrs: {
                                    options: _vm.actionMenuComponent,
                                    evaluationContext: _vm.innerContext,
                                    record: _vm.record
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.allowToggle
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "icon-holder",
                                    on: { click: _vm.toggle }
                                  },
                                  [
                                    _c(
                                      "svg",
                                      { staticClass: "svg-icon svg-20" },
                                      [
                                        _vm.collapsed
                                          ? _c("use", {
                                              staticClass: "hover",
                                              attrs: {
                                                "xlink:href":
                                                  "#layout-icons-header-arrow-up"
                                              }
                                            })
                                          : !_vm.collapsed
                                          ? _c("use", {
                                              staticClass: "hover",
                                              attrs: {
                                                "xlink:href":
                                                  "#layout-icons-header-arrow-down"
                                              }
                                            })
                                          : _vm._e()
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.computedLoading,
                    expression: "computedLoading"
                  }
                ],
                staticClass: "loading-overlay"
              }),
              _vm._v(" "),
              !_vm.computedLoading && _vm.innerContext !== null
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "perfectscroll",
                          rawName: "v-perfectscroll",
                          value: { enabled: true },
                          expression: "{enabled:true}"
                        }
                      ],
                      staticClass: "column-content",
                      class: { "no-header": _vm.hideHeader }
                    },
                    [
                      _c("control", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.collapsed,
                            expression: "collapsed"
                          }
                        ],
                        attrs: {
                          name: _vm.options.control.$type,
                          contentProps: _vm.options.control,
                          evaluationContext: _vm.innerContext
                        }
                      }),
                      _vm._v(" "),
                      _vm.options.controlMinimized
                        ? _c("control", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !_vm.collapsed &&
                                  _vm.options.controlMinimized,
                                expression:
                                  "!collapsed && options.controlMinimized"
                              }
                            ],
                            attrs: {
                              name: _vm.options.controlMinimized.$type,
                              contentProps: _vm.options.controlMinimized,
                              evaluationContext: _vm.innerContext
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }