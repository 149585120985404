var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-content-wrapper" }, [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ],
      staticClass: "loading-overlay large"
    }),
    _vm._v(" "),
    _c("div", { staticClass: "share-entity-dialog" }, [
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", { staticClass: "description" }, [
          _c("h5", {
            directives: [
              {
                name: "localization",
                rawName: "v-localization",
                value: { key: "dialogues.shareSavedSearch.findContacts" },
                expression: "{ key: 'dialogues.shareSavedSearch.findContacts' }"
              }
            ]
          })
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "select-entity" },
          [
            _c("control-lookup", {
              attrs: {
                evaluationContext: _vm.options.evaluationContext,
                options: _vm.getLookupOptions()
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "entity-list" },
          [
            _vm.userListTemplate
              ? _c("control", {
                  staticClass: "formcontrol-template",
                  attrs: {
                    name: _vm.userListTemplate.$type,
                    contentProps: _vm.userListTemplate,
                    evaluationContext: _vm.options.evaluationContext,
                    initResults: _vm.sharedData
                  }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "footer" }, [
        _c("div", { staticClass: "buttons" }, [
          _c("span", { staticClass: "button-wrapper" }, [
            _c("button", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: { key: "dialogues.shareSavedSearch.share" },
                  expression: "{key: 'dialogues.shareSavedSearch.share'}"
                }
              ],
              attrs: { disabled: !_vm.enableSave, type: "button" },
              on: { click: _vm.save }
            })
          ]),
          _vm._v(" "),
          _c("button", {
            directives: [
              {
                name: "localization",
                rawName: "v-localization",
                value: { key: "common.dictionary.buttons.cancel" },
                expression: "{key: 'common.dictionary.buttons.cancel'}"
              }
            ],
            staticClass: "cancel",
            attrs: { type: "button" },
            on: { click: _vm.close }
          })
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }