import { isUndefined } from '@acx-xms/data-functions/dist';
import { Set as StorageSet } from '@/Data/DataProviders/localStorageDataProvider';
import { GetAvatar } from '@/Data/avatar-service';

function findChat(recordId) {
	return this.edgeDataProvider.search({
		entities: ['chat'],
		filters: [
			sc.classes.get('offsetFrom.filter', 0),
			sc.classes.get('offsetSize.filter', 1000),
			sc.classes.get('termFacet.filter', {
				logicalName: this.logicalName + 'id.id',
				query: [recordId]
			}),
			sc.classes.get('selectedFields.filter', [
				{ logicalname: 'recordstate.id' },
				{ logicalname: 'id' }
			])
		],
		relation: false
	});
};

function getChatParticipant(systemUserId, chatId, additionalFilters = []) {
	this.isLoading = true;
	return this.edgeDataProvider.search(
		{
			entities: ['chatparticipant'],
			filters: [
				sc.classes.get('termFacet.filter', {
					logicalName: 'chatparticipantcontactidcontact.systemuserid.id',
					query: [systemUserId]
				}),
				sc.classes.get('termFacet.filter', {
					logicalName: 'chatid.id',
					query: [chatId]
				}),
				sc.classes.get('offsetSize.filter', 1),
				sc.classes.get('selectedFields.filter', [
					{ logicalname: 'chatparticipantchatidchat.recordstate.id' },
					{ logicalname: 'chatparticipantcontactidcontact.fullname' },
					{ logicalname: 'nonregistered' },
					{ logicalname: 'contactid' },
					{ logicalname: 'systemuserid.id' },
					{ logicalname: 'ispending' }
				]),
				...additionalFilters
			],
			resultFormat: 'detail'
		}
	);
};
function onNewMessages(messages) {
	for (let i = 0; i <= messages.length; i++) {
		onNewMessage(messages[i], i === messages.length);
	}
}

function onNewMessage(message, callMarkAsRead = true) {
	const markMessagesAsRead = async (lastMessage) => {
		if (lastMessage && (new Date(this.lastReadMessageDate) < new Date(lastMessage.Source.createdonfromui) || !this.lastReadMessageDate)) {
			await this.edgeDataProvider.update('chatparticipant', this.chatParticipant.Id, { lastreadmessagedate: lastMessage.Source.createdonfromui }, undefined, undefined, true);
			this.lastReadMessageDate = lastMessage.Source.createdonfromui;
		}

		this.unreadMessages.length && setTimeout(() => {
			this.unreadMessages.forEach((message) => {
				message.Source.unread = false;
			});
			this.$store.commit('counters/removeAllCountersForChat', this.chat.id);
			this.unreadMessages = [];
		}, 500);
	};
	if (message) {
		const isMessageInList = this.loadedMessageIds.indexOf(message.Id) > -1;
		const isAnonymousAndNotCreator = Boolean(this.chatParticipant.Source.nonregistered && (message.Source.contactname !== this.chatParticipant.Name));
		const isCreator = Boolean(message.Source.createdby.id === this.chatParticipant.Source.systemuserid.id);

		/* save message in storage */
		StorageSet(message.Source.chatid.id, [{
			chatid: message.Source.chatid.id,
			message
		}]);

		if (!isMessageInList && ((isAnonymousAndNotCreator && !isCreator) || (!isAnonymousAndNotCreator && isCreator) || (!isAnonymousAndNotCreator && !isCreator))) {
			if (!message.Source.chatmessagecreatedbysystemuser) {
				this.$set(message.Source, 'chatmessagecreatedbysystemuser', {
					fullname: this.currentUser.fullname,
					avatarid: GetAvatar()
				}); /* preventing bug with avatar */
			}
			if (!this.messages.find(el => el.Id === message.Id)) { /* preventing strange bug with search in 'loadedMessageIds' array */
				this.$set(message.Source, 'unread', true); /* set 'unread' only on new messages */
				this.messages.push(message);
				this.unreadMessages.push(message);
			}
			this.messages.sort((a, b) => new Date(a.Source.createdonfromui) - new Date(b.Source.createdonfromui));
			this.loadedMessageIds.push(message.Id);
		}

		callMarkAsRead && markMessagesAsRead(message);
		this.isLoading = false;
	}
};

function findMessages(options = { }) {
	const self = this;

	if (options.cleared) {
		self.loadedMessageIds = [];
		self.messages = [];
		self.oldmessagesscount = 0;
	}

	const getLastReadMessageDate = function () {
		return self.edgeDataProvider.search({
			entities: ['chatparticipant'],
			filters: [
				sc.classes.get('offsetFrom.filter', 0),
				sc.classes.get('offsetSize.filter', 1),
				sc.classes.get('ids.filter', [self.chatParticipant.Id]),
				sc.classes.get('selectedFields.filter', [{ logicalname: 'lastreadmessagedate' }]),
				sc.classes.get('resultsFormat.filter', 'Source')
			],
			relation: false
		}).then((data) => {
			const chatParticipant = data.Results.pop();
			const lastreadmessageDate = chatParticipant.lastreadmessagedate;
			if (isUndefined(self.initialLastReadMessage)) {
				self.initialLastReadMessage = lastreadmessageDate;
			}
			self.lastReadRemoteMessageDate = lastreadmessageDate;
			self.lastReadMessageDate = lastreadmessageDate;
			return lastreadmessageDate;
		});
	};

	const markMessagesAsRead = async function (lastMessage) {
		if (lastMessage && (new Date(self.lastReadMessageDate) < new Date(lastMessage.Source.createdonfromui) || !self.lastReadMessageDate)) {
			await self.edgeDataProvider.update('chatparticipant', self.chatParticipant.Id, { lastreadmessagedate: lastMessage.Source.createdonfromui }, undefined, undefined, true);
			self.lastReadMessageDate = lastMessage.Source.createdonfromui;
		}

		self.unreadMessages.length && setTimeout(() => {
			self.unreadMessages.forEach((message) => {
				if (message.Source.unread) {
					message.Source.unread = false;
				}
			});
			self.$store.commit('counters/removeAllCountersForChat', self.chat.id);

			self.unreadMessages = [];
		}, 500);
	};

	const responseCallback = function (messages) {
		if (messages && messages.Results && messages.Results.length) {
			const messagesById = {};
			self.messages.forEach((item, index) => {
				// messages with status were added by user and should be replaced with saved versions from the serevr
				if (!item.status) messagesById[item.Id] = item;
			});

			messages.Results.forEach((item) => {
				messagesById[item.Id] = item;
			});

			self.messages = Object.values(messagesById);
			self.messages.sort((a, b) => {
				return new Date(a.Source.createdonfromui) - new Date(b.Source.createdonfromui);
			});
		}
		return messages;
	};

	const getRequest = function (filters, isdescending = true) {
		const chatFilters = [
			sc.classes.get('termFacet.filter', {
				logicalName: 'chatid.id',
				query: [self.chat.id]
			}),
			sc.classes.get('orderBy.filter', {
				query: [{
					logicalName: 'createdonfromui',
					value: isdescending
				}]
			}),
			sc.classes.get('offsetSize.filter', self.numberOfMessages),
			sc.classes.get('selectedFields.filter', [
				{ logicalname: 'createdonfromui' },
				{ logicalname: 'createdby' },
				{ logicalname: 'ownerid' },
				{ logicalname: 'chatmessageid' },
				{ logicalname: 'messagetext' },
				{ logicalname: 'contactid' },
				{ logicalname: 'contactname' },
				{ logicalname: 'messagetype' },
				{ logicalname: 'nonregistered' },
				{ logicalname: 'parentchatmessageid' },
				{ logicalname: 'chatmessagecreatedbysystemuser.fullname' },
				{ logicalname: 'chatmessagecreatedbysystemuser.avatarid' },
				{ logicalname: 'chatmessagecontactidcontact.fullname' },
				{ logicalname: 'chatmessageparentchatmessageidchatmessage.messagetext' },
				{ logicalname: 'chatmessageparentchatmessageidchatmessage.createdonfromui' },
				{ logicalname: 'chatmessageparentchatmessageidchatmessage.contactname' },
				{ logicalname: 'chatmessageparentchatmessageidchatmessage.chatmessageid' },
				{ logicalname: 'chatmessagechatidchat.collaborationroomid' },
				{ logicalname: 'chatmessagechatidchat.dealroomid' }
			])
		];

		self.filters.forEach(filter => {
			filter && chatFilters.push(filter);
		});
		filters.push.apply(filters, chatFilters);

		return self.edgeDataProvider.search({
			entities: ['chatmessage'],
			filters,
			relation: false
		});
	};

	const getOldMessages = function (lastReadMessageDate) {
		if (!options.initial && self.oldmessagesscount >= self.oldmessagessTotalcount) {
			return $.Deferred().resolve().promise();
		}

		let date = lastReadMessageDate;
		const date1 = new Date(lastReadMessageDate);
		date1.setMilliseconds(date1.getMilliseconds() + 1);
		date = date1.toJSON();

		return getRequest([
			sc.classes.get('offsetFrom.filter', self.oldmessagesscount),
			sc.classes.get('dateTimeFacet.filter', {
				logicalName: 'createdonfromui',
				query: [{
					IsChecked: true,
					Range: {
						To: date,
						ToIncluded: true
					}
				}]
			})
		])
			.then(responseCallback)
			.then(function (messages) {
				if (messages && messages.Results && messages.Results.length) {
					self.oldmessagesscount += messages.Results.length;
					self.loadedMessageIds.push.apply(self.loadedMessageIds, messages.Results.map(res => res.Id));

					if (options.initial) {
						self.oldmessagessTotalcount = messages.Total;
					}
				}
				return messages;
			});
	};

	const getMessages = function () {
		const range = {
			Range: {
				FromIncluded: false,
				ToIncluded: true
			},
			IsChecked: true
		};

		range.Range.To = null;
		if (self.lastReadMessageDate) {
			const lastReadRemote = new Date(self.lastReadRemoteMessageDate);
			const lastRead = new Date(self.lastReadMessageDate);
			const dateFrom = lastReadRemote.getTime() < lastRead.getTime() ? lastReadRemote : lastRead;
			lastReadRemote.getTime() === lastRead.getTime() && dateFrom.setMilliseconds(dateFrom.getMilliseconds() + 1);

			range.Range.From = dateFrom.toJSON();
		}
		return getRequest([
			sc.classes.get('offsetFrom.filter', 0),
			sc.classes.get('dateTimeFacet.filter', {
				logicalName: 'createdonfromui',
				query: [range]
			})
		])
			.then((messages) => {
				messages && messages.Results && messages.Results.forEach((message) => {
					if (message.Source.createdby.id !== self.chatParticipant.Source.systemuserid.id && self.loadedMessageIds.indexOf(message.Id) === -1) {
						self.$set(message.Source, 'unread', true);
						self.unreadMessages.push(message);
						self.loadedMessageIds.push(message.Id);
					}
				});
				return messages;
			})
			.then(responseCallback)
			.then((messages) => {
				messages && messages.Results && messages.Results.length && markMessagesAsRead(messages.Results[0]);
				self.isLoading = false;
				return messages;
			});
	};

	const getMessagesFilteredByQuery = (query, initial) => {
		if (initial) {
			self.loadedMessageIds = [];
			self.messages = [];
			self.isLoading = true;
			self.loadMoreFiltered = true;
		} else if (!self.loadMoreFiltered) {
			self.isLoading = false;
			return Promise.resolve();
		}

		const additionalFilters = [
			sc.classes.get('offsetFrom.filter', initial ? 0 : self.messages.length)
		];
		if (query) {
			const filter = {
				additionalOptions: {
					kind: 'RelevancePrefix',
					boost: {
						phrase: 2400,
						phrasePrefix: 25,
						term: 1
					}
				},
				logicalName: ['messagetext', 'contactname'].map(field => {
					return {
						logicalName: field,
						boost: 200
					};
				}),
				query
			};
			additionalFilters.push(sc.classes.get('field.filter', filter));
		}

		let total = 0;
		return getRequest(additionalFilters, false)
			.then((messages) => {
				total = messages.Total;

				const replaceText = (text) => {
					if (!query) return [{ message: text }];

					let newQuery = '';
					query.split(' ').forEach(item => {
						if (newQuery) {
							newQuery += '|';
						}
						newQuery += item;
					});
					newQuery = '(' + newQuery + ')';

					const itemsArray = [];
					let lastSavedIndex = 0;
					const re = new RegExp(newQuery, 'gi');
					const matchArray = Array.from(text.matchAll(re));
					matchArray.forEach((item, index) => {
						const itemLength = item[0].length;
						if (lastSavedIndex < item.index) {
							itemsArray.push({
								message: text.substring(lastSavedIndex, item.index),
								highlight: false
							});
							lastSavedIndex = item.index;
						}
						if (lastSavedIndex === item.index) {
							itemsArray.push({
								message: text.substring(lastSavedIndex, lastSavedIndex + itemLength),
								highlight: true
							});
							lastSavedIndex = item.index + itemLength;
						}
						if (lastSavedIndex !== text.length && matchArray.length === (index + 1)) {
							itemsArray.push({
								message: text.substring(lastSavedIndex, text.length),
								highlight: false
							});
							lastSavedIndex = text.length;
						}
					});

					return itemsArray.length ? itemsArray : [{ message: text }];
				};

				messages && messages.Results && messages.Results.forEach((message) => {
					message.Source.contactname = replaceText(message.Source.contactname);
					message.Source.messagetext = replaceText(message.Source.messagetext);
					message.parsedMessage = true;
				});

				return messages;
			})
			.then(responseCallback)
			.then((messages) => {
				self.isLoading = false;
				self.loadMoreFiltered = self.messages.length < total;
				return messages;
			});
	};

	if (options.initial && !options.searchQuery) {
		return getLastReadMessageDate().then(options.initial ? getOldMessages : $.Deferred().resolve).then(getMessages);
	}
	if (options.isOld) {
		return getOldMessages(self.initialLastReadMessage);
	}
	if (options.searchQuery) {
		return getMessagesFilteredByQuery(options.searchQuery, options.initial);
	}
	return getMessages();
};

function scrollToTheBottom() {
	const container = this.$el.querySelector('.all-messages');
	if (container) {
		container.scrollTop = container.scrollHeight;
	}
};

function scrollToTheTop() {
	const container = this.$el.querySelector('.all-messages');
	if (container) {
		container.scrollTop = 0;
	}
};

function scrollToTheLastReadMessage() {
	const container = this.$el.querySelector('.all-messages');
	const firstUnreadMessage = this.$el.querySelector('.unread');
	if (container) {
		if (firstUnreadMessage) {
			container.scrollTop = firstUnreadMessage.offsetTop - (firstUnreadMessage.offsetHeight + 17);
		} else {
			container.scrollTop = container.scrollHeight;
		}
	}
};

function isNeededScrollToTheBottom() {
	const container = this.$el.querySelector('.all-messages');
	return container && (container.scrollHeight <= container.offsetHeight + container.scrollTop);
};

export {
	findChat, getChatParticipant, onNewMessage, onNewMessages, findMessages, scrollToTheBottom, scrollToTheTop, scrollToTheLastReadMessage, isNeededScrollToTheBottom
};
