var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "searchResults-rowsview" },
    [
      _vm.results && _vm.results.length && _vm.withCheckAll
        ? _c(
            "div",
            { staticClass: "searchResults-map-view-header tableview-header" },
            [
              _c("ControlSelectAll", {
                attrs: {
                  stateNamespace: _vm.stateNamespace,
                  results: _vm.results,
                  withCheckAll: _vm.withCheckAll,
                  isParent: _vm.options.isParent,
                  syncSelection: _vm.options.syncSelection,
                  targetEntity: _vm.targetEntity
                }
              }),
              _vm._v(" "),
              _c("div", [
                _c("span", {
                  directives: [
                    {
                      name: "localization",
                      rawName: "v-localization",
                      value: { key: "common.dictionary.selectAll" },
                      expression: "{ key: 'common.dictionary.selectAll' }"
                    }
                  ]
                })
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            "rowsview-wrapper",
            _vm.options.cssClass,
            { "without-tags": !_vm.options.withSelectionList }
          ]
        },
        [
          _vm.options.withSelectionList &&
          _vm.stateNamespace &&
          _vm.targetEntity &&
          _vm.results &&
          _vm.results.length
            ? _c("TagList", {
                attrs: {
                  stateNamespace: _vm.stateNamespace,
                  entityNamespace: _vm.targetEntity && _vm.targetEntity[0]
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.results && _vm.results.length
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "perfectscroll",
                      rawName: "v-perfectscroll",
                      value: {
                        enable: true,
                        onScroll: _vm.onScroll,
                        scrollToTopEvent: _vm.scrollToTopEvent
                      },
                      expression:
                        "{ enable: true, onScroll: onScroll, scrollToTopEvent }"
                    }
                  ],
                  staticClass:
                    "perfectscroll-wrapper search-results-row-items-wrapper"
                },
                [
                  _c(
                    "div",
                    { class: ["sc-grid", "columns-" + _vm.columns] },
                    _vm._l(_vm.results, function(item) {
                      return _c(
                        "div",
                        {
                          key: _vm.recordKey(item),
                          staticClass: "grid-cell column-span-1 item"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "item-wrapper" },
                            [
                              _c("ControlMapViewRow", {
                                attrs: {
                                  options: _vm.options,
                                  stateNamespace: _vm.stateNamespace,
                                  rowItem: item
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    })
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.results || !_vm.results.length,
                  expression: "!results || !results.length"
                }
              ],
              staticClass: "no-results-message"
            },
            [
              _c("div", {
                directives: [
                  {
                    name: "localization",
                    rawName: "v-localization",
                    value: { key: "common.dictionary.noMatchesFound" },
                    expression: "{ key: 'common.dictionary.noMatchesFound' }"
                  }
                ]
              }),
              _vm._v(" "),
              _c("div", {
                directives: [
                  {
                    name: "localization",
                    rawName: "v-localization",
                    value: { key: "common.dictionary.noMatchesFoundSubTitle" },
                    expression:
                      "{ key: 'common.dictionary.noMatchesFoundSubTitle' }"
                  }
                ]
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("Pagination", {
        attrs: {
          pageSize: _vm.paginationParams.pageSize,
          total: _vm.paginationParams.total,
          from: _vm.paginationParams.from,
          activePages: _vm.paginationParams.activePages
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }