/* eslint prefer-promise-reject-errors: 0 */
/* eslint no-async-promise-executor: 0 */

import {
	Search, Get, Update
} from '@acx-xms/data-functions/dist';
import { GetUserInfo } from '@/Data/Auth/authentication-service';

async function _getUserInfo() {
	return await GetUserInfo();
}

function _getExtReferenceKey(entity, foreignEntity, foreignField, isReference) {
	let result = entity + foreignEntity + 'id' + foreignEntity + '.' + foreignField;
	if (isReference) result += 'id.id';
	return result.toString();
}

export default {
	getParticipantContextWithNDA(roomRef) {
		const roomGetPromise = Get(roomRef.logicalname, roomRef.id);

		return new Promise((resolve, reject) => {
			Promise.all([_getUserInfo(), roomGetPromise])
				.then(([userInfo, roomInfoResults]) => {
					const promises = [this.getParticipantContext(roomRef)];
					const roomInfo = roomInfoResults;
					const ndaRef = sc.utils.findProperty(roomInfo, 'Source.ndaid', true);

					if (ndaRef) {
						const shareddocumentGetPromise = Search('shareddocument', [
							sc.classes.get('offsetFrom.filter', 0),
							sc.classes.get('offsetSize.filter', 1),
							sc.classes.get('ids.filter', [ndaRef.id]).fillQuery(),
							sc.classes.get('selectedFields.filter', [
								{ logicalname: 'documentid' }
							]).fillQuery()
						]);
						promises.push(shareddocumentGetPromise);
					} else {
						promises.push(Promise.resolve(null));
					}
					Promise.all(promises).then(async ([chatparticipant, shareddocument]) => {
						const result = {};
						result.Success = chatparticipant.Success;
						if (!result.Success) {
							return reject({
								error: true,
								message: 'Chat participant not found'
							});
						}

						const roomOwner = sc.utils.findProperty(roomInfo, 'Source.ownerid', true);
						const isOwner = (roomOwnerId, participantId) => {
							return roomOwnerId === participantId;
						};
						const cpUser = sc.utils.findProperty(chatparticipant.chatparticipant, 'Source.chatparticipantcontactidcontact.systemuserid', true);
						if (shareddocument) {
							const document = await Search('document', [
								sc.classes.get('offsetFrom.filter', 0),
								sc.classes.get('offsetSize.filter', 1),
								sc.classes.get('ids.filter', [shareddocument.Results[0].Source.documentid.id]).fillQuery(),
								sc.classes.get('selectedFields.filter', [
									{ logicalname: '#Document' },
									{ logicalname: 'filename' }
								]).fillQuery()
							]);
							result.ndadoc = document.Results && document.Results[0];
						} else {
							result.ndadoc = null;
						}
						result.chatparticipant = chatparticipant.chatparticipant;
						result.userInfo = userInfo;
						result.dealroomRec = roomInfo;
						result.isRoomOwner = isOwner(roomOwner.id, cpUser.id);

						resolve(result);
					})
						.catch((e) => {
							reject({
								error: true,
								message: e
							});
						});
				})
				.catch((e) => {
					reject({
						error: true,
						message: e
					});
				});
		});
	},
	async changeParticipantState(chatparticipant) {
		if (!sc.utils.findProperty(chatparticipant, 'ispending', true) && !sc.utils.findProperty(chatparticipant, 'Source.ispending', true)) {
			return Promise.resolve(null);
		}

		return Update(chatparticipant.Type, chatparticipant.Id, { ispending: false });
	},
	getParticipantContext(roomRef, additionalFilters = []) {
		const participantEntity = 'chatparticipant';
		const chatEntity = 'chat';

		return new Promise(async (resolve, reject) => {
			const userInfo = await _getUserInfo();
			const extRefKey = _getExtReferenceKey(participantEntity, chatEntity, roomRef.logicalname, true);
			const chatparticipant = await Search(['chatparticipant'],
				[
					sc.classes.get('offsetFrom.filter', 0),
					sc.classes.get('offsetSize.filter', 1),
					sc.classes.get('termFacet.filter', {
						logicalName: extRefKey,
						query: [roomRef.id]
					}).fillQuery(),
					sc.classes.get('termFacet.filter', {
						logicalName: 'chatparticipantcontactidcontact.systemuserid.id',
						query: [userInfo.Id]
					}).fillQuery(),
					sc.classes.get('selectedFields.filter', [
						{ logicalname: 'recordstate.id' },
						{ logicalname: 'requestedaccess' },
						{ logicalname: 'chatparticipantcontactidcontact.systemuserid' },
						{ logicalname: 'requestedAccess' },
						{ logicalname: 'chatparticipantchatidchat.dealroomid' },
						{ logicalname: 'ispending' },
						{ logicalname: 'ndasignindate' }
					]).fillQuery(),
					...additionalFilters
				]
			);
			const result = {};
			const cpIsActive = sc.utils.findProperty(chatparticipant.Results[0], 'Source.recordstate.id', true) !== '9e817a63-452c-e811-8320-7824af3b452f';
			result.Success = chatparticipant.Success && chatparticipant.Total > 0 && cpIsActive;
			let requestedAccess = sc.utils.findProperty(chatparticipant.Results[0], 'Source.requestedaccess', true);
			let roomOwnerName = '';
			if (!cpIsActive || !requestedAccess) {
				const rods = await Search(['roomopendata'],
					[
						sc.classes.get('offsetFrom.filter', 0),
						sc.classes.get('offsetSize.filter', 1),
						sc.classes.get('termFacet.filter', {
							logicalName: 'roomid.id',
							query: [roomRef.id]
						}).fillQuery(),
						sc.classes.get('selectedFields.filter', [
							{ logicalname: 'roomopendataroomiddealroom.recordstate.id' },
							{ logicalname: 'roomopendataroomidcollaborationroom.recordstate.id' },
							{ logicalname: 'roomopendataowneridsystemuser.fullname' }
						]).fillQuery()
					]
				);

				const rod = rods.Results.length ? rods.Results[0] : null;
				if (rod !== null) {
					const room = rod.Source.roomopendataroomiddealroom || rod.Source.roomopendataroomidcollaborationroom;
					const isRoomActive = room.recordstate
						? room.recordstate.id === '8d113fa8-3015-4060-a107-14cedcd19dd3'
						: false;

					if (!isRoomActive) {
						requestedAccess = null;
					} else if (!cpIsActive || requestedAccess === false) {
						roomOwnerName = sc.utils.findProperty(rod, 'Source.roomopendataowneridsystemuser.fullname', true);
					}
				}
			}

			if (!result.Success || requestedAccess) {
				reject({
					error: true,
					requestedAccess,
					message: requestedAccess ? 'Chatparticipant has requested access' : 'Chatparticipant not found',
					roomOwnerName
				});
			}

			result.chatparticipant = chatparticipant.Results[0];

			resolve(result);
		});
	}
};
