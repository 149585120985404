<template>
	<div class="dialog-info-wrapper">
		<div class="dialog-info">
			<div class="info-message" :class="{'with-template': options.template && options.records}">
				<div class="info">
					<p class="message">{{options.message}}</p>
				</div>
			</div>
			<div class="entity-list">
				<control class="formcontrol-template"
						 v-if="options.template && options.records"
						 :name="options.template.$type"
						 :contentProps.once="options.template"
						 :initResults="options.records"></control>
			</div>
			<div class="footer" v-if="showFooter">
				<div class="buttons">
					<button type="button" @click="close">OK</button>
				</div>
			</div>
		</div>
	</div>

</template>
<script>
import Control from '@/Components/Entity/control.vue';
export default {
	name: 'info-dialog',
	components: { Control },
	props: { options: Object },
	data() {
		return { showFooter: this.options.showFooter === void 0 ? true : this.options.showFooter };
	},
	methods: {
		close() {
			this.$parent.$emit('close');
		}
	}
};
</script>
<style src="./info-dialog.less" scoped></style>
