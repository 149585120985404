<template>
	<div class="editor">
		<div class="body create-shareddocument-editor"
			 v-perfectscroll="{ enable: true }">
			<div class="loading-overlay large" v-show="isLoading"></div>
			<control	v-if="editorTemplate" :name="editorTemplate.$type"
						:contentProps="editorTemplate"
						:evaluationContext="controlContext"></control>
			<!--remove after BA review if it is needed here-->
			<div class="entity-list">
				<control	class="formcontrol-template"
							v-if="false"
							:name="documentRelatedRecordsTemplate.$type"
							:contentProps="documentRelatedRecordsTemplate"
							:evaluationContext="controlContext"
							:initResults="results"></control>
			</div>

		</div>
		<div class="footer">
			<button v-show="state==='notStarted'"
					class="cancel"
					@click="onCancel"
					v-localization="{key: 'common.dictionary.buttons.cancel'}"
					v-data-attr="{title: options.title, $type: 'dialog.button.cancel'}">
			</button>
			<button v-show="state!=='finished'"
					:disabled="state==='started'"
					@click="saveSharedDoc"
					v-localization="{key: 'common.dictionary.buttons.upload'}"
					v-data-attr="{title: options.title, $type: 'dialog.button.submit'}">
			</button>
			<button v-show="state==='finished'"
					@click="onCancel"
					v-localization="{key: 'common.dictionary.buttons.close'}"
					v-data-attr="{title: options.title, $type: 'dialog.button.cancel'}">
			</button>
		</div>
	</div>
</template>
<script>
// TODO: rename this component to "create-shareddocument-dialog"
import Control from '@/Components/Entity/control.vue';
import editorMixin from '@/Components/editor.mixins.js';
import createDocumentMixins from './create.document.mixins';
import {
	showToastOrInfoDialog, Create, Search, CreateBulk
} from '@acx-xms/data-functions/dist';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';

export default {
	name: 'create-shareddocument-editor',
	components: { Control },
	mixins: [editorMixin, createDocumentMixins],
	props: { options: Object },
	computed: {
		store() {
			return this.$store.state[this.stateKey] && this.$store.state[this.stateKey].entityData;
		},
		selectedFiles() {
			return this.store && this.store.existingDocument && this.store.existingDocument.document;
		},
		selectedFolders() {
			return this.store && this.store.existingDocument && this.store.existingDocument.folder;
		},
		selectedSharedParentFolder() {
			return this.store && this.store.parentfolder;
		},
		parentRecord() {
			return this.store && this.store.parentRecord;
		}
	},

	watch: {
		selectedFiles(newValue, oldValue) {
			const newId = newValue && newValue.Id;
			const oldId = oldValue && oldValue.Id;
			this.onFileChange(newId, oldId);
		}
	},

	data() {
		return {
			editorTemplate: null,
			saveButtonEnabled: false,
			isLoading: false,
			documentRelatedRecordsTemplate: null,
			results: null
		};
	},
	async created() {
		this.logicalName = this.options.params.entity.logicalname;
		this.stateKey = this.registerModule(this.logicalName);
		const lookupPredefinedFields = this.options.params.lookupPredefinedFields;
		await this.$store.dispatch(this.stateKey + '/setField', {
			name: 'parentRecord',
			value: this.options.params.parentRecord
		});

		if (lookupPredefinedFields) {
			Object.keys(lookupPredefinedFields).forEach(async (key) => {
				await this.$store.dispatch(this.stateKey + '/setField', {
					name: key,
					value: lookupPredefinedFields[key]
				});
			});
		}

		this.localization = sc.classes.get('localization.dataProvider');

		const configDataProvider = sc.classes.get('entityConfiguration.dataProvider');
		this.controlContext = createEvaluationContext({ Type: this.logicalName });
		this.editorTemplate = this.options.params.template;
		this.relatedEntitiesList = ['shareddocumentlistingidlisting', 'shareddocumentcollaborationroomidcollaborationroom',
			'shareddocumentdealroomiddealroom', 'shareddocumentchatidchat', 'shareddocumentavailabilityidavailability'];
		this.documentRelatedRecordsTemplate = this.options.documentRelatedRecordsTemplate && await configDataProvider
			.getTemplate(this.options.documentRelatedRecordsTemplate.entity, this.options.documentRelatedRecordsTemplate.usage, this.options.documentRelatedRecordsTemplate.subtype);
	},
	methods: {
		async saveSharedDoc() {
			const notifyLogicalNames = ['shareddocument'];
			try {
				const createSharedDocument = (documentRef, documentTitle, documentCategory) => {
					const sharedDocBody = createSharedDocumentBody(documentRef, documentTitle, documentCategory);
					return Create('shareddocument', sharedDocBody);
				};

				const createSharedDocumentBody = (documentRef, documentTitle, documentCategory) => {
					const parentfolder = this.selectedSharedParentFolder
						? {
							id: this.selectedSharedParentFolder.id,
							logicalname: this.selectedSharedParentFolder.logicalname
						}
						: null;
					return {
						title: documentTitle,
						documentid: documentRef,
						parentfolder,
						category: documentCategory,
						[this.parentRecord.logicalname + 'id']: this.parentRecord
					};
				};

				const createSharedFolderBody = (folderRef, folderTitle) => {
					const parentfolder = this.selectedSharedParentFolder
						? {
							id: this.selectedSharedParentFolder.id,
							logicalname: this.selectedSharedParentFolder.logicalname
						}
						: null;
					return {
						title: folderTitle,
						folderid: folderRef,
						parentfolder,
						[this.parentRecord.logicalname + 'id']: this.parentRecord
					};
				};
				const records = [];

				if (this.selectedFolders && this.selectedFolders.length) {
					this.selectedFolders.forEach(folderObj => {
						const folderRef = sc.classes.get('entityReference', folderObj);
						const folderTitle = folderRef.name;
						const record = {
							id: sc.utils.guid(),
							method: 'POST',
							url: 'sharedfolder',
							body: createSharedFolderBody(folderRef, folderTitle)
						};
						records.push(record);
					});
					notifyLogicalNames.push('sharedfolder');
				}
				if (this.selectedFiles && this.selectedFiles.length) {
					this.selectedFiles.forEach(fileObj => {
						const documentRef = sc.classes.get('entityReference', fileObj);
						const documentTitle = documentRef.name;
						const documentCategory = fileObj.Source.category;
						const record = {
							id: sc.utils.guid(),
							method: 'POST',
							url: 'shareddocument',
							body: createSharedDocumentBody(documentRef, documentTitle, documentCategory)
						};
						records.push(record);
					});
				}

				if (records.length) {
					this.isLoading = true;
					await CreateBulk(records, { async: true });
					this.onCancel();
					this.isLoading = false;
				} else {
					await this.onSubmit({ afterCreateDocumentAspect: createSharedDocument });
				}
			} catch (error) {
				sc.events.emit('dialog.error', {
					title: this.localization.localize('entity.editor.validationError'),
					message: error.responseText
				});
			} finally {
				notifyLogicalNames.forEach(logicalName => {
					this.notifyEntityChanged('entity.changed', { logicalName });
				});
				await showToastOrInfoDialog({
					toastMessageKey: this.options.toastMessageKey,
					informationDialogTextKey: this.options.informationDialogTextKey,
					options: { context: this }
				});
			}
		},
		async onFileChange(newValue, oldValue) {
			if (newValue && newValue !== oldValue) {
				const logicalFilter = {
					$type: 'search.logicalFilter',
					operator: {
						$type: 'search.or',
						operator: [
							{
								$type: 'search.operand',
								filter: [
									{
										$type: 'search.termFacetFilter',
										negative: true,
										filterViewKind: 'Default',
										item: [
											{
												$type: 'search.termFacetItem',
												value: {
													$type: 'expression.text',
													value: null
												}
											}],
										logicalName: 'shareddocumentlistingidlisting'
									}]
							},
							{
								$type: 'search.operand',
								filter: [
									{
										$type: 'search.termFacetFilter',
										negative: true,
										filterViewKind: 'Default',
										item: [
											{
												$type: 'search.termFacetItem',
												value: {
													$type: 'expression.text',
													value: null
												}
											}],
										logicalName: 'shareddocumentcollaborationroomidcollaborationroom'
									}]
							},
							{
								$type: 'search.operand',
								filter: [
									{
										$type: 'search.termFacetFilter',
										negative: true,
										filterViewKind: 'Default',
										item: [
											{
												$type: 'search.termFacetItem',
												value: {
													$type: 'expression.text',
													value: null
												}
											}],
										logicalName: 'shareddocumentdealroomiddealroom'
									}]
							},
							{
								$type: 'search.operand',
								filter: [
									{
										$type: 'search.termFacetFilter',
										negative: true,
										filterViewKind: 'Default',
										item: [
											{
												$type: 'search.termFacetItem',
												value: {
													$type: 'expression.text',
													value: null
												}
											}],
										logicalName: 'shareddocumentchatidchat'
									}]
							},
							{
								$type: 'search.operand',
								filter: [
									{
										$type: 'search.termFacetFilter',
										negative: true,
										filterViewKind: 'Default',
										item: [
											{
												$type: 'search.termFacetItem',
												value: {
													$type: 'expression.text',
													value: null
												}
											}],
										logicalName: 'shareddocumentavailabilityidavailability'
									}]
							}
						]
					}
				};
				const relatedEntitiesList = ['shareddocumentlistingidlisting', 'shareddocumentcollaborationroomidcollaborationroom',
					'shareddocumentdealroomiddealroom', 'shareddocumentchatidchat', 'shareddocumentavailabilityidavailability'];
				this.isLoading = true;
				const filters = [
					sc.classes.get('offsetFrom.filter', 0),
					sc.classes.get('offsetSize.filter', 9999),
					sc.classes.get('termFacet.filter', {
						logicalName: 'documentid.id',
						query: [newValue]
					}).fillQuery(),
					sc.classes.get('search.logicalFilter', logicalFilter).toFilter()
				];

				const results = await Search([this.options.documentRelatedRecordsTemplate.entity], filters);
				this.results = results.Results.filter(res => { return !relatedEntitiesList.some(entity => res.Source[entity] && res.Source[entity].recordstate.id === '9e817a63-452c-e811-8320-7824af3b452f'); });
				this.isLoading = false;
			} else if (!newValue && oldValue) {
				this.results = null;
			}
		}
	}
};
</script>
<style src="../editor.less" scoped></style>
<style scoped>
	button:disabled {
		opacity: 0.5;
	}
</style>
