var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "manage-roles editor" }, [
    _c("h5", {
      directives: [
        {
          name: "localization",
          rawName: "v-localization",
          value: { key: "dialogues.manageAccessToRoom.selectRole" },
          expression: "{ key: 'dialogues.manageAccessToRoom.selectRole'}"
        }
      ],
      staticClass: "subheader"
    }),
    _vm._v(" "),
    _c("div", { staticClass: "dialog-container" }, [
      _c(
        "div",
        { staticClass: "input-radio-wrapper" },
        [
          _c("p", { staticClass: "h6 title" }, [_vm._v("Chat")]),
          _vm._v(" "),
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedChatRole,
                  expression: "selectedChatRole"
                }
              ],
              attrs: { type: "radio" },
              domProps: {
                value: _vm.defaultRole.roleName,
                checked: _vm._q(_vm.selectedChatRole, _vm.defaultRole.roleName)
              },
              on: {
                change: function($event) {
                  _vm.selectedChatRole = _vm.defaultRole.roleName
                }
              }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "p1" }, [
              _vm._v(_vm._s(_vm.defaultRole.name))
            ])
          ]),
          _vm._v(" "),
          _vm._l(_vm.chatRoles, function(role) {
            return _c("label", { key: role.name }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedChatRole,
                    expression: "selectedChatRole"
                  }
                ],
                attrs: { type: "radio" },
                domProps: {
                  value: role.roleName,
                  checked: _vm._q(_vm.selectedChatRole, role.roleName)
                },
                on: {
                  change: function($event) {
                    _vm.selectedChatRole = role.roleName
                  }
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "p1" }, [_vm._v(_vm._s(role.name))])
            ])
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "input-radio-wrapper" },
        [
          _c("p", { staticClass: "h6 title" }, [_vm._v("Documents")]),
          _vm._v(" "),
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedDocumentRole,
                  expression: "selectedDocumentRole"
                }
              ],
              attrs: { type: "radio" },
              domProps: {
                value: _vm.defaultRole.roleName,
                checked: _vm._q(
                  _vm.selectedDocumentRole,
                  _vm.defaultRole.roleName
                )
              },
              on: {
                change: function($event) {
                  _vm.selectedDocumentRole = _vm.defaultRole.roleName
                }
              }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "p1" }, [
              _vm._v(_vm._s(_vm.defaultRole.name))
            ])
          ]),
          _vm._v(" "),
          _vm._l(_vm.documentRoles, function(role) {
            return _c("label", { key: role.name }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedDocumentRole,
                    expression: "selectedDocumentRole"
                  }
                ],
                attrs: { type: "radio" },
                domProps: {
                  value: role.roleName,
                  checked: _vm._q(_vm.selectedDocumentRole, role.roleName)
                },
                on: {
                  change: function($event) {
                    _vm.selectedDocumentRole = role.roleName
                  }
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "p1" }, [_vm._v(_vm._s(role.name))])
            ])
          })
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c(
        "button",
        {
          directives: [
            {
              name: "data-attr",
              rawName: "v-data-attr",
              value: {
                title: _vm.options.title,
                $type: "dialog.button.cancel"
              },
              expression:
                "{title: options.title, $type: 'dialog.button.cancel'}"
            }
          ],
          staticClass: "cancel",
          on: { click: _vm.onCancel }
        },
        [_vm._v("Cancel")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          directives: [
            {
              name: "data-attr",
              rawName: "v-data-attr",
              value: {
                title: _vm.options.title,
                $type: "dialog.button.submit"
              },
              expression:
                "{title: options.title, $type: 'dialog.button.submit'}"
            }
          ],
          on: { click: _vm.onSubmit }
        },
        [_vm._v("Save")]
      )
    ]),
    _vm._v(" "),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ],
      staticClass: "loading-overlay"
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }