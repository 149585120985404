var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dialog-content-wrapper copy-link-dialog editor" },
    [
      _c("div", { staticClass: "dialog-container" }, [
        _c(
          "div",
          { staticClass: "field-wrapper field-wrapper-copy-share-link" },
          [
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isLoading || _vm.isDisabled,
                  expression: "isLoading || isDisabled"
                }
              ],
              staticClass: "loading-overlay"
            }),
            _vm._v(" "),
            _c("div", { staticClass: "info-text p1" }, [
              _vm._v(_vm._s(_vm.infoText))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "p4" }, [_vm._v("Link")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "field", class: _vm.isDisabled && "disabled" },
              [
                _c("input", {
                  ref: "text",
                  staticClass: "field-input",
                  attrs: { type: "text" },
                  domProps: { value: _vm.url }
                }),
                _vm._v(" "),
                _c(
                  "button",
                  { attrs: { type: "submit" }, on: { click: _vm.copy } },
                  [_vm._v(_vm._s(_vm.copyBtnText))]
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "p4 link-lifetime-title" }, [
              _vm._v("Link lifetime")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "link-lifetime" }, [
              _c("input", {
                staticClass: "link-lifetime-input",
                attrs: {
                  min: "1",
                  max: "24",
                  disabled: _vm.isCopied,
                  placeholder: "Default 6",
                  type: "number"
                },
                domProps: { value: _vm.linkLifeTime },
                on: {
                  keypress: _vm.lifeTimeHandlerRegex,
                  change: _vm.lifeTimeHandler
                }
              }),
              _vm._v("months\n\t\t\t")
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "footer" }, [
        _c("span", { staticClass: "buttons" }, [
          _c("button", {
            directives: [
              {
                name: "localization",
                rawName: "v-localization",
                value: { key: "common.dictionary.buttons.close" },
                expression: "{key: 'common.dictionary.buttons.close'}"
              }
            ],
            staticClass: "cancel",
            attrs: { type: "button" },
            on: { click: _vm.cancel }
          })
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }