var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "map-search-results-tooltip",
      class: _vm.showPagination && !_vm.selectedItem && "with-pagging"
    },
    [
      _c("div", { staticClass: "left-top-triangle" }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "perfectscroll",
              rawName: "v-perfectscroll",
              value: {
                enable: !_vm.selectedItem,
                addMouseoverEvent: _vm.psAddMouseOverEvent,
                refreshScroll: true,
                refreshEvent: _vm.refreshEvent
              },
              expression:
                "{ enable: !selectedItem, addMouseoverEvent: psAddMouseOverEvent, refreshScroll: true, refreshEvent: refreshEvent }"
            }
          ],
          staticClass: "results-wrapper",
          style: { height: _vm.itemHeight }
        },
        [
          _vm.selectedItem
            ? _c(
                "div",
                { class: _vm.additionalClass + " preview-item" },
                [
                  _c("entitytemplate-template", {
                    key: _vm.selectedItem.Id,
                    attrs: {
                      options: _vm.previewTemplate,
                      evaluationContext: _vm.getContext(_vm.selectedItem)
                    }
                  })
                ],
                1
              )
            : !_vm.selectedItem
            ? _c(
                "div",
                { staticClass: "records-list" },
                _vm._l(_vm.visibleItems, function(item) {
                  return _c("entitytemplate-template", {
                    key: item.Id,
                    attrs: {
                      options: _vm.listTemplate,
                      evaluationContext: _vm.getContext(item)
                    },
                    nativeOn: {
                      click: function($event) {
                        _vm.selectItem(item)
                      }
                    }
                  })
                })
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(_vm.pagination, {
        tag: "pagination",
        attrs: {
          pageSize: _vm.paginationParams.pageSize,
          total: _vm.paginationParams.total,
          from: _vm.paginationParams.from,
          activePages: _vm.paginationParams.activePages
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }