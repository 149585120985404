<template>
	<div class="delete-dialog-wrapper">
		<div class="delete-dialog-message">
			<span v-if="options.params.customConfirmRemovingMessage" class="p4">{{options.params.customConfirmRemovingMessage}}</span>
			<span v-else class="p4" v-localization="{ key: 'common.dictionary.confirmRemoving'}"></span>
		</div>
		<div class="footer">
			<button class="cancel" @click="close" v-localization="{ key: 'common.dictionary.buttons.cancel'}" v-data-attr="{title: options.title, $type: 'dialog.button.submit'}"></button>
			<button type="button" @click="onSubmit" v-data-attr="{title: options.title, $type: 'dialog.button.submit'}">
				<span v-if="options.params.customConfirmText">
					{{ options.params.customConfirmText }}
				</span>
				<span v-else v-localization="{ key: 'common.dictionary.buttons.delete'}" />
			</button>
		</div>
		<div class="loading-overlay" v-show="isLoading"></div>
	</div>
</template>
<script>
import { showToastOrInfoDialog, Delete } from '@acx-xms/data-functions/dist';

export default {
	name: 'delete-dialog',
	props: { options: Object },
	data() {
		return { isLoading: false };
	},
	methods: {
		close() {
			this.$parent.$emit('close');
		},
		async onSubmit() {
			const entityRefs = this.options.params.entities.map(item => sc.classes.get('entityReference', item));
			if (this.options.params.isFolder) {
				const deleteNextFolder = async () => {
					if (entityRefs.length) {
						const entity = entityRefs.shift();
						await Delete(entity.logicalname, entity.id);
						const arg = sc.classes.get('entity.eventArgs', entity);
						sc.events.emit('entity.' + 'deleted', arg);
						await deleteNextFolder();
					} else {
						await showToastOrInfoDialog({ toastMessageKey: 'common.dictionary.folder.delete.end' });
						this.options.params.refreshEvent && sc.events.emit(this.options.params.refreshEvent);
						this.options.params.actionMenuRefreshEvent && sc.events.emit(this.options.params.actionMenuRefreshEvent, {});
					}
				};
				await showToastOrInfoDialog({ toastMessageKey: 'common.dictionary.folder.delete.start' });
				this.close();
				await deleteNextFolder();
			} else if (this.options.params.isGuestSharedDoc) {
				const sharedDoc = this.options.params.entities[0]; // docs multiselection isn't available for guest
				const docRef = sharedDoc.Source.documentid;
				const argRef = sc.classes.get('entityReference', sharedDoc);
				const arg = sc.classes.get('entity.eventArgs', argRef);

				this.isLoading = true;
				await Promise.all([await Delete(sharedDoc.Type, sharedDoc.Id), await Delete(docRef.logicalname, docRef.id)]);
				sc.events.emit('entity.' + 'deleted', arg);
				this.isLoading = false;
				this.close();
			} else {
				this.isLoading = true;
				const deleteNext = async () => {
					if (entityRefs.length) {
						const entity = entityRefs.shift();
						try {
							await Delete(entity.logicalname, entity.id);
							// TODO: rework after get rid of edge.dataProvider
							const arg = sc.classes.get('entity.eventArgs', entity);
							sc.events.emit('entity.' + 'deleted', arg);
						} catch (error) {
							if (error.message.indexOf('referenced elsewhere') > -1) {
								sc.events.emit('vue.dialog.open', {
									icon: '',
									title: 'Restriction',
									component: 'delete.restriction.dialog',
									maximizedWidth: '500px',
									maximizedHeight: '550px',
									params: { entityRef: entity }
								});
							} else {
								sc.events.emit('dialog.error',
									{
										title: await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('errors.errorsHandler.title'),
										message: error.message
									});
							}
						} finally {
							await deleteNext();
						}
					} else {
						this.isLoading = false;
						this.close();
						this.options.params.refreshEvent && sc.events.emit(this.options.params.refreshEvent);
						this.options.params.actionMenuRefreshEvent && sc.events.emit(this.options.params.actionMenuRefreshEvent, {});
						await showToastOrInfoDialog({
							toastMessageKey: this.options.params.toastMessageKey,
							informationDialogTextKey: this.options.params.informationDialogTextKey,
							options: { context: this }
						});
					}
				};
				await deleteNext();
			}
		}
	}
};
</script>
<style src="./delete-dialog.less" scoped></style>
