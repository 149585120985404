import { BaseAspect } from './base.aspect';
import { createEvaluationContext } from '../../../../Data/EvaluationContext/evaluationContext';
import {
	generateGuid, CreateBulk, Create, Update, Search
} from '@acx-xms/data-functions/dist';

export class InviteParticipantsAspect extends BaseAspect {
	constructor(options) {
		super();
		this.init(options);
	}

	init(options) {
		this.action = options.action || null;
		this.chatActiveState = super.eval(options.chatActiveState) || null;
		this.fields = options.fields || [];
		this.stage = options.stage || 'afterSave';
		this.detach = (options.detach !== undefined || options.detach !== null) ? options.detach : true;

		this.progress = {
			total: 0,
			processed: 0,
			message: 'Related records are being added to your room',
			title: 'Collaboration Room Creation'
		};

		super.init(options, options.evaluationContext);
	}

	localize(label, options) {
		return sc.classes.get('localization.dataProvider').getObservableLabel(label, options);
	}

	async execute(options) {
		const listings = this.toArray(options.params.listings);
		const availabilities = this.toArray(options.params.availabilities);
		const entity = sc.classes.get('entityReference', options.saveResult);
		const chatFilters = [
			sc.classes.get('termFacet.filter', {
				logicalName: entity.logicalname + 'id.id',
				items: [entity.id],
				negative: false
			}),
			sc.classes.get('offsetSize.filter', 1),
			sc.classes.get('selectedFields.filter', [])
		];

		const data = await Search(['chat'], chatFilters, { resultFormat: 'detail' });
		if (data.Total > 0) return;

		this.progress.total = 0;

		sc.events.emit('vue.progressDialog.open', this.progress);

		const listingRecords = [];
		const availabilitiesRecords = [];

		if (listings.length) {
			listings.forEach(listing => {
				const listingRef = sc.classes.get('entityReference', listing);
				const items = this.createListing({
					crlistingid: generateGuid(),
					name: listingRef.name,
					collaborationroomid: entity,
					listingid: listingRef,
					archived: false
				}, availabilities);
				listingRecords.push(...items.listings);
				availabilitiesRecords.push(...items.availabilitiesRecords);
			});
		}
		const promises = listingRecords.length
			? [CreateBulk(listingRecords, { async: true })]
			: [];
		if (promises.length) promises[0].then(i => { this.progress.processed++; });
		if (listingRecords.length) this.progress.total++;
		if (availabilitiesRecords.length) this.progress.total++;

		this.progress.total++;
		promises.push(this.createChat(options.saveResult, 'chat', this.chatActiveState));

		await Promise.all(promises).catch(err => this.errorHandler(err));

		availabilitiesRecords.length && await CreateBulk(availabilitiesRecords, { async: true }).then(i => { this.progress.processed++; }).catch(err => this.errorHandler(err));

		const context = createEvaluationContext({
			Id: entity.id,
			Name: entity.name,
			Type: entity.logicalname,
			Source: options.saveResult
		});

		sc.events.emit('vue.dialog.open', {
			title: this.localize('inviteParticipantsPlugin.title'),
			component: 'invite.participants.dialog',
			evaluationContext: context,
			maximizedWidth: '90%',
			maximizedHeight: '100%',
			refreshNamespace: context.eval(this.action.refreshNamespace),
			multiGroup: !!context.eval(this.action.multiGroup),
			lookupFilters: this.action.lookupFilters,
			showSkip: context.eval(this.action.showSkip),
			userEntity: context.eval(this.action.userEntity),
			userListTemplate: this.action.userListTemplate,
			userLookupTemplate: this.action.userLookupTemplate,
			parentRecord: entity,
			defaultChat: null,
			selectedGroup: null,
			invitedcontactid: options.predefinedAspectsFields && options.predefinedAspectsFields.invitedcontactid
		});
	}

	createBulkBody(body, url) {
		return {
			id: generateGuid(),
			url,
			method: 'POST',
			body
		};
	}

	createListing(listingData, availabilities) {
		const listings = [];
		const availabilitiesRecords = [];
		listings.push(this.createBulkBody(listingData, 'crlisting'));

		if (availabilities.length) {
			const listingAvailabilities = availabilities.filter(item => {
				const availability = item.Source || item;
				return availability.listingid.id === listingData.listingid.id;
			});
			if (listingAvailabilities && listingAvailabilities.length) {
				for (let i = 0; i < listingAvailabilities.length; i++) {
					const availability = listingAvailabilities[i];
					const availabilityRef = sc.classes.get('entityReference', availability);

					availabilitiesRecords.push(this.createBulkBody({
						name: availabilityRef.name,
						collaborationroomid: listingData.collaborationroomid,
						crlistingid: {
							id: listingData.crlistingid,
							logicalname: 'crlisting'
						},
						availabilityid: availabilityRef,
						archived: false,
						cravailabilityid: generateGuid()
					}, 'cravailability'));
				}
			}
		}

		return {
			listings,
			availabilitiesRecords
		};
	}

	async createChat(parentEntity, chatLogicalName) {
		const parentEntityRef = sc.classes.get('entityReference', parentEntity);
		const chatData = {
			collaborationroomid: parentEntityRef,
			public: true,
			name: parentEntityRef.name,
			teamid: parentEntity.teamid,
			coownerteamid: parentEntity.coownerteamid,
			brokerteamid: parentEntity.brokerteamid
		};
		return await this.createRecord(chatLogicalName, chatData);
	}

	async createRecord(entity, data) {
		return await Create(entity, data).finally(() => { this.progress.processed += 1; });
	}

	async updateRecord(logicalname, id, data) {
		return await Update(logicalname, id, data).finally(() => { this.progress.processed += 1; });
	}

	toArray(data) {
		if (!data) return [];
		if (!_.isArray(data)) data = [data];
		return data;
	}
}
