<template>
	<div :class="{'lookup-folder-tree-view': true, 'invalid': invalid}">
		<div class="field-wrapper">
			<div class="field-title">
				<div class="required-mark" v-if="showRequiredMark">
					<svg class="svg-icon svg-8 layout-icons-required">
						<use xlink:href="#layout-icons-required"></use>
					</svg>
				</div>
				<p>Find in your documents</p>
			</div>
		</div>
		<component v-if="visible"
				   :is="'component-search-results'"
				   :options="options"></component>
	</div>
</template>
<script>
import ComponentSearchResults from '@/Components/ComponentSet/SearchResults/component-search-results';
export default {
	name: 'control-folder-tree-view-editor',
	props: {
		visible: Boolean,
		options: Object,
		invalid: Boolean,
		evaluationContext: Object,
		showRequiredMark: Boolean
	},
	data() {
		return { stateNamespace: this.evaluationContext.eval(this.options.stateNamespace) };
	},
	computed: {
		state() {
			return this.$store.state[this.stateNamespace];
		},
		selection() {
			const selection = this.state && this.state.selection;
			const excludedKeys = selection
				? Object.keys(selection).filter(selectionName => {
					return selection[selectionName].length === 0;
				})
				: [];
			if (excludedKeys.length) {
				excludedKeys.forEach(key => {
					delete selection[key];
				});
			}
			return selection;
		}
	},
	watch: {
		selection: function (newSelection) {
			this.$emit('existingDocument', newSelection);
		}
	},
	components: { ComponentSearchResults }
};
</script>
<style src="./control-folder-tree-view-editor.less" scoped></style>
