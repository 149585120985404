var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "control-dateeditor" },
    [
      _c("DatePicker", {
        directives: [
          {
            name: "data-attr",
            rawName: "v-data-attr",
            value: _vm.dataAttr || {},
            expression: "dataAttr || {}"
          }
        ],
        attrs: {
          enable: _vm.enable,
          "show-required-mark": _vm.showRequiredMark,
          valid: _vm.isValid,
          "has-today-action": _vm.options.showTodayAction
        },
        model: {
          value: _vm.date,
          callback: function($$v) {
            _vm.date = $$v
          },
          expression: "date"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }