<template>
	<div class="add-preference-dialog">
		<div class="loading-overlay large" v-show="isLoading"></div>
		<div class="body" v-if="isReady">
			<div class="custom-lookup">
				<div class="p13" v-localization="{key: 'addPreferenceDialog.contact.lookup'}"></div>
				<div class="add-preference">
					<control-lookup-editor v-model="selectedContact"
										   :showRequired="false"
										   :options="getContactLookupOptions()"></control-lookup-editor>
				</div>
			</div>

			<div class="custom-lookup">
				<div class="p13" v-localization="{key: 'addPreferenceDialog.preference.lookup'}"></div>
				<div class="add-preference">
					<control-lookup-editor v-model="selectedPreference"
										   :showRequired="true"
										   :class="{invalid: !isValid}"
										   :options="getPreferenceLookupOptions()"></control-lookup-editor>
				</div>
			</div>
		</div>
		<div class='footer'>
			<span class='buttons'>
				<button @click="save" type="button" v-localization="{key: 'common.dictionary.buttons.save'}"></button>
				<button @click="close" type="button" class="cancel" v-localization="{key: 'common.dictionary.buttons.cancel'}"></button>
			</span>
		</div>
	</div>
</template>
<script>
import addPreferenceMixin from './add-preference.mixin';
import {
	showToastOrInfoDialog, Update, Search
} from '@acx-xms/data-functions/dist';
export default {
	name: 'add-preference-dialog',
	props: { options: Object },
	mixins: [addPreferenceMixin],
	data() {
		return {
			isLoading: true,
			isReady: false,
			isValid: true
		};
	},
	created() {
		const preferenceData = this.options.preferenceData;

		if (preferenceData && preferenceData.Source) {
			if (preferenceData.Source.preferencecontactidcontact) {
				this.selectedContact = {
					data: { Source: preferenceData.Source.preferencecontactidcontact },
					displayName: preferenceData.Source.preferencecontactidcontact.fullname
				};
			}

			this.selectedPreference = {
				data: preferenceData,
				displayName: preferenceData.Source.name
			};

			this.collaborationroomRef = preferenceData.Source.collaborationroomid;
			this.initPreferenceRef = sc.classes.get('entityReference', preferenceData);
		} else {
			this.collaborationroomRef = {
				id: this.$route.params.id,
				logicalname: 'collaborationroom'
			};
		}

		this.isLoading = false;
		this.isReady = true;
	},
	methods: {
		async validate() {
			const errors = [];
			const selectedPref = this.selectedPreference && this.selectedPreference.data;
			if (!selectedPref) {
				errors.push({
					field: 'Preference',
					error: await this.localization.getLabelForCurrentLanguage('common.dictionary.validators.requiredField')
				});
			} else {
				if (selectedPref.Source.collaborationroomid) {
					const { Results: crResults } = await Search(['roomopendata'],
						[
							sc.classes.get('offsetSize.filter', 1),
							sc.classes.get('termFacet.filter', {
								logicalName: 'roomid.id',
								query: [selectedPref.Source.collaborationroomid.id]
							}).fillQuery(),
							sc.classes.get('selectedFields.filter', [
								{ logicalname: 'roomname' },
								{ logicalname: 'roomid.id' },
								{ logicalname: 'roomopendataroomowneridsystemuser.fullname' }
							]).fillQuery()
						]
					);
					const cr = crResults[0].Source;
					const crName = cr.roomname;
					const crOwner = cr.roomopendataroomowneridsystemuser.fullname;
					const crid = cr.roomid.id;

					errors.push({
						error: await this.localization.getLabelForCurrentLanguage('addPreferenceDialog.preference.alreadyused.error',
							[crid, crName, crOwner])
					});
				}
			}

			return errors;
		},

		async save() {
			const errors = await this.validate();

			if (errors.length) {
				this.isValid = false;
				let message = '';
				errors.forEach(e => {
					if (e.field) { message += `${e.field}: ${e.error} <br/>`; } else { message += `${e.error} <br/>`; }
				});

				sc.events.emit('dialog.error', {
					title: await this.localization.getLabelForCurrentLanguage('entity.editor.validationError'),
					message
				});
			} else {
				this.isLoading = true;
				const promises = [];

				// TODO: remove after schema 5.0.6 is applied, logic was moved to trigger
				this.initPreferenceRef && promises.push(Update('preference', this.initPreferenceRef.id, { collaborationroomid: null }));

				promises.push(Update('preference', this.selectedPreference.data.Id, { collaborationroomid: this.collaborationroomRef }));

				try {
					await Promise.all(promises);
				} catch (e) {
					sc.utils.errorMessage.byResponse(e);
				} finally {
					this.$root.$emit('refreshCurrentDetails');
					await showToastOrInfoDialog({
						toastMessageKey: this.options.toastMessageKey,
						informationDialogTextKey: this.options.informationDialogTextKey,
						options: { context: this }
					});
					this.close();
				}
			}
		},
		close() {
			this.$parent.$emit('close');
		}
	}
};
</script>
<style src="./add-preference-dialog.less" scoped></style>
