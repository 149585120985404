<template>
	<div class="image-gallery-dialog" v-if="images.length">
		<div :class="{'dialog-gallery': true, 'is-primary': currentImage.isPrimary}">
			<div class="dialog-gallery-image"
				 v-for="image in images" v-show="currentImage.id === image.id" :key="image.$key">
				<img :src="image.url" v-show="image.loaded && !image.error" @load="image.loaded = true" @error="image.error = true; image.loaded = true" />
				<img src="/Images/broken-file-icon-small.png" v-show="image.error" class="broken-image" />
				<i class="loading-overlay" v-show="!image.loaded"></i>
			</div>
			<template v-if="images.length > 1">
				<div class="prev" @click="prevImage">
					<div class="image-gallery-arrow">
						<svg class="svg-icon">
							<use xlink:href="#layout-icons-ms-arrow-left"></use>
						</svg>
					</div>
				</div>
				<div class="next" @click="nextImage">
					<div class="image-gallery-arrow">
						<svg class="svg-icon">
							<use xlink:href="#layout-icons-ms-arrow-right"></use>
						</svg>
					</div>
				</div>
			</template>
			<div class="image-menu" v-if="currentImage && menu && menu.item">
				<div class="item" v-for="(menuItem, index) in menu.item" :key="'menu-item' + index">
					<ControlSvgIconAction v-if="currentImage"
										  :evaluationContext="actionContext"
										  v-bind:options="menuItem" />
				</div>
			</div>
		</div>
		<div class="footer">
			<div @click="selectImage(image)" :id="'footer-image-' + image.id" class="footer-image"
				 v-for="image in images" :class="{ active: currentImage.id === image.id }" :key="image.$key">
				<div class="image-wrap" v-show="image.loaded" :class="{ 'broken-image': image.loaded && image.error}">
					<img :src="image.url" @load="image.loaded = true" v-if="!image.loaded" @error="image.error = true; image.loaded = true" />
					<img src="/Images/broken-file-icon-small.png" v-else-if="image.loaded && image.error" />
					<div v-else class="bg-image" :style="{'background-image': `url(${image.url})`}"></div>
					<div v-if="image.isPrimary" class="icon-primary">
						<svg class="svg-icon">
							<use xlink:href="#action-icons-image-gallery-primary"></use>
						</svg>
					</div>
				</div>
				<i class="loading-overlay" v-show="!image.loaded"></i>
			</div>
			<template v-if="images.length > 1">
				<div class="prev image-gallery-arrow" @click="prevImage">
					<svg class="svg-icon">
						<use xlink:href="#layout-icons-ms-arrow-left"></use>
					</svg>
				</div>
				<div class="next image-gallery-arrow" @click="nextImage">
					<svg class="svg-icon">
						<use xlink:href="#layout-icons-ms-arrow-right"></use>
					</svg>
				</div>
			</template>
		</div>
	</div>
	<div v-else class="info-message">
		<span class="info-message-title" v-localization="{key: 'imageGallery.noDataMessage'}"></span>
	</div>
</template>
<script>
import ActionResolver from '@/Actions/actionResolver';
import helperMethods from '@/Components/ComponentSet/component-set-evaluation-context-helper.methods';
import * as tracking from '@/Data/trackingModule';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';
const ControlSvgIconAction = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Images/SvgIconAction/control-svg-icon-action');

export default {
	name: 'image-gallery-dialog',
	props: {
		options: Object,
		dialogId: String
	},
	components: { ControlSvgIconAction },
	data() {
		return {
			images: [],
			menu: null,
			currentImage: null,
			currentIndex: 0
		};
	},
	methods: {
		nextImage() {
			const currentIndex = this.images.findIndex((image) => {
				return image.id === this.currentImage.id;
			});
			const nextIndex = currentIndex === this.images.length - 1 ? 0 : currentIndex + 1;
			this.selectImage(this.images[nextIndex]);

			tracking.AddUserActivity('imagegallery.navigate.next', 'Image gallery navigation - next image', this.options.parentEntity);
		},
		prevImage() {
			const currentIndex = this.images.findIndex((image) => {
				return image.id === this.currentImage.id;
			});
			const prevIndex = currentIndex === 0 ? this.images.length - 1 : currentIndex - 1;
			this.selectImage(this.images[prevIndex]);

			tracking.AddUserActivity('imagegallery.navigate.prev', 'Image gallery navigation - prev image', this.options.parentEntity);
		},
		selectImage(image) {
			this.currentImage = image;
			if (!this.currentImage) {
				this.currentIndex = null;
				return;
			}
			const imageElement = this.$el.querySelector('#footer-image-' + image.id);
			const footerElement = this.$el.querySelector('.footer');
			if (!imageElement) {
				return;
			}
			if (imageElement.offsetLeft < footerElement.scrollLeft) {
				footerElement.scrollTo({ left: imageElement.offsetLeft });
			} else if (imageElement.offsetLeft + imageElement.offsetWidth > footerElement.scrollLeft + footerElement.offsetWidth) {
				footerElement.scrollTo({ left: imageElement.offsetLeft + imageElement.offsetWidth - footerElement.offsetWidth });
			}
			this.currentIndex = this.images.findIndex(img => img.id === this.currentImage.id);
			this.updateTitle();
		},

		executeMenuAction(item) {
			const action = this.actionResolver.resolve(item.action.$type);
			if (!action) return;
			const localContext = createEvaluationContext(this.currentImage.data);
			let preenabled = true;
			if (item.action.enable) {
				preenabled = localContext.eval(item.action.enable);
			}
			if (preenabled) {
				const action = this.actionResolver.resolve(item.action.$type);
				if (action) {
					action.execute(item.action, localContext, [this.currentImage.data]);
				}
			}
		},

		async updateTitle() {
			if (this.dialogId) {
				const title = await sc.classes.get('localization.dataProvider')
					.getLabelForCurrentLanguage('imageGallery.dialog.header.images', [
						this.options.parentEntity.name,
						this.currentIndex + 1, this.images.length,
						this.currentImage.title
					]);
				sc.events.emit('dialog.state.changed', {
					id: this.dialogId,
					title
				});
			}
		},

		updateImages(images) {
			const token = localStorage.getItem(sc.classes.get('authentication').getTokenKey(sc.cluster.key));
			this.images = [];
			images.forEach((item) => {
				const id = item.imageid || item.Id;
				let url = sc.classes.get('edge.dataProvider').getImageUrl('image', id, 'fine');
				url += '?token=' + token;
				this.images.push({
					url,
					title: (item.Source && item.Source.title) || item.title,
					data: item,
					id,
					loaded: false,
					error: false,
					isPrimary: (item.Source && item.Source.primary) || item.primary,
					$key: helperMethods.getRandomArbitary(1, 10000000000000000).toFixed()
				});
			});
			this.currentImage = this.images[0];
		}
	},
	created() {
		this.menu = this.options.menu;
		this.updateImages(this.options.images);
		this.actionResolver = ActionResolver();
		this.$root.$on('imageGalleryUpdate', ({ items, menu }) => {
			this.menu = menu;
			this.updateImages(items);
			this.$nextTick(() => {
				this.selectImage(this.images[0]);
			});
		});
		this.updateTitle();
	},
	computed: {
		actionContext() {
			return createEvaluationContext(this.currentImage.data);
		}
	}
};
</script>
<style src="./image-gallery-dialog.less" scoped></style>
