var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mapping-edit-dialog" }, [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ],
      staticClass: "loading-overlay large"
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("mapping-search", {
          attrs: { pairs: _vm.pairs },
          on: { onChange: _vm.setFilteredPairs }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "list-content" }, [
          _c("div", { staticClass: "control-tableView inner-search-results" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.filteredPairs.length,
                    expression: "filteredPairs.length"
                  }
                ],
                staticClass: "tableview-header"
              },
              [
                _c(
                  "div",
                  { staticClass: "cell", staticStyle: { width: "35%" } },
                  [
                    _c("span", {
                      directives: [
                        {
                          name: "localization",
                          rawName: "v-localization",
                          value: { key: "dialogues.mapping.crmField" },
                          expression: "{ key: 'dialogues.mapping.crmField'}"
                        }
                      ]
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "cell", staticStyle: { width: "10%" } },
                  [
                    _c("span", {
                      directives: [
                        {
                          name: "localization",
                          rawName: "v-localization",
                          value: { key: "dialogues.mapping.type" },
                          expression: "{ key: 'dialogues.mapping.type'}"
                        }
                      ]
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "cell", staticStyle: { width: "10%" } },
                  [
                    _c("span", {
                      directives: [
                        {
                          name: "localization",
                          rawName: "v-localization",
                          value: { key: "dialogues.mapping.mapped" },
                          expression: "{ key: 'dialogues.mapping.mapped'}"
                        }
                      ]
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "cell", staticStyle: { width: "35%" } },
                  [
                    _c("span", {
                      directives: [
                        {
                          name: "localization",
                          rawName: "v-localization",
                          value: { key: "dialogues.mapping.msField" },
                          expression: "{ key: 'dialogues.mapping.msField'}"
                        }
                      ]
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "cell", staticStyle: { width: "10%" } },
                  [
                    _c("span", {
                      directives: [
                        {
                          name: "localization",
                          rawName: "v-localization",
                          value: { key: "dialogues.mapping.type" },
                          expression: "{ key: 'dialogues.mapping.type'}"
                        }
                      ]
                    })
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "tableview-content" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "perfectscroll",
                      rawName: "v-perfectscroll",
                      value: { enable: true, onScroll: _vm.onScroll },
                      expression: "{ enable: true, onScroll: onScroll}"
                    }
                  ],
                  staticClass: "content-scroll-wrapper"
                },
                [
                  _vm._l(_vm.filteredPairs, function(pair) {
                    return _c(
                      "div",
                      { key: pair.crmLogicalName, staticClass: "row" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "table-cell",
                            staticStyle: { width: "35%" }
                          },
                          [
                            _c("p", { staticClass: "label" }, [
                              _c("span", { staticClass: "p2" }, [
                                _vm._v(_vm._s(pair.crmDisplayName))
                              ]),
                              _vm._v(" (" + _vm._s(pair.crmLogicalName) + ")")
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "table-cell",
                            staticStyle: { width: "10%" }
                          },
                          [
                            _c("span", { staticClass: "label" }, [
                              _vm._v(_vm._s(pair.crmType))
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "table-cell",
                            staticStyle: { width: "10%" }
                          },
                          [
                            _c(
                              "svg",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: pair.msField,
                                    expression: "pair.msField"
                                  }
                                ],
                                staticClass:
                                  "svg-icon svg-20 control-component svg-primary"
                              },
                              [
                                _c("use", {
                                  attrs: {
                                    "xlink:href": "#layout-icons-positive"
                                  }
                                })
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "table-cell",
                            staticStyle: { width: "35%" }
                          },
                          [
                            _vm.editMode
                              ? _c("control-lookup-editor", {
                                  attrs: {
                                    evaluationContext: _vm.currentContext,
                                    options: _vm.getGroupLookupOptions()
                                  },
                                  model: {
                                    value: pair.msField,
                                    callback: function($$v) {
                                      _vm.$set(pair, "msField", $$v)
                                    },
                                    expression: "pair.msField"
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.editMode && pair.msFieldShort
                              ? _c("span", { staticClass: "label" }, [
                                  _c("p", { staticClass: "label" }, [
                                    _c("span", { staticClass: "p2" }, [
                                      _vm._v(
                                        _vm._s(pair.msFieldShort.displayName)
                                      )
                                    ]),
                                    _vm._v(
                                      " (" +
                                        _vm._s(pair.msFieldShort.logicalName) +
                                        ")"
                                    )
                                  ])
                                ])
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "table-cell",
                            staticStyle: { width: "10%" }
                          },
                          [
                            pair.msField
                              ? _c("span", { staticClass: "label" }, [
                                  _vm._v(
                                    _vm._s(
                                      pair.msField.data
                                        ? pair.msField.data.DataType
                                        : ""
                                    )
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm.filteredPairs && !_vm.filteredPairs.length
                    ? _c("div", { staticClass: "row no-results-message p13" }, [
                        _c(
                          "div",
                          {
                            staticClass: "table-cell",
                            style: { width: "100%" }
                          },
                          [
                            _c("div", {
                              directives: [
                                {
                                  name: "localization",
                                  rawName: "v-localization",
                                  value: {
                                    key: "common.dictionary.noMatchesFound"
                                  },
                                  expression:
                                    "{ key: 'common.dictionary.noMatchesFound' }"
                                }
                              ]
                            })
                          ]
                        )
                      ])
                    : _vm._e()
                ],
                2
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _vm.editMode
          ? _c("div", { staticClass: "footer" }, [
              _c("p", {
                directives: [
                  {
                    name: "localization",
                    rawName: "v-localization",
                    value: { key: "dialogues.mapping.footer" },
                    expression: "{ key: 'dialogues.mapping.footer'}"
                  }
                ],
                staticClass: "warning"
              }),
              _vm._v(" "),
              _c("div", { staticClass: "buttons" }, [
                _c("button", {
                  directives: [
                    {
                      name: "localization",
                      rawName: "v-localization",
                      value: { key: "common.dictionary.buttons.save" },
                      expression: "{key: 'common.dictionary.buttons.save'}"
                    }
                  ],
                  attrs: { type: "button" },
                  on: { click: _vm.save }
                }),
                _vm._v(" "),
                _c("button", {
                  directives: [
                    {
                      name: "localization",
                      rawName: "v-localization",
                      value: { key: "common.dictionary.buttons.cancel" },
                      expression: "{key: 'common.dictionary.buttons.cancel'}"
                    }
                  ],
                  staticClass: "cancel",
                  attrs: { type: "button" },
                  on: { click: _vm.close }
                })
              ])
            ])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }