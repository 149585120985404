var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.accessDeniedMsg
        ? _c("div", [_vm._v(_vm._s(_vm.accessDeniedMsg))])
        : [
            _vm.requestedAccess
              ? _c("div", [
                  _c("div", {
                    directives: [
                      {
                        name: "localization",
                        rawName: "v-localization",
                        value: { key: "common.dictionary.requestSentMessage" },
                        expression:
                          "{key: 'common.dictionary.requestSentMessage'}"
                      }
                    ]
                  })
                ])
              : _c("div", { class: _vm.requestedAccess === void 0 && "text" }, [
                  _c("div", {
                    directives: [
                      {
                        name: "localization",
                        rawName: "v-localization",
                        value: {
                          key: "common.dictionary.recordDetails.noAccess"
                        },
                        expression:
                          "{key: 'common.dictionary.recordDetails.noAccess'}"
                      }
                    ]
                  }),
                  _vm._v(" "),
                  _vm.requestedAccess === void 0 && _vm.showRequestAccess
                    ? _c("div", { staticClass: "no-access" }, [
                        _c("div", {
                          directives: [
                            {
                              name: "localization",
                              rawName: "v-localization",
                              value: {
                                key:
                                  "common.dictionary.recordDetails.requestAccess"
                              },
                              expression:
                                "{key: 'common.dictionary.recordDetails.requestAccess'}"
                            }
                          ]
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "buttons pt10" }, [
                          _c("button", {
                            directives: [
                              {
                                name: "localization",
                                rawName: "v-localization",
                                value: {
                                  key: "common.dictionary.buttons.cancel"
                                },
                                expression:
                                  "{key: 'common.dictionary.buttons.cancel'}"
                              }
                            ],
                            staticClass: "cancel mr20",
                            attrs: { type: "button" },
                            on: { click: _vm.cancel }
                          }),
                          _vm._v(" "),
                          _c("button", {
                            directives: [
                              {
                                name: "localization",
                                rawName: "v-localization",
                                value: {
                                  key: "common.dictionary.buttons.request"
                                },
                                expression:
                                  "{key: 'common.dictionary.buttons.request'}"
                              }
                            ],
                            attrs: { type: "button" },
                            on: { click: _vm.requestAccess }
                          })
                        ])
                      ])
                    : _vm._e()
                ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }