<template>
	<label
		v-data-attr="{ options }"
		:class="['custom-checkbox mobile tooltip geo-polygon-inline-view', {
			disabled: isDisabled
		}]"
	>
		<input v-model="selected" :disabled="isDisabled" type="checkbox" />
		<span class="value">
			<span class="value-checked">
				<span class="value-title" />
				<svg class="svg-icon svg-15">
					<use xlink:href="#layout-icons-bool-editor-on" class="boolean-icon layout-icons-bool-editor-on" />
				</svg>
			</span>
			<span class="value-unchecked">
				<span class="value-title" />
				<svg class="svg-icon svg-15">
					<use xlink:href="#layout-icons-bool-editor-off" class="boolean-icon layout-icons-bool-editor-off" />
				</svg>
			</span>
		</span>
	</label>
</template>
<script>
import '@/Bindings/ui-attribute.directive';
import FilterMixins from './../filter.mixins';
import helperMethods from '@/Components/ComponentSet/component-set-evaluation-context-helper.methods';

export default {
	name: 'geo-polygon-inline-view',
	mixins: [FilterMixins],
	props: {
		options: Object,
		evaluationContext: Object,
		stateNamespace: String,
		isDisabled: Boolean
	},
	data() {
		return {
			filterType: null,
			value: null
		};
	},
	computed: {
		selected: {
			get() {
				const value = this.$store.getters[this.stateNamespace + '/getFilterValue']({
					entity: this.entity,
					logicalName: this.logicalName
				});
				return !!value;
			},
			async set(value) {
				if (value) {
					const fltr = await this.getSearchFilter(this.logicalName);
					this.emitFilterChanged(fltr || null);
					this.$root.$emit(this.stateNamespace + '.switchLayout', this.mapComponentName);
					this.value && this.fork.emit(this.stateNamespace + '.mapSearchResults.areaSelectionFilter-setPolygon-enableFilter', this.value);
				} else {
					this.emitFilterChanged(null);
					this.fork.emit(this.stateNamespace + '.mapSearchResults.areaSelectionFilter-setPolygon-disableFilter');
				}
			}
		}
	},
	created() {
		this.mapComponentName = sc.utils.userAgent.userAgent.isMobile
			? 'component-set-mobile-search-results-map-only-view'
			: 'component-set-search-results-map-view';

		this.changeProp = (propName, val) => this.$emit('update:' + propName, val);

		this.logicalName = helperMethods.eval(this.evaluationContext, this.options.logicalName);
		this.entity = this.options.entities[0];

		const clearfn = (data) => {
			this.value = null;
			this.filterType = null;
			this.changeProp('isDisabled', true);
			data && data.clearPolygon && this.fork.emit(this.stateNamespace + '.mapSearchResults.areaSelectionFilter-clearPolygon');
		};

		this.unsubscribe = this.$store.subscribe((mutation, state) => {
			if (mutation.type === this.stateNamespace + '/resetFilters') {
				clearfn({ clearPolygon: true });
			}
			if (mutation.type === this.stateNamespace + '/replaceFilters') {
				clearfn({ clearPolygon: true });

				if (this.selected && mutation.payload.entity === this.entity) {
					const data = mutation.payload.filters[this.logicalName];
					let filterVal;

					switch (data.requestLabel) {
					case 'GeoDistanceFacetFilters':
						filterVal = {
							center: {
								lat: data.data.Pin.lat,
								lng: data.data.Pin.lon
							},
							radius: data.data.Items[0].Range.To
						};
						this.filterType = 'geoDistanceFacet';
						break;
					case 'GeoPolygonFilters':
						filterVal = data.data.Points.map(item => {
							return {
								lat: item.lat,
								lng: item.lon
							};
						});
						this.filterType = 'GeoPolygon';
						break;
					default:
						filterVal = null;
					}

					if (filterVal) {
						this.fork.emit(this.stateNamespace + '.mapSearchResults.areaSelectionFilter-setPolygon-enableFilter', filterVal);
						this.changeProp('isDisabled', false);
						this.$root.$emit(this.stateNamespace + '.switchLayout', this.mapComponentName);
						this.value = filterVal;
					}
				}
			}
		});

		this.fork = sc.events.fork();
		this.fork.on(this.stateNamespace + '.mapSearchResults.areaSelectionFilter-setPolygon', (shape) => {
			this.changeProp('isDisabled', false);
			this.filterType = shape.filterType;
			this.value = shape.value;

			this.selected = true;
		});
		this.fork.on(this.stateNamespace + '.mapSearchResults.areaSelectionFilter-reset', (data) => {
			this.selected = false;
			clearfn(data);
		});

		this.fork.on(this.stateNamespace + '.mapSearchResults.areaSelectionFilter-getPolygon', (callback) => {
			callback(this.value);
		});

		this.$root.$on(this.stateNamespace + '.map-ready', (callback) => {
			this.selected && this.filterType && this.value && callback(this.value);
		});
	},

	mounted() {
		this.changeProp('isDisabled', !this.selected);
	},

	beforeDestroy() {
		this.fork && this.fork.dispose();
		this.unsubscribe && this.unsubscribe();
		this.$root.$off(this.stateNamespace + '.map-ready');
	}
};
</script>
<style src="./bool-inline-view.less" scoped></style>
