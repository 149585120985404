var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "version-dialog" }, [
    _c(
      "div",
      { staticClass: "versions" },
      _vm._l(_vm.versions, function(version) {
        return _c("div", { key: version.label, staticClass: "version-item" }, [
          _c("span", { staticClass: "label p4" }, [
            _vm._v(_vm._s(version.label) + " ")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "value h5" }, [
            _vm._v(_vm._s(version.value))
          ])
        ])
      })
    ),
    _vm._v(" "),
    _vm.termsenabled
      ? _c("div", { staticClass: "privacy-policy" }, [
          _c(
            "a",
            {
              attrs: { href: _vm.ppLink },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.download(_vm.tosLink, "Terms Of Use")
                }
              }
            },
            [_vm._v("Terms of Use")]
          ),
          _vm._v(" and "),
          _c(
            "a",
            {
              attrs: { href: _vm.tosLink },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.download(_vm.ppLink, "Privacy Policy")
                }
              }
            },
            [_vm._v("Privacy Policy")]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ],
      staticClass: "loading-overlay large"
    })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "powered-by" }, [
      _c("span", { staticClass: "label p1" }, [_vm._v("Powered by")]),
      _vm._v(" "),
      _c("span", { staticClass: "value p2" }, [
        _c("a", { attrs: { target: "_blank", href: "https://ascendix.com" } }, [
          _vm._v("Ascendix Technologies")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }